import styled from 'styled-components';

export const StyledFilesTable = styled.div`
  margin-top: 78px;

  .table-wrapper {
    table {
      tbody {
        tr {
          td {
            padding: 8px 10px;
          }
        }
      }
    }
  }
`;
