import { useEffect, useCallback } from 'react';

interface InfiniteScrollProps {
  fetchMore: () => void;
  disabled?: boolean;
  scrollElement: HTMLElement | null;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  fetchMore,
  disabled,
  scrollElement,
}) => {
  const elementScroll = useCallback(
    (event: Event) => {
      if (!disabled) {
        const currentTarget = event.target as HTMLElement;
        const max = currentTarget.scrollHeight - currentTarget.clientHeight;

        if (Math.ceil(currentTarget.scrollTop) >= max) {
          fetchMore();
        }
      }
    },
    [disabled, fetchMore],
  );

  const pageScroll = useCallback(() => {
    if (!disabled) {
      const { offsetHeight, scrollHeight } = document.body;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (offsetHeight + scrollTop >= scrollHeight) {
        fetchMore();
      }
    }
  }, [disabled, fetchMore]);

  useEffect(() => {
    const currentScrollElement = scrollElement || document;

    currentScrollElement.addEventListener(
      'scroll',
      scrollElement ? elementScroll : pageScroll,
    );

    return () => {
      currentScrollElement.removeEventListener(
        'scroll',
        scrollElement ? elementScroll : pageScroll,
      );
    };
  }, [disabled, scrollElement, elementScroll, pageScroll]);

  return null;
};

export default InfiniteScroll;
