import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledSmartPushList } from './styles';
import AlertsTable from './SmartPushsTable';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Header from 'components/atom/Header';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { AvailableOrganizationModules } from 'utils/organization';

const SmartPushList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationModules = useAppSelector(
    (store) => store.organization.modules,
  );
  const canShowSmartPush: boolean =
    organizationModules?.includes(AvailableOrganizationModules.smartPush) ??
    false;

  useEffect(() => {
    if (!canShowSmartPush) {
      navigate('/');
      return;
    }
  }, [dispatch, navigate, canShowSmartPush]);

  return (
    <StyledSmartPushList>
      <Header
        leftSideContent={
          <div className="left-side-content">
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Smart pushs')}
            </Text>
            <Button leftIcon="add" theme="link-primary" to="/smartpush/add">
              {t('New push')}
            </Button>
          </div>
        }
      />
      <div className="content-config-container">
        <div className="content-side">
          <div className="content">
            <AlertsTable />
          </div>
        </div>
      </div>
    </StyledSmartPushList>
  );
};

export default SmartPushList;
