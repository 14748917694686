import styled from 'styled-components';

export const StyledCollaboratorProfile = styled.div`
  padding: 32px 0px;

  .collaborator-header {
    .collaborator-avatar {
      box-shadow: 0px 4px 100px 4px #0000001f;
    }

    .select {
      .react-select__control {
        .react-select__single-value {
          margin-top: 0px !important;
        }

        h6 {
          font-weight: 700;
          padding: 12px;
          padding-left: 0px;
        }
      }
    }
  }

  hr {
    margin: 32px 0px;
  }
`;

export const Container = styled.div`
  padding: 0px 42px;
`;
