import { t } from 'i18next';

export const getTinyMCEi18nStrings = () => {
  // see: https://github.com/i18next/react-i18next/issues/909
  // see: https://www.tiny.cloud/docs/api/tinymce.util/tinymce.util.i18n/#add

  // Strings from TinyMCE is "hardcoded" to their JS/TS files
  // We need to map here all keys-values that we use on component,
  // so it get translated by react-i18n and our translation tools

  /* eslint-disable */
  return {
    Blocks: t('Blocks'),
    Paragraph: t('Paragraph'),
    'Heading 1': t('Heading 1'),
    'Heading 2': t('Heading 2'),
    'Heading 3': t('Heading 3'),
    'Heading 4': t('Heading 4'),
    'Heading 5': t('Heading 5'),
    'Heading 6': t('Heading 6'),
    Preformatted: t('Preformatted'),
    Bold: t('Bold'),
    Italic: t('Italic'),
    'Text color': t('Text color'),
    'Light Green': t('Light Green'),
    'Light Yellow': t('Light Yellow'),
    'Light Red': t('Light Red'),
    'Light Purple': t('Light Purple'),
    'Light Blue': t('Light Blue'),
    Green: t('Green'),
    Yellow: t('Yellow'),
    Red: t('Red'),
    Purple: t('Purple'),
    Blue: t('Blue'),
    'Dark Turquoise': t('Dark Turquoise'),
    Orange: t('Orange'),
    'Dark Red': t('Dark Red'),
    'Dark Purple': t('Dark Purple'),
    'Dark Blue': t('Dark Blue'),
    'Light Gray': t('Light Gray'),
    'Medium Gray': t('Medium Gray'),
    Gray: t('Gray'),
    'Dark Gray': t('Dark Gray'),
    'Navy Blue': t('Navy Blue'),
    Black: t('Black'),
    White: t('White'),
    'Remove color': t('Remove color'),
    'Custom color': t('Custom color'),
    'Color Picker': t('Color Picker'),
    Cancel: t('Cancel'),
    Save: t('Save'),
    'Background color': t('Background color'),
    'Align left': t('Align left'),
    'Align center': t('Align center'),
    'Align right': t('Align right'),
    Justify: t('Justify'),
    'Bullet list': t('Bullet list'),
    Default: t('Default'),
    Circle: t('Circle'),
    Square: t('Square'),
    'Numbered list': t('Numbered list'),
    'Lower Alpha': t('Lower Alpha'),
    'Lower Greek': t('Lower Greek'),
    'Lower Roman': t('Lower Roman'),
    'Upper Alpha': t('Upper Alpha'),
    'Upper Roman': t('Upper Roman'),
    'Decrease indent': t('Decrease indent'),
    'Increase indent': t('Increase indent'),
    'More...': t('More...'),
    'Left to right': t('Left to right'),
    'Right to left': t('Right to left'),
    characters: t('characters'),
    words: t('words'),
    Resize: t('Resize'),
    Link: t('Link'),
    'Remove link': t('Remove link'),
    'Open link': t('Open link'),
    Blockquote: t('Blockquote'),
    'Insert image': t('Insert image'),
    'Horizontal line': t('Horizontal line'),
    Emojis: t('Emojis'),
    All: t('All'),
    Symbols: t('Symbols'),
    People: t('People'),
    'Animals and Nature': t('Animals and Nature'),
    'Food and Drink': t('Food and Drink'),
    Activity: t('Activity'),
    'Travel and Places': t('Travel and Places'),
    Objects: t('Objects'),
    Flags: t('Flags'),
    Search: t('Search'),
    'Image uploading': t('Image uploading'),
    'Failed to upload image: {0}': `${t('Failed to upload image')}: {0}`,
    'Insert/Edit Media': t('Insert/Edit Media'),
    Source: t('Source'),
    Close: t('Close'),
    'Media embed handler error: Media source (URL) supplied is not valid or not supported.': `${t(
      'Media embed handler error:',
    )} ${t('Media source (URL) supplied is not valid or not supported.')}`,
  };
};

export const isValidUrl = (url: string): boolean => {
  // easiest way to find if URL is really a valid url
  // regexes could validate it, but it is error-prone
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const removeParamsFromUrl = (url: string): string => {
  if (!url || !isValidUrl(url)) return url;

  // see: https://stackoverflow.com/a/67304579
  const parsedUrl: URL = new URL(url);
  parsedUrl.search = '';
  parsedUrl.hash = '';
  return parsedUrl.toString();
};
