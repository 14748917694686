import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import DatePicker from 'components/molecule/DatePicker';
import Tooltip from 'components/atom/Tooltip';
import Icon from 'components/atom/Icon';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import Separator from 'components/atom/Separator';
import SchedulePreview from '../../../SchedulePreview';
import Alert from 'components/molecule/Alert';

import { isValidTimeFormat } from 'utils/date';

import { RecurrenceConfigProps, SchedulerStateProps } from './types';
import { SelectOption } from 'components/molecule/Select/types';

import { StyledRecurrenceConfig } from './styles';
import { SingleValue } from 'react-select';

const RecurrenceConfig: React.FC<RecurrenceConfigProps> = ({
  contentState,
  setContentState,
  setWithRecurrence,
  contentStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectOptions: SelectOption[] = [
    {
      value: 'days',
      label: (
        <Text color="grayscale-200" className="scheduler-type-option">
          {t('Days')}
        </Text>
      ),
    },
    {
      value: 'weeks',
      label: (
        <Text color="grayscale-200" className="scheduler-type-option">
          {t('Weeks')}
        </Text>
      ),
    },
    {
      value: 'months',
      label: (
        <Text color="grayscale-200" className="scheduler-type-option">
          {t('Months')}
        </Text>
      ),
    },
    {
      value: 'years',
      label: (
        <Text color="grayscale-200" className="scheduler-type-option">
          {t('Years')}
        </Text>
      ),
    },
  ];

  const [scheduler, setScheduler] = useState<SchedulerStateProps>({
    scheduler_start_date_at: { ...contentState.scheduler_start_date_at },
    scheduler_finish_date_at: { ...contentState.scheduler_finish_date_at },
    content_start_time_at: { ...contentState.content_start_time_at },
    content_finish_time_at: { ...contentState.content_finish_time_at },
    content_duration: { ...contentState.content_duration },
    content_week_days: { ...contentState.content_week_days },
    scheduler_var: { ...contentState.scheduler_var },
    scheduler_type: { ...contentState.scheduler_type },
  });
  const [schedulerPreview, setSchedulerPreview] = useState<SchedulerStateProps>(
    {
      scheduler_start_date_at: { ...contentState.scheduler_start_date_at },
      scheduler_finish_date_at: { ...contentState.scheduler_finish_date_at },
      content_start_time_at: { ...contentState.content_start_time_at },
      content_finish_time_at: { ...contentState.content_finish_time_at },
      content_duration: { ...contentState.content_duration },
      content_week_days: { ...contentState.content_week_days },
      scheduler_var: { ...contentState.scheduler_var },
      scheduler_type: { ...contentState.scheduler_type },
    },
  );

  const [currentDurationTimeOut, setCurrentDurationTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [currentStartTimeAtTimeOut, setCurrentStartTimeAtTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [currentFinishTimeAtTimeOut, setCurrentFinishTimeAtTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [currentScheduleVarTimeOut, setCurrentScheduleVarTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [currentWeekDaysTimeOut, setCurrentWeekDaysTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const handleChangeStartDateAt = (date: Date) => {
    setScheduler((updatedState) => ({
      ...updatedState,
      scheduler_start_date_at: {
        ...updatedState.scheduler_start_date_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
    setSchedulerPreview((updatedState) => ({
      ...updatedState,
      scheduler_start_date_at: {
        ...updatedState.scheduler_start_date_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishDateAt = (date: Date) => {
    setScheduler((updatedState) => ({
      ...updatedState,
      scheduler_finish_date_at: {
        ...updatedState.scheduler_finish_date_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
    setSchedulerPreview((updatedState) => ({
      ...updatedState,
      scheduler_finish_date_at: {
        ...updatedState.scheduler_finish_date_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeContentDuration = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentDurationTimeOut) {
      clearTimeout(currentDurationTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      setSchedulerPreview((updatedState) => ({
        ...updatedState,
        content_duration: {
          ...updatedState.content_duration,
          value: value,
        },
      }));
    }, 1000);

    setCurrentDurationTimeOut(timeOut);
    setScheduler((updatedState) => ({
      ...updatedState,
      content_duration: {
        ...updatedState.content_duration,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeStartTimeAt = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentStartTimeAtTimeOut) {
      clearTimeout(currentStartTimeAtTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      setSchedulerPreview((updatedState) => ({
        ...updatedState,
        content_start_time_at: {
          ...updatedState.content_start_time_at,
          value: value,
        },
      }));
    }, 1000);

    setCurrentStartTimeAtTimeOut(timeOut);
    setScheduler((updatedState) => ({
      ...updatedState,
      content_start_time_at: {
        ...updatedState.content_start_time_at,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishTimeAt = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentFinishTimeAtTimeOut) {
      clearTimeout(currentFinishTimeAtTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      setSchedulerPreview((updatedState) => ({
        ...updatedState,
        content_finish_time_at: {
          ...updatedState.content_finish_time_at,
          value: value,
        },
      }));
    }, 1000);

    setCurrentFinishTimeAtTimeOut(timeOut);
    setScheduler((updatedState) => ({
      ...updatedState,
      content_finish_time_at: {
        ...updatedState.content_finish_time_at,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleDaySelection = (item: string) => {
    if (currentWeekDaysTimeOut) {
      clearTimeout(currentWeekDaysTimeOut);
    }

    const newWeekDays = [...scheduler.content_week_days.value];

    if (newWeekDays.some((day) => day === item)) {
      newWeekDays.splice(newWeekDays.indexOf(item), 1);
    } else {
      newWeekDays.push(item);
    }

    const timeOut = setTimeout(() => {
      setSchedulerPreview({
        ...scheduler,
        content_week_days: {
          ...scheduler.content_week_days,
          value: newWeekDays,
        },
      });
    }, 1000);

    setCurrentWeekDaysTimeOut(timeOut);
    setScheduler({
      ...scheduler,
      content_week_days: {
        ...scheduler.content_week_days,
        value: newWeekDays,
        error: { hasError: false, errorMessage: '' },
      },
    });
  };

  const handleChangeVar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentScheduleVarTimeOut) {
      clearTimeout(currentScheduleVarTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      setSchedulerPreview((updatedState) => ({
        ...updatedState,
        scheduler_var: {
          ...updatedState.scheduler_var,
          value: value,
        },
      }));
    }, 1000);

    setCurrentScheduleVarTimeOut(timeOut);
    setScheduler((updatedState) => ({
      ...updatedState,
      scheduler_var: {
        ...updatedState.scheduler_var,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeType = (option: SingleValue<SelectOption>) => {
    if (!option) return;

    setScheduler((updatedState) => ({
      ...updatedState,
      scheduler_type: {
        ...updatedState.scheduler_type,
        value: option.value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
    setSchedulerPreview((updatedState) => ({
      ...updatedState,
      scheduler_type: {
        ...updatedState.scheduler_type,
        value: option.value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const clearErrors = () => {
    setScheduler((updatedState) => ({
      ...updatedState,
      scheduler_start_date_at: {
        ...updatedState.scheduler_start_date_at,
        error: { hasError: false, errorMessage: '' },
      },
      content_start_time_at: {
        ...updatedState.content_start_time_at,
        error: { hasError: false, errorMessage: '' },
      },
      scheduler_finish_date_at: {
        ...updatedState.scheduler_finish_date_at,
        error: { hasError: false, errorMessage: '' },
      },
      content_finish_time_at: {
        ...updatedState.content_finish_time_at,
        error: { hasError: false, errorMessage: '' },
      },
      content_duration: {
        ...updatedState.content_duration,
        error: { hasError: false, errorMessage: '' },
      },
      scheduler_var: {
        ...updatedState.scheduler_var,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handeSave = () => {
    const {
      scheduler_start_date_at: { value: scheduler_start_date_at },
      content_start_time_at: { value: content_start_time_at },
      scheduler_finish_date_at: { value: scheduler_finish_date_at },
      content_finish_time_at: { value: content_finish_time_at },
      content_duration: { value: content_duration },
      scheduler_var: { value: scheduler_var },
      content_week_days: { value: content_week_days },
    } = scheduler;

    clearErrors();

    if (!scheduler_start_date_at) {
      setScheduler((updatedState) => ({
        ...updatedState,
        scheduler_start_date_at: {
          ...updatedState.scheduler_start_date_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      scheduler_finish_date_at &&
      !dayjs(scheduler_start_date_at).isSame(scheduler_finish_date_at) &&
      !dayjs(scheduler_start_date_at).isBefore(scheduler_finish_date_at)
    ) {
      setScheduler((updatedState) => ({
        ...updatedState,
        scheduler_finish_date_at: {
          ...updatedState.scheduler_finish_date_at,
          error: {
            hasError: true,
            errorMessage: t(
              'The start date cannot be greater than the end date.',
            ),
          },
        },
      }));
      return;
    }

    if (!content_duration && content_duration !== '0') {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_duration: {
          ...updatedState.content_duration,
          error: {
            hasError: true,
            errorMessage: t('This field is required'),
          },
        },
      }));
      return;
    }

    if (parseInt(content_duration) < 0) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_duration: {
          ...updatedState.content_duration,
          error: {
            hasError: true,
            errorMessage: t('Duration in days cannot be a negative value'),
          },
        },
      }));
      return;
    }

    if (
      typeof content_duration === 'string' &&
      content_duration.indexOf('.') !== -1
    ) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_duration: {
          ...updatedState.content_duration,
          error: {
            hasError: true,
            errorMessage: t('Enter an integer value'),
          },
        },
      }));
      return;
    }

    if (!content_start_time_at) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_start_time_at: {
          ...updatedState.content_start_time_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (!isValidTimeFormat(content_start_time_at.substring(0, 5))) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_start_time_at: {
          ...updatedState.content_start_time_at,
          error: { hasError: true, errorMessage: t('Enter a valid time') },
        },
      }));
      return;
    }

    if (!content_finish_time_at) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_finish_time_at: {
          ...updatedState.content_finish_time_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (!isValidTimeFormat(content_finish_time_at.substring(0, 5))) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_finish_time_at: {
          ...updatedState.content_finish_time_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      scheduler_finish_date_at &&
      dayjs(scheduler_start_date_at).isSame(scheduler_finish_date_at) &&
      content_finish_time_at <= content_start_time_at
    ) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_finish_time_at: {
          ...updatedState.content_finish_time_at,
          error: {
            hasError: true,
            errorMessage: t(
              'The start time cannot be greater than or equal to the end time.',
            ),
          },
        },
      }));
      return;
    }

    if (!scheduler_var) {
      setScheduler((updatedState) => ({
        ...updatedState,
        scheduler_var: {
          ...updatedState.scheduler_var,
          error: {
            hasError: true,
            errorMessage: t('This field is required'),
          },
        },
      }));
      return;
    }
    if (parseInt(scheduler_var, 10) < 0) {
      setScheduler((updatedState) => ({
        ...updatedState,
        scheduler_var: {
          ...updatedState.scheduler_var,
          error: {
            hasError: true,
            errorMessage: t('Enter a value greater than or equal to 0'),
          },
        },
      }));
      return;
    }

    if (
      typeof scheduler_var === 'string' &&
      scheduler_var.indexOf('.') !== -1
    ) {
      setScheduler((updatedState) => ({
        ...updatedState,
        scheduler_var: {
          ...updatedState.scheduler_var,
          error: {
            hasError: true,
            errorMessage: t('Enter an integer value'),
          },
        },
      }));
      return;
    }

    if (content_week_days.length === 0) {
      setScheduler((updatedState) => ({
        ...updatedState,
        content_week_days: {
          ...updatedState.content_week_days,
          error: {
            hasError: true,
            errorMessage: t('You must select at least one day'),
          },
        },
      }));
      return;
    }

    setContentState((updatedState) => ({
      ...updatedState,
      ...scheduler,
    }));
    setWithRecurrence(true);
    dispatch(closeModal());
  };

  return (
    <StyledRecurrenceConfig>
      {['active', 'scheduled'].includes(contentStatus) && (
        <Alert
          icon="alert"
          title={t('Attention!')}
          description={t(
            'Each schedule will be made when previous content is finalized',
          )}
        />
      )}

      <Text as="h5" weight="700">
        {t('Schedule start and end date')}
      </Text>
      <Text as="p" className="subtitle">
        {t(
          'Dates referring to the posting of the content, the time that this content will be on screen will be defined in the duration.',
        )}
      </Text>

      <div className="form-group">
        <DatePicker
          label={t('Start at')}
          id="start-at"
          icon="calendar-2-fill"
          value={scheduler.scheduler_start_date_at.value}
          onChange={handleChangeStartDateAt}
          hasError={scheduler.scheduler_start_date_at.error.hasError}
          errorMessage={scheduler.scheduler_start_date_at.error.errorMessage}
        />
        <DatePicker
          label={t('Finish at')}
          id="finish-at"
          icon="calendar-2-fill"
          value={scheduler.scheduler_finish_date_at.value}
          onChange={handleChangeFinishDateAt}
          hasError={scheduler.scheduler_finish_date_at.error.hasError}
          errorMessage={scheduler.scheduler_finish_date_at.error.errorMessage}
        />
      </div>

      <div className="duration-label-wrapper">
        <Text as="h5" weight="700">
          {t('Duration and repetition')}
        </Text>
        <div className="info">
          <Tooltip
            id="duration-info"
            content={t(
              'This is the duration of the on-screen content. Content has a single post but the exposure time is cyclic. It is possible to create content that will be displayed to collaborators for just a few hours each occurrence. Therefore, its duration complete 1 day.',
            )}
            place="top"
          >
            <Icon name="information-line" color="grayscale-200" />
          </Tooltip>
        </div>
      </div>

      <Input
        type="number"
        actions={<Text color="grayscale-200">{t('Days')}</Text>}
        className="screen-duration"
        value={scheduler.content_duration.value}
        onChange={handleChangeContentDuration}
        hasError={scheduler.content_duration.error.hasError}
        errorMessage={scheduler.content_duration.error.errorMessage}
      />

      <div className="form-group time-form-group">
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label={t('Start time')}
          placeholder="HH:MM"
          id="start-time"
          value={scheduler.content_start_time_at.value}
          onChange={handleChangeStartTimeAt}
          hasError={scheduler.content_start_time_at.error.hasError}
          errorMessage={scheduler.content_start_time_at.error.errorMessage}
        />
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label={t('End time')}
          placeholder="HH:MM"
          id="end-time"
          value={scheduler.content_finish_time_at.value}
          onChange={handleChangeFinishTimeAt}
          hasError={scheduler.content_finish_time_at.error.hasError}
          errorMessage={scheduler.content_finish_time_at.error.errorMessage}
        />
      </div>

      <div className="week-days-label-wrapper">
        <Text as="pre" color="grayscale-200">
          {t('Days of the week that content can launch')}
        </Text>
        <div className="info">
          <Tooltip
            id="week-days-info"
            content={t(
              'Choose the days of the week in will be allowed to start content display. Eg: If your organization does not work on weekends, deselect these days. And the content will never start on weekends.',
            )}
            place="top"
          >
            <Icon name="information-line" color="grayscale-200" />
          </Tooltip>
        </div>
      </div>

      <div className="week-days">
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '7')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('7')}
        >
          {t('Sunday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '1')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('1')}
        >
          {t('Monday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '2')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('2')}
        >
          {t('Tuesday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '3')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('3')}
        >
          {t('Wednesday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '4')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('4')}
        >
          {t('Thursday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '5')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('5')}
        >
          {t('Friday')[0]}
        </Button>
        <Button
          theme={`${
            scheduler.content_week_days.value.some((item) => item === '6')
              ? 'dark'
              : 'dark-flat'
          }`}
          size="small"
          radius="100px"
          onClick={() => handleDaySelection('6')}
        >
          {t('Saturday')[0]}
        </Button>
      </div>
      {scheduler.content_week_days.error.hasError && (
        <Text as="pre" color="danger-color" className="input-error-message">
          {scheduler.content_week_days.error.errorMessage}
        </Text>
      )}

      <div className="form-group repeat-form-group">
        <Input
          type="number"
          label={t('Repeat after')}
          id="repeat-number"
          value={scheduler.scheduler_var.value}
          onChange={handleChangeVar}
          hasError={scheduler.scheduler_var.error.hasError}
          errorMessage={scheduler.scheduler_var.error.errorMessage}
        />
        <Select
          value={
            selectOptions.find(
              (option) => option.value === scheduler.scheduler_type.value,
            ) || selectOptions[0]
          }
          setValue={(option) =>
            handleChangeType(option as SingleValue<SelectOption>)
          }
          options={selectOptions}
          theme="primary"
        />
      </div>

      <SchedulePreview schedulerState={schedulerPreview} />

      <Separator />

      <Button
        theme="primary"
        rounded="true"
        className="save-button"
        onClick={handeSave}
      >
        {t('Save')}
      </Button>
    </StyledRecurrenceConfig>
  );
};

export default RecurrenceConfig;
