import { MediaUrlResolverProps } from '../types';

const YOUTUBE_URL_REGEX =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\??v?=?)|(shorts\/))([^#&?]*).*/;

const getYouTubeVideoIdByUrl = (url: string): string | null => {
  const match: RegExpMatchArray | null = url.match(YOUTUBE_URL_REGEX);
  return match && match[8] && match[8].length === 11 ? match[8] : null;
};

export const isYouTubeUrl = (url: string) => {
  return url && YOUTUBE_URL_REGEX.test(url);
};

export const resolveYouTubeEmbed = ({
  data,
  resolve,
  reject,
}: MediaUrlResolverProps) => {
  const videoId: string | null = getYouTubeVideoIdByUrl(data.url);
  if (!videoId) {
    reject({
      msg: 'Media source (URL) supplied is not valid or not supported.',
    });
    return;
  }

  // language=html
  const html = `
    <iframe
      class="editor--embedded-video-container video-youtube"
      style="width: calc(100% - 5px); height: 100%; aspect-ratio: 16/9"
      src="https://youtube.com/embed/${videoId}"
      allowfullscreen="allowfullscreen">
    </iframe>
  `;
  resolve({ html });
};
