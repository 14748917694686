import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledAlertForm } from './styles';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import { SelectOption } from 'components/molecule/Select/types';
import Input from 'components/molecule/Input';
import DatePicker from 'components/molecule/DatePicker';
import Separator from 'components/atom/Separator';
import { AlertFields, KeyValueOptionProps } from './types';
import { useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCSCXAlert,
  getCSCXAlert,
  getCSCXAlertOptions,
  updateCSCXAlert,
} from 'apis/staff';
import { AlertPostSendData } from 'apis/staff/types';
import { dateAndTimeToApiDate } from 'utils/date';
import { RequestFieldError } from 'apis/types';
import { showMessage } from 'store/slices/toaster';
import Editor from 'components/molecule/Editor';
import SwitchButton from 'components/atom/SwitchButton';
import { ApiVersions, getBaseAPIUrl } from 'settings';

const AlertForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { alertId } = useParams();
  const [sections, setSections] = useState([]);
  const [targets, setTargets] = useState([]);
  const [modes, setModes] = useState([]);
  const [alert, setAlert] = useState<AlertFields>({
    title: { value: null, error: { hasError: false, errorMessage: '' } },
    content: { value: null, error: { hasError: false, errorMessage: '' } },
    section: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    target: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    organizations: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    mode: { value: null, error: { hasError: false, errorMessage: '' } },
    is_active: { value: true, error: { hasError: false, errorMessage: '' } },
    start_at: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    start_at_time: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    finish_at: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    finish_at_time: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
  });

  const getSectionId = () => {
    if (alert?.section.value) {
      const parts = (alert?.section.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getTargetId = () => {
    if (alert?.target.value) {
      const parts = (alert?.target.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getOrganizationsIds = () => {
    if (alert?.organizations.value) {
      const ids: string[] = [];
      const options = alert?.organizations.value as SelectOption[];
      options.forEach((option) => {
        const parts = option.value.split('__');
        ids.push(parts[parts.length - 1]);
      });
      return ids;
    }
    return [];
  };

  const getModeId = () => {
    if (alert?.mode.value) {
      const parts = (alert?.mode.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getStartAt = () => {
    if (alert.start_at.value) {
      return dateAndTimeToApiDate(
        alert.start_at.value,
        alert.start_at_time?.value || undefined,
      );
    }

    return '';
  };

  const getFinishAt = () => {
    if (alert.finish_at.value) {
      return dateAndTimeToApiDate(
        alert.finish_at.value,
        alert.finish_at_time?.value || undefined,
      );
    }

    return null;
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'danger',
        icon: 'close',
        time: 10000,
        customLeft: '0px',
      }),
    );
  };

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setAlert((lastFields) => {
        const fieldContent = {
          ...lastFields[error.field as keyof AlertFields],
        };

        return {
          ...lastFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const getTimeAsStr = (date: string) => {
    const dataUTC = new Date(
      Date.UTC(
        parseInt(date.substring(0, 4)), // Ano
        parseInt(date.substring(5, 7)) - 1, // Mês (0-11)
        parseInt(date.substring(8, 10)), // Dia
        parseInt(date.substring(11, 13)), // Hora
        parseInt(date.substring(14, 16)), // Minutos
        parseInt(date.substring(17, 19)), // Segundos
      ),
    );

    return `${dataUTC.getUTCHours().toString().padStart(2, '0')}:${dataUTC
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const fetchData = useCallback(() => {
    dispatch(setPageLoading(true));

    getCSCXAlertOptions()
      .then((response) => {
        setSections(
          response.data.sections.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );
        setTargets(
          response.data.targets.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );
        setModes(
          response.data.modes.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );

        if (alertId) {
          getCSCXAlert(alertId)
            .then((response) => {
              setAlert({
                ...alert,
                title: {
                  value: response.data.title,
                  error: { hasError: false, errorMessage: '' },
                },
                content: {
                  value: response.data.content,
                  error: { hasError: false, errorMessage: '' },
                },
                is_active: {
                  value: response.data.is_active,
                  error: { hasError: false, errorMessage: '' },
                },
                section: {
                  value: {
                    value: `${response.data.section.value}__${response.data.section.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.section.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                target: {
                  value: {
                    value: `${response.data.target.value}__${response.data.target.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.target.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                organizations: {
                  value: response.data.organizations.map(
                    (org: KeyValueOptionProps) => {
                      return {
                        value: `${org.value}__${org.key}`,
                        label: (
                          <Button theme="link-primary" size="small">
                            {org.value}
                          </Button>
                        ),
                      };
                    },
                  ),
                  error: { hasError: false, errorMessage: '' },
                },
                mode: {
                  value: {
                    value: `${response.data.mode.value}__${response.data.mode.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.mode.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                start_at: {
                  value: new Date(response.data.start_at),
                  error: { hasError: false, errorMessage: '' },
                },
                start_at_time: {
                  value: getTimeAsStr(response.data.start_at),
                  error: { hasError: false, errorMessage: '' },
                },
                finish_at: {
                  value: response.data.finish_at
                    ? new Date(response.data.finish_at)
                    : null,
                  error: { hasError: false, errorMessage: '' },
                },
                finish_at_time: {
                  value: response.data.finish_at
                    ? getTimeAsStr(response.data.finish_at)
                    : '',
                  error: { hasError: false, errorMessage: '' },
                },
              });
            })
            .catch(() => {
              navigate('/error-404');
            })
            .finally(() => {
              dispatch(setPageLoading(false));
            });
        } else {
          dispatch(setPageLoading(false));
        }
      })
      .catch(() => {
        dispatch(setPageLoading(false));
        navigate('/error-404');
      });
  }, [dispatch, alertId, alert, navigate]);

  const postAPIDataFactory = () => {
    return {
      title: alert.title.value || '',
      content: alert.content.value || '',
      section: getSectionId() || '',
      target: getTargetId() || '',
      organizations: getOrganizationsIds(),
      mode: getModeId() || '',
      is_active: alert.is_active.value,
      start_at: getStartAt(),
      finish_at: getFinishAt(),
    };
  };

  const createAlert = () => {
    dispatch(setPageLoading(true));

    const data: AlertPostSendData = postAPIDataFactory();

    createCSCXAlert(data)
      .then(() => {
        showSuccessMessage(t('The alert has been created'));
        navigate('/staff/cscx/alert');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while creating the alert'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const updateAlert = () => {
    dispatch(setPageLoading(true));

    const data: AlertPostSendData = postAPIDataFactory();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateCSCXAlert(alertId!, data)
      .then(() => {
        showSuccessMessage(t('The alert has been updated'));
        navigate('/staff/cscx/alert');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the alert'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSaveClick = () => {
    if (validateAlert()) {
      if (alertId) {
        updateAlert();
      } else {
        createAlert();
      }
    }
  };

  const validateAlert = () => {
    const updatedAlert = { ...alert };
    let isValid = true;

    if (!alert.title.value) {
      updatedAlert.title.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.title.error = { hasError: false, errorMessage: '' };
    }

    if (!alert.content.value) {
      updatedAlert.content.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.content.error = { hasError: false, errorMessage: '' };
    }

    if (!alert.section.value) {
      updatedAlert.section.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.section.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!alert.target.value) {
      updatedAlert.target.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.target.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (getTargetId() == '4' && getOrganizationsIds().length == 0) {
      updatedAlert.organizations.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.organizations.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!alert.mode.value) {
      updatedAlert.mode.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.mode.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!alert.start_at.value) {
      updatedAlert.start_at.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.start_at.error = { hasError: false, errorMessage: '' };
    }

    if (!alert.start_at_time.value) {
      updatedAlert.start_at_time.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.start_at_time.error = { hasError: false, errorMessage: '' };
    }

    if (alert.finish_at.value) {
      if (!alert.finish_at_time.value) {
        updatedAlert.finish_at_time.error = {
          hasError: true,
          errorMessage: t('This field is required'),
        };
        isValid = false;
      } else {
        updatedAlert.finish_at_time.error = {
          hasError: false,
          errorMessage: '',
        };
      }
    } else {
      updatedAlert.finish_at_time.error = { hasError: false, errorMessage: '' };
    }

    if (!alert.content.value) {
      updatedAlert.content.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedAlert.content.error = { hasError: false, errorMessage: '' };
    }

    setAlert(updatedAlert);
    return isValid;
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData, navigate]);

  return (
    <StyledAlertForm>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {alertId ? t('Edit alert') : t('New alert')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => navigate('/staff/cscx/alert')}
              >
                {t('Cancel')}
              </Button>
              <Button
                theme="primary"
                rounded="true"
                onClick={() => handleSaveClick()}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
          <div className="content">
            <section>
              <Text as="h4" className="section-title">
                {t('General information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="switch-wrapper">
                    <SwitchButton
                      id="isActive"
                      checked={alert.is_active.value}
                      onChange={() => {
                        setAlert({
                          ...alert,
                          is_active: {
                            value: !alert.is_active.value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                    />
                    <Text as="h6">{t('Is active')}</Text>
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Title')}*
                  </Text>
                  <div className="section-group">
                    <Input
                      type="text"
                      id="title"
                      theme="default"
                      limit={100}
                      value={
                        alert.title.value != null
                          ? alert.title.value
                          : undefined
                      }
                      onChange={(event) => {
                        const { value } = event.target;
                        setAlert({
                          ...alert,
                          title: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={alert.title.error.hasError}
                      errorMessage={alert.title.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Section')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={sections}
                      value={alert.section.value}
                      setValue={(value) =>
                        setAlert({
                          ...alert,
                          section: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={alert.section.error.hasError}
                      errorMessage={alert.section.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a section...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Target')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={targets}
                      value={alert.target.value}
                      setValue={(value) =>
                        setAlert({
                          ...alert,
                          target: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                          organizations: {
                            value: null,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={alert.target.error.hasError}
                      errorMessage={alert.target.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a target...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              {getTargetId() == '4' && (
                <div className="section-contents">
                  <div className="section-content">
                    <Text as="h6" color="grayscale-200">
                      {t('Organizations')}*
                    </Text>
                    <div className="section-group">
                      <Select
                        value={alert.organizations.value}
                        setValue={(value) =>
                          setAlert({
                            ...alert,
                            organizations: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={alert.organizations.error.hasError}
                        errorMessage={alert.organizations.error.errorMessage}
                        theme="default"
                        buttonTheme="link-primary"
                        placeholder={t('Select organizations...')}
                        apiUrl={`${getBaseAPIUrl(
                          ApiVersions.v2,
                        )}/staff/cscx/fields/organizations/`}
                        isSearchable
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Style')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={modes}
                      value={alert.mode.value}
                      setValue={(value) =>
                        setAlert({
                          ...alert,
                          mode: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={alert.mode.error.hasError}
                      errorMessage={alert.mode.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a style...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Start at')}*
                  </Text>
                  <div className="section-group">
                    <div className="form-group">
                      <DatePicker
                        id="startAt"
                        value={alert.start_at.value || undefined}
                        icon="calendar-2-fill"
                        onChange={(date) =>
                          setAlert({
                            ...alert,
                            start_at: {
                              value: date,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={alert.start_at.error.hasError}
                        errorMessage={alert.start_at.error.errorMessage}
                      />
                      <Input
                        type="time"
                        theme="gray"
                        icon="clock-solid"
                        placeholder="HH:MM"
                        value={alert.start_at_time.value?.toString() || ''}
                        onChange={(event) => {
                          const { value } = event.target;
                          setAlert({
                            ...alert,
                            start_at_time: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          });
                        }}
                        hasError={alert.start_at_time.error.hasError}
                        errorMessage={alert.start_at_time.error.errorMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Finish at')}
                  </Text>
                  <div className="section-group">
                    <div className="form-group">
                      <DatePicker
                        id="finishAt"
                        value={alert.finish_at.value || undefined}
                        icon="calendar-2-fill"
                        onChange={(date) =>
                          setAlert({
                            ...alert,
                            finish_at: {
                              value: date,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={alert.finish_at.error.hasError}
                        errorMessage={alert.finish_at.error.errorMessage}
                      />
                      <Input
                        type="time"
                        theme="gray"
                        icon="clock-solid"
                        placeholder="HH:MM"
                        value={alert.finish_at_time.value?.toString() || ''}
                        onChange={(event) => {
                          const { value } = event.target;
                          setAlert({
                            ...alert,
                            finish_at_time: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          });
                        }}
                        hasError={alert.finish_at_time.error.hasError}
                        errorMessage={alert.finish_at_time.error.errorMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Content')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="section-group">
                    <Editor
                      className="text"
                      value={alert.content.value?.toString() || undefined}
                      onChange={(value) => {
                        setAlert({
                          ...alert,
                          content: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      quickbarsInsOptions={'emoticons hr'}
                      limit={10000}
                      characterCountType="focused"
                      hasError={alert.content.error.hasError}
                      errorMessage={alert.content.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StyledAlertForm>
  );
};

export default AlertForm;
