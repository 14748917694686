import styled from 'styled-components';

export const StyledMuralContentResults = styled.div`
  height: calc(100% - 88px);
  width: 100%;

  .main-header {
    .back-logo {
      display: flex;
      align-items: center;

      .button {
        margin-right: 12px;
      }
    }
  }

  .details-content-wrapper {
    width: 100%;
    height: calc(100% - 64px);
    display: flex;
    justify-content: space-between;

    .details-content {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      & > div:first-child {
        flex: 1;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: scroll;
      }
    }
  }
`;

interface StyledRightSideContentProps {
  width: string;
}

export const StyledRightSideContent = styled.div<StyledRightSideContentProps>`
  width: ${({ width }) => width};
  border-left: ${({ width }) =>
    width === '0' ? '1px solid #0000' : '1px solid var(--gray-color)'};
  transition: all 0.5s;
`;
