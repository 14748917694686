import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Select from 'components/molecule/Select';
import Text from 'components/atom/Text';
import Avatar from 'components/molecule/Avatar';
import Textarea from 'components/molecule/Textarea';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';
import UserState from 'store/slices/user/types';
import { OrganizationState } from 'store/slices/organization/types';

import { StyledCommentField } from './styles';

interface CommentFieldProps {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  onSend: (data: { owner: number | null; message: string }) => void;
  disabled: boolean;
  loading: boolean;
  initialProfile: string;
  setProfile: (profile: string) => void;
}

const CommentField: React.FC<CommentFieldProps> = ({
  message,
  setMessage,
  onSend,
  disabled,
  loading,
  initialProfile,
  setProfile,
}) => {
  const { t } = useTranslation();

  const user: UserState = useAppSelector((state) => state.user);
  const organization: OrganizationState = useAppSelector(
    (state) => state.organization,
  );

  const options: readonly SelectOption[] = [
    {
      value: user.name,
      label: (
        <div className="profile-option">
          <Avatar size="34px" src={user.avatar || undefined} />
          <div className="texts">
            <Text color="grayscale-200" as="small">
              {t('Personal profile')}
            </Text>
            <Text className="name">{user.name}</Text>
          </div>
        </div>
      ),
    },
    {
      value: organization.name,
      label: (
        <div className="profile-option">
          <Avatar size="34px" src={organization.avatar || undefined} />
          <div className="texts">
            <Text color="grayscale-200" as="small">
              {t('Organization')}
            </Text>
            <Text className="name">{organization.name}</Text>
          </div>
        </div>
      ),
    },
  ];

  const [selectedProfile, setSelectedProfile] = useState<SelectOption>(
    options.filter((option) => option.value === initialProfile)[0],
  );
  const [textareaId, setTextareaId] = useState<string | null>(
    Date.now().toString(),
  );

  const handleChangeProfile = (option: SingleSelectedOption) => {
    setSelectedProfile(option as SelectOption);
    setProfile(
      option?.value === organization.name ? organization.name : user.name,
    );
  };

  const handleSend = () => {
    onSend({
      owner: selectedProfile.value === organization.name ? null : user.id,
      message,
    });

    // Force textarea re-render
    setTextareaId(null);
    setTimeout(() => {
      setTextareaId(Date.now().toString());
    }, 0);
  };

  return (
    <StyledCommentField>
      <Select
        options={options}
        value={selectedProfile}
        setValue={(option) =>
          handleChangeProfile(option as SingleSelectedOption)
        }
        size="small"
        theme="no-outline"
        isDisabled={disabled}
      />
      <div className="field-wrapper">
        <span>
          <Text as="pre" color="grayscale-200">
            {t('Comment as')}
          </Text>
          <Text as="pre" weight="700" color="grayscale-200">
            {selectedProfile.value}
          </Text>
        </span>
        {textareaId && (
          <Textarea
            id={textareaId}
            theme="gray"
            autoResize
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            placeholder={t('Comment')}
            onKeyDown={(event) => {
              const keyCode = event.key;
              if (keyCode === 'Enter' && !event.shiftKey) handleSend();
            }}
            disabled={disabled}
            autoFocus
          />
        )}
      </div>
      <Button
        theme="primary"
        size="small"
        rounded="true"
        onClick={handleSend}
        disabled={disabled}
      >
        <Icon name={loading ? 'more' : 'send'} color="white-color" />
      </Button>
    </StyledCommentField>
  );
};

export default CommentField;
