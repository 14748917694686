import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';

interface WelcomeStageProps {
  userName: string;
  companyName: string;
}

const WelcomeStage: React.FC<WelcomeStageProps> = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Text as="h2" weight="700" className="mt-2">
        {t('Welcome {{username}}', { username: props.userName })}
      </Text>
      <Text as="h3" weight="700">
        {t('Your organization - {{organization}} - was created!', {
          organization: props.companyName,
        })}
      </Text>
      <Text as="p" weight="400" className="mt-05 description" align="center">
        {t(
          'You can use your credentials to login in Useflow! Check the next step of this page.',
        )}
      </Text>
    </React.Fragment>
  );
};

export default WelcomeStage;
