import styled from 'styled-components';

import background from 'assets/images/background.svg';

export const StyledWizardConfirmAccount = styled.div`
  width: 100%;
  height: 100%;
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  .change-language {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  .card {
    width: 100%;
    max-width: 484px;
    padding: 64px 46px;

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 64px;
    }

    .mt-2 {
      margin-top: 24px;
    }

    .mt-1 {
      margin-top: 14px;
    }

    .image {
      display: block;
      margin: 0 auto;
    }

    .button {
      width: 100%;
      margin-top: 42px;
    }
  }
`;
