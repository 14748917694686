import { tv } from 'tailwind-variants';

export const dropdownVariants = tv({
  base: [
    'rounded-md animate-fade-in-down shadow-lg overflow-hidden z-[var(--z-index-level-6)]',
    '[&_.scwa-item:last-child]:mb-0',
  ],
  variants: {
    theme: {
      white: [
        'bg-gray-color',
        '[&_.scwa-item]:bg-white-color [&_.scwa-item]:text-grayscale-200',
        '[&_.scwa-item[data-highlighted]]:bg-gray-color',
        '[&_.scwa-item[data-disabled]]:text-grayscale-100',
        '[&_.scwa-item[data-disabled]]:my-0',
        '[&_.scwa-dropdown-separator]:bg-white-color',
        '[&_.scwa-dropdown-label]:bg-white-color [&_.scwa-dropdown-label]:text-grayscale-200',
      ],
      dark: [
        'bg-grayscale-300',
        '[&_.scwa-item]:bg-grayscale-400 [&_.scwa-item]:text-grayscale-100',
        '[&_.scwa-item[data-highlighted]]:bg-dark-color',
        '[&_.scwa-item[data-disabled]]:text-grayscale-100 [&_.scwa-item[data-disabled]]:opacity-80',
        '[&_.scwa-item[data-disabled]]:bg-grayscale-300',
        '[&_.scwa-item[data-disabled]]:my-0',
        '[&_.scwa-dropdown-separator]:bg-grayscale-400',
        '[&_.scwa-dropdown-label]:bg-grayscale-400 [&_.scwa-dropdown-label]:text-grayscale-100',
      ],
    },
  },
});
