import styled from 'styled-components';

export const StyledEditorialConfigItem = styled.button`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid var(--grayscale-100);

  &:hover {
    background: var(--gray-color);
  }

  &:disabled {
    cursor: initial;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  .info {
    .editorial-icon {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      margin-right: 10px;

      svg {
        width: 24px;
        height: 24px;
        path {
          fill: var(--white-color);
        }
      }
    }
  }

  .actions {
    .tag {
      margin-right: 8px;
    }

    .icon {
      font-size: 20px;
    }
  }
`;
