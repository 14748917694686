import styled from 'styled-components';

export const StyledBooleanOptions = styled.div`
  .field-section-wrapper {
    & > .text {
      margin-top: 32px;
    }
    margin-bottom: 16px;
  }

  .answer-options-preview-section {
    & > .text {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .answer-options-preview-wrapper {
    width: 100%;
    height: 80px;
    border-radius: 6px;
    background-color: var(--primary-color-100);
  }

  .answer-options-preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    & > div > .text {
      color: var(--primary-color-400);
      margin-bottom: 0px !important;
    }
  }

  .answer-options-preview-item-wrapper {
    & > .text {
      width: 200px;
      word-wrap: break-word;
    }
    & > .text:nth-child(1) {
      text-align: end;
    }
  }

  .answer-options-wrapper {
    margin-top: 32px;
    & > .text {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;
