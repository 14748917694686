import styled from 'styled-components';

export const StyledCheckbox = styled.label`
  &.content-container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .text {
      font-size: 18px;
      padding-top: 3px;
      font-weight: 500;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &:hover input ~ .checkmark {
      background-color: var(--gray-color);
    }

    input:checked ~ .checkmark {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 2.8px;
      background-color: var(--white-color);
      border: 1px solid var(--grayscale-100);
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 5.5px;
      top: 1px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.disabled {
    cursor: initial;
    .checkmark {
      background-color: var(--gray-color);
    }
    input {
      cursor: initial;
    }
    input:checked ~ .checkmark {
      opacity: 40%;
    }
  }
`;
