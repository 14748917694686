import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import EmptyMessage from 'components/molecule/EmptyMessage';
import ChoiceOption from './components/ChoiceOption';

import { ChoiceOptionsProps } from './types';

import { StyledChoiceOptions } from './styles';

const ChoiceOptions: React.FC<ChoiceOptionsProps> = ({
  name,
  setName,
  options,
  setOptions,
}) => {
  const { t } = useTranslation();

  const optionsLimit = 15;

  const handleAddOption = () => {
    setOptions([
      {
        id: undefined,
        name: '',
        initialOpen: true,
        inEdit: true,
      },
      ...options,
    ]);
  };

  const handleSave = (value: string, index: number) => {
    setOptions(() => {
      const currentOptions = [...options];
      currentOptions[index].name = value;
      currentOptions[index].initialOpen = false;
      currentOptions[index].inEdit = false;
      currentOptions[index].id = undefined;
      return currentOptions;
    });
  };

  const handleEdit = (index: number) => {
    setOptions(() => {
      const currentOptions = [...options];
      currentOptions[index].inEdit = true;
      return currentOptions;
    });
  };

  const handleRemoveOption = (index: number) => {
    const currentOptions = [...options];
    currentOptions.splice(index, 1);
    setOptions(currentOptions);
  };

  return (
    <StyledChoiceOptions>
      <div className="field-section-wrapper">
        <Text as="h6">{t('Field name')}</Text>
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={t('Enter the title')}
          limit={100}
        />
      </div>
      <Separator />
      <div className="choice-header">
        <Text as="h6">{t('Options')}</Text>
        <Button
          theme="link-primary"
          leftIcon="add"
          onClick={handleAddOption}
          disabled={options.some(
            (option) =>
              option.initialOpen ||
              option.inEdit ||
              options.length >= optionsLimit,
          )}
        >
          {t('Add option')}
        </Button>
      </div>
      <div className="option-list">
        {options.map((option, index) => (
          <ChoiceOption
            index={index}
            key={index}
            name={option.name}
            onSave={handleSave}
            onRemove={handleRemoveOption}
            onEdit={handleEdit}
            initialOpen={option.initialOpen}
            disabled={
              options.some((option) => option.initialOpen || option.inEdit) ||
              !!option.id
            }
          />
        ))}
      </div>
      {options.length === 0 && (
        <div className="no-options-message">
          <EmptyMessage
            title={t('No options')}
            description={t(
              'Add items that will be chosen when selecting the field with dropdown',
            )}
          />
        </div>
      )}
    </StyledChoiceOptions>
  );
};

export default ChoiceOptions;
