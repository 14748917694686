import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PaginationControl from 'components/molecule/PaginationControl';
import Loading from 'components/molecule/Loading';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Icon from 'components/atom/Icon';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';

import { TableProps } from './types';

import { StyledTable } from './styles';

const Table: React.FC<TableProps> = ({
  columns,
  setColumns = () => undefined,
  rows,
  textAlign = 'start',
  clickable,
  scrollable,
  fontColor = 'grayscale-200',
  hasPagination = false,
  pageCount = 1,
  page,
  onChangePage = undefined,
  loading = false,
  emptyMessageTitle,
  emptyMessageDescription,
  hideEmptyMessage,
}) => {
  const { t } = useTranslation();

  const [searchTimeOut, setSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const getCurrentSortableIcon = (columnIndex: number) => {
    if (!columns[columnIndex] || !columns[columnIndex].sortable) {
      return '';
    }

    const sortableConfig = columns[columnIndex].sortable;

    if (sortableConfig?.value === 0) {
      return 'unfold-more';
    }

    if (sortableConfig?.value === -1) {
      return 'arrow-down-s-line';
    }

    return 'arrow-up-s-line';
  };

  const handleSortableClick = (columnIndex: number) => {
    if (!columns[columnIndex] || !columns[columnIndex].sortable) {
      return;
    }

    const sortableConfig = columns[columnIndex].sortable;

    if (sortableConfig?.value === undefined) {
      return;
    }

    sortableConfig.value =
      sortableConfig?.value === 1 ? -1 : sortableConfig.value + 1;
    columns[columnIndex].sortable = { ...sortableConfig };

    setColumns([...columns]);

    if (sortableConfig?.sortableOnClick) {
      sortableConfig.sortableOnClick({
        key: sortableConfig.key,
        value: sortableConfig.value,
      });
    }
  };

  const handleSearchChange = (
    columnIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    if (!columns[columnIndex] || !columns[columnIndex].searchable) {
      return;
    }

    const searchableConfig = columns[columnIndex].searchable;

    if (!searchableConfig) return;

    searchableConfig.value = event.target.value;

    const timeOut = setTimeout(() => {
      const columnsWithSearchable = columns.filter(
        (column) => column.searchable !== undefined,
      );
      const values = columnsWithSearchable.map((column) => {
        if (!column.searchable) return { key: '', value: '' };
        return {
          key: column.searchable.key,
          value: column.searchable.value,
        };
      });

      searchableConfig.onSearch(values);
    }, 1000);

    setColumns([...columns]);
    setSearchTimeOut(timeOut);
  };

  return (
    <StyledTable
      className="table"
      textAlign={textAlign}
      scrollable={scrollable}
      fontColor={fontColor}
    >
      <div className="table-wrapper default-scroll">
        <table className={clickable ? 'clickable' : ''}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <div className="column-content">
                    {column.searchable && (
                      <Input
                        icon="search"
                        iconColor="primary-color"
                        placeholder={column.searchable.placeholder}
                        value={column.searchable.value}
                        onChange={(event) => handleSearchChange(index, event)}
                        theme="post"
                      />
                    )}
                    {!column.searchable && column.content}
                    {column.sortable && (
                      <Button
                        theme="link-dark"
                        size="small"
                        onClick={() => handleSortableClick(index)}
                      >
                        <Icon
                          name={getCurrentSortableIcon(index)}
                          color="primary-color"
                        />
                      </Button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} onClick={clickable ? row.onClick : undefined}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    onClick={cell.clickable ? cell.onClick : () => undefined}
                    className={
                      cell.clickable && cell.onClick ? 'clickable' : ''
                    }
                  >
                    {cell.content}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {rows.length === 0 && !hideEmptyMessage && (
          <div className="empty-message-wrapper">
            <EmptyMessage
              title={
                emptyMessageTitle ? emptyMessageTitle : t('No record found')
              }
              description={
                emptyMessageDescription
                  ? emptyMessageDescription
                  : t('Change the words in the search bar and try again.')
              }
              icon="stack-line"
            />
          </div>
        )}
      </div>

      {rows.length !== 0 && hasPagination && (
        <PaginationControl
          pageCount={pageCount}
          page={page}
          onChangePage={onChangePage ? onChangePage : () => undefined}
        />
      )}

      {loading && <Loading className="table-loading" />}
    </StyledTable>
  );
};

export default Table;
