import styled from 'styled-components';

export const StyledImageCrop = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 108px;

  .crop-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .ReactCrop {
      width: 100%;
      margin-bottom: 24px;

      img {
        width: 100%;
      }
    }

    .crop-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .button {
        margin-left: 16px;
      }

      .left-side {
        .button {
          margin-left: 0px;
        }
      }

      .right-side {
        display: flex;
      }
    }

    .image-crop-loading {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      min-height: 108px;

      display: flex;
      align-items: center;

      background: linear-gradient(
        30deg,
        rgba(255, 255, 255, 0.6),
        rgba(210, 209, 255, 0.6) 80%
      );
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
    }
  }

  .progress-bar {
    margin-bottom: 24px;
  }
`;

export const StyledRemoveCoverConfirm = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h5 {
      margin-bottom: 6px;
    }
  }

  .modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
