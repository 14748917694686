import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { setTheme } from 'store/slices/theme';
import { showMessage } from 'store/slices/toaster';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ApiErrorItem, FieldErrors } from './types';

import { SingleSelectedOption } from 'components/molecule/Select/types';

import { updatePaletteColor } from 'apis/visualIdentity';

const useVisualIdentity = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [saveLoading, setSaveLoading] = useState(false);

  const [paletteSelectOption, setPaletteSelectOption] =
    useState<SingleSelectedOption>(null);

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    palette_color: {
      hasError: false,
      errorMessage: '',
    },
  });

  const fetchUpdateVisualIdentity = useCallback(() => {
    updatePaletteColor(organizationId, paletteSelectOption?.value)
      .then((response) => {
        const { color_scheme } = response.data;
        dispatch(setTheme(color_scheme));

        dispatch(
          showMessage({
            title: t('Color palette updated successfully'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
      })
      .catch((error) => {
        if (error.response) {
          const { errors, code } = error.response.data;
          const newFieldErrors = { ...fieldErrors };

          if (code === 'field_error') {
            errors.forEach((errorItem: ApiErrorItem) => {
              newFieldErrors[errorItem.field as keyof FieldErrors].hasError =
                true;
              newFieldErrors[
                errorItem.field as keyof FieldErrors
              ].errorMessage = errorItem.error;
            });

            setFieldErrors(newFieldErrors);
          }
        }

        dispatch(
          showMessage({
            title: t('An unexpected error occurred while saving color schema'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => setSaveLoading(false));
  }, [organizationId, paletteSelectOption?.value, dispatch, t, fieldErrors]);

  const handleSave = () => {
    setSaveLoading(true);
    fetchUpdateVisualIdentity();
  };

  return {
    t,
    navigate,
    handleSave,
    saveLoading,
    fieldErrors,
    setFieldErrors,
    paletteSelectOption,
    setPaletteSelectOption,
  };
};

export default useVisualIdentity;
