import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultClientName, getDirectLegacyAdminURL } from 'settings';

import { useAppSelector } from 'store/hooks';

import ClientState from 'store/slices/client/types';

import { signIn } from 'apis/auth';

import { login } from 'services/auth';

import Card from 'components/atom/Card';
import Logo from 'components/molecule/Logo';
import Tag from 'components/atom/Tag';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
// import Checkbox from 'components/molecule/Checkbox';
// import Tooltip from 'components/atom/Tooltip';
// import Text from 'components/atom/Text';
import Image from 'components/molecule/Image';
import Toaster from 'components/organism/Toaster';
import PageLoading from 'components/molecule/PageLoading';
import ChangeLanguage from 'components/molecule/ChangeLanguage';

import { ApiErrorItem, FieldErrors } from './types';

import { StyledLogin } from './styles';

const Login: React.FC = () => {
  const { t } = useTranslation();

  const client: ClientState = useAppSelector((state) => state.client);

  const [clientField, setClientField] = useState(client.slug);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showClientField, setShowClientField] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    client: {
      hasError: false,
      errorMessage: '',
    },
    username: {
      hasError: false,
      errorMessage: '',
    },
    password: {
      hasError: false,
      errorMessage: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    theme: 'danger',
    icon: 'close',
    time: 10000,
    customLeft: '0px',
    show: false,
  });

  const clearErrorField = (field: string) => {
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[field as keyof FieldErrors].hasError = false;
    newFieldErrors[field as keyof FieldErrors].errorMessage = '';
    setFieldErrors(newFieldErrors);
  };

  const handleClientField = (value: string) => {
    const fieldName = 'client';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setClientField(value);
  };

  const handleUsernameField = (value: string) => {
    const fieldName = 'username';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setUsername(value);
  };

  const handlePasswordField = (value: string) => {
    const fieldName = 'password';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setPassword(value);
  };

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    signIn({
      client: clientField,
      username,
      password,
    })
      .then((response) => {
        const { access, refresh } = response.data;
        login(access, refresh, clientField);
        window.location.href = '/dashboard';
      })
      .catch((error) => {
        if (error.response) {
          const { errors, code } = error.response.data;
          const newFieldErrors = { ...fieldErrors };

          if (code === 'field_error') {
            errors.forEach((errorItem: ApiErrorItem) => {
              newFieldErrors[errorItem.field as keyof FieldErrors].hasError =
                true;
              newFieldErrors[
                errorItem.field as keyof FieldErrors
              ].errorMessage = errorItem.error;
            });

            setFieldErrors(newFieldErrors);
            return;
          }

          setErrorMessage({
            title: errors,
            theme: 'danger',
            icon: 'close',
            time: 10000,
            customLeft: '0px',
            show: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setShowClientField(
      !client.slug ||
        client.slug.toUpperCase() === defaultClientName.toUpperCase(),
    );
  }, [client.slug]);

  return (
    <StyledLogin className="login-page">
      <Toaster
        show={errorMessage.show.toString()}
        time={errorMessage.time}
        icon="close"
        title={errorMessage.title}
        theme={errorMessage.theme}
        customLeft={errorMessage.customLeft}
        onClose={() => setErrorMessage({ ...errorMessage, show: false })}
      />

      {loading && <PageLoading />}

      <ChangeLanguage />

      <Card noBorder="true">
        <div className="logo-wrapper">
          {client.logo ? <Image src={client.logo} /> : <Logo size="large" />}
          <Tag theme="warning-100" radius="6px">
            {t('Admin')}
          </Tag>
        </div>
        <form className="form-wrapper" onSubmit={handleSignin}>
          {showClientField && (
            <Input
              icon="home-6-line"
              label={t('Company')}
              id="company"
              placeholder={t('Your company')}
              className="company"
              value={clientField}
              onChange={(event) => handleClientField(event.target.value)}
              hasError={fieldErrors.client.hasError}
              errorMessage={fieldErrors.client.errorMessage}
            />
          )}
          <Input
            icon="user-line"
            label={t('LoginUsername')}
            id="login"
            placeholder={t('Your username')}
            value={username}
            onChange={(event) => handleUsernameField(event.target.value)}
            hasError={fieldErrors.username.hasError}
            errorMessage={fieldErrors.username.errorMessage}
          />
          <Input
            icon="lock-line"
            label={t('Password')}
            id="password"
            placeholder={t('Your password')}
            value={password}
            onChange={(event) => handlePasswordField(event.target.value)}
            actions={
              <Button
                theme="link-primary"
                size="small"
                onClick={() => setShowPassword(!showPassword)}
              >
                <Icon name={showPassword ? 'eye-off-fill' : 'eye-fill'} />
              </Button>
            }
            type={showPassword ? 'text' : 'password'}
            hasError={fieldErrors.password.hasError}
            errorMessage={fieldErrors.password.errorMessage}
          />
          {/* <div className="check-wrapper">
            <Checkbox text={t('Remember me')} />
            <Tooltip
              tooltipId="check-information"
              message={t(
                'Company information, login and password will be saved for the next access',
              )}
            >
              <Icon name="information-fill" color="grayscale-200" />
            </Tooltip>
          </div> */}

          <div className="button-actions">
            <Button
              theme="primary"
              className="login-button"
              size="big"
              type="submit"
            >
              {t('Login')}
            </Button>
            <Button
              theme="dark-outline-primary"
              size="big"
              href={getDirectLegacyAdminURL('password-reset/')}
            >
              {t('Recover password')}
            </Button>
            {/* <div className="not-account-text">
              <Text color="grayscale-300">
                {t('Do not have an account yet?')}
              </Text>
              <Button
               className="register-button"
               theme="link-primary"
               size="small"
             >
               {t('Register')}
             </Button>
            </div> */}
          </div>
        </form>
      </Card>
    </StyledLogin>
  );
};

export default Login;
