import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

import {
  getEditorialIcons,
  createEditorialLine,
  updateEditorialLine,
} from 'apis/config';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';
import { setPageLoading } from 'store/slices/pageLoading';

import {
  setLastEditorialColorSelected,
  getLastEditorialColorSelected,
} from 'services/editorial';

import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';
import ColorSelector from '../ColorSelector';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import EmptyMessage from 'components/molecule/EmptyMessage';
import SwitchButton from 'components/atom/SwitchButton';

import { EditorialIcon } from 'apis/config/types';
import { EditorialConfigItemData } from '../../types';

import { StyledEditorialDetails, StyledEditorialPreview } from './styles';

interface EditorialDetailsProps {
  editorial?: EditorialConfigItemData;
  onSave: () => void;
}

const EditorialDetails: React.FC<EditorialDetailsProps> = ({
  editorial,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const primaryColor = useAppSelector((state) => state.theme.primary_color_200);

  const initialColor = getLastEditorialColorSelected() || primaryColor;

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [title, setTitle] = useState(editorial?.title || '');
  const [isDefault, setIsDefault] = useState(editorial?.default || false);
  const [selectedColor, setSelectedColor] = useState(
    editorial?.color || initialColor,
  );
  const [icons, setIcons] = useState<EditorialIcon[]>([]);

  const getInitialEditorialIcon = () => {
    if (!editorial) return null;
    return {
      pk: editorial.icon_editorial,
      data_icon: editorial.data_icon,
    };
  };

  const [selectedIcon, setSelectedIcon] = useState<EditorialIcon | null>(
    getInitialEditorialIcon(),
  );
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const createEditorial = () => {
    dispatch(setPageLoading(true));

    createEditorialLine(organizationId, {
      color: selectedColor,
      default: isDefault,
      icon_editorial: selectedIcon?.pk,
      title,
    })
      .then(() => {
        onSave();
        setLastEditorialColorSelected(selectedColor);
        dispatch(
          setModalView({
            show: false,
          }),
        );
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;
        if (code === 'field_error') {
          dispatch(
            showMessage({
              title: errors[0].error,
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        } else if (code === 'invalid' || code.indexOf('invalid') !== -1) {
          dispatch(
            showMessage({
              title: errors[0],
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while saving the editorial line',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const updateEditorial = (id: string) => {
    dispatch(setPageLoading(true));

    updateEditorialLine(organizationId, id, {
      color: selectedColor,
      default: isDefault,
      icon_editorial: selectedIcon?.pk,
      title,
    })
      .then(() => {
        onSave();
        setLastEditorialColorSelected(selectedColor);
        dispatch(
          setModalView({
            show: false,
          }),
        );
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;
        if (code === 'field_error') {
          dispatch(
            showMessage({
              title: errors[0].error,
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while saving the editorial line',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSave = () => {
    if (editorial) {
      updateEditorial(editorial.id);
      return;
    }
    createEditorial();
  };

  const loadEditorialIcons = useCallback(
    (page: number) => {
      getEditorialIcons(organizationId, page)
        .then((response) => {
          const { results, next } = response.data;

          setHasNextPage(!!next);
          setPage(page);

          if (page === 1) {
            setIcons(results);
            if (!editorial) {
              setSelectedIcon(results[0]);
            }
            return;
          }

          setIcons((lastIcons) => [...lastIcons, ...results]);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An error occurred while fetching the editorial line icons',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadMoreLoading(false);
        });
    },
    [organizationId, dispatch, t, editorial],
  );

  useEffect(() => {
    loadEditorialIcons(1);
  }, [loadEditorialIcons]);

  return (
    <StyledEditorialDetails>
      <div className="modal-content">
        <Text as="h4" weight="700">
          {t('Customize editorial line')}
        </Text>
        <Text as="h6" color="grayscale-200">
          {t(
            'Editorial lines are classifications of published content. After saving the below version will replace default color and icon.',
          )}
        </Text>

        {!loading && icons.length > 0 && (
          <Input
            placeholder={t('Enter the title')}
            theme="post"
            limit={25}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        )}

        {!loading && icons.length > 0 && (
          <ColorSelector color={selectedColor} setColor={setSelectedColor} />
        )}

        {!loading && icons.length > 0 && selectedIcon && (
          <StyledEditorialPreview
            className="editorial-preview"
            color={selectedColor}
          >
            <div className="icon-wrapper">
              {HTMLReactParser(selectedIcon.data_icon)}
            </div>
          </StyledEditorialPreview>
        )}

        <div
          className="icon-list-wrapper default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          <div className="icon-list">
            {icons.map((icon, index) => (
              <Button
                size="small"
                key={index}
                theme="link-dark"
                onClick={() => setSelectedIcon(icon)}
              >
                {HTMLReactParser(icon.data_icon)}
              </Button>
            ))}
          </div>
          {loadMoreLoading && <Loading type="bubbles" />}
        </div>

        {loading && <Loading type="bubbles" />}

        {!loading && icons.length === 0 && (
          <EmptyMessage title={t('No icon found')} icon="stack-line" />
        )}

        {!loading && icons.length > 0 && (
          <div className="default-check">
            <SwitchButton
              id="is-default"
              checked={isDefault}
              onChange={(event) => setIsDefault(event.target.checked)}
            />
            <Text as="h6">{t('Set as default')}</Text>
          </div>
        )}
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          theme="dark"
          onClick={handleSave}
          disabled={(!loading && icons.length === 0) || title.length === 0}
        >
          {t('Save')}
        </Button>
      </div>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadMoreLoading(true);
          loadEditorialIcons(page + 1);
        }}
        disabled={loading || !hasNextPage}
      />
    </StyledEditorialDetails>
  );
};

export default EditorialDetails;
