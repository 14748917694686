import styled from 'styled-components';

interface StyledFooterProps {
  background: string;
  showResetButton: boolean;
}

export const StyledFooter = styled.footer.attrs(() => ({
  className: 'footer',
}))<StyledFooterProps>`
  background: var(--${(props) => props.background});
  padding: 20px 24px;
  height: 72px;
  box-sizing: border-box;

  .footer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remove-interactions {
    display: none;
    width: 100%;
    padding: 8px 0px 8px 0px;
    height: max-content;
    min-height: max-content;

    i#button-left-icon {
      padding-right: 4px;
    }

    .button-content {
      font-size: 14px;
      min-width: max-content;
      padding: 0px 4px;
    }
  }

  &:hover {
    .footer-wrapper {
      display: ${({ showResetButton }) => (showResetButton ? 'none' : 'flex')};
    }
    .remove-interactions {
      display: ${({ showResetButton }) => (showResetButton ? 'flex' : 'none')};
    }
  }

  small,
  i {
    color: var(--white-color);
    opacity: 0.75;
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 10px;
    border-radius: 6px;
    background: var(--grayscale-300);
    margin-right: 12px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }
`;
