import { AvailableTypes } from 'apis/board/types';

import { SearchResult } from 'components/organism/Search/types';

export interface BoardContentProps {
  id: string;
  content_id: string;
  type: string;
  subtype: string;
  cover: string | null;
  title: string;
  tags: number;
  comments: number;
  interactions: number;
  owner: string;
  start_at: string;
  finish_at: string;
  fixed: boolean;
  fixed_by: string | null;
  fixed_start_at: string | null;
  fixed_finish_at: string | null;
  status: string;
  url: string | null;
  options: number;
  can_edit: boolean;
  is_archived: boolean;
}

export enum BoardContentTypesEnum {
  QuickSurvey = 'survey',
}

export interface MuralFiltersState {
  page: number;
  search: string;
  type: keyof typeof AvailableTypes;
  order: string[];
}

export interface CustomFilterAttrsTypes {
  publication: string[];
  survey: string[];
  checkin: SearchResult[];
  status: string[];
  start_at_period_begin: string | null;
  start_at_period_end: string | null;
  finish_at_period_begin: string | null;
  finish_at_period_end: string | null;
  tag: SearchResult[];
  can_comment: boolean | null;
  comments_operator: string | null;
  comments_count: number | null;
  owner: SearchResult[];
  editorial: string[];
}

export interface CustomFilterSendAttrs {
  publication: string[];
  survey: string[];
  checkin: string[];
  status: string[];
  start_at_period_begin: string | null;
  start_at_period_end: string | null;
  finish_at_period_begin: string | null;
  finish_at_period_end: string | null;
  tag: string[];
  can_comment: boolean | null;
  comments_operator: string | null;
  comments_count: number | null;
  owner: string[];
  editorial: string[];
}
