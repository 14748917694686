import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import SwitchButton from 'components/atom/SwitchButton';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';

import { TextOptionsProps } from './types';
import { StyledTextOptions } from './styles';

const TextOptions: React.FC<TextOptionsProps> = ({
  config,
  name,
  setName,
  item,
  setItem,
}) => {
  const { t } = useTranslation();

  const integerNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const allowedCharacters = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ];
    if (!allowedCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <StyledTextOptions>
      <div className="field-section-wrapper">
        <div className="field-name">
          <Text as="h6">{t('Field name')}</Text>
          <Input
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder={t('Enter the title')}
            limit={100}
          />
        </div>
        <div className="field-max-chars">
          <Text as="h6">{t('Max length')}</Text>
          <Input
            value={item.max_length === null ? '' : item.max_length}
            onChange={(event) => {
              setItem((prevItem) => ({
                ...prevItem,
                max_length:
                  event.target.value === '' ? null : Number(event.target.value),
                max_length_invalid_msg: '',
              }));
            }}
            type="number"
            placeholder={
              config?.max_length ? config.max_length.toString() : '1000'
            }
            onKeyDown={integerNumberKeyDown}
            hasError={item.max_length_invalid_msg !== ''}
            errorMessage={item.max_length_invalid_msg}
            disabled={!!item.id}
          />
        </div>
      </div>
      <Separator />
      <div className="field-preview-wrapper">
        <div className="field-section-wrapper">
          <div className="multiline-label-wrapper">
            <Text as="h6">{t('Multiple line')}</Text>
            {item.is_multiline && (
              <div>
                <Tooltip
                  id="multiple-line-tooltip"
                  content={t(
                    'It is still not possible to segment content by multi-line custom fields.',
                  )}
                  place="top"
                >
                  <Icon name="information-line" color="grayscale-200" />
                </Tooltip>
              </div>
            )}
          </div>

          <SwitchButton
            id="multiple-line"
            checked={item.is_multiline}
            onChange={(event) => {
              setItem((prevItem) => ({
                ...prevItem,
                is_multiline: event.target.checked,
              }));
            }}
            disabled={!!item.id}
          />
        </div>
        {!item.is_multiline ? (
          <Input
            disabled={true}
            value={t('Users will be able to answer on a space like this')}
          />
        ) : (
          <Textarea
            disabled={true}
            value={t('Users will\nbe able to answer\non a space like this')}
          />
        )}
      </div>
    </StyledTextOptions>
  );
};

export default TextOptions;
