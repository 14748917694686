import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { cn } from 'utils/cn';

import Tag from 'components/atom/Tag';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { DropzoneProps } from './types';

import { StyledDropzone } from './styles';

const Dropzone: React.FC<DropzoneProps> = ({
  dragOnly,
  children,
  className = '',
  onDrop,
  onDragLeave,
  onDragEnter,
}) => {
  const { t } = useTranslation();

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      onDragEnter?.(event);
    },
    [onDragEnter],
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      onDragLeave?.(event);
    },
    [onDragLeave],
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop?.(acceptedFiles);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
  });

  return (
    <StyledDropzone
      {...getRootProps()}
      className={cn(
        'dropzone w-full border-2 border-dashed border-grayscale-100 rounded',
        'min-h-[284px] flex justify-center items-center bg-white-color',
        { 'border-solid bg-grayscale-100': isDragActive },
        className,
      )}
    >
      <input {...getInputProps()} />
      <div className="drop-area flex flex-col justify-center items-center gap-2">
        <Tag radius="12px" theme={isDragActive ? 'primary' : 'gray-100'}>
          <Icon
            name="upload-cloud"
            color={isDragActive ? 'white-color' : 'dark-color'}
          />
        </Tag>
        <Text as="h6" weight="700" className="send-your-file-label">
          {dragOnly ? t('Drop your file here') : t('Send your file')}
        </Text>
        {children}
        {!dragOnly && (
          <div className="flex items-center gap-1">
            <Text color="primary-color">{t('Search on computer')}</Text>
            <Text color="grayscale-200">{t('or drag to this area')}</Text>
          </div>
        )}
      </div>
    </StyledDropzone>
  );
};

export default Dropzone;
