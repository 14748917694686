import styled from 'styled-components';

export const StyledMuralConfigMenu = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .tab-menu {
    background: none;
    padding: 0px;

    flex-direction: column;

    .button {
      border-radius: 0px;
      width: 100%;
      padding: 0px;
      text-align: start;
      justify-content: flex-start;
      height: 60px;

      .button-content {
        text-align: start;
      }

      &:hover {
        background: none;
        & * {
          color: var(--dark-color);
        }
      }
    }

    .button {
      &.active {
        background: var(--grayscale-100);
        box-shadow: none;
        border-right: 1px solid var(--dark-color);
        cursor: pointer;
      }
    }
  }
`;
