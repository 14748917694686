import React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

import Text from 'components/atom/Text';
import Tag from 'components/atom/Tag';
import Icon from 'components/atom/Icon';

import { StyledEditorialConfigItem } from './styles';

interface EditorialConfigItemProps {
  id: string;
  title: string;
  icon: string;
  isDefault?: boolean;
  color: string;
  onClick: (id: string) => void;
  disabled?: boolean;
}

const EditorialConfigItem: React.FC<EditorialConfigItemProps> = ({
  id,
  title,
  icon,
  isDefault = false,
  color,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledEditorialConfigItem onClick={() => onClick(id)} disabled={disabled}>
      <div className="info">
        <div className="editorial-icon" style={{ background: color }}>
          {HTMLReactParser(icon)}
        </div>
        <Text>{title}</Text>
      </div>
      <div className="actions">
        {isDefault && <Tag theme="gray-100">{t('Default')}</Tag>}
        {!disabled && <Icon name="arrow-right-s-line" color="grayscale-200" />}
      </div>
    </StyledEditorialConfigItem>
  );
};

export default EditorialConfigItem;
