import { createSlice } from '@reduxjs/toolkit';

import ContentResultsState from './types';

const initialState: ContentResultsState = {
  has_custom_content: false,
  content_title: '',
  title_suggestion: '',
};

export const contentResultsSlice = createSlice({
  name: 'contentResults',
  initialState,
  reducers: {
    setHasCustomContent: (state, action) => {
      state.has_custom_content = action.payload;
    },
    setContentTitle: (state, action) => {
      state.content_title = action.payload;
    },
    setContentTitleSuggestion: (state, action) => {
      state.title_suggestion = action.payload;
    },
  },
});

export const {
  setHasCustomContent,
  setContentTitle,
  setContentTitleSuggestion,
} = contentResultsSlice.actions;

export default contentResultsSlice.reducer;
