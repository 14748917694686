export const getIcon = (type: string) => {
  const editableDoc = ['.doc', '.docx', '.txt', '.odt', '.pages'];
  const spreadsheets = ['.xls', '.xlsx', '.xlsm', '.ods', '.numbers'];
  const presentations = ['.ppt', '.pptx', '.odp', '.key'];
  const nonEditableDoc = ['.epub'];
  const pdf = ['.pdf'];
  const audios = ['.mp3', '.wav', '.wma', '.aif', '.ogg'];
  const videos = ['.mp4', '.mkv', '.avi', '.mpg', '.mpeg', '.mov'];
  const images = ['.png', '.jpeg', '.gif', '.tiff', '.bmp', '.jpg'];

  if (type === 'folder') {
    return 'folder-fill';
  } else if (editableDoc.includes(type)) {
    return 'file-edit-line';
  } else if (spreadsheets.includes(type)) {
    return 'table';
  } else if (presentations.includes(type)) {
    return 'slide';
  } else if (nonEditableDoc.includes(type)) {
    return 'file-strange';
  } else if (pdf.includes(type)) {
    return 'pdf';
  } else if (audios.includes(type)) {
    return 'sound';
  } else if (videos.includes(type)) {
    return 'play_circle';
  } else if (images.includes(type)) {
    return 'image-fill';
  } else {
    return 'file';
  }
};
