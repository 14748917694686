import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';

import { StyldContentCommentsHeader } from './styles';

const ContentCommentsHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyldContentCommentsHeader>
      <Text as="h3" weight="700">
        {t('Comments')}
      </Text>
      <div className="actions"></div>
    </StyldContentCommentsHeader>
  );
};

export default ContentCommentsHeader;
