import React, {
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import FilesTableInfo from './components/FilesTableInfo';
import FilesTableCreatedBy from './components/FilesTableCreatedBy';
import FilesTableSize from './components/FilesTableSize';
import FilesTableCreatedAt from './components/FilesTableCreatedAt';
import FilesTableActions from './components/FilesTableActions';
import Text from 'components/atom/Text';

import {
  ColumnProps,
  RowProps,
  SortableValueState,
} from 'components/organism/Table/types';
import { FileContentProps, FileFilter } from './types';
import { useSearchParams } from 'react-router-dom';

const useFilesTable = ({
  files,
  filter,
  setFilter,
  handleNavigate,
  onFetchFiles,
}: {
  handleNavigate: (path: string) => void;
  filter: FileFilter;
  files: FileContentProps[];
  setFilter: Dispatch<SetStateAction<FileFilter>>;
  onFetchFiles: (filters: {
    page: number;
    search: string;
    ordering: string;
    size: string;
    size_order: string;
    created_at_period_from: string;
    created_at_period_to: string;
    types: string[];
  }) => void;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const orgId = searchParams.get('orgId');
  const lang = searchParams.get('lang');
  const isMobile = searchParams.get('mobile') === 'true';

  const [fileTableRows, setFileTableRows] = useState<RowProps[]>([]);

  const generateFileTableRows = useCallback(
    (data: FileContentProps[]) => {
      return data.map((content, index) => {
        const {
          id,
          extension,
          file_app_url,
          url_view,
          name,
          owner,
          created_at,
          size_format,
          is_shared,
          is_folder,
        } = content;

        return {
          cells: [
            {
              content: (
                <FilesTableInfo
                  index={index}
                  name={name}
                  type={is_folder ? 'folder' : extension}
                />
              ),
              clickable: is_folder,
              onClick: () => {
                handleNavigate(
                  `/drive/${id}?token=${token}&orgId=${orgId}&lang=${lang}&mobile=${isMobile}`,
                );
              },
            },
            {
              content: <FilesTableCreatedBy index={index} owner={owner} />,
            },
            {
              content: <FilesTableSize size={size_format} />,
            },
            {
              content: <FilesTableCreatedAt period={created_at} />,
            },
            // {
            //   content: <FilesTableShared isShared={is_shared} />,
            // },
            {
              content: (
                <FilesTableActions
                  fileLink={file_app_url}
                  isShared={is_shared}
                  viewFile={url_view}
                  fileName={name}
                  isMobile={isMobile}
                />
              ),
            },
          ],
        };
      });
    },
    [isMobile, handleNavigate, token, orgId, lang],
  );

  const handleOrder = useCallback(
    (sortableValue: SortableValueState) => {
      setFilter((updatedFiltersState) => {
        let elementIndex = -1;
        const {
          page,
          search,
          ordering,
          created_at_period_from,
          created_at_period_to,
          size,
          size_order,
          types,
        } = updatedFiltersState;

        if (sortableValue.value === 0) {
          elementIndex = ordering.indexOf(`-${sortableValue.key}`);
          ordering.splice(elementIndex, elementIndex === -1 ? 0 : 1);
        }

        if (sortableValue.value === 1) {
          elementIndex = ordering.indexOf(sortableValue.key);
          ordering.splice(elementIndex, elementIndex === -1 ? 0 : 1);
          ordering.push(sortableValue.key);
        }

        if (sortableValue.value === -1) {
          elementIndex = ordering.indexOf(sortableValue.key);
          if (elementIndex !== -1) {
            ordering[elementIndex] = `-${sortableValue.key}`;
          }
        }

        onFetchFiles({
          page,
          search,
          ordering: ordering.toString(),
          created_at_period_from: created_at_period_from || '',
          created_at_period_to: created_at_period_to || '',
          size: size || '',
          size_order,
          types,
        });

        updatedFiltersState.ordering = ordering;
        return updatedFiltersState;
      });
    },
    [onFetchFiles, setFilter],
  );

  const handleChangePage = (currentPage: number) => {
    const newFilter = {
      ...filter,
      page: currentPage + 1,
    };
    setFilter(newFilter);
    onFetchFiles({
      ...filter,
      page: currentPage + 1,
      ordering: filter.ordering.toString(),
      created_at_period_from: filter.created_at_period_from || '',
      created_at_period_to: filter.created_at_period_to || '',
      size: filter.size || '',
      types: filter.types,
    });
  };

  const [columns, setColumns] = useState<ColumnProps[]>([]);

  const getSortableValue = useCallback(
    (key: string) => {
      const { ordering } = filter;
      const includeKey = ordering.includes(key);
      const includeNegativeKey = ordering.includes(`-${key}`);

      if (includeKey) return 1;
      if (includeNegativeKey) return -1;

      return 0;
    },
    [filter],
  );

  useEffect(() => {
    setColumns(() => [
      {
        content: <Text color="grayscale-200">{t('Name')}</Text>,
        sortable: {
          key: 'name',
          sortableOnClick: handleOrder,
          value: getSortableValue('name'),
        },
      },
      {
        content: <Text color="grayscale-200">{t('Created by')}</Text>,
      },
      {
        content: <Text color="grayscale-200">{t('Size')}</Text>,
        sortable: {
          key: 'size',
          sortableOnClick: handleOrder,
          value: getSortableValue('size'),
        },
      },
      {
        content: <Text color="grayscale-200">{t('Created at')}</Text>,
        sortable: {
          key: 'created_at',
          sortableOnClick: handleOrder,
          value: getSortableValue('created_at'),
        },
      },
      {
        content: <Text color="grayscale-200"></Text>,
      },
    ]);
  }, [getSortableValue, handleOrder, t]);

  useEffect(() => {
    if (files) {
      setFileTableRows(() => generateFileTableRows(files));
    }
  }, [files, generateFileTableRows]);

  return {
    columns,
    setColumns,
    handleChangePage,
    fileTableRows,
  };
};

export default useFilesTable;
