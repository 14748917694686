import styled from 'styled-components';

export const StyledSurveyList = styled.div`
  .sort-selector {
    .react-select__control {
      .react-select__indicator {
        display: none;
      }
    }

    .react-select__value-container {
      .sort-list-item {
        padding: 0px;
      }
    }

    .react-select .react-select__control:hover {
      border-color: var(--primary-color);
    }
  }

  .pagination-control {
    margin-top: 56px;
    padding-bottom: 56px;

    justify-content: center;
  }
  .filters {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
      width: 100%;
      max-width: 200px;

      .react-select__control {
        padding-left: 0px;
      }
    }

    .input {
      .input-content {
        padding-right: 6px;
        height: 56px;
      }

      .custom-filter-bottom {
        .counter-label {
          width: 16px;
          min-width: 16px;
          height: 16px;
          top: -8px;
          right: -8px;
        }
      }
    }

    .counter {
      width: max-content;
      min-width: 100px;
      margin-left: 10px;
    }
  }
`;
