import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FileContentProps, FileFilter } from './components/FilesTable/types';
import { BreadcrumbItem } from 'apis/general/types';
import { getDriveItems } from 'apis/drive';

import { CustomFilterAttrsTypes } from './components/FilterModal/types';

const customFilterInitialState = {
  created_at_period_from: null,
  created_at_period_to: null,
  size_number: '',
  size_order_of_magnitude: 'KB',
  types: [],
};

interface FilterParams {
  page: number;
  search: string;
  ordering: string;
  created_at_period_from: string;
  created_at_period_to: string;
  size: string;
  types: string[];
}

const useDriveList = (organizationId: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const orgId = searchParams.get('orgId');
  const urlSufix = `?token=${token}&orgId=${orgId}`;

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [tableError, setTableError] = useState(false);
  const [files, setFiles] = useState<FileContentProps[]>([]);

  const [filter, setFilter] = useState<FileFilter>({
    page: 1,
    search: '',
    ordering: [],
    size: '',
    size_order: 'KB',
    created_at_period_from: '',
    created_at_period_to: '',
    types: [],
  });

  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [hasMoreBreadcrumb, setHasMoreBreadcrumb] = useState(false);

  const loadFiles = useCallback(
    (
      filters: FilterParams = {
        page: 1,
        search: '',
        ordering: '',
        created_at_period_from: '',
        created_at_period_to: '',
        size: '',
        types: [],
      },
    ) => {
      setTableLoading(true);
      setTableError(false);

      getDriveItems(organizationId, filters, parentId || '', token || '')
        .then((response) => {
          const {
            num_pages,
            results,
            breadcrumb: currentBreadcrumb,
          } = response.data;

          setPageCount(num_pages);
          setFiles(results);

          setFilter((updatedDriveFiltersState) => ({
            ...updatedDriveFiltersState,
            page: filters.page,
          }));

          if (parentId) {
            setBreadcrumb(currentBreadcrumb.results);
            setHasMoreBreadcrumb(currentBreadcrumb.has_more);
          }
        })
        .catch(() => {
          setTableError(true);
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [organizationId, parentId, token],
  );

  const handleSearchChange = (value: string) => {
    setFilter((updatedDriveFiltersState) => ({
      ...updatedDriveFiltersState,
      search: value,
    }));
    loadFiles({
      ...filter,
      created_at_period_from: filter.created_at_period_from || '',
      created_at_period_to: filter.created_at_period_to || '',
      ordering: filter.ordering.toString(),
      search: value,
      size: filter.size ? `${filter.size}${filter.size_order}` : '',
    });
  };

  const handleNavigate = (path: string) => {
    setTableLoading(true);
    navigate(path);
  };

  const handleApplyCustomFilter = (newFilter: CustomFilterAttrsTypes) => {
    const updatedFilter = {
      ...filter,
      size: newFilter.size_number,
      size_order: newFilter.size_order_of_magnitude,
      created_at_period_from: newFilter.created_at_period_from,
      created_at_period_to: newFilter.created_at_period_to,
      types: newFilter.types,
    };
    loadFiles({
      ...updatedFilter,
      created_at_period_from: updatedFilter.created_at_period_from || '',
      created_at_period_to: updatedFilter.created_at_period_to || '',
      ordering: updatedFilter.ordering.toString(),
      search: updatedFilter.search,
      size: updatedFilter.size
        ? `${updatedFilter.size}${updatedFilter.size_order}`
        : '',
      types: updatedFilter.types,
    });
    setFilter(updatedFilter);
    setOpenModal((currentState) => !currentState);
  };

  const handleChangeType = (type: string) => {
    const currentTypes = [...filter.types];

    if (type !== 'all' && currentTypes.includes(type)) {
      currentTypes.splice(currentTypes.indexOf(type), 1);
    } else if (type !== 'all') {
      currentTypes.push(type);
    }

    const updatedFilter = {
      ...filter,
      types: type !== 'all' ? currentTypes : [],
    };
    loadFiles({
      ...updatedFilter,
      created_at_period_from: updatedFilter.created_at_period_from || '',
      created_at_period_to: updatedFilter.created_at_period_to || '',
      ordering: updatedFilter.ordering.toString(),
      search: updatedFilter.search,
      size: updatedFilter.size
        ? `${updatedFilter.size}${updatedFilter.size_order}`
        : '',
    });
    setFilter(updatedFilter);
  };

  useEffect(() => {
    loadFiles();
  }, [loadFiles, parentId]);

  useEffect(() => {
    setFilter({
      ordering: [],
      page: 1,
      search: '',
      size: '',
      size_order: 'KB',
      created_at_period_from: '',
      created_at_period_to: '',
      types: [],
    });
  }, [parentId]);

  return {
    urlSufix,
    t,
    pageCount,
    tableLoading,
    tableError,
    filter,
    setFilter,
    openModal,
    setOpenModal,
    files,
    loadFiles,
    setTableLoading,
    breadcrumb,
    hasMoreBreadcrumb,
    handleSearchChange,
    parentId,
    handleNavigate,
    handleApplyCustomFilter,
    customFilterInitialState,
    handleChangeType,
  };
};

export default useDriveList;
