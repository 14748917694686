import styled from 'styled-components';

interface StyledColorSelectorProps {
  color: string;
}

export const StyledColorSelector = styled.div<StyledColorSelectorProps>`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0px;
  }

  .color-options {
    display: flex;

    .button {
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-left: 14px;
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);

      &:hover {
        border-color: var(--grayscale-100) !important;
        box-shadow: none;
      }

      &.selected {
        border-color: var(--grayscale-200) !important;
        box-shadow: none;
      }

      .button-content {
        margin: 0;
        span {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
    }

    .custom {
      position: relative;
      .custom-color {
        width: 32px;
        min-width: 32px;
        height: 32px;
        box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);
        border: 1px solid ${({ color }) => color};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        position: relative;
        z-index: var(--z-index-level-1);
        cursor: pointer;

        &:hover {
          box-shadow: none;
        }

        .icon {
          font-size: 20px;
          color: ${({ color }) => color};
        }
      }

      input {
        position: absolute;
        top: 12px;
        left: 14px;
        opacity: 0;
      }
    }
  }
`;
