import apiV2 from 'apis/apiV2';
import QueryString from 'qs';

import {
  MuralListParams,
  BoardContentPostSendData,
  BoardContentSurveySendData,
  GetListParams,
  SchedulePreviewParams,
  ContentCommentSendData,
  FixedMuralListParams,
  SegmentationSendProps,
} from './types';

const basePathname = '/admin/org';

export const getBoardContent = (
  organizationId: string,
  params: MuralListParams,
) => {
  return apiV2.get(`${basePathname}/${organizationId}/board/content/`, {
    params: {
      page: params.page,
      search: params.search,
      type: params.type,
      ordering: params.order,
      post: params.filter?.publication,
      survey: params.filter?.survey,
      checkin: params.filter?.checkin,
      status: params.filter?.status,
      start_at_period_begin: params.filter?.start_at_period_begin,
      start_at_period_end: params.filter?.start_at_period_end,
      finish_at_period_begin: params.filter?.finish_at_period_begin,
      finish_at_period_end: params.filter?.finish_at_period_end,
      tag: params.filter?.tag,
      can_comment: params.filter?.can_comment,
      comments_operator: params.filter?.comments_operator,
      comments_count: params.filter?.comments_count,
      owner: params.filter?.owner,
      editorial: params.filter?.editorial,
    },
    paramsSerializer: {
      serialize: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
};

export const getFixedContents = (
  organizationId: string,
  params: FixedMuralListParams,
) => {
  return apiV2.get(`${basePathname}/${organizationId}/board/content/`, {
    params: {
      page: params.page,
      search: params.search,
      fixed: true,
    },
    paramsSerializer: {
      serialize: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
};

export const addFixedContent = (
  organizationId: string,
  contentId: string,
  fixed_start_at: string | null,
  fixed_finish_at: string | null,
) => {
  return apiV2.patch(
    `${basePathname}/${organizationId}/board/content/${contentId}/fixed/`,
    {
      fixed_start_at,
      fixed_finish_at,
    },
  );
};

export const archiveContent = (organizationId: string, contentId: string) => {
  return apiV2.patch(
    `${basePathname}/${organizationId}/board/content/${contentId}/archived/`,
    {
      is_archived: true,
    },
  );
};

export const removeContent = (organizationId: string, contentId: string) => {
  return apiV2.delete(
    `${basePathname}/${organizationId}/board/content/${contentId}/removed/`,
  );
};

export const makeCopy = (organizationId: string, contentId: string) => {
  return apiV2.post(
    `${basePathname}/${organizationId}/board/content/${contentId}/make-a-copy/`,
  );
};

export const removeFixedContents = (
  organizationId: string,
  content_ids: string[],
) => {
  return apiV2.put(`${basePathname}/${organizationId}/board/content/fixed/`, {
    content_ids,
  });
};

export const getMetabaseDashboards = (
  organizationId: string,
  contentId: string,
  type: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/${contentId}/type/${type}/metabase/`,
  );

export const getMetabaseLandingDashboards = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/metabase/dashboard/`);

export const getContent = (
  organizationId: string,
  type: string,
  contentId: string,
) => apiV2.get(`${basePathname}/${organizationId}/comm/${type}/${contentId}`);

export const getContentComments = (
  organizationId: string,
  contentId: string,
  commentId: string | undefined = '',
  page: number,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/${contentId}/comment/${commentId}`,
    {
      params: {
        page,
      },
    },
  );

export const createContentComment = (
  organizationId: string,
  contentId: string,
  commentId: string | undefined = '',
  data: ContentCommentSendData,
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/board/content/${contentId}/comment/${commentId}`,
    data,
  );

export const deleteContentComment = (
  organizationId: string,
  contentId: string,
  commentId: string,
) =>
  apiV2.delete(
    `${basePathname}/${organizationId}/board/content/${contentId}/comment/${commentId}`,
  );

export const getContentStatistics = (
  organizationId: string,
  contentId: string,
  entity: string,
  period: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/${contentId}/statistic/${entity}/`,
    {
      params: {
        period,
      },
    },
  );

export const getContentHistory = (
  organizationId: string,
  contentId: string,
  page: number,
  order: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/${contentId}/history/`,
    {
      params: {
        page,
        ordering: order,
      },
    },
  );

// Post

export const getBoardContentPost = (organizationId: string, postId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/post/${postId}`);

export const createBoardContentPost = (
  organizationId: string,
  data: BoardContentPostSendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/post/`, data);

export const updateBoardContentPost = (
  organizationId: string,
  postId: string,
  data: BoardContentPostSendData,
) => apiV2.put(`${basePathname}/${organizationId}/comm/post/${postId}`, data);

export const publishBoardContentPost = (
  organizationId: string,
  postId: string,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/post/${postId}/published`);

// Content

export const getSegmentationOptions = (
  organizationId: string,
  contentId?: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/segmentation/options/${
      contentId ? `?exclude_content_id=${contentId}` : ''
    }`,
  );

export const getSegmentation = (
  organizationId: string,
  segmentationId: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/segmentation/${segmentationId}/entry/`,
  );

export const cauculateTotalSegmentation = (
  organizationId: string,
  data?: SegmentationSendProps,
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/board/segmentation/total`,
    data,
  );

export const getNotificationConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/notification/options/`);

export const getContentParentCandidates = (
  organizationId: string,
  params: MuralListParams,
  contentId?: string,
  contentType?: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/parent/content/?page=${
      params.page
    }&search=${params.search}&type=${
      params.type !== 'all' ? params.type : ''
    }&ordering=${params.order}&content_id=${contentId || ''}&content_type=${
      contentType || ''
    }&selected_content_id=${params.selected_content_id}`,
  );

export const getTags = (organizationId: string, page: number, search: string) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/tag/?page=${page}&search=${search}`,
  );

export const createTag = (organizationId: string, title: string) =>
  apiV2.post(`${basePathname}/${organizationId}/board/tag/`, {
    title,
  });

export const getOwners = (
  organizationId: string,
  page: number,
  search: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/comm/accounts/owner/?page=${page}&search=${search}`,
  );

export const createSurveyCustomField = (
  organizationId: string,
  contentId: string,
  title: string,
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/board/content/${contentId}/customfield/`,
    {
      title,
    },
  );

// Survey

export const getSurveyConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/survey/config/`);

export const getBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
  seeResults?: boolean,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/comm/survey/${surveyId}${
      seeResults ? `?see_results=${seeResults}` : ''
    }`,
  );

export const createBoardContentSurvey = (
  organizationId: string,
  data: BoardContentSurveySendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/survey/`, data);

export const updateBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
  data: BoardContentSurveySendData,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/survey/${surveyId}`, data);

export const publishBoardContentSurvey = (
  organizationId: string,
  surveyId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/comm/survey/${surveyId}/published`,
  );

// Checkin

export const getCheckins = (organizationId: string, params: GetListParams) =>
  apiV2.get(`${basePathname}/${organizationId}/comm/checkin/`, {
    params,
  });

export const getBoardContentCheckin = (
  organizationId: string,
  postId: string,
) => apiV2.get(`${basePathname}/${organizationId}/comm/checkin/${postId}`);

export const createBoardContentCheckin = (
  organizationId: string,
  data: BoardContentPostSendData,
) => apiV2.post(`${basePathname}/${organizationId}/comm/checkin/`, data);

export const updateBoardContentCheckin = (
  organizationId: string,
  postId: string,
  data: BoardContentPostSendData,
) =>
  apiV2.put(`${basePathname}/${organizationId}/comm/checkin/${postId}`, data);

export const publishBoardContentCheckin = (
  organizationId: string,
  postId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/comm/checkin/${postId}/published`,
  );

export const getSchedulePreview = (
  organizationId: string,
  params: SchedulePreviewParams,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/scheduler/preview`,
    {
      params,
    },
  );

export const getContentConfigs = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/content/configs/`);

export const getPendingReportExports = (
  organizationId: string,
  contentId: string,
  page: number,
  type: string,
  onlyTotalReport: boolean,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/${contentId}/report/export/`,
    {
      params: {
        page,
        type,
        only_total_report: onlyTotalReport,
      },
    },
  );

export const resetInteractions = (organizationId: string) =>
  apiV2.post(
    `${basePathname}/${organizationId}/board/accounts/me/reset/interaction`,
  );

export const exportReport = (
  organizationId: string,
  contentId: string,
  data: {
    order: string;
    period: string | null;
    type: string;
  },
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/board/content/${contentId}/report/export/`,
    data,
  );
