import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { loginSSO } from 'apis/auth';

import { login, setCurrentOrganizationId } from 'services/auth';

import AppLoading from 'components/organism/AppLoading';

import { StyledLoginSSO } from './styles';

const LoginSSO: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const identityId = params.identityId;

  useEffect(() => {
    if (!identityId) {
      navigate('/');
      return;
    }

    loginSSO(identityId)
      .then((response) => {
        const { access, refresh, client, organization } = response.data;
        login(access, refresh, client);
        setCurrentOrganizationId(organization);
        navigate('/dashboard');
      })
      .catch(() => {
        navigate('/');
      });
  }, [identityId, navigate]);

  return (
    <StyledLoginSSO>
      <AppLoading />
    </StyledLoginSSO>
  );
};

export default LoginSSO;
