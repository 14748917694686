import styled from 'styled-components';

interface StyledAlertProps {
  theme: string;
}

export const StyledAlert = styled.div<StyledAlertProps>`
  width: 100%;
  padding: 20px 26px;
  border-radius: 12px;
  background: var(--warning-color-100);
  border: 1px solid var(--warning-color);

  display: flex;
  justify-content: space-between;
  align-items: center;

  .alert-info {
    display: flex;
    align-items: center;
    margin-right: 24px;

    .icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      background: var(--warning-color);
      margin-right: 20px;
    }

    .title {
      color: var(--grayscale-400);
    }

    .description {
      color: var(--grayscale-300);
    }
  }

  .alert-actions {
    display: flex;
    align-items: center;
  }

  .alert-close-button {
    .icon {
      color: var(--grayscale-300);
    }
  }
`;
