import React, { useRef, useState, useEffect } from 'react';

import { StyledCollapse } from './styles';
import { AvailableThemeTypes } from 'styles/button-themes';

interface CollapseProps {
  isOpen: boolean;
  setIsOpen: () => void;
  children: React.ReactNode;
  collapseHeader: React.ReactNode;
  headerButtonTheme?: keyof typeof AvailableThemeTypes;
  disabled?: boolean;
}

const Collapse: React.FC<CollapseProps> = ({
  isOpen,
  setIsOpen,
  children,
  collapseHeader,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | undefined>(0);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <StyledCollapse
      className={`collapse-wrapper ${isOpen ? 'collapse-open' : ''}`}
    >
      <div className="collapse-header" onClick={setIsOpen}>
        {collapseHeader}
      </div>
      <div className="collapse-content" style={{ height }}>
        <div ref={ref}>{children}</div>
      </div>
    </StyledCollapse>
  );
};

export default Collapse;
