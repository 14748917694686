import React from 'react';

import Tag from 'components/atom/Tag';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { FormStepsProps } from './types';

import { StyledFormSteps } from './styles';

const FormSteps: React.FC<FormStepsProps> = ({
  activeIndex,
  stepLabels,
  className = '',
}) => {
  return (
    <StyledFormSteps className={`form-steps ${className}`.trim()}>
      {stepLabels.map((label, index) => (
        <div
          className={`step ${activeIndex === index ? 'active' : ''}`.trim()}
          key={index}
        >
          <Tag radius="50%" padding="4px 9px" theme="dark">
            <Text as="pre" weight="700">
              {index + 1}
            </Text>
          </Tag>
          <Text as="h6">{label}</Text>
          {index !== stepLabels.length - 1 && (
            <Icon name="arrow-right-s-line" color="grayscale-200" />
          )}
        </div>
      ))}
    </StyledFormSteps>
  );
};

export default FormSteps;
