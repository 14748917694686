import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledNavigationTableActions } from './styles';
import Button from 'components/molecule/Button';

interface NavigationTableActionsProps {
  onAccess?: () => void;
}

const NavigationTableActions: React.FC<NavigationTableActionsProps> = ({
  onAccess,
}) => {
  const { t } = useTranslation();

  return (
    <StyledNavigationTableActions className="navigation-table-actions">
      <Button
        rightIcon="arrow-right-s-line"
        theme="link-gray-primary"
        onClick={onAccess}
      >
        {t('Access')}
      </Button>
    </StyledNavigationTableActions>
  );
};

export default NavigationTableActions;
