import { OrganizationState } from 'store/slices/organization/types';
import { MenuOptionProp } from 'components/organism/Menu/types';

export const useLegacyMenuOptions = (
  currentOrganization: OrganizationState,
  onClickLegacyMenuOption: (url: string) => void,
): MenuOptionProp[] => {
  return currentOrganization.legacy_menu.map((value) => {
    return {
      label: value.label,
      url: value.url,
      onClick: () => onClickLegacyMenuOption(value.url),
      disabeld: false,
    };
  });
};
