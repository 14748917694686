import styled from 'styled-components';

export const StyledNewContentModal = styled.div`
  & > h4 {
    margin-bottom: 36px;
  }

  .content-type-list {
    width: 100%;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--grayscale-100);

    .content-type-item {
      width: 100%;
      border-top: 1px solid var(--grayscale-100);
      padding: 14px 16px;
      height: max-content;

      #button-left-icon,
      #button-right-icon {
        display: none;
      }

      &.disabled,
      &:disabled {
        .button-content {
          .avatar {
            opacity: 0.4;
          }
          .texts {
            h6 {
              color: var(--grayscale-100);
            }
          }
        }
      }

      :hover {
        background: var(--gray-color);
      }

      :active {
        p,
        .actions-side .icon {
          color: var(--grayscale-200);
        }
      }

      .button-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-side {
          display: flex;
          align-items: center;

          .avatar {
            margin-right: 14px;
          }

          h6 {
            text-align: start;
            color: var(--grayscale-400);
          }
        }

        .actions-side {
          height: 19px;
        }
      }
    }
  }
`;
