import publicApi from 'apis/publicApi';
import { CompanyProps } from './types';

export const getCheckCompanySlug = (company_slug: string) =>
  publicApi.get(`/company/register/check/?company_slug=${company_slug}`);

export const getCheckCompanyShortName = (company_short_name: string) =>
  publicApi.get(`/company/register/check/?company_short=${company_short_name}`);

export const postRegisterCompany = (data: CompanyProps) =>
  publicApi.post('/company/register/', { ...data });

export const postResendEmailToConfirmCompany = (company_token: string) =>
  publicApi.post(`/company/register/resend/${company_token}/`);

export const postConfirmCompany = (company_token: string) =>
  publicApi.post(`/company/register/confirm/${company_token}/`);
