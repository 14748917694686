import styled from 'styled-components';

export const StyledCollaboratorsTable = styled.div`
  width: 100%;

  .content-header {
    .actions {
      display: flex;

      .button:last-child {
        padding-right: 0px;
        display: flex;
        justify-content: flex-end;

        #button-right-icon {
          display: none;
        }
      }
    }
  }

  .content {
    .table {
      width: 100%;
      max-width: 100%;

      margin-top: 56px;

      display: block;

      .table-wrapper {
        width: 100%;
        table {
          width: max-content;
          min-width: 100%;
          tbody {
            tr {
              td {
                .tooltip {
                  display: flex;
                  justify-content: center;
                }

                &:first-child {
                  min-width: max-content;
                  .tooltip {
                    justify-content: flex-start;
                    .text {
                      max-width: 37ch;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }

                &:nth-child(2) {
                  min-width: max-content;
                  .tooltip {
                    justify-content: flex-start;
                    .text {
                      max-width: 37ch;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }

                &:nth-last-child(4) {
                  min-width: max-content;
                  .tooltip {
                    justify-content: flex-start;
                    .text {
                      max-width: 37ch;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }

                &:nth-last-child(2) {
                  min-width: 140px;
                  max-width: 160px;
                }

                &:nth-last-child(1) {
                  .text {
                    max-width: 25ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }

      .pagination-control {
        margin-top: 16px;
        margin-bottom: 22px;
      }
    }
  }
`;
