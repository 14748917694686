import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';
import { useAppDispatch } from 'store/hooks';

import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';

import { NewFolderProps } from './types';

import { createDriveFile } from 'apis/driveDashboard';

import { StyledNewFolder } from './styles';

const NewFolder: React.FC<NewFolderProps> = ({
  setLoadingSaveFolder,
  organizationId,
  driveId,
  parentId,
  setTableLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleConfirm = () => {
    setLoadingSaveFolder(true);

    createDriveFile(organizationId, {
      drive: driveId,
      name: value,
      parent: parentId,
    })
      .then((response) => {
        const { data } = response;

        setLoadingSaveFolder(false);
        setTableLoading(true);
        dispatch(closeModal());

        navigate(`/drive/dashboard/${data.id}`);
      })
      .catch(() => {
        setLoadingSaveFolder(false);

        dispatch(
          showMessage({
            title: t('An unexpected error occurred while creating a folder'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  };

  return (
    <StyledNewFolder>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('New folder')}
        </Text>
      </div>

      <Input
        placeholder={t('Enter your folder name')}
        limit={100}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          theme="primary-outline"
          onClick={handleConfirm}
          disabled={value.trim().length <= 0}
        >
          {t('Save')}
        </Button>
      </div>
    </StyledNewFolder>
  );
};

export default NewFolder;
