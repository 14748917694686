import styled from 'styled-components';

export const StyledHierarchyImport = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main-header {
    padding-left: 54px;

    .back-logo {
      display: flex;
      align-items: center;

      .button {
        margin-right: 12px;
      }
    }
  }

  .secondary-header {
    padding-left: 54px;
  }

  .content-container {
    width: 100%;
    max-width: 1396px;
    height: calc(100% - 144px);
    display: flex;
    justify-content: space-between;

    .content-side {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 32px 62px;
      display: flex;
      justify-content: center;

      .content-wrapper {
        width: 100%;

        .title {
          margin-bottom: 6px;
        }

        & > .description {
          margin-bottom: 52px;
        }

        .import-actions {
          width: 100%;
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          & > * {
            margin-right: 16px;
            margin-bottom: 8px;
          }

          .select {
            .react-select--is-disabled {
              .upload-category-option,
              .icon {
                color: var(--grayscale-100);
              }
            }

            .react-select__control {
              min-width: 200px;

              &:hover {
                border-color: var(--primary-color);
              }
              .upload-category-option {
                padding: 12px 0px;
              }
            }

            .react-select__menu {
              z-index: var(--z-index-level-4);
              .upload-category-option {
                padding: 16px;
              }
            }
          }

          .button:last-child {
            padding-left: 0px;
          }
        }

        .file-uploader,
        .import-preview-card {
          margin-bottom: 56px;
        }

        .import-content-loading {
          padding: 54px 0px;
        }

        .import-errors {
          padding-bottom: 64px;
          position: relative;
          z-index: var(--z-index-level-4);
          background-color: var(--white-color);
          .import-error-item:last-child {
            border-bottom: 1px solid var(--gray-color);
          }
        }
      }
    }

    .menu-side {
      width: 100%;
      max-width: 454px;
      border-left: 1px solid var(--grayscale-100);
      border-right: 1px solid var(--grayscale-100);

      .import-menu-header {
        padding: 24px 20px;
        border-bottom: 1px solid var(--grayscale-100);
      }

      .import-menu-content {
        height: calc(100% - 74px);
        overflow-y: scroll;
        position: relative;

        .import-button-list {
          height: 100%;
          position: relative;

          .no-file-send {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            .status-icon {
              position: relative;
              .tag {
                margin-bottom: 10px;
                width: 32px;
                height: 32px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 10%;
              }
              .icon {
                position: absolute;
                top: 8px;
                left: 8px;
                font-size: 16px;
              }
            }
          }

          .import-buttons-loading {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
`;
