import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getPendingReportExports } from 'apis/board';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { PendingExportsProps, PendingExport, ExtraFilters } from './types';

import { StyledPendingExports } from './styles';

const PendingExports: React.FC<PendingExportsProps> = ({
  userEmail,
  contentId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector((state) => state.organization.pk);

  const exportTypes = {
    history: t('Edit history'),
  };

  const [reportItems, setReportItems] = useState<PendingExport[]>([]);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);

  const loadPendingExports = useCallback(
    (page: number) => {
      getPendingReportExports(organizationId, contentId, page, 'history', false)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);

          if (page > 1) {
            setReportItems((lastItems) => [
              ...lastItems,
              ...response.data.results,
            ]);
            return;
          }

          setReportItems(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading history'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, organizationId, t, contentId],
  );

  const getReportParams = (type: string, extraFilters: ExtraFilters) => {
    if (type === 'history') {
      return t('Sorted by creation date in {{order}} order', {
        order:
          extraFilters.order === 'created_at'
            ? t('ascending')
            : t('descending'),
      });
    }

    return '';
  };

  useEffect(() => {
    setLoading(true);
    loadPendingExports(1);
  }, [loadPendingExports]);

  return (
    <StyledPendingExports>
      <Text as="h5" weight="700">
        {t('Processing export request')}
      </Text>
      <Text as="p">
        {t('The report will be sent to {{target_email}}', {
          target_email: userEmail,
        })}
      </Text>

      <div
        className="report-list default-scroll"
        ref={(currentRef) => setScrollElement(currentRef)}
      >
        {reportItems.map((item, index) => (
          <div className="report-item" key={index}>
            <Text as="h6">
              {exportTypes[item.type as keyof typeof exportTypes]}
            </Text>
            <Text color="grayscale-200">
              {t('{{created_at}} at {{created_at_time}}', {
                created_at: getDateFromApiDate(item.created_at),
                created_at_time: getTimeFromApiDate(item.created_at),
              })}
            </Text>
            <Text color="grayscale-200">
              {getReportParams(item.type, item.extra_filters)}
            </Text>
          </div>
        ))}
      </div>

      {loading && <Loading type="bubbles" />}

      {loadMoreLoading && <Loading type="bubbles" />}

      {!loading && reportItems.length === 0 && (
        <EmptyMessage
          title={t('No export pending')}
          icon="checkbox-circle-line"
        />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadPendingExports(currentPage + 1);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledPendingExports>
  );
};

export default PendingExports;
