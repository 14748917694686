import styled from 'styled-components';

export const StyledSubscriptionModal = styled.div`
  & > .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-level-5);

    .close-modal-button {
      min-width: 40px;
      max-width: 40px;
    }
  }
`;
