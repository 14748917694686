export enum FileUploadErrorNames {
  UPLOAD_FAILED = 'upload-failed',
  UPLOAD_CANCELED = 'upload-canceled',
  NO_COMMON_DIRECTORY = 'no-common-directory',
  CREATE_S3_URL_FAILED = 'create-s3-url-failed',
}

export enum FileUploadModules {
  CORE = 'core',
  PERFORMANCE = 'performance',
  TRAINING = 'training',
  BOARD = 'board',
  DRIVE = 'drive',
  RESEARCH = 'research',
}

export interface FileUploadErrorDetails {
  status: number;
  error: unknown;
}

export interface UploadDataRequest {
  organizationId: string;
  module: string;
  file?: File;
  fileList?: File[];
  cacheControl?: string | null;
}

export interface UploadDataResponse {
  // Properties to mount the URL that should be used to point to uploaded data
  baseUrl: string;
  organizationId: string;
  uuid: string;

  // Properties that are intrinsic to FileUpload class, should not be changed
  totalFiles: number;
  progress: number;
  uploaded: boolean;
  canceled: boolean;
  moduleId: number;
  fileUrl: string;
}
