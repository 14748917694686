import styled from 'styled-components';

export const StyledFilesTableInfo = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;

  p {
    text-wrap: nowrap;
  }

  .input {
    width: 200px;

    .input-content {
      padding: 0px;
      padding-left: 14px;
    }
  }

  .icon-wrapper {
    padding: 8px;

    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: var(--gray-color);
  }

  .text {
    width: 100%;
    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rename-file-wrapper {
    display: flex;
    gap: 8px;
  }
`;
