import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import { downloadFile } from 'utils/download';

import Icon from 'components/atom/Icon';
import { Dropdown } from 'components/molecule/DropdownV2';

import Delete from '../../../actions/Delete';
import Move from '../../../actions/Move';

import { moveDriveFiles } from 'apis/driveDashboard';

import { StyledFilesTableActions } from './styles';

interface FilesTableActionsProps {
  id: string;
  parentId: string;
  segmentationItem: string | null;
  name: string;
  fileAppUrl: string;
  onRenameClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onShare: (
    fileId: string,
    fileAppUrl: string,
    segmentationItem: string | undefined,
    isShared: boolean,
  ) => void;
  fileLink: string;
  isShared: boolean;
  viewFile: string | null;
  onLoadDriveFiles: (newPathId?: string | null) => void;
  setMoveLoading: Dispatch<SetStateAction<boolean>>;
  isFolder: boolean;
}

const FilesTableActions: React.FC<FilesTableActionsProps> = ({
  id,
  name,
  fileAppUrl,
  segmentationItem,
  onRenameClick,
  onDeleteClick,
  onShare,
  parentId,
  fileLink,
  isShared,
  viewFile,
  onLoadDriveFiles,
  setMoveLoading,
  isFolder,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pk: organizationId } = useAppSelector((state) => state.organization);

  const handleVisualizeClick = () => {
    window.open(viewFile ? viewFile : '', '_blank');
  };

  const handleRenameClick = () => {
    onRenameClick(id);
  };

  const handleShareClick = () => {
    onShare(id, fileAppUrl, segmentationItem || undefined, isShared);
  };

  const handleDownloadClick = async () => {
    const fileUrl = viewFile || '';

    dispatch(
      showMessage({
        title: t('File download started'),
        theme: 'warning',
        icon: 'download',
        time: 10000,
      }),
    );

    try {
      await downloadFile(fileUrl, name);

      dispatch(
        showMessage({
          title: t('Download completed successfully'),
          theme: 'success',
          icon: 'check',
          time: 10000,
        }),
      );
    } catch (error) {
      dispatch(
        showMessage({
          title: t(
            'An unexpected error occurred while downloading the file/folder',
          ),
          theme: 'danger',
          icon: 'close',
          time: 10000,
        }),
      );
    }
  };

  const handleConfirmMove = (toPath: string | null) => {
    setMoveLoading(true);

    moveDriveFiles(organizationId, id, toPath)
      .then(() => {
        onLoadDriveFiles(toPath);
        dispatch(
          showMessage({
            title: t('Moved'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while moving'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setMoveLoading(false);
      });
  };

  const handleMoveClick = () => {
    dispatch(
      setModalView({
        show: true,
        width: '800px',
        padding: '0px',
        content: (
          <Move
            fileId={id}
            name={name}
            onConfirm={handleConfirmMove}
            currentParentId={parentId}
            onLoadDriveFiles={onLoadDriveFiles}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const handlePublishClick = () => {
    navigate(`/mural/content/post/drive?driveUrl=${fileLink}`);
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(fileLink);
    dispatch(
      showMessage({
        title: t('You have copied the file/folder link to your clipboard.'),
        theme: 'success',
        icon: 'check',
        time: 3000,
      }),
    );
  };

  const handleDeleteClick = () => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: <Delete name={name} onConfirm={() => onDeleteClick(id)} />,
      }),
    );
  };

  return (
    <StyledFilesTableActions className="action-wrapper">
      <Dropdown.Root
        trigger={<Icon name="more-2" color="grayscale-200" />}
        theme="dark"
      >
        <React.Fragment>
          <Dropdown.Item onClick={handleVisualizeClick} disabled={!viewFile}>
            {t('View')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleRenameClick}>
            {t('Rename')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDownloadClick} disabled={!viewFile}>
            {t('Download')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleMoveClick}>{t('Move')}</Dropdown.Item>
          <Dropdown.Item onClick={handleCopyLinkClick} disabled={!isShared}>
            {t('Copy Link')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleShareClick}>{t('Share')}</Dropdown.Item>
          <Dropdown.Item
            onClick={handlePublishClick}
            disabled={!isShared || isFolder}
          >
            {t('Post on wall')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDeleteClick}>
            {t('Remove')}
          </Dropdown.Item>
        </React.Fragment>
      </Dropdown.Root>
    </StyledFilesTableActions>
  );
};

export default FilesTableActions;
