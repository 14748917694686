import { dateAndTimeToApiDate } from 'utils/date';
import { stripHTMLTags } from 'utils/string';

import { PostContentState } from './types';
import { CoverParams, NotificationSendProps } from 'apis/board/types';
import { schedulerInitialState } from '../PublicationDateModal/components/RecurrenceConfig/utils';

export const postInitialState = {
  cover: {
    value: '',
    url: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  title: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  text: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  url: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  description: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  start_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  start_at_time: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  finish_at: {
    value: undefined,
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  finish_at_time: {
    value: '',
    error: {
      hasError: false,
      errorMessage: '',
    },
    disabled: false,
  },
  ...schedulerInitialState,
};

export const postAPIDataFactory = (currentContentState: PostContentState) => {
  const {
    cover,
    title,
    description,
    text,
    url,
    start_at,
    start_at_time,
    finish_at,
    finish_at_time,
    type,
    can_comment,
    tag_ids,
    segmentations,
    gamification,
    notifications,
    parent,
    scheduler_start_date_at,
    scheduler_finish_date_at,
    content_start_time_at,
    content_finish_time_at,
    content_duration,
    content_week_days,
    scheduler_var,
    scheduler_type,
    voting_is_enabled,
    voting_message,
    with_recurrence,
    editorial,
    can_reaction,
  } = currentContentState;

  const contentCover: CoverParams = {
    id: cover.value || null,
    path: cover.url || null,
  };

  let startAtInAPIFormat = null;
  let finishAtInAPIFormat = null;

  if (start_at.value) {
    startAtInAPIFormat = dateAndTimeToApiDate(
      start_at.value,
      start_at_time.value?.toString() || '',
    );
  }

  if (finish_at.value) {
    finishAtInAPIFormat = dateAndTimeToApiDate(
      finish_at.value,
      finish_at_time.value?.toString() || '',
    );
  }

  let currentNotifications: NotificationSendProps[] = [];

  if (notifications) {
    currentNotifications = notifications
      .filter((item) => item.checked)
      .map((item) => ({ key: item.key, message: item.message }));
  }

  let scheduderData = null;
  if (with_recurrence) {
    scheduderData = {
      scheduler_start_date_at: scheduler_start_date_at.value
        ? dateAndTimeToApiDate(scheduler_start_date_at.value).split('T')[0]
        : null,
      scheduler_finish_date_at: scheduler_finish_date_at.value
        ? dateAndTimeToApiDate(scheduler_finish_date_at.value).split('T')[0]
        : null,
      content_start_time_at: content_start_time_at.value,
      content_finish_time_at: content_finish_time_at.value,
      content_duration: content_duration.value,
      content_week_days: content_week_days.value,
      scheduler_var: scheduler_var.value,
      scheduler_type: scheduler_type.value,
    };
    startAtInAPIFormat = null;
    finishAtInAPIFormat = null;
  } else {
    scheduderData = null;
  }

  const currentEditorial = editorial === 'not_defined' ? null : editorial;

  return {
    cover: contentCover.id,
    title: title.value,
    description: description?.value,
    text: type === 'short' ? stripHTMLTags(text?.value) : text?.value,
    url: url?.value,
    start_at: startAtInAPIFormat,
    finish_at: finishAtInAPIFormat,
    type,
    can_comment,
    tags: tag_ids,
    segmentations: segmentations ? [segmentations] : [],
    gamification,
    notifications: currentNotifications,
    parent,
    scheduler: scheduderData,
    enable_poll: voting_is_enabled || false,
    poll: voting_message || null,
    editorial: currentEditorial,
    can_reaction,
  };
};
