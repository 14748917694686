import styled from 'styled-components';

export const StyledAlertList = styled.div`
  width: 100%;

  h3 {
    margin-right: 12px;
  }

  .content-config-container {
    width: 100%;
    height: calc(100% - 144px);
    padding: 0px 58px;

    .content-side {
      width: 100%;
      padding: 32px 0px;

      .content-header {
        display: flex;
        min-height: 44px;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          .button:last-child {
            padding-right: 0px;
            display: flex;
            justify-content: flex-end;

            #button-right-icon {
              display: none;
            }
          }
        }
      }

      .content {
        width: 100%;
        overflow-y: visible !important;
      }
    }
  }
`;
