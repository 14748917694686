import styled from 'styled-components';

export const StyledSegmentationRow = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .entity-wrapper {
    display: flex;
    align-items: center;
    /* min-width: 200px; */

    .tag {
      margin-right: 10px;
      font-size: 12px;
      text-transform: uppercase;
    }

    .entity-select {
      flex: 1;
      margin-right: 10px;
      min-width: 128px;

      .react-select {
        .react-select__menu {
          width: max-content;
          .button-content {
            font-size: 14px;
            text-transform: uppercase;
          }
        }

        .react-select__control {
          padding-left: 0px;

          .button {
            padding: 0px 8px;
            height: max-content;
            min-height: max-content;

            .button-content {
              font-size: 12px;
              text-transform: uppercase;
              margin-top: 0px;
              max-width: 16ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 4px 0px;
            }
          }

          .react-select__placeholder {
            padding-left: 8px;
            text-transform: uppercase;
            font-size: 14px;
          }
        }

        .react-select__menu {
          max-width: 400px;

          .react-select__option {
            .button {
              height: auto;
              .button-content {
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }

  .operator-wrapper {
    width: 148px;
    height: 100%;
    margin-right: 10px;

    .operator-select {
      width: 100%;

      .react-select {
        .react-select__menu {
          width: max-content;
        }

        .react-select__control {
          padding-left: 0px;

          .button {
            &:hover * {
              color: var(--grayscale-200);
            }

            .button-content {
              margin-top: 0px;
              max-width: 12ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 4px 0px;
            }
          }
        }
      }
    }
  }

  .segmentation-field {
    flex: 1;
    margin-right: 20px;
    width: 334px;

    .react-select {
      .react-select__control {
        .button {
          &:hover * {
            color: var(--grayscale-200);
          }
        }
      }
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
  }

  .react-select__value-container--is-multi {
    padding: 2px 0px !important;
  }

  .react-select__clear-indicator {
    display: none;
  }
`;
