import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { dateAndTimeToApiDate } from 'utils/date';

import FilterItem from '../FilterItem';
import DatePicker from 'components/molecule/DatePicker';

import { CustomFilterAttrsTypes } from '../../types';

import { StyledCreatedAtFilter } from './styles';

export interface CreatedAtFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const CreatedAtFilter: React.FC<CreatedAtFilterProps> = ({
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(
    !!filter.created_at_period_from || !!filter.created_at_period_to,
  );

  const handleClear = () => {
    const newFilter = {
      ...filter,
      created_at_period_from: null,
      created_at_period_to: null,
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleChangePeriodBegin = (value: Date) => {
    setFilter({
      ...filter,
      created_at_period_from: dateAndTimeToApiDate(value),
    });
  };

  const handleChangePeriodEnd = (value: Date) => {
    setFilter({
      ...filter,
      created_at_period_to: dateAndTimeToApiDate(value),
    });
  };

  return (
    <StyledCreatedAtFilter>
      <FilterItem
        title={t('Creation date')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={
          isOpen &&
          (!!filter.created_at_period_from || !!filter.created_at_period_to)
        }
        onClear={handleClear}
        showCheckAllButton={false}
      >
        <div className="datepicker-wrapper">
          <DatePicker
            label={t('From')}
            id="start-at-period-begin"
            icon="calendar-2-fill"
            value={
              filter.created_at_period_from
                ? dayjs(filter.created_at_period_from).toDate()
                : undefined
            }
            onChange={handleChangePeriodBegin}
          />
          <DatePicker
            label={t('To')}
            id="start-at-period-end"
            icon="calendar-2-fill"
            value={
              filter.created_at_period_to
                ? dayjs(filter.created_at_period_to).toDate()
                : undefined
            }
            onChange={handleChangePeriodEnd}
          />
        </div>
      </FilterItem>
    </StyledCreatedAtFilter>
  );
};

export default CreatedAtFilter;
