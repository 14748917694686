import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';

interface LoginTutorialStageProps {
  companySlug: string;
  userSlug: string;
}

const LoginTutorialStage: React.FC<LoginTutorialStageProps> = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Text as="h3" weight="700">
        {t('Login using your credentials')}
      </Text>
      <Text as="p" className="mt-05">
        {t('Check the following example:')}
      </Text>
      <Input
        icon="home-6-line"
        label={t('Company')}
        id="company"
        value={props.companySlug}
        className="mt-2"
        disabled
      />
      <Input
        icon="user-line"
        label={t('User')}
        id="login"
        value={props.userSlug}
        className="mt-2"
        disabled
      />
      <Input
        icon="lock-line"
        label={t('Password')}
        id="password"
        placeholder={t('Password')}
        className="mt-2"
        disabled
      />
    </React.Fragment>
  );
};

export default LoginTutorialStage;
