import { MediaUrlResolverProps } from '../types';
import { removeParamsFromUrl } from '../utils';
import { t } from 'i18next';

const SHAREPOINT_URL_REGEX = /^https?:\/\/.+.sharepoint.com\//;

export const isSharePointUrl = (url: string) => {
  return url && SHAREPOINT_URL_REGEX.test(url);
};

export const resolveSharePointEmbed = ({
  data,
  resolve,
}: MediaUrlResolverProps): void => {
  const mediaUrl = removeParamsFromUrl(data.url);

  // see: https://github.com/e107inc/e107/issues/3793
  // see: https://www.mrsharepoint.guru/how-to-embed-video-in-sharepoint-modern-page/
  // see: https://www.sharepointdiary.com/2020/05/sharepoint-online-link-to-document-download-instead-of-open.html
  // see: https://sharepoint.stackexchange.com/q/252084
  // language=html
  const html = `
    <video
      src="${mediaUrl}?download=1"
      style="width: calc(100% - 5px); height: 100%; aspect-ratio: 16/9"
      controlslist="nodownload"
      controls="controls">
      ${t('Your device does not support the video. Please contact support.')}
    </video>
  `;
  resolve({ html });
};
