import React, { useState, useEffect } from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  GroupBase,
} from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { getSelectGeneralOptions } from 'apis/general';

import Icon from 'components/atom/Icon';
import Button from 'components/molecule/Button';

import {
  SelectedOptionValueType,
  SelectOption,
  SelectProps,
  AsyncPaginatePage,
  SelectAsyncResponseProps,
} from './types';

import { StyledSelect } from './styles';
import Text from 'components/atom/Text';

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="drop" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<SelectOption>) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="close-circle-fill" />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<SelectOption>) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="close-circle-fill" />
    </components.MultiValueRemove>
  );
};

const Select: React.FC<SelectProps> = ({
  value,
  setValue,
  options = [],
  onChange,
  className = '',
  theme = 'default',
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isMulti = false,
  menuIsOpen = undefined,
  size = 'normal',
  apiUrl,
  selectKey,
  buttonTheme = 'link-gray-primary',
  hasError = false,
  errorMessage,
  apiVersion = 'v2',
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [searchChangeControl, setSearchChangeControl] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);

  const handleChange = (option: SelectedOptionValueType) => {
    setValue(option);
    if (onChange) {
      onChange(option);
    }
  };

  // fixes issue with SHIFT+HOME & SHIFT+END,
  // see: https://github.com/JedWatson/react-select/issues/3562
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Home' || event.key === 'End') {
      event.preventDefault();
      const length = (event.target as HTMLInputElement).value.length;

      if (event.key === 'Home') {
        (event.target as HTMLInputElement).selectionStart = 0;
      } else {
        (event.target as HTMLInputElement).selectionEnd = length;
      }
    }
  };

  const loadOptions: LoadOptions<
    SelectOption,
    GroupBase<SelectOption>,
    AsyncPaginatePage
  > = (search, prevOptions, additional) => {
    return new Promise((resolve, reject) => {
      const page = !additional ? 1 : additional.page;

      if (currentSearchTimeOut) {
        clearTimeout(currentSearchTimeOut);
      }

      const searchPage = search !== searchChangeControl ? 1 : page + 1;

      const timeOut = setTimeout(
        () => {
          getSelectGeneralOptions(
            apiUrl || '',
            search ? searchPage : page,
            search,
            apiVersion,
          )
            .then((response) => {
              const { next, results } = response.data;

              setCurrentPage(page + 1);
              setSearchChangeControl(search);
              setFirstLoad(false);

              resolve({
                options: results.map((item: SelectAsyncResponseProps) => ({
                  value: `${item.value}__${item.key}`,
                  label: (
                    <Button theme={buttonTheme} size="small">
                      {item.value}
                    </Button>
                  ),
                })),
                hasMore: !!next,
                additional: {
                  page: search ? searchPage : page + 1,
                },
              });
            })
            .catch(() => {
              dispatch(
                showMessage({
                  title: t('An error occurred while fetching the content'),
                  theme: 'danger',
                  icon: 'close',
                  time: 10000,
                }),
              );

              reject();
            });
        },
        search !== searchChangeControl && !firstLoad ? 1000 : 400,
      );

      setCurrentSearchTimeOut(timeOut);
    });
  };

  useEffect(() => {
    setCurrentPage(() => 1);
  }, [apiUrl]);

  const filterOptions = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    candidate: { label: any; value: string; data: any },
    input: string,
  ) => {
    if (!input) {
      return true;
    }
    const inputLower = input.toLowerCase();
    const valueMatch = candidate.value.toLowerCase().includes(inputLower);

    let labelMatch = false;
    const labelChildren = candidate.label.props?.children;
    const labelChildrens = candidate.label.props?.children?.[1].props?.children;
    if (typeof labelChildren === 'string') {
      labelMatch = labelChildren.toLowerCase().includes(inputLower);
    } else if (typeof labelChildrens === 'string') {
      labelMatch = labelChildrens.toLowerCase().includes(inputLower);
    }

    return valueMatch || labelMatch;
  };

  return (
    <StyledSelect className={`select ${className} ${theme}`} size={size}>
      {!apiUrl && (
        <ReactSelect
          key={selectKey}
          value={value}
          noOptionsMessage={() => t('No options')}
          loadingMessage={() => `${t('Loading')}...`}
          onChange={(option) => handleChange(option)}
          onKeyDown={handleKeyDown}
          menuIsOpen={menuIsOpen}
          options={options}
          className={`react-select ${hasError ? 'has-error' : ''}`}
          classNamePrefix="react-select"
          placeholder={placeholder || t('Select')}
          isSearchable={isSearchable}
          filterOption={filterOptions}
          isDisabled={isDisabled}
          isMulti={isMulti}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          theme={(reactSelectTheme) => ({
            ...reactSelectTheme,
            borderRadius: 0,
            colors: {
              ...reactSelectTheme.colors,
              primary: 'var(--primary-color)',
              primary75: 'var(--primary-color-100)',
              primary50: 'var(--primary-color-300)',
              primary25: 'var(--primary-color-400)',
              danger: 'var(--danger-color)',
              dangerLight: 'var(--danger-color-100)',
              neutral0: 'var(--white-color)',
              neutral5: 'var(--gray-color)',
              neutral10: 'var(--grayscale-100)',
              neutral20: 'var(--grayscale-200)',
              neutral30: 'var(--grayscale-300)',
              neutral40: 'var(--grayscale-400)',
              neutral50: 'var(--dark-color)',
            },
          })}
        />
      )}

      {apiUrl && (
        <AsyncPaginate
          key={`${selectKey}__${apiUrl}`}
          value={value}
          noOptionsMessage={() => t('No options')}
          loadingMessage={() => `${t('Loading')}...`}
          onChange={(option) => handleChange(option)}
          onKeyDown={handleKeyDown}
          menuIsOpen={menuIsOpen}
          className={`react-select ${hasError ? 'has-error' : ''}`}
          classNamePrefix="react-select"
          placeholder={placeholder || t('Select')}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          theme={(reactSelectTheme) => ({
            ...reactSelectTheme,
            borderRadius: 0,
            colors: {
              ...reactSelectTheme.colors,
              primary: 'var(--primary-color)',
              primary75: 'var(--primary-color-100)',
              primary50: 'var(--primary-color-300)',
              primary25: 'var(--primary-color-400)',
              danger: 'var(--danger-color)',
              dangerLight: 'var(--danger-color-100)',
              neutral0: 'var(--white-color)',
              neutral5: 'var(--gray-color)',
              neutral10: 'var(--grayscale-100)',
              neutral20: 'var(--grayscale-200)',
              neutral30: 'var(--grayscale-300)',
              neutral40: 'var(--grayscale-400)',
              neutral50: 'var(--dark-color)',
            },
          })}
          loadOptions={loadOptions}
          additional={{
            page: currentPage,
          }}
          cacheUniqs={[apiUrl]}
        />
      )}

      {errorMessage && (
        <Text as="pre" color="danger-color" className="select-error-message">
          {errorMessage}
        </Text>
      )}
    </StyledSelect>
  );
};

export default Select;
