import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Select from 'components/molecule/Select';
import Separator from 'components/atom/Separator';
import Input from 'components/molecule/Input';
import Tooltip from 'components/atom/Tooltip';
import SwitchButton from 'components/atom/SwitchButton';
import { Tab } from 'components/molecule/TabMenu/types';
import TabMenu from 'components/molecule/TabMenu';

import NPSScale from './components/NPSScale';

import {
  SelectOption,
  SingleSelectedOption,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';
import {
  Question as QuestionType,
  QuestionState as QuestionStateType,
  QuestionMode as QuestionModeType,
  SwapOptions as SwapQuestionsType,
  QuestionHandle,
} from '../types';

import { StyledQuestionContent } from './styles';
import PollOptions from './components/PollOptions';

interface QuestionProps {
  obj: QuestionType;
  qntQuestions: number;
  questionCategories: SelectOption[];
  handleRemoveQuestion: () => void;
  handleCopyQuestion: () => void;
  handleSwapQuestions: (order: number, direction: SwapQuestionsType) => void;
  handleSaveQuestion: (question: QuestionType) => void;
  hasReachedQuestionLimit: boolean;
  canEdit: boolean;
}

const Question = forwardRef<QuestionHandle, QuestionProps>(
  (
    {
      obj,
      qntQuestions,
      questionCategories,
      handleRemoveQuestion,
      handleCopyQuestion,
      handleSwapQuestions,
      handleSaveQuestion,
      hasReachedQuestionLimit,
      canEdit,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const getCurrentCategoryOrDefault = () => {
      const category = questionCategories.find(
        (element) => element.value === obj.category,
      ) as SingleSelectedOption;
      return category;
    };

    const [currentQuestionCategory, setCurrentQuestionCategory] =
      useState<SelectedOptionValueType>(getCurrentCategoryOrDefault());

    useImperativeHandle(ref, () => ({
      saveQuestion() {
        return validateAndSaveQuestionHandler();
      },
    }));

    const questionInitialState = {
      id: obj.id,
      title: {
        value: obj.title || '',
        error: {
          hasError: false,
          errorMessage: '',
        },
        disabled: false,
      },
      order: obj.order,
      category: obj.category,
      is_required: obj.is_required,
      nps_has_labels: obj.nps_has_labels,
      nps_scale: obj.nps_scale || 5,
      nps_first_value: obj.nps_first_value || 1,
      nps_last_value: obj.nps_last_value || 5,
      nps_first_label: {
        value: obj.nps_first_label || '',
        error: {
          hasError: false,
          errorMessage: '',
        },
        disabled: false,
      },
      nps_last_label: {
        value: obj.nps_last_label || '',
        error: {
          hasError: false,
          errorMessage: '',
        },
        disabled: false,
      },
      mode: obj.mode,
      options: {
        items: [],
        errors: [],
      },
      survey: obj.survey,
    };

    const [question, setQuestion] =
      useState<QuestionStateType>(questionInitialState);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const [tabs, setTabs] = useState<Tab[]>(
      [5, 7, 10].map((item) => ({
        key: item.toString(),
        content: item.toString(),
        active: question.nps_scale === item,
        disabled: isDisabled,
      })),
    );

    useEffect(() => {
      const tempIsDisabled = [
        QuestionModeType.Edit,
        QuestionModeType.Fetch,
      ].includes(question.mode);

      if (tempIsDisabled !== isDisabled) {
        setIsDisabled(() => tempIsDisabled);

        setTabs((prevTabs) => {
          return prevTabs.map((item) => ({
            ...item,
            disabled: tempIsDisabled,
          }));
        });
      }

      if (obj.options.items.length > 0) {
        setQuestion((updatedState: QuestionStateType) => ({
          ...updatedState,
          options: {
            items: obj.options.items.map((item) => {
              return {
                ...item,
                error: {
                  hasError: false,
                  errorMessage: '',
                },
              };
            }),
            errors: [],
          },
        }));
      }

      if (!['single_choice', 'multiple_choice'].includes(question.category)) {
        setQuestion((updatedState: QuestionStateType) => ({
          ...updatedState,
          options: {
            items: [],
            errors: [],
          },
        }));
      } else if (obj.options.items.length === 0) {
        setQuestion((updatedState: QuestionStateType) => ({
          ...updatedState,
          options: {
            items: [
              {
                id: 'poll-option-1',
                text: '',
                is_correct: false,
                is_editable: true,
              },
              {
                id: 'poll-option-2',
                text: '',
                is_correct: false,
                is_editable: true,
              },
            ],
            errors: [],
          },
        }));
      }
    }, [question.mode, isDisabled, obj.options, question.category]);

    const getMinValue = () =>
      getStepValue() * ((question.nps_first_value || 1) - 1);

    const getMaxValue = () => {
      return question.nps_last_value === question.nps_scale
        ? 100
        : getStepValue() * ((question.nps_last_value || 5) - 2);
    };

    const getStepValue = () => 100 / ((question.nps_scale || 5) - 2);

    const handleChangeFirstValue = (value: number) => {
      const newFirstValue = value / getStepValue() + 2;

      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        nps_first_value: Math.round(newFirstValue - 1),
      }));
    };

    const handleChangeLastValue = (value: number) => {
      const newLastValue = value / getStepValue() + 1;

      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        nps_last_value: Math.round(newLastValue + 1),
      }));
    };

    const handleChangeFirstLabel = (value: string) => {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        nps_first_label: {
          value: value,
          error: { hasError: false, errorMessage: '' },
          disabled: false,
        },
      }));
    };

    const handleChangeLastLabel = (value: string) => {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        nps_last_label: {
          value: value,
          error: { hasError: false, errorMessage: '' },
          disabled: false,
        },
      }));
    };

    const handleChangeScale = (key: string) => {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        nps_scale: parseInt(key),
        nps_first_value: 1,
        nps_last_value: question.nps_scale,
      }));
      setTabs(
        tabs.map((tab: Tab) => {
          if (tab.key === key) {
            tab.active = true;
            return tab;
          }

          tab.active = false;
          return tab;
        }),
      );
    };

    const renderQuestionActionButtons = () => {
      switch (question.mode) {
        case QuestionModeType.Save:
          return (
            <>
              <Tooltip
                content={t('Cancel changes')}
                id="cancel-edit-question"
                key="cancel-edit-question"
              >
                <Button
                  theme="danger-outline"
                  rounded="true"
                  size="small"
                  onClick={cancelQuestionChanges}
                >
                  <Icon name="close" color="grayscale-200" />
                </Button>
              </Tooltip>

              <Tooltip
                content={t('Save question')}
                id="save-edit-question"
                key="save-edit-question"
              >
                <Button
                  theme="success"
                  rounded="true"
                  size="small"
                  onClick={validateAndSaveQuestionHandler}
                >
                  <Icon name="check" color="grayscale-200" />
                </Button>
              </Tooltip>
            </>
          );
        case QuestionModeType.Edit:
          return (
            <>
              <Tooltip
                content={t('Move question up')}
                id="move-question-up"
                key="move-question-up"
              >
                <Button
                  theme="dark-shadow"
                  rounded="true"
                  size="small"
                  disabled={!(question.order > 1) || !canEdit}
                  onClick={() =>
                    handleSwapQuestions(question.order, SwapQuestionsType.Up)
                  }
                >
                  <Icon name="arrow-up-line" color="grayscale-200" />
                </Button>
              </Tooltip>

              <Tooltip
                content={t('Move question down')}
                id="move-question-down"
                key="move-question-down"
              >
                <Button
                  theme="dark-shadow"
                  rounded="true"
                  size="small"
                  disabled={!(question.order < qntQuestions) || !canEdit}
                  onClick={() =>
                    handleSwapQuestions(question.order, SwapQuestionsType.Down)
                  }
                >
                  <Icon name="arrow-down-line" color="grayscale-200" />
                </Button>
              </Tooltip>

              <Tooltip
                content={t('Copy question')}
                id="copy-question"
                key="copy-question"
              >
                <Button
                  theme="dark-shadow"
                  rounded="true"
                  size="small"
                  onClick={handleCopyQuestion}
                  disabled={hasReachedQuestionLimit || !canEdit}
                >
                  <Icon name="copy-outline" color="grayscale-200" />
                </Button>
              </Tooltip>

              <Tooltip
                content={t('Edit question')}
                id="edit-question"
                key="edit-question"
              >
                <Button
                  disabled={!canEdit}
                  theme="dark-shadow"
                  rounded="true"
                  size="small"
                  onClick={() =>
                    onChangeQuestionModeHandler(QuestionModeType.Save)
                  }
                >
                  <Icon name="edit-line" color="grayscale-200" />
                </Button>
              </Tooltip>

              <Tooltip
                content={t('Remove question')}
                id="remove-question"
                key="remove-question"
              >
                <Button
                  theme="danger-outline"
                  rounded="true"
                  size="small"
                  onClick={handleRemoveQuestion}
                  disabled={!canEdit}
                >
                  <Icon name="delete-bin-6-line" color="danger-color" />
                </Button>
              </Tooltip>
            </>
          );
        case QuestionModeType.Fetch:
          return (
            <Tooltip
              content={t('Saving data...')}
              id="saving-data"
              key="saving-data"
            >
              <Button
                className="animate-spin"
                theme="dark-shadow"
                rounded="true"
                size="small"
                disabled
              >
                <Icon name="loader" color="grayscale-200" />
              </Button>
            </Tooltip>
          );
        default:
          break;
      }
    };

    const renderQuestionContentOptions = () => {
      const contentStatus = canEdit && !isDisabled ? '' : 'disabled';
      const hasPaddingLeft =
        contentStatus === '' && question.options.items.length > 2;
      return (
        <div>
          <div className={`${hasPaddingLeft ? 'pl-[52px] pt-5' : ' pt-5'}`}>
            <PollOptions
              options={question.options.items}
              setQuestionState={setQuestion}
              contentStatus={contentStatus}
            />
          </div>
          <div
            className={`flex gap-2 flex-col information-wrapper ${
              question.options.errors.length > 0 ? 'mt-5' : ''
            }`}
          >
            {question.options.errors.map((message, index) => {
              return (
                <Text
                  key={index}
                  as="pre"
                  color="danger-color"
                  className="input-error-message"
                >
                  {message}
                </Text>
              );
            })}
          </div>
        </div>
      );
    };

    const renderQuestionContent = () => {
      switch (question.category) {
        case 'open_answer':
          return (
            <Text as="p" className="px-3.5 py-3.5" color="grayscale-300">
              {t('Response of up to 1000 characters')}
            </Text>
          );
        case 'point_scale':
          return (
            <div className={isDisabled ? 'pointer-events-none' : ''}>
              <div className="scale-size">
                <Text as="h6">{t('Scale size')}</Text>
                <TabMenu tabs={tabs} onChangeTab={handleChangeScale} />
              </div>
              <NPSScale
                scaleSize={question.nps_scale || 5}
                minValue={getMinValue()}
                maxValue={getMaxValue()}
                minValueLabel={question.nps_first_label?.value || ''}
                maxValueLabel={question.nps_last_label?.value || ''}
                hasLabels={question.nps_has_labels}
                onChangeMinValue={handleChangeFirstValue}
                onChangeMaxValue={handleChangeLastValue}
                onChangeMinValueLabel={handleChangeFirstLabel}
                onChangeMaxValueLabel={handleChangeLastLabel}
                isDisabled={isDisabled}
              />
            </div>
          );
        case 'single_choice':
          return renderQuestionContentOptions();
        case 'multiple_choice':
          return renderQuestionContentOptions();
        default:
          break;
      }
    };

    const cancelQuestionChanges = () => {
      if (questionInitialState.title?.value === '') {
        handleRemoveQuestion();
      }
      setQuestion({ ...questionInitialState, mode: QuestionModeType.Edit });
    };

    const onChangeCategoryHandler = (
      selectedOption: SelectedOptionValueType,
    ) => {
      const option = selectedOption as SingleSelectedOption;
      if (option && option.value) {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          category: option.value,
        }));
      }
    };

    const onChangeTitleHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value } = event.target;
      setQuestion((prevQuestion: QuestionStateType) => ({
        ...prevQuestion,
        title: {
          value: value,
          error: {
            hasError: false,
            errorMessage: '',
          },
          disabled: false,
        },
      }));
    };

    const onChangeQuestionModeHandler = (mode: QuestionModeType) => {
      setQuestion((prevQuestion) => ({ ...prevQuestion, mode: mode }));
    };

    const validateQuestionOptions = (): string[] => {
      const errors: string[] = [];

      // 1. Validate the number of options (2 to 15)
      if (
        question.options.items.length < 2 ||
        question.options.items.length > 15
      ) {
        errors.push(
          t('The question must have at least 2 and at most 15 options.'),
        );
      }

      // 2. Validate that 'text' of each option is not empty
      const emptyTextOptions = question.options.items.filter(
        (option) => option.text.trim() === '',
      );
      if (emptyTextOptions.length > 0) {
        errors.push(t('All options must have non-empty text.'));
      }

      // 3. Validate that 'text' values are unique
      const seenTexts = new Set<string>();
      const duplicateTexts = new Set<string>();

      question.options.items.forEach((option) => {
        const trimmedText = option.text.trim();
        if (seenTexts.has(trimmedText)) {
          duplicateTexts.add(trimmedText);
        } else {
          seenTexts.add(trimmedText);
        }
      });

      if (duplicateTexts.size > 0) {
        errors.push(t('There are options with the same description.'));
      }

      return errors;
    };

    const validateAndSaveQuestionHandler = () => {
      let hasErrors = false;

      if (['single_choice', 'multiple_choice'].includes(question.category)) {
        const errors: string[] = validateQuestionOptions();

        setQuestion((updatedState: QuestionStateType) => ({
          ...updatedState,
          options: {
            ...updatedState.options,
            errors: errors,
          },
        }));

        if (errors.length > 0) {
          hasErrors = true;
        }
      }

      if (['', undefined, null].includes(question.title?.value.trim() || '')) {
        setQuestion((updatedState: QuestionStateType) => ({
          ...updatedState,
          title: {
            value: question.title?.value || '',
            error: {
              errorMessage: t('Field is required.'),
              hasError: true,
            },
            disabled: false,
          },
        }));
        hasErrors = true;
      }

      if (!hasErrors) {
        const newQuestion: QuestionType = {
          id: question.id,
          title: question.title?.value || '',
          survey: question.survey || '',
          is_active: question.is_active || true,
          order: question.order,
          category: question.category,
          is_required: question.is_required,
          nps_has_labels: question.nps_has_labels,
          nps_scale: question.nps_scale,
          nps_first_value: question.nps_first_value,
          nps_last_value: question.nps_last_value,
          nps_first_label: question.nps_first_label?.value || '',
          nps_last_label: question.nps_last_label?.value || '',
          options: question.options,
          mode: QuestionModeType.Edit,
        };
        handleSaveQuestion(newQuestion);
        onChangeQuestionModeHandler(QuestionModeType.Edit);

        return true;
      }
      return false;
    };

    return (
      <div
        className={twMerge([
          'flex flex-col gap-3 px-8 py-7 rounded-xl',
          'shadow-[0px_4px_52px_0px_rgba(0,0,0,0.1)] w-[800px]',
        ])}
      >
        <div
          className={twMerge([
            'flex justify-between items-center px-3.5 py-2.5',
          ])}
        >
          <Text as="h6" className="text-gray-400">
            {`${question.order}/${qntQuestions}`}
          </Text>

          <Select
            className="min-w-[150px]"
            theme="no-outline"
            value={currentQuestionCategory}
            setValue={setCurrentQuestionCategory}
            options={questionCategories.filter(
              (option) =>
                currentQuestionCategory &&
                'value' in currentQuestionCategory &&
                currentQuestionCategory.value !== option.value,
            )}
            onChange={onChangeCategoryHandler}
            isDisabled={isDisabled}
          />
        </div>

        <StyledQuestionContent>
          <Input
            className="input-title"
            theme="post"
            value={question.title?.value || ''}
            onChange={onChangeTitleHandler}
            limit={255}
            placeholder={t('Insert the question')}
            hasError={question.title?.error.hasError}
            errorMessage={question.title?.error.errorMessage}
            disabled={isDisabled}
            id="title"
          />
          {renderQuestionContent()}
        </StyledQuestionContent>

        <Separator />

        <div className="flex justify-between px-3.5 py-3">
          <div className="flex gap-5">
            <div className="switch-wrapper flex items-center gap-3">
              <Tooltip
                content={t('Make this question required')}
                id="question-required"
                key="question-required"
              >
                <SwitchButton
                  id={`question_${question.order}_is_required`}
                  checked={question.is_required}
                  onChange={(event) => {
                    setQuestion({
                      ...question,
                      is_required: event.target.checked,
                    });
                  }}
                  disabled={isDisabled}
                />
              </Tooltip>
              <Text as="h6">{t('Required')}</Text>
            </div>
            {question.category === 'point_scale' && (
              <div className="switch-wrapper flex items-center gap-3">
                <Tooltip
                  content={t('Enable or disable labels for this question')}
                  id="question-has-labels"
                  key="question-has-labels"
                >
                  <SwitchButton
                    id={`question_${question.order}_has_labels`}
                    checked={question.nps_has_labels}
                    onChange={(event) => {
                      setQuestion({
                        ...question,
                        nps_has_labels: event.target.checked,
                      });
                    }}
                    disabled={isDisabled}
                  />
                </Tooltip>
                <Text as="h6">{t('Labels')}</Text>
              </div>
            )}
          </div>
          <div className="flex gap-3">{renderQuestionActionButtons()}</div>
        </div>
      </div>
    );
  },
);

Question.displayName = 'Question';

export default Question;
