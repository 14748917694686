import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';

import i18nextConfig from 'i18n';

import { FileUpload } from 'services/upload';

import { limitFileName } from 'utils/files';

import {
  startUpload,
  completeUpload,
  updateUploadProgress,
  setFileUploadInstance,
  setUploadDone,
} from './index';

import { DriveUploadFile, DriveUploadFileStatus } from './types';
import { FileUploadErrorNames, FileUploadModules } from 'services/upload/types';
import { showMessage } from '../toaster';
import { createDriveFile } from 'apis/driveDashboard';
import { AxiosError } from 'axios';

enum ErrorStatus {
  noSpaceError = 'noSpaceError',
  unexpectedError = 'unexpectedError',
  canceled = 'canceled',
}

export const uploadFile = createAsyncThunk<
  DriveUploadFile,
  DriveUploadFile,
  { rejectValue: string }
>('drive/uploadFile', async (file, thunkAPI) => {
  const fileUpload = new FileUpload();

  thunkAPI.dispatch(setFileUploadInstance(fileUpload));

  try {
    const response = await fileUpload.send(
      file.file,
      FileUploadModules.DRIVE,
      file.organizationId,
      (progress: number) => {
        thunkAPI.dispatch(updateUploadProgress(progress));
      },
    );

    await createDriveFile(file.organizationId, {
      drive: file.moduleDriveId,
      name: limitFileName(file.file.name, 100),
      parent: file.parentId,
      raw: response.moduleId,
      size: Math.ceil(file.file.size / 1024),
    });

    return file;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.data.non_field_errors) {
        return thunkAPI.rejectWithValue(ErrorStatus.noSpaceError);
      }

      return thunkAPI.rejectWithValue(ErrorStatus.unexpectedError);
    }

    return thunkAPI.rejectWithValue(
      (error as string).includes(FileUploadErrorNames.UPLOAD_CANCELED)
        ? ErrorStatus.canceled
        : ErrorStatus.unexpectedError,
    );
  }
});

const determineUploadStatus = (
  dispatch: AppDispatch,
  payload?: string | DriveUploadFile,
): DriveUploadFileStatus => {
  if (payload && typeof payload === 'string') {
    const responseStatus = ErrorStatus[payload as keyof typeof ErrorStatus];
    const currentStatus =
      responseStatus === ErrorStatus.canceled ? 'success' : 'error';

    if (responseStatus === ErrorStatus.unexpectedError) {
      dispatch(
        showMessage({
          title: i18nextConfig.t(
            'An unexpected error occurred while uploading the file',
          ),
          theme: 'danger',
          icon: 'close',
          time: 10000,
        }),
      );
    }

    if (responseStatus === ErrorStatus.noSpaceError) {
      dispatch(
        showMessage({
          title: i18nextConfig.t('Not enough disk space to upload this file'),
          theme: 'danger',
          icon: 'close',
          time: 10000,
        }),
      );
    }

    return currentStatus;
  }

  return 'success';
};

const dispatchUpload = async (
  dispatch: AppDispatch,
  currentPendingFile: DriveUploadFile,
) => {
  try {
    dispatch(startUpload(currentPendingFile));
    const response = await dispatch(uploadFile(currentPendingFile));

    const currentStatus = determineUploadStatus(dispatch, response.payload);

    dispatch(
      completeUpload({
        fileId: currentPendingFile.id,
        status: currentStatus,
      }),
    );

    setTimeout(() => {
      dispatch(uploadNextFile());
    }, 100);
  } catch (error) {
    dispatch(
      completeUpload({
        fileId: currentPendingFile.id,
        status: 'error',
      }),
    );
  }
};

export const uploadNextFile = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const currentPendingFile = state.drive.uploaded_files.find(
      (file) => file.status === 'pending',
    );
    const isInProgress = state.drive.upload_in_progress;

    if (currentPendingFile) {
      dispatchUpload(dispatch, currentPendingFile);
    } else {
      if (isInProgress) {
        dispatch(setUploadDone());
      }
    }
  };
};
