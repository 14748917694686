import React from 'react';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';

import { getIcon } from 'pages/drive/utils/icons';

import { StyledFilesTableInfo } from './styles';
import { addEllipsisInMiddle } from 'utils/string';

interface FilesTableInfoProps {
  index: number;
  name: string;
  type: string;
}

const FilesTableInfo: React.FC<FilesTableInfoProps> = ({
  index,
  name,
  type,
}) => {
  return (
    <StyledFilesTableInfo className="drive-info">
      <div className="icon-wrapper">
        <Icon name={getIcon(type)} color="grayscale-200" />
      </div>
      <Tooltip
        id={`title-${index}`}
        content={addEllipsisInMiddle(name, 30, 30, 7)}
        disabled={name.length < 19}
        className="title-tooltip"
      >
        <Text>{name}</Text>
      </Tooltip>
    </StyledFilesTableInfo>
  );
};

export default FilesTableInfo;
