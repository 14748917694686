import styled from 'styled-components';

interface StyledFreeTrialButtonWrapperProps {
  isFreeTrial?: boolean;
}

export const StyledFreeTrialButtonWrapper = styled.div<StyledFreeTrialButtonWrapperProps>`
  cursor: ${({ isFreeTrial }) => (isFreeTrial ? 'pointer' : 'initial')};

  & *:active {
    pointer-events: ${({ isFreeTrial }) => (isFreeTrial ? 'none' : '')};
  }
`;
