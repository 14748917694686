import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import Breadcrumb from 'components/organism/Breadcrumb';
import MuralConfigMenu from './components/MuralConfigMenu';
import CustomFieldsConfig from './components/CustomFieldsConfig';
import DenylistConfig from './components/DenylistConfig';
import OrganizationConfig from './components/OrganizationConfig';
import SubscriptionModal from 'components/molecule/FreeTrialButtonWrapper/SubscriptionModal';
import PageLoading from 'components/molecule/PageLoading';

import { StyledGenralConfig } from './styles';

const GenralConfig: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [trialModalIsVisible, setTrialModalIsVisible] = useState(false);
  const isFreeTrial = useAppSelector((state) => state.organization.is_free);

  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const handleBack = () => {
    navigate('/settings');
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const [menuActiveTab, setMenuActiveTab] = useState('organization');
  const [menuOptions, setMenuOptions] = useState([
    {
      key: 'organization',
      content: t('Organization'),
      active: menuActiveTab === 'organization',
    },
    {
      key: 'custom-fields',
      content: t('Custom fields'),
      active: menuActiveTab === 'custom-fields',
    },
    {
      key: 'denylist',
      content: t('Denylist'),
      active: menuActiveTab === 'denylist',
    },
    {
      key: 'general',
      content: t('General'),
      active: menuActiveTab === 'general',
      disabled: !isFreeTrial,
    },
    {
      key: 'members',
      content: t('Members'),
      active: menuActiveTab === 'members',
      disabled: !isFreeTrial,
    },
  ]);

  const handleChangeMenuTab = (tab: string) => {
    if (isFreeTrial && (tab === 'general' || tab === 'members')) {
      setTrialModalIsVisible(true);
      return;
    }

    setMenuOptions(
      menuOptions.map((item) => {
        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      }),
    );
    setMenuActiveTab(tab !== menuActiveTab ? tab : '');
  };

  const renderMenuTabContent = () => {
    switch (menuActiveTab) {
      case 'organization': {
        return <OrganizationConfig setSaveLoading={setSaveLoading} />;
      }
      case 'custom-fields': {
        return <CustomFieldsConfig />;
      }
      case 'denylist': {
        return <DenylistConfig />;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <StyledGenralConfig className="mural-content-config-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <Header
        className="secondary-header"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              {
                label: t('Profile and platform management'),
                action: () => navigate(''),
              },
            ]}
          />
        }
      />
      <div className="content-config-container">
        <div className="menu-side">
          <MuralConfigMenu
            tabMenuOptions={menuOptions}
            onChangeTab={handleChangeMenuTab}
          />
        </div>
        <div className="content-side relative">{renderMenuTabContent()}</div>
      </div>

      {trialModalIsVisible && (
        <SubscriptionModal
          onCloseButtonClick={() => setTrialModalIsVisible(false)}
          hide={false}
          onSubscribe={() => setTrialModalIsVisible(false)}
        />
      )}

      {saveLoading && <PageLoading />}
    </StyledGenralConfig>
  );
};

export default GenralConfig;
