import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import Confirm from 'components/molecule/Confirm';
import PageLoading from 'components/molecule/PageLoading';

import { DuplicateSurveyProps } from './types';

import { StyledDuplicateSurvey } from './styles';
import { duplicateSurvey } from 'apis/survey';

const DuplicateSurvey: React.FC<DuplicateSurveyProps> = ({
  surveyId,
  organizationId,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleDuplicateSurvey = () => {
    duplicateSurvey(organizationId, surveyId)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Survey has been duplicated'),
            theme: 'success',
            icon: 'check',
            time: 10000,
            customLeft: '0px',
          }),
        );

        onSave();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while duplicating the survey',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(closeModal());
      });
  };

  return (
    <StyledDuplicateSurvey>
      {loading && <PageLoading />}
      <Confirm
        title={t('Duplicate survey')}
        subtitle={t(
          'The survey will be duplicated and saved as a draft so that the target audience and publication date can be defined. All other settings will be maintained.',
        )}
        confirmLabel={t('Confirm')}
        onConfirm={handleDuplicateSurvey}
      />
    </StyledDuplicateSurvey>
  );
};

export default DuplicateSurvey;
