import { PayloadAction } from '@reduxjs/toolkit';
import {
  DriveState,
  DriveUploadFile,
  DriveUploadFileStatus,
  FileUploadType,
} from './types';

export const addFileToUploadListUtil = (
  state: DriveState,
  action: PayloadAction<DriveUploadFile>,
) => {
  state.uploaded_files.push(action.payload);
};

export const startUploadUtil = (
  state: DriveState,
  action: PayloadAction<DriveUploadFile>,
) => {
  state.uploaded_files = state.uploaded_files.map((file) => {
    if (file.id === action.payload.id) {
      file.status = 'in-progress';
    }
    return file;
  });
  state.current_in_progress_file_id = action.payload.id;
  state.close_button_is_visible = false;
  state.upload_in_progress = true;
};

export const setUploadDoneUtil = (state: DriveState) => {
  state.upload_in_progress = false;
};

export const completeUploadUtil = (
  state: DriveState,
  action: PayloadAction<{ fileId: string; status: DriveUploadFileStatus }>,
) => {
  state.current_in_progress_file_id = '';
  state.uploaded_files = state.uploaded_files.map((file) => {
    if (file.id === action.payload.fileId) {
      file.status = action.payload.status;
    }
    return file;
  });
  if (!state.uploaded_files.some((file) => file.status === 'pending')) {
    state.close_button_is_visible = true;
  }
};

export const updateUploadProgressUtil = (
  state: DriveState,
  action: PayloadAction<number>,
) => {
  state.uploaded_files = state.uploaded_files.map((file) => {
    if (file.id === state.current_in_progress_file_id) {
      file.progress = action.payload;
    }
    return file;
  });
};

export const setUploadListIsVisibleUtil = (
  state: DriveState,
  action: PayloadAction<boolean>,
) => {
  state.is_visible = action.payload;
};

export const setUploadListIsExpandedUtil = (
  state: DriveState,
  action: PayloadAction<boolean>,
) => {
  state.upload_list_is_expanded = action.payload;
};

export const setCloseButtonIsVisibleUtil = (
  state: DriveState,
  action: PayloadAction<boolean>,
) => {
  state.close_button_is_visible = action.payload;
};

export const closeUploadListUtil = (state: DriveState) => {
  state.close_button_is_visible = false;
  state.upload_list_is_expanded = true;
  state.current_in_progress_file_id = '';
  state.is_visible = false;
  state.last_uploaded_file_id = '';
  state.uploaded_files = [];
};

export const cancelUploadUtil = (state: DriveState) => {
  state.file_upload_instance?.cancel();

  state.uploaded_files = state.uploaded_files.filter(
    (file) => file.id !== state.current_in_progress_file_id,
  );

  if (state.uploaded_files.length === 0) {
    closeUploadListUtil(state);
  }
};

export const setFileUploadInstanceUtil = (
  state: DriveState,
  action: PayloadAction<FileUploadType>,
) => {
  state.file_upload_instance = action.payload;
};
