import styled from 'styled-components';

export const StyledTextOptions = styled.div`
  .field-section-wrapper {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .field-name {
      flex-grow: 1;
      & > .text {
        margin-bottom: 8px;
      }
    }

    .field-max-chars {
      max-width: 150px;
      & > .text {
        margin-bottom: 8px;
      }
    }

    .multiline-label-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      height: 20px;

      .icon {
        font-size: 20px;
      }
    }
  }
  .field-preview-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
    & > .text {
      margin-bottom: 8px;
    }
  }
`;
