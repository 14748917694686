import React, { SetStateAction, Dispatch } from 'react';

import { AvailableColorTypes } from 'styles/global-styles';

export interface SortableValueState {
  key: string;
  value: number;
}

export interface SearchableValue {
  key: string;
  value: string;
}

export interface SortableColumnProps {
  key: string;
  sortableOnClick: (value: SortableValueState) => void;
  value: number;
}

export interface SearchableColumnProps {
  key: string;
  onSearch: (values: SearchableValue[]) => void;
  value: string;
  placeholder: string;
}

export interface ColumnProps {
  content: React.ReactNode;
  sortable?: SortableColumnProps;
  searchable?: SearchableColumnProps;
}

export interface CellProps {
  content: React.ReactNode;
  clickable?: boolean;
  onClick?: () => void;
}

export interface RowProps {
  cells: CellProps[];
  onClick?: () => void;
}

export const AvailableTextAlignTypes = {
  start: 'start',
  center: 'center',
  end: 'end',
};

export interface TableProps {
  columns: ColumnProps[];
  setColumns?: Dispatch<SetStateAction<ColumnProps[]>>;
  rows: RowProps[];
  textAlign?: keyof typeof AvailableTextAlignTypes;
  clickable?: boolean;
  scrollable?: boolean;
  fontColor?: keyof typeof AvailableColorTypes;
  hasPagination?: boolean;
  pageCount?: number;
  page?: number;
  onChangePage?: (selectedItem: number) => void;
  loading?: boolean;
  emptyMessageTitle?: string;
  emptyMessageDescription?: string;
  hideEmptyMessage?: boolean;
}
