import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledPopupForm } from './styles';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import { SelectOption } from 'components/molecule/Select/types';
import Input from 'components/molecule/Input';
import DatePicker from 'components/molecule/DatePicker';
import Separator from 'components/atom/Separator';
import { PopupFields, KeyValueOptionProps } from './types';
import { useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCSCXPopup,
  getCSCXPopup,
  getCSCXPopupOptions,
  updateCSCXPopup,
} from 'apis/staff';
import { PopupPostSendData } from 'apis/staff/types';
import { dateAndTimeToApiDate } from 'utils/date';
import { RequestFieldError } from 'apis/types';
import { showMessage } from 'store/slices/toaster';
import Editor from 'components/molecule/Editor';
import SwitchButton from 'components/atom/SwitchButton';
import { ApiVersions, getBaseAPIUrl } from 'settings';

const PopupForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { popupId } = useParams();
  const [sections, setSections] = useState([]);
  const [targets, setTargets] = useState([]);
  const [popup, setPopup] = useState<PopupFields>({
    title: { value: null, error: { hasError: false, errorMessage: '' } },
    content: { value: null, error: { hasError: false, errorMessage: '' } },
    section: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    target: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    organizations: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    is_active: { value: true, error: { hasError: false, errorMessage: '' } },
    start_at: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    start_at_time: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    finish_at: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    finish_at_time: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
  });

  const getSectionId = () => {
    if (popup?.section.value) {
      const parts = (popup?.section.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getTargetId = () => {
    if (popup?.target.value) {
      const parts = (popup?.target.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getOrganizationsIds = () => {
    if (popup?.organizations.value) {
      const ids: string[] = [];
      const options = popup?.organizations.value as SelectOption[];
      options.forEach((option) => {
        const parts = option.value.split('__');
        ids.push(parts[parts.length - 1]);
      });
      return ids;
    }
    return [];
  };

  const getStartAt = () => {
    if (popup.start_at.value) {
      return dateAndTimeToApiDate(
        popup.start_at.value,
        popup.start_at_time?.value || undefined,
      );
    }

    return '';
  };

  const getFinishAt = () => {
    if (popup.finish_at.value) {
      return dateAndTimeToApiDate(
        popup.finish_at.value,
        popup.finish_at_time?.value || undefined,
      );
    }

    return null;
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'danger',
        icon: 'close',
        time: 10000,
        customLeft: '0px',
      }),
    );
  };

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setPopup((lastFields) => {
        const fieldContent = {
          ...lastFields[error.field as keyof PopupFields],
        };

        return {
          ...lastFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const getTimeAsStr = (date: Date) => {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const fetchData = useCallback(() => {
    dispatch(setPageLoading(true));

    getCSCXPopupOptions()
      .then((response) => {
        setSections(
          response.data.sections.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );
        setTargets(
          response.data.targets.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );

        if (popupId) {
          getCSCXPopup(popupId)
            .then((response) => {
              setPopup({
                ...popup,
                title: {
                  value: response.data.title,
                  error: { hasError: false, errorMessage: '' },
                },
                content: {
                  value: response.data.content,
                  error: { hasError: false, errorMessage: '' },
                },
                is_active: {
                  value: response.data.is_active,
                  error: { hasError: false, errorMessage: '' },
                },
                section: {
                  value: {
                    value: `${response.data.section.value}__${response.data.section.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.section.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                target: {
                  value: {
                    value: `${response.data.target.value}__${response.data.target.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.target.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                organizations: {
                  value: response.data.organizations.map(
                    (org: KeyValueOptionProps) => {
                      return {
                        value: `${org.value}__${org.key}`,
                        label: (
                          <Button theme="link-primary" size="small">
                            {org.value}
                          </Button>
                        ),
                      };
                    },
                  ),
                  error: { hasError: false, errorMessage: '' },
                },
                start_at: {
                  value: new Date(response.data.start_at),
                  error: { hasError: false, errorMessage: '' },
                },
                start_at_time: {
                  value: getTimeAsStr(new Date(response.data.start_at)),
                  error: { hasError: false, errorMessage: '' },
                },
                finish_at: {
                  value: response.data.finish_at
                    ? new Date(response.data.finish_at)
                    : null,
                  error: { hasError: false, errorMessage: '' },
                },
                finish_at_time: {
                  value: response.data.finish_at
                    ? getTimeAsStr(new Date(response.data.finish_at))
                    : '',
                  error: { hasError: false, errorMessage: '' },
                },
              });
            })
            .catch(() => {
              navigate('/error-404');
            })
            .finally(() => {
              dispatch(setPageLoading(false));
            });
        } else {
          dispatch(setPageLoading(false));
        }
      })
      .catch(() => {
        dispatch(setPageLoading(false));
        navigate('/error-404');
      });
  }, [dispatch, navigate, popupId, popup]);

  const postAPIDataFactory = () => {
    return {
      title: popup.title.value || '',
      content: popup.content.value || '',
      section: getSectionId() || '',
      target: getTargetId() || '',
      organizations: getOrganizationsIds(),
      is_active: popup.is_active.value,
      start_at: getStartAt(),
      finish_at: getFinishAt(),
    };
  };

  const createPopup = () => {
    dispatch(setPageLoading(true));

    const data: PopupPostSendData = postAPIDataFactory();

    createCSCXPopup(data)
      .then(() => {
        showSuccessMessage(t('The popup has been created'));
        navigate('/staff/cscx/popup');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while creating the popup'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const updatePopup = () => {
    dispatch(setPageLoading(true));

    const data: PopupPostSendData = postAPIDataFactory();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updateCSCXPopup(popupId!, data)
      .then(() => {
        showSuccessMessage(t('The popup has been updated'));
        navigate('/staff/cscx/popup');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the popup'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSaveClick = () => {
    if (validatePopup()) {
      if (popupId) {
        updatePopup();
      } else {
        createPopup();
      }
    }
  };

  const validatePopup = () => {
    const updatedPopup = { ...popup };
    let isValid = true;

    if (!popup.title.value) {
      updatedPopup.title.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.title.error = { hasError: false, errorMessage: '' };
    }

    if (!popup.content.value) {
      updatedPopup.content.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.content.error = { hasError: false, errorMessage: '' };
    }

    if (!popup.section.value) {
      updatedPopup.section.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.section.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!popup.target.value) {
      updatedPopup.target.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.target.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (getTargetId() == '4' && getOrganizationsIds().length == 0) {
      updatedPopup.organizations.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.organizations.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!popup.start_at.value) {
      updatedPopup.start_at.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.start_at.error = { hasError: false, errorMessage: '' };
    }

    if (!popup.start_at_time.value) {
      updatedPopup.start_at_time.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.start_at_time.error = { hasError: false, errorMessage: '' };
    }

    if (popup.finish_at.value) {
      if (!popup.finish_at_time.value) {
        updatedPopup.finish_at_time.error = {
          hasError: true,
          errorMessage: t('This field is required'),
        };
        isValid = false;
      } else {
        updatedPopup.finish_at_time.error = {
          hasError: false,
          errorMessage: '',
        };
      }
    } else {
      updatedPopup.finish_at_time.error = { hasError: false, errorMessage: '' };
    }

    if (!popup.content.value) {
      updatedPopup.content.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPopup.content.error = { hasError: false, errorMessage: '' };
    }

    setPopup(updatedPopup);
    return isValid;
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, navigate, fetchData]);

  return (
    <StyledPopupForm>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {popupId ? t('Edit popup') : t('New popup')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => navigate('/staff/cscx/popup')}
              >
                {t('Cancel')}
              </Button>
              <Button
                theme="primary"
                rounded="true"
                onClick={() => handleSaveClick()}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
          <div className="content">
            <section>
              <Text as="h4" className="section-title">
                {t('General information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="switch-wrapper">
                    <SwitchButton
                      id="isActive"
                      checked={popup.is_active.value}
                      onChange={() => {
                        setPopup({
                          ...popup,
                          is_active: {
                            value: !popup.is_active.value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                    />
                    <Text as="h6">{t('Is active')}</Text>
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Title')}*
                  </Text>
                  <div className="section-group">
                    <Input
                      type="text"
                      id="title"
                      theme="default"
                      limit={100}
                      value={
                        popup.title.value != null
                          ? popup.title.value
                          : undefined
                      }
                      onChange={(event) => {
                        const { value } = event.target;
                        setPopup({
                          ...popup,
                          title: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={popup.title.error.hasError}
                      errorMessage={popup.title.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Section')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={sections}
                      value={popup.section.value}
                      setValue={(value) =>
                        setPopup({
                          ...popup,
                          section: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={popup.section.error.hasError}
                      errorMessage={popup.section.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a section...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Target')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={targets}
                      value={popup.target.value}
                      setValue={(value) =>
                        setPopup({
                          ...popup,
                          target: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                          organizations: {
                            value: null,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={popup.target.error.hasError}
                      errorMessage={popup.target.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a target...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              {getTargetId() == '4' && (
                <div className="section-contents">
                  <div className="section-content">
                    <Text as="h6" color="grayscale-200">
                      {t('Organizations')}*
                    </Text>
                    <div className="section-group">
                      <Select
                        value={popup.organizations.value}
                        setValue={(value) =>
                          setPopup({
                            ...popup,
                            organizations: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={popup.organizations.error.hasError}
                        errorMessage={popup.organizations.error.errorMessage}
                        theme="default"
                        buttonTheme="link-primary"
                        placeholder={t('Select organizations...')}
                        apiUrl={`${getBaseAPIUrl(
                          ApiVersions.v2,
                        )}/staff/cscx/fields/organizations/`}
                        isSearchable
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Start at')}*
                  </Text>
                  <div className="section-group">
                    <div className="form-group">
                      <DatePicker
                        id="startAt"
                        value={popup.start_at.value || undefined}
                        icon="calendar-2-fill"
                        onChange={(date) =>
                          setPopup({
                            ...popup,
                            start_at: {
                              value: date,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={popup.start_at.error.hasError}
                        errorMessage={popup.start_at.error.errorMessage}
                      />
                      <Input
                        type="time"
                        theme="gray"
                        icon="clock-solid"
                        placeholder="HH:MM"
                        value={popup.start_at_time.value?.toString() || ''}
                        onChange={(event) => {
                          const { value } = event.target;
                          setPopup({
                            ...popup,
                            start_at_time: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          });
                        }}
                        hasError={popup.start_at_time.error.hasError}
                        errorMessage={popup.start_at_time.error.errorMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Finish at')}
                  </Text>
                  <div className="section-group">
                    <div className="form-group">
                      <DatePicker
                        id="finishAt"
                        value={popup.finish_at.value || undefined}
                        icon="calendar-2-fill"
                        onChange={(date) =>
                          setPopup({
                            ...popup,
                            finish_at: {
                              value: date,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={popup.finish_at.error.hasError}
                        errorMessage={popup.finish_at.error.errorMessage}
                      />
                      <Input
                        type="time"
                        theme="gray"
                        icon="clock-solid"
                        placeholder="HH:MM"
                        value={popup.finish_at_time.value?.toString() || ''}
                        onChange={(event) => {
                          const { value } = event.target;
                          setPopup({
                            ...popup,
                            finish_at_time: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          });
                        }}
                        hasError={popup.finish_at_time.error.hasError}
                        errorMessage={popup.finish_at_time.error.errorMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Content')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="section-group">
                    <Editor
                      className="text"
                      value={popup.content.value?.toString() || undefined}
                      onChange={(value) => {
                        setPopup({
                          ...popup,
                          content: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      quickbarsInsOptions={'emoticons hr'}
                      limit={10000}
                      characterCountType="focused"
                      hasError={popup.content.error.hasError}
                      errorMessage={popup.content.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StyledPopupForm>
  );
};

export default PopupForm;
