import styled from 'styled-components';

export const StyledPeriodFilter = styled.div`
  border-bottom: 1px solid var(--grayscale-100);

  .datepicker-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 36px;
    flex-wrap: wrap;
    gap: 8px;

    .datepicker {
      width: 200px;
      margin-right: 16px;
    }
  }

  @media (max-width: 720px) {
    .datepicker {
      width: 100% !important;
    }
  }

  @media (max-width: 300px) {
    .datepicker-wrapper {
      .datepicker {
        input::placeholder {
          font-size: 14px;
        }
      }
    }
  }
`;
