export interface SubmittedImageProps {
  width: number;
  height: number;
}

export const getSubmittedImageProps = (file: File) => {
  return new Promise<SubmittedImageProps>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.src = event.target.result ? event.target.result.toString() : '';
      }
    };
    reader.readAsDataURL(file);
  });
};

export const limitFileName = (fileName: string, maxLength: number) => {
  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex !== -1 && lastDotIndex < fileName.length - 1) {
    const name = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex);

    const maxLengthWithoutExtension = maxLength - extension.length;

    return name.slice(0, maxLengthWithoutExtension) + extension;
  }

  return fileName.slice(0, maxLength);
};
