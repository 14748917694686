import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { TooltipProps } from './types';

import { StyledTooltip } from './styles';

const Tooltip: React.FC<TooltipProps> = ({
  afterShow,
  children,
  clickable = false,
  content,
  id,
  isOpen,
  noArrow = false,
  offset = 10,
  openOnClick = false,
  place = 'top',
  className = '',
  width = 'max-content',
  disabled = false,
}) => {
  return (
    <StyledTooltip className={`tooltip ${className}`.trim()} width={width}>
      <div data-tooltip-id={id}>{children}</div>
      {!disabled && (
        <ReactTooltip
          clickable={clickable}
          id={id}
          place={place}
          style={{ position: 'absolute' }}
          isOpen={isOpen}
          noArrow={noArrow}
          offset={offset}
          openOnClick={openOnClick}
          afterShow={afterShow}
        >
          {content}
        </ReactTooltip>
      )}
    </StyledTooltip>
  );
};

export default Tooltip;
