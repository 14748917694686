import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getContentComments,
  createContentComment,
  getContent,
  deleteContentComment,
} from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { dateAndTimeToApiDate } from 'utils/date';

import ContentCommentsHeader from './components/ContentCommentsHeader';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Comment from './components/Comment';
import CommentField from './components/CommentField';
import Button from 'components/molecule/Button';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import CommentLoading from './components/CommentLoading';
import Loading from 'components/molecule/Loading';

import { ContentCommentProps } from 'apis/board/types';
import UserState from 'store/slices/user/types';

import { StyledContentComments } from './styles';

const ContentCommnts: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { type, contentId: subcontentId } = useParams();

  const organizationId = useAppSelector((state) => state.organization.pk);
  const user: UserState = useAppSelector((state) => state.user);

  const [canComment, setCanComment] = useState(false);
  const [contentId, setContentId] = useState('');

  const [comments, setComments] = useState<ContentCommentProps[]>([]);
  const [subcomments, setSubcomments] = useState<ContentCommentProps[]>([]);
  const [pileCommentDetails, setPileCommentDetails] = useState<string[]>([]);
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const [newSubcommentLoading, setNewSubcommentLoading] = useState(false);
  const [commentBeingRemoved, setCommentBeingRemoved] = useState('');

  // Infinit scroll states
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);

  // Field state
  const [message, setMessage] = useState('');
  const [sendLoading, setSendLoading] = useState(false);
  const [lastUsedProfile, setLastUsedProfile] = useState(user.name);

  const loadComments = useCallback(
    (content: string, page: number, commentId?: string) => {
      getContentComments(organizationId, content, commentId, page)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);

          if (page > 1) {
            if (!commentId) {
              setComments((lastComments) => [
                ...lastComments,
                ...response.data.results,
              ]);
              setSubcomments([]);
            } else {
              setComments([{ ...response.data.parent, open_to_reply: true }]);
              setSubcomments((lastSubcomments) => [
                ...lastSubcomments,
                ...response.data.results,
              ]);
            }
            return;
          }

          if (!commentId) {
            setComments(response.data.results);
            setSubcomments([]);
            return;
          }

          setComments([{ ...response.data.parent, open_to_reply: true }]);
          setSubcomments(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('There was an error loading comments'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setNewSubcommentLoading(false);
          setNewCommentLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, organizationId, t],
  );

  const handleSeeComments = (commentId: string) => {
    setComments([]);
    setSubcomments([]);
    setLoading(true);
    loadComments(contentId, 1, commentId);
    const newPile = [...pileCommentDetails];
    newPile.push(commentId);
    setPileCommentDetails(newPile);
  };

  const handleBack = () => {
    const newPile = [...pileCommentDetails];
    newPile.pop();
    setPileCommentDetails(newPile);
    setComments([]);
    setSubcomments([]);
    setLoading(true);
    const commentToLoad = newPile[newPile.length - 1];
    loadComments(contentId, 1, commentToLoad);
  };

  const handleSendComment = (data: {
    owner: number | null;
    message: string;
  }) => {
    setSendLoading(true);
    createContentComment(
      organizationId,
      contentId,
      pileCommentDetails[pileCommentDetails.length - 1],
      {
        owner: data.owner,
        text: data.message,
      },
    )
      .then(() => {
        const commentWillBeReply =
          pileCommentDetails[pileCommentDetails.length - 1];

        if (commentWillBeReply) {
          setNewSubcommentLoading(true);
        } else {
          setNewCommentLoading(true);
        }

        loadComments(contentId, 1, commentWillBeReply);
        setMessage('');

        if (scrollElement) {
          scrollElement.scrollTop = 0;
        }
      })
      .catch((error) => {
        if (error.response.data.code === 'field_error') {
          dispatch(
            showMessage({
              title: error.response.data.errors[0].error,
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t('There was an error sending the comment'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const updateRemovedComment = (
    commentList: ContentCommentProps[],
    commentId: string,
    parentRemoved: boolean,
    userSlug: string,
  ) => {
    const currentDate = dateAndTimeToApiDate(
      new Date(),
      new Date().toLocaleTimeString('pt-BR').slice(0, 5),
    );

    return commentList.map((comment: ContentCommentProps) => {
      if (parentRemoved) {
        const updatedComment = {
          ...comment,
          has_removed: true,
          deleted_at: currentDate,
          deleted_by: userSlug,
          parent_removed: true,
        };
        return updatedComment;
      }

      if (comment.id === commentId && !parentRemoved) {
        const updatedComment = {
          ...comment,
          has_removed: true,
          deleted_at: currentDate,
          deleted_by: userSlug,
        };
        return updatedComment;
      }

      return comment;
    });
  };

  const handleRemove = (commentId: string) => {
    setCommentBeingRemoved(commentId);

    deleteContentComment(organizationId, contentId, commentId)
      .then(() => {
        const removedInComments = comments.some(
          (comment) => comment.id === commentId,
        );

        let updatedComments: ContentCommentProps[] = [...comments];
        let updatedSubcomments: ContentCommentProps[] = [];

        if (removedInComments) {
          updatedComments = updateRemovedComment(
            comments,
            commentId,
            false,
            user.slug,
          );
          updatedSubcomments = updateRemovedComment(
            subcomments,
            commentId,
            true,
            user.slug,
          );
        } else {
          updatedSubcomments = updateRemovedComment(
            subcomments,
            commentId,
            false,
            user.slug,
          );
        }

        setComments(updatedComments);
        setSubcomments(updatedSubcomments);

        dispatch(
          showMessage({
            title: t('Comment removed'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while removing the comment'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setCommentBeingRemoved('');
      });
  };

  useEffect(() => {
    setLoading(true);

    if (!type || !subcontentId) return;

    getContent(organizationId, type, subcontentId)
      .then((response) => {
        setCanComment(response.data.can_comment);
        setContentId(response.data.content_id);
        loadComments(response.data.content_id, 1);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('There was an error loading comments'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  }, [loadComments, organizationId, type, subcontentId, dispatch, t]);

  return (
    <StyledContentComments>
      <ContentCommentsHeader />

      {!loading && pileCommentDetails.length > 0 && (
        <Button
          theme="link-primary"
          leftIcon="arrow-left-line"
          className="back-button"
          onClick={handleBack}
        >
          {t('Back')}
        </Button>
      )}

      {loading &&
        (pileCommentDetails[pileCommentDetails.length - 1] ? (
          <>
            <CommentLoading className="comment-loading" />
            <CommentLoading className="subcomment-loading" />
            <CommentLoading className="subcomment-loading" />
            <CommentLoading className="subcomment-loading" />
            <CommentLoading className="subcomment-loading" />
            <CommentLoading className="subcomment-loading" />
            <CommentLoading className="subcomment-loading" />
          </>
        ) : (
          <>
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
            <CommentLoading className="comment-loading" />
          </>
        ))}

      <div
        className="comments-content default-scroll"
        ref={(currentRef) => setScrollElement(currentRef)}
      >
        {newCommentLoading && <CommentLoading className="comment-loading" />}
        <div className="comment-list">
          {comments.map((comment, index) => (
            <Comment
              key={index}
              id={comment.id}
              name={comment.name}
              avatar={comment.avatar}
              createdAt={comment.created_at}
              text={comment.text}
              totalComments={comment.total_responses}
              removedAt={comment.deleted_at}
              removedBy={comment.deleted_by}
              parentRemoved={comment.parent_removed}
              openToReply={comment.open_to_reply}
              onRemove={(id) => handleRemove(id)}
              onSeeComments={(id) => handleSeeComments(id)}
              removing={commentBeingRemoved === comment.id}
            />
          ))}
        </div>

        {newSubcommentLoading && (
          <CommentLoading className="subcomment-loading" />
        )}
        <div className="subcomments">
          {subcomments.map((comment, index) => (
            <Comment
              key={index}
              id={comment.id}
              name={comment.name}
              avatar={comment.avatar}
              createdAt={comment.created_at}
              text={comment.text}
              totalComments={comment.total_responses}
              removedAt={comment.deleted_at}
              removedBy={comment.deleted_by}
              parentRemoved={comment.parent_removed}
              onRemove={(id) => handleRemove(id)}
              onSeeComments={(id) => handleSeeComments(id)}
              removing={commentBeingRemoved === comment.id}
              hideSeeComments={true}
            />
          ))}
        </div>

        {!loading && comments.length === 0 && (
          <EmptyMessage
            title={t('No comment')}
            description={
              !canComment ? t('Comments were not enabled in this content') : ''
            }
            icon="chat-4-line"
          />
        )}

        {loadMoreLoading && <Loading type="bubbles" />}
      </div>

      {!loading && canComment && (
        <CommentField
          message={message}
          setMessage={setMessage}
          onSend={(data) => handleSendComment(data)}
          initialProfile={lastUsedProfile}
          setProfile={setLastUsedProfile}
          disabled={
            sendLoading ||
            (!!pileCommentDetails[pileCommentDetails.length - 1] &&
              comments[0] &&
              comments[0].has_removed)
          }
          loading={sendLoading}
        />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadComments(
            contentId,
            currentPage + 1,
            pileCommentDetails[pileCommentDetails.length - 1],
          );
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledContentComments>
  );
};

export default ContentCommnts;
