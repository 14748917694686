import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

import Text from 'components/atom/Text';
import ConfigCard from './components/ConfigCard';
import ManagementHeader from './components/ManagementHeader';

import { StyledManagementSections } from './styles';

const ManagementSections: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modules: string[] | null = useAppSelector(
    (state) => state.organization.modules,
  );

  const showSurveysAndEvaluations = () => {
    return (
      modules &&
      (modules.includes('module_performance') ||
        modules.includes('module_survey'))
    );
  };

  const showHierarchy = () => {
    return modules && modules.includes('module_setting_organization');
  };

  const showGeneralCustomization = () => {
    return modules && modules.includes('module_setting_organization');
  };

  const showVisualCustomization = () => {
    return modules && modules.includes('module_setting_organization');
  };

  const showMuralContents = () => {
    return (
      modules &&
      (modules.includes('module_comm') || modules.includes('module_advocacy'))
    );
  };

  const showOrganizationSessionLabel = () => {
    const allowedModules = [
      'module_comm',
      'module_advocacy',
      'module_performance',
      'module_survey',
      'module_training',
    ];
    return modules && allowedModules.some((module) => modules.includes(module));
  };

  return (
    <StyledManagementSections>
      <ManagementHeader />
      <div className="management-content">
        <div className="management-section">
          <div className="header">
            <Text as="h4" weight="700">
              {t('Organization')}
            </Text>
          </div>
          <div className="cards">
            <ConfigCard
              title={t('Profile and platform management')}
              subtitle={t('Basic platform edits')}
              icon="settings-4-fill"
              color="primary-color"
              onClick={() => navigate('/general/settings/custom-fields')}
              disabled={!showGeneralCustomization()}
            />
            <ConfigCard
              title={t('Visual identity')}
              subtitle={t('Customize colors, logo and covers')}
              icon="palette-fill"
              color="tertiary-color-3"
              onClick={() => navigate('/visual-identity/settings')}
              disabled={!showVisualCustomization()}
            />

            <ConfigCard
              title={t('Organizational structure')}
              subtitle={t('Departments and profiles')}
              icon="git-merge-line"
              color="support-pastel-color-2"
              onClick={() => navigate('/hierarchy/settings/positions')}
              disabled={!showHierarchy()}
            />

            <ConfigCard
              title={t('Collaborators')}
              subtitle={t('Administrators and participants')}
              icon="group-fill"
              color="secondary-color"
              onClick={() => navigate('/collaborators/settings')}
              disabled={!showHierarchy()}
            />
          </div>
        </div>

        <div className="management-section">
          {showOrganizationSessionLabel() && (
            <div className="header">
              <Text as="h4" weight="700">
                {t('Organization sections')}
              </Text>
            </div>
          )}
          <div className="cards">
            {showMuralContents() && (
              <ConfigCard
                title={t('Mural contents')}
                subtitle={t('Basic platform edits')}
                icon="dashboard-fill"
                color="primary-color"
                onClick={() => navigate('/mural/settings')}
              />
            )}
            {modules && modules.includes('module_training') && (
              <ConfigCard
                title={t('Academy and courses')}
                subtitle={t('Modules and presets')}
                icon="educator"
                color="primary-color"
                disabled
              />
            )}
            {showSurveysAndEvaluations() && (
              <ConfigCard
                title={t('Surveys and evaluations')}
                subtitle={t('Participant rules')}
                icon="level"
                color="primary-color"
                disabled
              />
            )}
          </div>
        </div>
      </div>
    </StyledManagementSections>
  );
};

export default ManagementSections;
