import React from 'react';

import { abbreviateNumberFormatter } from 'utils/numbers';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Tag from 'components/atom/Tag';

import { HierarchyTabOptionProps } from './types';

import { StyledHierarchyTabOption } from './styles';

const HierarchyTabOption: React.FC<HierarchyTabOptionProps> = ({
  total = null,
  icon,
  name,
}) => {
  return (
    <StyledHierarchyTabOption className="hierarchy-tab-option">
      <Icon name={icon} />
      <Text>{name}</Text>
      {total !== null && (
        <Tag padding="2px 4px" theme="grayscale-100">
          <Text as="small">{abbreviateNumberFormatter(total)}</Text>
        </Tag>
      )}
    </StyledHierarchyTabOption>
  );
};

export default HierarchyTabOption;
