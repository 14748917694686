import React from 'react';
import HTMLReactParser from 'html-react-parser';

import { StyledRichTextView } from './styles';

interface RichTextViewProps {
  content: string;
}

const RichTextView: React.FC<RichTextViewProps> = ({ content }) => {
  return <StyledRichTextView>{HTMLReactParser(content)}</StyledRichTextView>;
};

export default RichTextView;
