import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setUser } from 'store/slices/user';

import { getAccount } from 'apis/auth';

import { logout } from 'services/auth';

import useValidateAccess from 'hooks/auth/useValidateAccess';
import useUricConfig from 'hooks/general/useUricConfig';

import AppLoading from 'components/organism/AppLoading';

import { StyledStaffRestrictedLayout } from './styles';
import { setOrganizations } from 'store/slices/organizations';

interface StaffRestrictedLayoutProps {
  children?: React.ReactNode;
}

const StaffRestrictedLayout: React.FC<StaffRestrictedLayoutProps> = ({
  children,
}) => {
  const effectRan = useRef(false);
  const accessIsValid = useValidateAccess();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useUricConfig();

  const hasLoaded: number | null = useAppSelector((store) => store.user.id);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }

    getAccount()
      .then((response) => {
        const { avatar, name, pk, slug, organizations, is_staff, staff_areas } =
          response.data;
        dispatch(
          setUser({
            avatar,
            id: pk,
            name,
            slug,
            isStaff: is_staff,
            staffAreas: staff_areas,
          }),
        );
        dispatch(
          setOrganizations({
            list: organizations || [],
          }),
        );

        if (is_staff) {
          setLoaded(true);
        } else if ((organizations || []).length > 0) {
          navigate('/dashboard');
        } else {
          logout();
          navigate('/error-403');
        }
      })
      .catch(() => {
        logout();
        navigate('/error-403');
      });

    return () => {
      effectRan.current = true;
    };
  }, [navigate, dispatch, hasLoaded]);

  if (!accessIsValid || !loaded) {
    return <AppLoading removeAnimation />;
  }

  return <StyledStaffRestrictedLayout>{children}</StyledStaffRestrictedLayout>;
};

export default StaffRestrictedLayout;
