export const AvailableTagThemes = {
  default: 'default',
  'primary-100': 'primary-100',
  dark: 'dark',
  white: 'white',
  primary: 'primary',
  'primary-300': 'primary-300',
  secondary: 'secondary',
  'secondary-200': 'secondary-200',
  'tertiary-1': 'tertiary-1',
  'support-pastel-2': 'support-pastel-2',
  'gray-100': 'gray-100',
  'gray-200': 'gray-200',
  'gray-300': 'gray-300',
  'success-100': 'success-100',
  'danger-100': 'danger-100',
  'warning-100': 'warning-100',
  'grayscale-100': 'grayscale-100',
};
