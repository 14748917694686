import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';

import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';

import { CurrentProfileProps } from './types';

import { updateColaboratorProfile, getColaboratorProfile } from 'apis/config';

export const useCollaboratorProfile = ({
  id,
  onSave,
}: {
  id: string;
  onSave: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [loading, setLoading] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<CurrentProfileProps>({
    avatar: '',
    name: '',
    date_joined: '',
  });

  const [positions, setPositions] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);

  const options: readonly SelectOption[] = useMemo(
    () => [
      {
        value: 'admin',
        label: (
          <Text as="h6" className="p-4" color="grayscale-200">
            {t('Admin')}
          </Text>
        ),
      },
      {
        value: 'user',
        label: (
          <Text as="h6" className="p-4" color="grayscale-200">
            {t('User')}
          </Text>
        ),
      },
    ],
    [t],
  );

  const [selectedProfile, setSelectedProfile] = useState<SelectOption>(
    options.filter((option) => option.value === 'admin')[0],
  );

  const handleChangeProfile = (option: SingleSelectedOption) => {
    setSelectedProfile(option as SelectOption);
  };

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleSaveProfile = () => {
    setLoading(true);

    const isAdmin = selectedProfile.value === 'admin';

    updateColaboratorProfile(organizationId, id, isAdmin)
      .then(() => {
        onSave();

        dispatch(
          setModalView({
            show: false,
          }),
        );

        dispatch(
          showMessage({
            title: t('Profile updated successfully'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while loading the data updating the collaborator profile',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCollaboratorProfile = useCallback(() => {
    setLoading(true);

    getColaboratorProfile(organizationId, id)
      .then((response) => {
        const profileType = response.data.is_organization_admin
          ? 'admin'
          : 'user';

        setSelectedProfile(() => {
          return options.filter((option) => option.value === profileType)[0];
        });

        setCurrentProfile(response.data);
        setPositions(response.data.profiles);
        setDepartments(response.data.departments);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while loading the data'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id, options, organizationId, t]);

  useEffect(() => {
    loadCollaboratorProfile();
  }, [loadCollaboratorProfile]);

  return {
    t,
    options,
    loading,
    positions,
    departments,
    handleCancel,
    currentProfile,
    selectedProfile,
    handleSaveProfile,
    handleChangeProfile,
  };
};
