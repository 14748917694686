import React from 'react';

import Card from 'components/atom/Card';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { AvailableIcons } from 'components/atom/Icon/types';
import { AvailableColorTypes } from 'styles/global-styles';

import { StyledConfigCard } from './styles';

interface ConfigCardProps {
  title: string;
  subtitle: string;
  icon: keyof typeof AvailableIcons;
  color: keyof typeof AvailableColorTypes;
  disabled?: boolean;
  onClick?: () => void;
}

const ConfigCard: React.FC<ConfigCardProps> = ({
  title,
  subtitle,
  icon,
  color,
  disabled,
  onClick,
}) => {
  return (
    <StyledConfigCard onClick={onClick} disabled={disabled}>
      <Card
        shadow={!disabled ? 'true' : 'false'}
        noBorder="true"
        color={!disabled ? 'white-color' : 'gray-color'}
      >
        <Icon name={icon} color={!disabled ? color : 'grayscale-200'} />
        <Text as="h4" weight="700">
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </Card>
    </StyledConfigCard>
  );
};

export default ConfigCard;
