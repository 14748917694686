import styled from 'styled-components';

export const StyledPollOptions = styled.div`
  width: 100%;
  position: relative;
  .option-list {
    & > .tooltip {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
  }
`;
