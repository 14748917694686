import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getProfileConfigItems, getHierarchyTotals } from 'apis/config';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { closeModal, setModalView } from 'store/slices/modal';

import Button from 'components/molecule/Button';
import TabMenu from 'components/molecule/TabMenu';
import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import TagGrouper from 'components/molecule/TagGrouper';
import ProfileItemActions from './components/ProfileItemActions';
import ProfileList from '../ProfileList';
import ProfileForm from './components/ProfileForm';
import HierarchyTabOption from '../HierarchyTabOption';
import Modal from 'components/molecule/Modal';
import Separator from 'components/atom/Separator';

import {
  ProfilesConfigProps,
  ProfileConfigItem,
  ProfileFilter,
  ProfileRowActions,
} from './types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
  SearchableValue,
} from 'components/organism/Table/types';

import { StyledProfilesConfig } from './styles';

const getTableRows = (
  data: ProfileConfigItem[],
  actions: ProfileRowActions,
) => {
  return data.map((item) => {
    return {
      cells: [
        {
          content: <Text color="grayscale-400">{item.name}</Text>,
        },
        {
          content:
            item.managers.total > 0 ? (
              <TagGrouper
                tags={item.managers.results}
                total={item.managers.total}
                displayLimit={2}
                characterLimit={20}
              />
            ) : (
              '-'
            ),
          clickable: item.managers.total > 0,
          onClick: () => {
            actions.showProfileList('managers', item.id, item.name);
          },
        },
        {
          content:
            item.manageds.total > 0 ? (
              <TagGrouper
                tags={item.manageds.results}
                total={item.manageds.total}
                displayLimit={2}
                characterLimit={20}
              />
            ) : (
              '-'
            ),
          clickable: item.manageds.total > 0,
          onClick: () =>
            actions.showProfileList('manageds', item.id, item.name),
        },
        {
          content: (
            <ProfileItemActions
              onEdit={() => {
                if (!actions.loadProfiles) return;
                actions.showProfileForm(
                  actions.loadProfiles,
                  item.id,
                  item.name,
                );
              }}
            />
          ),
        },
      ],
    };
  });
};

const ProfilesConfig: React.FC<ProfilesConfigProps> = ({
  tabOptions,
  changeTabOption,
  setMenuOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<ProfileFilter>({
    order: '',
    search: '',
    managed: '',
    manager: '',
  });

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCreationForm, setIsCreationForm] = useState(true);

  const showProfileList = useCallback(
    (type: string, id: string, name: string) => {
      dispatch(
        setModalView({
          show: true,
          width: '600px',
          content: <ProfileList type={type} id={id} name={name} />,
        }),
      );
    },
    [dispatch],
  );

  const showProfileForm = useCallback(
    (
      onLoad: (page: number, filter: ProfileFilter) => void,
      id?: string,
      name?: string,
    ) => {
      dispatch(
        setModalView({
          show: true,
          width: '715px',
          content: (
            <ProfileForm
              id={id}
              name={name}
              onLoad={() => onLoad(1, filter)}
              setShowCancelModal={setShowCancelModal}
            />
          ),
          disableBackgroundClick: true,
          onCloseButtonClick: () => {
            setShowCancelModal(true);
          },
        }),
      );
      setIsCreationForm(!id);
    },
    [dispatch, filter],
  );

  const loadProfiles: (page: number, filter: ProfileFilter) => void =
    useCallback(
      (page: number, filter: ProfileFilter) => {
        setTableLoading(true);

        getHierarchyTotals(organizationId).then((totalResponse) => {
          if (!setMenuOptions) return;

          setMenuOptions((lastMenuOptions) =>
            lastMenuOptions.map((option) => {
              if (option.key === 'positions') {
                return {
                  ...option,
                  content: (
                    <HierarchyTabOption
                      icon="group-fill"
                      name={t('Positions')}
                      total={totalResponse.data.total_profiles}
                    />
                  ),
                };
              }
              if (option.key === 'departments') {
                return {
                  ...option,
                  content: (
                    <HierarchyTabOption
                      icon="folder-fill"
                      name={t('Departments')}
                      total={totalResponse.data.total_departments}
                    />
                  ),
                };
              }
              return option;
            }),
          );

          getProfileConfigItems(organizationId, page, filter)
            .then((response) => {
              const { num_pages, results } = response.data;
              setPageCount(num_pages);
              setCurrentPage(page);
              setTableRows(
                getTableRows(results, {
                  showProfileList,
                  showProfileForm,
                  loadProfiles,
                }),
              );
            })
            .catch((error) => {
              if (error.response.status === 403) {
                window.location.pathname = '/settings';
                dispatch(
                  showMessage({
                    title: t('You do not have permission to access this page'),
                    theme: 'danger',
                    icon: 'close',
                    time: 10000,
                  }),
                );
                return;
              }
              dispatch(
                showMessage({
                  title: t(
                    'An unexpected error occurred while loading the positions',
                  ),
                  theme: 'danger',
                  icon: 'close',
                  time: 10000,
                }),
              );
            })
            .finally(() => {
              setTableLoading(false);
            });
        });
      },
      [
        dispatch,
        organizationId,
        t,
        showProfileList,
        showProfileForm,
        setMenuOptions,
      ],
    );

  const handleSearch = (values: SearchableValue[]) => {
    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        search: values.find((value) => value.key === 'name')?.value || '',
        managed: values.find((value) => value.key === 'manageds')?.value || '',
        manager: values.find((value) => value.key === 'managers')?.value || '',
      };
      loadProfiles(1, newFilter);
      return newFilter;
    });
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    const { value } = sortableValue;
    let newOrder = '';

    if (value === 1) {
      newOrder = 'name';
    }

    if (value === 0) {
      newOrder = '';
    }

    if (value === -1) {
      newOrder = '-name';
    }

    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        order: newOrder,
      };
      loadProfiles(1, newFilter);
      return newFilter;
    });
  };

  const handleChangePage = (newPage: number) => {
    loadProfiles(newPage, filter);
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Position')}</Text>,
      sortable: {
        key: 'name',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
      searchable: {
        key: 'name',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by position'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Leaders')}</Text>,
      searchable: {
        key: 'managers',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by leaders'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Subordinates')}</Text>,
      searchable: {
        key: 'manageds',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by subordinates'),
        value: '',
      },
    },
    {
      content: '',
    },
  ]);

  useEffect(() => {
    loadProfiles(1, filter);
  }, [loadProfiles, filter]);

  return (
    <StyledProfilesConfig className="profiles-config">
      <div className="content-header">
        <Button
          theme="primary-outline"
          rounded="true"
          leftIcon="add"
          onClick={() => {
            showProfileForm(loadProfiles);
          }}
        >
          {t('Add position')}
        </Button>
        <TabMenu tabs={tabOptions} onChangeTab={changeTabOption} />
        <Button
          theme="link-gray-primary"
          leftIcon="table"
          to="/hierarchy/import"
        >
          {t('Upload sheet')}
        </Button>
      </div>
      <div className="content">
        <Table
          columns={columns}
          setColumns={setColumns}
          rows={tableRows}
          textAlign="start"
          fontColor="grayscale-200"
          hasPagination
          pageCount={pageCount}
          page={currentPage - 1}
          onChangePage={(page) => handleChangePage(page + 1)}
          loading={tableLoading}
          scrollable
        />
      </div>

      {showCancelModal && (
        <Modal
          content={
            <div className="cancel-form-confirm">
              <Text as="h4" weight="700" className="title">
                {!isCreationForm
                  ? t('Do you want to cancel position update?')
                  : t('Do you want to cancel the creation of a new position?')}
              </Text>
              {!isCreationForm && (
                <Text as="h6" className="description">
                  {t(
                    'You may not reuse this information. However, you can create this role again whenever you want.',
                  )}
                </Text>
              )}
              <Separator />
              <div className="cancel-modal-actions">
                <Button
                  theme="link-gray-primary"
                  leftIcon="arrow-left-s-line"
                  onClick={() => {
                    setShowCancelModal(false);
                  }}
                >
                  {t('Back')}
                </Button>
                <Button
                  theme="white"
                  onClick={() => {
                    dispatch(closeModal());
                    setShowCancelModal(false);
                  }}
                >
                  {!isCreationForm ? t('Cancel update') : t('Cancel creation')}
                </Button>
              </div>
            </div>
          }
          hideCloseButton
          disableBackgroundClick
          width="700px"
        />
      )}
    </StyledProfilesConfig>
  );
};

export default ProfilesConfig;
