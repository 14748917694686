import styled from 'styled-components';

export const StyledSurveyDetails = styled.div`
  width: 100%;

  .skeleton-loading {
    margin-bottom: 16px;
  }

  .details-wrapper {
    width: 100%;

    .cover {
      width: 100%;
      height: auto;
      min-height: 60px;
      position: relative;
      margin-bottom: 24px;

      .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        padding: 10px 32px;

        .button {
          border: 1px solid var(--grayscale-100);
        }

        .counters {
          display: flex;
          align-items: center;

          .tag {
            margin-left: 8px;
            border: 1px solid var(--grayscale-100);
          }
        }
      }

      .image {
        width: 100%;
        height: auto;
      }
    }

    .content-elements {
      padding: 0px 32px;

      pre {
        margin-bottom: 4px;
      }

      .title {
        margin-bottom: 8px;
      }

      .description {
        margin-bottom: 20px;
        line-height: 1.2;
      }

      .poll-type {
        margin-bottom: 16px;
      }

      .options {
        .card {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 10px;
          margin-bottom: 16px;

          .check-wrapper {
            min-width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
          }
        }
      }

      .card {
        width: 100%;
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;

        .icon {
          margin-right: 6px;
        }
      }
    }
  }
`;
