import React from 'react';

import Text from 'components/atom/Text';

import { StyledCheckbox } from './styles';

interface CheckboxProps {
  text?: React.ReactNode;
  className?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  text,
  className = '',
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledCheckbox
      className={`checkbox content-container ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {text && <Text>{text}</Text>}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </StyledCheckbox>
  );
};

export default Checkbox;
