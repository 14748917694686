import styled from 'styled-components';

export const StyledMuralTableContent = styled.div`
  display: flex;
  align-items: center;

  .image-wrapper {
    position: relative;

    .image {
      margin-right: 12px;
      min-width: 124px;
    }

    .tag {
      position: absolute;
      top: -12px;
      left: -12px;
      width: 24px;
      height: 24px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  .texts {
    .title {
      display: -webkit-box;
      max-width: 264px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.2;
    }
    .description {
      margin-top: 4px;
    }
  }
`;
