import styled from 'styled-components';

export const StyledContentHistory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .history-item-loading {
    padding-left: 32px;
  }

  .history-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .history-item-list {
      width: 100%;
    }

    .empty-message {
      margin-top: 200px;

      h6 {
        color: var(--grayscale-200);
        font-size: 24px;
      }
    }
  }
`;
