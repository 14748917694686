import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PublicationDateConfig from './components/PublicationDateConfig';
import RecurrenceConfig from './components/RecurrenceConfig';
import TabMenu from 'components/molecule/TabMenu';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { PublicationDateModalProps } from './types';

import { StyledPublicationDateModal } from './styles';
import { hideModal, showModal } from 'store/slices/modal';
import SubscriptionModal from 'components/molecule/FreeTrialButtonWrapper/SubscriptionModal';

const PublicationDateModal: React.FC<PublicationDateModalProps> = ({
  contentState,
  setContentState,
  canEditFinishAt,
  withRecurrence,
  setWithRecurrence,
  contentStatus,
  canSetRecurrence,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isFreeTrial = useAppSelector((state) => state.organization.is_free);

  const [menuOptions, setMenuOptions] = useState([
    {
      key: 'without-recurrence',
      content: t('Without recurrence'),
      active: !withRecurrence,
      allowedOnFreeTrial: true,
    },
    {
      key: 'with-recurrence',
      content: t('Recurrence'),
      active: withRecurrence,
      allowedOnFreeTrial: false,
    },
  ]);
  const [subscriptionModalIsVisible, setSubscriptionModalIsVisible] =
    useState(false);

  const onChangeTab = (key: string) => {
    if (isFreeTrial && key === 'with-recurrence') {
      dispatch(hideModal());
      setSubscriptionModalIsVisible(true);
      return;
    }

    setMenuOptions(
      menuOptions.map((option) => {
        if (option.key === key) {
          option.active = true;
          return option;
        }

        option.active = false;
        return option;
      }),
    );
  };

  const handleCloseModal = () => {
    dispatch(showModal());
    setSubscriptionModalIsVisible(false);
    onChangeTab('without-recurrence');
  };

  return (
    <StyledPublicationDateModal>
      {canSetRecurrence ? (
        <>
          <TabMenu tabs={menuOptions} onChangeTab={onChangeTab} />

          <div className="separator" />

          {menuOptions[0].active && (
            <PublicationDateConfig
              contentState={contentState}
              setContentState={setContentState}
              canEditFinishAt={canEditFinishAt}
              setWithRecurrence={setWithRecurrence}
              contentStatus={contentStatus}
            />
          )}

          {menuOptions[1].active && (
            <RecurrenceConfig
              contentState={contentState}
              setContentState={setContentState}
              canEditFinishAt={canEditFinishAt}
              setWithRecurrence={setWithRecurrence}
              contentStatus={contentStatus}
            />
          )}
        </>
      ) : (
        <PublicationDateConfig
          contentState={contentState}
          setContentState={setContentState}
          canEditFinishAt={canEditFinishAt}
          setWithRecurrence={setWithRecurrence}
          contentStatus={contentStatus}
        />
      )}

      {!!subscriptionModalIsVisible && (
        <SubscriptionModal
          onCloseButtonClick={() => handleCloseModal()}
          hide={false}
          onSubscribe={() => handleCloseModal()}
        />
      )}
    </StyledPublicationDateModal>
  );
};

export default PublicationDateModal;
