import styled from 'styled-components';

export const StyledCommentField = styled.div`
  width: 100%;
  padding: 32px 54px;
  border-top: 1px solid var(--gray-color);

  display: flex;

  .select {
    margin-top: 20px;
    .react-select {
      width: max-content;
      margin-top: 4px;

      .react-select__control {
        padding: 0;

        &.react-select__control--is-disabled {
          background: none;
        }

        .profile-option {
          .texts {
            display: none;
          }
        }
      }

      .react-select__menu {
        bottom: 100%;
        top: auto;
        width: max-content;
        max-width: 300px;

        .profile-option {
          display: flex;
          padding: 16px;

          .avatar {
            margin-right: 10px;
            min-width: 34px;
          }

          .texts {
            .name {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .field-wrapper {
    flex: 1;
    span {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      pre {
        margin-right: 4px;
      }
    }
  }

  .button {
    margin-left: 14px;
    margin-top: 24px;
  }
`;
