import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import { getTags, createTag } from 'apis/board';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import Icon from 'components/atom/Icon';
import Loading from 'components/molecule/Loading';
import Separator from 'components/atom/Separator';

import { TagsModalProps } from './types';
import { TagParams } from 'apis/board/types';

import { StyledTagsModal } from './styles';

const TagsModal: React.FC<TagsModalProps> = ({ selectedTagList, onSave }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const maxTagNumber = 10;
  const maxCharTagLength = 30;

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [tags, setTags] = useState<TagParams[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [selectedTags, setSelectedTags] = useState(selectedTagList);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');

  const loadTags = useCallback(
    (page: number, search: string, currentSelectedTags: TagParams[]) => {
      if (page > 1) {
        setFetchMoreLoading(true);
      } else {
        setLoading(true);
      }

      getTags(organizationId, page, search)
        .then((response) => {
          const { results, count, num_pages } = response.data;

          if (page > 1) {
            setTags((lastTags) =>
              [...lastTags, ...results].filter(
                (tag) =>
                  !currentSelectedTags.some((item) => item.id === tag.id),
              ),
            );
          } else {
            setTags(
              results.filter(
                (tag: TagParams) =>
                  !currentSelectedTags.some((item) => item.id === tag.id),
              ),
            );
          }

          setCurrentPage(page);
          setTotal(count);
          setTotalPages(num_pages);
          setFetchMoreLoading(false);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An error occurred while fetching tags'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
          setTags([]);
          setFetchMoreLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
          setAddLoading(false);
        });
    },
    [dispatch, t, organizationId],
  );

  const handleLoadingMore = () => {
    loadTags(currentPage + 1, search, selectedTags);
  };

  const handleSearch = (value: string) => {
    setSearchLoading(true);

    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const timeOut = setTimeout(() => {
      loadTags(1, value, selectedTags);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
    setSearchErrorMessage('');
  };

  const handleSelect = (tagId: string) => {
    setTags((lastState) => {
      const selectTagIndex = lastState.findIndex((tag) => tag.id === tagId);
      const removedTag = lastState.splice(
        selectTagIndex,
        selectTagIndex !== -1 ? 1 : 0,
      );

      if (removedTag) {
        setSelectedTags((tags) => {
          return [...removedTag, ...tags];
        });
      }

      return lastState;
    });
  };

  const handleRemove = (tagId: string) => {
    const newState = [...selectedTags];
    const selectTagIndex = newState.findIndex((tag) => tag.id === tagId);

    newState.splice(
      selectTagIndex !== -1 ? selectTagIndex : 1,
      selectTagIndex !== -1 ? 1 : 0,
    );

    loadTags(1, search, newState);
    setSelectedTags(newState);
  };

  const handleCreateTag = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchLoading || !search) return;

    if (search.length > maxCharTagLength) {
      setSearchErrorMessage(
        `${t('The tag must have a maximum of')} ${maxCharTagLength} ${t(
          'characters',
        )}`,
      );
      return;
    }

    if (
      selectedTags.some(
        (tag) => tag.title.toLowerCase() === search.toLocaleLowerCase(),
      )
    ) {
      setSearchErrorMessage(t('This tag has already been added'));
      return;
    }

    if (
      tags.some((tag) => tag.title.toLowerCase() === search.toLocaleLowerCase())
    ) {
      const [newSelectedTag] = tags.filter(
        (tag) => tag.title.toLowerCase() === search.toLowerCase(),
      );

      handleSelect(newSelectedTag.id);
      return;
    }

    setAddLoading(true);

    createTag(organizationId, search)
      .then((response) => {
        const { id, title } = response.data;

        setSearch('');
        const newSelectedTags = [{ id, title }, ...selectedTags];
        loadTags(1, '', newSelectedTags);
        setSelectedTags(newSelectedTags);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An error occurred while adding the tag'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
            customLeft: '0px',
          }),
        );
        setAddLoading(false);
      });
  };

  const handleSave = () => {
    onSave(selectedTags);
    dispatch(closeModal());
  };

  useEffect(() => {
    loadTags(1, '', selectedTagList);
  }, [loadTags, selectedTagList]);

  return (
    <StyledTagsModal>
      <Text as="h5" weight="700">
        {t('Select content tags')}
      </Text>
      <Text as="p" color="grayscale-200">
        {selectedTags.length} {t('of')} {total} {t('selected')}
      </Text>
      <div className="search-row">
        <form onSubmit={handleCreateTag}>
          <Input
            theme="post"
            placeholder={t('Search by name or add a new tag')}
            icon="search"
            iconColor="primary-color"
            actions={
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => handleSearch('')}
              >
                <Icon name="close-circle-fill" />
              </Button>
            }
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
            errorMessage={searchErrorMessage}
            disabled={addLoading}
          />
          <Button
            theme="primary-flat"
            leftIcon="add"
            rounded="true"
            type="submit"
            disabled={
              searchLoading ||
              addLoading ||
              selectedTags.length >= maxTagNumber ||
              !search
            }
          >
            {t('Add')}
          </Button>
        </form>
      </div>

      {!loading && !addLoading && (
        <>
          <Text as="h6" weight="700" className="founded-tags-count">
            {tags.length} {t('tags found')}
          </Text>

          <div className="tag-list">
            {tags.map((tag) => (
              <Button
                key={tag.id}
                size="small"
                theme="dark-outline"
                onClick={() => handleSelect(tag.id)}
                disabled={selectedTags.length >= maxTagNumber}
              >
                {tag.title}
              </Button>
            ))}
          </div>

          {!fetchMoreLoading && totalPages !== currentPage && total !== 0 && (
            <div className="load-more-button-wrapper">
              <Button
                theme="link-primary"
                leftIcon="add"
                rounded="true"
                onClick={handleLoadingMore}
              >
                {t('Load more tags')}
              </Button>
            </div>
          )}

          {!loading && !fetchMoreLoading && tags.length === 0 && search && (
            <Text weight="700" color="grayscale-200" align="center">
              {t('Search for another name or create a new tag')}
            </Text>
          )}
        </>
      )}

      {(loading || fetchMoreLoading || addLoading) && (
        <Loading type="bubbles" />
      )}

      {!loading && !addLoading && (
        <>
          {selectedTags.length > 0 && (
            <>
              <Separator />
              <Text as="h6" weight="700" className="founded-tags-count">
                {selectedTags.length}{' '}
                {t('tags selected', {
                  count: selectedTags.length,
                })}
              </Text>
            </>
          )}

          <div className="selected-tag-list">
            {selectedTags.map((tag) => (
              <Button
                key={tag.id}
                size="small"
                theme="dark"
                onClick={() => handleRemove(tag.id)}
              >
                {tag.title}
                <Icon name="close" />
              </Button>
            ))}
          </div>
        </>
      )}

      {!loading && !fetchMoreLoading && !addLoading && (
        <Button
          theme="primary"
          rounded="true"
          className="save-button"
          disabled={addLoading}
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      )}
    </StyledTagsModal>
  );
};

export default TagsModal;
