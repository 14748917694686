import styled from 'styled-components';

export const StyledRichTextView = styled.div`
  letter-spacing: 0.5px;
  line-height: 1.4;
  word-wrap: break-word;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  p {
    font-size: 16px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  h5 {
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  h6 {
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  em {
    font-style: italic;
  }

  blockquote {
    border-left: 2px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 1rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
