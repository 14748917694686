import styled from 'styled-components';

export const StyledChoiceOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grayscale-100);
  padding: 10px 0px;
  position: relative;

  &:last-child {
    border: none;
  }

  & > .content {
    display: flex;
    align-items: center;
    gap: 6px;

    .tooltip {
      .button:first-child {
        min-width: max-content;
        .button-content {
          * {
            color: var(--grayscale-300);
            max-width: 54ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        :hover {
          .button-content {
            * {
              color: var(--grayscale-400);
            }
          }
        }
        :disabled {
          .button-content {
            * {
              color: var(--grayscale-200);
            }
          }
        }
      }
    }

    .input {
      min-width: 378px;
    }

    .save-button {
      background: var(--tertiary-color-1);
      padding: 8px 12px;
      height: max-content;
      min-height: max-content;
      margin-top: -24px;
      :hover {
        background: var(--tertiary-color-1);
        opacity: 0.9;
      }
      &:disabled {
        background-color: var(--grayscale-100);
      }
    }
  }

  & > .button {
    padding-right: 0px;
    & > i:last-child {
      display: none;
    }
    .button-content {
      justify-content: flex-start;
      .icon {
        font-size: 24px;
      }
    }

    &.in-edit {
      margin-top: -24px;
    }
  }
`;
