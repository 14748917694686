import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';
import SchedulePreview from '../SchedulePreview';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { SchedulerStateProps } from '../PublicationDateModal/components/RecurrenceConfig/types';

import { StyledConfirmPublish } from './styles';

interface ConfirmPublishProps {
  startAt?: string | null;
  startAtTime?: string | null;
  finishAt?: string | null;
  finishAtTime?: string | null;
  onConfirm: () => void;
  withRecurrence: boolean;
  scheduleState: SchedulerStateProps;
}

const ConfirmPublish: React.FC<ConfirmPublishProps> = ({
  startAt,
  startAtTime,
  finishAt,
  finishAtTime,
  onConfirm,
  withRecurrence,
  scheduleState,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledConfirmPublish>
      <div className="modal-content">
        <Text as="h4" weight="700">
          {t('You confirm the publication definition of this content?')}
        </Text>
        <Text as="h6">
          {t(
            'You can change the dates whenever you want until the day of publication.',
          )}
        </Text>
        {withRecurrence && <SchedulePreview schedulerState={scheduleState} />}

        {!withRecurrence && (
          <div className="publish-dates">
            <div className={`item ${!finishAt ? 'is-single' : ''}`}>
              <div className="point-wrapper">
                <div className="point"></div>
              </div>
              <Text as="pre" color="grayscale-200">
                {t('The content will be published in')}
              </Text>
              <Text as="p" weight="700">
                {t('{{start_at}} at {{start_at_time}}', {
                  start_at: startAt,
                  start_at_time: startAtTime,
                })}
              </Text>
            </div>
            {finishAt && (
              <div className="item">
                <div className="point-wrapper">
                  <div className="point"></div>
                </div>
                <Text as="pre" color="grayscale-200">
                  {t('It will be finished on the mural in')}
                </Text>
                <Text as="p" weight="700">
                  {t('{{finish_at}} at {{finish_at_time}}', {
                    finish_at: finishAt,
                    finish_at_time: finishAtTime,
                  })}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>

      <Separator />
      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleConfirm}>{t('Confirm')}</Button>
      </div>
    </StyledConfirmPublish>
  );
};

export default ConfirmPublish;
