import styled from 'styled-components';

import { StyledTooltipProps } from './types';

export const StyledTooltip = styled.div<StyledTooltipProps>`
  position: relative;

  & > div {
    width: max-content;
  }

  .react-tooltip {
    opacity: 0;
    animation: fadeIn 0.4s;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    -ms-animation: fadeIn 0.4s;
    animation-fill-mode: forwards;

    background-color: var(--dark-color);
    color: var(--white-color);
    width: ${({ width }) => width};
    font-size: 14px;
    border-radius: 6px;
    word-break: break-word;
  }
`;
