import styled from 'styled-components';

export const StyledPublicationDateConfig = styled.div`
  h5 {
    margin-bottom: 24px;
  }

  .alert {
    margin-bottom: 24px;
    .description {
      font-size: 14px;
    }
  }

  .form-group {
    display: flex;
    align-items: flex-start;
    margin-bottom: 42px;

    .datepicker {
      margin-right: 10px;
    }

    .input {
      max-width: 122px;
    }
  }

  .button {
    margin-top: 22px;
    width: 100%;
  }
`;
