import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import MultiRange from 'components/atom/MultiRange';
import Input from 'components/molecule/Input';
import Loading from 'components/molecule/Loading';

import { MultiRangeValueProps } from 'components/atom/MultiRange/types';

import { StyledNPSScale } from './styles';

interface NPSScaleProps {
  scaleSize: number;
  minValue: number;
  maxValue: number;
  minValueLabel: string;
  maxValueLabel: string;
  onChangeMinValue: (value: number) => void;
  onChangeMaxValue: (value: number) => void;
  onChangeMinValueLabel: (value: string) => void;
  onChangeMaxValueLabel: (value: string) => void;
  minPlaceholderLabel?: string;
  maxPlaceholderLabel?: string;
  initialScale?: number;
  isDisabled: boolean;
  hasLabels: boolean;
}

let scaleSizeChangeObserver = 1;

const NPSScale: React.FC<NPSScaleProps> = ({
  scaleSize,
  minValue,
  maxValue,
  minValueLabel,
  maxValueLabel,
  onChangeMinValue,
  onChangeMaxValue,
  onChangeMinValueLabel,
  onChangeMaxValueLabel,
  minPlaceholderLabel,
  maxPlaceholderLabel,
  initialScale = 1,
  isDisabled,
  hasLabels,
}) => {
  const { t } = useTranslation();

  const rangeWidthDifference = useMemo(
    () => 100 / (scaleSize - scaleSize / 2),
    [scaleSize],
  );
  const stepValue = useMemo(() => 100 / (scaleSize - 2), [scaleSize]);

  const [scaleItems, setScaleItems] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const handleChangeRangeValue = (rangeValues: MultiRangeValueProps) => {
    if (Math.round(rangeValues.minValue) !== Math.round(minValue)) {
      onChangeMinValue(
        scaleSizeChangeObserver === scaleSize ? rangeValues.minValue : 0,
      );
    }

    if (Math.round(rangeValues.maxValue) !== Math.round(maxValue)) {
      onChangeMaxValue(
        scaleSizeChangeObserver === scaleSize ? rangeValues.maxValue : 100,
      );
    }
  };

  const handleChangeMinValueLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChangeMinValueLabel(event.target.value);
  };

  const handleChangeMaxValueLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChangeMaxValueLabel(event.target.value);
  };

  useEffect(() => {
    const items: number[] = [];
    for (let i = 0; i < scaleSize; i += 1) {
      items.push(i + initialScale);
    }
    setScaleItems(() => items);

    // force render range
    setLoading(() => true);
    setTimeout(() => {
      setLoading(() => false);
      scaleSizeChangeObserver = scaleSize;
    }, 400);
  }, [scaleSize, initialScale]);

  return (
    <StyledNPSScale>
      {!loading && (
        <>
          <div className="nps-labels">
            <Text color="danger-color" weight="700">
              {t('Negative')}
            </Text>
            <Text color="grayscale-200" weight="700">
              {t('Neutral')}
            </Text>
            <Text color="success-color" weight="700">
              {t('Positive')}
            </Text>
          </div>
          <div className="range-scale">
            <div className="multi-range-scale">
              <div
                className="range"
                style={{
                  width: `calc(100% - ${rangeWidthDifference}%)`,
                }}
              >
                <MultiRange
                  type="large"
                  barLeftColor="var(--danger-color)"
                  barRightColor="var(--success-color)"
                  barInnerColor="var(--gray-color)"
                  thumbLeftColor="var(--white-color)"
                  thumbRightColor="var(--white-color)"
                  min={0}
                  max={100}
                  step={stepValue}
                  minValue={minValue}
                  maxValue={maxValue}
                  stepOnly
                  onChange={handleChangeRangeValue}
                />
              </div>
            </div>
            <div className="scale">
              {scaleItems.map((item, index) => (
                <div className="scale-item" key={index}>
                  <Text as="h6" weight="700">
                    {item}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="nps-fields">
            <div className="nps-field">
              <Text>1 - </Text>
              <Input
                placeholder={minPlaceholderLabel || t('Disagree')}
                limit={30}
                value={minValueLabel}
                onChange={handleChangeMinValueLabel}
                disabled={isDisabled || hasLabels === false}
              />
            </div>
            <div className="nps-field">
              <Text>{scaleSize} - </Text>
              <Input
                placeholder={maxPlaceholderLabel || t('Agree')}
                limit={30}
                value={maxValueLabel}
                onChange={handleChangeMaxValueLabel}
                disabled={isDisabled || hasLabels === false}
              />
            </div>
          </div>
        </>
      )}
      {loading && (
        <div className="loading">
          <Loading />
        </div>
      )}
    </StyledNPSScale>
  );
};

export default NPSScale;
