import styled from 'styled-components';

export const StyledPendingExports = styled.div`
  h5 {
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 32px;
  }

  .report-list {
    width: 100%;
    max-height: 272px;
    overflow-y: scroll;

    .report-item {
      width: 100%;
      padding: 16px;
      border-bottom: 1px solid var(--grayscale-100);

      &:first-child {
        border-top: 1px solid var(--grayscale-100);
      }

      p {
        margin: 0px;
      }

      .text {
        margin-bottom: 4px;
      }
    }
  }
`;
