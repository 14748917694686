import React from 'react';

import useColorPalette from './useColorPalette';

import Text from 'components/atom/Text';
import Select from 'components/molecule/Select';
import { SingleSelectedOption } from 'components/molecule/Select/types';
import { ColorPaletteProps } from './types';

const ColorPalette: React.FC<ColorPaletteProps> = ({
  fieldErrors,
  setFieldErrors,
  paletteSelectOption,
  setPaletteSelectOption,
}) => {
  const {
    t,
    loading,
    paletteFieldErros,
    paletteOptions,
    paletteOption,
    handleChangeColorPalette,
  } = useColorPalette({
    fieldErrors,
    setFieldErrors,
    paletteSelectOption,
    setPaletteSelectOption,
  });

  return (
    <div className="">
      <Text as="h5" weight="700" color="grayscale-400">
        {t('Color palette')}
      </Text>
      <Text color="grayscale-200" className="mt-0.5">
        {t('Customize your community according to the available palettes.')}
      </Text>
      <Select
        value={paletteOption}
        setValue={(option) =>
          handleChangeColorPalette(option as SingleSelectedOption)
        }
        options={paletteOptions}
        placeholder={
          <div>
            {loading ? (
              <Text color="grayscale-200">{t('Loading...')}</Text>
            ) : (
              <Text color="grayscale-200">{t('Select...')}</Text>
            )}
          </div>
        }
        hasError={paletteFieldErros.palette_color.hasError}
        errorMessage={paletteFieldErros.palette_color.errorMessage}
        className="mt-3"
        isDisabled={loading}
      />
    </div>
  );
};

export default ColorPalette;
