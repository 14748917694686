import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledPaymentForm } from './styles';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import { SelectOption } from 'components/molecule/Select/types';
import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import DatePicker from 'components/molecule/DatePicker';
import Separator from 'components/atom/Separator';
import { PaymentFields, KeyValueOptionProps } from './types';
import { useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getFinancialPayment,
  getFinancialPaymentOptions,
  updateFinancialPayment,
} from 'apis/staff';
import { PaymentPostSendData } from 'apis/staff/types';
import { dateToApiDate } from 'utils/date';
import { RequestFieldError } from 'apis/types';
import { showMessage } from 'store/slices/toaster';

const PaymentForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const [statusTypes, setStatusTypes] = useState([]);
  const [payment, setPayment] = useState<PaymentFields>({
    contract: {
      client: { value: null, error: { hasError: false, errorMessage: '' } },
      organizations: {
        value: null,
        error: { hasError: false, errorMessage: '' },
      },
      contract_type: {
        value: null,
        error: { hasError: false, errorMessage: '' },
      },
      start_at: { value: null, error: { hasError: false, errorMessage: '' } },
      finish_at: { value: null, error: { hasError: false, errorMessage: '' } },
    },
    ref_date: { value: null, error: { hasError: false, errorMessage: '' } },
    exp_date: { value: null, error: { hasError: false, errorMessage: '' } },
    pay_date: { value: null, error: { hasError: false, errorMessage: '' } },
    value: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    status: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    comments: { value: null, error: { hasError: false, errorMessage: '' } },
  });

  const getStatusTypeId = () => {
    if (payment?.status.value) {
      const parts = (payment?.status.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'danger',
        icon: 'close',
        time: 10000,
        customLeft: '0px',
      }),
    );
  };

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setPayment((lastFields) => {
        const fieldContent = {
          ...lastFields[error.field as keyof PaymentFields],
        };

        return {
          ...lastFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const fetchData = useCallback(() => {
    dispatch(setPageLoading(true));

    getFinancialPaymentOptions()
      .then((response) => {
        setStatusTypes(
          response.data.status_types.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );

        if (paymentId) {
          getFinancialPayment(paymentId)
            .then((response) => {
              setPayment({
                ...payment,
                contract: {
                  client: {
                    value: {
                      value: `${response.data.contract.client.value}__${response.data.contract.client.key}`,
                      label: (
                        <Button theme="link-primary" size="small">
                          {response.data.contract.client.value}
                        </Button>
                      ),
                    },
                    error: { hasError: false, errorMessage: '' },
                  },
                  organizations: {
                    value: response.data.contract.organizations.map(
                      (org: KeyValueOptionProps) => {
                        return {
                          value: `${org.value}__${org.key}`,
                          label: (
                            <Button theme="link-primary" size="small">
                              {org.value}
                            </Button>
                          ),
                        };
                      },
                    ),
                    error: { hasError: false, errorMessage: '' },
                  },
                  contract_type: {
                    value: {
                      value: `${response.data.contract.contract_type.value}__${response.data.contract.contract_type.key}`,
                      label: (
                        <Button theme="link-primary" size="small">
                          {response.data.contract.contract_type.value}
                        </Button>
                      ),
                    },
                    error: { hasError: false, errorMessage: '' },
                  },
                  start_at: {
                    value: new Date(
                      `${response.data.contract.start_at}T00:00:00`,
                    ),
                    error: { hasError: false, errorMessage: '' },
                  },
                  finish_at: {
                    value: response.data.contract.finish_at
                      ? new Date(`${response.data.contract.finish_at}T00:00:00`)
                      : null,
                    error: { hasError: false, errorMessage: '' },
                  },
                },
                ref_date: {
                  value: new Date(`${response.data.ref_date}T00:00:00`),
                  error: { hasError: false, errorMessage: '' },
                },
                exp_date: {
                  value: new Date(`${response.data.exp_date}T00:00:00`),
                  error: { hasError: false, errorMessage: '' },
                },
                pay_date: {
                  value: response.data.pay_date
                    ? new Date(`${response.data.pay_date}T00:00:00`)
                    : null,
                  error: { hasError: false, errorMessage: '' },
                },
                value: {
                  value: response.data.value,
                  error: { hasError: false, errorMessage: '' },
                },
                status: {
                  value: {
                    value: `${response.data.status.value}__${response.data.status.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.status.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                comments: {
                  value: response.data.comments,
                  error: { hasError: false, errorMessage: '' },
                },
              });
            })
            .catch(() => {
              navigate('/error-404');
            })
            .finally(() => {
              dispatch(setPageLoading(false));
            });
        } else {
          dispatch(setPageLoading(false));
          navigate('/error-404');
        }
      })
      .catch(() => {
        dispatch(setPageLoading(false));
        navigate('/error-404');
      });
  }, [dispatch, paymentId, payment, navigate]);

  const postAPIDataFactory = () => {
    return {
      pay_date: payment.pay_date.value
        ? dateToApiDate(payment.pay_date.value)
        : null,
      value: payment.value.value?.toString() || '',
      status: getStatusTypeId() || '',
      comments: payment.comments.value,
    };
  };

  const updatePayment = () => {
    dispatch(setPageLoading(true));

    const data: PaymentPostSendData = postAPIDataFactory();

    updateFinancialPayment(paymentId || '', data)
      .then(() => {
        showSuccessMessage(t('The payment has been updated'));
        navigate('/staff/finance/contract');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the payment'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSaveClick = () => {
    if (validatePayent()) {
      updatePayment();
    }
  };

  const validatePayent = () => {
    const updatedPayment = { ...payment };
    let isValid = true;

    if (!payment.value.value) {
      updatedPayment.value.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPayment.value.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!payment.status.value) {
      updatedPayment.status.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPayment.status.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    setPayment(updatedPayment);
    return isValid;
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, navigate, fetchData]);

  return (
    <StyledPaymentForm>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {t('Edit payment')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => navigate('/staff/finance/contract')}
              >
                {t('Cancel')}
              </Button>
              <Button
                theme="primary"
                rounded="true"
                onClick={() => handleSaveClick()}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
          <div className="content">
            <section>
              <Text as="h4" className="section-title">
                {t('Contract information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Client')}
                  </Text>
                  <div className="section-group">
                    <Select
                      value={payment.contract.client.value}
                      isDisabled={true}
                      setValue={() => null}
                      theme="default"
                      buttonTheme="link-primary"
                      placeholder={t('Select a client...')}
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              {payment.contract.client.value && (
                <div className="section-contents">
                  <div className="section-content">
                    <Text as="h6" color="grayscale-200">
                      {t('Organizations')}
                    </Text>
                    <div className="section-group">
                      <Select
                        value={payment.contract.organizations.value}
                        isDisabled={true}
                        setValue={() => null}
                        theme="default"
                        buttonTheme="link-primary"
                        placeholder={t('Select organizations...')}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Contract type')}
                  </Text>
                  <div className="section-group">
                    <Select
                      value={payment.contract.contract_type.value}
                      isDisabled={true}
                      setValue={() => null}
                      theme="default"
                      placeholder={t('Select a contract type...')}
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Start date')}
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="startDate"
                      value={payment.contract.start_at.value || undefined}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('End date')}
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="endDate"
                      value={payment.contract.finish_at.value || undefined}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Payment information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Reference date')}*
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="refDate"
                      value={payment.ref_date.value || undefined}
                      disabled={true}
                      hasError={payment.ref_date.error.hasError}
                      errorMessage={payment.ref_date.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Expiration date')}*
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="expDate"
                      value={payment.exp_date.value || undefined}
                      disabled={true}
                      hasError={payment.exp_date.error.hasError}
                      errorMessage={payment.exp_date.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Payment date')}
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="payDate"
                      value={payment.pay_date.value || undefined}
                      onChange={(date) =>
                        setPayment({
                          ...payment,
                          pay_date: {
                            value: date,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={payment.pay_date.error.hasError}
                      errorMessage={payment.pay_date.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Value')}*
                  </Text>
                  <div className="section-group">
                    <Input
                      type="number"
                      id="paymentValue"
                      theme="default"
                      value={
                        payment.value.value != null
                          ? payment.value.value
                          : undefined
                      }
                      onChange={(event) => {
                        const { value } = event.target;
                        setPayment({
                          ...payment,
                          value: {
                            value: isNaN(Number.parseFloat(value))
                              ? null
                              : Number.parseFloat(value),
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={payment.value.error.hasError}
                      errorMessage={payment.value.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Status')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={statusTypes}
                      value={payment.status.value}
                      setValue={(value) =>
                        setPayment({
                          ...payment,
                          status: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={payment.status.error.hasError}
                      errorMessage={payment.status.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a status...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Comments')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="section-group">
                    <Textarea
                      id="comments"
                      value={payment.comments.value || undefined}
                      onChange={(event) => {
                        const { value } = event.target;
                        setPayment({
                          ...payment,
                          comments: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={payment.comments.error.hasError}
                      errorMessage={payment.comments.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StyledPaymentForm>
  );
};

export default PaymentForm;
