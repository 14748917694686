import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledContractForm } from './styles';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import { SelectOption } from 'components/molecule/Select/types';
import { ApiVersions, getBaseAPIUrl } from 'settings';
import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import DatePicker from 'components/molecule/DatePicker';
import Separator from 'components/atom/Separator';
import { ContractFields, KeyValueOptionProps } from './types';
import { useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createFinancialContract,
  getFinancialContract,
  getFinancialContractOptions,
  updateFinancialContract,
} from 'apis/staff';
import { ContractPostSendData } from 'apis/staff/types';
import { dateToApiDate } from 'utils/date';
import { RequestFieldError } from 'apis/types';
import { showMessage } from 'store/slices/toaster';

const ContractForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { contractId } = useParams();
  const [installmentTypes, setInstallmentTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [contract, setContract] = useState<ContractFields>({
    client: { value: null, error: { hasError: false, errorMessage: '' } },
    organizations: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    contract_type: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    start_at: { value: null, error: { hasError: false, errorMessage: '' } },
    finish_at: { value: null, error: { hasError: false, errorMessage: '' } },
    installment_value: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    installment_type: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    comments: { value: null, error: { hasError: false, errorMessage: '' } },
  });

  const getClientId = () => {
    if (contract?.client.value) {
      const parts = (contract?.client.value as SelectOption).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getContractTypeId = () => {
    if (contract?.contract_type.value) {
      const parts = (contract?.contract_type.value as SelectOption).value.split(
        '__',
      );
      return parts[parts.length - 1];
    }
    return null;
  };

  const getInstallmentTypeId = () => {
    if (contract?.installment_type.value) {
      const parts = (
        contract?.installment_type.value as SelectOption
      ).value.split('__');
      return parts[parts.length - 1];
    }
    return null;
  };

  const getOrganizationsIds = () => {
    if (contract?.organizations.value) {
      const ids: string[] = [];
      const options = contract?.organizations.value as SelectOption[];
      options.forEach((option) => {
        const parts = option.value.split('__');
        ids.push(parts[parts.length - 1]);
      });
      return ids;
    }
    return null;
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'danger',
        icon: 'close',
        time: 10000,
        customLeft: '0px',
      }),
    );
  };

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setContract((lastFields) => {
        const fieldContent = {
          ...lastFields[error.field as keyof ContractFields],
        };

        return {
          ...lastFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const fetchData = useCallback(() => {
    dispatch(setPageLoading(true));

    getFinancialContractOptions()
      .then((response) => {
        setContractTypes(
          response.data.contract_types.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );
        setInstallmentTypes(
          response.data.installment_types.map((item: KeyValueOptionProps) => ({
            value: `${item.value}__${item.key}`,
            label: (
              <Button theme="link-primary" size="small">
                {item.value}
              </Button>
            ),
          })),
        );

        if (contractId) {
          getFinancialContract(contractId)
            .then((response) => {
              setContract({
                ...contract,
                client: {
                  value: {
                    value: `${response.data.client.value}__${response.data.client.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.client.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                organizations: {
                  value: response.data.organizations.map(
                    (org: KeyValueOptionProps) => {
                      return {
                        value: `${org.value}__${org.key}`,
                        label: (
                          <Button theme="link-primary" size="small">
                            {org.value}
                          </Button>
                        ),
                      };
                    },
                  ),
                  error: { hasError: false, errorMessage: '' },
                },
                contract_type: {
                  value: {
                    value: `${response.data.contract_type.value}__${response.data.contract_type.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.contract_type.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                start_at: {
                  value: new Date(`${response.data.start_at}T00:00:00`),
                  error: { hasError: false, errorMessage: '' },
                },
                finish_at: {
                  value: response.data.finish_at
                    ? new Date(`${response.data.finish_at}T00:00:00`)
                    : null,
                  error: { hasError: false, errorMessage: '' },
                },
                installment_type: {
                  value: {
                    value: `${response.data.installment_type.value}__${response.data.installment_type.key}`,
                    label: (
                      <Button theme="link-primary" size="small">
                        {response.data.installment_type.value}
                      </Button>
                    ),
                  },
                  error: { hasError: false, errorMessage: '' },
                },
                installment_value: {
                  value: response.data.installment_value,
                  error: { hasError: false, errorMessage: '' },
                },
                comments: {
                  value: response.data.comments,
                  error: { hasError: false, errorMessage: '' },
                },
              });
            })
            .catch(() => {
              navigate('/error-404');
            })
            .finally(() => {
              dispatch(setPageLoading(false));
            });
        } else {
          dispatch(setPageLoading(false));
        }
      })
      .catch(() => {
        dispatch(setPageLoading(false));
        navigate('/error-404');
      });
  }, [dispatch, contractId, navigate, contract]);

  const postAPIDataFactory = () => {
    return {
      client: getClientId() || '',
      organizations: getOrganizationsIds(),
      contract_type: getContractTypeId() || '',
      start_at: contract.start_at.value
        ? dateToApiDate(contract.start_at.value)
        : '',
      finish_at: contract.finish_at.value
        ? dateToApiDate(contract.finish_at.value)
        : null,
      installment_value: contract.installment_value.value?.toString() || '',
      installment_type: getInstallmentTypeId() || '',
      comments: contract.comments.value,
    };
  };

  const createContract = () => {
    dispatch(setPageLoading(true));

    const data: ContractPostSendData = postAPIDataFactory();

    createFinancialContract(data)
      .then(() => {
        showSuccessMessage(t('The contract has been created'));
        navigate('/staff/finance/contract');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while creating the contract'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const updateContract = () => {
    dispatch(setPageLoading(true));

    const data: ContractPostSendData = postAPIDataFactory();

    updateFinancialContract(contractId || '', data)
      .then(() => {
        showSuccessMessage(t('The contract has been updated'));
        navigate('/staff/finance/contract');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the contract'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSaveClick = () => {
    if (validateContract()) {
      if (contractId) {
        updateContract();
      } else {
        createContract();
      }
    }
  };

  const validateContract = () => {
    const updatedContract = { ...contract };
    let isValid = true;

    if (!contract.client.value) {
      updatedContract.client.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedContract.client.error = { hasError: false, errorMessage: '' };
    }

    if (!contract.contract_type.value) {
      updatedContract.contract_type.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedContract.contract_type.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!contract.start_at.value) {
      updatedContract.start_at.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedContract.start_at.error = { hasError: false, errorMessage: '' };
    }

    if (contract.installment_value.value == null) {
      updatedContract.installment_value.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedContract.installment_value.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    if (!contract.installment_type.value) {
      updatedContract.installment_type.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedContract.installment_type.error = {
        hasError: false,
        errorMessage: '',
      };
    }

    setContract(updatedContract);
    return isValid;
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, navigate, fetchData]);

  return (
    <StyledContractForm>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {contractId ? t('Edit contract') : t('New contract')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => navigate('/staff/finance/contract')}
              >
                {t('Cancel')}
              </Button>
              <Button
                theme="primary"
                rounded="true"
                onClick={() => handleSaveClick()}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
          <div className="content">
            <section>
              <Text as="h4" className="section-title">
                {t('General information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Client')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      isDisabled={contractId != undefined}
                      value={contract.client.value}
                      setValue={(value) => {
                        setContract({
                          ...contract,
                          client: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                          organizations: {
                            value: null,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={contract.client.error.hasError}
                      errorMessage={contract.client.error.errorMessage}
                      theme="default"
                      buttonTheme="link-primary"
                      placeholder={t('Select a client...')}
                      apiUrl={`${getBaseAPIUrl(
                        ApiVersions.v2,
                      )}/staff/financial/fields/clients/`}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              {contract.client.value && (
                <div className="section-contents">
                  <div className="section-content">
                    <Text as="h6" color="grayscale-200">
                      {t('Organizations')}
                    </Text>
                    <div className="section-group">
                      <Select
                        value={contract.organizations.value}
                        setValue={(value) =>
                          setContract({
                            ...contract,
                            organizations: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={contract.organizations.error.hasError}
                        errorMessage={contract.organizations.error.errorMessage}
                        theme="default"
                        buttonTheme="link-primary"
                        placeholder={t('Select organizations...')}
                        apiUrl={`${getBaseAPIUrl(
                          ApiVersions.v2,
                        )}/staff/financial/fields/clients/${getClientId()}/organizations/`}
                        isSearchable
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Contract type')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      isDisabled={contractId != undefined}
                      options={contractTypes}
                      value={contract.contract_type.value}
                      setValue={(value) =>
                        setContract({
                          ...contract,
                          contract_type: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={contract.contract_type.error.hasError}
                      errorMessage={contract.contract_type.error.errorMessage}
                      theme="default"
                      placeholder={t('Select a contract type...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Start date')}*
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="startDate"
                      value={contract.start_at.value || undefined}
                      onChange={(date) =>
                        setContract({
                          ...contract,
                          start_at: {
                            value: date,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={contract.start_at.error.hasError}
                      errorMessage={contract.start_at.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('End date')}
                  </Text>
                  <div className="section-group">
                    <DatePicker
                      id="endDate"
                      value={contract.finish_at.value || undefined}
                      onChange={(date) =>
                        setContract({
                          ...contract,
                          finish_at: {
                            value: date,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={contract.finish_at.error.hasError}
                      errorMessage={contract.finish_at.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Installment')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Type')}*
                  </Text>
                  <div className="section-group">
                    <Select
                      options={installmentTypes}
                      value={contract.installment_type.value}
                      setValue={(value) =>
                        setContract({
                          ...contract,
                          installment_type: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        })
                      }
                      hasError={contract.installment_type.error.hasError}
                      errorMessage={
                        contract.installment_type.error.errorMessage
                      }
                      theme="default"
                      placeholder={t('Select an installment type...')}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
              </div>
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Value')}*
                  </Text>
                  <div className="section-group">
                    <Input
                      type="number"
                      id="installmentValue"
                      theme="default"
                      value={
                        contract.installment_value.value != null
                          ? contract.installment_value.value
                          : undefined
                      }
                      onChange={(event) => {
                        const { value } = event.target;
                        setContract({
                          ...contract,
                          installment_value: {
                            value: isNaN(Number.parseFloat(value))
                              ? null
                              : Number.parseFloat(value),
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={contract.installment_value.error.hasError}
                      errorMessage={
                        contract.installment_value.error.errorMessage
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Comments')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="section-group">
                    <Textarea
                      id="comments"
                      value={contract.comments.value || undefined}
                      onChange={(event) => {
                        const { value } = event.target;
                        setContract({
                          ...contract,
                          comments: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      hasError={contract.comments.error.hasError}
                      errorMessage={contract.comments.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StyledContractForm>
  );
};

export default ContractForm;
