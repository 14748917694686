import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { showMessage } from 'store/slices/toaster';
import { setClientConfig } from 'store/slices/client';
import { setOrganization } from 'store/slices/organization';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setOrganizations } from 'store/slices/organizations';
import { OrganizationState } from 'store/slices/organization/types';

import Text from 'components/atom/Text';
import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';
import Image from 'components/molecule/Image';

import {
  OrganizationProps,
  TimezoneProps,
  UseOrganizationProps,
  FieldErrors,
  ApiErrorItem,
  CountryProps,
} from './types';

import { getAccount } from 'apis/auth';
import { getCountries } from 'apis/general';
import { getOrganizationInfo, updateOrganizationInfo } from 'apis/organization';

import {
  getCurrentOrganizationId,
  logout,
  setCurrentOrganizationId,
} from 'services/auth';

export const useOrganization = ({ setSaveLoading }: UseOrganizationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [loading, setLoading] = useState(true);

  const [currentOrganizationInfo, setCurrentOrganizationInfo] =
    useState<OrganizationProps | null>(null);
  const [timezoneOptions, setTimezoneOptions] = useState<SelectOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);

  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [countrySelectOption, setCountrySelectOption] =
    useState<SingleSelectedOption>(null);
  const [timezoneSelectOption, setTimezoneSelectOption] =
    useState<SingleSelectedOption>(null);

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    name: {
      hasError: false,
      errorMessage: '',
    },
    timezone: {
      hasError: false,
      errorMessage: '',
    },
    country: {
      hasError: false,
      errorMessage: '',
    },
  });

  const customInformation = () => {
    return (
      <div className="flex flex-col gap-4 max-w-xs font-bold px-1 py-3">
        <Text color="white-color" className="!text-sm">
          {t(
            'The company nickname defined in this field should be used by all employees when filling in the "Company" field during their login, whether in the apps or the admin panel.',
          )}
        </Text>
      </div>
    );
  };

  const clearErrorField = (field: string) => {
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[field as keyof FieldErrors].hasError = false;
    newFieldErrors[field as keyof FieldErrors].errorMessage = '';
    setFieldErrors(newFieldErrors);
  };

  const handleChangeOrganizationName = (newName: string) => {
    const fieldName = 'name';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setNewOrganizationName(newName);
  };

  const handleChangeCountry = (option: SingleSelectedOption) => {
    const fieldName = 'country';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setCountrySelectOption(option);
  };

  const handleChangeTimezone = (option: SingleSelectedOption) => {
    const fieldName = 'timezone';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setTimezoneSelectOption(option);
  };

  const timezoneOptionItem = ({
    diff,
    time_utc,
    localization,
  }: TimezoneProps) => {
    return (
      <div className="p-3">
        <Text as="pre" color="grayscale-200">
          {`UTC ${time_utc}`}
        </Text>
        <Text color="grayscale-400" className="mt-1">
          {diff ? `(${diff}hrs) ${localization}` : `${localization}`}
        </Text>
      </div>
    );
  };

  const timezoneSelectOptionFactory = useCallback(
    (timezoneOptions: TimezoneProps[]): SelectOption[] => {
      return timezoneOptions.map((option: TimezoneProps) => {
        if (option.selected) {
          const timezoneOptionCurrent: SingleSelectedOption = {
            value: option.localization,
            label: timezoneOptionItem({
              time_utc: option.time_utc,
              localization: option.localization,
            }),
          };
          setTimezoneSelectOption(timezoneOptionCurrent);
        }
        return {
          value: option.localization,
          label: timezoneOptionItem({
            diff: option.diff,
            time_utc: option.time_utc,
            localization: option.localization,
          }),
        };
      });
    },
    [],
  );

  const countryOptionItem = (flag: string, label: string) => {
    return (
      <div className="p-3 flex gap-1 items-center">
        <Image src={flag} />
        <Text color="grayscale-400" className="mt-1">
          {label}
        </Text>
      </div>
    );
  };

  const countrySelectOptionFactory = useCallback(
    (countryOptions: CountryProps[]): SelectOption[] => {
      return countryOptions.map((option: CountryProps) => ({
        value: option.code,
        label: countryOptionItem(option.flag, option.name),
      }));
    },
    [],
  );

  const loadCountries = useCallback(() => {
    return new Promise((resolve, reject) => {
      getCountries()
        .then(({ data }) => {
          const countryOptionsData = countrySelectOptionFactory(data);

          setCountryOptions(countryOptionsData);

          resolve(data);
        })
        .catch((error) => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading the countries',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );

          reject(error);
        })
        .finally(() => setLoading(false));
    });
  }, [t, dispatch, countrySelectOptionFactory]);

  const loadOrganizationInformation = useCallback(() => {
    return new Promise((resolve, reject) => {
      getOrganizationInfo(organizationId)
        .then(({ data }) => {
          const timezoneOptionsData = timezoneSelectOptionFactory(
            data.timezones_display,
          );

          const countryOptionCurrent: SingleSelectedOption = {
            value: data.country_display.code,
            label: countryOptionItem(
              data.country_display.flag,
              data.country_display.name,
            ),
          };

          setCountrySelectOption(countryOptionCurrent);
          setCurrentOrganizationInfo(data);
          setNewOrganizationName(data.name);
          setTimezoneOptions(timezoneOptionsData);

          resolve(data);
        })
        .catch((error) => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading the organizations information',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );

          reject(error);
        })
        .finally(() => setLoading(false));
    });
  }, [t, dispatch, organizationId, timezoneSelectOptionFactory]);

  const fetchUpdateOrganization = useCallback(() => {
    return new Promise((resolve, reject) => {
      const data = {
        name: newOrganizationName,
        timezone: timezoneSelectOption?.value,
        country: countrySelectOption?.value,
      };

      updateOrganizationInfo(organizationId, data)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          if (error.response) {
            const { errors, code } = error.response.data;
            const newFieldErrors = { ...fieldErrors };

            if (code === 'field_error') {
              errors.forEach((errorItem: ApiErrorItem) => {
                newFieldErrors[errorItem.field as keyof FieldErrors].hasError =
                  true;
                newFieldErrors[
                  errorItem.field as keyof FieldErrors
                ].errorMessage = errorItem.error;
              });

              setFieldErrors(newFieldErrors);
            }
          }

          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while saving organization information',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );

          reject(error);
        });
    });
  }, [
    t,
    dispatch,
    fieldErrors,
    organizationId,
    newOrganizationName,
    countrySelectOption?.value,
    timezoneSelectOption?.value,
  ]);

  const fetchAccountOrganizationInfo = useCallback(() => {
    return new Promise((resolve, reject) => {
      getAccount()
        .then((response) => {
          const { organizations, client } = response.data;

          const {
            avatar,
            language,
            logo,
            name,
            pk,
            slug,
            timezone,
            timezone_utc,
          } = client;

          dispatch(
            setClientConfig({
              avatar,
              id: pk,
              language,
              logo,
              name,
              slug,
              timezone,
              timezone_utc,
            }),
          );

          dispatch(
            setOrganizations({
              list: organizations || [],
            }),
          );

          if ((organizations || []).length > 0) {
            const storedOrganizationId = getCurrentOrganizationId();
            let storedOrganization = null;

            if (storedOrganizationId) {
              storedOrganization = organizations.find(
                (organization: OrganizationState) =>
                  parseInt(organization.pk) === parseInt(storedOrganizationId),
              );
            }

            const selectedOrganization = storedOrganization || organizations[0];

            setCurrentOrganizationId(selectedOrganization.pk);
            dispatch(setOrganization({ ...selectedOrganization }));
          }

          dispatch(
            showMessage({
              title: t('Organization settings updated successfully'),
              theme: 'success',
              icon: 'check',
              time: 10000,
            }),
          );

          resolve(response);
        })
        .catch((error) => {
          logout();
          navigate('/error-403');

          reject(error);
        });
    });
  }, [dispatch, navigate, t]);

  const handleSubmit = () => {
    setSaveLoading(true);

    Promise.all([fetchUpdateOrganization()])
      .then(() => {
        fetchAccountOrganizationInfo();
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([loadOrganizationInformation(), loadCountries()]).finally(
      () => {
        setSaveLoading(false);
      },
    );
  }, [loadCountries, loadOrganizationInformation, setSaveLoading]);

  return {
    t,
    loading,
    fieldErrors,
    handleSubmit,
    countryOptions,
    timezoneOptions,
    customInformation,
    countrySelectOption,
    handleChangeCountry,
    timezoneSelectOption,
    handleChangeTimezone,
    newOrganizationName,
    handleChangeOrganizationName,
    currentOrganizationInfo,
  };
};
