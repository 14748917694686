export const isValidHttpUrl = (stringUrl: string) => {
  try {
    const url = new URL(stringUrl);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

export const isValidUrlRegex = (stringUrl: string) => {
  const regex =
    /^((https?|ftp):\/\/)?[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  return !!regex.test(stringUrl);
};

export const isValidDriveUrl = (stringUrl: string) => {
  const regex =
    /^https?:\/\/(app\.useflow\.tech|homo\.gowithflow\.com\.br)\/drive\/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\/organization\/[0-9]+$/i;
  return !!regex.test(stringUrl);
};

export const isValidUrl = (stringUrl: string) =>
  isValidHttpUrl(stringUrl) && isValidUrlRegex(stringUrl);
