import styled from 'styled-components';

export const StyledShare = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h5 {
      margin-bottom: 6px;
    }
  }

  .input-share-link {
    margin-bottom: 32px;
    input {
      pointer-events: none;
      color: var(--grayscale-200);
    }
    .input-content:focus-within {
      border-color: var(--grayscale-100);
    }
  }

  .modal-footer {
    padding: 24px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    .left-actions {
      button:first-child {
        padding: 0px;
      }
    }
  }
`;
