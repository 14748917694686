import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getDepartments, getHierarchyTotals } from 'apis/config';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import { abbreviateNumberFormatter } from 'utils/numbers';

import Button from 'components/molecule/Button';
import TabMenu from 'components/molecule/TabMenu';
import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import Input from 'components/molecule/Input';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import DepartmentBreadcrumb from './components/DepartmentBreadcrumb';
import DepartmentForm from './components/DepartmentForm';
import DepartmentItemActions from './components/DepartmentItemActions';
import HierarchyTabOption from '../HierarchyTabOption';

import {
  DepartmentsConfigProps,
  DepartmentConfigItem,
  DepartmentRowActions,
} from './types';
import { RowProps } from 'components/organism/Table/types';
import { BreadcrumbItem } from 'apis/general/types';

import { StyledDepartmentsConfig } from './styles';

const getTableRows = (
  data: DepartmentConfigItem[],
  actions: DepartmentRowActions,
) => {
  return data.map((item) => {
    return {
      cells: [
        {
          content: (
            <div className="department-column">
              <Icon name="drop" />
              <Text color="grayscale-400">{item.name}</Text>
              <Tag padding="2px 6px" radius="4px" theme="gray-100">
                {abbreviateNumberFormatter(item.total)}
              </Tag>
            </div>
          ),
          clickable: true,
          onClick: () =>
            actions.onNavigate(`/hierarchy/settings/departments/${item.id}`),
        },
        {
          content: (
            <DepartmentItemActions
              onEdit={() =>
                actions.showDepartmentForm(
                  actions.loadDepartments,
                  item.id,
                  item.name,
                )
              }
            />
          ),
        },
      ],
    };
  });
};

const DepartmentsConfig: React.FC<DepartmentsConfigProps> = ({
  tabOptions,
  changeTabOption,
  setMenuOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: departmentId } = useParams();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [hasMoreBreadcrumb, setHasMoreBreadcrumb] = useState(false);

  const showDepartmentForm = useCallback(
    (
      onLoad: (id: string | null, page: number, currentSearch: string) => void,
      id?: string,
      name?: string,
    ) => {
      dispatch(
        setModalView({
          show: true,
          width: '600px',
          content: (
            <DepartmentForm
              parent={departmentId}
              id={id}
              name={name}
              onLoad={() => onLoad(departmentId || null, 1, search)}
            />
          ),
          disableBackgroundClick: true,
        }),
      );
    },
    [departmentId, dispatch, search],
  );

  const loadDepartments = useCallback(
    (id: string | null, page: number, currentSearch: string) => {
      setTableLoading(true);

      getHierarchyTotals(organizationId).then((totalResponse) => {
        if (!setMenuOptions) return;

        setMenuOptions((lastMenuOptions) =>
          lastMenuOptions.map((option) => {
            if (option.key === 'positions') {
              return {
                ...option,
                content: (
                  <HierarchyTabOption
                    icon="group-fill"
                    name={t('Positions')}
                    total={totalResponse.data.total_profiles}
                  />
                ),
              };
            }
            if (option.key === 'departments') {
              return {
                ...option,
                content: (
                  <HierarchyTabOption
                    icon="folder-fill"
                    name={t('Departments')}
                    total={totalResponse.data.total_departments}
                  />
                ),
              };
            }
            return option;
          }),
        );

        getDepartments(organizationId, id, currentSearch, page)
          .then((response) => {
            const {
              num_pages,
              results,
              breadcrumb: currentBreadcrumb,
              more_breadcrumb,
            } = response.data;

            setPageCount(num_pages);
            setCurrentPage(page);
            setTableRows(() =>
              getTableRows(results, {
                showDepartmentForm,
                onNavigate: (path: string) => navigate(path),
                loadDepartments,
              }),
            );
            setBreadcrumb(currentBreadcrumb);
            setHasMoreBreadcrumb(more_breadcrumb);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              window.location.pathname = '/settings';
              dispatch(
                showMessage({
                  title: t('You do not have permission to access this page'),
                  theme: 'danger',
                  icon: 'close',
                  time: 10000,
                }),
              );
              return;
            }
            dispatch(
              showMessage({
                title: t(
                  'An unexpected error occurred while loading departments',
                ),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
          })
          .finally(() => {
            setTableLoading(false);
          });
      });
    },
    [dispatch, organizationId, t, navigate, showDepartmentForm, setMenuOptions],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      loadDepartments(departmentId || null, 1, value);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const handleChangePage = (newPage: number) => {
    loadDepartments(departmentId || null, newPage, search);
  };

  useEffect(() => {
    loadDepartments(departmentId || null, 1, '');
  }, [loadDepartments, departmentId]);

  return (
    <StyledDepartmentsConfig className="profiles-config">
      <div className="content-header">
        <Button
          theme="primary-outline"
          rounded="true"
          leftIcon="add"
          onClick={() => showDepartmentForm(loadDepartments)}
        >
          {t('Add department')}
        </Button>
        <TabMenu tabs={tabOptions} onChangeTab={changeTabOption} />
        <Button
          theme="link-gray-primary"
          leftIcon="table"
          to="/hierarchy/import"
        >
          {t('Upload sheet')}
        </Button>
      </div>
      <div className="content">
        <DepartmentBreadcrumb
          initialLastId={breadcrumb.length > 0 ? breadcrumb[0].id : ''}
          links={breadcrumb.map((item) => ({
            label: item.name,
            path: `/hierarchy/settings/departments/${item.id}`,
          }))}
          hasMore={hasMoreBreadcrumb}
          rootPath={
            departmentId ? '/hierarchy/settings/departments' : undefined
          }
        />
        <Input
          placeholder={t('Search by title')}
          icon="search"
          value={search}
          onChange={handleSearch}
        />
        <Table
          columns={[]}
          rows={tableRows}
          textAlign="start"
          fontColor="grayscale-200"
          hasPagination
          pageCount={pageCount}
          page={currentPage - 1}
          onChangePage={(page) => handleChangePage(page + 1)}
          loading={tableLoading}
          scrollable
          clickable
        />
      </div>
    </StyledDepartmentsConfig>
  );
};

export default DepartmentsConfig;
