import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atom/Icon';
import Image from 'components/molecule/Image';
import Text from 'components/atom/Text';

import { AvailableIcons } from 'components/atom/Icon/types';

import defaultEmptyMessageImage from 'assets/images/clip-list-is-empty.svg';

import { StyledEmptyMessage } from './styles';

interface EmptyMessageProps {
  title?: string;
  description?: string;
  icon?: keyof typeof AvailableIcons;
  src?: string;
  showImage?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({
  title,
  description,
  icon,
  showImage,
  src,
  children,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <StyledEmptyMessage className={`empty-message ${className}`}>
      {icon && <Icon name={icon} color="grayscale-200" />}
      {showImage && <Image src={src ? src : defaultEmptyMessageImage} />}
      <Text as="h6" weight="700" align="center">
        {title || t('No record found')}
      </Text>
      {description && (
        <Text
          as="p"
          color="grayscale-200"
          className="description"
          align="center"
        >
          {description}
        </Text>
      )}
      {children && <div className="empty-message-content">{children}</div>}
    </StyledEmptyMessage>
  );
};

export default EmptyMessage;
