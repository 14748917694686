import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { downloadFile } from 'utils/download';
import { addEllipsisInMiddle } from 'utils/string';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';
import { Dropdown } from 'components/molecule/DropdownV2';

import { getIcon } from 'pages/drive/utils/icons';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import { DriveCardProps } from './types';

import { StyledDriveCard } from './styles';

const DriveCard: React.FC<DriveCardProps> = ({
  id,
  index,
  type = 'file',
  name,
  size,
  date,
  disableBackgroundClick,
  setTableLoading,
  onShare,
  fileLink,
  viewFile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getPeriodLabel = useCallback(
    (period: string) => {
      const periodLabel = `${t('{{start_at}} at {{time_start_at}}', {
        start_at: getDateFromApiDate(period),
        time_start_at: getTimeFromApiDate(period),
      })}`;

      return periodLabel;
    },
    [t],
  );

  const description =
    type === 'folder'
      ? getPeriodLabel(date)
      : `${size} • ${getPeriodLabel(date)}`;

  const handleVisualizeClick = () => {
    window.open(viewFile ? viewFile : '', '_blank');
  };

  const handleShareClick = () => {
    onShare(id);
  };

  const handleDownloadClick = async () => {
    const fileUrl = viewFile || '';

    dispatch(
      showMessage({
        title: t('File download started'),
        theme: 'warning',
        icon: 'download',
        time: 10000,
      }),
    );

    try {
      await downloadFile(fileUrl, name);

      dispatch(
        showMessage({
          title: t('Download completed successfully'),
          theme: 'success',
          icon: 'check',
          time: 10000,
        }),
      );
    } catch (error) {
      dispatch(
        showMessage({
          title: t(
            'An unexpected error occurred while downloading the file/folder',
          ),
          theme: 'danger',
          icon: 'close',
          time: 10000,
        }),
      );
    }
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(fileLink);
    dispatch(
      showMessage({
        title: t('You have copied the file/folder link to your clipboard.'),
        theme: 'success',
        icon: 'check',
        time: 3000,
      }),
    );
  };

  const handleListingFolder = () => {
    setTableLoading(true);
    navigate(`/drive/dashboard/${id}`);
  };

  return (
    <StyledDriveCard
      className={`drive-card ${disableBackgroundClick ? '' : 'cursor-pointer'}`}
    >
      <div
        className="info-wrapper"
        onClick={disableBackgroundClick ? () => undefined : handleListingFolder}
      >
        <div className="icon-wrapper">
          <Icon name={getIcon(type.toLowerCase())} color="grayscale-300" />
        </div>
        <div className="file-info-wrapper">
          <Tooltip
            id={`${index}-drive-card-title`}
            content={addEllipsisInMiddle(name, 30, 30, 7)}
            place="top"
            disabled={name.length < 17}
          >
            <Text weight="500" color="dark-color">
              {name}
            </Text>
          </Tooltip>
          <Tooltip
            id={`${index}-drive-card-description`}
            content={description}
            place="top"
            disabled={description.length <= 17}
          >
            <Text weight="400" color="grayscale-200">
              {description}
            </Text>
          </Tooltip>
        </div>
      </div>
      <div className="action-wrapper">
        <Dropdown.Root
          trigger={<Icon name="more-2" color="grayscale-200" />}
          theme="dark"
          className="z-30"
        >
          <React.Fragment>
            <Dropdown.Item onClick={handleVisualizeClick} disabled={!viewFile}>
              {t('View')}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDownloadClick} disabled={!viewFile}>
              {t('Download')}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleCopyLinkClick}>
              {t('Copy Link')}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleShareClick}>
              {t('Share')}
            </Dropdown.Item>
          </React.Fragment>
        </Dropdown.Root>
      </div>
    </StyledDriveCard>
  );
};

export default DriveCard;
