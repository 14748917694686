import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import CoverImage from 'components/organism/CoverImage';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { isValidDriveUrl } from 'utils/validations';

import { DrivePostContentProps } from './types';
import { CoverProps } from 'components/organism/CoverImage/types';
import { PostContentFields } from '../../types';

import { StyledDrivePostContent } from './styles';

const DrivePostContent: React.FC<DrivePostContentProps> = ({
  urlContentState,
  setContentState,
  contentStatus,
}) => {
  const { t } = useTranslation();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const [urlField, setUrlField] = useState(
    urlContentState.url.value?.toString() || undefined,
  );
  const [currentUrlTimeOut, setCurrentUrlTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentUrlTimeOut) {
      clearTimeout(currentUrlTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      const urlIsValid = isValidDriveUrl(value);

      setContentState((updatedState: PostContentFields) => ({
        ...updatedState,
        url: {
          ...updatedState.url,
          value: value,
          error: {
            hasError: urlIsValid,
            errorMessage: urlIsValid ? '' : t('Enter a valid drive url'),
          },
        },
      }));
    }, 400);

    setCurrentUrlTimeOut(timeOut);
    setUrlField(value);
  };

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleClearUrl = () => {
    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      url: {
        ...updatedState.url,
        value: '',
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  useEffect(() => {
    setUrlField(urlContentState.url.value?.toString() || undefined);
  }, [urlContentState.url.value]);

  return (
    <StyledDrivePostContent className="short-post-content">
      <Input
        className="url"
        theme="dark"
        icon="external-link"
        placeholder={`${t('Enter the link *')}`}
        label={t('Participants must click')}
        value={urlField}
        onChange={handleChangeUrl}
        hasError={urlContentState.url.error.hasError}
        errorMessage={urlContentState.url.error.errorMessage}
        actions={
          <Button
            theme="link-white"
            size="small"
            onClick={handleClearUrl}
            disabled={contentStatus !== 'draft' && contentStatus !== ''}
          >
            {contentStatus === 'draft' && <Icon name="close" />}
          </Button>
        }
        disabled={contentStatus !== 'draft' && contentStatus !== ''}
      />

      <CoverImage
        coverUrl={urlContentState.cover.url}
        setCover={handleChangeCover}
        organizationId={organizationId}
        hasError={urlContentState.cover.error.hasError}
        errorMessage={urlContentState.cover.error.errorMessage}
      />

      <Input
        id="url-post-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={urlContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={urlContentState.title.error.hasError}
        errorMessage={urlContentState.title.error.errorMessage}
      />
      <Textarea
        id="url-post-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Description *')}
        placeholder={`${t('Enter the description *')}`}
        value={urlContentState.description.value?.toString() || undefined}
        onChange={handleChangeDescription}
        limit={1000}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={urlContentState.description.error.hasError}
        errorMessage={urlContentState.description.error.errorMessage}
        removeBreakLines
      />
    </StyledDrivePostContent>
  );
};

export default DrivePostContent;
