import React from 'react';

import { SubscriptionModalProps } from './types';

import Button from 'components/molecule/Button';
import Modal from 'components/molecule/Modal';

import { StyledSubscriptionModal } from './styles';
import { useTranslation } from 'react-i18next';

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  onSubscribe,
  ...rest
}) => {
  const { t } = useTranslation();

  const openCrisp = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Crisp integration
    window.$crisp.push(['do', 'chat:open']);
    onSubscribe();
  };

  return (
    <StyledSubscriptionModal>
      <Modal
        content={
          <div className="flex flex-col items-center justify-center">
            <p className="mb-8">
              {t(
                'This action is not available to free accounts. Contact our team and gain access to every incredible feature USEFLOW can offer.',
              )}
            </p>
            <Button onClick={() => openCrisp()}>{t('Contact us')}</Button>
          </div>
        }
        {...rest}
      />
    </StyledSubscriptionModal>
  );
};

export default SubscriptionModal;
