import styled from 'styled-components';

export const StyledManagementHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 54px;
  border-bottom: 1px solid var(--grayscale-100);
`;
