import styled from 'styled-components';

export const StyledContentDetailsHeader = styled.div`
  width: 100%;
  height: 80px;
  background: var(--white-color);
  border-bottom: 1px solid var(--grayscale-100);
  position: relative;
  padding-right: 48px;
  padding-left: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    padding: 8px;
  }

  .tab-menu {
    background: none;
    padding: 0px;

    .button {
      border-radius: 0px;
      height: 80px;
      width: 200px;
      min-width: 200px;
      padding: 0px;

      &:hover {
        background: none;
        & * {
          color: var(--dark-color);
        }
      }
    }

    .button {
      &.active {
        background: none;
        box-shadow: none;
        border-bottom: 1px solid var(--dark-color);
      }
    }
  }

  #create-segment-button {
    padding-right: 0;
    &.hide {
      visibility: hidden;
    }
  }
`;
