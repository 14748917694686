import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { imageFileToBase64 } from 'utils/fileConversions';
import { getSubmittedImageProps } from 'utils/files';

import { FileUpload } from 'services/upload';

import FileButton from 'components/molecule/FileButton';
import ImageCrop from 'components/organism/ImageCrop';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Image from 'components/molecule/Image';
import Text from 'components/atom/Text';

import { FileUploadModules, UploadDataResponse } from 'services/upload/types';
import { CoverImageProps } from './types';

import { StyledCoverImage } from './styles';

const CoverImage: React.FC<CoverImageProps> = ({
  coverUrl = '',
  setCover,
  organizationId,
  minWidth,
  minHeight,
  hasError,
  errorMessage,
  module = FileUploadModules.BOARD,
  disabled = false,
  fileNamePrefix = 'content-cover',
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const allowedFileTypes = ['image/png', 'image/jpeg'];

  const [coverSrc, setCoverSrc] = useState(coverUrl);
  const [showCropper, setShowCropper] = useState(false);
  const [buttonFileErrorMessage, setButtonFileErrorMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const setSrcToCover = (file: File) => {
    imageFileToBase64(file)
      .then((base64image) => {
        const newImageUrl = base64image ? base64image.toString() : '';
        setCoverSrc(newImageUrl);
        setShowCropper(true);
      })
      .catch(() => {
        setButtonFileErrorMessage(
          t('An unexpected error occurred while loading the image.'),
        );
      });
  };

  const handleChangeCoverFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file) {
      return;
    }

    if (!allowedFileTypes.some((fileType) => fileType === file.type)) {
      setButtonFileErrorMessage(
        t('Invalid file type. You must choose a png or jpeg image.'),
      );
      return;
    }

    setButtonFileErrorMessage('');

    if (minWidth || minHeight) {
      getSubmittedImageProps(file)
        .then((submittedImageProps) => {
          const minWidthExceeded =
            minWidth && submittedImageProps.width < minWidth;
          const minHeightExceeded =
            minHeight && submittedImageProps.height < minHeight;

          if (minWidthExceeded || minHeightExceeded) {
            setButtonFileErrorMessage(
              `${t(
                'Image uploaded must be at least the following dimension size:',
              )} ${minWidth}x${minHeight} ${t('pixels')}`,
            );
            return;
          }

          setSrcToCover(file);
        })
        .catch(() => {
          setButtonFileErrorMessage(
            t('An unexpected error occurred while loading the image.'),
          );
        });
      return;
    }

    setSrcToCover(file);
  };

  const editCover = () => {
    setCoverSrc(coverUrl);
    setShowCropper(true);
  };

  const removeCover = () => {
    setCoverSrc('');
    if (setCover) {
      setCover({
        url: '',
        id: '',
      });
      setShowCropper(false);
    }
  };

  const handleCancel = () => {
    setCoverSrc('');
    setShowCropper(false);
  };

  const saveCover = (file: File) => {
    setSaveLoading(true);
    setUploadInProgress(true);

    const fileUpload = new FileUpload();
    fileUpload
      .send(
        file,
        module,
        organizationId,
        (progress) => {
          setUploadProgress(() => progress);
        },
        'no-cache',
      )
      .then((response: UploadDataResponse) => {
        const { fileUrl, moduleId } = response;
        if (setCover) {
          setCover({
            url: fileUrl,
            id: moduleId.toString(),
          });
          setCoverSrc(fileUrl);
          setShowCropper(false);
          setUploadProgress(0);
        }
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An error occurred while saving file'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setSaveLoading(false);
        setUploadInProgress(false);
      });
  };

  return (
    <StyledCoverImage>
      {!coverUrl && !coverSrc && (
        <FileButton
          id="content-cover"
          className="initial-file-button"
          buttonIcon="image-fill"
          buttonTheme="primary-outline"
          onChange={handleChangeCoverFile}
          accept={allowedFileTypes}
          disabled={disabled}
        >
          {t('Add cover')}
        </FileButton>
      )}

      {coverSrc && showCropper && (
        <ImageCrop
          src={coverSrc}
          aspect={16 / 9}
          fileNamePrefix={fileNamePrefix}
          loading={saveLoading}
          onSave={saveCover}
          onCancel={handleCancel}
          onRemove={removeCover}
          showProgress={uploadInProgress}
          progress={uploadProgress}
          minWidth={minWidth}
          minHeight={minHeight}
        />
      )}

      {coverUrl && !showCropper && (
        <div className="cover-preview">
          <div className="cover-preview-actions">
            <FileButton
              id="content-cover"
              buttonIcon="upload"
              buttonTheme="primary-shadow"
              onChange={handleChangeCoverFile}
              accept={allowedFileTypes}
              disabled={disabled}
            >
              {t('Change image')}
            </FileButton>

            <Button
              theme="primary-shadow"
              size="small"
              rounded="true"
              onClick={editCover}
              className="edit"
              disabled={disabled}
            >
              <Icon name="crop-outline" />
            </Button>
          </div>
          <Image width="100%" src={coverUrl} />
        </div>
      )}

      {buttonFileErrorMessage ||
        (hasError && (
          <Text color="danger-color">
            {hasError ? errorMessage : buttonFileErrorMessage}
          </Text>
        ))}
    </StyledCoverImage>
  );
};

export default CoverImage;
