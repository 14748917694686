import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Separator from 'components/atom/Separator';

import { CustomFieldTypeModalProps } from './types';

import { StyledCustomFieldTypeModal } from './styles';

const CustomFieldTypeModal: React.FC<CustomFieldTypeModalProps> = ({
  onSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedType, setSelectedType] = useState('');

  return (
    <StyledCustomFieldTypeModal>
      <div className="modal-content">
        <Text as="h4" weight="700">
          {t('Select the fields you want to add')}
        </Text>
        <Text as="h6" color="grayscale-200">
          {t(
            'Added fields can be customized and will impact the profile of community participants.',
          )}
        </Text>

        <div className="custom-field-options">
          <Button
            theme="dark-outline"
            className={selectedType === 'custom_string' ? 'selected' : ''}
            onClick={() => setSelectedType('custom_string')}
          >
            <div className="custom-field-icon">
              <div className="icon-background"></div>
              <Icon name="menu" />
            </div>
            <Text as="h6" weight="700">
              {t('Text')}
            </Text>
          </Button>

          <Button
            theme="dark-outline"
            className={selectedType === 'custom_choices' ? 'selected' : ''}
            onClick={() => setSelectedType('custom_choices')}
          >
            <div className="custom-field-icon">
              <div className="icon-background"></div>
              <Icon name="drop" />
            </div>
            <Text as="h6" weight="700">
              {t('Dropdown')}
            </Text>
          </Button>

          <Button
            theme="dark-outline"
            className={selectedType === 'custom_boolean' ? 'selected' : ''}
            onClick={() => setSelectedType('custom_boolean')}
          >
            <div className="custom-field-icon">
              <div className="icon-background"></div>
              <Icon name="toggle-line" />
            </div>
            <Text as="h6" weight="700">
              {t('Boolean')}
            </Text>
          </Button>

          <Button
            theme="dark-outline"
            className={selectedType === 'custom_decimal' ? 'selected' : ''}
            onClick={() => setSelectedType('custom_decimal')}
          >
            <div className="custom-field-icon">
              <div className="icon-background"></div>
              <Icon name="number" />
            </div>
            <Text as="h6" weight="700">
              {t('Numerical')}
            </Text>
          </Button>
        </div>

        <Separator />
      </div>
      <div className="modal-footer">
        <Button
          theme="primary"
          rounded="true"
          disabled={!selectedType}
          onClick={() => {
            dispatch(closeModal());
            onSelect(selectedType);
          }}
        >
          {t('Add item')}
        </Button>
      </div>
    </StyledCustomFieldTypeModal>
  );
};

export default CustomFieldTypeModal;
