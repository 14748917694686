import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Textarea from 'components/molecule/Textarea';

import { StyledInstructionsConfig } from './styles';

interface InstructionsConfigProps {
  instructions: string | null;
  setInstructions: Dispatch<SetStateAction<string | null>>;
  onSave: (newInstructions: string) => void;
  canEdit: boolean;
}

const InstructionsConfig: React.FC<InstructionsConfigProps> = ({
  instructions,
  onSave,
  canEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [currentInstructions, setCurrentInstruction] = useState<string>(
    instructions || '',
  );

  const handleSave = () => {
    onSave(currentInstructions);
    dispatch(closeModal());
  };

  const handleChangeMessage = (newValue: string) => {
    setCurrentInstruction(newValue);
  };

  return (
    <StyledInstructionsConfig className="instructions-config">
      <Text as="h5" weight="bold">
        {t('Type the instructions for filling the survey')}
      </Text>

      <div className="collapses">
        <Textarea
          value={currentInstructions}
          onChange={(event) => handleChangeMessage(event.target.value)}
          limit={1000}
          theme="gray"
          rows={4}
          placeholder={t('Type the text')}
          disabled={!canEdit}
        />
      </div>
      <Button
        theme="primary"
        rounded="true"
        onClick={handleSave}
        disabled={!canEdit}
      >
        {t('Save')}
      </Button>
    </StyledInstructionsConfig>
  );
};

export default InstructionsConfig;
