import { CustomDecimalCfgResponse, CustomFieldNumeric } from 'apis/core/types';
import { Dispatch, SetStateAction } from 'react';

export interface NumericOptionItem extends CustomFieldNumeric {
  id?: string;
  name: string;
  decimal_min_value_invalid_msg: string;
}

export const defaultNumericOptionItem: NumericOptionItem = {
  name: '',
  decimal_min_value: null,
  decimal_max_value: null,
  decimal_min_value_invalid_msg: '',
};

export interface NumericOptionsProps {
  config?: CustomDecimalCfgResponse;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  item: NumericOptionItem;
  setItem: Dispatch<SetStateAction<NumericOptionItem>>;
}
