import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showMessage } from 'store/slices/toaster';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';

import { FieldErrors } from '../../types';
import { ColorOptions, ColorPaletteProps, PaletteProps } from './types';

import { getOrganizationInfo } from 'apis/organization';

const useColorPalette = ({
  fieldErrors,
  setFieldErrors,
  paletteSelectOption,
  setPaletteSelectOption,
}: ColorPaletteProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [loading, setLoading] = useState(true);

  const [paletteOptions, setPaletteOptions] = useState<SelectOption[]>([]);

  const clearErrorField = (field: string) => {
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[field as keyof FieldErrors].hasError = false;
    newFieldErrors[field as keyof FieldErrors].errorMessage = '';
    setFieldErrors(newFieldErrors);
  };

  const handleChangeColorPalette = (option: SingleSelectedOption) => {
    const fieldName = 'palette_color';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setPaletteSelectOption(option);
  };

  const paletteOptionItem = (colorOptions: ColorOptions) => {
    return (
      <div className="flex flex-col p-[10px] gap-[8px]">
        <span>{colorOptions.title}</span>
        <div className="flex gap-[10px]">
          <div
            className="w-[98px] h-[54px] rounded"
            style={{ background: colorOptions.primary_color_200 }}
          ></div>
          <div
            className="w-[98px] h-[54px] rounded"
            style={{ background: colorOptions.primary_color_300 }}
          ></div>
          <div
            className="w-[98px] h-[54px] rounded"
            style={{ background: colorOptions.secondary_color_100 }}
          ></div>
          <div
            className="w-[98px] h-[54px] rounded"
            style={{ background: colorOptions.secondary_color_200 }}
          ></div>
          <div
            className="w-[98px] h-[54px] rounded"
            style={{ background: colorOptions.tertiary_color_1 }}
          ></div>
        </div>
      </div>
    );
  };

  const paletteSelectOptionFactory = useCallback(
    (
      countryOptions: PaletteProps[],
      paletteSelected: string,
    ): SelectOption[] => {
      return countryOptions.map((option: PaletteProps) => {
        if (option.id === paletteSelected) {
          const timezoneOptionCurrent: SingleSelectedOption = {
            value: option.id,
            label: paletteOptionItem(option.options),
          };
          setPaletteSelectOption(timezoneOptionCurrent);
        }

        return {
          value: option.id,
          label: paletteOptionItem(option.options),
        };
      });
    },
    [setPaletteSelectOption],
  );

  const loadColorPalette = useCallback(() => {
    getOrganizationInfo(organizationId)
      .then(({ data }) => {
        const paletteOptionsData = paletteSelectOptionFactory(
          data.color_schemes_display,
          data.color_scheme_id_display,
        );

        setPaletteOptions(paletteOptionsData);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while loading the colors schema',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [dispatch, organizationId, paletteSelectOptionFactory, t]);

  useEffect(() => {
    loadColorPalette();
  }, [loadColorPalette]);

  return {
    t,
    loading,
    paletteFieldErros: fieldErrors,
    paletteOptions,
    paletteOption: paletteSelectOption,
    handleChangeColorPalette,
  };
};

export default useColorPalette;
