import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomFields } from 'apis/core';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import EmptyMessage from 'components/molecule/EmptyMessage';
import CustomFieldsConfigItem from './components/CustomFieldsConfigItem';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import CustomFieldTypeModal from './components/CustomFieldTypeModal';
import CustomFieldFormModal from './components/CustomFieldFormModal';

import { CustomFieldsConfigItemData } from './types';

import { StyledCustomFieldsConfig } from './styles';

const CustomFieldsConfig: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [customFields, setCustomFields] = useState<
    CustomFieldsConfigItemData[]
  >([]);
  const [, setCustomFieldsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const loadCustomFields = useCallback(
    (page: number) => {
      getCustomFields(organizationId, page)
        .then((response) => {
          const { results, next, recordsTotal } = response.data;

          setCustomFieldsCount(recordsTotal);
          setHasNextPage(!!next);
          setPage(page);

          if (page === 1) {
            setCustomFields(results);
            return;
          }

          setCustomFields((lastEditorials) => [...lastEditorials, ...results]);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }
          dispatch(
            showMessage({
              title: t('An error occurred while fetching the custom fields'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadMoreLoading(false);
        });
    },
    [dispatch, t, organizationId],
  );

  const showFormModal = (type: string, id?: string) => {
    dispatch(
      setModalView({
        show: true,
        width: '663px',
        content: (
          <CustomFieldFormModal
            type={type}
            onSave={() => {
              setLoading(true);
              loadCustomFields(1);
              dispatch(
                showMessage({
                  title: t('Custom field saved'),
                  theme: 'success',
                  icon: 'check',
                  time: 5000,
                }),
              );
            }}
            id={id}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const handleAdd = () => {
    dispatch(
      setModalView({
        show: true,
        width: '980px',
        content: (
          <CustomFieldTypeModal
            onSelect={(type) => {
              showFormModal(type);
            }}
          />
        ),
      }),
    );
  };

  useEffect(() => {
    loadCustomFields(1);
  }, [loadCustomFields]);

  return (
    <StyledCustomFieldsConfig>
      <Text as="h3" weight="700">
        {t('Custom fields')}
      </Text>
      <Text as="h5">{t('Customize the member profile')}</Text>
      <Text color="grayscale-200">
        {t(
          'Define the type of content and its characteristics, and the profiles of participants who can access it.',
        )}
      </Text>
      {!loading && (
        <div className="custom-field-list-header">
          <Button theme="link-primary" leftIcon="add" onClick={handleAdd}>
            {t('Add')}
          </Button>
        </div>
      )}
      {!loading && customFields.length > 0 && (
        <div
          className="custom-field-list default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          {customFields.map((editorial, index) => (
            <CustomFieldsConfigItem
              key={index}
              title={editorial.key}
              type={editorial.type}
              id={editorial.id}
              onEdit={() => showFormModal(editorial.type, editorial.id)}
              disabled={editorial.is_content_custom_field}
            />
          ))}
          {loadMoreLoading && <Loading type="bubbles" />}
        </div>
      )}

      {loading && <Loading type="bubbles" />}

      {!loading && customFields.length === 0 && (
        <EmptyMessage
          title={t('No custom field')}
          description={t(
            'Add and manage custom fields to be on users profile and can be used, for example, for segmentation on Useflow',
          )}
          icon="stack-line"
        />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadMoreLoading(true);
          loadCustomFields(page + 1);
        }}
        disabled={loading || !hasNextPage}
      />
    </StyledCustomFieldsConfig>
  );
};

export default CustomFieldsConfig;
