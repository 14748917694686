import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FAQ_URL } from 'settings';

import { OrganizationState } from 'store/slices/organization/types';

import FastMenu from 'components/organism/FastMenu';
import { FastMenuOptionProp } from 'components/organism/FastMenu/types';

import { StyledExpandedLayoutMenu } from './styles';

interface ExpandedLayoutMenuProps {
  selectedOrganization: OrganizationState;
}

const ExpandedLayoutMenu: React.FC<ExpandedLayoutMenuProps> = ({
  selectedOrganization,
}) => {
  const location = useLocation();

  // Fast Menu options
  const topOptions: FastMenuOptionProp[] = useMemo(
    () => [
      {
        icon: 'home-6-fill',
        path: '/dashboard',
        modules: ['home'],
        disabled: false,
      },
      {
        icon: 'dashboard-fill',
        path: '/mural',
        modules: ['module_comm', 'module_advocacy'],
        disabled: false,
      },
    ],
    [],
  );

  const bottomOptions: FastMenuOptionProp[] = [
    // {
    //   icon: 'user-fill',
    //   path: '/dashboard',
    //   disabled: false,
    // },
    {
      icon: 'settings-4-fill',
      path: '/settings',
      disabled: false,
    },
    {
      icon: 'question-fill',
      href: FAQ_URL,
      disabled: false,
    },
  ];

  const [currentTopOptions, setCurrentTopOptions] = useState<
    FastMenuOptionProp[]
  >([]);

  const unshiftMenuOption = (
    optionList: FastMenuOptionProp[],
    path: string,
  ) => {
    const selectedOptionIndex = optionList.findIndex(
      (option) => option.path === path,
    );
    const [selectedOption] = optionList.splice(
      selectedOptionIndex,
      selectedOptionIndex !== -1 ? 1 : 0,
    );

    if (selectedOption) {
      optionList.unshift(selectedOption);
    }

    return [...optionList];
  };

  useEffect(() => {
    const newTopOptions: FastMenuOptionProp[] = [];
    topOptions.forEach((option, index) => {
      if (
        selectedOrganization.modules &&
        selectedOrganization.modules.some((module) =>
          option.modules?.some((item) => item === 'home' || item === module),
        )
      ) {
        newTopOptions.push(topOptions[index]);
      }
    });

    setCurrentTopOptions(unshiftMenuOption(newTopOptions, location.pathname));
  }, [selectedOrganization, topOptions, location.pathname]);

  return (
    <StyledExpandedLayoutMenu className="expanded-layout-menu">
      <FastMenu topOptions={currentTopOptions} bottomOptions={bottomOptions} />
    </StyledExpandedLayoutMenu>
  );
};

export default ExpandedLayoutMenu;
