import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';

import { getIcon } from 'pages/drive/utils/icons';

import { StyledFilesTableInfo } from './styles';
import { addEllipsisInMiddle } from 'utils/string';

interface FilesTableInfoProps {
  id: string;
  index: number;
  name: string;
  type: string;
  renaming?: boolean;
  onCloseRename?: () => void;
  onFinishRename?: (id: string, newName: string) => void;
}

const FilesTableInfo: React.FC<FilesTableInfoProps> = ({
  id,
  index,
  name,
  type,
  renaming,
  onCloseRename,
  onFinishRename,
}) => {
  const { t } = useTranslation();
  const currentName = type !== 'folder' ? name.replace(type, '') : name;

  const [newName, setNewName] = useState('');

  const handleCloseRename = () => {
    setNewName(currentName);
    if (onCloseRename) {
      onCloseRename();
    }
  };

  const handleNameSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onFinishRename && onCloseRename) {
      onFinishRename(id, newName);
      setNewName(currentName);
      onCloseRename();
    }
  };

  useEffect(() => {
    setNewName(currentName);
  }, [currentName]);

  return (
    <StyledFilesTableInfo className="drive-info">
      <div className="icon-wrapper">
        <Icon name={getIcon(type.toLowerCase())} color="grayscale-200" />
      </div>
      {renaming ? (
        <form onSubmit={handleNameSubmit}>
          <div className="rename-file-wrapper">
            <Input
              type="text"
              placeholder={t('New name')}
              actions={
                <Button
                  theme="link-gray-primary"
                  size="small"
                  onClick={handleCloseRename}
                >
                  <Icon name="close-circle-fill" color="primary-color" />
                </Button>
              }
              value={newName}
              onChange={(event) => setNewName(event.target.value)}
              limit={type !== 'folder' ? 100 - type.length : 100}
              hideCharacterCount
            />
            <Button
              type="submit"
              size="small"
              theme="success"
              rounded="true"
              disabled={newName.trim().length === 0}
            >
              <Icon name="check" color="white-color" />
            </Button>
          </div>
        </form>
      ) : (
        <Tooltip
          id={`title-${index}`}
          content={addEllipsisInMiddle(name, 30, 30, 7)}
          disabled={name.length < 19}
          className="title-tooltip"
        >
          <Text>{name}</Text>
        </Tooltip>
      )}
    </StyledFilesTableInfo>
  );
};

export default FilesTableInfo;
