import apiV2 from 'apis/apiV2';

import {
  FilterParams,
  SchedulePreviewParams,
  SegmentationSendProps,
  SurveyContentPostSendData,
  QuestionData,
  QuestionSwapData,
} from './types';

const basePathname = '/admin/org';

export const getSurveys = (organizationId: string, params: FilterParams) =>
  apiV2.get(`${basePathname}/${organizationId}/research/surveys/`, {
    params: {
      page: params.page,
      search: params.search,
      ordering: params.ordering,
      start_at: params.start_at,
      finish_at: params.finish_at,
      anonymous: params.anonymous,
      status: params.status,
    },
  });

export const getSurveySegmentation = (
  organizationId: string,
  segmentationId: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/research/segmentation/${segmentationId}/entry/`,
  );

export const getSurveySegmentationOptions = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/research/segmentation/options/`);

export const cauculateTotalSegmentation = (
  organizationId: string,
  data?: SegmentationSendProps,
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/research/segmentation/total/`,
    data,
  );

export const getSurveyConfigs = (organizationId: string, category: string) =>
  apiV2.get(
    `${basePathname}/${organizationId}/research/surveys/${category}/configs/`,
  );

// General operations
export const getGeneralAddContent = (
  organizationId: string,
  researchId: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/research/surveys/${researchId}/`,
  );

export const createGeneralAddContent = (
  organizationId: string,
  data: SurveyContentPostSendData,
  category: string,
) => {
  return apiV2.post(
    `${basePathname}/${organizationId}/research/surveys/${category}/create/`,
    data,
  );
};

export const updateGeneralAddContent = (
  organizationId: string,
  postId: string,
  category: string,
  data: SurveyContentPostSendData,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/research/surveys/${category}/create/${postId}/`,
    data,
  );

export const publishGeneralAddContent = (
  organizationId: string,
  surveyId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/research/surveys/${surveyId}/published/`,
  );

export const getNotificationConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/research/notification/options/`);

export const getSchedulePreview = (
  organizationId: string,
  params: SchedulePreviewParams,
) =>
  apiV2.get(`${basePathname}/${organizationId}/research/scheduler/preview`, {
    params,
  });

export const getSurveyQuestions = (organizationId: string, survey: string) =>
  apiV2.get(
    `${basePathname}/${organizationId}/research/surveys/${survey}/questions/`,
  );

export const createSurveyQuestions = (
  organizationId: string,
  survey: string,
  data: QuestionData,
) =>
  apiV2.post(
    `${basePathname}/${organizationId}/research/surveys/${survey}/questions/`,
    data,
  );

export const updateSurveyQuestions = (
  organizationId: string,
  survey: string,
  data: QuestionData,
  questionId: string,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/research/surveys/${survey}/questions/${questionId}/`,
    data,
  );

export const deleteSurveyQuestions = (
  organizationId: string,
  survey: string,
  questionId: string,
) =>
  apiV2.delete(
    `${basePathname}/${organizationId}/research/surveys/${survey}/questions/${questionId}/`,
  );

export const swapSurveyQuestions = (
  organizationId: string,
  survey: string,
  data: QuestionSwapData,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/research/surveys/${survey}/questions/swap/`,
    data,
  );

export const getSurveyMetabaseDashboards = (
  organizationId: string,
  surveyId: string | undefined,
  questionId: string | undefined,
) => {
  if (!questionId) {
    return apiV2.get(
      `${basePathname}/${organizationId}/research/surveys/${surveyId}/metabase/questions/`,
    );
  } else {
    return apiV2.get(
      `${basePathname}/${organizationId}/research/surveys/${surveyId}/metabase/questions/${questionId}/answers/`,
    );
  }
};

export const finalizeSurvey = (
  organizationId: string,
  surveyId: string | undefined,
) => {
  return apiV2.patch(
    `${basePathname}/${organizationId}/research/surveys/${surveyId}/finalize/`,
    {
      status: 'finished',
    },
  );
};

export const duplicateSurvey = (
  organizationId: string,
  surveyId: string | undefined,
) => {
  return apiV2.post(
    `${basePathname}/${organizationId}/research/surveys/${surveyId}/duplicate/`,
  );
};
