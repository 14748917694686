import apiV2 from 'apis/apiV2';
import {
  AlertPostSendData,
  ContractPostSendData,
  PaymentPostSendData,
  PopupPostSendData,
} from './types';

/**
 * Contracts
 */
export const getFinancialContractOptions = () =>
  apiV2.get('staff/financial/contracts/options/');

export const getFinancialPaymentOptions = () =>
  apiV2.get('staff/financial/payments/options/');

export const getFinancialContract = (id: string) =>
  apiV2.get(`staff/financial/contracts/${id}/`);

export const getFinancialPayment = (id: string) =>
  apiV2.get(`staff/financial/payments/${id}/`);

export const getFinancialContracts = (
  page: number,
  filter: {
    search: string;
    client: string;
    organizations: string;
    contract_type: string;
    start_at: string;
    finish_at: string;
    order: string;
  },
) =>
  apiV2.get('staff/financial/contracts/', {
    params: {
      page,
      search: filter.search,
      client: filter.client,
      organizations: filter.organizations,
      contract_type: filter.contract_type,
      start_at: filter.start_at,
      finish_at: filter.finish_at,
      ordering: filter.order,
    },
  });

export const createFinancialContract = (data: ContractPostSendData) =>
  apiV2.post('staff/financial/contracts/', data);

export const updateFinancialContract = (
  contractId: string,
  data: ContractPostSendData,
) => apiV2.put(`staff/financial/contracts/${contractId}/`, data);

export const updateFinancialPayment = (
  paymentId: string,
  data: PaymentPostSendData,
) => apiV2.put(`staff/financial/payments/${paymentId}/`, data);

/**
 * Alerts
 */
export const getCSCXAlertOptions = () =>
  apiV2.get('staff/cscx/alerts/options/');

export const getCSCXAlert = (id: string) =>
  apiV2.get(`staff/cscx/alerts/${id}/`);

export const getCSCXAlerts = (
  page: number,
  filter: {
    search: string;
    title: string;
    order: string;
  },
) =>
  apiV2.get('staff/cscx/alerts/', {
    params: {
      page,
      search: filter.search,
      title: filter.title,
      ordering: filter.order,
    },
  });

export const createCSCXAlert = (data: AlertPostSendData) =>
  apiV2.post('staff/cscx/alerts/', data);

export const updateCSCXAlert = (alertId: string, data: AlertPostSendData) =>
  apiV2.put(`staff/cscx/alerts/${alertId}/`, data);

export const getAlerts = (organizationId: string, section: string) =>
  apiV2.get(`staff/org/${organizationId}/alerts/`, {
    params: {
      section,
    },
  });

/**
 * Popups
 */
export const getCSCXPopupOptions = () =>
  apiV2.get('staff/cscx/popups/options/');

export const getCSCXPopup = (id: string) =>
  apiV2.get(`staff/cscx/popups/${id}/`);

export const getCSCXPopups = (
  page: number,
  filter: {
    search: string;
    title: string;
    order: string;
  },
) =>
  apiV2.get('staff/cscx/popups/', {
    params: {
      page,
      search: filter.search,
      title: filter.title,
      ordering: filter.order,
    },
  });

export const createCSCXPopup = (data: PopupPostSendData) =>
  apiV2.post('staff/cscx/popups/', data);

export const updateCSCXPopup = (popupId: string, data: PopupPostSendData) =>
  apiV2.put(`staff/cscx/popups/${popupId}/`, data);

export const getPopups = (organizationId: string, section: string) =>
  apiV2.get(`staff/org/${organizationId}/popups/`, {
    params: {
      section,
    },
  });

export const deletePopup = (organizationId: string, popupId: string) =>
  apiV2.delete(`staff/org/${organizationId}/popups/${popupId}/`);

/**
 * Menu
 */
export const getMenuDashboards = () => apiV2.get('staff/menu/dashboards/');

export const getDashboards = (type: string) =>
  apiV2.get(`staff/menu/dashboards/${type}/`);
