import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export const useFreeTrialRestriction = (restrictedRoutes: string[]) => {
  const isFreeTrial = useAppSelector((state) => state.organization.is_free);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isFreeTrial &&
      restrictedRoutes.some((route) => pathname.indexOf(route) !== -1)
    ) {
      navigate('/dashboard');
    }
  }, [isFreeTrial, navigate, pathname, restrictedRoutes]);
};
