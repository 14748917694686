import React, { useCallback, useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import { objectsAreEquals } from 'utils/comparations';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import Separator from 'components/atom/Separator';

import {
  SegmentationOptionProps,
  SegmentationValueProps,
} from 'components/organism/SegmentationEditor/types';
import { ShareProps } from './types';

import CopyToClipboard from 'components/molecule/CopyToClipboard';
import SegmentationEditor from 'components/organism/SegmentationEditor';
import { showMessage } from 'store/slices/toaster';
import { getSegmentationDataValues } from 'utils/segmentation';
import Loading from 'components/molecule/Loading';
import { DriveSegmentationSendProps } from 'apis/driveDashboard/types';
import { getDriveSegmentation, shareDriveFile } from 'apis/driveDashboard';
import PageLoading from 'components/molecule/PageLoading';
import Tooltip from 'components/atom/Tooltip';
import SwitchButton from 'components/atom/SwitchButton';

import { StyledShare } from './styles';

const Share: React.FC<ShareProps> = ({
  fileId,
  fileUrl,
  isShared: fileIsShared,
  onSave,
  segmentationConfig,
  segmentationItem,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const prefixId = useId();
  const { pk: organizationId } = useAppSelector((store) => store.organization);
  const [segmentation, setSegmentation] = useState<SegmentationValueProps[]>(
    [],
  );
  const [initialSegmentation, setInitialSegmentation] = useState<
    SegmentationValueProps[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [shareSaveLoading, setShareSaveLoading] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const [isShared, setIsShared] = useState(fileIsShared);

  const getSegmentationEntries = useCallback(
    (id: string) => {
      getDriveSegmentation(organizationId, id)
        .then((response) => {
          const segmentationData = response.data.map(
            (item: SegmentationValueProps) => {
              return {
                ...item,
                values: (item.values as SegmentationOptionProps[])?.map(
                  (valueItem) => ({
                    ...valueItem,
                    key: String(valueItem.key),
                  }),
                ),
              };
            },
          );

          setSegmentation(_.cloneDeep(segmentationData));
          setInitialSegmentation(_.cloneDeep(segmentationData));
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading segmentation',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .then(() => {
          setLoading(false);
        });
    },
    [dispatch, organizationId, t],
  );

  const handleAddRow = () => {
    const rowId = `${prefixId}__${Date.now()}`;
    setSegmentation([
      ...segmentation,
      {
        id: rowId,
        key: '',
        operator: 'is_equal',
        values: [],
        type: 'string',
      },
    ]);
  };

  const handleClearAll = () => {
    const newSegmentation = segmentation.map((row) => {
      row.values = [];
      return row;
    });
    setSegmentation(newSegmentation);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const getCurrentSaveData = useCallback(
    () => ({
      segmentations: [
        {
          id: segmentationItem,
          entries: segmentation
            .filter((item) => !!item.key)
            .map((item) => ({
              id: item.id.includes(':') ? null : item.id,
              key: item.key,
              operator: item.operator,
              type: item.type,
              values: getSegmentationDataValues(item.type, item.values),
            })),
        },
      ],
      is_shared: isShared,
    }),
    [segmentation, segmentationItem, isShared],
  );

  const handleConfirmShare = useCallback(
    (newSegmentationData: DriveSegmentationSendProps | null) => {
      setShareSaveLoading(true);

      shareDriveFile(organizationId, fileId, newSegmentationData || undefined)
        .then(() => {
          setSegmentation([]);
          dispatch(closeModal());

          dispatch(
            showMessage({
              title: t('File/folder shared successfully'),
              theme: 'success',
              icon: 'check',
              time: 10000,
              customLeft: '0px',
            }),
          );

          onSave();
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while sharing the file'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .finally(() => {
          setShareSaveLoading(false);
        });
    },
    [dispatch, fileId, onSave, organizationId, t],
  );

  const handleSave = useCallback(() => {
    const data: DriveSegmentationSendProps = getCurrentSaveData();

    if (
      data.segmentations &&
      data.segmentations[0].entries.some(
        (item) =>
          item.operator !== 'is_set' &&
          item.operator !== 'is_not_set' &&
          (!item.values || item.values.length === 0 || item.values[0] === ''),
      )
    ) {
      dispatch(
        showMessage({
          title: t('There are fields that have not been filled'),
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
      return;
    }

    handleConfirmShare(data);
  }, [dispatch, handleConfirmShare, t, getCurrentSaveData]);

  useEffect(() => {
    if (segmentationItem) {
      getSegmentationEntries(segmentationItem);
    } else {
      setLoading(false);
    }
  }, [getSegmentationEntries, segmentationItem]);

  useEffect(() => {
    if (
      objectsAreEquals(segmentation, initialSegmentation) &&
      fileIsShared === isShared
    ) {
      setIsActive(false);
      return;
    }

    setIsActive(true);
  }, [
    segmentation,
    initialSegmentation,
    segmentationItem,
    isShared,
    fileIsShared,
  ]);

  return (
    <StyledShare>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('Select audience')}
        </Text>
        <Text as="p">
          {t('Select the audience that will access this file/folder')}
        </Text>
      </div>

      {!loading && (
        <>
          <Input
            className="input-share-link"
            label={t('Share link')}
            value={fileUrl}
            actions={
              <CopyToClipboard
                copyId="copy-drive-file"
                message={fileUrl}
                successMessage={t('Link copied')}
                messagePlace="top"
              >
                <Button
                  theme="link-primary"
                  onClick={() => undefined}
                  rightIcon="copy-outline"
                >
                  {t('Copy link')}
                </Button>
              </CopyToClipboard>
            }
          />

          <Separator />

          <SegmentationEditor
            segmentationConfig={segmentationConfig}
            segmentation={segmentation}
            setSegmentation={setSegmentation}
            apiVersion="v1"
          />

          <Separator />

          <div className="modal-footer">
            <div className="left-actions">
              <Button
                leftIcon="add"
                theme="link-primary"
                onClick={handleAddRow}
              >
                {t('Add')}
              </Button>
              {segmentation.length > 0 && segmentation[0].key && (
                <Button theme="link-gray-primary" onClick={handleClearAll}>
                  {t('Clear all')}
                </Button>
              )}
              <div className="default-check flex items-center gap-3">
                <Tooltip
                  content={t('A shared file/folder can be viewed by users')}
                  id="random"
                  width="267px"
                >
                  <SwitchButton
                    id="is-default"
                    checked={isShared}
                    onChange={(event) => setIsShared(event.target.checked)}
                  />
                </Tooltip>
                <Text as="h6">{t('Share file/folder')}</Text>
              </div>
            </div>
            <div className="right-actions">
              <Button theme="link-gray-primary" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button
                theme="primary"
                rounded="true"
                onClick={handleSave}
                disabled={!isActive}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </>
      )}

      {loading && <Loading width="80px" height="80px" color="primary-color" />}

      {shareSaveLoading && <PageLoading />}
    </StyledShare>
  );
};

export default Share;
