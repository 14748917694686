import accountApiV1 from 'apis/accountApiV1';
import QueryString from 'qs';

import { DriveListParams } from './types';

const basePathname = '/drive/org';

export const getDriveItems = (
  organizationId: string,
  params: DriveListParams,
  parentId: string,
  token: string,
) => {
  return accountApiV1.get(
    `${basePathname}/${organizationId}/files/account/shared-with-me/${
      parentId ? `${parentId}/` : ''
    }`,
    {
      params: {
        page: params.page,
        search: params.search,
        ordering: params.ordering,
        type_of_category: params.types,
        created_at_period_from: params.created_at_period_from,
        created_at_period_to: params.created_at_period_to,
        size: params.size,
      },
      paramsSerializer: {
        serialize: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
      },
      headers: {
        Authorization: token,
      },
    },
  );
};

export const getAccountByToken = (token: string) =>
  accountApiV1.get('/accounts/me/', {
    headers: {
      Authorization: token,
    },
  });
