import React, {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import useIsFirstRender from 'hooks/general/useIsFirstRender';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import useContentInitialState from 'hooks/survey/useContentInitialState';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Confirm from 'components/molecule/Confirm';
import Icon from 'components/atom/Icon';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import PublicationDateModal from './components/PublicationDateModal';
import NotificationsConfig from './components/NotificationsConfig';
import Organization from 'components/molecule/Organization';

import GeneralAdd from './components/GeneralAdd';
import ContentSegmentationModal from './components/ContentSegmentationModal';

import { contentStateFactory } from './utils';
import { objectsAreEquals } from 'utils/comparations';
import { generalAddInitialState } from './components/GeneralAdd/utils';
import { getSegmentationDataValues } from 'utils/segmentation';

import { SurveyContentFields, SurveyContentState } from './types';

import { RequestFieldError } from 'apis/types';
import { GeneralAddContentFields } from './components/GeneralAdd/types';

import { SegmentationValueProps } from 'components/organism/SegmentationEditor/types';
import InstructionsConfig from './components/InstructionsConfig';

import { StyledSurveyContent } from './styles';
import {
  cauculateTotalSegmentation,
  getSurveySegmentation,
  getSurveySegmentationOptions,
  getSurveyConfigs,
} from 'apis/survey';
import {
  NotificationConfigProps,
  NotificationValueProps,
  SegmentationSendProps,
} from 'apis/survey/types';
import { apiDateToDayjsFormat } from 'utils/date';

const SurveyContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { researchType: contentType = '' } = useParams();
  const isFirstRender = useIsFirstRender();

  const contentInitialState: SurveyContentFields =
    useContentInitialState(contentType);

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const [periodDefault, setPeriodDefault] = useState<{
    start_at: Date | undefined;
    start_at_time: string;
  }>({
    start_at: undefined,
    start_at_time: '',
  });

  const [contentFields, setContentFields] =
    useState<SurveyContentFields>(contentInitialState);

  const [contentStatus, setContentStatus] = useState('');
  const [surveyPageTitle, setSurveyPageTitle] = useState('');

  const [changeControl, setChangeControl] = useState<SurveyContentState>();

  const [segmentationId, setSegmentationId] = useState<string | null>(null);
  const [segmentationConfig, setSegmentationConfig] = useState(null);
  const [segmentationData, setSegmentationData] =
    useState<SegmentationSendProps>();
  const [currentSegmentation, setCurrentSegmentation] = useState<
    SegmentationValueProps[]
  >([]);
  const [segmentationLoading, setSegmentationLoading] = useState(true);
  const [segmentationChangeControl, setSegmentationChangeControl] =
    useState<SegmentationSendProps>();
  const [segmentationTotalTarget, setSegmentationTotalTarget] = useState(0);

  const [notificationConfig, setNotificationConfig] = useState<
    NotificationConfigProps[] | null
  >(null);
  const [notificationValues, setNotificationValues] = useState<
    NotificationValueProps[] | null
  >(null);
  const [notificationChangeControl, setNotificationChangeControl] = useState<
    NotificationValueProps[] | null
  >(null);

  const [instructions, setInstructions] = useState<string | null>(null);
  const [instructionsChangeControl, setInstructionsChangeControl] = useState<
    string | null
  >(null);

  const [canEditFinishAt, setCanEditFinishAt] = useState(true);

  const [canEdit, setCanEdit] = useState(true);

  const [withRecurrence, setWithRecurrence] = useState(false);

  const [hideAnswers, setHideAnswers] = useState(true);
  const [shuffle, setShuffle] = useState(false);

  const showUnsavedChangesModal = (confirmCallback: () => void) => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <Confirm
            title={t('There are unsaved changes')}
            subtitle={t(
              'Any unsaved changes will be lost. Do you want to continue without saving?',
            )}
            onConfirm={confirmCallback}
          />
        ),
      }),
    );
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = useCallback(
    (message: string) => {
      dispatch(
        showMessage({
          title: message,
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
    },
    [dispatch],
  );

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setContentFields((lastContentFields) => {
        const fieldContent = {
          ...lastContentFields[error.field as keyof SurveyContentFields],
        };

        return {
          ...lastContentFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const calculateTotalSegmentationTarget = useCallback(
    (data?: SegmentationSendProps) => {
      cauculateTotalSegmentation(organizationId, data)
        .then((response) => {
          const { count_accounts } = response.data;
          setSegmentationTotalTarget(count_accounts);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while calculating total targeted users.',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .finally(() => {
          setSegmentationLoading(false);
        });
    },
    [t, dispatch, organizationId],
  );

  const getSegmentationEntries = useCallback(
    (id: string) => {
      getSurveySegmentation(organizationId, id)
        .then((response) => {
          setCurrentSegmentation(response.data);

          const newSegmentationData = {
            id: id,
            entries: response.data
              .filter((item: SegmentationValueProps) => !!item.key)
              .map((item: SegmentationValueProps) => ({
                id: item.id.includes(':') ? null : item.id,
                key: item.key,
                operator: item.operator,
                type: item.type,
                values: getSegmentationDataValues(item.type, item.values),
              })),
          };

          setSegmentationData(newSegmentationData);
          setSegmentationChangeControl(newSegmentationData);
          calculateTotalSegmentationTarget(newSegmentationData);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading segmentation',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
          setSegmentationLoading(false);
        });
    },
    [dispatch, organizationId, t, calculateTotalSegmentationTarget],
  );

  const loadSegmentationConfig = useCallback(
    (id?: string) => {
      getSurveySegmentationOptions(organizationId)
        .then((response) => {
          setSegmentationConfig(
            response.data.map((configItems: { url: string | null }) => ({
              ...configItems,
              url: configItems.url ? configItems.url.replace('/v2', '') : null,
            })),
          );
          if (id) {
            getSegmentationEntries(id);
            return;
          }

          calculateTotalSegmentationTarget();
          setSegmentationId(id || null);
        })
        .catch(() => {
          showErrorMessage(
            t('An unexpected error occurred while segmenting the research'),
          );
          setSegmentationLoading(false);
        });
    },
    [
      organizationId,
      showErrorMessage,
      t,
      getSegmentationEntries,
      calculateTotalSegmentationTarget,
    ],
  );

  const renderContent = () => {
    switch (contentType) {
      case 'general': {
        return (
          <GeneralAdd
            contentState={contentFields as GeneralAddContentFields}
            setContentState={
              setContentFields as Dispatch<
                SetStateAction<GeneralAddContentFields>
              >
            }
            contentStatus={contentStatus}
            setInitialChangeControl={setInitialChangeControl}
            segmentationData={segmentationData}
            segmentationConfig={segmentationConfig}
            loadSegmentationConfig={loadSegmentationConfig}
            segmentationLoading={segmentationLoading}
            setNotificationChangeControl={setNotificationChangeControl}
            segmentationTotalTarget={segmentationTotalTarget}
            setContentStatus={setContentStatus}
            notificationValues={notificationValues}
            setNotificationValues={setNotificationValues}
            instructions={instructions}
            setInstructions={setInstructions}
            setInstructionsChangeControl={setInstructionsChangeControl}
            setCanEditFinishAt={setCanEditFinishAt}
            setChangeControl={setChangeControl}
            getPeriodButtonLabel={getPeriodButtonLabel}
            isEditable={isEditable}
            showSegmentationModal={showSegmentationModal}
            showPublicationDateModal={showPublicationDateModal}
            showNotificationModal={showNotificationModal}
            showInstructionsModal={showInstructionsModal}
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
            handleFieldErrors={handleFieldErrors}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            setSegmentationId={setSegmentationId}
            setSegmentationChangeControl={setSegmentationChangeControl}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            hideAnswers={hideAnswers}
            setHideAnswers={setHideAnswers}
            shuffle={shuffle}
            setShuffle={setShuffle}
            surveyPageTitle={surveyPageTitle}
            setSurveyPageTitle={setSurveyPageTitle}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  const handleBack = () => {
    const currentContentState = contentStateFactory(contentFields, {
      status: contentStatus,
      type: contentType,
      with_recurrence: withRecurrence,
    });

    const currentChangeControl = {
      ...changeControl,
      segmentations: undefined,
      notifications: undefined,
      instructions: undefined,
      type: currentContentState.type,
    };

    if (
      !objectsAreEquals(currentContentState, currentChangeControl) ||
      !objectsAreEquals(segmentationData, segmentationChangeControl) ||
      !objectsAreEquals(notificationValues, notificationChangeControl) ||
      !objectsAreEquals(instructions, instructionsChangeControl)
    ) {
      showUnsavedChangesModal(() => {
        navigate('/research');
      });
      return;
    }
    navigate('/research');
  };

  const showPublicationDateModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '482px',
        content: (
          <PublicationDateModal
            contentState={contentFields}
            setContentState={setContentFields}
            canEditFinishAt={canEditFinishAt}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            contentStatus={contentStatus}
            canSetRecurrence
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const getPeriodButtonLabel = () => {
    if (!contentFields.start_at.value && !withRecurrence) {
      return t('Content period');
    }

    if (withRecurrence) {
      return t('Schedule');
    }

    const startAt = dayjs(contentFields.start_at.value).format('ll');

    switch (contentStatus) {
      case 'draft': {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
      case 'scheduled': {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
      case 'active': {
        if (contentFields.finish_at.value) {
          const finishAt = dayjs(contentFields.finish_at.value).format('ll');
          return t('Ends in {{finishAt}}', {
            finishAt,
          });
        }
        return t('Started in {{startAt}}', {
          startAt,
        });
      }
      case 'finishing': {
        if (contentFields.finish_at.value) {
          const finishAt = dayjs(contentFields.finish_at.value).format('ll');
          return t('Ends in {{finishAt}}', {
            finishAt,
          });
        }
        return t('Started in {{startAt}}', {
          startAt,
        });
      }
      case 'finished': {
        const finishAt = dayjs(contentFields.finish_at.value).format('ll');
        return t('Ended in {{finishAt}}', {
          finishAt,
        });
      }
      case 'canceled': {
        return t('Would start in {{startAt}}', {
          startAt,
        });
      }
      default: {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
    }
  };

  const showSegmentationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '890px',
        content: (
          <ContentSegmentationModal
            title={t('Select the audience')}
            subtitle={t(
              'Apply segmentation criteria to define the audience that will be impacted by this content.',
            )}
            segmentationConfig={segmentationConfig}
            segmentationId={segmentationId}
            currentSegmentation={currentSegmentation}
            onSave={(data, segmentation) => {
              setSegmentationData(data);
              setCurrentSegmentation(segmentation);
              calculateTotalSegmentationTarget(data);
              setSegmentationLoading(true);
            }}
            canEdit={canEdit}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showNotificationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '390px',
        content: (
          <NotificationsConfig
            notificationConfig={notificationConfig}
            setNotificationConfig={setNotificationConfig}
            notificationValues={notificationValues}
            onSave={(newNotificationValues) => {
              setNotificationValues(newNotificationValues);
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showInstructionsModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '390px',
        content: (
          <InstructionsConfig
            setInstructions={setInstructions}
            instructions={instructions}
            onSave={(newInstructions) => {
              setInstructions(newInstructions);
            }}
            canEdit={canEdit}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const setInitialChangeControl = useCallback(() => {
    let currentInitialState = null;

    if (contentType === 'general') {
      currentInitialState = { ...generalAddInitialState };
    }

    if (currentInitialState) {
      setChangeControl(
        contentStateFactory(
          {
            ...currentInitialState,
            start_at: {
              ...currentInitialState.start_at,
              value: periodDefault.start_at,
            },
            start_at_time: {
              ...currentInitialState.start_at_time,
              value: periodDefault.start_at_time,
            },
          },
          {
            notifications: null,
            instructions: null,
            segmentations: undefined,
            status: '',
            type: contentType,
            with_recurrence: false,
          },
        ),
      );
    }
  }, [contentType, periodDefault.start_at, periodDefault.start_at_time]);

  const isEditable = () => contentStatus === 'draft' || contentStatus === '';

  const loadContentConfigs = useCallback(() => {
    getSurveyConfigs(organizationId, contentType).then((response) => {
      const formattedStartAt = apiDateToDayjsFormat(
        response.data.default_open_at,
      );
      const [startAt, startAtTime] = formattedStartAt.split(' ');

      setPeriodDefault({
        start_at: startAt ? dayjs(startAt).toDate() : undefined,
        start_at_time: startAtTime ? startAtTime.split(':').join(':') : '',
      });

      setSurveyPageTitle(response.data.page_title);

      setContentFields((contentInitial) => {
        return {
          ...contentInitial,
          start_at: {
            ...contentInitial.start_at,
            value: startAt ? dayjs(startAt).toDate() : undefined,
          },
          start_at_time: {
            ...contentInitial.start_at_time,
            value: startAtTime ? startAtTime.split(':').join(':') : '',
          },
        };
      });
    });
  }, [organizationId, contentType]);

  useEffect(() => {
    loadSegmentationConfig();
  }, [loadSegmentationConfig]);

  useEffect(() => {
    if (isFirstRender) {
      loadContentConfigs();
    }
  }, [isFirstRender, loadContentConfigs]);

  return (
    <StyledSurveyContent className="mural-content-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      {renderContent()}
    </StyledSurveyContent>
  );
};

export default SurveyContent;
