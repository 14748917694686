import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { createProfile, updateProfile, getProfile } from 'apis/config';

import FormSteps from 'components/molecule/FormSteps';
import Button from 'components/molecule/Button';
import ClientStep from './components/ClientStep';
import ProfileSelect from './components/ProfileSelect';
// import Text from 'components/atom/Text';
import PageLoading from 'components/molecule/PageLoading';

import { ProfileFormProps } from './types';

import { StyledProfileForm } from './styles';
// import Separator from 'components/atom/Separator';
import Loading from 'components/molecule/Loading';

const ProfileForm: React.FC<ProfileFormProps> = ({
  id,
  onLoad,
  name,
  setShowCancelModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [currentStep, setCurrentStep] = useState(1);
  const [profileName, setProfileName] = useState(name || '');
  const [selectedManagersProfiles, setSelectedManagersProfiles] = useState<
    string[]
  >([]);
  const [selectedManagedsProfiles, setSelectedManagedsProfiles] = useState<
    string[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const handleNext = () => {
    if (currentStep === 3) return;
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (currentStep === 1) return;
    setCurrentStep(currentStep - 1);
  };

  const handleCreateProfile = () => {
    createProfile(
      organizationId,
      profileName,
      selectedManagersProfiles.toString(),
      selectedManagedsProfiles.toString(),
    )
      .then(() => {
        dispatch(
          showMessage({
            title: t('Position created'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
        dispatch(closeModal());
        onLoad();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          const { code, errors } = err.response.data;
          if (code === 'invalid' || code.indexOf('invalid') !== -1) {
            dispatch(
              showMessage({
                title: errors[0],
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }
        }

        dispatch(
          showMessage({
            title: t('An error occurred while saving the position'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateProfile = () => {
    if (!id) return;

    updateProfile(
      organizationId,
      profileName,
      selectedManagersProfiles.toString(),
      selectedManagedsProfiles.toString(),
      id,
    )
      .then(() => {
        dispatch(
          showMessage({
            title: t('Position updated'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
        dispatch(closeModal());
        onLoad();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An error occurred while saving the position'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    setLoading(true);

    if (!id) {
      handleCreateProfile();
      return;
    }
    handleUpdateProfile();
  };

  useEffect(() => {
    if (id) {
      setFetchLoading(true);

      getProfile(organizationId, id)
        .then((response) => {
          setSelectedManagedsProfiles(response.data.manageds);
          setSelectedManagersProfiles(response.data.managers);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading the position',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          dispatch(closeModal());
        })
        .finally(() => {
          setFetchLoading(false);
        });
    }
  }, [dispatch, id, organizationId, t]);

  return (
    <StyledProfileForm>
      <FormSteps
        activeIndex={currentStep - 1}
        stepLabels={[t('Create a position'), t('Led by'), t('Leader of')]}
      />

      {!fetchLoading && (
        <>
          <div className="form-step-content">
            {currentStep === 1 && (
              <ClientStep
                name={profileName}
                setName={setProfileName}
                isUpdate={!!id}
              />
            )}
            {currentStep === 2 && (
              <ProfileSelect
                currentProfileName={profileName}
                title={t('Select leaders')}
                description={t(
                  'Choose which roles will be responsible for the new role.',
                )}
                selectedProfiles={selectedManagersProfiles}
                setSelectedProfiles={setSelectedManagersProfiles}
                type="managers"
                profileId={id}
              />
            )}
            {currentStep === 3 && (
              <ProfileSelect
                currentProfileName={profileName}
                title={t('Select subordinates')}
                description={t(
                  'Choose which positions will be under the responsibility of the created position.',
                )}
                selectedProfiles={selectedManagedsProfiles}
                setSelectedProfiles={setSelectedManagedsProfiles}
                type="manageds"
                profileId={id}
              />
            )}
          </div>

          <div className="form-step-actions">
            {currentStep === 1 && (
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => {
                  setShowCancelModal(true);
                }}
              >
                {t('Cancel')}
              </Button>
            )}
            {currentStep > 1 && (
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={handleBack}
              >
                {t('Back')}
              </Button>
            )}
            {currentStep < 3 && (
              <Button
                theme="primary-flat"
                rounded="true"
                rightIcon="arrow-right-s-line"
                onClick={handleNext}
                disabled={profileName.trim().length === 0}
              >
                {t('Next')}
              </Button>
            )}
            {currentStep === 3 && (
              <Button theme="primary" rounded="true" onClick={handleSave}>
                {t('Save')}
              </Button>
            )}
          </div>
        </>
      )}

      {fetchLoading && <Loading />}

      {/* {showCancelModal && (
        <div className="cancel-modal">
          <Text as="h4" weight="700" className="title">
            {id
              ? t('Do you want to cancel position update?')
              : t('Do you want to cancel the creation of a new position?')}
          </Text>
          {!id && (
            <Text as="h6" className="description">
              {t(
                'You may not reuse this information. However, you can create this role again whenever you want.',
              )}
            </Text>
          )}
          <Separator />
          <div className="cancel-modal-actions">
            <Button
              theme="link-gray-primary"
              leftIcon="arrow-left-s-line"
              onClick={() => {
                setShowCancelModal(false);
                dispatch(updateCloseButtonView(false));
              }}
            >
              {t('Back')}
            </Button>
            <Button
              theme="white"
              onClick={() => {
                dispatch(closeModal());
                setShowCancelModal(false);
              }}
            >
              {id ? t('Cancel update') : t('Cancel creation')}
            </Button>
          </div>
        </div>
      )} */}

      {loading && <PageLoading />}
    </StyledProfileForm>
  );
};

export default ProfileForm;
