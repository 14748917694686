import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';

import { ChoiceOptionProps } from './types';

import { StyledChoiceOption } from './styles';
import Text from 'components/atom/Text';

const ChoiceOption: React.FC<ChoiceOptionProps> = ({
  index,
  name,
  onSave,
  onRemove,
  onEdit,
  initialOpen,
  disabled,
}) => {
  const { t } = useTranslation();

  const charLimitView = 54;

  const [inEdit, setInEdit] = useState(initialOpen);
  const [value, setValue] = useState(name);

  useEffect(() => {
    setInEdit(initialOpen);
    setValue(name);
  }, [initialOpen, name]);

  return (
    <StyledChoiceOption>
      <form
        className="content"
        onSubmit={(event) => {
          event.preventDefault();
          onSave(value, index);
          setInEdit(false);
        }}
      >
        {!inEdit && (
          <Tooltip
            content={value}
            id={`option-${index}`}
            disabled={value.length < charLimitView}
            width="340px"
          >
            <Button
              theme="link-dark"
              size="small"
              onClick={() => {
                setInEdit(true);
                onEdit(index);
              }}
              disabled={disabled}
            >
              <Text as="h6">{value}</Text>
            </Button>
          </Tooltip>
        )}
        {inEdit && (
          <>
            <Input
              placeholder={t('Option name')}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              autoFocus
              limit={255}
            />
            <Button
              rounded="true"
              className="save-button"
              type="submit"
              disabled={!value || value.trim().length === 0}
            >
              {t('Save')}
            </Button>
          </>
        )}
      </form>
      <Button
        theme="link-danger"
        onClick={() => onRemove(index)}
        disabled={disabled}
        className={`${disabled ? 'is-disabled' : ''} ${
          inEdit ? 'in-edit' : ''
        }`}
        size="small"
      >
        <Icon name="less" />
      </Button>
    </StyledChoiceOption>
  );
};

export default ChoiceOption;
