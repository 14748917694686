/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from './types';
import { StyledWizardStep } from '../../styles';

import Text from 'components/atom/Text';
import Select from 'components/molecule/Select';

import {
  SelectOption,
  SingleSelectedOption,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';

import Input from 'components/molecule/Input';
import Checkbox from 'components/molecule/Checkbox';
import Button from 'components/molecule/Button';
import Image from 'components/molecule/Image';
import { getCheckCompanyShortName, getCheckCompanySlug } from 'apis/wizard';
import { getCountries } from 'apis/general';
import { showMessage } from 'store/slices/toaster';
import { useAppDispatch } from 'store/hooks';

interface CompanyStageProps {
  stageErrors: FieldErrors;
  formSubmit: (
    companyName: string,
    companyShortName: string,
    companyLoginName: string,
    companyCountry: string,
    companyEmployees: string,
    companySegment: string,
  ) => void;
  goBack: (
    companyName: string,
    companyShortName: string,
    companyLoginName: string,
    companyCountry: string,
    companyEmployees: string,
    companySegment: string,
  ) => void;
}

interface CountryProps {
  name: string;
  code: string;
  flag: string;
}

// Stage 2

const CompanyStage: React.FC<CompanyStageProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [company_country, setCountry] = useState<SelectedOptionValueType>(null);
  const [company_name, setCompanyName] = useState('');
  const [company_short_name, setCompanyShortName] = useState('');
  const [company_slug, setCompanySlug] = useState('');
  const [company_employees, setEmployees] =
    useState<SelectedOptionValueType>(null);
  const [company_segment, setSegment] = useState<SelectedOptionValueType>(null);
  const [companySlugTimer, setCompanySlugTimer] =
    useState<NodeJS.Timeout | null>(null);
  const [companyShortNameTimer, setCompanyShortNameTimer] =
    useState<NodeJS.Timeout | null>(null);
  const [companySlugIsValid, setCompanySlugIsValid] = useState(false);
  const [companyShortNameIsValid, setCompanyShortNameIsValid] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(
    props.stageErrors,
  );

  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);

  const employeesOptions: readonly SelectOption[] = useMemo(
    () => [
      {
        value: '-10',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Between 1 and 10 employees')}
          </Button>
        ),
      },
      {
        value: '-50',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Up to 50 employees')}
          </Button>
        ),
      },
      {
        value: '-100',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Up to 100 employees')}
          </Button>
        ),
      },
      {
        value: '-1000',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Up to 1000 employees')}
          </Button>
        ),
      },
      {
        value: '-5000',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Up to 5000 employees')}
          </Button>
        ),
      },
      {
        value: '+5000',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('More than 5000 employees')}
          </Button>
        ),
      },
    ],
    [t],
  );

  const segmentOptions: readonly SelectOption[] = useMemo(
    () => [
      {
        value: 'industry',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Industry')}
          </Button>
        ),
      },
      {
        value: 'tourism',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Tourism')}
          </Button>
        ),
      },
      {
        value: 'utilities',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Utilities')}
          </Button>
        ),
      },
      {
        value: 'retail',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Retail')}
          </Button>
        ),
      },
      {
        value: 'wholesale',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Wholesale')}
          </Button>
        ),
      },
      {
        value: 'service',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Service')}
          </Button>
        ),
      },
      {
        value: 'health',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Health')}
          </Button>
        ),
      },
      {
        value: 'entertainment',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Entertainment')}
          </Button>
        ),
      },
      {
        value: 'other',
        label: (
          <Button className="sel-button" theme="link-primary">
            {t('Other')}
          </Button>
        ),
      },
    ],
    [t],
  );

  const checkValidSlug = useCallback(
    (name: string) => {
      getCheckCompanySlug(name)
        .then(() => {
          setCompanySlugIsValid(true);
        })
        .catch((err) => {
          const ms1 = t(
            'Invalid company login name, either is occupied or does not match the requirements.',
          );
          const ms2 = t(
            'It should not start with a number, have at least 4 characters, a maximum of 30 characters and must not have spaces.',
          );
          if (err.response && err.response.status === 403) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              company_slug: {
                hasError: true,
                errorMessage: ms1 + '\n' + ms2,
              },
            }));
          } else {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              company_slug: {
                hasError: true,
                errorMessage: t(
                  'There was an error trying to fetch your company login name.',
                ),
              },
            }));
          }
        });
    },
    [setCompanySlugIsValid, setFieldErrors, t],
  );

  const checkValidShortName = useCallback(
    (name: string) => {
      getCheckCompanyShortName(name)
        .then(() => {
          setCompanyShortNameIsValid(true);
        })
        .catch((err) => {
          const message1 = t(
            'Invalid company short name, either is occupied or does not match the requirements.',
          );
          const message2 = t(
            'It must have at least 4 characters and a maximum of 60 characters.',
          );
          if (err.response && err.response.status === 403) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              company_short_name: {
                hasError: true,
                errorMessage: message1 + '\n' + message2,
              },
            }));
          } else {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              company_slug: {
                hasError: true,
                errorMessage: t(
                  'There was an error trying to fetch your company short name.',
                ),
              },
            }));
          }
        });
    },
    [setCompanyShortNameIsValid, setFieldErrors, t],
  );

  const countryOptionItem = (flag: string, label: string) => {
    return (
      <div className="p-3 flex gap-1 items-center">
        <Image src={flag} />
        <Text color="grayscale-400" className="mt-[4px]">
          {label}
        </Text>
      </div>
    );
  };

  const countrySelectOptionFactory = useCallback(
    (countryOptions: CountryProps[]): SelectOption[] => {
      return countryOptions.map((option: CountryProps) => ({
        value: option.code,
        label: countryOptionItem(option.flag, option.name),
      }));
    },
    [],
  );

  const loadCountries = useCallback(() => {
    getCountries()
      .then(({ data }) => {
        const countryOptionsData = countrySelectOptionFactory(data);
        const storedCountry = localStorage.getItem('wizard-company-country');

        if (storedCountry) {
          const found = countryOptionsData.find(
            (element) => element.value === storedCountry,
          );
          if (found) {
            setCountry(found);
          }
        }

        setCountryOptions(countryOptionsData);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while loading the countries',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  }, [t, dispatch, countrySelectOptionFactory]);

  useEffect(() => {
    const storedEmployees = localStorage.getItem('wizard-company-employees');
    const storedSegment = localStorage.getItem('wizard-company-segment');

    loadCountries();

    if (storedEmployees) {
      const found = employeesOptions.find(
        (element) => element.value === storedEmployees,
      );
      if (found) {
        setEmployees(found);
      }
    }

    if (storedSegment) {
      const found = segmentOptions.find(
        (element) => element.value === storedSegment,
      );
      if (found) {
        setSegment(found);
      }
    }

    const storedCompanyName = localStorage.getItem('wizard-company-name');

    if (storedCompanyName) {
      setCompanyName(storedCompanyName);
    }

    const storedShortName = localStorage.getItem('wizard-company-short-name');

    if (storedShortName) {
      setCompanyShortName(storedShortName);
      checkValidShortName(storedShortName);
    }

    const storedSlug = localStorage.getItem('wizard-company-slug');

    if (storedSlug) {
      setCompanySlug(storedSlug);
      checkValidSlug(storedSlug);
    }
  }, [
    checkValidShortName,
    checkValidSlug,
    employeesOptions,
    loadCountries,
    segmentOptions,
  ]);

  useEffect(() => {
    setFieldErrors(props.stageErrors);
  }, [props.stageErrors]);

  const clearErrorField = (field: string) => {
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[field as keyof FieldErrors].hasError = false;
    newFieldErrors[field as keyof FieldErrors].errorMessage = '';
    setFieldErrors(newFieldErrors);
  };

  const handleCountryChange = (option: SelectedOptionValueType) => {
    const option_aux = option as SingleSelectedOption;
    if (option_aux)
      localStorage.setItem(
        'wizard-company-country',
        option_aux.value.toString(),
      );
    setCountry(option);
  };

  const handleEmployeesChange = (option: SelectedOptionValueType) => {
    const option_aux = option as SingleSelectedOption;
    if (option_aux)
      localStorage.setItem(
        'wizard-company-employees',
        option_aux.value.toString(),
      );
    setEmployees(option);
  };

  const handleSegmentChange = (option: SelectedOptionValueType) => {
    const option_aux = option as SingleSelectedOption;
    if (option_aux)
      localStorage.setItem(
        'wizard-company-segment',
        option_aux.value.toString(),
      );
    setSegment(option);
  };

  const handleCompanyNameChange = (value: string) => {
    localStorage.setItem('wizard-company-name', value);
    const fieldName = 'company_name';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setCompanyName(value);
  };

  const handleCompanyShortNameChange = (value: string) => {
    localStorage.setItem('wizard-company-short-name', value);
    const fieldName = 'company_short_name';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setCompanyShortName(value);
    setCompanyShortNameIsValid(false);

    if (companyShortNameTimer) {
      clearTimeout(companyShortNameTimer);
    }

    const newTimer = setTimeout(() => {
      checkValidShortName(value);
    }, 500);

    setCompanyShortNameTimer(newTimer);
  };

  const handleCompanySlugChange = (value: string) => {
    localStorage.setItem('wizard-company-slug', value);
    const fieldName = 'company_slug';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setCompanySlug(value);
    setCompanySlugIsValid(false);

    if (companySlugTimer) {
      clearTimeout(companySlugTimer);
    }

    const newTimer = setTimeout(() => {
      checkValidSlug(value);
    }, 500);

    setCompanySlugTimer(newTimer);
  };

  const countryIsNotEmpty = company_country != null;
  const employeesIsNotEmpty = company_employees != null;
  const segmentIsNotEmpty = company_segment != null;
  const companyNameIsNotEmpty = company_name.trim().length > 0;
  const companyShortNameIsBiggerThanMin = company_short_name.trim().length > 3;
  const companySlugIsBiggerThanMin = company_slug.trim().length > 3;

  const hasError = Object.values(fieldErrors).some(
    (fieldError) => fieldError.hasError,
  );

  const formIsValid =
    countryIsNotEmpty &&
    employeesIsNotEmpty &&
    segmentIsNotEmpty &&
    companyNameIsNotEmpty &&
    companyShortNameIsBiggerThanMin &&
    companySlugIsBiggerThanMin &&
    companySlugIsValid &&
    companyShortNameIsValid &&
    checkbox &&
    !hasError;

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formIsValid) {
      const currentCountry = company_country as SingleSelectedOption;
      const currentEmployees = company_employees as SingleSelectedOption;
      const currentSegment = company_segment as SingleSelectedOption;
      props.formSubmit(
        company_name,
        company_short_name,
        company_slug,
        currentCountry!.value.toString(),
        currentEmployees!.value.toString(),
        currentSegment!.value.toString(),
      );
    }
  };

  const goBackHandler = () => {
    const currentCountry =
      (company_country as SingleSelectedOption)?.value.toString() || '';
    const currentEmployees =
      (company_employees as SingleSelectedOption)?.value.toString() || '';
    const currentSegment =
      (company_segment as SingleSelectedOption)?.value.toString() || '';
    props.goBack(
      company_name,
      company_short_name,
      company_slug,
      currentCountry,
      currentEmployees,
      currentSegment,
    );
  };

  const getTerms = (text: string): ReactNode[] => {
    const regex = /<terms>(.*?)<\/terms>|<service>(.*?)<\/service>/g;
    const replacements: {
      [key: string]: (
        match: string,
        content: string,
        count: number,
      ) => JSX.Element;
    } = {
      '<terms>(.*?)</terms>': (match, content) => (
        <Button
          key="termsButton"
          theme="link-primary"
          className="checkbox-text-button"
          onClick={() =>
            window.open(
              'https://d1asjutgsmztwh.cloudfront.net/flow/use-terms',
              '_blank',
            )
          }
        >
          {content}
        </Button>
      ),
      '<service>(.*?)</service>': (match, content) => (
        <Button
          key="serviceButton"
          theme="link-primary"
          className="checkbox-text-button"
          onClick={() =>
            window.open(
              'https://d1asjutgsmztwh.cloudfront.net/flow/use-terms',
              '_blank',
            )
          }
        >
          {content}
        </Button>
      ),
    };

    const elements: ReactNode[] = [];
    let lastIndex = 0;
    let count = 0;

    text.replace(regex, (match, p1, p2, index) => {
      elements.push(text.substring(lastIndex, index));
      const replacement = Object.entries(replacements).find(([key]) =>
        new RegExp(key).test(match),
      );
      if (replacement) {
        const [, replace] = replacement;
        elements.push(replace(match, p1 || p2, count));
        count++;
      } else {
        elements.push(match);
      }
      lastIndex = index + match.length;
      return match;
    });

    elements.push(text.substring(lastIndex));

    return elements;
  };

  return (
    <StyledWizardStep>
      <form className="wizard-form" onSubmit={formSubmitHandler}>
        <Text as="h2" color="dark-color" weight="700">
          {t('Transform the way your organization communicates.')}
        </Text>
        <Text as="h5" className="mt-2">
          {t('Talk a bit about your company.')}
        </Text>
        <Text className="mt-2" as="pre" color="grayscale-200">
          {t('In which country is your company based?')}
        </Text>
        <Select
          options={countryOptions as SelectOption[]}
          value={company_country}
          setValue={handleCountryChange}
          isSearchable
          placeholder={
            <div className="wizard-form-select-placeholder">
              <Text color="grayscale-200">{t('Select...')}</Text>
            </div>
          }
        />
        <Input
          type="text"
          id="company-name"
          icon="build-2"
          label={t('What is the legal name of your company?')}
          placeholder={t('Company legal name')}
          className="mt-2"
          value={company_name}
          limit={255}
          hideCharacterCount={true}
          onChange={(event) => handleCompanyNameChange(event.target.value)}
          hasError={fieldErrors.company_name.hasError}
          errorMessage={fieldErrors.company_name.errorMessage}
        />
        <Input
          type="text"
          id="company-short-name"
          icon="home-6-line"
          label={t('What is the short name of your company?')}
          placeholder={t('Company short name')}
          className="mt-2"
          value={company_short_name}
          limit={60}
          hideCharacterCount={true}
          onChange={(event) => handleCompanyShortNameChange(event.target.value)}
          hasError={fieldErrors.company_short_name.hasError}
          errorMessage={fieldErrors.company_short_name.errorMessage}
        />
        <Input
          type="text"
          id="company-log-name"
          icon="user-line"
          label={t('What is the authentication/login name of your company?')}
          placeholder={t('Company login name')}
          value={company_slug}
          className="mt-2"
          limit={30}
          hideCharacterCount={true}
          onChange={(event) => handleCompanySlugChange(event.target.value)}
          hasError={fieldErrors.company_slug.hasError}
          errorMessage={fieldErrors.company_slug.errorMessage}
        />
        <Text className="mt-2" as="pre" color="grayscale-200">
          {t('How many employees work in your company?')}
        </Text>
        <Select
          options={employeesOptions as SelectOption[]}
          value={company_employees}
          setValue={handleEmployeesChange}
          isSearchable
          placeholder={
            <div className="wizard-form-select-placeholder">
              <Text color="grayscale-200">{t('Select...')}</Text>
            </div>
          }
        />
        <Text className="mt-2" as="pre" color="grayscale-200">
          {t('What is your segment?')}
        </Text>
        <Select
          options={segmentOptions as SelectOption[]}
          value={company_segment}
          setValue={handleSegmentChange}
          isSearchable
          placeholder={
            <div className="wizard-form-select-placeholder">
              <Text color="grayscale-200">{t('Select...')}</Text>
            </div>
          }
        />
        <div className="checkbox-wrapper mt-1">
          <Checkbox
            checked={checkbox}
            onChange={() => setCheckbox(!checkbox)}
          />
          <p className="checkbox-text">
            {getTerms(
              t(
                'I accept the <terms>terms</terms> and <service>service contract</service>.',
              ),
            )}
          </p>
        </div>
        <div className="wizard-footer">
          <div className="wizard-action">
            <Button
              theme="link-dark-gray"
              rounded="true"
              onClick={goBackHandler}
            >
              {t('Back')}
            </Button>
            <Button
              theme="primary"
              rounded="true"
              rightIcon="arrow-right-s-line"
              disabled={!formIsValid}
              type="submit"
            >
              {t('Submit')}
            </Button>
          </div>
        </div>
      </form>
    </StyledWizardStep>
  );
};

export default CompanyStage;
