import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/molecule/Checkbox';
import FilterItem from '../FilterItem';

import { CustomFilterAttrsTypes } from 'pages/board/Mural/types';

import { StyledSurveyFilter } from './styles';

export interface SurveyFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const SurveyFilter: React.FC<SurveyFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const totalFilterItems = 5;

  const [isOpen, setIsOpen] = useState(filter.survey.length > 0);

  const handleCheck = (checked: boolean, item: string) => {
    const newSurvey = [...filter.survey];

    if (checked) {
      newSurvey.push(item);
    } else {
      newSurvey.splice(newSurvey.indexOf(item), 1);
    }

    setFilter({ ...filter, survey: newSurvey });
  };

  const handleClear = () => {
    const newFilter = { ...filter, survey: [] };
    setFilter(newFilter);
  };

  const handleCheckAll = () => {
    const newFilter = {
      ...filter,
      survey: ['single', 'multi', 'nps', 'image', 'message'],
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  return (
    <StyledSurveyFilter>
      <FilterItem
        title={t('Quick search')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={filter.survey.length > 0}
        onClear={handleClear}
        showCheckAllButton={isOpen && filter.survey.length < totalFilterItems}
        onCheckAll={handleCheckAll}
      >
        <Checkbox
          text={t('Single choice poll')}
          checked={filter.survey.indexOf('single') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'single')}
        />
        <Checkbox
          text={t('Multi choice poll')}
          checked={filter.survey.indexOf('multi') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'multi')}
        />
        <Checkbox
          text={t('Likert')}
          checked={filter.survey.indexOf('nps') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'nps')}
        />
        <Checkbox
          text={t('Image upload')}
          checked={filter.survey.indexOf('image') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'image')}
        />
        <Checkbox
          text={t('Text sending')}
          checked={filter.survey.indexOf('message') !== -1}
          onChange={(event) => handleCheck(event.target.checked, 'message')}
        />
      </FilterItem>
    </StyledSurveyFilter>
  );
};

export default SurveyFilter;
