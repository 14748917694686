import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue, MultiValue } from 'react-select';

import Select from 'components/molecule/Select';
import {
  SelectedOptionValueType,
  SelectOption,
} from 'components/molecule/Select/types';
import Loading from 'components/molecule/Loading';

import { editorialSelectOptionFactory } from './utils';

import { EditorialConfigProps } from './types';

import { StyledEditorialConfig } from './styles';

const emptyEditorialIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z" fill="#B4B6CB"/></svg>';

const EditorialConfig: React.FC<EditorialConfigProps> = ({
  editorials,
  loading,
  selectedEditorial,
  setSelectedEditorial,
}) => {
  const { t } = useTranslation();

  const [editorialSelectOptions, setEditorialSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [editorialValue, setEditorialValue] = useState<SelectedOptionValueType>(
    editorialSelectOptions[0],
  );

  const handleChangeOption = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>,
  ) => {
    const currentOption = option as SingleValue<SelectOption>;
    setEditorialValue(currentOption);
    setSelectedEditorial(currentOption ? currentOption.value : null);
  };

  useEffect(() => {
    if (editorials) {
      const newOptions: SelectOption[] = [
        ...editorialSelectOptionFactory([
          {
            id: 'not_defined',
            title: t('Editorial line (not defined)'),
            color: '#B4B6CB',
            data_icon: emptyEditorialIcon,
          },
          ...editorials,
        ]),
      ];

      const currentEditorial = newOptions.find((item) => {
        if (!selectedEditorial) {
          return newOptions[0];
        }
        return item.value === selectedEditorial;
      });

      setEditorialSelectOptions(newOptions);

      if (currentEditorial) {
        setEditorialValue(currentEditorial);
        setSelectedEditorial(currentEditorial ? currentEditorial.value : null);
      }
    }
  }, [editorials, selectedEditorial, t, setSelectedEditorial]);

  return (
    <StyledEditorialConfig className="editorial-config">
      {!loading && editorials && editorials.length > 0 && (
        <Select
          value={editorialValue}
          setValue={handleChangeOption}
          options={editorialSelectOptions}
          theme="primary"
        />
      )}
      {loading && <Loading type="bubbles" />}
    </StyledEditorialConfig>
  );
};

export default EditorialConfig;
