import React from 'react';

import useSurveyList from './useSurveyList';

import { twMerge } from 'tailwind-merge';

import Text from 'components/atom/Text';
import Header from 'components/atom/Header';
import Modal from 'components/molecule/Modal';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import SurveyItem from './components/SurveyItem';
import Loading from 'components/molecule/Loading';
import FilterModal from './components/FilterModal';
import FastFilterItem from './components/FastFilterItem';
import PageLoading from 'components/molecule/PageLoading';
import EmptyMessage from 'components/molecule/EmptyMessage';
import DebouncedInput from 'components/molecule/DebouncedInput';
import PaginationControl from 'components/molecule/PaginationControl';

import { StyledSurveyList } from './styles';

const SurveyList: React.FC = () => {
  const {
    t,
    filter,
    isLoading,
    pageCount,
    fastFilters,
    getStatus,
    openModal,
    setOpenModal,
    researchType,
    loadingChange,
    isFilterApplied,
    handleChangePage,
    selectOptions,
    surveyItems,
    selectedOption,
    setSelectedOption,
    handleChangeStatus,
    handleSearchChange,
    handleChangeOrdering,
    handleApplyCustomFilter,
    hasAppliedFilter,
    onReloadSurveys,
  } = useSurveyList();

  return (
    <StyledSurveyList className="survey-list w-full h-full">
      <Header
        leftSideContent={
          <div className="flex items-center">
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Surveys')}
            </Text>
            <Button
              theme="link-primary"
              leftIcon="add"
              to={`/research/${researchType}/add`}
            >
              {t('New survey')}
            </Button>
          </div>
        }
      />
      <div className="w-full h-[calc(100%-144px)] px-[58px] py-7">
        {!isLoading &&
          (surveyItems.length > 0 || isFilterApplied ? (
            <>
              <div className="filters">
                <DebouncedInput
                  icon="search"
                  iconColor="primary-color"
                  placeholder={t('Search by title')}
                  initialValue={filter.search}
                  onChange={handleSearchChange}
                  actions={
                    <Button
                      theme="primary-outline"
                      leftIcon="filter-order"
                      onClick={() =>
                        setOpenModal((currentState) => !currentState)
                      }
                      className="custom-filter-bottom"
                      badge={hasAppliedFilter() ? ' ' : ''}
                    >
                      {t('Filters')}
                    </Button>
                  }
                />
                <Select
                  value={selectedOption}
                  setValue={setSelectedOption}
                  options={selectOptions.filter(
                    (option) =>
                      selectedOption &&
                      'value' in selectedOption &&
                      selectedOption.value !== option.value,
                  )}
                  onChange={handleChangeOrdering}
                />
              </div>
              <div className="flex gap-10 justify-between flex-wrap my-9">
                <FastFilterItem
                  name={t('Drafts')}
                  color="#D2D1FF"
                  qtdResearch={fastFilters.draft}
                  onClick={() => handleChangeStatus('draft')}
                  currentStatus={filter.status}
                  status="draft"
                />
                <FastFilterItem
                  name={t('Scheduleds')}
                  color="#FFAB2C"
                  qtdResearch={fastFilters.scheduled}
                  onClick={() => handleChangeStatus('scheduled')}
                  currentStatus={filter.status}
                  status="scheduled"
                />
                <FastFilterItem
                  name={t('Active')}
                  color="#45C93A"
                  qtdResearch={fastFilters.active}
                  onClick={() => handleChangeStatus('active')}
                  currentStatus={filter.status}
                  status="active"
                />
                <FastFilterItem
                  name={t('Finisheds')}
                  color="#B4B6CB"
                  qtdResearch={fastFilters.finished}
                  onClick={() => handleChangeStatus('finished')}
                  currentStatus={filter.status}
                  status="finished"
                />
              </div>

              {surveyItems.length > 0 && (
                <div>
                  <div className="grid grid-cols-2 gap-9 relative">
                    {loadingChange && <PageLoading />}
                    {surveyItems.map((surveyItem, index) => (
                      <SurveyItem
                        key={index}
                        id={surveyItem.id}
                        cover={surveyItem.cover}
                        title={surveyItem.title}
                        status={getStatus(surveyItem.status)}
                        period={surveyItem.period}
                        startAt={surveyItem.start_at}
                        answers={surveyItem.answers}
                        statusValue={surveyItem.status}
                        qtdPerCycle={surveyItem.cycle}
                        percentAnswers={surveyItem.percent_answers}
                        totalQuestions={surveyItem.questions}
                        anonymous={surveyItem.anonymous}
                        category={surveyItem.category}
                        onReloadSurveys={onReloadSurveys}
                      />
                    ))}
                  </div>

                  <PaginationControl
                    pageCount={pageCount}
                    page={filter.page - 1}
                    onChangePage={handleChangePage}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              className={twMerge([
                'w-full h-[calc(100%-16px)] flex items-center justify-center',
              ])}
            >
              <div className="max-w-[248px] flex flex-col items-center">
                <EmptyMessage
                  icon="action"
                  title={t('No survey')}
                  description={t(
                    'Explore your employees information to make decisions for your company',
                  )}
                />
                <Button
                  theme="link-primary"
                  leftIcon="add"
                  to={`/research/${researchType}/add`}
                >
                  {t('New survey')}
                </Button>
              </div>
            </div>
          ))}

        {isLoading ? (
          <Loading
            width="80px"
            height="80px"
            color="primary-color"
            className="pt-12"
          />
        ) : (
          !loadingChange &&
          surveyItems.length <= 0 &&
          isFilterApplied && (
            <div
              className={twMerge([
                'w-full h-[calc(100%-341px)] flex items-center justify-center',
              ])}
            >
              <div className="max-w-[248px] flex flex-col items-center">
                <EmptyMessage
                  icon="action"
                  title={t('No survey was found')}
                  description={t(
                    'Change the words in the search field or adjusting the filters and try again.',
                  )}
                />
              </div>
            </div>
          )
        )}
      </div>

      {openModal && (
        <Modal
          content={
            <FilterModal
              customFilter={{
                minimum: filter.minimum,
                maximum: filter.maximum,
                start_at: filter.start_at,
                finish_at: filter.finish_at,
                anonymous: filter.anonymous,
              }}
              onApply={handleApplyCustomFilter}
              initialState={{
                minimum: '0',
                maximum: '0',
                start_at: '',
                finish_at: '',
                anonymous: 'all',
              }}
              onCancel={() => setOpenModal((currentState) => !currentState)}
            />
          }
          width="100%"
          maxWidth="865px"
          onCloseButtonClick={() =>
            setOpenModal((currentState) => !currentState)
          }
          disableBackgroundClick
        />
      )}
    </StyledSurveyList>
  );
};

export default SurveyList;
