import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import Text from 'components/atom/Text';

import { HistoryItemProps } from './types';

import { StyledHistoryItem } from './styles';

const HistoryItem: React.FC<HistoryItemProps> = ({
  changes_fields,
  created_at,
  created_by,
  type,
  hideTitle,
}) => {
  const { t } = useTranslation();

  const historyMessages = {
    create: t('The content was created'),
    update: t('The content has been modified'),
    finish: t('The content has been finalized'),
    finishing: t('The content is finalizing'),
    archived: t('The content has been archived'),
    duplicated: t('The content was duplicated'),
    start: t('Content has started'),
    scheduled: t('The content has been scheduled'),
    canceled: t('Content has been canceled'),
  };

  const getTitle = () => {
    return historyMessages[type as keyof typeof historyMessages];
  };

  const getDescription = () => {
    if (!created_by) {
      return getTimeFromApiDate(created_at);
    }
    return t('{{ created_at_time }} by {{created_by}}', {
      created_at_time: getTimeFromApiDate(created_at),
      created_by: created_by,
    });
  };

  return (
    <StyledHistoryItem>
      {!hideTitle && (
        <Text weight="700" className="title">
          {getDateFromApiDate(created_at)}
        </Text>
      )}
      <div className="history-action">
        <Text as="h6">{getTitle()}</Text>
        {changes_fields && changes_fields.length > 0 && (
          <div className="modifications">
            <Text color="grayscale-200">
              {t('Modifications')}:{' '}
              {changes_fields.toString().replaceAll(',', ', ').toLowerCase()}.
            </Text>
          </div>
        )}
        <Text color="grayscale-200">{getDescription()}</Text>
      </div>
    </StyledHistoryItem>
  );
};

export default HistoryItem;
