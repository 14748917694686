import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledWizardAccountConfirmed } from './styles';

import Card from 'components/atom/Card';

import ChangeLanguage from 'components/molecule/ChangeLanguage';
import Logo from 'components/molecule/Logo';
import Button from 'components/molecule/Button';
import PageLoading from 'components/molecule/PageLoading';

import { postConfirmCompany } from 'apis/wizard';
import DotPaginator from 'components/atom/DotPaginator';
import WelcomeStage from './components/WelcomeStage';
import LoginTutorialStage from './components/LoginTutorialStage';

const stageNumber = 2;

const WizardAccountConfirmed: React.FC = () => {
  const { t } = useTranslation();

  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [stageIndex, setStageIndex] = useState(0);
  const [loginInfo, setLoginInfo] = useState({
    companyName: '',
    companySlug: '',
    userName: '',
    userSlug: '',
  });

  const handleContinueButton = () => {
    if (stageIndex < stageNumber - 1) {
      setStageIndex((stageIndex) => stageIndex + 1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (token) {
      postConfirmCompany(token)
        .then((response) => {
          setLoginInfo({
            companyName: response.data.company.name,
            companySlug: response.data.company.slug,
            userName: response.data.user.name,
            userSlug: response.data.user.slug,
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            navigate('/', { replace: true });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, token]);

  return (
    <StyledWizardAccountConfirmed>
      {loading && <PageLoading />}

      <ChangeLanguage />

      <Card noBorder="true">
        <div className="information-wrapper">
          <div className="logo-wrapper">
            <Logo size="large" />
          </div>
          {stageIndex === 0 && (
            <WelcomeStage
              userName={loginInfo.userName}
              companyName={loginInfo.companyName}
            />
          )}
          {stageIndex === 1 && (
            <LoginTutorialStage
              companySlug={loginInfo.companySlug}
              userSlug={loginInfo.userSlug}
            />
          )}
        </div>
        <div className="action-wrapper">
          <Button
            theme="link-dark-gray"
            rounded="true"
            disabled={stageIndex === 0}
            onClick={() => setStageIndex((stageIndex) => stageIndex - 1)}
            leftIcon="arrow-left-s-line"
          >
            {t('Back')}
          </Button>
          <div className="carrossel-pagination">
            <DotPaginator activeIndex={stageIndex} pageAmount={stageNumber} />
          </div>
          <Button theme="primary" rounded="true" onClick={handleContinueButton}>
            {stageIndex < stageNumber - 1 ? t('Continue') : t('Go to Login!')}
          </Button>
        </div>
      </Card>
    </StyledWizardAccountConfirmed>
  );
};

export default WizardAccountConfirmed;
