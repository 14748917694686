import styled from 'styled-components';

export const StyledQuestionContent = styled.div`
  .input-error-message {
    margin-left: 12px;
  }

  .scale-size {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;

    .text {
      margin-right: 24px;
    }

    .tab-menu {
      .button {
        width: 56px;
        min-width: 56px;
      }
    }
  }
`;
