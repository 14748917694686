import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { getDepartmentBreadcrumb } from 'apis/config';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Dropdown from 'components/molecule/Dropdown';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Tooltip from 'components/atom/Tooltip';

import { DepartmentBreadcrumbProps, DepartmentBreadcrumbLink } from './types';
import { BreadcrumbItem } from 'apis/general/types';

import { StyledDepartmentBreadcrumb } from './styles';

const DepartmentBreadcrumb: React.FC<DepartmentBreadcrumbProps> = ({
  links,
  rootPath,
  hasMore = false,
  initialLastId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState<DepartmentBreadcrumbLink[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);
  const [currentLastId, setCurrentLastId] = useState(initialLastId);

  const loadItems = useCallback(
    (lastId: string) => {
      getDepartmentBreadcrumb(organizationId, lastId, 10)
        .then((response) => {
          const { results } = response.data;

          setHasNextPage(!!response.data.more_breadcrumb);
          setCurrentLastId(results[results.length - 1].id);

          if (lastId !== initialLastId) {
            setItems((lastItems) => [
              ...lastItems,
              ...response.data.results.map((item: BreadcrumbItem) => ({
                label: item.name,
                path: `/hierarchy/settings/departments/${item.id}`,
              })),
            ]);
            return;
          }

          setItems(
            response.data.results.map((item: BreadcrumbItem) => ({
              label: item.name,
              path: `/hierarchy/settings/departments/${item.id}`,
            })),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, t, initialLastId, organizationId],
  );

  useEffect(() => {
    setMenuIsOpen(false);
  }, [initialLastId]);

  return (
    <StyledDepartmentBreadcrumb className="breadcrumb">
      <ol>
        {rootPath && (
          <li className="breadcrumb-item home-button">
            <div className="link-button-wrapper">
              <Button
                to=""
                theme="link-page-button"
                size="small"
                onClick={() => {
                  setItems([]);
                  navigate(rootPath);
                }}
              >
                <Icon name="home-6-line" />
              </Button>
            </div>
          </li>
        )}

        {links.length > 1 && (
          <li className="breadcrumb-item back-button">
            <div className="link-button-wrapper">
              <Button
                to=""
                theme="link-page-button"
                size="small"
                onClick={() => {
                  setItems([]);
                  navigate(links[links.length - 2].path);
                }}
              >
                <Icon name="arrow-left-line" />
              </Button>
            </div>
          </li>
        )}

        {hasMore && (
          <li className="breadcrumb-item more-options">
            <div className="link-button-wrapper">
              <Dropdown
                open={menuIsOpen}
                setOpen={(value) => setMenuIsOpen(value)}
                toggleElement={<Icon name="more" color="grayscale-200" />}
                toggleButtonTheme="link-page-button"
                onOpen={() => {
                  setLoading(true);
                  setItems([]);
                  loadItems(initialLastId);
                }}
              >
                <div
                  className="link-list default-scroll"
                  ref={(currentRef) => setScrollElement(currentRef)}
                >
                  {items.map((item, index) => (
                    <Button
                      theme="link-dark"
                      radius="0px"
                      size="small"
                      onClick={() => navigate(item.path)}
                      key={index}
                    >
                      {item.label}
                    </Button>
                  ))}

                  {(loading || loadMoreLoading) && (
                    <Loading type="bubbles" height="28px" width="28px" />
                  )}

                  {!loading && items.length === 0 && (
                    <EmptyMessage title={t('No data')} />
                  )}
                </div>
              </Dropdown>
              <Icon name="arrow-right-s-line" color="grayscale-200" />
            </div>
          </li>
        )}
        {links.map((link, index) => (
          <li
            key={index}
            className={`breadcrumb-item link-item ${
              index === links.length - 1 ? 'active' : ''
            }`}
          >
            {index === links.length - 1 ? (
              <Tooltip
                id={`tooltip-${link.path}`}
                content={link.label}
                key={index}
              >
                <Text weight="700" as="h6">
                  {link.label}
                </Text>
              </Tooltip>
            ) : (
              <div className="link-button-wrapper">
                <Tooltip
                  id={`tooltip-${link.path}`}
                  content={link.label}
                  key={index}
                >
                  <Button
                    onClick={() => navigate(link.path)}
                    theme="link-page-button"
                    size="small"
                  >
                    {link.label}
                  </Button>
                </Tooltip>
                <Icon name="arrow-right-s-line" color="grayscale-200" />
              </div>
            )}
          </li>
        ))}
      </ol>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadItems(currentLastId);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledDepartmentBreadcrumb>
  );
};

export default DepartmentBreadcrumb;
