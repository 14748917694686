import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import FilesTableInfo from './components/FilesTableInfo';
import FilesTableSize from './components/FilesTableSize';
import PageLoading from 'components/molecule/PageLoading';
import FilesTableShared from './components/FilesTableShared';
import FilesTableActions from './components/FilesTableActions';
import FilesTableCreatedBy from './components/FilesTableCreatedBy';
import FilesTableCreatedAt from './components/FilesTableCreatedAt';

import {
  ColumnProps,
  RowProps,
  SortableValueState,
} from 'components/organism/Table/types';
import { FileContentProps, FilesTableProps } from './types';

import { deleteDriveFiles, updateDriveFile } from 'apis/driveDashboard';

import { StyledFilesTable } from './styles';

const FilesTable: React.FC<FilesTableProps> = ({
  organizationId,
  files,
  filter,
  setFilter,
  fetchFiles,
  fetchRecents,
  pageCount,
  loading,
  setTableLoading,
  onShare,
  parentId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [fileTableRows, setFileTableRows] = useState<RowProps[]>([]);
  const [fileBeingRenamed, setFileBeingRenamed] = useState('');
  const [moveLoading, setMoveLoading] = useState(false);

  const handleFinishRename = useCallback(
    (id: string, newName: string) => {
      updateDriveFile(organizationId, id, { name: newName })
        .then(() => {
          fetchFiles({
            ...filter,
            ordering: filter.ordering.toString(),
          });

          if (!parentId) {
            fetchRecents();
          }

          dispatch(
            showMessage({
              title: t('File/folder renamed successfully'),
              theme: 'success',
              icon: 'check',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while renamed the file/folder',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        });
    },
    [dispatch, fetchRecents, fetchFiles, filter, organizationId, t, parentId],
  );

  const handleDelete = useCallback(
    (id: string) => {
      deleteDriveFiles(organizationId, id)
        .then(() => {
          fetchFiles({
            ...filter,
            ordering: filter.ordering.toString(),
            page: 1,
          });

          if (!parentId) {
            fetchRecents();
          }

          dispatch(
            showMessage({
              title: t('File/folder removed successfully'),
              theme: 'success',
              icon: 'check',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while deleting the file/folder',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        });
    },
    [dispatch, fetchRecents, fetchFiles, filter, organizationId, t, parentId],
  );

  const generateFileTableRows = useCallback(
    (data: FileContentProps[]) => {
      return data.map((content, index) => {
        const {
          id,
          extension,
          file_app_url,
          url_view,
          name,
          owner,
          created_at,
          size_format,
          is_shared,
          is_folder,
          parent,
          segmentations,
        } = content;

        return {
          cells: [
            {
              content: (
                <FilesTableInfo
                  id={id}
                  index={index}
                  name={name}
                  type={is_folder ? 'folder' : extension}
                  renaming={fileBeingRenamed === id}
                  onFinishRename={(id: string, newName: string) =>
                    handleFinishRename(id, newName)
                  }
                  onCloseRename={() => setFileBeingRenamed('')}
                />
              ),
              clickable: is_folder && fileBeingRenamed !== id,
              onClick: () => {
                setTableLoading(true);
                navigate(`/drive/dashboard/${id}`);
              },
            },
            {
              content: <FilesTableCreatedBy index={index} owner={owner} />,
            },
            {
              content: <FilesTableSize size={size_format} />,
            },
            {
              content: <FilesTableCreatedAt period={created_at} />,
            },
            {
              content: <FilesTableShared isShared={is_shared} />,
            },
            {
              content: (
                <FilesTableActions
                  id={id}
                  name={name}
                  fileAppUrl={file_app_url}
                  segmentationItem={segmentations[0] || null}
                  onRenameClick={(id: string) => setFileBeingRenamed(id)}
                  onDeleteClick={(id: string) => handleDelete(id)}
                  onShare={onShare}
                  parentId={parent || ''}
                  fileLink={file_app_url}
                  isShared={is_shared}
                  viewFile={url_view}
                  onLoadDriveFiles={() => {
                    fetchFiles({
                      ...filter,
                      ordering: filter.ordering.toString(),
                    });
                    fetchRecents();
                  }}
                  setMoveLoading={setMoveLoading}
                  isFolder={is_folder}
                />
              ),
            },
          ],
        };
      });
    },
    [
      fileBeingRenamed,
      onShare,
      navigate,
      setTableLoading,
      handleDelete,
      handleFinishRename,
      fetchFiles,
      fetchRecents,
      filter,
    ],
  );

  const handleOrder = useCallback(
    (sortableValue: SortableValueState) => {
      setFilter((updatedFiltersState) => {
        let elementIndex = -1;
        const { page, search, ordering } = updatedFiltersState;
        const newOrdering = [...ordering];

        if (sortableValue.value === 0) {
          elementIndex = newOrdering.indexOf(`-${sortableValue.key}`);
          newOrdering.splice(elementIndex, elementIndex === -1 ? 0 : 1);
        }

        if (sortableValue.value === 1) {
          elementIndex = newOrdering.indexOf(sortableValue.key);
          newOrdering.splice(elementIndex, elementIndex === -1 ? 0 : 1);
          newOrdering.push(sortableValue.key);
        }

        if (sortableValue.value === -1) {
          elementIndex = newOrdering.indexOf(sortableValue.key);
          if (elementIndex !== -1) {
            newOrdering[elementIndex] = `-${sortableValue.key}`;
          }
        }

        fetchFiles({
          page,
          search,
          ordering: newOrdering.toString(),
        });

        updatedFiltersState.ordering = newOrdering;
        return updatedFiltersState;
      });
    },
    [fetchFiles, setFilter],
  );

  const handleChangePage = (currentPage: number) => {
    const newFilter = {
      ...filter,
      page: currentPage + 1,
    };
    setFilter(newFilter);
    fetchFiles({
      ...filter,
      page: currentPage + 1,
      ordering: filter.ordering.toString(),
    });
  };

  const getSortableValue = useCallback(
    (key: string) => {
      const { ordering } = filter;
      const includeKey = ordering.includes(key);
      const includeNegativeKey = ordering.includes(`-${key}`);

      if (includeKey) return 1;
      if (includeNegativeKey) return -1;

      return 0;
    },
    [filter],
  );

  const [columns, setColumns] = useState<ColumnProps[]>([]);

  useEffect(() => {
    setColumns(() => [
      {
        content: <Text color="grayscale-200">{t('Name')}</Text>,
        sortable: {
          key: 'name',
          sortableOnClick: handleOrder,
          value: getSortableValue('name'),
        },
      },
      {
        content: <Text color="grayscale-200">{t('Created by')}</Text>,
      },
      {
        content: <Text color="grayscale-200">{t('Size')}</Text>,
        sortable: {
          key: 'size',
          sortableOnClick: handleOrder,
          value: getSortableValue('size'),
        },
      },
      {
        content: <Text color="grayscale-200">{t('Created at')}</Text>,
        sortable: {
          key: 'created_at',
          sortableOnClick: handleOrder,
          value: getSortableValue('created_at'),
        },
      },
      {
        content: <Text color="grayscale-200">{t('Sharing')}</Text>,
      },
      {
        content: <Text color="grayscale-200"></Text>,
      },
    ]);
  }, [getSortableValue, handleOrder, t]);

  useEffect(() => {
    if (files) {
      setFileTableRows(() => generateFileTableRows(files));
    }
  }, [files, fileBeingRenamed, generateFileTableRows]);

  useEffect(() => {
    if (loading) {
      setFileBeingRenamed('');
    }
  }, [loading]);

  return (
    <StyledFilesTable className={'file-table-wrapper'}>
      <Table
        columns={columns}
        setColumns={setColumns}
        rows={fileTableRows}
        scrollable={false}
        textAlign="start"
        fontColor="grayscale-200"
        hasPagination={true}
        pageCount={pageCount}
        page={filter.page - 1}
        onChangePage={handleChangePage}
        loading={loading}
      />
      {moveLoading && <PageLoading className="!fixed" />}
    </StyledFilesTable>
  );
};

export default FilesTable;
