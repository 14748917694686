import React, { useState, useId, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';
import Loading from 'components/molecule/Loading';
import SegmentationEditor from 'components/organism/SegmentationEditor';

import { getSegmentationDataValues } from 'utils/segmentation';

import { ContentSegmentationModalProps } from './types';
import { SegmentationSendProps } from 'apis/board/types';
import { SegmentationValueProps } from 'components/organism/SegmentationEditor/types';

import { StyledContentSegmentationModal } from './styles';

const ContentSegmentationModal: React.FC<ContentSegmentationModalProps> = ({
  title,
  subtitle,
  segmentationConfig,
  segmentationId,
  currentSegmentation,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const prefixId = useId();

  const [segmentation, setSegmentation] =
    useState<SegmentationValueProps[]>(currentSegmentation);
  const [loading, setLoading] = useState(true);

  const clearAll = () => {
    const newSegmentation = segmentation.map((row) => {
      row.values = [];
      return row;
    });
    setSegmentation(newSegmentation);
  };

  const addRow = () => {
    const rowId = `${prefixId}__${Date.now()}`;
    setSegmentation([
      ...segmentation,
      {
        id: rowId,
        key: '',
        operator: 'is_equal',
        values: [],
        type: 'string',
      },
    ]);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    const data: SegmentationSendProps = {
      id: segmentationId,
      entries: segmentation
        .filter((item) => !!item.key)
        .map((item) => ({
          id: item.id.includes(':') ? null : item.id,
          key: item.key,
          operator: item.operator,
          type: item.type,
          values: getSegmentationDataValues(item.type, item.values),
        })),
    };

    if (
      data.entries.some(
        (item) =>
          item.operator !== 'is_set' &&
          item.operator !== 'is_not_set' &&
          (!item.values || item.values.length === 0 || item.values[0] === ''),
      )
    ) {
      dispatch(
        showMessage({
          title: t('There are fields that have not been filled'),
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
      return;
    }

    onSave(data, segmentation);
    dispatch(closeModal());
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  return (
    <StyledContentSegmentationModal className="segmentation-editor">
      <Text className="title" as="h4" weight="700">
        {title}
      </Text>
      <Text className="subtitle" color="grayscale-200">
        {subtitle}
      </Text>

      {!loading && (
        <>
          <SegmentationEditor
            segmentationConfig={segmentationConfig}
            segmentation={segmentation}
            setSegmentation={setSegmentation}
          />

          <Separator />
          <div className="segmentation-actions">
            <div className="left-actions">
              <Button leftIcon="add" theme="link-primary" onClick={addRow}>
                {t('Add')}
              </Button>
              {segmentation.length > 0 && segmentation[0].key && (
                <Button theme="link-gray-primary" onClick={clearAll}>
                  {t('Clear all')}
                </Button>
              )}
            </div>
            <div className="right-actions">
              <Button theme="link-gray-primary" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button theme="primary" rounded="true" onClick={handleSave}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </>
      )}

      {loading && <Loading width="80px" height="80px" color="primary-color" />}
    </StyledContentSegmentationModal>
  );
};

export default ContentSegmentationModal;
