import styled from 'styled-components';

export const StyledCustomFieldTypeModal = styled.div`
  .modal-content {
    & > h4 {
      margin-bottom: 4px;
    }

    & > h6 {
      margin-bottom: 42px;
    }

    .custom-field-options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-self: flex-start;

      & > .button {
        height: max-content;
        margin: 0px 16px 16px 0px;
        width: 210px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 2px;

        &:hover {
          border-color: var(--gray-color);
        }
        &:active {
          border-color: var(--tertiary-color-1);
          background-color: var(--gray-color);
          .text {
            color: var(--dark-color);
          }
        }

        &.selected {
          border-color: var(--tertiary-color-1);
        }

        &:disabled {
          background-color: var(--gray-color);
          border-color: var(--gray-color);
          .text {
            color: var(--grayscale-200);
          }
        }

        .button-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          * {
            pointer-events: none;
          }

          .custom-field-icon {
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            position: relative;
            height: 44px;
            width: 44px;

            display: flex;
            justify-content: center;
            align-items: center;

            .icon-background {
              width: 100%;
              height: 100%;
              background-color: var(--tertiary-color-1);
              opacity: 0.1;
            }

            .icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              font-size: 24px;
              color: var(--tertiary-color-1);
            }
          }
        }
      }
    }

    hr {
      margin: 24px 0px 32px 0px;
      display: block;
    }
  }

  .modal-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button {
      width: 100%;
      max-width: 396px;
    }
  }
`;
