import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { cn } from 'utils/cn';

import { loadBreadcrumbItems } from 'apis/general';
import { BreadcrumbItem } from 'apis/general/types';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Dropdown from 'components/molecule/Dropdown';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { BreadcrumbProps, BreadcrumbLink } from './types';

import { StyledBreadcrumb } from './styles';

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  links,
  fetchUrl,
  rootPath,
  hasMore = false,
  hasBackButton = false,
  linkOnClick,
  backButttonOnClick,
  rootOnClick,
  hasCharLimit,
  limit,
  fetchParams,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const splitedPath = rootPath?.split('?');

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState<BreadcrumbLink[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const loadItems = useCallback(
    (page: number) => {
      loadBreadcrumbItems(fetchUrl || '', page, fetchParams)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);

          if (page > 1) {
            setItems((lastItems) => [
              ...lastItems,
              ...response.data.results.map((item: BreadcrumbItem) => ({
                label: item.name,
                path:
                  splitedPath && splitedPath[1]
                    ? `${splitedPath[0]}/${item.id}?${splitedPath[1]}`
                    : `${rootPath}/${item.id}`,
              })),
            ]);

            return;
          }

          setItems(
            response.data.results.map((item: BreadcrumbItem) => ({
              label: item.name,
              path:
                splitedPath && splitedPath[1]
                  ? `${splitedPath[0]}/${item.id}?${splitedPath[1]}`
                  : `${rootPath}/${item.id}`,
            })),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, t, fetchUrl, rootPath, splitedPath, fetchParams],
  );

  useEffect(() => {
    setMenuIsOpen(false);
  }, [links]);

  return (
    <StyledBreadcrumb className="breadcrumb">
      <ol>
        {rootPath && (
          <li className="breadcrumb-item more-options">
            <div className="link-button-wrapper">
              <Button
                to=""
                theme="link-page-button"
                size="small"
                onClick={() => {
                  setItems([]);

                  if (!rootOnClick && rootPath) {
                    navigate(rootPath);
                    return;
                  }
                  rootOnClick?.(rootPath || '');
                }}
              >
                <Icon name="home-6-line" />
              </Button>
            </div>
          </li>
        )}

        {hasBackButton && links.length > 1 && (
          <li className="breadcrumb-item back-button">
            <div className="link-button-wrapper">
              <Button
                to=""
                theme="link-page-button"
                size="small"
                onClick={() => {
                  const targetPath = links[links.length - 2].path ?? '/';
                  setItems([]);

                  if (!backButttonOnClick) {
                    navigate(targetPath);
                    return;
                  }

                  backButttonOnClick(targetPath);
                }}
              >
                <Icon name="arrow-left-line" />
              </Button>
            </div>
          </li>
        )}

        {hasMore && fetchUrl && (
          <li className="breadcrumb-item more-options">
            <div className="link-button-wrapper">
              <Dropdown
                open={menuIsOpen}
                setOpen={() => setMenuIsOpen(!menuIsOpen)}
                toggleElement={<Icon name="more" color="grayscale-200" />}
                toggleButtonTheme="link-page-button"
                onOpen={() => {
                  setLoading(true);
                  setItems([]);
                  loadItems(1);
                }}
              >
                <div
                  className="link-list default-scroll"
                  ref={(currentRef) => setScrollElement(currentRef)}
                >
                  {items.map((item, index) => (
                    <Button
                      theme="link-dark"
                      radius="0px"
                      size="small"
                      key={index}
                      onClick={() => {
                        const targetPath = item.path ?? '/';
                        setMenuIsOpen(false);
                        setItems([]);

                        if (!linkOnClick) {
                          navigate(targetPath);
                          return;
                        }

                        linkOnClick(targetPath);
                      }}
                    >
                      <Text
                        weight="700"
                        as="h6"
                        className="truncate max-w-[27ch]"
                        style={{ fontSize: '16px' }}
                      >
                        {item.label}
                      </Text>
                    </Button>
                  ))}

                  {(loading || loadMoreLoading) && <Loading type="bubbles" />}

                  {!loading && items.length === 0 && (
                    <EmptyMessage title={t('No data')} />
                  )}
                </div>
              </Dropdown>
              <Icon name="arrow-right-s-line" color="grayscale-200" />
            </div>
          </li>
        )}
        {links.map((link, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${
              index === links.length - 1 ? 'active' : ''
            }`}
          >
            {index === links.length - 1 ? (
              <Tooltip
                id={`tooltip-${link.id ? link.id : link.path}`}
                content={link.label}
                key={index}
                disabled={!hasCharLimit}
              >
                <Text
                  weight="700"
                  as="h6"
                  className={cn({
                    ['truncate']: hasCharLimit,
                  })}
                  style={{ maxWidth: hasCharLimit ? `${limit}ch` : 'auto' }}
                >
                  {link.label}
                </Text>
              </Tooltip>
            ) : (
              <div className="link-button-wrapper">
                <Tooltip
                  id={`tooltip-${link.id ? link.id : link.path}`}
                  content={link.label}
                  key={index}
                  disabled={!hasCharLimit}
                >
                  <Button
                    onClick={link.action}
                    theme="link-page-button"
                    size="small"
                  >
                    <Text
                      weight="700"
                      as="h6"
                      className={cn({
                        ['truncate']: hasCharLimit,
                      })}
                      style={{ maxWidth: hasCharLimit ? `${limit}ch` : 'auto' }}
                    >
                      {link.label}
                    </Text>
                  </Button>
                </Tooltip>
                <Icon name="arrow-right-s-line" color="grayscale-200" />
              </div>
            )}
          </li>
        ))}
      </ol>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadItems(currentPage + 1);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
