import styled from 'styled-components';

export const StyledDepartmentBreadcrumb = styled.div`
  ol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h6 {
      margin-top: 1px;
    }

    .back-button,
    .home-button {
      margin-right: 4px !important;

      .button-content {
        .icon {
          margin: 0;
        }
      }
    }

    .more-options {
      .more-button {
        background: none;
      }

      .button-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin: 0;
        }
      }

      .dropdown-content {
        max-height: 240px;

        .link-list {
          position: relative;
          width: max-content;
          min-width: 100px;
          max-width: max-content;

          min-height: 32px;

          max-height: 240px;
          overflow-y: scroll;

          .button {
            width: 100%;
            margin: 0;
            justify-content: flex-start;
            padding: 8px 16px;
            height: max-content;

            .button-content {
              font-size: 16px;
              height: max-content;
              text-align: start;
            }

            &:first-child {
              border-radius: 6px 6px 0px 0px;
            }

            &:last-child {
              border-radius: 0px 0px 6px 6px;
            }
          }
        }
      }
    }

    .breadcrumb-item {
      margin: 4px 0px;

      .text {
        max-width: 27ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.active {
        h6 {
          margin-top: 4px;
        }
      }
    }

    .link-button-wrapper {
      display: flex;
      align-items: center;

      .tooltip {
        .button {
          margin-right: 4px;

          .button-content {
            margin: 0;
            margin-top: 4px;
            font-size: 18px;
            font-weight: 700;
            max-width: 27ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0.9;
          }
        }
      }

      .icon {
        margin-right: 8px;
      }
    }
  }
`;
