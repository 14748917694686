import styled from 'styled-components';

export const StyledProfileSelect = styled.div`
  .card {
    width: 100% !important;
    padding: 20px 0px !important;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 4px;
  }

  .description {
    margin-bottom: 32px;
  }

  .profiles-select {
    margin-bottom: 16px;
  }

  .view-more-button {
    width: 100%;
    margin-top: 16px;
  }

  .selected-list-wrapper {
    min-height: 50px;
    max-height: 300px;
    min-height: 80px;
    overflow-y: scroll;
  }

  .selected-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .tag {
      display: flex;
      align-items: center;
      margin-right: 12px;
      margin-bottom: 12px;

      .button {
        margin-left: 8px;
        padding: 0;
        width: 20px;
        min-width: 20px;
        height: 16px;
        min-height: 16px;
      }
    }
  }

  .remove-all-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .empty-message {
    min-width: 100%;
    padding: 0px 32px;
  }
`;
