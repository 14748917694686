import styled from 'styled-components';

export const StyledDepartmentForm = styled.div`
  .title {
    margin-bottom: 6px;
  }

  .input {
    margin: 24px 0px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button:first-child {
      padding-left: 0px;
    }
  }
`;
