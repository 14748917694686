import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import Text from 'components/atom/Text';
import InfiniteScroll from 'components/atom/InfiniteScroll';

import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Loading from 'components/molecule/Loading';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Image from 'components/molecule/Image';
import Checkbox from 'components/molecule/Checkbox';

import { getFixedContents } from 'apis/board';

import { BoardContentProps, BoardContentTypesEnum } from '../../types';

import { StyledPinnedContentListModal } from './styles';

interface PinnedContentListModalProps {
  onDelete: (content_ids: string[]) => void;
}

const PinnedContentListModal: React.FC<PinnedContentListModalProps> = ({
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const [items, setItems] = useState<BoardContentProps[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const loadItems = useCallback(
    (page: number, search: string) => {
      getFixedContents(organizationId, {
        page,
        search,
      })
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);

          if (page > 1) {
            setItems((lastItems) => [...lastItems, ...response.data.results]);
            return;
          }

          setItems(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, t, organizationId],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      loadItems(1, value);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const getFormattedDescription = useCallback(
    (tags: number, options: number, type: string): string => {
      const textTags: string =
        tags > 0 ? `${tags} ${t('tag', { count: tags })}` : '';
      let textOpts: string =
        options > 0 ? `${options} ${t('option', { count: options })}` : '';

      if (type === BoardContentTypesEnum.QuickSurvey && options === 0) {
        textOpts = t('No options');
      }

      if (textTags && !textOpts) return textTags;
      else if (!textTags && textOpts) return textOpts;
      else if (textTags && textOpts) return `${textTags} | ${textOpts}`;
      return '';
    },
    [t],
  );

  useEffect(() => {
    setLoading(true);
    loadItems(1, '');
  }, [loadItems]);

  const handleCheckItem = (checked: boolean, content_id: string) => {
    if (checked) {
      setSelectedItems((prevItems) => [...prevItems, content_id]);
    } else {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item !== content_id),
      );
    }
  };

  const handleDelete = () => {
    dispatch(closeModal());
    onDelete(selectedItems);
  };

  const dateTimeConvert = (date_time: string | null) => {
    if (date_time !== null) {
      const time = getTimeFromApiDate(date_time);
      const date = getDateFromApiDate(date_time);
      return `${date} ${time}h`;
    } else {
      return t('No date defined');
    }
  };

  const fixedDatesGenerator = (
    fixed_start_at: string | null,
    fixed_finish_at: string | null,
  ) => {
    if (!fixed_start_at && !fixed_finish_at) {
      return (
        <Text weight="400" color="grayscale-200" className="date">
          {t('No dates defined')}
        </Text>
      );
    } else if (fixed_start_at && !fixed_finish_at) {
      return (
        <Text weight="400" color="grayscale-200" className="date">
          {t('Start at')} {dateTimeConvert(fixed_start_at)}
        </Text>
      );
    } else if (!fixed_start_at && fixed_finish_at) {
      return (
        <Text weight="400" color="grayscale-200" className="date">
          {t('Finish at')} {dateTimeConvert(fixed_finish_at)}
        </Text>
      );
    } else {
      return (
        <>
          <Text weight="400" color="grayscale-200" className="date">
            {t('Start at')} {dateTimeConvert(fixed_start_at)}
          </Text>
          <Text weight="400" color="grayscale-200" className="date">
            {t('Finish at')} {dateTimeConvert(fixed_finish_at)}
          </Text>
        </>
      );
    }
  };

  return (
    <StyledPinnedContentListModal>
      <Text as="h4" weight="700">
        {t('Manage Pins')}
      </Text>
      <Text as="p" className="modal-description" color="grayscale-200">
        {t(
          'By removing the highlight, the contents will be placed in the original submission order. You can re-highlight the contents whenever you wish.',
        )}
      </Text>
      <Input
        placeholder={t('Search by name')}
        icon="search"
        iconColor="dark-color"
        value={search}
        onChange={handleSearch}
      />

      <div
        className="list default-scroll"
        ref={(currentRef) => setScrollElement(currentRef)}
      >
        {items.map((item, index) => (
          <div className="item" key={index}>
            <div className="left-wrapper">
              <Checkbox
                onChange={(event) =>
                  handleCheckItem(event.target.checked, item.content_id)
                }
              />
              <Image src={item.cover} width="90px" height="72px" />

              <div className="title-wrapper">
                {item.title && (
                  <Text weight="700" className="title" color="grayscale-400">
                    {item.title}
                  </Text>
                )}
                {!item.title && (
                  <Text
                    weight="700"
                    italic
                    className="title"
                    color="grayscale-400"
                  >
                    {t('No title')}
                  </Text>
                )}
                <Text color="grayscale-400" className="description">
                  {getFormattedDescription(item.tags, item.options, item.type)}
                </Text>
              </div>
            </div>

            <div className="creator-wrapper">
              {fixedDatesGenerator(item.fixed_start_at, item.fixed_finish_at)}
              <Text weight="700" color="grayscale-200" className="creator">
                {item.fixed_by ? item.fixed_by : t('No user defined.')}
              </Text>
            </div>
          </div>
        ))}

        {loadMoreLoading && <Loading type="bubbles" />}
      </div>

      {loading && <Loading type="bubbles" />}

      {!loading && items.length === 0 && (
        <EmptyMessage title={t('No matching items')} icon="close-circle-line" />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadItems(currentPage + 1, search);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />

      <div className="form-action">
        <Button theme="link-dark-gray" onClick={() => dispatch(closeModal())}>
          {t('Cancel')}
        </Button>
        <Button
          theme="dark"
          rounded="true"
          disabled={selectedItems.length === 0}
          onClick={handleDelete}
        >
          {t('Remove highlighted ({{postsNumber}}) content', {
            postsNumber: selectedItems.length,
            count: selectedItems.length,
          })}
        </Button>
      </div>
    </StyledPinnedContentListModal>
  );
};

export default PinnedContentListModal;
