import styled from 'styled-components';

export const StyledHistoryItem = styled.div`
  width: 100%;

  .title {
    margin: 16px 0px;
    padding-left: 32px;
  }

  .modifications {
    margin-bottom: 4px;
  }

  .history-action {
    padding: 20px 0px;
    padding-left: 32px;
    border-bottom: 1px solid var(--grayscale-100);
    h6 {
      margin-bottom: 4px;
    }
  }
`;
