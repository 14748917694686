import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import PeriodFilter from './components/PeriodFilter';
import AnonymousFilter from './components/AnonymousFilter';

import { CustomFilterAttrsTypes } from './types';

import { StyledFilterModal } from './styles';

interface FilterModalProps {
  customFilter: CustomFilterAttrsTypes;
  onApply: (filterState: CustomFilterAttrsTypes) => void;
  initialState: CustomFilterAttrsTypes;
  onCancel: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  customFilter,
  onApply,
  initialState,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<CustomFilterAttrsTypes>({
    ...customFilter,
  });

  const handleClearAll = () => {
    setFilter({ ...initialState });
  };

  const clearAllIsVisible = () => {
    const filterArray = Object.keys(filter).map((key) => {
      return filter[key as keyof CustomFilterAttrsTypes];
    });

    const isVisible = filterArray.some(
      (item: unknown) =>
        (Array.isArray(item) && item.length !== 0) ||
        (!Array.isArray(item) &&
          item !== null &&
          !Array.isArray(item) &&
          item !== '' &&
          !Array.isArray(item) &&
          item !== 'KB'),
    );

    return isVisible;
  };

  const handleSave = () => {
    onApply({ ...filter });
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledFilterModal className="filter-modal">
      <div className="filter-modal-header">
        <div className="text">
          <Text as="h4" weight="700">
            {t('Filters')}
          </Text>
        </div>
        <div className="actions">
          {clearAllIsVisible() && (
            <Button
              theme="link-gray-primary"
              size="small"
              onClick={handleClearAll}
            >
              {t('Reset filters')}
            </Button>
          )}
        </div>
      </div>

      <div className="filter-modal-content">
        {/* <RegisteredParticipantsFilter filter={filter} setFilter={setFilter} /> */}
        <PeriodFilter filter={filter} setFilter={setFilter} />
        <AnonymousFilter filter={filter} setFilter={setFilter} />
      </div>

      <div className="filter-modal-footer">
        <Button theme="link-dark-gray" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="dark" onClick={handleSave}>
          {t('Show results')}
        </Button>
      </div>
    </StyledFilterModal>
  );
};

export default FilterModal;
