import styled from 'styled-components';

export const StyledCustomFieldsConfigItem = styled.div`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 16px;
  border-bottom: 1px solid var(--grayscale-100);

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  .info {
    .custom-field-icon {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      margin-right: 10px;

      i {
        font-size: 24px;
      }
    }

    & .tooltip p {
      max-width: 56ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
