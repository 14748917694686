import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';

import { CustomFilterAttrsTypes } from '../../types';

import { AvailableVisibleTypes } from 'pages/surveys/SurveyList/types';

import Select from 'components/molecule/Select';
import {
  SelectOption,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';

import { StyledAnonymousFilter } from './styles';

export interface AnonymousFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const AnonymousFilter: React.FC<AnonymousFilterProps> = ({
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  const selectOptions: readonly SelectOption[] = useMemo(() => {
    return [
      {
        value: 'all',
        label: (
          <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('AllF')}
            </Text>
          </div>
        ),
      },
      {
        value: 'true',
        label: (
          <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Anonymous')}
            </Text>
          </div>
        ),
      },
      {
        value: 'false',
        label: (
          <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Not anonymous')}
            </Text>
          </div>
        ),
      },
    ];
  }, [t]);

  const [selectedOption, setSelectedOption] = useState<SelectedOptionValueType>(
    selectOptions[0],
  );

  const handleChangeVisualization = (option: SelectedOptionValueType) => {
    const currentType = (
      option && 'value' in option ? option.value : 'all'
    ) as keyof typeof AvailableVisibleTypes;

    setFilter({
      ...filter,
      anonymous: currentType,
    });
  };

  useEffect(() => {
    setSelectedOption(
      selectOptions.find(
        (option) => filter.anonymous && filter.anonymous === option.value,
      ) || selectOptions[0],
    );
  }, [filter.anonymous, selectOptions]);

  return (
    <StyledAnonymousFilter>
      <Text as="h5" color="grayscale-400">
        {t('Type of answers')}
      </Text>
      <Select
        value={selectedOption}
        setValue={setSelectedOption}
        options={selectOptions}
        onChange={handleChangeVisualization}
        className="sort-selector min-w-[190px]"
      />
    </StyledAnonymousFilter>
  );
};

export default AnonymousFilter;
