import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import Tooltip from 'components/atom/Tooltip';

import { PushConfigItem, PushFilter } from './types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
  SearchableValue,
} from 'components/organism/Table/types';

import { StyledSmartPushsTable } from './styles';
import { useNavigate } from 'react-router-dom';
import Tag from 'components/atom/Tag';
import { getSmartPushs } from 'apis/smartpush';
import dayjs from 'dayjs';
import { defaultDateFormat } from 'settings';

const SmartPushsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<PushFilter>({
    search: '',
    message: '',
    order: '',
  });

  const getTimeAsStr = (date: Date) => {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const getStatusTheme = (key: string) => {
    switch (key) {
      case 'sending':
        return 'primary-100';
      case 'sent':
        return 'success-100';
      case 'canceled':
        return 'danger-100';
      default:
        return 'grayscale-100';
    }
  };

  const getTableRows = useCallback(
    (data: PushConfigItem[]) => {
      return data.map((item, index) => {
        return {
          onClick: () => navigate(`/smartpush/${item.id}`),
          cells: [
            {
              content: (
                <Tooltip
                  id={`title-${item.id}-${index}`}
                  content={item.clean_message}
                >
                  <Text color="grayscale-400">{item.clean_message}</Text>
                </Tooltip>
              ),
            },
            {
              content: (
                <Text color="grayscale-400">
                  {t('{{send_at}} at {{time_send_at}}', {
                    send_at: dayjs(new Date(item.send_at)).format(
                      defaultDateFormat,
                    ),
                    time_send_at: getTimeAsStr(new Date(item.send_at)),
                  })}
                </Text>
              ),
            },
            {
              content: (
                <Tag theme={getStatusTheme(item.status.key)}>
                  {item.status.value}
                </Tag>
              ),
            },
          ],
        };
      });
    },
    [navigate, t],
  );

  const loadPushs = useCallback(
    (page: number, filter: PushFilter) => {
      setTableLoading(true);

      getSmartPushs(organizationId, page, filter)
        .then((response) => {
          const { num_pages, results } = response.data;
          setPageCount(num_pages);
          setCurrentPage(page);
          setTableRows(() => getTableRows(results));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }

          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, organizationId, t, getTableRows],
  );

  const handleSearch = (values: SearchableValue[]) => {
    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        message: values.find((value) => value.key === 'message')?.value || '',
      };
      loadPushs(1, newFilter);
      return newFilter;
    });
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    const { value, key } = sortableValue;
    let newOrder = '';

    if (value === 1) {
      newOrder = key;
    }

    if (value === 0) {
      newOrder = '';
    }

    if (value === -1) {
      newOrder = `-${key}`;
    }

    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        order: newOrder,
      };
      loadPushs(1, newFilter);
      return newFilter;
    });
  };

  const handleChangePage = (newPage: number) => {
    loadPushs(newPage, filter);
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Message')}</Text>,
      searchable: {
        key: 'message',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by message'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Send at')}</Text>,
      sortable: {
        key: 'send_at',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
    },
    {
      content: <Text color="grayscale-200">{t('Status')}</Text>,
    },
  ]);

  useEffect(() => {
    loadPushs(1, {
      search: '',
      message: '',
      order: '',
    });
  }, [loadPushs]);

  return (
    <StyledSmartPushsTable className="pushs-table">
      <Table
        clickable={true}
        columns={columns}
        setColumns={setColumns}
        rows={tableRows}
        textAlign="start"
        fontColor="grayscale-200"
        hasPagination
        pageCount={pageCount}
        page={currentPage - 1}
        onChangePage={(page) => handleChangePage(page + 1)}
        loading={tableLoading}
        emptyMessageDescription={t(
          'If there are already smart pushs registered, change the words in the search bar and try again',
        )}
        scrollable
      />
    </StyledSmartPushsTable>
  );
};

export default SmartPushsTable;
