import styled from 'styled-components';

export const StyledChangeLanguage = styled.div`
  z-index: var(--z-index-level-2);

  .select {
    .react-select__control {
      padding-right: 0px;

      .react-select__single-value {
        margin-top: 0px !important;
      }

      .language-option {
        padding: 0px;

        .image {
          margin: 0px;
          position: relative;
        }

        p {
          display: none;
        }
      }
    }

    .react-select__menu {
      width: max-content;
      right: 0;
    }

    .language-option {
      display: flex;
      align-items: center;
      padding: 8px;
      width: max-content;

      .image {
        width: 20px;
        margin-right: 8px;
      }
    }
  }
`;
