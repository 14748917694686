import styled from 'styled-components';

export const StyledImportPreviewCard = styled.div`
  width: 100%;
  min-height: 284px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;

  &.in-progress {
    background-color: var(--gray-color);
    border: 1px solid var(--grayscale-100);
    box-shadow: none;
  }

  .import-loading {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .loading-wrapper {
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: var(--white-color);
      margin-right: 8px;
    }

    .text {
      width: max-content;
    }
  }

  .status {
    position: relative;
    margin-bottom: 20px;
    .tag {
      width: 52px;
      height: 52px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 10%;
    }
    .icon {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  .file-name {
    margin-bottom: 4px;
  }

  .description {
    margin-bottom: 24px;
  }
`;
