import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import { createDepartment, updateDepartment } from 'apis/config';

import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import PageLoading from 'components/molecule/PageLoading';

import { DepartmentFormProps } from './types';

import { StyledDepartmentForm } from './styles';

const DepartmentForm: React.FC<DepartmentFormProps> = ({
  onLoad,
  id,
  name,
  parent,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [departmentName, setDepartmentName] = useState(name || '');
  const [departmentNameHasError, setDepartmentNameHasError] = useState(false);
  const [departmentNameErrorMessage, setDepartmentNameErrorMessage] =
    useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateDepartment = () => {
    createDepartment(organizationId, departmentName, parent)
      .then(() => {
        dispatch(
          showMessage({
            title: t(
              'Department {{departmentName}} created successfully, it will be shown alphabetically on the list',
              {
                departmentName: departmentName,
              },
            ),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
        dispatch(closeModal());
        onLoad();
      })
      .catch((error) => {
        const { code, errors } = error.response.data;
        if (code === 'field_error') {
          const fieldError = errors.find(
            (item: { field: string; error: string }) => item.field === 'name',
          );
          if (fieldError) {
            setDepartmentNameHasError(true);
            setDepartmentNameErrorMessage(t(fieldError.error));
          }
          return;
        } else if (code === 'invalid' || code.indexOf('invalid') !== -1) {
          dispatch(
            showMessage({
              title: errors[0],
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t('An error occurred while saving department'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateDepartment = () => {
    if (!id) return;

    updateDepartment(organizationId, departmentName, id)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Department updated'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
        dispatch(closeModal());
        onLoad();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An error occurred while saving department'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    if (departmentName.trim().length === 0) {
      setDepartmentNameHasError(true);
      setDepartmentNameErrorMessage(t('This field may not be blank'));
      return;
    }

    setLoading(true);

    if (id) {
      handleUpdateDepartment();
      return;
    }
    handleCreateDepartment();
  };

  return (
    <StyledDepartmentForm>
      <Text as="h4" weight="700" className="title">
        {id ? t('Update department') : t('Create a department')}
      </Text>
      {/* <Text className="description">Descrição</Text> */}
      <Input
        value={departmentName}
        onChange={(event) => {
          setDepartmentNameHasError(false);
          setDepartmentNameErrorMessage('');
          setDepartmentName(event.target.value);
        }}
        limit={100}
        placeholder={t('Department')}
        hasError={departmentNameHasError}
        errorMessage={departmentNameErrorMessage}
      />
      <div className="actions">
        <Button
          theme="link-gray-primary"
          onClick={() => dispatch(closeModal())}
          size="small"
        >
          {t('Cancel')}
        </Button>
        <Button theme="primary" rounded="true" onClick={handleSave}>
          {t('Save')}
        </Button>
      </div>

      {loading && <PageLoading />}
    </StyledDepartmentForm>
  );
};

export default DepartmentForm;
