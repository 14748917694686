import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Loading from 'components/molecule/Loading';

import { ImportPreviewCardProps } from './types';
import { AvailableTagThemes } from 'components/atom/Tag/types';
import { AvailableColorTypes } from 'styles/global-styles';

import { StyledImportPreviewCard } from './styles';

const ImportPreviewCard: React.FC<ImportPreviewCardProps> = ({
  status,
  inProgressMessage = '',
  createdAt,
  createdAtTime,
  userName,
  fileUrl,
}) => {
  const { t } = useTranslation();

  const inProgress = status === 'in-progress';
  const tagStatusThemes = {
    'in-progress': 'support-pastel-2',
    success: 'success',
    error: 'danger',
  };
  const tagIconColors = {
    'in-progress': 'support-pastel-color-2',
    success: 'success-color',
    error: 'danger-color',
  };

  const getStatusMessage = () => {
    if (status === 'in-progress') return '';
    if (status === 'success') return t('completed');
    if (status === 'error') return t('failed');
  };

  return (
    <StyledImportPreviewCard className={`import-preview-card ${status}`}>
      {status === 'in-progress' && (
        <div className="import-loading">
          <div className="loading-wrapper">
            <Loading type="spin" width="32px" height="32px" />
          </div>
          <Text>{inProgressMessage || t('Import in progress')}</Text>
        </div>
      )}
      {!inProgress && status && (
        <div className="status">
          <Tag
            radius="12px"
            theme={tagStatusThemes[status] as keyof typeof AvailableTagThemes}
          />
          <Icon
            name="file"
            color={tagIconColors[status] as keyof typeof AvailableColorTypes}
          />
        </div>
      )}
      {userName && createdAt && createdAtTime && (
        <Text
          weight="700"
          color={'dark-color'}
          className="description"
          align="center"
        >
          {t(
            'Import {{status}} requested by {{user}} on {{created_at}} at {{created_at_time}}',
            {
              status: getStatusMessage(),
              user: userName,
              created_at: createdAt,
              created_at_time: createdAtTime,
            },
          )}
        </Text>
      )}
      {fileUrl && (
        <Button theme="link-primary" href={fileUrl} target="_blank">
          {t('Download')}
        </Button>
      )}
    </StyledImportPreviewCard>
  );
};

export default ImportPreviewCard;
