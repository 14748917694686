import React, { useEffect, useState } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';

import { AvailableIcons } from 'components/atom/Icon/types';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { StyledInput } from './styles';
import { get24hourTimeMask } from './utils';
import { AvailableColorTypes } from '../../../styles/global-styles';

export const AvailableInputThemes = {
  default: 'default',
  gray: 'gray',
  post: 'post',
  shadow: 'shadow',
  'secondary-dark': 'secondary-dark',
  dark: 'dark',
};

const AvailableCharacterCountTypes = {
  focused: 'focused',
  unfocused: 'unfocused',
};

export interface InputProps {
  id?: string;
  icon?: keyof typeof AvailableIcons;
  iconColor?: keyof typeof AvailableColorTypes;
  label?: string;
  focusedLabel?: string;
  hideLabel?: boolean;
  placeholder?: string;
  placeholderAsLabel?: boolean;
  limit?: number;
  hideCharacterCount?: boolean;
  characterCountType?: keyof typeof AvailableCharacterCountTypes;
  actions?: React.ReactNode;
  type?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  errorMessage?: string;
  theme?: keyof typeof AvailableInputThemes;
  disabled?: boolean | undefined;
  rounded?: string;
  className?: string;
  mask?: Mask;
  removeBreakLines?: boolean;
  autoFocus?: boolean;
}

const Input: React.FC<InputProps> = ({
  id,
  icon,
  iconColor = 'grayscale-200',
  label,
  focusedLabel,
  hideLabel = false,
  placeholder = '',
  placeholderAsLabel = false,
  limit,
  hideCharacterCount = false,
  characterCountType = 'unfocused',
  actions,
  type = 'text',
  value = '',
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  hasError = false,
  errorMessage,
  theme = 'default',
  disabled,
  rounded = 'false',
  className = '',
  mask,
  removeBreakLines = false,
  autoFocus = false,
}) => {
  const [count, setCount] = useState(0);
  const [showCounter, setShowCounter] = useState(
    characterCountType === 'unfocused',
  );
  const [loaded, setLoaded] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (removeBreakLines) {
      event.target.value = event.target.value.replaceAll('\n', '');
    }

    if (limit) {
      const newValue = event.target.value.substring(0, limit);
      setCount(newValue.length);
      event.target.value = newValue;
      onChange?.(event);
      return;
    }

    onChange?.(event);
  };

  const renderLabel = () => {
    const labelText: string =
      label || focusedLabel || (placeholderAsLabel ? placeholder : '');
    const shouldHideLabel: boolean =
      hideLabel || (focusedLabel && !value) || (placeholderAsLabel && !value);

    if (!labelText) return '';

    return (
      <label htmlFor={id}>
        <Text
          as="pre"
          color="grayscale-200"
          className={`input-label ${shouldHideLabel ? 'hide' : ''}`}
        >
          {labelText}
        </Text>
      </label>
    );
  };

  const handleOnFocus = () => {
    setShowCounter(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    setShowCounter(characterCountType === 'unfocused');
    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    if (value) {
      setCount(value.toString().length);
    }
  }, [value]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <StyledInput
      className={`input ${theme} ${className}`}
      rounded={rounded === 'true'}
    >
      {renderLabel()}
      <div>
        <div
          className={`input-content ${hasError ? 'has-error' : ''} ${
            disabled ? 'disabled' : ''
          }`}
        >
          {icon && <Icon name={icon} color={iconColor} />}

          {
            /* We can't set input timer with 24h format regardless of browser */
            /* In this specific this case, we will use a handmade input */
            type === 'time' && (
              <MaskedInput
                id={id || ''}
                className={'input-time'}
                name={id}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                mask={mask || get24hourTimeMask}
                keepCharPositions={true}
                guide={true}
                disabled={disabled}
              />
            )
          }
          {
            /* For other types of input, we keep using the normal input */
            type !== 'time' && !mask && (
              <input
                type={type}
                id={id || ''}
                name={id}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onKeyDown={onKeyDown}
                disabled={disabled}
                ref={(currentRef) => {
                  if (autoFocus && !loaded) {
                    currentRef?.focus();
                  }
                }}
              />
            )
          }

          {type !== 'time' && mask && (
            <MaskedInput
              id={id || ''}
              name={id}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              mask={mask || []}
              keepCharPositions={true}
              guide={true}
              disabled={disabled}
            />
          )}

          {actions && <div className="actions">{actions}</div>}
        </div>
      </div>

      <div className="information-wrapper">
        {!errorMessage && <span></span>}
        {errorMessage && (
          <Text as="pre" color="danger-color" className="input-error-message">
            {errorMessage}
          </Text>
        )}
        {limit && !hideCharacterCount && showCounter && (
          <Text as="pre" color="grayscale-200" className="input-limit">
            <span>{count}</span>/<span>{limit}</span>
          </Text>
        )}
      </div>
    </StyledInput>
  );
};

export default Input;
