import styled from 'styled-components';

export const StyledDepartmentsConfig = styled.div`
  width: 100%;

  .content-header {
    position: relative;
    justify-content: space-between;
  }

  .content {
    width: 100%;
    margin-top: 16px;
    overflow-y: visible !important;

    .breadcrumb {
      margin-bottom: 8px;
    }

    .table {
      width: 100%;
      margin-top: 32px;
      .table-wrapper {
        width: 100%;
        padding-bottom: 44px;
        table {
          width: 100%;
          tbody {
            tr {
              td {
                &:first-child {
                  .text {
                    max-width: 100ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 18px;
                  }
                }

                .department-column {
                  display: flex;
                  align-items: center;

                  .icon {
                    rotate: 270deg;
                    margin-right: 8px;
                  }

                  .text {
                    margin-right: 8px;
                  }

                  .tag {
                    color: var(--dark-color);
                    font-size: 12px;
                    background: var(--grayscale-100);
                  }
                }
              }
              td:last-child {
                width: 70px;
              }
            }
          }
        }
      }

      .pagination-control {
        margin-top: 16px;
      }
    }
  }
`;
