import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getProfileOptions,
  getSelectedProfileIds,
  getSelectedProfiles,
} from 'apis/config';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Card from 'components/atom/Card';
import Text from 'components/atom/Text';
import Tag from 'components/atom/Tag';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import MultiSelect from 'components/organism/MultiSelect';
import Loading from 'components/molecule/Loading';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { ProfileSelectProps, ProfileItem } from './types';
import { MultiSelectOption } from 'components/organism/MultiSelect/types';
import { MultiSelectAPIItem } from 'apis/config/types';

import { StyledProfileSelect } from './styles';

const ProfileSelect: React.FC<ProfileSelectProps> = ({
  title,
  description,
  selectedProfiles,
  setSelectedProfiles,
  currentProfileName,
  type,
  profileId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector((state) => state.organization.pk);

  // Profile select states
  const [search, setSearch] = useState('');
  const [selectedList, setSelectedList] = useState<string[]>([
    ...selectedProfiles,
  ]);
  const [addedList, setAddedList] = useState<string[]>([...selectedProfiles]);
  const [allIsChecked, setAllIsChecked] = useState(false);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Fetch states
  const [options, setOptions] = useState<MultiSelectOption[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [loadMoreOptionsLoading, setLoadMoreOptionsLoading] = useState(false);
  const [optionsHasNextPage, setOptionsHasNextPage] = useState(false);
  const [optionsCurrentPage, setOptionsCurrentPage] = useState(1);

  // Added list state
  const [selectedItems, setSelectedItems] = useState<ProfileItem[]>([]);
  const [selectedItemsLoading, setSelectedItemsLoading] = useState(false);
  const [selectedItemsHasNextPage, setSelectedItemsHasNextPage] =
    useState(false);
  const [selectedItemsCurrentPage, setSelectedItemsCurrentPage] = useState(1);

  const loadProfileOptions = (
    page: number,
    search: string,
    addedIs: string[],
  ) => {
    getProfileOptions(organizationId, addedIs, page, search, profileId)
      .then((response) => {
        const { next, count, added_ids } = response.data;

        setOptionsHasNextPage(!!next);
        setOptionsCurrentPage(page);
        setCount(count);

        if (page > 1) {
          setOptions((lastItems) => [
            ...lastItems,
            ...response.data.results.map((item: MultiSelectAPIItem) => ({
              key: item.id,
              value: item.name,
              checked:
                added_ids.includes(item.id) ||
                (!added_ids.includes(item.id) && allIsChecked),
              disabled: added_ids.includes(item.id),
            })),
          ]);
          return;
        }

        setOptions(
          response.data.results.map((item: MultiSelectAPIItem) => ({
            key: item.id,
            value: item.name,
            checked:
              added_ids.includes(item.id) ||
              (!added_ids.includes(item.id) && added_ids.length === count),
            disabled: added_ids.includes(item.id),
          })),
        );

        setSelectedList(added_ids);
        setAddedList(added_ids);
        setAllIsChecked(added_ids.length === count);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while loading data'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setOptionsLoading(false);
        setLoadMoreOptionsLoading(false);
      });
  };

  const handleOnCheck = (key: string) => {
    const isIncluded = selectedList.includes(key);

    setOptions((lastOptions) =>
      lastOptions.map((option) => {
        if (option.key === key) {
          option.checked = !isIncluded;
        }
        return option;
      }),
    );

    const newSelectedList = isIncluded
      ? selectedList.filter((item) => item !== key)
      : [...selectedList, key];

    setAllIsChecked(newSelectedList.length === count);
    setSelectedList(newSelectedList);
  };

  const handleOnAll = (search: string) => {
    setAllIsChecked(!allIsChecked);

    if (allIsChecked) {
      setSelectedList(addedList);
      setOptions((lastOptions) =>
        lastOptions.map((option) => {
          if (!addedList.includes(option.key)) {
            option.checked = false;
          }
          return option;
        }),
      );
      return;
    }

    setOptionsLoading(true);
    getSelectedProfileIds(organizationId, 'all', search, profileId)
      .then((response) => {
        setSelectedList(response.data);
        setOptions((lastOptions) =>
          lastOptions.map((option) => {
            if (!option.checked) {
              option.checked = true;
            }
            return option;
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while selecting items'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setOptionsLoading(false);
      });
  };

  const handleAdd = () => {
    setIsOpen(false);
    setSelectedItemsLoading(true);
    setSelectedItems([]);

    const currentSet = new Set([...selectedList, ...selectedProfiles]);
    const itemsToAdded = Array.from(currentSet);

    setSelectedProfiles(itemsToAdded);
    loadAddedProfiles(1, itemsToAdded);
    setSearch('');
  };

  const loadAddedProfiles = useCallback(
    (page: number, ids: string[]) => {
      getSelectedProfiles(organizationId, ids.toString(), page, profileId)
        .then((response) => {
          setSelectedItemsHasNextPage(!!response.data.next);
          setSelectedItemsCurrentPage(page);
          if (page > 1) {
            setSelectedItems((lastItems) => [
              ...lastItems,
              ...response.data.results,
            ]);
            return;
          }
          setSelectedItems(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setSelectedItemsLoading(false);
        });
    },
    [dispatch, t, organizationId, profileId],
  );

  const handleRemove = (id: string) => {
    setSelectedProfiles(selectedProfiles.filter((item) => item !== id));
  };

  const handleRemoveAll = () => {
    setIsOpen(false);
    setSelectedProfiles([]);
  };

  useEffect(() => {
    loadAddedProfiles(1, selectedProfiles);
  }, [loadAddedProfiles, selectedProfiles]);

  useEffect(() => {
    setAddedList(selectedProfiles);
    setSelectedList(selectedProfiles);
  }, [selectedProfiles]);

  return (
    <StyledProfileSelect>
      <Card color="gray-color" noBorder="true">
        <Text>
          {t('Position: {{name}}', {
            name: currentProfileName,
          })}
        </Text>
      </Card>
      <Text as="h4" weight="bold" className="title">
        {title}
      </Text>
      <Text className="description">{description}</Text>

      <div className="profiles-select">
        <MultiSelect
          items={options}
          setItems={(newItems) => setOptions(newItems)}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          hasNextPage={optionsHasNextPage}
          loading={optionsLoading}
          setLoading={(value) => setOptionsLoading(value)}
          loadMoreLoading={loadMoreOptionsLoading}
          setLoadMoreLoading={(value) => setLoadMoreOptionsLoading(value)}
          page={optionsCurrentPage}
          fetchItems={(page, search) =>
            loadProfileOptions(page, search, selectedProfiles)
          }
          allIsChecked={allIsChecked}
          onAll={(search) => handleOnAll(search)}
          onCheck={(id) => handleOnCheck(id)}
          onSelect={handleAdd}
          onCancel={() => {
            // setSelectedIds(selectedProfiles);
          }}
          search={search}
          setSearch={setSearch}
          placeholder={type === 'managers' ? t('Led by') : t('Leader of')}
          selectButton={
            <Button
              leftIcon="add"
              theme="primary-flat"
              rounded="true"
              onClick={handleAdd}
              disabled={optionsLoading || options.length === 0}
              id="add-button"
            >
              {t('Add')}
            </Button>
          }
          count={count - addedList.length}
          onOutsideClick={(event) => {
            if (
              event.relatedTarget &&
              event.relatedTarget.id === 'add-button'
            ) {
              handleAdd();
              return;
            }
          }}
        />
      </div>

      {selectedItems.length > 0 && (
        <div className="remove-all-wrapper">
          <Button
            theme="link-gray-primary"
            size="small"
            onClick={handleRemoveAll}
          >
            {t('Remove all')}
          </Button>
        </div>
      )}

      <div className="selected-list-wrapper default-scroll">
        <div className="selected-list">
          {selectedItems.map((item, index) => (
            <Tag radius="6px" padding="8px 16px" key={index}>
              <Text weight="700" as="p">
                {item.name}
              </Text>
              <Button
                size="small"
                theme="link-gray-primary"
                onClick={() => handleRemove(item.id)}
              >
                <Icon name="close" />
              </Button>
            </Tag>
          ))}
        </div>

        {selectedItemsLoading && <Loading type="bubbles" />}

        {!selectedItemsLoading && selectedItems.length === 0 && (
          <EmptyMessage title={t('No position selected')} />
        )}

        {!selectedItemsLoading && selectedItemsHasNextPage && (
          <Button
            leftIcon="add"
            theme="link-primary"
            className="view-more-button"
            onClick={() => {
              setSelectedItemsLoading(true);
              loadAddedProfiles(selectedItemsCurrentPage + 1, selectedProfiles);
            }}
          >
            {t('Load more positions')}
          </Button>
        )}
      </div>
    </StyledProfileSelect>
  );
};

export default ProfileSelect;
