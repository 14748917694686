import React from 'react';

import SkeletonLoading from 'components/atom/SkeletonLoading';

import { StyledCommentLoading } from './styles';

interface CommentLoadingProps {
  className?: string;
}

const CommentLoading: React.FC<CommentLoadingProps> = ({ className }) => {
  return (
    <StyledCommentLoading className={className}>
      <SkeletonLoading
        type="custom"
        customStyle={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          minWidth: '32px',
        }}
      />
      <div className="lines">
        <SkeletonLoading type="short-line" />
        <SkeletonLoading type="line" />
        <SkeletonLoading type="short-line" />
      </div>
    </StyledCommentLoading>
  );
};

export default CommentLoading;
