import styled from 'styled-components';

export const StyledContractForm = styled.div`
  width: 100%;

  h3 {
    margin-right: 12px;
  }

  section {
    margin-bottom: 24px;

    .section-contents {
      max-width: 800px;

      .section-content {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;
        margin-bottom: 16px;

        h6 {
          margin-bottom: 8px;
        }

        .section-group {
          align-items: center;
        }
      }
    }
  }

  .content-config-container {
    width: 100%;
    height: calc(100% - 144px);
    padding: 0px 58px;

    .content-side {
      width: 100%;
      padding: 32px 0px;

      .content-header {
        display: flex;
        min-height: 44px;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          align-items: center;

          .button:first-child {
            padding-left: 0px;
          }
        }
      }

      .content {
        width: 100%;
        overflow-y: visible !important;
        margin-top: 24px;
      }
    }
  }
`;
