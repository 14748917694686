import styled from 'styled-components';

export const StyledChoiceOptions = styled.div`
  .choice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0px;

    .button {
      padding-right: 0px;
      i:last-child {
        display: none;
      }
      .button-content {
        justify-content: flex-start;
      }
    }
  }

  .no-options-message {
    padding: 16px 16px 32px 16px;
    display: flex;
    justify-content: center;
  }
`;
