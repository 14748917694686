import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Avatar from 'components/molecule/Avatar';
import Icon from 'components/atom/Icon';
import Button from 'components/molecule/Button';
import FreeTrialButtonWrapper from 'components/molecule/FreeTrialButtonWrapper';

import { ContentTypeItemProps } from './types';

import { StyledNewContentModal } from './styles';

const ContentTypeItem: React.FC<ContentTypeItemProps> = ({
  avatarIcon,
  avatarTheme,
  title,
  description,
  onClick,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      theme="link-page-button-gray-primary"
      size="small"
      className="content-type-item"
      radius="0px"
      disabled={disabled}
    >
      <div className="info-side">
        <Avatar theme={avatarTheme} size="52px" icon={avatarIcon} />
        <div className="texts">
          <Text as="h6">{title}</Text>
          <Text color="grayscale-200" className="description">
            {description}
          </Text>
        </div>
      </div>
      <div className="actions-side">
        <Icon name="arrow-right-s-line" color="grayscale-200" />
      </div>
    </Button>
  );
};

const NewContentModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isFreeTrail = useAppSelector((state) => state.organization.is_free);

  const handleClick = (to: string) => {
    dispatch(closeModal());
    navigate(to);
  };

  return (
    <StyledNewContentModal className="new-content-modal">
      <Text as="h4" weight="700">
        {t('Select content type')}
      </Text>

      <div className="content-type-list">
        <ContentTypeItem
          avatarIcon="chat-4-fill"
          avatarTheme="tertiary-1"
          title={t('Publication')}
          description={t(
            'Create short texts, URLs and articles with images and links.',
          )}
          onClick={() => handleClick('/mural/content/post/article')}
        />
        <ContentTypeItem
          avatarIcon="idea"
          avatarTheme="secondary"
          title={t('Quick searches')}
          description={t(
            'Collect information from contributors through text and images.',
          )}
          onClick={() => handleClick('/mural/content/survey/poll')}
        />
        <FreeTrialButtonWrapper isFreeTrial={isFreeTrail} className="w-full">
          <ContentTypeItem
            avatarIcon="map-pin-2-fill"
            avatarTheme="tertiary-3"
            title={t('Check-in')}
            description={t('Ask employees to be present at chosen locations.')}
            onClick={() => handleClick('/mural/content/checkin/checkin')}
          />
        </FreeTrialButtonWrapper>
        <FreeTrialButtonWrapper isFreeTrial={isFreeTrail} className="w-full">
          <ContentTypeItem
            avatarIcon="share-fill"
            avatarTheme="support-pastel-2"
            title={t('Social network tasks')}
            description={t(
              'Request engagement in actions of different social networks.',
            )}
            onClick={() => handleClick('/mural/content/task')}
            disabled={!isFreeTrail}
          />
        </FreeTrialButtonWrapper>
      </div>
    </StyledNewContentModal>
  );
};

export default NewContentModal;
