import React from 'react';

import { StyledDotPaginator } from './styles';

interface DotPaginatorProps {
  pageAmount: number;
  activeIndex: number;
}

const DotPaginator: React.FC<DotPaginatorProps> = ({
  activeIndex = 0,
  pageAmount,
}) => {
  const renderDots = () => {
    const dots = [];
    for (let i = 0; i < pageAmount; i++) {
      dots.push(
        <li key={i} className={i === activeIndex ? 'active' : ''}></li>,
      );
    }
    return dots;
  };

  return (
    <StyledDotPaginator className="dot-paginator">
      {renderDots()}
    </StyledDotPaginator>
  );
};

export default DotPaginator;
