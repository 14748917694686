import React from 'react';

import Loading from 'components/molecule/Loading';

import { StyledPageLoading } from './styles';
import { cn } from 'utils/cn';

interface PageLoadingProps {
  className?: string;
}

const PageLoading: React.FC<PageLoadingProps> = ({ className }) => {
  return (
    <StyledPageLoading className={cn('page-loading', className)}>
      <Loading type="bubbles" />
    </StyledPageLoading>
  );
};

export default PageLoading;
