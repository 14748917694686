import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setContent } from 'store/slices/content';
import {
  setHasCustomContent,
  setContentTitle,
  setContentTitleSuggestion,
} from 'store/slices/contentResults';

import { getBoardContentSurvey } from 'apis/board';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import Image from 'components/molecule/Image';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import Text from 'components/atom/Text';
import SkeletonLoading from 'components/atom/SkeletonLoading';
import Card from 'components/atom/Card';
import LikertScaleView from 'components/organism/LikertScaleView';

import { BoardContentSurveyData } from 'apis/board/types';

import { StyledSurveyDetails } from './styles';

const SurveyDetails: React.FC = () => {
  const { type, subtype, contentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [data, setData] = useState<BoardContentSurveyData>();
  const [loading, setLoading] = useState(true);

  const showFinishAtLabel = () => {
    if (data && data.finish_at) {
      return t('to {{finish_at}} at {{time_finish_at}}', {
        finish_at: getDateFromApiDate(data.finish_at),
        time_finish_at: getTimeFromApiDate(data.finish_at),
      });
    }

    return '';
  };

  const isLikert = () => {
    return (
      data && data.nps_scale && data.nps_first_value && data.nps_last_value
    );
  };

  useEffect(() => {
    if (contentId) {
      getBoardContentSurvey(organizationId, contentId, true)
        .then((response) => {
          setData(response.data);

          const {
            has_dashboard,
            can_comment,
            content_id,
            has_content_custom_field,
            title,
            title_suggestion,
          } = response.data;

          dispatch(
            setContent({
              has_dashboard,
              can_comment,
              id: content_id,
            }),
          );
          dispatch(setHasCustomContent(has_content_custom_field));
          dispatch(setContentTitle(title));
          dispatch(setContentTitleSuggestion(title_suggestion));
        })
        .catch(() => {
          navigate('/error-404');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contentId, navigate, organizationId, dispatch]);

  return (
    <StyledSurveyDetails className="details">
      {loading && (
        <div className="loadings">
          <SkeletonLoading
            type="custom"
            customStyle={{ width: '100%', height: '300px' }}
          />
          <SkeletonLoading type="line" />
          <SkeletonLoading type="text" />
          <SkeletonLoading type="paragraph" />
        </div>
      )}

      {!loading && data && (
        <div className="details-wrapper">
          <div className="cover">
            <div className="actions">
              <div className="edit-button-wrapper">
                {['active'].includes(data.status) && (
                  <Button
                    rounded="true"
                    size="small"
                    theme="gray-primary"
                    to={`/mural/content/${type}/${subtype}/${contentId}`}
                  >
                    <Icon name="pencil-fill" />
                  </Button>
                )}
              </div>
              <div className="counters">
                {data.gamification.participation_points !== 0 && (
                  <Tag theme="gray-100">
                    {t('{{points}} points', {
                      points: data.gamification.participation_points,
                      count: data.gamification.participation_points,
                    })}
                  </Tag>
                )}
                {data.tags && data.tags.length > 0 && (
                  <Tag theme="gray-100">
                    {t('{{tags}} tag', {
                      tags: data.tags.length,
                      count: data.tags.length,
                    })}
                  </Tag>
                )}
              </div>
            </div>
            {data.cover && <Image src={data.cover.path} />}
          </div>
          <div className="content-elements">
            {data.start_at && (
              <Text as="pre" color="grayscale-200">
                {t('{{start_at}} at {{time_start_at}}', {
                  start_at: getDateFromApiDate(data.start_at),
                  time_start_at: getTimeFromApiDate(data.start_at),
                })}
                <span> </span>
                {showFinishAtLabel()}
              </Text>
            )}
            <Text as="h4" weight="700" className="title">
              {data.title}
            </Text>
            {data.description && (
              <Text className="description">{data.description}</Text>
            )}

            {data.type === 'single' && (
              <Text className="poll-type" as="pre" color="grayscale-200">
                {t('Single choice')}
              </Text>
            )}

            {data.type === 'multi' && (
              <Text className="poll-type" as="pre" color="grayscale-200">
                {t('Multi choice')}
              </Text>
            )}

            {data.options && data.options.length > 0 && (
              <div className="options">
                {data.options.map((option, index) => (
                  <Card key={index}>
                    <Text>{option.text}</Text>
                    <div className="check-wrapper">
                      {option.is_correct && (
                        <Icon
                          name="checkbox-circle-fill"
                          color="success-color"
                        />
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            )}

            {isLikert() && (
              <LikertScaleView
                initialScale={data.initial_scale || undefined}
                scaleSize={data.nps_scale || 5}
                minValue={data.nps_first_value || null}
                maxValue={data.nps_last_value || null}
                minValueLabel={data.nps_first_label || t('Disagree')}
                maxValueLabel={data.nps_last_label || t('Agree')}
              />
            )}

            {data.type === 'image' && (
              <Card noBorder="true" color="gray-color">
                <Icon name="image-fill" color="grayscale-200" />
                <Text color="grayscale-200">
                  {t(
                    'Users will be able to send .jpeg, .jpg, .png files up to 5MB.',
                  )}
                </Text>
              </Card>
            )}

            {data.type === 'message' && (
              <Card noBorder="true" color="gray-color">
                <Icon name="text-expand" color="grayscale-200" />
                <Text color="grayscale-200">
                  {t(
                    'Users will be able to send texts with up to 1.000 characters.',
                  )}
                </Text>
              </Card>
            )}
          </div>
        </div>
      )}
    </StyledSurveyDetails>
  );
};

export default SurveyDetails;
