import { DocIconSectionType } from './types';

export const docIconSection: DocIconSectionType[] = [
  {
    label: 'By category',
    subsections: [
      {
        label: 'Brand',
        iconNames: ['logo'],
      },
      {
        label: 'Toggle',
        iconNames: [
          'checkbox-blank-circle-line',
          'checkbox-blank-line',
          'checkbox-fill',
          'checkbox-indeterminate-line',
          'checkbox-line',
          'radio-button-line',
          'toggle-fill',
          'toggle-line',
        ],
      },
      {
        label: 'Arrow',
        iconNames: [
          'arrow-down-line',
          'arrow-down-s-line',
          'arrow-left-line',
          'arrow-left-right-line',
          'arrow-left-s-line',
          'arrow-right-line',
          'arrow-right-s-line',
          'arrow-top-line',
          'arrow-up-line',
          'arrow-up-s-line',
          'code-s-line',
          'code-s-slash-line',
          'drop',
          'drop-double',
          'left-drop',
          'left-solid-arrow',
          'top-solid-arrow',
        ],
      },
      {
        label: 'Social media',
        iconNames: [
          'facebook-circle-fill',
          'facebook-circle-line',
          'instagram-fill',
          'instagram-line',
          'linkedin-fill',
          'linkedin-line',
          'twitter-fill',
          'twitter-line',
          'whatsapp-fill',
          'whatsapp-line',
          'youtube-fill',
          'youtube-line',
        ],
      },
      {
        label: 'Reactions',
        iconNames: [
          'emotion-fill',
          'emotion-happy-fill',
          'emotion-happy-line',
          'emotion-laugh-fill',
          'emotion-laugh-line',
          'emotion-line',
          'emotion-normal-fill',
          'emotion-normal-line',
          'emotion-sad-fill',
          'emotion-sad-line',
          'emotion-unhappy-fill',
          'emotion-unhappy-line',
          'heart-fill',
          'heart-line',
          'thumb-up-fill',
          'thumb-up-line',
        ],
      },
      {
        label: 'Circle',
        iconNames: [
          'add-circle-fill',
          'add-circle-line',
          'checkbox-circle-fill',
          'checkbox-circle-line',
          'close-circle-fill',
          'close-circle-line',
          'error-warning-fill',
          'error-warning-line',
          'indeterminate-circle-fill',
          'indeterminate-circle-line',
          'information-fill',
          'information-line',
          'question-fill',
          'question-line',
        ],
      },
      {
        label: 'Evaluation and reward',
        iconNames: [
          'shield-fill',
          'shield-line',
          'star-fill',
          'star-half-line',
          'star-line',
        ],
      },
      {
        label: 'Chat',
        iconNames: [
          'chat-4-fill',
          'chat-4-line',
          'message-2-fill',
          'message-2-line',
          'question-answer-fill',
          'question-answer-line',
          'chat-pool',
        ],
      },
    ],
  },
  {
    label: 'General Remix Icon',
    iconNames: [
      'action',
      'add',
      'add-outline',
      'add-solid',
      'alert',
      'alert-fill',
      'at',
      'book-open-fill',
      'book-open-line',
      'box-play',
      'build-1',
      'build-2',
      'build-3',
      'bullets',
      'calendar-2-fill',
      'calendar-2-line',
      'camera',
      'card',
      'chat-dot',
      'chat-pool',
      'check',
      'check-double',
      'circle',
      'clock-outline',
      'clock-solid',
      'close',
      'coin-outline',
      'coin-solid',
      'collage',
      'copy',
      'copy-outline',
      'crop-cut',
      'crop-outline',
      'dashboard-fill',
      'dashboard-line',
      'delete-bin-6-fill',
      'delete-bin-6-line',
      'doc',
      'doc-file',
      'doc-fill',
      'double-coin',
      'download',
      'download-1',
      'drag',
      'drag2',
      'earth-fill',
      'earth-line',
      'edit-fill',
      'edit-line',
      'equalizer-fill',
      'equalizer-line',
      'excel',
      'external-link',
      'eye-fill',
      'eye-line',
      'eye-off-fill',
      'eye-off-line',
      'file',
      'file-edit-fill',
      'file-edit-line',
      'file-strange',
      'filter-order',
      'flag',
      'flag-2-fill',
      'flag-2-line',
      'flag-outline',
      'flag-wind',
      'focus',
      'folder-fill',
      'folder-line',
      'form',
      'fullscreen',
      'gift',
      'git-merge-fill',
      'git-merge-line',
      'grid',
      'group-fill',
      'group-line',
      'guide-fill',
      'guide-line',
      'hastag',
      'history',
      'home-6-fill',
      'home-6-line',
      'hospital',
      'idea',
      'image',
      'image-fill',
      'image-line',
      'label',
      'level',
      'link',
      'list',
      'loader',
      'lock-fill',
      'lock-fill-2',
      'lock-line',
      'lock-line-2',
      'lock-line-2-1',
      'lock-open-2',
      'logout',
      'mail-add-line-1',
      'mail-fill',
      'mail-line',
      'mail-unread-line',
      'map-pin-2-fill',
      'map-pin-2-line',
      'mastercard',
      'menu',
      'menu-2',
      'mic-fill',
      'mic-line',
      'money-dollar-circle-fill',
      'money-dollar-circle-line',
      'more',
      'more-2',
      'news',
      'notification-3-fill-1',
      'notification-3-line-1',
      'out-system',
      'palette-fill',
      'palette-line',
      'pause',
      'pdf',
      'pencil-fill',
      'pencil-line',
      'people',
      'phone-outline',
      'phone-solid',
      'pin-place',
      'pin-side',
      'pin-ver',
      'plant',
      'plant-line',
      'play',
      'play_circle',
      'play-outline',
      'qr-code',
      'qr-code-fill',
      'question-mark',
      'radio-button-line-1',
      'route-fill',
      'route-fill-1',
      'route-line',
      'search',
      'search-fill',
      'send',
      'settings-4-fill',
      'settings-4-line',
      'share-fill',
      'share-line',
      'shop-bag',
      'shufle',
      'shut-down-fill',
      'shut-down-line',
      'sip',
      'smile',
      'sound',
      'spam-2-fill',
      'spam-2-fill-1',
      'spam-3-fill',
      'spam-3-fill-1',
      'stack-fill',
      'stack-line',
      'store',
      'table',
      'text-expand',
      'thumb-down-fill',
      'thumb-down-line',
      'ticket-outline',
      'ticket-solid',
      'time-fill',
      'time-line',
      'title-major',
      'title-minor',
      'tools-fill',
      'troph-outline',
      'troph-solid',
      'unsplash',
      'upload',
      'upload2',
      'uric-coins',
      'upload-cloud',
      'user-fill',
      'user-line',
      'u-cross',
      'video-fill',
      'video-line',
      'slide',
      'visa',
      'vote-pool',
      'zoom-in-map',
      'zoom-out-map',
      'sort-right',
      'shield-person',
    ],
  },
  {
    label: 'Google Icon',
    iconNames: [
      'educator',
      'forward-10sec',
      'less',
      'replay-10sec',
      'replay',
      'skip-next',
      'skip-previous',
      'unfold-less',
      'unfold-more',
    ],
  },
];
