import { getAccountByToken } from 'apis/drive';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { setAccountAccessToken } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import { DriveOrganizationState } from './types';
import { setTheme } from 'store/slices/theme';

const useDrive = () => {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const organizationId = searchParams.get('orgId');
  const language = searchParams.get('lang');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setAccountAccessToken(accessToken || '');
    if (language) {
      i18n.changeLanguage(language);
    }

    if (accessToken && organizationId) {
      getAccountByToken(accessToken)
        .then((response) => {
          const { organization_is_active_set: organizations, client } =
            response.data;

          if (organizations && organizations.length > 0) {
            const selectedOrganization = organizations.find(
              (organization: DriveOrganizationState) =>
                parseInt(organization.id || '') === parseInt(organizationId),
            );

            if (selectedOrganization.color_scheme) {
              dispatch(setTheme({ ...selectedOrganization.color_scheme }));
            }

            if (!selectedOrganization.color_scheme && client.color_scheme) {
              dispatch(setTheme({ ...client.color_scheme }));
            }
          }

          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [accessToken, dispatch, organizationId, language, i18n]);

  return {
    hasToken: !!accessToken,
    t,
    organizationId,
    isLoading,
    isError: isError || !accessToken || !organizationId,
  };
};

export default useDrive;
