import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import MultiRange from 'components/atom/MultiRange';
import Loading from 'components/molecule/Loading';

import { StyledLikertScaleView } from './styles';

interface LikertScaleViewProps {
  scaleSize: number;
  minValue?: number | null;
  maxValue?: number | null;
  minValueLabel: string;
  maxValueLabel: string;
  initialScale?: number;
}

const LikertScaleView: React.FC<LikertScaleViewProps> = ({
  scaleSize,
  minValue,
  maxValue,
  minValueLabel,
  maxValueLabel,
  initialScale = 1,
}) => {
  const { t } = useTranslation();

  const rangeWidthDifference = useMemo(
    () => 100 / (scaleSize - scaleSize / 2),
    [scaleSize],
  );
  const stepValue = useMemo(() => 100 / (scaleSize - 2), [scaleSize]);

  const [scaleItems, setScaleItems] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const getStepValue = () => {
    if (!scaleSize) {
      return 0;
    }
    return 100 / (scaleSize - 2);
  };

  const getMinValue = () => {
    if (!minValue) {
      return 0;
    }
    return getStepValue() * (minValue - 1);
  };

  const getMaxValue = () => {
    if (!maxValue || !scaleSize) {
      return 100;
    }
    return maxValue === scaleSize ? 100 : getStepValue() * (maxValue - 2);
  };

  useEffect(() => {
    const items: number[] = [];
    for (let i = 0; i < scaleSize; i += 1) {
      items.push(i + initialScale);
    }
    setScaleItems(() => items);

    // force render range
    setLoading(() => true);
    setTimeout(() => {
      setLoading(() => false);
    }, 400);
  }, [scaleSize, initialScale]);

  return (
    <StyledLikertScaleView>
      {!loading && (
        <>
          <div className="nps-labels">
            <Text color="danger-color" weight="700">
              {t('Negative')}
            </Text>
            <Text color="grayscale-200" weight="700">
              {t('Neutral')}
            </Text>
            <Text color="success-color" weight="700">
              {t('Positive')}
            </Text>
          </div>
          <div className="range-scale">
            <div className="multi-range-scale">
              <div
                className="range"
                style={{
                  width: `calc(100% - ${rangeWidthDifference}%)`,
                }}
              >
                <MultiRange
                  type="large"
                  barLeftColor="var(--danger-color)"
                  barRightColor="var(--success-color)"
                  barInnerColor="var(--gray-color)"
                  thumbLeftColor="var(--white-color)"
                  thumbRightColor="var(--white-color)"
                  min={0}
                  max={100}
                  step={stepValue}
                  minValue={getMinValue()}
                  maxValue={getMaxValue()}
                  stepOnly
                />
              </div>
            </div>
            <div className="scale">
              {scaleItems.map((item, index) => (
                <div className="scale-item" key={index}>
                  <Text as="h6" weight="700">
                    {item}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="nps-fields">
            <div className="nps-field">
              <Text>1 - </Text>
              <Text>{minValueLabel}</Text>
            </div>
            <div className="nps-field">
              <Text>{scaleSize} - </Text>
              <Text>{maxValueLabel}</Text>
            </div>
          </div>
        </>
      )}
      {loading && (
        <div className="loading">
          <Loading />
        </div>
      )}
    </StyledLikertScaleView>
  );
};

export default LikertScaleView;
