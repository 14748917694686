import apiV1 from 'apis/apiV1';
import QueryString from 'qs';

import {
  DriveFileParams,
  DriveListParams,
  DriveSegmentationSendProps,
  UpdateDriveFile,
} from './types';

const basePathname = '/drive/org';

export const getDriveFiles = (
  organizationId: string,
  params: DriveListParams,
  parentId: string,
) => {
  return apiV1.get(`${basePathname}/${organizationId}/files/${parentId}`, {
    params: {
      page: params.page,
      search: params.search,
      ordering: params.ordering,
      is_folder: params.is_folder,
      exclude_file_id: params.exclude_file_id,
    },
    paramsSerializer: {
      serialize: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
};

export const createDriveFile = (
  organizationId: string,
  params: DriveFileParams,
) => {
  return apiV1.post(`${basePathname}/${organizationId}/files/create`, params);
};

export const updateDriveFile = (
  organizationId: string,
  fileId: string,
  params: UpdateDriveFile,
) => {
  return apiV1.patch(
    `${basePathname}/${organizationId}/files/${fileId}/update`,
    params,
  );
};

export const getRecentDriveFiles = (organizationId: string) => {
  return apiV1.get(`${basePathname}/${organizationId}/files/`, {
    params: {
      recent: true,
    },
    paramsSerializer: {
      serialize: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
};

export const deleteDriveFiles = (organizationId: string, fileId: string) =>
  apiV1.delete(`${basePathname}/${organizationId}/files/${fileId}/file`);

export const moveDriveFiles = (
  organizationId: string,
  fileId: string,
  toPath: string | null,
) => {
  return apiV1.put(`${basePathname}/${organizationId}/files/${fileId}/move`, {
    to_path: toPath,
  });
};

export const getDriveSegmentationOptions = (
  organizationId: string,
  contentId?: string,
) =>
  apiV1.get(
    `${basePathname}/${organizationId}/segmentation/options/${
      contentId ? `?exclude_content_id=${contentId}` : ''
    }`,
  );

export const getDriveSegmentation = (
  organizationId: string,
  segmentationId: string,
) =>
  apiV1.get(
    `${basePathname}/${organizationId}/segmentation/${segmentationId}/entry/`,
  );

export const shareDriveFile = (
  organizationId: string,
  fileId: string,
  data?: DriveSegmentationSendProps,
) => {
  return apiV1.put(
    `${basePathname}/${organizationId}/segmentation/${fileId}/file/`,
    data,
  );
};
