import React from 'react';
import { useCollaboratorProfile } from './hook';

import { cn } from 'utils/cn';

import Text from 'components/atom/Text';
import Select from 'components/molecule/Select';
import Avatar from 'components/molecule/Avatar';
import PageLoading from 'components/molecule/PageLoading';

import { SingleSelectedOption } from 'components/molecule/Select/types';

import Tag from 'components/atom/Tag';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';

import { CollaboratorProfileProps } from './types';

import { getDateFromApiDate } from 'utils/date';

import { Container, StyledCollaboratorProfile } from './styles';

const CollaboratorProfile: React.FC<CollaboratorProfileProps> = ({
  id,
  onSave,
}) => {
  const {
    t,
    loading,
    options,
    positions,
    departments,
    handleCancel,
    currentProfile,
    selectedProfile,
    handleSaveProfile,
    handleChangeProfile,
  } = useCollaboratorProfile({ id, onSave });

  return (
    <StyledCollaboratorProfile className="collaborator-profile">
      <Container>
        <Text as="h4" weight="700">
          {t('Collaborator profile')}
        </Text>
        <div
          className={cn(
            'collaborator-header mt-10 flex gap-[120px] items-center justify-between',
          )}
        >
          <div className="flex items-center gap-5">
            <div
              className={cn(
                'collaborator-avatar w-[120px] min-w-[120px] h-[120px] p-2 rounded-[24px]',
              )}
            >
              <Avatar
                src={currentProfile.avatar}
                imgClassName="!rounded-[20px] object-cover"
                size="100%"
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <Text as="h6" weight="700">
                {currentProfile.name}
              </Text>
              <Text as="small" weight="500" color="grayscale-200">
                {t('Member since {{date_joined}}', {
                  date_joined: getDateFromApiDate(currentProfile.date_joined),
                })}
              </Text>
            </div>
          </div>
          <Select
            options={options}
            value={selectedProfile}
            setValue={(option) =>
              handleChangeProfile(option as SingleSelectedOption)
            }
            size="normal"
            theme="default"
            className="w-full max-w-[172px]"
          />
        </div>
      </Container>

      <Separator />

      <Container>
        <Text weight="500" as="h6">
          {t('Positions')}
        </Text>
        <div className="flex gap-3 flex-wrap mt-5">
          {positions.length > 0 && !loading ? (
            positions.map((position, index) => (
              <Tag
                key={index}
                radius="6px"
                padding="8px 16px"
                theme="default"
                className="max-w-full"
              >
                <Text color="grayscale-400" weight="700">
                  {position}
                </Text>
              </Tag>
            ))
          ) : (
            <Text
              className="w-full"
              align="center"
              color="grayscale-200"
              weight="700"
            >
              {t('No positions available')}
            </Text>
          )}
        </div>
      </Container>

      <Separator />

      <Container>
        <Text weight="500" as="h6">
          {t('Departments')}
        </Text>
        <div className="flex gap-3 flex-wrap mt-5">
          {departments.length > 0 && !loading ? (
            departments.map((department, index) => (
              <Tag
                key={index}
                radius="6px"
                padding="8px 16px"
                theme="default"
                className="max-w-full"
              >
                <Text color="grayscale-400" weight="700">
                  {department}
                </Text>
              </Tag>
            ))
          ) : (
            <Text
              className="w-full"
              align="center"
              color="grayscale-200"
              weight="700"
            >
              {t('No departments available')}
            </Text>
          )}
        </div>
      </Container>

      <Separator />

      <Container>
        <div className="flex justify-between pt-5 pb-5">
          <Button theme="link-primary" onClick={handleCancel}>
            {t('Cancel')}
          </Button>

          <Button theme="primary" rounded="true" onClick={handleSaveProfile}>
            {t('Save')}
          </Button>
        </div>
      </Container>

      {loading && <PageLoading />}
    </StyledCollaboratorProfile>
  );
};

export default CollaboratorProfile;
