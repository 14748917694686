import styled from 'styled-components';

interface StyledSelectProps {
  size: string;
}

export const StyledSelect = styled.div<StyledSelectProps>`
  .react-select {
    &.has-error {
      .react-select__control {
        border-color: var(--danger-color);
      }
    }

    .react-select__control {
      border-radius: 8px;
      border: 1px solid var(--grayscale-100);
      padding: 0px 4px 0px 12px;
      min-height: ${({ size }) => (size === 'normal' ? '44px' : '30px')};
      min-width: 44px;
      display: flex;
      align-items: center;
      background: var(--white-color);
      cursor: pointer;
      font-size: 18px;

      &:hover {
        border-color: var(--dark-color);
      }

      &.react-select__control--is-focused {
        box-shadow: none;
        border-color: var(--grayscale-200);
      }

      .react-select__placeholder {
        color: var(--grayscale-200);
      }

      .react-select__value-container {
        padding: 0px;
        margin: 0px;

        .react-select__single-value {
          margin: 0px;
          padding: 0px;
          margin-top: 2px;
        }

        .react-select__input-container {
          margin: 0px;
          padding: 0px;
        }

        .react-select__multi-value {
          border-radius: 100px;
          padding: 4px 8px;
          background: var(--gray-color);

          .react-select__multi-value__label {
            padding: 0px;
            margin-top: 2px;
            font-size: 16px;

            .button {
              height: max-content;
              min-width: auto;
              padding: 0px;
            }
          }

          .react-select__multi-value__remove {
            padding-right: 0px;
            &:hover {
              background: none;
              color: var(--dark-color);
            }

            .close-circle-fill {
              font-size: 14px;
            }
          }
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }

        .react-select__indicator {
          padding: 4px;
          .icon {
            color: var(--grayscale-200);
          }
        }
      }
    }

    .react-select__menu {
      border-radius: 8px;
      box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.05);

      .react-select__menu-list {
        padding: 6px 0px;

        &::-webkit-scrollbar-track {
          background: none;
          border-radius: 4px;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background: #0000;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--grayscale-200);
          border-radius: 4px;
        }

        .react-select__option {
          padding: 0px;
          cursor: pointer;
          font-size: 18px;

          &.react-select__option--is-focused {
            background: var(--gray-color);
          }

          &.react-select__option--is-selected {
            background: var(--grayscale-100);
            color: var(--dark-color);
          }

          & > .button {
            width: 100%;
            justify-content: start;
            .button-content {
              text-align: start;
            }
          }
        }
      }
    }

    .react-select--is-disabled {
      .react-select__control {
        background: var(--gray-color);
        cursor: initial;

        .react-select__value-container {
          .react-select__single-value {
            color: var(--grayscale-200);
          }
        }
      }

      .react-select__menu {
        .react-select__menu-list {
          .react-select__option {
            cursor: initial;
          }
        }
      }
    }
  }

  .select-error-message {
    margin-top: 6px;
    padding-left: 2px;
    white-space: pre-line;
  }
`;
