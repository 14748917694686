import { createSlice } from '@reduxjs/toolkit';

import ContentState from './types';

const initialState: ContentState = {
  id: '',
  has_dashboard: false,
  can_comment: false,
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setHasDashboard: (state, action) => {
      state.has_dashboard = action.payload;
    },
    setCanComment: (state, action) => {
      state.can_comment = action.payload;
    },
    setContentId: (state, action) => {
      state.id = action.payload;
    },
    setContent: (state, action) => {
      const { id, can_comment, has_dashboard } = action.payload;
      state.id = id;
      state.can_comment = can_comment;
      state.has_dashboard = has_dashboard;
    },
    clearContent: (state) => {
      state.id = '';
      state.has_dashboard = false;
      state.can_comment = false;
    },
  },
});

export const {
  setHasDashboard,
  setCanComment,
  setContentId,
  setContent,
  clearContent,
} = contentSlice.actions;

export default contentSlice.reducer;
