import styled from 'styled-components';

export const StyledManagementSections = styled.div`
  .management-content {
    padding: 52px 54px;

    .management-section {
      margin-bottom: 64px;

      .header {
        margin-bottom: 32px;
      }

      .cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
`;
