import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { StyledColorSelector } from './styles';

interface ColorSelectorProps {
  color: string;
  setColor: Dispatch<SetStateAction<string>>;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ color, setColor }) => {
  const { t } = useTranslation();
  const {
    primary_color_200,
    secondary_color_200,
    tertiary_color_1,
    support_pastel_color_2,
    tertiary_color_3,
    tertiary_color_2,
  } = useAppSelector((state) => state.theme);

  const [currentColor, setCurrentColor] = useState(color);
  const [currentColorTimeOut, setCurrentColorTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const handleChangeColor = (newColor: string) => {
    if (currentColorTimeOut) {
      clearTimeout(currentColorTimeOut);
    }

    const timeOut = setTimeout(() => {
      setColor(newColor);
    }, 400);

    setCurrentColorTimeOut(timeOut);
    setCurrentColor(newColor);
  };

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  return (
    <StyledColorSelector className="color-selector" color={color}>
      <Text as="h6">{t('Choose color')}</Text>
      <div className="color-options">
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === primary_color_200 ? 'selected' : ''}
          onClick={() => setColor(primary_color_200)}
        >
          <span style={{ background: primary_color_200 }}></span>
        </Button>
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === secondary_color_200 ? 'selected' : ''}
          onClick={() => setColor(secondary_color_200)}
        >
          <span style={{ background: secondary_color_200 }}></span>
        </Button>
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === tertiary_color_1 ? 'selected' : ''}
          onClick={() => setColor(tertiary_color_1)}
        >
          <span style={{ background: tertiary_color_1 }}></span>
        </Button>
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === support_pastel_color_2 ? 'selected' : ''}
          onClick={() => setColor(support_pastel_color_2)}
        >
          <span style={{ background: support_pastel_color_2 }}></span>
        </Button>
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === tertiary_color_3 ? 'selected' : ''}
          onClick={() => setColor(tertiary_color_3)}
        >
          <span style={{ background: tertiary_color_3 }}></span>
        </Button>
        <Button
          size="small"
          theme="dark-shadow"
          rounded="true"
          className={currentColor === tertiary_color_2 ? 'selected' : ''}
          onClick={() => setColor(tertiary_color_2)}
        >
          <span style={{ background: tertiary_color_2 }}></span>
        </Button>
        <div className="custom">
          <label htmlFor="color-button">
            <div className="custom-color">
              <Icon name="add" color="primary-color" />
            </div>
          </label>
          <input
            type="color"
            id="color-button"
            value={currentColor}
            onChange={(event) => handleChangeColor(event.target.value)}
          />
        </div>
      </div>
    </StyledColorSelector>
  );
};

export default ColorSelector;
