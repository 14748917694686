import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Avatar from 'components/molecule/Avatar';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Confirm from 'components/molecule/Confirm';
import Tag from 'components/atom/Tag';

import { StyledComment } from './styles';

interface CommentProps {
  id: string;
  name: string;
  avatar?: string | null;
  text: string;
  createdAt: string;
  removedAt: string | null;
  removedBy: string | null;
  totalComments: number;
  parentRemoved?: boolean;
  openToReply?: boolean;
  removing: boolean;
  hideSeeComments?: boolean;
  onRemove: (id: string) => void;
  onSeeComments: (id: string) => void;
}

const Comment: React.FC<CommentProps> = ({
  id,
  name,
  avatar,
  text,
  createdAt,
  removedAt,
  removedBy,
  totalComments,
  parentRemoved,
  openToReply,
  removing,
  hideSeeComments,
  onRemove,
  onSeeComments,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleRemove = () => {
    dispatch(
      setModalView({
        show: true,
        width: '400px',
        content: (
          <Confirm
            title={t('Remove comment')}
            subtitle={t(
              'By removing this comment, any replies will also be removed. Do you wish to continue?',
            )}
            onConfirm={() => onRemove(id)}
          />
        ),
      }),
    );
  };

  const getCommentLabel = () => {
    if (totalComments > 0) {
      return t('More ({{total_comments}})', {
        total_comments: totalComments,
      });
    }
    return t('More');
  };

  return (
    <StyledComment>
      <Avatar size="32px" icon="user-fill" src={avatar || undefined} />
      <div className="comment-content">
        <Text as="pre" weight="700" className="owner-name">
          {name}
        </Text>
        <Text className={`${removedAt ? 'removed' : ''}`} as="pre">
          {text}
        </Text>
        <div className="dates">
          <Text as="small" color="grayscale-200" className="created-at">
            {t('{{created_at}} at {{time_created_at}}', {
              created_at: getDateFromApiDate(createdAt),
              time_created_at: getTimeFromApiDate(createdAt),
            })}
          </Text>
          {removedAt && removedBy && (
            <Text as="small" color="grayscale-200" className="removed-at">
              {t(
                'Comment removed on {{removed_at}} at {{time_removed_at}} by {{author}}',
                {
                  removed_at: getDateFromApiDate(removedAt),
                  time_removed_at: getTimeFromApiDate(removedAt),
                  author: removedBy,
                },
              )}
            </Text>
          )}
          {removedAt && !removedBy && (
            <Text as="small" color="grayscale-200" className="removed-at">
              {t(
                'Comment removed on {{removed_at}} at {{time_removed_at}} by Denylist',
                {
                  removed_at: getDateFromApiDate(removedAt),
                  time_removed_at: getTimeFromApiDate(removedAt),
                  author: removedBy,
                },
              )}
            </Text>
          )}
          {parentRemoved && (
            <Tag theme="danger-100" padding="4px 6px">
              {t('Parent removed')}
            </Tag>
          )}
        </div>
        <div className="actions">
          {!openToReply && !hideSeeComments && (
            <Button
              theme="link-primary"
              size="small"
              onClick={() => onSeeComments(id)}
              disabled={removing}
            >
              {getCommentLabel()}
            </Button>
          )}
          <Button
            theme="link-danger"
            size="small"
            onClick={handleRemove}
            disabled={!!removedAt || removing}
          >
            {!removing ? t('Remove') : t('Removing...')}
          </Button>
        </div>
      </div>
      <div className="comment-info"></div>
    </StyledComment>
  );
};

export default Comment;
