import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import TagGrouper from 'components/molecule/TagGrouper';
import Tooltip from 'components/atom/Tooltip';

import { ContractConfigItem, ContractFilter } from './types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
  SearchableValue,
} from 'components/organism/Table/types';

import { StyledContractsTable } from './styles';
import { getFinancialContracts } from 'apis/staff';
import dayjs from 'dayjs';
import { defaultDateFormat } from 'settings';
import { useNavigate } from 'react-router-dom';

const ContractsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<ContractFilter>({
    search: '',
    client: '',
    organizations: '',
    contract_type: '',
    start_at: '',
    finish_at: '',
    order: '',
  });

  const getTableRows = useCallback(
    (data: ContractConfigItem[]) => {
      return data.map((item, index) => {
        return {
          onClick: () => navigate(`/staff/finance/contract/${item.id}`),
          cells: [
            {
              content: (
                <Tooltip
                  id={`client-${item.id}-${index}`}
                  content={item.client.value}
                >
                  <Text color="grayscale-400">{item.client.value}</Text>
                </Tooltip>
              ),
            },
            {
              content:
                item.organizations && item.organizations.length > 0 ? (
                  <TagGrouper
                    tags={item.organizations.map((value) => value.value)}
                    total={item.organizations.length}
                    displayLimit={2}
                    characterLimit={20}
                  />
                ) : (
                  '-'
                ),
              clickable: false,
            },
            {
              content: (
                <Text color="grayscale-400">{item.contract_type.value}</Text>
              ),
            },
            {
              content: (
                <Text color="grayscale-400">
                  {dayjs(item.start_at).format(defaultDateFormat)}
                </Text>
              ),
            },
            {
              content: (
                <Text color="grayscale-400">
                  {item.finish_at
                    ? dayjs(item.finish_at).format(defaultDateFormat)
                    : ''}
                </Text>
              ),
            },
          ],
        };
      });
    },
    [navigate],
  );

  const loadContracts = useCallback(
    (page: number, filter: ContractFilter) => {
      setTableLoading(true);

      getFinancialContracts(page, filter)
        .then((response) => {
          const { num_pages, results } = response.data;
          setPageCount(num_pages);
          setCurrentPage(page);
          setTableRows(() => getTableRows(results));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }

          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, t, getTableRows],
  );

  const handleSearch = (values: SearchableValue[]) => {
    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        client: values.find((value) => value.key === 'client')?.value || '',
        organizations:
          values.find((value) => value.key === 'organizations')?.value || '',
        contract_type:
          values.find((value) => value.key === 'contract_type')?.value || '',
      };
      loadContracts(1, newFilter);
      return newFilter;
    });
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    const { value, key } = sortableValue;
    let newOrder = '';

    if (value === 1) {
      newOrder = key;
    }

    if (value === 0) {
      newOrder = '';
    }

    if (value === -1) {
      newOrder = `-${key}`;
    }

    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        order: newOrder,
      };
      loadContracts(1, newFilter);
      return newFilter;
    });
  };

  const handleChangePage = (newPage: number) => {
    loadContracts(newPage, filter);
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Client')}</Text>,
      sortable: {
        key: 'client',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
      searchable: {
        key: 'client',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by client'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Organizations')}</Text>,
      searchable: {
        key: 'organizations',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by organization'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Contract type')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Start date')}</Text>,
      sortable: {
        key: 'start_at',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
    },
    {
      content: <Text color="grayscale-200">{t('End date')}</Text>,
      sortable: {
        key: 'finish_at',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
    },
  ]);

  useEffect(() => {
    loadContracts(1, {
      search: '',
      client: '',
      organizations: '',
      contract_type: '',
      start_at: '',
      finish_at: '',
      order: '',
    });
  }, [loadContracts]);

  return (
    <StyledContractsTable className="contracts-table">
      <Table
        clickable={true}
        columns={columns}
        setColumns={setColumns}
        rows={tableRows}
        textAlign="start"
        fontColor="grayscale-200"
        hasPagination
        pageCount={pageCount}
        page={currentPage - 1}
        onChangePage={(page) => handleChangePage(page + 1)}
        loading={tableLoading}
        emptyMessageDescription={t(
          'If there are already contracts registered, change the words in the search bar and try again',
        )}
        scrollable
      />
    </StyledContractsTable>
  );
};

export default ContractsTable;
