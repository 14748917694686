import styled from 'styled-components';

interface StyledDatePickerProps {
  rounded: boolean;
}

export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  width: 100%;
  background: var(--white-color);

  .input-label {
    margin-bottom: 6px;
    font-weight: 500;
  }

  .input-content {
    border: 1px solid var(--grayscale-100);
    border-radius: ${({ rounded }) => (rounded ? '100px' : '8px')};
    padding: 6px 14px;

    display: flex;
    align-items: center;

    & > .icon {
      color: var(--grayscale-200);
      margin-right: 6px;
      font-weight: 500;
    }

    input {
      flex: 1;
      border: none;
      font-size: 18px;
      outline: none;
      padding-top: 2px;
      color: var(--dark-color);
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      font-weight: 500;
      width: 100%;

      ::-webkit-input-placeholder {
        /* Edge */
        color: var(--grayscale-200);
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--grayscale-200);
      }

      ::placeholder {
        color: var(--grayscale-200);
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    &:focus-within {
      border-color: var(--primary-color);
    }

    &.has-error {
      border-color: var(--danger-color);
    }

    &.disabled {
      background: var(--gray-color);
      border-color: var(--gray-color);
    }

    &.disabled:focus-within {
      border-color: var(--gray-color);
    }

    .datepicker-popper {
      padding: 16px 0px;

      .react-datepicker {
        border: none;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        border-radius: 14px;

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__navigation {
          top: 14px;
          .react-datepicker__navigation-icon::before {
            border-width: 2px 2px 0 0;
            width: 6px;
            height: 6px;
            color: var(--grayscale-200);
          }
        }

        .react-datepicker__month-container {
          .react-datepicker__header {
            background: var(--white-color);
            border-radius: 14px 14px 0px 0px;
            padding-top: 16px;
            border: none;

            .react-datepicker__current-month {
              font-size: 18px;
              font-weight: 500;
              color: var(--grayscale-300);
            }

            .react-datepicker__day-name {
              color: var(--grayscale-200);
              text-transform: uppercase;
              font-size: 12px;
            }
          }

          .react-datepicker__month {
            .react-datepicker__day:not(.react-datepicker__day--disabled) {
              color: var(--grayscale-400);
            }

            .react-datepicker__day {
              font-size: 16px;
              border-radius: 4px;
              outline: none;

              &.today {
                font-weight: bold !important;
              }

              &.react-datepicker__day--today {
                font-weight: 500;
              }

              &.react-datepicker__day--keyboard-selected {
                background: none;
              }

              &.react-datepicker__day--selected {
                color: var(--white-color);
                background: var(--primary-color);
                :hover {
                  background: var(--primary-color-300);
                }
              }

              &.react-datepicker__day--disabled {
                color: var(--grayscale-100);
              }
            }
          }
        }
      }
    }
  }

  .input-limit {
    width: 100%;
    text-align: end;
    margin-top: 6px;
  }

  .input-error-message {
    margin-top: 6px;
    white-space: pre-line;
  }
`;
