import styled from 'styled-components';

export const StyledContentComments = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .back-button {
    margin-bottom: 24px;
    margin-left: 16px;
  }

  .comment-loading {
    padding-left: 32px;
  }

  .subcomment-loading {
    padding-left: 64px;
  }

  .comments-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .comment-list {
      width: 100%;
    }

    .subcomments {
      width: 100%;
      padding-left: 40px;
    }

    .empty-message {
      margin-top: 200px;

      h6 {
        color: var(--grayscale-200);
        font-size: 24px;
      }
    }
  }
`;
