import styled from 'styled-components';

export const StyledStaffHeaderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-left: 28px;
  }

  .account-menu {
    margin-left: 36px;
  }
`;
