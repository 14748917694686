import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FilterItem from '../FilterItem';

import { CustomFilterAttrsTypes } from '../../types';

import { StyledSizeFilter } from './styles';
import Input from 'components/molecule/Input';
import Select from 'components/molecule/Select';
import Text from 'components/atom/Text';
import { SelectOption } from 'components/molecule/Select/types';

export interface SizeFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const options: readonly SelectOption[] = [
  {
    value: 'KB',
    label: (
      <div className="size-option p-2">
        <Text color="grayscale-200">KB</Text>
      </div>
    ),
  },
  {
    value: 'MB',
    label: (
      <div className="size-option p-2">
        <Text color="grayscale-200">MB</Text>
      </div>
    ),
  },
  {
    value: 'GB',
    label: (
      <div className="size-option p-2">
        <Text color="grayscale-200">GB</Text>
      </div>
    ),
  },
];

const SizeFilter: React.FC<SizeFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(!!filter.size_number);
  const [selectedOrderOfMagnitude, setSelectedOrderOfMagnitude] =
    useState<SelectOption>(options[0]);

  useEffect(() => {
    setSelectedOrderOfMagnitude(
      options.find(
        (option) => option.value === filter.size_order_of_magnitude,
      ) || options[0],
    );
  }, [filter.size_order_of_magnitude]);

  const handleClear = () => {
    const newFilter = {
      ...filter,
      size_number: null,
      size_order_of_magnitude: 'KB',
    };
    setFilter(newFilter);
    setSelectedOrderOfMagnitude(options[0]);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleChangeSizeNumber = (value: string) => {
    setFilter({
      ...filter,
      size_number: value,
    });
  };

  const handleChangeOrderOfMagnitude = (option: SelectOption) => {
    setFilter({
      ...filter,
      size_order_of_magnitude: option.value,
    });
    setSelectedOrderOfMagnitude(option);
  };

  return (
    <StyledSizeFilter>
      <FilterItem
        title={t('File size')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={
          isOpen &&
          (!!filter.size_number ||
            (!!filter.size_order_of_magnitude &&
              filter.size_order_of_magnitude !== 'KB'))
        }
        onClear={handleClear}
        showCheckAllButton={false}
      >
        <div className="datepicker-wrapper flex-wrap gap-2">
          <Text className="min-w-max mr-2" as="h6">
            {t('Up to')}
          </Text>
          <div className="flex">
            <Input
              type="number"
              value={filter.size_number?.toString()}
              onChange={(event) => handleChangeSizeNumber(event.target.value)}
              placeholder={t('Enter a number')}
              className="max-w-[250px]"
            />
            <Select
              options={options}
              value={selectedOrderOfMagnitude}
              setValue={(option) =>
                handleChangeOrderOfMagnitude(option as SelectOption)
              }
              size="normal"
              className="min-w-max ml-4"
            />
          </div>
        </div>
      </FilterItem>
    </StyledSizeFilter>
  );
};

export default SizeFilter;
