import dayjs from 'dayjs';

import { schedulerInitialState } from './components/PublicationDateModal/components/RecurrenceConfig/utils';

import { SchedulerData } from 'apis/survey/types';

import { SurveyContentFields, SurveyContentGeneralFields } from './types';

export const contentStateFactory = (
  contentFields: SurveyContentFields,
  generalStates: SurveyContentGeneralFields,
) => {
  const {
    cover,
    finish_at,
    finish_at_time,
    start_at,
    start_at_time,
    title,
    description,
    scheduler_start_date_at,
    scheduler_finish_date_at,
    content_start_time_at,
    content_finish_time_at,
    content_duration,
    content_week_days,
    scheduler_var,
    scheduler_type,
  } = contentFields;

  const {
    notifications,
    segmentations,
    status,
    type,
    with_recurrence,
    anonymous,
    shuffle,
    instructions,
  } = generalStates;

  switch (type) {
    case 'general': {
      return {
        cover,
        title,
        description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        segmentations,
        notifications,
        with_recurrence,
        anonymous,
        shuffle,
        instructions,
      };
    }
    default:
      return {
        cover,
        title,
        description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        segmentations,
        notifications,
        with_recurrence,
        anonymous,
        shuffle,
        instructions,
      };
  }
};

export const parseSchedulerDataToStateFormat = (
  schedulerData: SchedulerData | null,
) => {
  if (!schedulerData) {
    return { ...schedulerInitialState };
  }

  return {
    scheduler_start_date_at: {
      ...schedulerInitialState.scheduler_start_date_at,
      value: dayjs(schedulerData.scheduler_start_date_at).toDate(),
    },
    scheduler_finish_date_at: {
      ...schedulerInitialState.scheduler_finish_date_at,
      value: schedulerData.scheduler_finish_date_at
        ? dayjs(schedulerData.scheduler_finish_date_at).toDate()
        : undefined,
    },
    content_start_time_at: {
      ...schedulerInitialState.content_start_time_at,
      value: schedulerData.content_start_time_at,
    },
    content_finish_time_at: {
      ...schedulerInitialState.content_finish_time_at,
      value: schedulerData.content_finish_time_at,
    },
    content_duration: {
      ...schedulerInitialState.content_duration,
      value: schedulerData.content_duration.toString(),
    },
    content_week_days: {
      ...schedulerInitialState.content_week_days,
      value: schedulerData.content_week_days,
    },
    scheduler_var: {
      ...schedulerInitialState.scheduler_var,
      value: schedulerData.scheduler_var,
    },
    scheduler_type: {
      ...schedulerInitialState.scheduler_type,
      value: schedulerData.scheduler_type,
    },
  };
};
