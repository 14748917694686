import axios, { AxiosRequestConfig } from 'axios';

import { getAccountAccessToken } from 'services/auth';
import { getI18NextLanguage } from 'services/language';

import { ApiVersions, getBaseAPIUrl } from '../settings';

const instance = axios.create({
  baseURL: getBaseAPIUrl(ApiVersions.v1),
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const accessToken = getAccountAccessToken();

  if (!accessToken || !config.headers) {
    return config;
  }

  config.headers['Accept-Language'] = getI18NextLanguage();

  return config;
});

export default instance;
