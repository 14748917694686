import styled from 'styled-components';

import { StyledTagGrouperProps } from './types';

const getCharLimit = (total?: number) => {
  if (total) {
    return `${total}ch`;
  }
  return '100%';
};

export const StyledTagGrouper = styled.div<StyledTagGrouperProps>`
  display: flex;
  align-items: center;

  .tag {
    margin-right: 4px;
    max-width: ${({ characterLimit }) => getCharLimit(characterLimit)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
