import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { createSurveyCustomField } from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setHasCustomContent } from 'store/slices/contentResults';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import TabMenu from 'components/molecule/TabMenu';
import Button from 'components/molecule/Button';
import Tooltip from 'components/atom/Tooltip';
import Loading from 'components/molecule/Loading';
import CreateSegmentModal from './components/CreateSegmentModal';

import { Tab } from 'components/molecule/TabMenu/types';

import { StyledContentDetailsHeader } from './styles';
import { setModalView } from 'store/slices/modal';

interface ContentDetailsHeaderProps {
  tabMenuOptions: Tab[];
  onChangeTab: (key: string) => void;
}

const ContentDetailsHeader: React.FC<ContentDetailsHeaderProps> = ({
  tabMenuOptions,
  onChangeTab,
}) => {
  const { t } = useTranslation();
  const { subtype } = useParams();
  const hasContentCustomField = useAppSelector(
    (state) => state.contentResults.has_custom_content,
  );
  const organizationId = useAppSelector((state) => state.organization.pk);
  // const title = useAppSelector((store) => store.contentResults.content_title);
  const titleSuggestion = useAppSelector(
    (store) => store.contentResults.title_suggestion,
  );
  const contentId = useAppSelector((store) => store.content.id);
  const dispatch = useAppDispatch();

  const [createSegmentLoading, setCreateSegmentLoading] = useState(false);

  const subtypeLabels = {
    short: t('Announcement'),
    article: t('Article'),
    url: t('URL'),
    poll: t('Poll'),
    nps: t('Likert'),
    image: t('Image upload'),
    message: t('Text sending'),
    checkin: t('Check-in'),
    drive: t('Drive'),
  };

  const getSubtypeLabel = () => {
    if (subtype) {
      return subtypeLabels[subtype as keyof typeof subtypeLabels];
    }
    return t('Content details');
  };

  const handleCreateSegment = (value: string) => {
    setCreateSegmentLoading(true);

    createSurveyCustomField(organizationId, contentId, value)
      .then(() => {
        dispatch(setHasCustomContent(true));
        dispatch(
          showMessage({
            title: t('Segment created'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          showMessage({
            title: error.response.data.errors,
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setCreateSegmentLoading(false);
      });
  };

  const showCreateSegmentModal = () => {
    dispatch(
      setModalView({
        show: true,
        content: (
          <CreateSegmentModal
            onConfirm={handleCreateSegment}
            title={titleSuggestion}
          />
        ),
        width: '565px',
      }),
    );
  };

  return (
    <StyledContentDetailsHeader>
      <Text as="h6" weight="700">
        {getSubtypeLabel()}
      </Text>
      <TabMenu tabs={tabMenuOptions} onChangeTab={(tab) => onChangeTab(tab)} />
      <Tooltip
        id="can-segment-tooltip"
        content={t('This custom field was already created')}
        disabled={
          hasContentCustomField === null ||
          !hasContentCustomField ||
          createSegmentLoading
        }
      >
        {!createSegmentLoading && (
          <Button
            theme="link-primary"
            id="create-segment-button"
            size="small"
            disabled={!!hasContentCustomField}
            className={hasContentCustomField === null ? 'hide' : ''}
            onClick={showCreateSegmentModal}
          >
            {t('Create segment')}
          </Button>
        )}
        {createSegmentLoading && <Loading type="bubbles" />}
      </Tooltip>
    </StyledContentDetailsHeader>
  );
};

export default ContentDetailsHeader;
