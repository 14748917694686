import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditorType, EditorEvent, Events } from 'tinymce';

import Text from 'components/atom/Text';

import {
  getBlockFormats,
  getDefaultEditorCSSRules,
  getFontFamilyFormats,
  getMediaPluginSettings,
  getPluginOptions,
  getQuickbarInsertOptions,
  getQuickbarSelectionOptions,
  getToolbarOptions,
} from './settings';
import { StyledEditor } from './styles';
import {
  AvailableCharacterCountTypes,
  BlobInfo,
  EditorUploadedFileResponse,
} from './types';
import { getTinyMCEi18nStrings } from './utils';

interface EditorProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  toolbar?: boolean;
  pluginsOptions?: string[];
  toolbarOptions?: string[];
  quickbarsSelOptions?: string[];
  quickbarsInsOptions?: string;
  label?: string;
  focusedLabel?: string;
  hideLabel?: boolean;
  placeholder?: string;
  placeholderAsLabel?: boolean;
  limit?: number;
  hideCharacterCount?: boolean;
  characterCountType?: keyof typeof AvailableCharacterCountTypes;
  resizable?: boolean;
  value?: string;
  hasError?: boolean;
  errorMessage?: string;
  height?: number;
  onUpload?: (
    selectedFile: File,
    editorProgressCallback: (percent: number) => void,
  ) => Promise<EditorUploadedFileResponse>;
  onChange: (value: string) => void;
}

const Editor: React.FC<EditorProps> = ({
  id,
  className = '',
  disabled = false,
  toolbar = false,
  pluginsOptions = null,
  toolbarOptions = null,
  quickbarsSelOptions = null,
  quickbarsInsOptions = null,
  label,
  focusedLabel,
  hideLabel = false,
  placeholder = '',
  placeholderAsLabel = false,
  limit,
  hideCharacterCount = false,
  characterCountType = 'unfocused',
  resizable = true,
  hasError = false,
  errorMessage,
  value = '',
  height = 500,
  onChange,
  onUpload,
}) => {
  const { t } = useTranslation();
  const editorRef = useRef<TinyMCEEditorType | null>(null);

  const [count, setCount] = useState(0);
  const [showCounter, setShowCounter] = useState(
    characterCountType === 'unfocused',
  );

  // every time TinyMCE editor content has been changed
  const handleUpdate = (value: string, editor: TinyMCEEditorType) => {
    const length = editor.getContent({ format: 'text' }).length;
    setCount(length);

    if (limit === undefined || (limit && length <= limit)) {
      onChange(value);
      return;
    }
  };

  // every time before add any character to content
  const handleBeforeAddUndo = (
    event: EditorEvent<Events.EditorEventMap['BeforeAddUndo']>,
    editor: TinyMCEEditorType,
  ) => {
    if (limit) {
      const length = editor.getContent({ format: 'text' }).length;
      if (length > limit) event.preventDefault();
    }
  };

  // every time before paste any content to editor
  const handleOnPaste = (
    event: EditorEvent<Events.EditorEventMap['paste']>,
  ) => {
    const textContent: string | undefined =
      event.clipboardData?.getData('text');

    // block paste content if it exceeds limit
    if (limit && textContent && textContent.length > limit) {
      event.preventDefault();
    }
  };

  // when editor should upload a image to server
  const handleImagesUpload = (
    blobInfo: BlobInfo,
    progress: (percent: number) => void,
  ) =>
    new Promise(
      (
        resolve: (uploadedImageUrl: string) => void,
        reject: ({ errorMessage }: { errorMessage: string }) => void,
      ) => {
        const file: File = new File([blobInfo.blob()], blobInfo.filename());

        if (onUpload) {
          onUpload(file, progress)
            .then((res: EditorUploadedFileResponse) => {
              return resolve(
                `${res.baseUrl}/org/${res.organizationId}/upload/${res.uuid}/`,
              );
            })
            .catch((err: Error) => {
              return reject({ errorMessage: err.message });
            });
        }
      },
    );

  const renderLabel = () => {
    const labelText: string =
      label || focusedLabel || (placeholderAsLabel ? placeholder : '');
    const shouldHideLabel: boolean =
      hideLabel || (focusedLabel && !value) || (placeholderAsLabel && !value);

    if (!labelText) return '';

    return (
      <label htmlFor={id} onClick={() => editorRef?.current?.focus()}>
        <Text
          as="pre"
          color="grayscale-200"
          className={`editor-label ${shouldHideLabel ? 'hide' : ''}`}
        >
          {labelText}
        </Text>
      </label>
    );
  };

  return (
    <StyledEditor
      className={`editor ${className} ${hasError ? 'has-error' : ''}`}
      toolbar={toolbar}
    >
      <div className="editor-content">
        {renderLabel()}

        <TinyMCEEditor
          id={id}
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          disabled={disabled}
          value={value}
          onEditorChange={handleUpdate}
          onBeforeAddUndo={handleBeforeAddUndo}
          onPaste={handleOnPaste}
          onInit={(event, editor) => (editorRef.current = editor)}
          onFocus={() => setShowCounter(true)}
          onBlur={() => setShowCounter(characterCountType === 'unfocused')}
          init={{
            height,
            menubar: false,
            branding: false,
            statusbar: toolbar,
            resize: resizable,
            placeholder: placeholder || t('Enter a text here'),

            plugins: pluginsOptions || getPluginOptions(),
            toolbar: toolbarOptions || getToolbarOptions(toolbar),
            quickbars_selection_toolbar:
              quickbarsSelOptions || getQuickbarSelectionOptions(toolbar),
            quickbars_insert_toolbar:
              quickbarsInsOptions || getQuickbarInsertOptions(),
            content_style: getDefaultEditorCSSRules(),
            font_family_formats: getFontFamilyFormats(),
            block_formats: getBlockFormats(),
            ...getMediaPluginSettings(),

            // Disables context menu (right click on editor)
            contextmenu_never_use_native: true,
            contextmenu: false,

            // Keeps only images being able to be resized in editor
            // This fixes issues with media embedding
            object_resizing: 'img',

            // Fixes error with images being floated without a parent container
            // see: https://stackoverflow.com/a/71315732
            formats: {
              alignleft: {
                selector: 'p',
                styles: {
                  'text-align': 'left',
                },
              },
              aligncenter: {
                selector: 'p',
                styles: {
                  'text-align': 'center',
                },
              },
              alignright: {
                selector: 'p',
                styles: {
                  'text-align': 'right',
                },
              },
            },

            // Fixes possible issues with foreign objects or images
            paste_as_text: true,
            paste_block_drop: true,
            paste_merge_formats: true,
            paste_tab_spaces: 4,
            smart_paste: false,
            paste_data_images: false,

            // Handles image upload
            automatic_uploads: true,
            block_unsupported_drop: true,
            images_upload_handler: (blobInfo, success) =>
              handleImagesUpload(blobInfo, success),

            setup: (editor) => {
              // Inject i18n references (strings) to current TinyMCE
              // Is better than store string pairs (key-trans) on .js's
              // It also has an advantage to set EN-US as fallback language
              editor.editorManager.addI18n('en', getTinyMCEi18nStrings());

              // Hides media plugin (insert/edit media) options [tabs]
              // We hide it here because there's no other option to disable it
              // See: https://github.com/tinymce/tinymce/issues/8225
              // See: https://github.com/tinymce/tinymce/issues/6082
              editor.on('ExecCommand', (event): void => {
                if (event?.command === 'mceMedia') {
                  /* eslint-disable quotes */
                  const tabsElement = document.querySelector<HTMLElement>(
                    "div[role='tablist']",
                  );
                  if (tabsElement) tabsElement.style.display = 'none';
                }
              });
            },

            init_instance_callback(editor) {
              // Set up character counter after loading everything, on init
              const length = editor.getContent({ format: 'text' }).length;
              setCount(length);

              // Tighten editor with label, setting up ARIA labels
              document
                ?.querySelector('[for=' + editor.id + ']')
                ?.setAttribute('id', editor.id + '_label');
              document
                ?.querySelector('#' + editor.id + ' + .tox-tinymce')
                ?.setAttribute('aria-labelledby', editor.id + '_label');
            },
          }}
        />

        {limit && !hideCharacterCount && showCounter && (
          <div className="input-limit">
            <Text as="pre" color="grayscale-200" className="input-limit">
              <span>{count}</span>/<span>{limit}</span>
            </Text>
          </div>
        )}
        {errorMessage && (
          <Text as="pre" color="danger-color" className="editor-error-message">
            {errorMessage}
          </Text>
        )}
      </div>
    </StyledEditor>
  );
};

export default Editor;
