import apiV2 from 'apis/apiV2';
import apiV1 from 'apis/apiV1';
import { QueryParams } from 'apis/types';

const apiVersions = {
  v1: apiV1,
  v2: apiV2,
};

export const getSelectGeneralOptions = (
  url: string,
  page: number,
  search: string,
  apiVersion: string,
) =>
  apiVersions[apiVersion as keyof typeof apiVersions].get(
    `${url}?page=${page}&search=${search}`,
  );

export const loadBreadcrumbItems = (
  url: string,
  page: number,
  fetchParams?: QueryParams,
) =>
  apiV1.get(`${url}?page=${page}`, {
    params: fetchParams,
  });

export const getCountries = () => apiV1.get('/countries/');
