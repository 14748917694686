import { createGlobalStyle } from 'styled-components';

import ButtonThemes from './button-themes';
import InputThemes from './input-themes';
import SelectThemes from './select-themes';
import TagThemes from './tag-themes';
import TextareaThemes from './textarea-themes';
import AlertThemes from './alert-themes';
import AvatarThemes from './avatar-themes';
import DatePickerThemes from './datepicker-themes';

interface DefaultTheme {
  primary_color_100: string;
  primary_color_200: string;
  primary_color_300: string;
  primary_color_400: string;
  secondary_color_100: string;
  secondary_color_200: string;
  tertiary_color_1: string;
  tertiary_color_2: string;
  tertiary_color_3: string;
  support_pastel_color_1: string;
  support_pastel_color_2: string;
}

// mapping: variable <-> html color
export const appDefaultColors = {
  'grayscale-100': '#EAEAF0',
  'grayscale-200': '#B4B6CB',
  'grayscale-300': '#5E6C88',
  'grayscale-400': '#262B53',

  'white-color': '#FFFFFF',
  'white-gray-color': '#FCFCFC',
  'gray-color': '#F6F6F6',
  'dark-color': '#01102D',

  'success-color': '#45C93A',
  'success-color-100': '#DCFED9',
  'success-color-300': '#369E2D',

  'warning-color': '#FFE70F',
  'warning-color-100': '#FFF8BC',

  'danger-color': '#E7401C',
  'danger-color-100': '#FFE6E1',
  'danger-color-300': '#C23320',
};

// mapping: variable <-> theme prop name
export const customThemeColors = {
  'primary-color': 'primary_color_200',
  'primary-color-100': 'primary_color_100',
  'primary-color-200': 'primary_color_200',
  'primary-color-300': 'primary_color_300',
  'primary-color-400': 'primary_color_400',

  'secondary-color': 'secondary_color_100',
  'secondary-color-200': 'secondary_color_200',

  'tertiary-color-1': 'tertiary_color_1',
  'tertiary-color-2': 'tertiary_color_2',
  'tertiary-color-3': 'tertiary_color_3',

  'support-pastel-color-1': 'support_pastel_color_1',
  'support-pastel-color-2': 'support_pastel_color_2',
};

// just an alias for all available colors css variables
// you should NOT rely on value of those keys (only keys),
// as it have not just static but dynamic colors inside
export const AvailableColorTypes = {
  ...appDefaultColors,
  ...customThemeColors,
};

/* eslint-disable */
const GlobalStyles = createGlobalStyle<{ theme: DefaultTheme }>`
  html {
    height: 100%;
    max-height: 100%;
    body {
      height: 100%;
      #root {
        height: 100%;
        overflow: hidden;
      }
    }

    ${(props) => {
      // custom theme colors
      return Object.entries(customThemeColors).map(
        ([cssVariable, themePropName]) => {
          const htmlColor = props.theme[themePropName as keyof DefaultTheme];
          return `--${cssVariable}: ${htmlColor};`;
        },
      );
    }}

    ${() => {
      // app default colors
      return Object.entries(appDefaultColors).map(
        ([cssVariable, htmlColor]) => `--${cssVariable}: ${htmlColor};`,
      );
    }}

    /* z-index levels */
    --z-index-level-1: 100;
    --z-index-level-2: 200;
    --z-index-level-3: 300;
    --z-index-level-4: 400;
    --z-index-level-5: 500;
    --z-index-level-6: 600;
    --z-index-level-7: 700;
    --z-index-level-8: 800;

    & * {
      font-family: 'Hind', sans-serif;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    /* Default scroll style */
    .default-scroll::-webkit-scrollbar-track {
      background: none;
      border-radius: 4px;
    }
    .default-scroll::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: #0000;
      border-radius: 4px;
    }
    .default-scroll::-webkit-scrollbar-thumb {
      background: var(--grayscale-200);
      border-radius: 4px;
    }

    /* CRISP fixes */
    .crisp-client {
      #crisp-chatbox {
        z-index: var(--z-index-level-5);
      }
    }
  }

  #flowbot-button {
    bottom: 90px !important;
    right: 30px !important;
    background: var(--primary-color);

    &.opened {
      bottom: -6px !important;
      right: -6px !important;
    }
  }

  /* TinyMCE */
  .tox .tox-notifications-container {
    z-index: 30000 !important;
    position: fixed !important;
    top: 72px !important;

    .tox-notification {
      max-width: 800px;
      width: 100%;
      padding: 20px 26px;
      border-radius: 12px;
      border: none;
      background-color: var(--danger-color);

      .tox-notification__icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          path {
            fill: var(--danger-color);
          }
        }

        background: var(--white-color);

        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        margin-right: 20px;
      }

      .tox-notification__body {
        p {
          font-family: 'Hind', sans-serif;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;

          color: var(--white-color);
          width: 100%;
          text-align: start;
          max-width: 400px;

        }
      }

      .tox-button {
        &:hover {
          background: none;
        }
        &:focus {
          background: none;
        }

        svg {
          width: 28px;
          height: 28px;

          path {
            fill: var(--white-color);
          }
        }
      }
    }
  }

  @media (max-width: 479px) {
    #flowbot-button {
      bottom: 78px !important;
      right: 16px !important;
      background: var(--primary-color);
    }
  }

  /* Component themes */
  ${ButtonThemes}
  ${InputThemes}
  ${SelectThemes}
  ${TagThemes}
  ${TextareaThemes}
  ${AlertThemes}
  ${AvatarThemes}
  ${DatePickerThemes}
`;

export default GlobalStyles;
