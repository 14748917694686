import React from 'react';
import { FileUploader as ReactFileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Tag from 'components/atom/Tag';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { FileUploaderProps } from './types';

import { StyledFileUploader } from './styles';

const FileUploader: React.FC<FileUploaderProps> = ({
  fileTypes,
  maxSize,
  disabled = false,
  onChange,
  dragOnly = false,
  onDrop,
  onDragEnd,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = (file: File) => {
    if (onChange) {
      onChange(file);
    }
  };

  const getFormatsPlaceholder = () => {
    if (!fileTypes) return '';
    return fileTypes.map((format) => ` .${format.toLowerCase()}`).toString();
  };

  const handleTypeError = () => {
    onDragEnd?.();

    dispatch(
      showMessage({
        title: t('Invalid file type'),
        theme: 'danger',
        icon: 'close',
        time: 10000,
      }),
    );
  };

  const handleOnDrop = (file: File) => {
    onDragEnd?.();
    onDrop?.(file);
  };

  const handleSizeError = () => {
    onDragEnd?.();

    dispatch(
      showMessage({
        title: t(`The file must be a maximum of ${maxSize}MB`),
        theme: 'danger',
        icon: 'close',
        time: 10000,
      }),
    );
  };

  return (
    <StyledFileUploader className="file-uploader">
      <ReactFileUploader
        handleChange={handleChange}
        name="file"
        types={
          fileTypes
            ? fileTypes.map((format) => format.replace('.', '').toUpperCase())
            : []
        }
        maxSize={maxSize}
        hoverTitle=" "
        dropMessageStyle={{
          background: 'var(--gray-color)',
          border: 'none',
          opacity: '1',
          zIndex: 'var(--z-index-level-1)',
        }}
        disabled={disabled}
        onTypeError={handleTypeError}
        onSizeError={handleSizeError}
        onDrop={handleOnDrop}
      >
        <div className="drop-area">
          <Tag radius="12px">
            <Icon name="upload-cloud" />
          </Tag>
          <Text as="h6" weight="700" className="send-your-file-label">
            {dragOnly ? t('Drop your file here') : t('Send your file')}
          </Text>
          {!dragOnly && (
            <>
              <Text className="format-and-size-label">
                {getFormatsPlaceholder()}
                {fileTypes &&
                  fileTypes.length > 0 &&
                  maxSize &&
                  maxSize > 0 &&
                  ' - '}
                {maxSize &&
                  t('Maximum file size up to {{file_size}}MB', {
                    file_size: maxSize,
                  })}
              </Text>
              <div>
                <Text color="primary-color">{t('Search on computer')}</Text>
                <Text color="grayscale-200">{t('or drag to this area')}</Text>
              </div>
            </>
          )}
        </div>
      </ReactFileUploader>
    </StyledFileUploader>
  );
};

export default FileUploader;
