import apiV2 from 'apis/apiV2';

import { EditorialData } from './types';

const basePathname = '/admin/org';

export const getEditorialIcons = (organizationId: string, page: number) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/content/config/editorial/icons?size=40`,
    {
      params: {
        page,
      },
    },
  );

export const getEditorialLines = (
  organizationId: string,
  page: number,
  search: string,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/content/editorial`, {
    params: {
      page,
      search,
    },
  });

export const createEditorialLine = (
  organizationId: string,
  data: EditorialData,
) =>
  apiV2.post(`${basePathname}/${organizationId}/board/content/editorial`, data);

export const updateEditorialLine = (
  organizationId: string,
  editorialId: string,
  data: EditorialData,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/board/content/editorial/${editorialId}`,
    data,
  );

export const getProfileConfigItems = (
  organizationId: string,
  page: number,
  filter: {
    search: string;
    manager: string;
    managed: string;
    order: string;
  },
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/`, {
    params: {
      page,
      search: filter.search,
      manager: filter.manager,
      managed: filter.managed,
      ordering: filter.order,
    },
  });

export const getProfiles = (
  organizationId: string,
  type: string,
  id: string,
  search: string,
  page: number,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/${id}/${type}/`, {
    params: {
      search,
      page,
    },
  });

export const getHierarchyTotals = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/hierarchy/total/`);

export const getColaboratorsConfigItems = (
  organizationId: string,
  page: number,
  filter: {
    search: string;
    slug: string;
    departments: string;
    profiles: string;
    order: string;
  },
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/account/`, {
    params: {
      page,
      search: filter.search,
      slug: filter.slug,
      department: filter.departments,
      profile: filter.profiles,
      ordering: filter.order,
    },
  });

export const getColaboratorProfile = (
  organizationId: string,
  collaboratorId: string,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/account/${collaboratorId}`,
  );

export const updateColaboratorProfile = (
  organizationId: string,
  collaboratorId: string,
  isAdmin: boolean,
) =>
  apiV2.put(
    `${basePathname}/${organizationId}/board/account/${collaboratorId}`,
    {
      is_organization_admin: isAdmin,
    },
  );

export const getAccountProfilesDepartments = (
  organizationId: string,
  type: string,
  id: string,
  search: string,
  page: number,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/account/${id}/${type}/`, {
    params: {
      search,
      page,
    },
  });

export const getDepartments = (
  organizationId: string,
  id: string | null,
  search: string,
  page: number,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/board/department${
      id ? `/${id}` : ''
    }/all/`,
    {
      params: {
        search,
        page,
      },
    },
  );

export const getDepartmentBreadcrumb = (
  organizationId: string,
  lastId: string,
  total: number,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/department/breadcrumb`, {
    params: {
      last_id: lastId,
      total,
    },
  });

export const getSelectedProfileIds = (
  organizationId: string,
  selecteds: string,
  search: string,
  excludes?: string,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/all`, {
    params: {
      selecteds: selecteds,
      only_selecteds: true,
      only_ids: true,
      search,
      excludes,
    },
  });

export const getSelectedProfiles = (
  organizationId: string,
  selecteds: string,
  page: number,
  excludes?: string,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/all`, {
    params: {
      selecteds: selecteds,
      page: page,
      only_selecteds: true,
      only_ids: false,
      excludes,
    },
  });

export const getProfileOptions = (
  organizationId: string,
  selecteds: string[],
  page: number,
  search: string,
  excludes?: string,
) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/all`, {
    params: {
      selecteds: selecteds.toString(),
      page: page,
      search: search,
      only_selecteds: false,
      only_ids: false,
      order_selected: false,
      excludes,
    },
  });

export const createProfile = (
  organizationId: string,
  name: string,
  managers: string,
  manageds: string,
) =>
  apiV2.post(`${basePathname}/${organizationId}/board/profile`, {
    name,
    managers,
    manageds,
  });

export const updateProfile = (
  organizationId: string,
  name: string,
  managers: string,
  manageds: string,
  id: string,
) =>
  apiV2.put(`${basePathname}/${organizationId}/board/profile/${id}/`, {
    name,
    managers,
    manageds,
  });

export const getProfile = (organizationId: string, id: string) =>
  apiV2.get(`${basePathname}/${organizationId}/board/profile/${id}/`);

export const createDepartment = (
  organizationId: string,
  name: string,
  parent?: string,
) =>
  apiV2.post(`${basePathname}/${organizationId}/board/department`, {
    name,
    parent,
  });

export const updateDepartment = (
  organizationId: string,
  name: string,
  id: string,
) =>
  apiV2.put(`${basePathname}/${organizationId}/board/department/${id}/`, {
    name,
  });
