import styled from 'styled-components';

interface StyledSecondaryToasterProps {
  customLeft: string;
  show?: string;
}

export const StyledSecondaryToaster = styled.div<StyledSecondaryToasterProps>`
  position: absolute;
  bottom: 72px;
  padding-left: ${({ customLeft }) => customLeft};
  z-index: var(--z-index-level-5);
  width: 100%;
  display: ${({ show }) => (show === 'true' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .alert {
    width: max-content;
    max-width: 700px;
    min-width: 300px;
    opacity: 0;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  .alert-close-button {
    margin-left: 10px;
    padding-right: 0px;
    justify-content: flex-end;

    .icon {
      font-size: 20px;
      font-weight: 700;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
