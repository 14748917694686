import React, { useEffect, useState } from 'react';

import Text from 'components/atom/Text';

import { StyledDashboard } from './styles';
import { getDashboards } from 'apis/staff';
import { useAppDispatch } from 'store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { setPageLoading } from 'store/slices/pageLoading';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { typeId } = useParams();
  const [title, setTitle] = useState('');
  const [dashboardUrls, setDashboardUrls] = useState([]);

  useEffect(() => {
    dispatch(setPageLoading(true));
    getDashboards(typeId || 'home')
      .then((response) => {
        setTitle(response.data.title);
        setDashboardUrls(response.data.dashboards);
      })
      .catch(() => {
        navigate('/error-404');
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  }, [navigate, dispatch, typeId]);

  return (
    <StyledDashboard>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {title}
            </Text>
          </div>
          <div className="content">
            {dashboardUrls.map((dashboardUrl, index) => (
              <iframe
                key={index}
                src={dashboardUrl}
                className="default-scroll"
                loading="eager"
              />
            ))}
          </div>
        </div>
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
