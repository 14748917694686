import styled from 'styled-components';

export const StyledPinnedContentListModal = styled.div`
  .input {
    margin-bottom: 12px;
    padding-right: 8px;
  }

  .modal-description {
    font-size: 18px;
    margin: 6px 0px 24px;
  }

  .empty-message {
    margin: 40px auto;
  }

  .list {
    width: 100%;
    max-height: 272px;
    overflow-y: scroll;

    .item {
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--grayscale-100);
      align-items: center;

      .left-wrapper {
        display: flex;
        align-items: center;

        & > .image {
          margin-left: 12px;
        }

        & > .checkbox {
        }

        & > .title-wrapper {
          margin-left: 12px;

          & > .title {
            display: -webkit-box;
            max-width: 264px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.2;
          }
          & > .description {
            margin-top: 4px;
          }
        }
      }

      .checkbox {
        top: -10px;
      }

      .creator-wrapper {
        padding-left: 8px;
        padding-right: 8px;

        & > .date {
          text-align: end;
        }

        & > .creator {
          margin-top: 4px;
          max-width: 25ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: end;
        }
      }
    }
  }

  .form-action {
    margin-top: 32px;

    display: flex;
    justify-content: space-between;
  }
`;
