import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import CoverImage from 'components/organism/CoverImage';
import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import Editor from 'components/molecule/Editor';

import { CoverProps } from 'components/organism/CoverImage/types';
import { ArticlePostContentProps } from './types';
import { PostContentFields } from '../../types';

import { FileUpload } from 'services/upload';
import { FileUploadModules } from 'services/upload/types';

import { StyledArticlePostContent } from './styles';

const ArticlePostContent: React.FC<ArticlePostContentProps> = ({
  articleContentState,
  setContentState,
}) => {
  const { t } = useTranslation();
  const fileUploader = new FileUpload();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const handleChangeText = (value: string) => {
    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      text: {
        ...updatedState.text,
        value,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };
  const handleUploadTextImage = (
    image: File,
    progress: (percent: number) => void,
  ) => {
    return fileUploader.send(
      image,
      FileUploadModules.BOARD,
      organizationId,
      progress,
    );
  };

  return (
    <StyledArticlePostContent className="article-post-content">
      <CoverImage
        coverUrl={articleContentState.cover.url}
        setCover={handleChangeCover}
        organizationId={organizationId}
        hasError={articleContentState.cover.error.hasError}
        errorMessage={articleContentState.cover.error.errorMessage}
      />

      <Input
        id="article-post-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={articleContentState.title.value?.toString() || undefined}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        onChange={handleChangeTitle}
        hasError={articleContentState.title.error.hasError}
        errorMessage={articleContentState.title.error.errorMessage}
      />
      <Textarea
        id="article-post-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Description *')}
        placeholder={`${t('Enter the description *')}`}
        value={articleContentState.description.value?.toString() || undefined}
        onChange={handleChangeDescription}
        limit={1000}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={articleContentState.description.error.hasError}
        errorMessage={articleContentState.description.error.errorMessage}
        autoResize
        removeBreakLines
      />
      <Editor
        id="article-post-text-editor"
        className="text"
        focusedLabel={t('Content *')}
        placeholder={`${t('Enter the content *')}`}
        value={articleContentState.text.value?.toString() || undefined}
        onUpload={handleUploadTextImage}
        onChange={handleChangeText}
        limit={10000}
        characterCountType="focused"
        hasError={articleContentState.text.error.hasError}
        errorMessage={articleContentState.text.error.errorMessage}
      />
    </StyledArticlePostContent>
  );
};

export default ArticlePostContent;
