import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import Confirm from 'components/molecule/Confirm';
import PageLoading from 'components/molecule/PageLoading';

import { removeContent } from 'apis/board';

import { RemoveContentProps } from './types';

import { StyledRemoveContent } from './styles';

const RemoveContent: React.FC<RemoveContentProps> = ({
  contentTypeId,
  organizationId,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleRemoveContent = () => {
    removeContent(organizationId, contentTypeId)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Content was deleted'),
            theme: 'success',
            icon: 'check',
            time: 10000,
            customLeft: '0px',
          }),
        );

        onSave();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while remove the content'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(closeModal());
      });
  };

  return (
    <StyledRemoveContent>
      {loading && <PageLoading />}
      <Confirm
        title={t('Delete content')}
        subtitle={t(
          'Are you sure you want to delete content saved as a draft? This action is irreversible.',
        )}
        confirmLabel={t('Delete')}
        onConfirm={handleRemoveContent}
      />
    </StyledRemoveContent>
  );
};

export default RemoveContent;
