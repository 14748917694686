import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import CoverImage from 'components/organism/CoverImage';
import Card from 'components/atom/Card';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { MessageSurveyContentProps } from './types';
import { CoverProps } from 'components/organism/CoverImage/types';
import { SurveyContentFields } from '../../types';

import { StyledMessageSurveyContent } from './styles';

const MessageSurveyContent: React.FC<MessageSurveyContentProps> = ({
  messageContentState,
  setContentState,
}) => {
  const { t } = useTranslation();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  return (
    <StyledMessageSurveyContent>
      <Card noBorder="true" color="gray-color">
        <Icon name="text-expand" color="grayscale-200" />
        <Text color="grayscale-200">
          {t('Users will be able to send texts with up to 1.000 characters.')}
        </Text>
      </Card>

      <CoverImage
        coverUrl={messageContentState.cover.url}
        setCover={handleChangeCover}
        organizationId={organizationId}
        hasError={messageContentState.cover.error.hasError}
        errorMessage={messageContentState.cover.error.errorMessage}
      />

      <Input
        id="message-survey-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={messageContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={messageContentState.title.error.hasError}
        errorMessage={messageContentState.title.error.errorMessage}
      />
      <Textarea
        id="message-survey-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Description')}
        placeholder={`${t('Enter the description')}`}
        value={messageContentState.description.value?.toString() || undefined}
        onChange={handleChangeDescription}
        limit={500}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={messageContentState.description.error.hasError}
        errorMessage={messageContentState.description.error.errorMessage}
        autoResize
        removeBreakLines
      />
    </StyledMessageSurveyContent>
  );
};

export default MessageSurveyContent;
