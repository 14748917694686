import styled from 'styled-components';

export const StyledRecurrenceConfig = styled.div`
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid var(--grayscale-100);

  .alert {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 4px;
  }

  .subtitle {
    margin-bottom: 24px;
  }

  .form-group {
    display: flex;
    align-items: flex-start;
    margin-bottom: 42px;

    .datepicker {
      margin-right: 10px;
    }
  }

  .duration-label-wrapper {
    position: relative;
    border-top: 1px solid var(--grayscale-100);
    padding-top: 20px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;

    h5 {
      margin-right: 6px;
      margin-top: 2px;
    }

    .icon {
      font-size: 20px;
    }

    .info {
      position: relative;
      .react-tooltip {
        width: 267px;
        position: absolute !important;
        top: auto !important;
        left: auto !important;
        bottom: 28px !important;
        right: -124px !important;
      }
    }
  }

  .screen-duration {
    max-width: calc(50% - 12px);
    margin-bottom: 32px;

    .input-content {
      input {
        width: 100%;
      }
    }
  }

  .time-form-group {
    .input {
      margin-right: 12px;
    }
  }

  .week-days-label-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    border-top: 1px solid var(--grayscale-100);
    padding-top: 20px;
    position: relative;

    pre {
      margin-right: 6px;
    }

    .info {
      position: relative;
      .react-tooltip {
        width: 267px;
        position: absolute !important;
        top: auto !important;
        left: auto !important;
        bottom: 28px !important;
        right: -124px !important;
      }
    }
  }

  .week-days {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .button {
      min-width: 50px;

      .button-content {
        text-transform: uppercase;
      }
    }
  }

  & > .input-error-message {
    margin-bottom: 20px;
  }

  .repeat-form-group {
    align-items: flex-start;

    & > * {
      width: 50%;
      margin-right: 12px;
    }

    input {
      width: 100%;
    }

    .select {
      margin-top: 20px;
      .react-select__control {
        height: 46px;
        .scheduler-type-option {
          padding: 0px;
        }
      }

      .scheduler-type-option {
        padding: 16px;
      }

      .scheduler-type-option:last-child {
        border-bottom: none;
      }
    }
  }

  .card {
    margin-bottom: 24px;
  }

  .save-button {
    margin-top: 22px;
    width: 100%;
  }
`;
