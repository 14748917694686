import styled from 'styled-components';

export const StyledSecondaryDetailsHeader = styled.div`
  width: 48px;
  height: 100%;
  background: var(--white-color);
  border-left: 1px solid var(--grayscale-100);
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    padding: 8px;
  }

  & > h6 {
    position: absolute;
    left: 40px;
  }

  .tab-menu {
    background: none;
    padding: 0px;

    flex-direction: column;

    .button {
      border-radius: 0px;
      min-height: 156px;
      width: 47px;
      min-width: 47px;
      padding: 0px;

      .button-content {
        transform: rotate(-90deg);
      }

      &:hover {
        background: none;
        & * {
          color: var(--dark-color);
        }
      }
    }

    .button {
      &.active {
        background: var(--grayscale-100);
        box-shadow: none;
        border-left: 1px solid var(--dark-color);
        cursor: pointer;
        pointer-events: all;
      }
    }
  }
`;
