import apiV2 from 'apis/apiV2';

import { ImportData, ImportGlobalTypes } from './types';

const basePathname = '/admin/org';

export const createImport = (
  organizationId: string,
  data: ImportData,
  onUploadProgress?: (progress: number) => void,
) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('import_type', data.import_type);

  return apiV2.post(
    `${basePathname}/${organizationId}/mass_import/import/`,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        if (!progressEvent.total || !onUploadProgress) return;
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        onUploadProgress(progress);
      },
    },
  );
};

export const getImport = (organizationId: string, importId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/mass_import/import/${importId}`);

export const getLastImportInProgress = (
  organizationId: string,
  type: keyof typeof ImportGlobalTypes,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/mass_import/${type}/import/last_in_progress/`,
  );

export const getImportHistory = (
  organizationId: string,
  type: keyof typeof ImportGlobalTypes,
  page: number,
) =>
  apiV2.get(
    `${basePathname}/${organizationId}/mass_import/${type}/history/?page=${page}&size=20`,
  );
