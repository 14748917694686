import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'components/atom/Tag';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { ImportItemButtonProps } from './types';
import { AvailableTagThemes } from 'components/atom/Tag/types';
import { AvailableColorTypes } from 'styles/global-styles';

import { StyledImportItemButton } from './styles';

const ImportItemButton: React.FC<ImportItemButtonProps> = ({
  date,
  uploadedBy,
  onClick,
  status,
  type,
  active,
}) => {
  const { t } = useTranslation();
  const tagStatusThemes = {
    'in-progress': 'support-pastel-2',
    success: 'success',
    error: 'danger',
  };
  const tagIconColors = {
    'in-progress': 'support-pastel-color-2',
    success: 'success-color',
    error: 'danger-color',
  };

  return (
    <StyledImportItemButton
      onClick={onClick}
      className={`import-item-button ${active ? 'active' : ''}`}
    >
      <div className="status-side">
        <Tag
          theme={tagStatusThemes[status] as keyof typeof AvailableTagThemes}
          radius="6px"
        />
        <Icon
          name="file"
          color={tagIconColors[status] as keyof typeof AvailableColorTypes}
        />
      </div>
      <div className="info-side">
        <Text weight="700" as="pre">
          {date} • {type}
        </Text>
        <Text className="file-name" color="grayscale-200">
          {t('Uploaded by {{uploadedBy}}', {
            uploadedBy: uploadedBy,
          })}
        </Text>
      </div>
    </StyledImportItemButton>
  );
};

export default ImportItemButton;
