import React from 'react';

import SkeletonLoading from 'components/atom/SkeletonLoading';

import { StyledHistoryItemLoading } from './styles';

interface HistoryItemLoadingProps {
  className?: string;
}

const HistoryItemLoading: React.FC<HistoryItemLoadingProps> = ({
  className,
}) => {
  return (
    <StyledHistoryItemLoading className={className}>
      <div className="lines">
        <SkeletonLoading type="short-line" />
        <SkeletonLoading type="line" />
        <SkeletonLoading type="short-line" />
      </div>
    </StyledHistoryItemLoading>
  );
};

export default HistoryItemLoading;
