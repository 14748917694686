import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Icon from 'components/atom/Icon';
import { Dropdown } from 'components/molecule/DropdownV2';

import { StyledFilesTableActions } from './styles';
import { downloadFile } from 'utils/download';

interface FilesTableActionsProps {
  fileLink: string;
  isShared: boolean;
  viewFile: string | null;
  fileName: string;
  isMobile: boolean;
}

const FilesTableActions: React.FC<FilesTableActionsProps> = ({
  fileLink,
  isShared,
  viewFile,
  fileName,
  isMobile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleVisualizeClick = () => {
    window.open(viewFile ? viewFile : '', '_blank');
  };

  const handleMobileDownloadClick = () => {
    window.open(viewFile ? viewFile + '?download=true' : '', '_blank');
  };

  const handleDownloadClick = async () => {
    const fileUrl = viewFile || '';

    dispatch(
      showMessage({
        title: t('File download started'),
        theme: 'warning',
        icon: 'download',
        time: 10000,
      }),
    );

    try {
      await downloadFile(fileUrl, fileName);

      dispatch(
        showMessage({
          title: t('Download completed successfully'),
          theme: 'success',
          icon: 'check',
          time: 10000,
        }),
      );
    } catch (error) {
      dispatch(
        showMessage({
          title: t(
            'An unexpected error occurred while downloading the file/folder',
          ),
          theme: 'danger',
          icon: 'close',
          time: 10000,
        }),
      );
    }
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(fileLink);
    dispatch(
      showMessage({
        title: t('You have copied the file/folder link to your clipboard.'),
        theme: 'success',
        icon: 'check',
        time: 3000,
      }),
    );
  };

  return (
    <StyledFilesTableActions className="action-wrapper">
      <Dropdown.Root
        trigger={<Icon name="more-2" color="grayscale-200" />}
        theme="dark"
      >
        <React.Fragment>
          <Dropdown.Item onClick={handleVisualizeClick} disabled={!viewFile}>
            {t('View')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              isMobile ? handleMobileDownloadClick() : handleDownloadClick();
            }}
            disabled={!viewFile}
          >
            {t('Download')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleCopyLinkClick} disabled={!isShared}>
            {t('Copy Link')}
          </Dropdown.Item>
        </React.Fragment>
      </Dropdown.Root>
    </StyledFilesTableActions>
  );
};

export default FilesTableActions;
