import React from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { StyledCircularProgressBar } from './styles';

interface CircularProgressBarProps {
  completed: number;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  completed,
}) => {
  const generatePathColor = () => {
    let color = 'var(--success-color)';
    if (completed > 50) {
      color = 'var(--secondary-color-200)';
    }
    if (completed > 75) {
      color = 'var(--danger-color)';
    }
    return color;
  };

  return (
    <StyledCircularProgressBar>
      <CircularProgressbar
        className="circular-progress-bar"
        value={completed}
        text={`${completed}%`}
        strokeWidth={12}
        styles={buildStyles({
          // Colors
          pathColor: generatePathColor(),
          textColor: 'var(--grayscale-400)',
          trailColor: 'var(--grayscale-100)',
        })}
      />
    </StyledCircularProgressBar>
  );
};

export default CircularProgressBar;
