import styled from 'styled-components';

export const StyledNavigationTableActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .button {
    gap: 32px;

    i {
      font-size: 28px;
    }

    #button-left-icon {
      display: none;
    }
  }
`;
