import React from 'react';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
// import Checkbox from 'components/molecule/Checkbox';
import Collapse from 'components/molecule/Collapse';

import { ImportErrorItemProps } from './types';

import { StyledImportErrorItem } from './styles';

const ImportErrorItem: React.FC<ImportErrorItemProps> = ({
  row,
  errors,
  isOpen,
  setIsOpen,
}) => {
  // const [checked, setChecked] = useState(false);

  return (
    <StyledImportErrorItem className="import-error-item">
      <Collapse
        collapseHeader={
          <>
            <div className="left-side">
              <Icon name="alert-fill" color="danger-color" />
              <div className="info">
                <Text color="grayscale-200">{row || errors[0]}</Text>
              </div>
            </div>
            <div className="actions">
              {/* <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
              /> */}
            </div>
          </>
        }
        isOpen={isOpen && !!row}
        setIsOpen={setIsOpen}
        disabled={!row}
      >
        {errors.map((error, index) => (
          <Text key={index} weight="700">
            {error}
          </Text>
        ))}
      </Collapse>
    </StyledImportErrorItem>
  );
};

export default ImportErrorItem;
