import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { ProfileItemActionsProps } from './types';

import { StyledProfileItemActions } from './styles';

const ProfileItemActions: React.FC<ProfileItemActionsProps> = ({ onEdit }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <StyledProfileItemActions>
      <Dropdown
        open={open}
        setOpen={setOpen}
        toggleElement={<Icon name="more-2" />}
        toggleButtonTheme="link-page-button-gray-primary"
        dropdownContentColor="dark-color"
        contentPlace="right"
      >
        <Button
          theme="dark"
          leftIcon="pencil-fill"
          radius="0px"
          onClick={onEdit}
        >
          {t('Edit')}
        </Button>
      </Dropdown>
    </StyledProfileItemActions>
  );
};

export default ProfileItemActions;
