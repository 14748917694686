import styled from 'styled-components';

export const StyledMetabaseResults = styled.div`
  width: 100%;
  height: max-content;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading,
  .empty-message {
    margin-top: 128px;
  }

  iframe {
    width: 100%;
    max-width: 1000px;
    min-height: 852px;
    overflow: hidden;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
