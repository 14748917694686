import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setHasCustomContent } from 'store/slices/contentResults';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import ContentDetailsHeader from './components/ContentDetailsHeader';
import SecondaryDetailsHeader from './components/SecondaryDetailsHeader';
import ContentDetails from './components/ContentDetails';
import MetabaseResults from './components/MetabaseResults';
import ContentCommnts from './components/ContentCommnts';
import Statistics from './components/Statistics';
import ContentHistory from './components/ContentHistory';

import { StyledMuralContentResults, StyledRightSideContent } from './styles';

const MuralContentResults: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );
  const hasDashboard: boolean = useAppSelector(
    (state) => state.content.has_dashboard,
  );

  // main menu
  const [mainMenuActiveTab, setMainMenuActiveTab] = useState('content');
  const [mainMenuOptions, setMainMenuOptions] = useState([
    {
      key: 'content',
      content: t('Content'),
      active: mainMenuActiveTab === 'content',
    },
    {
      key: 'statistics',
      content: t('Insights'),
      active: mainMenuActiveTab === 'statistics',
    },
    {
      key: 'results',
      content: t('Results'),
      active: mainMenuActiveTab === 'results',
      disabled: !hasDashboard,
    },
  ]);

  // secondary menu
  const [secondaryMenuActiveTab, setSecondaryMenuActiveTab] =
    useState('comments');
  const [secondaryMenuOptions, setSecondaryMenuOptions] = useState([
    {
      key: 'comments',
      content: t('Comments'),
      active: secondaryMenuActiveTab === 'comments',
    },
    {
      key: 'history',
      content: t('History'),
      active: secondaryMenuActiveTab === 'history',
    },
    {
      key: 'responses',
      content: t('Responses'),
      active: secondaryMenuActiveTab === 'responses',
      disabled: true,
    },
  ]);

  const handleChangeMainTab = (tab: string) => {
    setMainMenuOptions(
      mainMenuOptions.map((item) => {
        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      }),
    );
    setMainMenuActiveTab(tab);
    setSecondaryMenuActiveTab(
      tab === 'content'
        ? secondaryMenuOptions.filter((item) => item.active)[0].key
        : '',
    );
  };

  const renderMainTabContent = () => {
    switch (mainMenuActiveTab) {
      case 'content': {
        return <ContentDetails />;
      }
      case 'statistics': {
        return <Statistics />;
      }
      case 'results': {
        return <MetabaseResults />;
      }
      default: {
        return '';
      }
    }
  };

  const handleChangeSecondaryTab = (tab: string) => {
    setSecondaryMenuOptions(
      secondaryMenuOptions.map((item) => {
        if (item.key === secondaryMenuActiveTab) {
          return {
            ...item,
            active: false,
          };
        }

        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      }),
    );
    setSecondaryMenuActiveTab(tab !== secondaryMenuActiveTab ? tab : '');
  };

  const renderSecondaryTabContent = () => {
    switch (secondaryMenuActiveTab) {
      case 'comments': {
        return <ContentCommnts />;
      }
      case 'history': {
        return <ContentHistory />;
      }
      case 'achievements': {
        return 'Achievements';
      }
      default: {
        return '';
      }
    }
  };

  useEffect(() => {
    setMainMenuOptions((options) =>
      options.map((option) => {
        return {
          ...option,
          disabled: option.key === 'results' && !hasDashboard,
        };
      }),
    );
  }, [hasDashboard, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setHasCustomContent(null));
    };
  }, [dispatch]);

  return (
    <StyledMuralContentResults className="mural-content-results">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={() => navigate('/mural')}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <ContentDetailsHeader
        tabMenuOptions={mainMenuOptions}
        onChangeTab={handleChangeMainTab}
      />
      <div className="details-content-wrapper">
        <div className="details-content">
          <div className="left-side-content default-scroll">
            {renderMainTabContent()}
          </div>
          <StyledRightSideContent
            className="right-side-content"
            width={secondaryMenuActiveTab === '' ? '0' : '50%'}
          >
            {renderSecondaryTabContent()}
          </StyledRightSideContent>
        </div>
        {mainMenuActiveTab === 'content' && (
          <SecondaryDetailsHeader
            tabMenuOptions={secondaryMenuOptions}
            onChangeTab={handleChangeSecondaryTab}
          />
        )}
      </div>
    </StyledMuralContentResults>
  );
};

export default MuralContentResults;
