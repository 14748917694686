import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import MainRestrictedLayout from 'layouts/RestrictedLayouts/MainRestrictedLayout';

import StaffRestrictedLayout from 'layouts/RestrictedLayouts/StaffRestrictedLayout';
import StaffDashboardLayout from 'layouts/RestrictedLayouts/StaffDashboardLayout';
import StaffDashboard from 'pages/staff/general/Dashboard';
import StaffFinanceContractList from 'pages/staff/finance/ContractList';
import StaffFinanceContractForm from 'pages/staff/finance/ContractForm';
import StaffFinancePaymentForm from 'pages/staff/finance/PaymentForm';
import StaffCSCXAlertList from 'pages/staff/cscx/AlertList';
import StaffCSCXAlertForm from 'pages/staff/cscx/AlertForm';
import StaffCSCXPopupList from 'pages/staff/cscx/PopupList';
import StaffCSCXPopupForm from 'pages/staff/cscx/PopupForm';

import AuthLayout from 'layouts/PublicLayouts/AuthLayout';
import DocumentationLayout from 'layouts/PublicLayouts/DocumentationLayout';
import ErrorLayout from 'layouts/PublicLayouts/ErrorLayout';
import CreationLayout from 'layouts/RestrictedLayouts/CreationLayout';
import DashboardLayout from 'layouts/RestrictedLayouts/DashboardLayout';
import ExpandedLayout from 'layouts/RestrictedLayouts/ExpandedLayout';

import Alerts from 'pages/documentation/Alerts';
import Avatars from 'pages/documentation/Avatars';
import Buttons from 'pages/documentation/Buttons';
import Cards from 'pages/documentation/Cards';
import Checkboxes from 'pages/documentation/Checkboxes';
import Colors from 'pages/documentation/Colors';
import CopyToClipboards from 'pages/documentation/CopyToClipboards';
import Dropdowns from 'pages/documentation/Dropdowns';
import EmptyMessages from 'pages/documentation/EmptyMessages';
import DocumentationHome from 'pages/documentation/Home';
import Icons from 'pages/documentation/Icons';
import Images from 'pages/documentation/Images';
import Inputs from 'pages/documentation/Inputs';
import Loadings from 'pages/documentation/Loadings';
import Modals from 'pages/documentation/Modals';
import Selects from 'pages/documentation/Selects';
import Tables from 'pages/documentation/Tables';
import Tags from 'pages/documentation/Tags';
import Textareas from 'pages/documentation/Textareas';
import Toasters from 'pages/documentation/Toasters';
import Tooltips from 'pages/documentation/Tooltips';
import Typography from 'pages/documentation/Typography';
import Editors from 'pages/documentation/Editors';
import DatePickers from 'pages/documentation/DatePickers';
import Ranges from 'pages/documentation/Ranges';
import Collapses from 'pages/documentation/Collapses';
import Maps from 'pages/documentation/Maps';

import Error403 from 'pages/general/Error403';
import Error404 from 'pages/general/Error404';
import Error500 from 'pages/general/Error500';
import Login from 'pages/general/Login';
import RedirectToLegacy from 'pages/general/RedirectToLegacy';
import LoginSSO from 'pages/general/LoginSSO';

import Dashboard from 'pages/board/Dashboard';
import Mural from 'pages/board/Mural';
import MuralContent from 'pages/board/MuralContent';
import MuralContentResults from 'pages/board/MuralContentResults';

import DriveManager from 'pages/drive/DriveManager';
import Drive from 'pages/drive/Drive';

import SurveyContent from 'pages/surveys/SurveyContent';
import SurveyMetabaseDashboards from 'pages/surveys/SurveyMetabaseDashboards';
import SurveyList from 'pages/surveys/SurveyList';

import Wizard from 'pages/onboarding/Register';
import WizardConfirmAccount from 'pages/onboarding/ConfirmAccount';
import WizardAccountConfirmed from 'pages/onboarding/AccountConfirmed';

import ManagementSections from 'pages/settings/ManagementSections';
import MuralContentConfig from 'pages/settings/MuralContentConfig';
import HierarchyConfig from 'pages/settings/HierarchyConfig';
import CollaboratorsConfig from 'pages/settings/CollaboratorsConfig';
import CollaboratorsImport from 'pages/settings/CollaboratorsImport';
import HierarchyImport from 'pages/settings/HierarchyImport';
import GenralConfig from 'pages/settings/GeneralConfig';
import VisualCustomization from 'pages/settings/VisualCustomization';

import StaffRestrictedCSCXLayout from 'layouts/RestrictedLayouts/StaffRestrictedCSCXLayout';
import StaffRestrictedFinancialLayout from 'layouts/RestrictedLayouts/StaffRestrictedFinancialLayout';

import SmartPushList from 'pages/smartpush/SmartPushList';
import SmartPushForm from 'pages/smartpush/SmartPushForm';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          {/* Redirect routes */}
          <Route path="/login/sso/:identityId" element={<LoginSSO />} />
          <Route path="/drive" element={<Drive />} />
          <Route path="/drive/:parentId" element={<Drive />} />

          {/* Public routes */}
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="/" element={<Login />} />
          </Route>

          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="/register" element={<Wizard />} />
            <Route
              path="/confirm-account/:token"
              element={<WizardConfirmAccount />}
            />
            <Route
              path="/register/:token/confirm"
              element={<WizardAccountConfirmed />}
            />
          </Route>

          <Route
            element={
              <ErrorLayout>
                <Outlet />
              </ErrorLayout>
            }
          >
            <Route path="*" element={<Error404 />} />
            <Route path="/error-403" element={<Error403 />} />
            <Route path="/error-500" element={<Error500 />} />
          </Route>

          <Route
            element={
              <DocumentationLayout>
                <Outlet />
              </DocumentationLayout>
            }
          >
            <Route path="/doc/home" element={<DocumentationHome />} />
            <Route path="/doc/typography" element={<Typography />} />
            <Route path="/doc/icons" element={<Icons />} />
            <Route path="/doc/buttons" element={<Buttons />} />
            <Route path="/doc/colors" element={<Colors />} />
            <Route path="/doc/cards" element={<Cards />} />
            <Route path="/doc/alerts" element={<Alerts />} />
            <Route path="/doc/toasters" element={<Toasters />} />
            <Route path="/doc/loadings" element={<Loadings />} />
            <Route path="/doc/inputs" element={<Inputs />} />
            <Route path="/doc/textareas" element={<Textareas />} />
            <Route path="/doc/tooltips" element={<Tooltips />} />
            <Route
              path="/doc/copy-to-clipboards"
              element={<CopyToClipboards />}
            />
            <Route path="/doc/dropdowns" element={<Dropdowns />} />
            <Route path="/doc/selects" element={<Selects />} />
            <Route path="/doc/checkboxes" element={<Checkboxes />} />
            <Route path="/doc/tags" element={<Tags />} />
            <Route path="/doc/images" element={<Images />} />
            <Route path="/doc/tables" element={<Tables />} />
            <Route path="/doc/empty-messages" element={<EmptyMessages />} />
            <Route path="/doc/modals" element={<Modals />} />
            <Route path="/doc/avatars" element={<Avatars />} />
            <Route path="/doc/editors" element={<Editors />} />
            <Route path="/doc/datepickers" element={<DatePickers />} />
            <Route path="/doc/ranges" element={<Ranges />} />
            <Route path="/doc/collapses" element={<Collapses />} />
            <Route path="/doc/maps" element={<Maps />} />
          </Route>

          {/* Restricted staff routes */}
          <Route
            element={
              <StaffRestrictedLayout>
                <Outlet />
              </StaffRestrictedLayout>
            }
          >
            <Route
              element={
                <StaffDashboardLayout>
                  <Outlet />
                </StaffDashboardLayout>
              }
            >
              <Route path="/staff/dashboard" element={<StaffDashboard />} />
              <Route
                path="/staff/dashboard/:typeId"
                element={<StaffDashboard />}
              />
            </Route>
          </Route>

          <Route
            element={
              <StaffRestrictedFinancialLayout>
                <Outlet />
              </StaffRestrictedFinancialLayout>
            }
          >
            <Route
              element={
                <StaffDashboardLayout>
                  <Outlet />
                </StaffDashboardLayout>
              }
            >
              <Route
                path="/staff/finance/contract"
                element={<StaffFinanceContractList />}
              />
              <Route
                path="/staff/finance/contract/add"
                element={<StaffFinanceContractForm />}
              />
              <Route
                path="/staff/finance/contract/:contractId"
                element={<StaffFinanceContractForm />}
              />
              <Route
                path="/staff/finance/payment/:paymentId"
                element={<StaffFinancePaymentForm />}
              />
              <Route
                path="/staff/finance/dashboard/:typeId"
                element={<StaffDashboard />}
              />
            </Route>
          </Route>

          <Route
            element={
              <StaffRestrictedCSCXLayout>
                <Outlet />
              </StaffRestrictedCSCXLayout>
            }
          >
            <Route
              element={
                <StaffDashboardLayout>
                  <Outlet />
                </StaffDashboardLayout>
              }
            >
              <Route
                path="/staff/cscx/alert"
                element={<StaffCSCXAlertList />}
              />
              <Route
                path="/staff/cscx/alert/add"
                element={<StaffCSCXAlertForm />}
              />
              <Route
                path="/staff/cscx/alert/:alertId"
                element={<StaffCSCXAlertForm />}
              />
              <Route
                path="/staff/cscx/popup"
                element={<StaffCSCXPopupList />}
              />
              <Route
                path="/staff/cscx/popup/add"
                element={<StaffCSCXPopupForm />}
              />
              <Route
                path="/staff/cscx/popup/:popupId"
                element={<StaffCSCXPopupForm />}
              />
              <Route
                path="/staff/cscx/dashboard/:typeId"
                element={<StaffDashboard />}
              />
            </Route>
          </Route>

          {/* Restricted routes */}
          <Route
            element={
              <MainRestrictedLayout>
                <Outlet />
              </MainRestrictedLayout>
            }
          >
            <Route
              element={
                <DashboardLayout>
                  <Outlet />
                </DashboardLayout>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/mural" element={<Mural />} />
              <Route path="/drive/dashboard" element={<DriveManager />} />
              <Route
                path="/drive/dashboard/:parentId"
                element={<DriveManager />}
              />
              <Route path="/research" element={<SurveyList />} />
              <Route path="/redirect" element={<RedirectToLegacy />} />

              <Route path="/settings" element={<ManagementSections />} />

              <Route path="/smartpush" element={<SmartPushList />} />
              <Route path="/smartpush/add" element={<SmartPushForm />} />
              <Route path="/smartpush/:pushId" element={<SmartPushForm />} />
              <Route
                path="/research/:contentId/questions"
                element={<SurveyMetabaseDashboards />}
              />
              <Route
                path="/research/:contentId/questions/:questionId/answers"
                element={<SurveyMetabaseDashboards />}
              />
            </Route>

            <Route
              element={
                <CreationLayout>
                  <Outlet />
                </CreationLayout>
              }
            >
              <Route
                path="/mural/content/:type/:subtype/:contentId"
                element={<MuralContent />}
              />
              <Route
                path="/mural/content/:type/:subtype"
                element={<MuralContent />}
              />

              {/* <Route path="/smartpush/add" element={<SmartPush />} />
              <Route path="/smartpush/:alertId" element={<SmartPush />} /> */}
            </Route>

            <Route
              element={
                <ExpandedLayout>
                  <Outlet />
                </ExpandedLayout>
              }
            >
              <Route path="/mural/settings" element={<MuralContentConfig />} />
              <Route
                path="/hierarchy/settings/:entity"
                element={<HierarchyConfig />}
              />
              <Route
                path="/hierarchy/settings/:entity/:id"
                element={<HierarchyConfig />}
              />
              <Route
                path="/collaborators/settings"
                element={<CollaboratorsConfig />}
              />
              <Route
                path="/collaborators/import"
                element={<CollaboratorsImport />}
              />
              <Route path="/hierarchy/import" element={<HierarchyImport />} />
              <Route
                path="/mural/content/:type/:subtype/:contentId/results"
                element={<MuralContentResults />}
              />
              <Route
                path="/general/settings/:entity"
                element={<GenralConfig />}
              />
              <Route
                path="/visual-identity/settings"
                element={<VisualCustomization />}
              />
              <Route
                path="/research/:researchType/add"
                element={<SurveyContent />}
              />
              <Route
                path="/research/:researchType/edit/:contentId"
                element={<SurveyContent />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
