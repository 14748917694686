import styled from 'styled-components';

export const StyledDriveCard = styled.div`
  width: 242px;
  height: 72px;
  background-color: var(--white-color);
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 12px;
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 20px;
    }
  }

  .file-info-wrapper {
    width: 120px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .text {
      font-size: 14px;
      max-width: 16ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .action-wrapper {
    padding: 12px;
  }

  .action-wrapper > .dropdown > .button {
    margin-top: 0px;
    padding: 0;
    height: auto;
    min-width: auto;
  }
`;
