import styled from 'styled-components';

export const StyledDotPaginator = styled.ul`
  display: flex;
  gap: 26px;

  li {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: var(--grayscale-100);
  }

  .active {
    background-color: var(--primary-color);
    position: relative;
  }

  .active:after {
    border-radius: 50%;
    padding: 5px;
    border: 2px solid var(--primary-color-100);
    position: absolute;
    content: '';
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
  }
`;
