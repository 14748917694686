import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getDateFromApiDate } from 'utils/date';

import {
  getContent,
  getContentHistory,
  getPendingReportExports,
  exportReport,
} from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage, closeMessage } from 'store/slices/toaster';
import { showSecondaryMessage } from 'store/slices/secondaryToaster';
import { setModalView } from 'store/slices/modal';
import { setPageLoading } from 'store/slices/pageLoading';

import ContentHistoryHeader from './components/ContentHistoryHeader';
import EmptyMessage from 'components/molecule/EmptyMessage';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import HistoryItemLoading from './components/HistoryItemLoading';
import Loading from 'components/molecule/Loading';
import HistoryItem from './components/HistoryItem';
import ExportConfirm from './components/ExportConfirm';
import Button from 'components/molecule/Button';
import PendingExports from './components/PendingExports';

import { HistoryItemProps } from './components/HistoryItem/types';

import { StyledContentHistory } from './styles';

const ContentHistory: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { type, contentId: subcontentId } = useParams();

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [contentId, setContentId] = useState('');

  const [historyItems, setHistoryItems] = useState<HistoryItemProps[]>([]);

  // Infinit scroll states
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState('-created_at');
  const [, setIsAFirstRender] = useState(true);
  const [userEmail, setUserEmail] = useState('');

  const checkPendingReports = useCallback(
    (content: string, email: string) => {
      getPendingReportExports(organizationId, content, 1, 'history', true).then(
        (response) => {
          const { total } = response.data;

          if (total > 0) {
            dispatch(
              showMessage({
                title: t('There are {{count}} items in processing', {
                  count: total,
                }),
                theme: 'warning',
                icon: 'alert-fill',
                time: 10000,
                children: (
                  <Button
                    theme="link-primary"
                    className="see-all-button"
                    size="small"
                    onClick={() => {
                      dispatch(closeMessage());
                      dispatch(
                        setModalView({
                          show: true,
                          width: '655px',
                          content: (
                            <PendingExports
                              userEmail={email}
                              contentId={content}
                            />
                          ),
                        }),
                      );
                    }}
                  >
                    {t('See all')}
                  </Button>
                ),
              }),
            );
          }
        },
      );
    },
    [dispatch, organizationId, t],
  );

  const loadHistory = useCallback(
    (content: string, page: number, order: string) => {
      getContentHistory(organizationId, content, page, order)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);
          setUserEmail(response.data.email);

          setIsAFirstRender((isFirst) => {
            if (isFirst) {
              checkPendingReports(content, response.data.email);
            }
            return false;
          });

          if (page > 1) {
            setHistoryItems((lastHistory) => [
              ...lastHistory,
              ...response.data.results,
            ]);
            return;
          }

          setHistoryItems(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading history'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, organizationId, t, checkPendingReports],
  );

  const handleChangeOrder = (value: string) => {
    setLoading(true);
    setCurrentOrder(value);
    loadHistory(contentId, 1, value);
  };

  const confirmExport = () => {
    dispatch(setPageLoading(true));

    exportReport(organizationId, contentId, {
      order: currentOrder,
      period: null,
      type: 'history',
    })
      .then(() => {
        dispatch(
          showSecondaryMessage({
            title: t('Export request successful'),
            description: t(
              'Exported file will be sent to e-mail: {{target_mail}}',
              { target_mail: userEmail },
            ),
            theme: 'success-dark',
            icon: 'check',
            time: 10000,
          }),
        );
        checkPendingReports(contentId, userEmail);
      })
      .catch((error) => {
        if (error.response.data.code[0] === 'invalid') {
          dispatch(
            showMessage({
              title: error.response.data.errors[0],
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t('An unexpected error occurred while exporting the report'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleExport = () => {
    dispatch(
      setModalView({
        show: true,
        width: '655px',
        content: (
          <ExportConfirm
            order={currentOrder}
            onExport={confirmExport}
            userEmail={userEmail}
          />
        ),
      }),
    );
  };

  useEffect(() => {
    setLoading(true);

    if (!type || !subcontentId) return;

    getContent(organizationId, type, subcontentId)
      .then((response) => {
        const { content_id } = response.data;

        setContentId(content_id);
        loadHistory(content_id, 1, currentOrder);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while loading history'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  }, [
    loadHistory,
    organizationId,
    type,
    subcontentId,
    dispatch,
    t,
    currentOrder,
  ]);

  return (
    <StyledContentHistory>
      <ContentHistoryHeader
        onExport={handleExport}
        onChangeOrder={handleChangeOrder}
      />

      {loading && (
        <>
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
          <HistoryItemLoading className="history-item-loading" />
        </>
      )}

      <div
        className="history-content default-scroll"
        ref={(currentRef) => setScrollElement(currentRef)}
      >
        <div className="history-item-list">
          {historyItems.map((historyItem, index) => (
            <HistoryItem
              key={index}
              changes_fields={historyItem.changes_fields}
              created_at={historyItem.created_at}
              created_by={historyItem.created_by}
              type={historyItem.type}
              hideTitle={
                index > 0 &&
                getDateFromApiDate(historyItems[index - 1].created_at) ===
                  getDateFromApiDate(historyItem.created_at)
              }
            />
          ))}
        </div>

        {!loading && historyItems.length === 0 && (
          <EmptyMessage title={t('No history')} icon="clock-outline" />
        )}

        {loadMoreLoading && <Loading type="bubbles" />}
      </div>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadHistory(contentId, currentPage + 1, currentOrder);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledContentHistory>
  );
};

export default ContentHistory;
