import React from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridRow, GridColumn } from 'components/atom/Grid';
import Text from 'components/atom/Text';
import Image from 'components/molecule/Image';

import nointernet from 'assets/images/nointernet.gif';

import { StyledError500 } from './styles';

const Error500: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledError500 className="error-500-page">
      <GridContainer>
        <GridRow>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="message-wrapper">
              <Text as="h1">{t('500')}</Text>
              <Text as="h2">{t('An unexpected error occurred')}</Text>
              <Text as="h5">{t('Try again later')}</Text>
            </div>
          </GridColumn>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="image-wrapper">
              <Image src={nointernet} />
            </div>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </StyledError500>
  );
};

export default Error500;
