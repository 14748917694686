import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Footer from 'components/molecule/Footer';
import Button from 'components/molecule/Button';
import Collapse from 'components/molecule/Collapse';

import { StyledStaffMenu } from './styles';
import UserState from 'store/slices/user/types';
import { useAppSelector } from 'store/hooks';
import { getMenuDashboards } from 'apis/staff';
import { DashboardTypeItem, DashboardTypesList } from './types';

const StaffMenu: React.FC = () => {
  const { t } = useTranslation();

  const [financeIsOpen, setFinanceIsOpen] = useState(false);
  const [cscxIsOpen, setCsCxIsOpen] = useState(false);
  const user: UserState = useAppSelector((state) => state.user);
  const [generalDashboards, setGeneralDashboards] = useState<
    DashboardTypeItem[]
  >([]);
  const [financialDashboards, setFinancialDashboards] = useState<
    DashboardTypeItem[]
  >([]);
  const [cscxDashboards, setCSCXDashboards] = useState<DashboardTypeItem[]>([]);

  useEffect(() => {
    getMenuDashboards().then((response) => {
      const data: DashboardTypesList = response.data as DashboardTypesList;
      setGeneralDashboards(data.area_0);
      setFinancialDashboards(data.area_1);
      setCSCXDashboards(data.area_2);
    });
  }, [user]);

  return (
    <StyledStaffMenu>
      <div className="menu-options default-scroll">
        <div className="highlight-options">
          <Button radius="0px" leftIcon="home-6-fill" to="/staff/dashboard">
            {t('Home')}
          </Button>
          {generalDashboards.map((dash) => (
            <React.Fragment key={dash.id}>
              <Button
                radius="0px"
                leftIcon="level"
                to={`/staff/dashboard/${dash.id}`}
              >
                {dash.title}
              </Button>
            </React.Fragment>
          ))}
          {user.staffAreas.includes('1') && (
            <Collapse
              collapseHeader={
                <Button
                  radius="0px"
                  leftIcon="list"
                  onClick={() => undefined}
                  className="legacies-button"
                >
                  {t('Finance')}
                </Button>
              }
              isOpen={financeIsOpen}
              setIsOpen={() => setFinanceIsOpen(!financeIsOpen)}
            >
              <div className="sub-options">
                <Button radius="0px" to="/staff/finance/contract">
                  {t('Contracts')}
                </Button>
              </div>
              {financialDashboards.map((dash) => (
                <React.Fragment key={dash.id}>
                  <div className="sub-options">
                    <Button
                      radius="0px"
                      to={`/staff/finance/dashboard/${dash.id}`}
                    >
                      {dash.title}
                    </Button>
                  </div>
                </React.Fragment>
              ))}
            </Collapse>
          )}
          {user.staffAreas.includes('2') && (
            <Collapse
              collapseHeader={
                <Button
                  radius="0px"
                  leftIcon="list"
                  onClick={() => undefined}
                  className="legacies-button"
                >
                  {t('CS/CX')}
                </Button>
              }
              isOpen={cscxIsOpen}
              setIsOpen={() => setCsCxIsOpen(!cscxIsOpen)}
            >
              <div className="sub-options">
                <Button radius="0px" to="/staff/cscx/alert">
                  {t('Alerts')}
                </Button>
              </div>
              <div className="sub-options">
                <Button radius="0px" to="/staff/cscx/popup">
                  {t('Popups')}
                </Button>
              </div>
              {cscxDashboards.map((dash) => (
                <React.Fragment key={dash.id}>
                  <div className="sub-options">
                    <Button
                      radius="0px"
                      to={`/staff/cscx/dashboard/${dash.id}`}
                    >
                      {dash.title}
                    </Button>
                  </div>
                </React.Fragment>
              ))}
            </Collapse>
          )}
        </div>
      </div>
      <Footer organizationName="USEFLOW STAFF" />
    </StyledStaffMenu>
  );
};

export default StaffMenu;
