import styled from 'styled-components';

export const StyledStaffMenu = styled.div.attrs(() => ({
  className: 'menu',
}))`
  width: 240px;
  height: 100%;
  max-height: 100%;
  background: var(--dark-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;

  .menu-options {
    position: relative;
    flex: 1;
    overflow-y: scroll;
  }

  .highlight-options,
  .options,
  .collapse {
    .button,
    .legacies-button {
      width: 100%;
      justify-content: flex-start;
      padding-left: 34px;
      transition: background 0.2s;

      #button-left-icon {
        color: var(--secondary-color);
        display: block;
      }

      div,
      i {
        font-size: 18px;
        color: var(--white-color);
      }

      &:disabled,
      &.disabled {
        background: var(--dark-color);
        border: none;
        & > * {
          color: var(--grayscale-300);
        }
      }
    }
  }

  .collapse {
    .collapse-header {
      width: 100%;
      border: none;
    }
    .collapse-content {
      & > div {
        .options {
          padding-top: 0;
          .button {
            padding-left: 24px;
          }
        }
        .sub-options {
          padding-top: 1px;
          padding-bottom: 1px;
          .button {
            padding-left: 24px;
            background-color: var(--grayscale-300);
          }
          .button:active {
            background-color: red;
          }
          .button:hover {
            background-color: var(--grayscale-400);
          }
        }
      }
    }
  }

  /* .highlight-options {
    height: max-content;
  } */

  /* .options {
    flex: 1;
    padding: 16px 0px;
    overflow-y: scroll;
    transition: background 0.2s;

    .button {
      padding-left: 26px;
    }
  } */
`;
