interface LanguageCode {
  [key: string]: string;
}

// converting our language strings to the ones supported by dayjs
// to add more, check list at https://github.com/iamkun/dayjs/tree/dev/src/locale
export const getDayJSLanguageCodes = (language: string) => {
  const languages: LanguageCode = {
    en: 'en',
    es: 'es',
    pt: 'pt',
    'pt-pt': 'pt',
  };

  const languageCode = languages[language];

  return languageCode ? languageCode : 'en';
};
