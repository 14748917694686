import styled from 'styled-components';

export const StyledNavigationTable = styled.div`
  margin-top: 16px;

  .table-wrapper table tbody tr td {
    padding: 8px;
  }

  table tbody tr:hover .navigation-table-actions .button-content,
  table tbody tr:hover .navigation-table-actions #button-right-icon {
    color: var(--primary-color);
  }
`;
