import React from 'react';

import Image from '../Image';
import Text from 'components/atom/Text';

import { StyledOrganization } from './styles';

interface OrganizationProps {
  avatar: string | null;
  name: string;
  clientName: string;
}

const Organization: React.FC<OrganizationProps> = ({
  avatar,
  name,
  clientName,
}) => {
  return (
    <StyledOrganization className="organization">
      <Image src={avatar} width="48px" height="48px" radius="50%" />
      <div className="info">
        <Text as="h6" weight="bold">
          {name}
        </Text>
        <Text as="p">{clientName}</Text>
      </div>
    </StyledOrganization>
  );
};

export default Organization;
