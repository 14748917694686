import styled from 'styled-components';

export const StyledOrganization = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  text-align: start;

  .image {
    margin-right: 8px;
  }
`;
