import styled from 'styled-components';

import background from 'assets/images/background.svg';

interface StyledWizardProps {
  carrosselImage: string;
}

export const StyledWizard = styled.div<StyledWizardProps>`
  width: 100%;
  height: 100%;
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
    max-width: 1600px;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: row;
  }

  .change-language {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 9;
  }

  pre {
    white-space: normal;
  }

  .wizard-wrapper {
    position: relative;
    width: 60%;
    height: 100%;
    background-color: var(--white-color);
    border-radius: 8px 0 0 8px;
    padding: 32px 32px 0 32px;
    overflow-y: auto;

    .header {
      height: auto;
      margin-bottom: 32px;
    }

    .input-limit {
      height: auto;
      animation: none;
    }
  }

  .carrossel-wrapper {
    position: relative;
    width: 40%;
    height: 100%;
    background-image: url(${(props) => props.carrosselImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0 8px 8px 0;
  }

  .header {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  @media only screen and (max-width: 960px) {
    padding: 0;

    .card {
      width: 100%;
      height: 100%;
    }

    .carrossel-wrapper {
      display: none;
    }

    .wizard-wrapper {
      width: 100%;
    }
  }
`;

export const StyledWizardStep = styled.div`
  height: calc(100% - 64px);

  .wizard-form {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wizard-form-select-placeholder {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .wizard-form-select-placeholder .text {
    padding-top: 3px;
    font-size: 18px;
  }

  .wizard-footer {
    background-color: var(--white-color);
    width: 100%;
    height: auto;
    margin-top: auto;
    padding-bottom: 32px;
    padding-top: 32px;
  }

  .wizard-action {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .mt-2 {
    margin-top: 24px;
  }

  .mt-1 {
    margin-top: 14px;
  }
  .checkbox-wrapper {
    display: flex;
  }

  .checkbox-text {
    margin-top: 2.5px;
  }

  .checkbox-text-button {
    display: inline-block;
    padding: 0px;
    height: 0px;
  }

  .checkbox-text-button #button-left-icon {
    display: none;
  }

  .sel-button {
    padding: 0;
  }

  .react-select__single-value {
    .sel-button {
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }
  }

  @media only screen and (max-width: 960px) {
    .wizard-footer {
      background-color: var(--white-color);
      position: initial;
    }
  }
`;

interface StyledWizardBalloonProps {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  borderRadius: string;
  width: string;
}

export const StyledWizardBalloon = styled.div<StyledWizardBalloonProps>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: ${(props) => props.borderRadius};
  padding: 20px 20px;
  word-wrap: break-word;
  max-width: ${(props) => props.width};
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.15);

  .balloon-text {
    line-height: 110%;
  }

  .icon {
    font-size: 30px;
  }
`;
