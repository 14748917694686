import React from 'react';

import Text from 'components/atom/Text';

import { StyledFilesTableSize } from './styles';

interface FilesTableSizeProps {
  size: string;
}

const FilesTableSize: React.FC<FilesTableSizeProps> = ({ size }) => {
  return (
    <StyledFilesTableSize>
      <Text as="pre" color="grayscale-300">
        {size}
      </Text>
    </StyledFilesTableSize>
  );
};

export default FilesTableSize;
