import apiV2 from 'apis/apiV2';
import { PushPostSendData } from './types';

const basePathname = '/admin/org';

/**
 * Smart Pushs
 */
export const getSmartPush = (organizationId: string, id: string) =>
  apiV2.get(`${basePathname}/${organizationId}/smartpush/${id}/`);

export const getSmartPushs = (
  organizationId: string,
  page: number,
  filter: {
    search: string;
    message: string;
    order: string;
  },
) =>
  apiV2.get(`${basePathname}/${organizationId}/smartpush/`, {
    params: {
      page,
      search: filter.search,
      message: filter.message,
      ordering: filter.order,
    },
  });

export const createSmartPush = (
  organizationId: string,
  data: PushPostSendData,
) => apiV2.post(`${basePathname}/${organizationId}/smartpush/`, data);

export const updateSmartPush = (
  organizationId: string,
  id: string,
  data: PushPostSendData,
) => apiV2.put(`${basePathname}/${organizationId}/smartpush/${id}/`, data);

export const abortSmartPush = (organizationId: string, id: string) =>
  apiV2.delete(`${basePathname}/${organizationId}/smartpush/${id}/`);
