import React from 'react';

const skeletonLoadingTypes = {
  line: 'line',
  'short-line': 'short-line',
  text: 'text',
  paragraph: 'paragraph',
  custom: 'custom',
};

interface SkeletonLoadingProps {
  type: keyof typeof skeletonLoadingTypes;
  customStyle?: React.CSSProperties;
}

import { StyledSkeletonLoading } from './styles';

const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({
  type,
  customStyle,
}) => {
  return (
    <StyledSkeletonLoading className="skeleton-loading">
      {type === 'line' && (
        <div className="line">
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'short-line' && (
        <div className="short-line">
          <span className="skeleton-box" style={{ width: '150px' }}></span>
        </div>
      )}

      {type === 'text' && (
        <div className="short-line">
          <span className="skeleton-box" style={{ width: '90%' }}></span>
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'paragraph' && (
        <div className="paragraph">
          <span className="skeleton-box" style={{ width: '80%' }}></span>
          <span className="skeleton-box" style={{ width: '90%' }}></span>
          <span className="skeleton-box" style={{ width: '83%' }}></span>
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'custom' && (
        <div className="custom">
          <span className="skeleton-box" style={customStyle}></span>
        </div>
      )}
    </StyledSkeletonLoading>
  );
};

export default SkeletonLoading;
