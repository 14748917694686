import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Text from 'components/atom/Text';

import { DenylistItemProps } from './types';

import { StyledDenylistItem } from './styles';

const DenylistItem: React.FC<DenylistItemProps> = ({
  index,
  name,
  onSave,
  onRemove,
  onEdit,
  onCancel,
  initialOpen,
  disabled,
  hasError,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const charLimitView = 54;

  const [inEdit, setInEdit] = useState(initialOpen);
  const [value, setValue] = useState(name);
  const [error, setError] = useState(hasError);

  useEffect(() => {
    setInEdit(initialOpen);
    setValue(name);
    setError(hasError);
  }, [initialOpen, name, hasError]);

  return (
    <StyledDenylistItem>
      <div className="form-wrapper">
        <form
          className="content"
          onSubmit={(event) => {
            event.preventDefault();
            onSave(value, index);
          }}
        >
          {!inEdit && (
            <Tooltip
              content={value}
              id={`option-${index}`}
              disabled={!!value && value.length < charLimitView}
              width="340px"
            >
              <Button
                theme="link-dark"
                size="small"
                onClick={() => {
                  setInEdit(true);
                  onEdit(index);
                }}
                disabled={disabled}
              >
                <Text as="h6">{value}</Text>
              </Button>
            </Tooltip>
          )}
          {inEdit && (
            <>
              <Input
                placeholder={t('Entry')}
                value={value}
                onChange={(event) => {
                  setError(false);
                  setValue(event.target.value.toLowerCase());
                }}
                autoFocus
                limit={50}
              />
              <Button
                rounded="true"
                className="save-button"
                type="submit"
                disabled={!value || value.trim().length === 0}
              >
                {t('Save')}
              </Button>
              <Button
                rounded="true"
                className="cancel-button"
                theme="link-primary"
                onClick={() => {
                  onCancel(index);
                  setInEdit(false);
                  setValue(name);
                }}
                size="small"
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </form>
        <Button
          theme="link-danger"
          onClick={() => onRemove(index)}
          disabled={disabled}
          className={`remove-button ${disabled ? 'is-disabled' : ''}`}
          size="small"
        >
          <Icon name="less" />
        </Button>
      </div>
      {error && (
        <Text color="danger-color" as="pre">
          {errorMessage}
        </Text>
      )}
    </StyledDenylistItem>
  );
};

export default DenylistItem;
