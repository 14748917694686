import styled from 'styled-components';

export const StyledCustomFieldFormModal = styled.div`
  .custom-field-type {
    background: var(--gray-color);
    width: max-content;
    padding: 14px 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      margin-right: 4px;
      font-size: 24px;
      color: var(--tertiary-color-1);
    }
  }

  & > .text {
    margin-bottom: 32px;
  }

  .form-wrapper {
    & > .text {
      margin-top: 32px;
      margin-bottom: 8px;
    }

    .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .select {
        flex: 1;
        .select-option {
          padding: 12px;
          pre {
            margin-bottom: 2px;
          }
        }

        .react-select__control {
          .select-option {
            padding: 12px 0px;
          }
        }

        .react-select__menu {
          .select-option {
            pre {
              display: none;
            }
          }
        }
      }
    }

    & > .input {
      margin-bottom: 32px;
    }

    .field-section-wrapper {
      margin-top: 32px;
      padding-bottom: 8px;
      & > .text {
        margin-bottom: 8px;
      }
    }

    .field-section-wrapper + hr {
      margin-top: 8px;
    }

    .required-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }
  }

  hr {
    margin: 0;
  }

  .modal-footer {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button:first-child {
      padding-left: 0px;
      i {
        display: none;
      }
      .button-content {
        justify-content: flex-start;
      }
    }
  }

  .loading {
    margin-top: 32px;
  }
`;
