import styled from 'styled-components';

export const StyledNumericOptions = styled.div`
  .threshold-wrapper {
    display: flex;
    margin-top: 8px;
    margin-bottom: 32px;
    gap: 12px;
    & > div {
      width: 100%;
    }
    & > div > .text {
      margin-bottom: 8px;
    }

    .value-label-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 3px;

      .icon {
        font-size: 20px;
      }
    }
  }

  .react-tooltip {
    max-width: 200px;
  }
`;
