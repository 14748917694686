import styled from 'styled-components';

export const StyledExpandedLayout = styled.div.attrs(() => ({
  className: 'expanded-layout',
}))`
  width: 100%;
  height: 100%;
  display: flex;

  .content-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .alert-container {
    margin: 8px;
  }
`;

export default StyledExpandedLayout;
