import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { getContentStatistics, getContent } from 'apis/board';

import Card from 'components/atom/Card';
import TabMenu from 'components/molecule/TabMenu';
import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';
import Icon from 'components/atom/Icon';
import Loading from 'components/molecule/Loading';
import Image from 'components/molecule/Image';

import likeImage from 'assets/images/like.svg';
import celebrateImage from 'assets/images/celebrate.svg';
import thinkingImage from 'assets/images/thinking.svg';
import dislikeImage from 'assets/images/dislike.svg';

import { PeriodStatisticsCardProps } from './types';
import { StatisticResultItem } from 'apis/board/types';

import { StyledPeriodStatisticsCard } from './styles';

const PeriodStatisticsCard: React.FC<PeriodStatisticsCardProps> = ({
  title,
  description,
  statisticEntity,
  isPercent,
}) => {
  const { t } = useTranslation();
  const { contentId: subcontentId, type } = useParams();
  const dispatch = useAppDispatch();
  const isReaction = statisticEntity === 'reaction';

  const weekDays = useMemo(
    () => ({
      monday: t('Monday'),
      tuesday: t('Tuesday'),
      wednesday: t('Wednesday'),
      thursday: t('Thursday'),
      friday: t('Friday'),
      saturday: t('Saturday'),
      sunday: t('Sunday'),
    }),
    [t],
  );
  const months = useMemo(
    () => ({
      january: t('January'),
      february: t('February'),
      march: t('March'),
      april: t('April'),
      may: t('May'),
      june: t('June'),
      july: t('July'),
      august: t('August'),
      september: t('September'),
      october: t('October'),
      november: t('November'),
      december: t('December'),
    }),
    [t],
  );
  const reactionImages = useMemo(
    () => ({
      like: likeImage,
      celebrate: celebrateImage,
      thinking: thinkingImage,
      dislike: dislikeImage,
    }),
    [],
  );

  const primaryColor = useAppSelector((state) => state.theme.primary_color_200);
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [activePeriod, setActivePeriod] = useState('day');
  const [periodOptions, setPeriodOptions] = useState([
    {
      key: 'day',
      content: t('Today'),
      active: activePeriod === 'day',
    },
    {
      key: 'week',
      content: t('Week'),
      active: activePeriod === 'week',
    },
    {
      key: 'month',
      content: t('Month'),
      active: activePeriod === 'month',
    },
    {
      key: 'year',
      content: t('Year'),
      active: activePeriod === 'year',
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [contentId, setContentId] = useState('');
  const [data, setData] = useState<StatisticResultItem[]>([]);
  const [total, setTotal] = useState('');
  const [reactionPeriodLabel, setReactionPeriodLabel] = useState('');

  const loadStatistics = useCallback(
    (contentId: string, period: string) => {
      if (!contentId) return;

      getContentStatistics(organizationId, contentId, statisticEntity, period)
        .then((response) => {
          const { results, total: statisticTotal, label } = response.data;

          if (isReaction) {
            setReactionPeriodLabel(label);
          }

          setTotal(statisticTotal);

          if (period === 'week' && !isReaction) {
            results.map((result: StatisticResultItem) => {
              const currentKey = (result.key as string).toLowerCase();
              result.key = weekDays[
                currentKey as keyof typeof weekDays
              ].substring(0, 3);
              return result;
            });
          }

          if (period === 'year' && !isReaction) {
            results.map((result: StatisticResultItem) => {
              const currentKey = (result.key as string).toLowerCase();
              result.key = months[currentKey as keyof typeof months].substring(
                0,
                3,
              );
              return result;
            });
          }

          setData(results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      organizationId,
      statisticEntity,
      months,
      weekDays,
      dispatch,
      t,
      isReaction,
    ],
  );

  const handleChangeMainTab = (tab: string) => {
    setPeriodOptions(
      periodOptions.map((item) => {
        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      }),
    );
    setActivePeriod(tab);
    setLoading(true);
    loadStatistics(contentId, tab);
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <Card className="custom-tooltip">
          <Text>{label}</Text>
          <Text color="primary-color">
            {t('Total')}: {payload[0].value}
          </Text>
        </Card>
      );
    }

    return null;
  };

  useEffect(() => {
    if (type && subcontentId) {
      getContent(organizationId, type, subcontentId)
        .then((response) => {
          setContentId(response.data.content_id);
          loadStatistics(response.data.content_id, 'day');
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading the content data',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        });
    }
  }, [loadStatistics, type, subcontentId, dispatch, t, organizationId]);

  return (
    <StyledPeriodStatisticsCard>
      <Card
        shadow={!loading && data.length === 0 ? 'false' : 'true'}
        noBorder="true"
      >
        <TabMenu tabs={periodOptions} onChangeTab={handleChangeMainTab} />
        <div className="info">
          <div className="left-side">
            <div className="description">
              <Tooltip
                content={description}
                id={`${'statistic-info'}-${title}`}
              >
                <Icon name="information-line" color="grayscale-200" />
              </Tooltip>
            </div>
            <div className="total-of">
              <Text as="h6" color="grayscale-200">
                {t('Total of')}
              </Text>
              <Text as="h4" color="grayscale-200" weight="700">
                {title}
              </Text>
            </div>
          </div>
          <div className="right-side">
            <Text as="h1" weight="700">
              {total} {isPercent ? '%' : ''}
            </Text>
          </div>
        </div>

        {statisticEntity !== 'reaction' && (
          <div className="chart-wrapper">
            <ResponsiveContainer width="100%" height={240}>
              <AreaChart
                data={data}
                margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={primaryColor}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={primaryColor}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="key"
                  tick={{ fill: '#B4B6CB' }}
                  stroke="#B4B6CB"
                />
                <ChartTooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={primaryColor}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}

        {isReaction && (
          <div className="reactions">
            <div className="counts">
              {data.map((item, index) => (
                <div className="reaction-count" key={index}>
                  <Text color="grayscale-200" className="reaction-label">
                    {item.label}
                  </Text>
                  <Image
                    width="35px"
                    src={
                      reactionImages[item.key as keyof typeof reactionImages]
                    }
                  />
                  <Text weight="700">{item.value}</Text>
                </div>
              ))}
            </div>
            <div className="info">
              <Text color="grayscale-200">{reactionPeriodLabel}</Text>
            </div>
          </div>
        )}

        {loading && (
          <div className="statistic-loading">
            <Loading />
          </div>
        )}

        {!loading && data.length === 0 && (
          <div className="no-data">
            <Text as="h6" color="grayscale-200">
              {t('No')}
            </Text>
            <Text as="h4" color="grayscale-200" weight="700">
              {statisticEntity}
            </Text>
          </div>
        )}
      </Card>
    </StyledPeriodStatisticsCard>
  );
};

export default PeriodStatisticsCard;
