import React from 'react';

import { useAppSelector } from 'store/hooks';

import { abbreviateNumberFormatter } from 'utils/numbers';

import { getI18NextLanguage } from 'services/language';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import { StyledMuralTableInteractions } from './styles';

interface MuralTableInteractionsProps {
  total: number;
  interaction: string;
}

const MuralTableInteractions: React.FC<MuralTableInteractionsProps> = ({
  total,
}) => {
  const storedLanguage = getI18NextLanguage();
  const language: string = useAppSelector((state) => state.client.language);

  return (
    <StyledMuralTableInteractions>
      <Icon name="emotion-line" color="grayscale-200" />
      <Text color="grayscale-400">
        {abbreviateNumberFormatter(total, storedLanguage || language)}
      </Text>
    </StyledMuralTableInteractions>
  );
};

export default MuralTableInteractions;
