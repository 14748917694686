import styled from 'styled-components';

export const StyledPublicationDateModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .separator {
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid var(--grayscale-100);
  }

  .tab-menu {
    margin-bottom: 20px;
    width: max-content;
  }
`;
