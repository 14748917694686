import styled from 'styled-components';

export const StyledCollaboratorsTableActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .text {
    max-width: 13ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-toggle {
    padding: 8px;
    border-radius: 50%;
  }

  .dropdown-content {
    border-radius: 6px;
    overflow: hidden;
    /* top: -48px; */

    .button {
      width: 100%;
      border-bottom: 1px solid var(--grayscale-300);
      justify-content: flex-start;

      &:last-child {
        border: none;
      }
    }
  }
`;
