import styled from 'styled-components';

export const StyledSizeFilter = styled.div`
  .datepicker-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .datepicker {
      width: 200px;
      margin-right: 16px;
    }
  }

  .select {
    .react-select__control {
      padding-right: 0px;

      .react-select__single-value {
        margin-top: 0px !important;
      }

      .size-option {
        padding: 0px;
      }
    }
  }

  @media (max-width: 368px) {
    .datepicker-wrapper {
      & > div {
        gap: 6px;
        flex-direction: column;

        input::placeholder {
          font-size: 16px;
        }

        .select {
          margin: 0px;
        }
      }
    }
  }
`;
