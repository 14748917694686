import styled from 'styled-components';

export const StyledAnonymousFilter = styled.div`
  border-bottom: 1px solid var(--grayscale-100);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 720px) {
    .select {
      width: 100% !important;
    }
  }
`;
