import styled from 'styled-components';

export const StyledImportItemButton = styled.div`
  width: 100%;
  padding: 20px 24px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--grayscale-100);
  cursor: pointer;
  transition: background 0.2s;

  &:hover,
  &.active {
    background-color: var(--gray-color);
  }

  &.active {
    cursor: initial;
    pointer-events: none;
  }

  .status-side {
    position: relative;
    .tag {
      width: 32px;
      height: 32px;
      padding: 0;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 10%;
    }
    .icon {
      font-size: 16px;
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  .file-name {
    margin-top: 2px;
    word-break: break-all;
    font-size: 14px;
  }
`;
