import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Header from 'components/atom/Header';
import Text from 'components/atom/Text';
import WelcomeImage from './components/WelcomeImage';

import { StyledDashboard } from './styles';
import { getMetabaseLandingDashboards } from 'apis/board';
import Loading from 'components/molecule/Loading';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState<string[]>([]);

  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  useEffect(() => {
    setLoading(true);
    getMetabaseLandingDashboards(organizationId)
      .then((response) => {
        const dashboards = response.data;
        setDashboards(dashboards);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while loading statistics'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, dispatch, organizationId]);

  return (
    <StyledDashboard>
      <Header
        leftSideContent={
          <div style={{ display: 'flex' }}>
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Dashboard')}
            </Text>
          </div>
        }
      />
      <div className="page-content">
        {loading && <Loading />}
        {!loading && dashboards.length === 0 && (
          <div className="welcome-message">
            <WelcomeImage />
            <Text as="h5" weight="700">
              {t('We have extra good news for you.')}
            </Text>
            <Text align="center" as="h6">
              {t(
                'Well, if you are at this page, you already noticed we have news… Yeah, that is it, welcome to New Useflow, our newest admin, that not only looks fresh - and even more beautiful - but brings a series of evolution that will boost your productivity and new features such as a brand-new experience on ultra-segmentation of the content you create. This very page will, soon, will host a dashboard that you will be able to customize to your need and preference! Is not amazing?',
              )}
            </Text>
            <Text align="center" as="h6">
              {t(
                'Our CS team should contact you to provide you with more details, clear any questions and schedule a new training session with your team of admins.',
              )}
            </Text>
          </div>
        )}
        {!loading && dashboards.length > 0 && (
          <div className="dashboards-wrapper">
            {dashboards.map((dashboardUrl, index) => (
              <iframe key={index} src={dashboardUrl} loading="eager" />
            ))}
          </div>
        )}
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
