import styled from 'styled-components';

export const StyledMove = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h4 {
      margin-bottom: 6px;
      padding: 32px 0px 0px 32px;
    }

    .subtitle-wrapper {
      padding-left: 32px;
    }
  }

  .modal-footer {
    padding: 0px 32px 32px 32px;
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      gap: 16px;
    }
  }

  .subtitle-wrapper {
    margin-top: 8px;
    display: flex;
    gap: 6px;
  }

  .location-wrapper {
    width: 100%;
    height: 78px;
    margin-top: 24px;
    background-color: var(--gray-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;

    & > div {
      display: flex;
      align-items: center;
      gap: 12px;

      div {
        padding: 10px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        p {
          margin-top: 3px;
        }
      }
    }

    .initial-location > div {
      background-color: var(--grayscale-100);
    }

    .final-location > div {
      border: 1px solid var(--grayscale-100);
    }
  }

  .folder-navigation-wrapper {
    padding: 32px;

    .input {
      margin-top: 12px;
      width: 200px;

      .input-content {
        padding: 0px;
        padding-left: 14px;
      }
    }
  }
`;
