import { MediaUrlResolverProps } from '../types';

const KALTURA_URL_REGEX = /^https?:\/\/api.flix.tupi.io\//;

export const isKalturaUrl = (url: string) => {
  return url && KALTURA_URL_REGEX.test(url);
};

export const resolveKalturaEmbed = ({
  data,
  resolve,
}: MediaUrlResolverProps) => {
  // language=html
  const html = `
    <iframe
      class="editor--embedded-video-container video-kaltura"
      style="width: calc(100% - 5px); height: 100%; aspect-ratio: 16/9"
      src="${data.url}"
      allowfullscreen="allowfullscreen">
    </iframe>
  `;
  resolve({ html });
};
