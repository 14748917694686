import styled from 'styled-components';

export const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);

  h3 {
    margin-right: 12px;
  }

  .header {
    padding: 22px 54px;
  }

  .page-content {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .welcome-message {
      margin-top: 100px;
      width: 100%;
      max-width: 520px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        max-width: 180px;
        height: auto;
        margin-bottom: 32px;
      }

      h5 {
        margin-bottom: 24px;
      }

      h6 {
        margin-bottom: 12px;
        line-height: 1.1;
      }
    }

    .dashboards-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 44px;
      iframe {
        width: 100%;
        height: 852px;
        overflow: hidden;
        margin-bottom: 32px;
      }
    }
  }
`;
