import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FAQ_URL } from 'settings';

import { useAppDispatch } from 'store/hooks';
import { OrganizationState } from 'store/slices/organization/types';
import { setModalView } from 'store/slices/modal';

import { useLegacyMenuOptions } from 'hooks/layout/useLegacyMenuOptions';
import { useHighlightMenuOptions } from 'hooks/layout/useHighlightMenuOptions';

import FastMenu from 'components/organism/FastMenu';
import Menu from 'components/organism/Menu';
import { FastMenuOptionProp } from 'components/organism/FastMenu/types';
import { MenuOptionProp } from 'components/organism/Menu/types';

import LegacyMenuRedirectModal from '../LegacyMenuRedirectModal';

interface OrganizationMenuProps {
  selectedOrganization: OrganizationState;
}

const OrganizationMenu: React.FC<OrganizationMenuProps> = ({
  selectedOrganization,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onClickLegacyMenuOption = (url: string) => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: <LegacyMenuRedirectModal redirectUrl={url} />,
        disableBackgroundClick: false,
        hideCloseButton: false,
      }),
    );
  };

  // Menu options
  const legacyMenuOptions: MenuOptionProp[] = useLegacyMenuOptions(
    selectedOrganization,
    onClickLegacyMenuOption,
  );
  const highlightOptions: MenuOptionProp[] = useHighlightMenuOptions();

  // Fast Menu options
  const topOptions: FastMenuOptionProp[] = useMemo(
    () => [
      {
        icon: 'home-6-fill',
        path: '/dashboard',
        modules: ['home'],
        disabled: false,
      },
      {
        icon: 'dashboard-fill',
        path: '/mural',
        modules: ['module_comm', 'module_advocacy'],
        disabled: false,
      },
    ],
    [],
  );

  const bottomOptions: FastMenuOptionProp[] = [
    // {
    //   icon: 'user-fill',
    //   path: '/dashboard',
    //   disabled: false,
    // },
    {
      icon: 'settings-4-fill',
      path: '/settings',
      disabled: false,
    },
    {
      icon: 'question-fill',
      href: FAQ_URL,
      disabled: false,
    },
  ];

  const [currentTopOptions, setCurrentTopOptions] = useState<
    FastMenuOptionProp[]
  >([]);

  const unshiftMenuOption = (
    optionList: FastMenuOptionProp[],
    path: string,
  ) => {
    const selectedOptionIndex = optionList.findIndex(
      (option) => option.path === path,
    );
    const [selectedOption] = optionList.splice(
      selectedOptionIndex,
      selectedOptionIndex !== -1 ? 1 : 0,
    );

    if (selectedOption) {
      optionList.unshift(selectedOption);
    }

    return [...optionList];
  };

  const handleAfterChangeOption = (to: string) => {
    setCurrentTopOptions((menuTopOptions) =>
      unshiftMenuOption(menuTopOptions, to),
    );
  };

  useEffect(() => {
    const newTopOptions: FastMenuOptionProp[] = [];
    topOptions.forEach((option, index) => {
      if (
        selectedOrganization.modules &&
        selectedOrganization.modules.some((module) =>
          option.modules?.some((item) => item === 'home' || item === module),
        )
      ) {
        newTopOptions.push(topOptions[index]);
      }
    });

    setCurrentTopOptions(unshiftMenuOption(newTopOptions, location.pathname));
  }, [selectedOrganization, topOptions, location.pathname]);

  return (
    <>
      <FastMenu topOptions={currentTopOptions} bottomOptions={bottomOptions} />
      <Menu
        highlightOptions={highlightOptions}
        options={legacyMenuOptions}
        organizationName={selectedOrganization.name}
        onAfterClick={handleAfterChangeOption}
      />
    </>
  );
};

export default OrganizationMenu;
