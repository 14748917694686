import React from 'react';

import Table from 'components/organism/Table';

import { FilesTableProps } from './types';

import { StyledFilesTable } from './styles';
import useFilesTable from './hook';

const FilesTable: React.FC<FilesTableProps> = ({
  files,
  filter,
  setFilter,
  fetchFiles,
  pageCount,
  loading,
  handleNavigate,
}) => {
  const { columns, fileTableRows, handleChangePage, setColumns } =
    useFilesTable({
      files,
      filter,
      handleNavigate,
      onFetchFiles: fetchFiles,
      setFilter,
    });
  return (
    <StyledFilesTable className={'file-table-wrapper'}>
      <Table
        columns={columns}
        setColumns={setColumns}
        rows={fileTableRows}
        scrollable
        textAlign="start"
        fontColor="grayscale-200"
        hasPagination={true}
        pageCount={pageCount}
        page={filter.page - 1}
        onChangePage={handleChangePage}
        loading={loading}
      />
    </StyledFilesTable>
  );
};

export default FilesTable;
