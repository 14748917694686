import styled from 'styled-components';

export const StyledSurveyQuestions = styled.div`
  .add-button {
    margin: 24px 0;
  }

  .question-container {
    margin-bottom: 24px;
  }
`;
