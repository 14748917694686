import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setModalView } from 'store/slices/modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { ResetInteractionsProps } from './types';

import { StyledResetInteractions } from './styles';
import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import PageLoading from 'components/molecule/PageLoading';
import { resetInteractions } from 'apis/board';

const ResetInteractions: React.FC<ResetInteractionsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.organization);
  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const [value, setValue] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleConfirm = () => {
    setLoading(true);

    resetInteractions(organizationId)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Interactions removed'),
            theme: 'success',
            icon: 'check',
            time: 10000,
          }),
        );
        dispatch(
          setModalView({
            show: false,
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while removing interactions.',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeValue = (value: string) => {
    setValue(value.toUpperCase());
  };

  const generateRandomHash = useCallback(() => {
    const hashLength = 8;
    const regex = /[A-Za-z0-9#$%&@]/;
    let hash = '';

    for (let i = 0; i < hashLength; i++) {
      const randomCharCode = Math.floor(Math.random() * (126 - 35) + 35);
      const randomChar = String.fromCharCode(randomCharCode);
      if (regex.test(randomChar)) {
        hash += randomChar;
      } else {
        i--;
      }
    }

    return hash;
  }, []);

  useEffect(() => {
    setCode(generateRandomHash().toUpperCase());
  }, [generateRandomHash]);

  return (
    <StyledResetInteractions>
      <div className="modal-content">
        <Text as="h5" weight="700" className="reset-title">
          {t('Attention!')}
        </Text>
        <Text as="p">
          {t(
            'Do you really want to reset your interactions from the organization {{organizationName}}? This action is irreversible!  Write the code below to continue.',
            {
              organizationName: name,
            },
          )}
        </Text>

        <div className="generated-code">{code}</div>

        <Input
          placeholder={t('Enter the code to continue')}
          value={value}
          onChange={(event) => handleChangeValue(event.target.value)}
        />
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          theme="danger-outline"
          onClick={handleConfirm}
          disabled={value !== code}
        >
          {t('Continue')}
        </Button>
      </div>

      {loading && <PageLoading />}
    </StyledResetInteractions>
  );
};

export default ResetInteractions;
