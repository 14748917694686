import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { dateAndTimeToApiDate } from 'utils/date';

import Text from 'components/atom/Text';
import DatePicker from 'components/molecule/DatePicker';

import { CustomFilterAttrsTypes } from '../../types';

import { StyledPeriodFilter } from './styles';

export interface PeriodFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const PeriodFilter: React.FC<PeriodFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const [minDate, setMinDate] = useState<string | undefined>(
    filter.start_at ? filter.start_at : undefined,
  );

  const handleChangePeriodBegin = (value: Date) => {
    setFilter({
      ...filter,
      start_at: dateAndTimeToApiDate(value),
      finish_at: null,
    });

    setMinDate(dateAndTimeToApiDate(value));
  };

  const handleChangePeriodEnd = (value: Date) => {
    setFilter({
      ...filter,
      finish_at: dateAndTimeToApiDate(value),
    });
  };

  return (
    <StyledPeriodFilter>
      <Text as="h5" color="grayscale-400" className="pt-9 pb-[10px]">
        {t('Dates')}
      </Text>
      <div className="datepicker-wrapper">
        <DatePicker
          id="start-at-period-begin"
          icon="calendar-2-fill"
          value={filter.start_at ? dayjs(filter.start_at).toDate() : undefined}
          onChange={handleChangePeriodBegin}
          label={t('Start of the cycle')}
        />
        <DatePicker
          id="start-at-period-end"
          icon="calendar-2-fill"
          value={
            filter.finish_at ? dayjs(filter.finish_at).toDate() : undefined
          }
          onChange={handleChangePeriodEnd}
          label={t('End of the cycle')}
          minDate={minDate ? dayjs(minDate).toDate() : undefined}
        />
      </div>
    </StyledPeriodFilter>
  );
};

export default PeriodFilter;
