import React, { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import Confirm from 'components/molecule/Confirm';

import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Dropdown from 'components/molecule/Dropdown';

import { PollOptionProps } from './types';

import { StyledPollOption } from './styles';

const PollOption: React.FC<PollOptionProps> = ({
  id,
  text,
  isCorrect,
  onChange,
  onRemove,
  onClear,
  onToggleSelection,
  disableRemove = false,
  status,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(text);
  const [textTimeOut, setTextTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const confirmRemove = () => {
    onRemove(id);
  };

  const handleRemove = () => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <Confirm
            title={t('Remove option')}
            subtitle={t('Are you sure you want to remove this option?')}
            onConfirm={confirmRemove}
          />
        ),
      }),
    );
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (textTimeOut) {
      clearTimeout(textTimeOut);
    }

    const timeOut = setTimeout(() => {
      onChange(event.target.value, id);
    }, 500);

    setValue(event.target.value);
    setTextTimeOut(timeOut);
  };

  const toggleSelection = () => {
    onToggleSelection(id);
  };

  const handleClear = () => {
    onClear(id);
  };

  useEffect(() => {
    setValue(() => text);
  }, [text]);

  return (
    <StyledPollOption className="poll-option">
      {!disableRemove && (status === '' || status === 'draft') && (
        <Dropdown
          open={open}
          setOpen={setOpen}
          toggleElement={<Icon name="more-2" />}
          toggleButtonTheme="link-page-button-gray-primary"
          dropdownContentColor="white-color"
          contentPlace="left"
        >
          <Button
            theme="link-danger"
            leftIcon="delete-bin-6-line"
            radius="0px"
            onClick={handleRemove}
          >
            {t('Remove')}
          </Button>
        </Dropdown>
      )}
      <Input
        theme="gray"
        value={value}
        onChange={handleChangeText}
        placeholder={`${t('Option')} *`}
        disabled={disabled}
        actions={
          value &&
          !disabled && (
            <Button size="small" theme="link-danger" onClick={handleClear}>
              <Icon name="indeterminate-circle-line" />
            </Button>
          )
        }
        limit={150}
        characterCountType="unfocused"
      />
      <Tooltip
        id={`tooltip-option-1-${isCorrect}`}
        key={`tooltip-option-1-${isCorrect}`}
        content={isCorrect ? t('Undo') : t('Set right answer')}
        place="right"
        disabled={status !== '' && status !== 'draft'}
      >
        <Button
          size="small"
          theme="gray-success"
          className={`button-check ${isCorrect ? 'is-correct' : ''}`}
          onClick={toggleSelection}
          disabled={(status !== '' && status !== 'draft') || disabled}
        >
          <Icon name="check" />
        </Button>
      </Tooltip>
    </StyledPollOption>
  );
};

export default memo(PollOption);
