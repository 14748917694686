import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';

import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import Confirm from 'components/molecule/Confirm';
import PageLoading from 'components/molecule/PageLoading';

import { FinalizeSurveyProps } from './types';

import { StyledFinalizeSurvey } from './styles';
import { finalizeSurvey } from 'apis/survey';

const FinalizeSurvey: React.FC<FinalizeSurveyProps> = ({
  surveyId,
  organizationId,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleFinalizeSurvey = () => {
    finalizeSurvey(organizationId, surveyId)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Survey was finished'),
            theme: 'success',
            icon: 'check',
            time: 10000,
            customLeft: '0px',
          }),
        );

        onSave();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while finalize the survey'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(closeModal());
      });
  };

  return (
    <StyledFinalizeSurvey>
      {loading && <PageLoading />}
      <Confirm
        title={t('Finalize survey')}
        subtitle={t(
          'The survey will be finalized immediately, and no further responses will be accepted. If there are recurrences, they will be maintained. This action is irreversible.',
        )}
        confirmLabel={t('Confirm')}
        onConfirm={handleFinalizeSurvey}
      />
    </StyledFinalizeSurvey>
  );
};

export default FinalizeSurvey;
