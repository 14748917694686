import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import ProgressBar from 'components/atom/ProgressBar';
import Text from 'components/atom/Text';

import { cn } from 'utils/cn';

import { FileUploadListProps, UploadedFileProps } from './types';

import { StyledFileUploadList } from './styles';
import { AvailableColorTypes } from 'styles/global-styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  cancelUpload,
  closeUploadList,
  setUploadListIsExpanded,
} from 'store/slices/drive';

const UploadedFile: React.FC<UploadedFileProps> = ({
  className = '',
  onCancel,
  percent,
  status,
  title,
}) => {
  const currentProgressColor = useMemo(() => {
    const statusColors = {
      pending: 'primary-color',
      'in-progress': 'primary-color',
      error: 'danger-color',
      success: 'primary-color',
    };

    return statusColors[status];
  }, [status]);

  const currentTitleColor = useMemo(() => {
    const statusColors = {
      pending: 'dark-color',
      'in-progress': 'dark-color',
      error: 'danger-color',
      success: 'dark-color',
    };

    return statusColors[status];
  }, [status]);

  return (
    <div className={cn('min-h-[56px]', className)}>
      <Text
        color={currentTitleColor as keyof typeof AvailableColorTypes}
        className="max-w-[280px] truncate"
      >
        {title}
      </Text>
      <div className="flex items-center gap-2">
        <ProgressBar
          completed={percent}
          color={currentProgressColor as keyof typeof AvailableColorTypes}
        />
        {status === 'in-progress' && (
          <Button
            theme="gray-primary"
            rounded="true"
            size="small"
            onClick={() => onCancel('opa')}
          >
            <Icon name="close" />
          </Button>
        )}
        {status === 'error' && (
          <div className="h-10 w-10 min-w-[40px] flex items-center justify-center rounded-full bg-gray-color">
            <Icon name="alert-fill" color="danger-color" />
          </div>
        )}

        {status === 'success' && (
          <div className="h-10 w-10 min-w-[40px] flex items-center justify-center rounded-full bg-gray-color">
            <Icon name="check" color="success-color" />
          </div>
        )}

        {status === 'pending' && (
          <div className="h-10 w-10 min-w-[40px] flex items-center justify-center rounded-full bg-gray-color">
            <Icon name="clock-outline" color="grayscale-200" />
          </div>
        )}
      </div>
    </div>
  );
};

const FileUploadList: React.FC<FileUploadListProps> = ({ className = '' }) => {
  const { t } = useTranslation();
  const {
    uploaded_files: storedUploadedFiles,
    close_button_is_visible: closeButtonIsVisible,
    upload_list_is_expanded: expanded,
  } = useAppSelector((state) => state.drive);
  const dispatch = useAppDispatch();

  const totalSendingFiles = useMemo(() => {
    return storedUploadedFiles.filter((file) =>
      ['pending', 'in-progress'].includes(file.status),
    ).length;
  }, [storedUploadedFiles]);

  const totalSendedFiles = useMemo(() => {
    return storedUploadedFiles.filter((file) =>
      ['success'].includes(file.status),
    ).length;
  }, [storedUploadedFiles]);

  const headerMessage = useMemo(() => {
    if (totalSendingFiles > 0) {
      return t('Sending files ({{total}})', {
        total: totalSendingFiles,
      });
    }
    return t('{{total}} files sended', {
      total: totalSendedFiles,
      count: totalSendedFiles,
    });
  }, [t, totalSendedFiles, totalSendingFiles]);

  return (
    <StyledFileUploadList
      className={cn(
        'bg-white-color shadow-lg rounded-xl rounded-ee-none rounded-es-none overflow-hidden min-w-[400px]',
        className,
      )}
    >
      <div className="bg-dark-color flex items-center justify-between py-4 px-6 gap-12">
        <p className="text-white-color text-xl">{headerMessage}</p>
        <div className="flex items-center gap-2">
          <Button
            theme="gray-primary"
            rounded="true"
            size="small"
            onClick={() => dispatch(setUploadListIsExpanded(!expanded))}
            className="mr-1.5"
          >
            <Icon name={expanded ? 'arrow-down-s-line' : 'arrow-up-s-line'} />
          </Button>
          {closeButtonIsVisible && (
            <Button
              theme="gray-primary"
              rounded="true"
              size="small"
              onClick={() => dispatch(closeUploadList())}
            >
              <Icon name="close" />
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col gap-4 default-scroll max-h-96 overflow-y-scroll',
          { 'h-0': !expanded },
          { 'py-4 px-6': expanded },
        )}
      >
        {storedUploadedFiles.map((storedFile) => (
          <UploadedFile
            key={storedFile.id}
            title={storedFile.title}
            percent={storedFile.progress}
            status={
              storedFile.progress > 0 &&
              storedFile.progress <= 100 &&
              !['error', 'success'].includes(storedFile.status)
                ? 'in-progress'
                : storedFile.status
            }
            onCancel={() => dispatch(cancelUpload())}
          />
        ))}
      </div>
    </StyledFileUploadList>
  );
};

export default FileUploadList;
