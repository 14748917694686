import styled from 'styled-components';

export const StyledDrivePostContent = styled.div`
  .url {
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
    .input-content {
      padding: 0px;

      input {
        font-size: 38px;
      }
    }
  }

  .description {
    .textarea-content {
      padding: 0px;
    }
  }
`;
