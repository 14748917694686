import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ApiVersions, getBaseAPIUrl } from 'settings';

import { getI18NextLanguage } from 'services/language';

import {
  createImport,
  getImport,
  getLastImportInProgress,
  getImportHistory,
} from 'apis/import';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import Breadcrumb from 'components/organism/Breadcrumb';
import Text from 'components/atom/Text';
import ImportItemButton from './components/ImportItemButton';
import Tag from 'components/atom/Tag';
import Loading from 'components/molecule/Loading';
import FileUploader from 'components/molecule/FileUploader';
import Select from 'components/molecule/Select';
import ImportPreviewCard from 'components/organism/ImportPreviewCard';
import ImportErrorItem from './components/ImportErrorItem';
import InfiniteScroll from 'components/atom/InfiniteScroll';

import { SingleSelectedOption } from 'components/molecule/Select/types';
import { ImportTypes } from 'apis/import/types';
import { ImportPreviewCardStatus } from 'components/organism/ImportPreviewCard/types';
import { ImportItemButtonStatus } from './components/ImportItemButton/types';
import { ImportHistoryItemProps, ImportError } from './types';

import { StyledCollaboratorsImport } from './styles';

const CollaboratorsImport: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentImportIdRef = useRef<string | null>();

  const globalType = 'accounts';

  const {
    avatar,
    name,
    client,
    pk: organizationId,
  } = useAppSelector((state) => state.organization);

  const uploadCategoryOptions = useMemo(
    () => [
      {
        label: <Text className="upload-category-option">{t('Add users')}</Text>,
        value: 'accounts',
      },
      {
        label: (
          <Text className="upload-category-option">{t('Update users')}</Text>
        ),
        value: 'update_accounts',
      },
      {
        label: (
          <Text className="upload-category-option">{t('Remove users')}</Text>
        ),
        value: 'inactive_accounts',
      },
    ],
    [t],
  );

  const apiImportStatus = useMemo(
    () => ({
      in_progress: 'in-progress',
      error: 'error',
      done: 'success',
    }),
    [],
  );

  const importTypes = useMemo(
    () => ({
      accounts: t('New users'),
      update_accounts: t('Update users'),
      inactive_accounts: t('Remove users'),
      departments: t('New departments'),
      profiles: t('New positions'),
    }),
    [t],
  );

  const [selectedUploadCategory, setSelectedUploadCategory] =
    useState<SingleSelectedOption>(uploadCategoryOptions[0]);

  const [inProgressMessage, setInProgressMessage] = useState('');
  const [importStatus, setImportStatus] = useState<string | null>(null);
  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const [currentImportId, setCurrentImportId] = useState(null);
  const [userName, setUserName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [errors, setErrors] = useState<ImportError[]>([]);
  const [currentCheckImportInterval, setCurrentCheckImportInterval] =
    useState<ReturnType<typeof setInterval>>();

  const [loadImportLoading, setLoadImportLoading] = useState(true);
  const [errorsIsOpen, setErrorsIsOpen] = useState(false);

  // history states
  const [items, setItems] = useState<ImportHistoryItemProps[]>([]);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);

  const handleBack = () => {
    navigate('/collaborators/settings');
  };

  const loadImportHistory = useCallback(
    (page: number, importId: string | null) => {
      getImportHistory(organizationId, globalType, page)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);

          if (page > 1) {
            setItems((lastItems) => [
              ...lastItems,
              ...response.data.results.map((item: ImportHistoryItemProps) => {
                item.active = item.id === importId;
                return item;
              }),
            ]);
            return;
          }

          setItems(
            response.data.results.map((item: ImportHistoryItemProps) => {
              item.active = item.id === importId;
              return item;
            }),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading history'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, organizationId, t],
  );

  const loadImport = useCallback(
    (importId: string) => {
      setLoadImportLoading(true);

      getImport(organizationId, importId)
        .then((response) => {
          const {
            id,
            name,
            filename,
            created_at,
            status,
            file,
            errors,
            import_type,
          } = response.data;

          setCurrentImportId(id);
          currentImportIdRef.current = id;
          setUserName(name);
          setCreatedAt(created_at);
          setImportStatus(
            apiImportStatus[status as keyof typeof apiImportStatus],
          );
          setFileUrl(file);
          setFileName(filename);

          const selectedImportType = uploadCategoryOptions.find(
            (option) => option.value === import_type,
          );
          if (selectedImportType) {
            setSelectedUploadCategory(selectedImportType);
          }

          setErrors(errors);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the import'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoadImportLoading(false);
        });
    },
    [apiImportStatus, organizationId, dispatch, t, uploadCategoryOptions],
  );

  const checkImportIsDone = useCallback(
    (lastImportId: string) => {
      if (currentCheckImportInterval) {
        clearTimeout(currentCheckImportInterval);
      }

      const interval = setInterval(() => {
        getLastImportInProgress(organizationId, globalType).then((response) => {
          if (!response.data.id) {
            getImportHistory(organizationId, globalType, 1).then((response) => {
              const { results } = response.data;
              const firstHistoryItem = results[0];

              dispatch(
                showMessage({
                  title:
                    firstHistoryItem.status === 'done'
                      ? t('Import completed')
                      : t('Import failed'),
                  theme:
                    firstHistoryItem.status === 'done' ? 'success' : 'danger',
                  icon: firstHistoryItem.status === 'done' ? 'check' : 'close',
                  time: 10000,
                }),
              );

              setItems((lastItems) => {
                return lastItems.map((item) => {
                  if (item.id === lastImportId) {
                    item.status = firstHistoryItem.status;
                    item.created_at = firstHistoryItem.created_at;
                  }
                  return item;
                });
              });

              setIsImportInProgress(false);

              if (firstHistoryItem.id === currentImportIdRef.current) {
                loadImport(firstHistoryItem.id);
              }
            });

            clearInterval(interval);
          }
        });
      }, 10000);

      setCurrentCheckImportInterval(interval);
    },
    [dispatch, t, currentCheckImportInterval, organizationId, loadImport],
  );

  const checkImportInProgress = useCallback(() => {
    getLastImportInProgress(organizationId, globalType).then((response) => {
      const { id } = response.data;

      if (id) {
        loadImport(id);
        setCurrentImportId(id);
        currentImportIdRef.current = id;
        setIsImportInProgress(true);
        loadImportHistory(1, id);
        checkImportIsDone(id);
        return;
      }

      setCurrentImportId(null);
      currentImportIdRef.current = null;
      setLoadImportLoading(false);
      setIsImportInProgress(false);
      loadImportHistory(1, null);
    });
  }, [organizationId, loadImport, loadImportHistory, checkImportIsDone]);

  const handleUploadSheet = (file: File) => {
    setImportStatus('in-progress');
    createImport(
      organizationId,
      {
        file,
        import_type: selectedUploadCategory?.value as keyof typeof ImportTypes,
      },
      (progress) => {
        setInProgressMessage(
          t('Upload in progress {{progress}}%', {
            progress,
          }),
        );
      },
    )
      .then((response) => {
        const { id } = response.data;

        setCurrentImportId(id);
        currentImportIdRef.current = id;
        setIsImportInProgress(true);
        loadImport(id);
        loadImportHistory(1, id);
        checkImportIsDone(id);
      })
      .catch((error) => {
        setImportStatus(null);

        if (error.response.status === 400) {
          dispatch(
            showMessage({
              title: t('There is already an import in progress'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          checkImportInProgress();
          return;
        }

        dispatch(
          showMessage({
            title: t('An unexpected error occurred while uploading the file'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setInProgressMessage('');
      });
  };

  const handleHistoryItemClick = (id: string) => {
    loadImport(id);
    setItems((lastItems) =>
      lastItems.map((item) => {
        item.active = item.id === id;
        return item;
      }),
    );
  };

  const getErrorRow = (row: number | null) => {
    if (!row) return null;
    return t('Line {{line_number}}', {
      line_number: row,
    });
  };

  const handleNewImportClick = () => {
    setImportStatus(null);
    setCurrentImportId(null);
    setFileUrl('');
    setFileName('');
    currentImportIdRef.current = null;
    setErrors([]);
    setItems((lastItems) =>
      lastItems.map((item) => {
        item.active = false;
        return item;
      }),
    );
    setSelectedUploadCategory(uploadCategoryOptions[0]);
  };

  useEffect(() => {
    if (items.length === 0) {
      checkImportInProgress();
    }
  }, [checkImportInProgress, loadImportHistory, items.length]);

  useEffect(() => {
    return () => {
      if (currentCheckImportInterval) {
        clearInterval(currentCheckImportInterval);
      }
    };
  }, [currentCheckImportInterval]);

  return (
    <StyledCollaboratorsImport className="account-import-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <Header
        className="secondary-header"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              {
                label: t('Collaborators'),
                action: () => navigate('/collaborators/settings'),
              },
              { label: t('Import workers'), action: () => navigate('') },
            ]}
          />
        }
      />
      <div className="content-container">
        <div className="content-side default-scroll">
          <div className="content-wrapper">
            <Text as="h3" weight="700" className="title">
              {t('File upload')}
            </Text>
            <Text color="grayscale-200" className="description">
              {t('Create, edit or remove users by uploading a file')}
            </Text>

            {!loadImportLoading && (
              <div className="import-actions">
                <Text weight="700" as="h6">
                  {t('Import type')}
                </Text>
                <Select
                  options={uploadCategoryOptions}
                  value={selectedUploadCategory}
                  setValue={(option) =>
                    setSelectedUploadCategory(option as SingleSelectedOption)
                  }
                  size="small"
                />
                <Button
                  leftIcon="download"
                  theme="primary-outline"
                  rounded="true"
                  href={`${getBaseAPIUrl(
                    ApiVersions.current,
                  )}/admin/org/${organizationId}/mass_import/template/${
                    selectedUploadCategory?.value
                  }/?language=${getI18NextLanguage()}`}
                  target="_blank"
                >
                  {t('Download template')}
                </Button>
                <Button
                  theme="link-primary"
                  leftIcon="replay"
                  disabled={isImportInProgress || !importStatus}
                  onClick={handleNewImportClick}
                >
                  {t('New import')}
                </Button>
              </div>
            )}

            {!loadImportLoading && !importStatus && (
              <FileUploader
                fileTypes={['XLS', 'XLSX', 'CSV']}
                onChange={handleUploadSheet}
              />
            )}

            {!loadImportLoading && importStatus && (
              <ImportPreviewCard
                status={importStatus as keyof typeof ImportPreviewCardStatus}
                inProgressMessage={inProgressMessage}
                userName={userName}
                createdAt={createdAt ? getDateFromApiDate(createdAt) : ''}
                createdAtTime={createdAt ? getTimeFromApiDate(createdAt) : ''}
                fileUrl={fileUrl}
                fileName={fileName}
              />
            )}

            {loadImportLoading && (
              <Loading type="spin" className="import-content-loading" />
            )}

            {!loadImportLoading && (
              <div className="import-errors">
                {errors.map((error, index) => (
                  <ImportErrorItem
                    key={index}
                    row={getErrorRow(error.row)}
                    errors={error.errors}
                    isOpen={errorsIsOpen}
                    setIsOpen={() => setErrorsIsOpen(!errorsIsOpen)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="menu-side">
          <div className="import-menu-header">
            <Text as="h4" weight="700">
              {t('Upload history')}
            </Text>
          </div>
          <div
            className="import-menu-content default-scroll"
            ref={(currentRef) => setScrollElement(currentRef)}
          >
            <div className="import-button-list">
              {items.map((item, index) => (
                <ImportItemButton
                  key={index}
                  date={t('{{created_at}} at {{created_at_time}}', {
                    created_at: getDateFromApiDate(item.created_at),
                    created_at_time: getTimeFromApiDate(item.created_at),
                  })}
                  uploadedBy={item.uploaded_by}
                  status={
                    apiImportStatus[
                      item.status as keyof typeof apiImportStatus
                    ] as keyof typeof ImportItemButtonStatus
                  }
                  type={
                    importTypes[item.import_type as keyof typeof importTypes]
                  }
                  onClick={() => {
                    handleHistoryItemClick(item.id);
                  }}
                  active={item.active}
                />
              ))}

              {!loading && items.length === 0 && (
                <div className="no-file-send">
                  <div className="status-icon">
                    <Tag theme="support-pastel-2" radius="6px" />
                    <Icon name="file" color="support-pastel-color-2" />
                  </div>
                  <Text color="grayscale-200">{t('No file uploaded')}</Text>
                </div>
              )}

              {loading && (
                <Loading type="spin" className="import-buttons-loading" />
              )}

              {loadMoreLoading && (
                <Loading
                  type="bubbles"
                  className="load-more-import-buttons-loading"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadImportHistory(currentPage + 1, currentImportId);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledCollaboratorsImport>
  );
};

export default CollaboratorsImport;
