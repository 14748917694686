import styled from 'styled-components';

export const StyledHierarchyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    margin-bottom: 6px;
    width: 100%;
  }

  & > p {
    word-break: break-all;
    margin-bottom: 32px;
    width: 100%;
  }

  .search-wrapper {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    align-items: center;

    .input {
      margin-right: 8px;
    }

    .text {
      min-width: max-content;
    }
  }

  .list {
    width: 100%;
    max-height: 272px;
    overflow-y: scroll;

    .item {
      word-break: break-all;
      width: 100%;
      padding: 16px;
      border-bottom: 1px solid var(--grayscale-100);

      &:first-child {
        border-top: 1px solid var(--grayscale-100);
      }
    }
  }

  .loading {
    margin-top: 32px;
  }
`;
