import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';
import Icon from 'components/atom/Icon';
import Input from 'components/molecule/Input';

import {
  preventNonNumericInput,
  enforceIntegerAndDecimalLimit,
} from 'utils/numbers';

import { StyledNumericOptions } from './styles';
import { NumericOptionsProps } from './types';

const INTEGER_LIMIT = 7;
const DECIMAL_PLACES_LIMIT = 2;

const NumericOptions: React.FC<NumericOptionsProps> = ({
  config,
  name,
  setName,
  item,
  setItem,
}) => {
  const { t } = useTranslation();

  const getValuePlaceholder = (input: string) => {
    if (!item.id) {
      if (input === 'min') {
        return config?.decimal_min_value
          ? config.decimal_min_value.toString()
          : '0';
      } else if (input === 'max') {
        return config?.decimal_max_value
          ? config.decimal_max_value.toString()
          : '0';
      }
    } else {
      return t('Not defined');
    }
  };

  return (
    <StyledNumericOptions>
      <div className="field-section-wrapper">
        <Text as="h6">{t('Field name')}</Text>
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={t('Enter the title')}
          limit={100}
        />
      </div>
      <div className="threshold-wrapper">
        <div>
          <div className="value-label-wrapper">
            <Text as="h6">{t('Minimum value')}</Text>
            <div>
              <Tooltip
                id="minimum-value-tooltip"
                content={t(
                  'Use a dot for number separator. The number should be up to 7 digits and 2 decimal places.',
                )}
                place="top"
              >
                <Icon name="information-line" color="grayscale-200" />
              </Tooltip>
            </div>
          </div>
          <Input
            type="number"
            placeholder={getValuePlaceholder('min')}
            value={
              item.decimal_min_value
                ? parseFloat(item.decimal_min_value?.toString())
                : item.decimal_min_value?.toString()
            }
            onChange={(event) => {
              const currentValue = enforceIntegerAndDecimalLimit(
                event.target.value,
                INTEGER_LIMIT,
                DECIMAL_PLACES_LIMIT,
              );
              setItem((prevItem) => ({
                ...prevItem,
                decimal_min_value: currentValue,
                decimal_min_value_invalid_msg: '',
              }));
            }}
            onKeyDown={preventNonNumericInput}
            hasError={item.decimal_min_value_invalid_msg !== ''}
            errorMessage={item.decimal_min_value_invalid_msg}
            disabled={!!item.id}
          />
        </div>
        <div>
          <div className="value-label-wrapper">
            <Text as="h6">{t('Maximum value')}</Text>
            <div>
              <Tooltip
                id="maximum-value-tooltip"
                content={t(
                  'Use a dot for number separator. The number should be up to 7 digits and 2 decimal places.',
                )}
                place="top"
              >
                <Icon name="information-line" color="grayscale-200" />
              </Tooltip>
            </div>
          </div>
          <Input
            type="number"
            placeholder={getValuePlaceholder('max')}
            value={
              item.decimal_max_value
                ? parseFloat(item.decimal_max_value?.toString())
                : item.decimal_max_value?.toString()
            }
            onChange={(event) => {
              const currentValue = enforceIntegerAndDecimalLimit(
                event.target.value,
                INTEGER_LIMIT,
                DECIMAL_PLACES_LIMIT,
              );
              setItem((prevItem) => ({
                ...prevItem,
                decimal_max_value: currentValue,
              }));
            }}
            onKeyDown={preventNonNumericInput}
            disabled={!!item.id}
          />
        </div>
      </div>
    </StyledNumericOptions>
  );
};

export default NumericOptions;
