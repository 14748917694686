import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

const useVisualCustomization = () => {
  const navigate = useNavigate();

  const { pk, avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const handleBack = () => {
    navigate('/settings');
  };

  return {
    orgInfo: {
      pk,
      avatar,
      name,
      client,
    },
    handleBack,
  };
};

export default useVisualCustomization;
