import dayjs from 'dayjs';

import { schedulerInitialState } from './components/PublicationDateModal/components/RecurrenceConfig/utils';

import { BoardContentFields, BoardContentGeneralFields } from './types';
import { PostContentFields } from './components/PostContents/types';
import { SurveyContentFields } from './components/SurveyContents/types';
import { CheckinContentFields } from './components/CheckinContents/types';
import { SchedulerData } from 'apis/board/types';

export const contentStateFactory = (
  contentFields: BoardContentFields,
  generalStates: BoardContentGeneralFields,
) => {
  const {
    cover,
    finish_at,
    finish_at_time,
    start_at,
    start_at_time,
    title,
    scheduler_start_date_at,
    scheduler_finish_date_at,
    content_start_time_at,
    content_finish_time_at,
    content_duration,
    content_week_days,
    scheduler_var,
    scheduler_type,
  } = contentFields;

  const {
    can_comment,
    can_reaction,
    gamification,
    notifications,
    parent,
    segmentations,
    status,
    tags,
    total_tags,
    tag_ids,
    type,
    voting_is_enabled,
    voting_message,
    with_recurrence,
    editorial,
    random_options,
    anonymous_answers,
  } = generalStates;

  switch (type) {
    case 'short': {
      return {
        cover,
        title,
        text: (contentFields as PostContentFields).text,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        voting_is_enabled,
        voting_message,
        with_recurrence,
        editorial,
      };
    }
    case 'url': {
      return {
        cover,
        title,
        description: (contentFields as PostContentFields).description,
        url: (contentFields as PostContentFields).url,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
      };
    }
    case 'drive': {
      return {
        cover,
        title,
        description: (contentFields as PostContentFields).description,
        url: (contentFields as PostContentFields).url,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
      };
    }
    case 'article': {
      return {
        cover,
        title,
        description: (contentFields as PostContentFields).description,
        text: (contentFields as PostContentFields).text,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        voting_is_enabled,
        voting_message,
        with_recurrence,
        editorial,
      };
    }
    case 'nps': {
      return {
        cover,
        title,
        description: (contentFields as SurveyContentFields).description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        nps_first_label: (contentFields as SurveyContentFields).nps_first_label,
        nps_last_label: (contentFields as SurveyContentFields).nps_last_label,
        nps_first_value: (contentFields as SurveyContentFields).nps_first_value,
        nps_last_value: (contentFields as SurveyContentFields).nps_last_value,
        nps_scale: (contentFields as SurveyContentFields).nps_scale,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
        anonymous_answers,
      };
    }
    case 'image': {
      return {
        cover,
        title,
        description: (contentFields as SurveyContentFields).description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
        anonymous_answers,
      };
    }
    case 'message': {
      return {
        cover,
        title,
        description: (contentFields as SurveyContentFields).description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
        anonymous_answers,
      };
    }
    case 'poll':
    case 'single':
    case 'multi': {
      return {
        cover,
        title,
        description: (contentFields as SurveyContentFields).description,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        subtype: (contentFields as SurveyContentFields).subtype,
        options: (contentFields as SurveyContentFields).options,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
        random_options,
        anonymous_answers,
      };
    }
    case 'checkin': {
      return {
        cover,
        title,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        lat: (contentFields as CheckinContentFields).lat,
        lng: (contentFields as CheckinContentFields).lng,
        location: (contentFields as CheckinContentFields).location,
        with_recurrence,
        editorial,
      };
    }
    default:
      return {
        cover,
        title,
        text: (contentFields as PostContentFields).text,
        start_at,
        start_at_time,
        finish_at,
        finish_at_time,
        scheduler_start_date_at,
        scheduler_finish_date_at,
        content_start_time_at,
        content_finish_time_at,
        content_duration,
        content_week_days,
        scheduler_var,
        scheduler_type,
        type,
        status,
        can_comment,
        can_reaction,
        tags,
        total_tags,
        tag_ids,
        segmentations,
        gamification,
        notifications,
        parent,
        with_recurrence,
        editorial,
      };
  }
};

export const contentSubtypes = [
  'short',
  'article',
  'url',
  'drive',
  'nps',
  'image',
  'message',
  'poll',
  'checkin',
];

export const parseSchedulerDataToStateFormat = (
  schedulerData: SchedulerData | null,
) => {
  if (!schedulerData) {
    return { ...schedulerInitialState };
  }

  return {
    scheduler_start_date_at: {
      ...schedulerInitialState.scheduler_start_date_at,
      value: dayjs(schedulerData.scheduler_start_date_at).toDate(),
    },
    scheduler_finish_date_at: {
      ...schedulerInitialState.scheduler_finish_date_at,
      value: schedulerData.scheduler_finish_date_at
        ? dayjs(schedulerData.scheduler_finish_date_at).toDate()
        : undefined,
    },
    content_start_time_at: {
      ...schedulerInitialState.content_start_time_at,
      value: schedulerData.content_start_time_at,
    },
    content_finish_time_at: {
      ...schedulerInitialState.content_finish_time_at,
      value: schedulerData.content_finish_time_at,
    },
    content_duration: {
      ...schedulerInitialState.content_duration,
      value: schedulerData.content_duration.toString(),
    },
    content_week_days: {
      ...schedulerInitialState.content_week_days,
      value: schedulerData.content_week_days,
    },
    scheduler_var: {
      ...schedulerInitialState.scheduler_var,
      value: schedulerData.scheduler_var,
    },
    scheduler_type: {
      ...schedulerInitialState.scheduler_type,
      value: schedulerData.scheduler_type,
    },
  };
};
