import React from 'react';

import { StyledFilesTableShared } from './styles';
import Icon from 'components/atom/Icon';

interface FilesTableSharedProps {
  isShared: boolean;
}

const FilesTableShared: React.FC<FilesTableSharedProps> = ({ isShared }) => {
  return (
    <StyledFilesTableShared>
      <Icon
        name="check-double"
        color={isShared ? 'success-color' : 'grayscale-200'}
      />
    </StyledFilesTableShared>
  );
};

export default FilesTableShared;
