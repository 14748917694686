import styled from 'styled-components';

export const StyledCircularProgressBar = styled.div`
  width: 54px;
  height: 54px;

  .CircularProgressbar-text {
    font-size: 20px;
    font-weight: 700;
  }
`;
