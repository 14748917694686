import React from 'react';

import Tag from 'components/atom/Tag';

import { TagGrouperProps } from './types';

import { StyledTagGrouper } from './styles';

const TagGrouper: React.FC<TagGrouperProps> = ({
  tags,
  tagTheme = 'default',
  total,
  characterLimit,
  displayLimit,
}) => {
  const tagsToShow = tags.filter((_, index) => {
    if (!displayLimit) {
      return true;
    }

    if (displayLimit && index + 1 <= displayLimit) {
      return true;
    }

    return false;
  });

  const showMoreIndicator = () => {
    if (displayLimit && total > displayLimit) {
      return <Tag theme={tagTheme}>+{total - tagsToShow.length}</Tag>;
    }

    return '';
  };

  return (
    <StyledTagGrouper className="tag-grouper" characterLimit={characterLimit}>
      {tagsToShow.map((tag, index) => (
        <Tag key={index} theme={tagTheme}>
          {tag}
        </Tag>
      ))}
      {showMoreIndicator()}
    </StyledTagGrouper>
  );
};

export default TagGrouper;
