import styled from 'styled-components';

export const StyledSmartPushsTable = styled.div`
  width: 100%;

  .table {
    width: 100%;
    margin-top: 0px;
    .table-wrapper {
      width: 100%;
      padding-bottom: 44px;
      table {
        width: 100%;
        tbody {
          tr {
            td {
              .tooltip {
                display: flex;
                justify-content: center;
              }

              &:first-child {
                min-width: max-content;
                .tooltip {
                  justify-content: flex-start;
                  .text {
                    max-width: 37ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }

              &:nth-child(2) {
                min-width: max-content;
                .tooltip {
                  justify-content: flex-start;
                  .text {
                    max-width: 37ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }

              &:nth-last-child(3) {
                min-width: max-content;
                .tooltip {
                  justify-content: flex-start;
                  .text {
                    max-width: 37ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }

              &:nth-last-child(2) {
                min-width: 140px;
                max-width: 160px;
              }

              &:nth-last-child(1) {
                .text {
                  max-width: 25ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .pagination-control {
      margin-top: 16px;
    }
  }
`;
