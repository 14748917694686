import styled from 'styled-components';

export const StyledOrganizationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid var(--grayscale-100);
  text-align: start;

  .image {
    margin-right: 14px;
  }
`;
