import styled from 'styled-components';

export const StyledImportErrorItem = styled.div`
  width: 100%;

  .collapse-wrapper {
    padding: 12px 16px;
    border-top: 1px solid var(--gray-color);

    .collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;

      .left-side {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 16px;
        }
        .info {
          padding-right: 8px;

          gap: 6px;
          display: flex;
          .text {
            margin-top: 4px;
            display: block;
            word-break: break-all;
            font-size: 14px;
          }
        }
      }

      .button.link-dark {
        .button-content {
          .icon {
            color: var(--danger-color);
          }
          .text {
            font-weight: 700;
          }
        }
      }

      .button.link-dark:disabled *,
      .button.link-dark.disabled * {
        color: initial;
      }
    }

    .collapse-content {
      & > div {
        padding: 0px;
        .text {
          padding-top: 8px;
        }
      }
    }
  }

  /*  */

  /* .actions {
    .checkbox {
      height: 24px;
      overflow: hidden;
      input ~ .checkmark {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid var(--grayscale-100);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input:checked ~ .checkmark {
        background-color: var(--success-color);
        border-color: var(--success-color);
      }

      .checkmark:after {
        position: relative;
        top: -1.5px;
        left: 0;
      }
    }
  } */
`;
