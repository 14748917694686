import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Alert from 'components/molecule/Alert';

import { StyledLegacyMenuRedirectModal } from './styles';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';

interface LegacyMenuRedirectModalProps {
  redirectUrl: string;
}

const LegacyMenuRedirectModal: React.FC<LegacyMenuRedirectModalProps> = ({
  redirectUrl,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledLegacyMenuRedirectModal>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('Redirection to Legacy Admin')}
        </Text>
        <Alert
          icon="alert"
          title={t('Attention!')}
          description={t(
            'You will be redirected to old Admin to proceed to selected page. Continue?',
          )}
        />
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="primary-outline" to={`/redirect?to=${redirectUrl}`}>
          {t('Continue')}
        </Button>
      </div>
    </StyledLegacyMenuRedirectModal>
  );
};

export default LegacyMenuRedirectModal;
