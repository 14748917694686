import React, {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  getSegmentationOptions,
  getSegmentation,
  getSurveyConfig,
  getContentConfigs,
  cauculateTotalSegmentation,
} from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';
import useIsFirstRender from 'hooks/general/useIsFirstRender';

import useBoardTabOptions from 'hooks/board/useBoardTabOptions';
import useContentInitialState from 'hooks/board/useContentInitialState';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Confirm from 'components/molecule/Confirm';
import Icon from 'components/atom/Icon';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import PublicationDateModal from './components/PublicationDateModal';
import TagsModal from './components/TagsModal';
import GamificationConfig from './components/GamificationConfig';
import NotificationsConfig from './components/NotificationsConfig';
import DependencyConfig from './components/DependencyConfig';
import DirectPollConfig from './components/DirectPollConfig';
import Organization from 'components/molecule/Organization';

import CheckinContents from './components/CheckinContents';
import PostContents from './components/PostContents';
import SurveyContents from './components/SurveyContents';
import ContentSegmentationModal from './components/ContentSegmentationModal';

import { contentStateFactory } from './utils';
import { objectsAreEquals } from 'utils/comparations';
import { postInitialState } from './components/PostContents/utils';
import { surveyInitialState } from './components/SurveyContents/utils';
import { checkinInitialState } from './components/CheckinContents/utils';
import { getSegmentationDataValues } from 'utils/segmentation';

import {
  BoardContentFields,
  BoardContentState,
  ContentConfigsProps,
} from './types';
import {
  TagParams,
  GamificationProps,
  NotificationConfigProps,
  NotificationValueProps,
  SegmentationSendProps,
} from 'apis/board/types';
import { RequestFieldError } from 'apis/types';
import { PostContentFields } from './components/PostContents/types';
import {
  SurveyContentFields,
  SurveyConfigProps,
} from './components/SurveyContents/types';
import { CheckinContentFields } from './components/CheckinContents/types';
import { SegmentationValueProps } from 'components/organism/SegmentationEditor/types';

import { isValidDriveUrl } from '../../../utils/validations';
import { apiDateToDayjsFormat } from 'utils/date';

import { StyledMuralContent } from './styles';

const MuralContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    type: contentType = '',
    subtype = '',
    contentId: contentTypeId,
  } = useParams();
  const [searchParams] = useSearchParams();
  const driveUrl = searchParams.get('driveUrl');

  const contentInitialState: BoardContentFields =
    useContentInitialState(contentType);
  const tabOptions = useBoardTabOptions(contentType, subtype);
  const isFirstRender = useIsFirstRender();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );
  const organizationModules = useAppSelector(
    (state) => state.organization.modules,
  );

  const [periodDefault, setPeriodDefault] = useState<{
    start_at: Date | undefined;
    start_at_time: string;
  }>({
    start_at: undefined,
    start_at_time: '',
  });
  const [contentFields, setContentFields] =
    useState<BoardContentFields>(contentInitialState);

  const [contentStatus, setContentStatus] = useState('');

  const [tabs, setTabs] = useState(tabOptions);

  const [changeControl, setChangeControl] = useState<BoardContentState>();

  const [contentTagIds, setContentTagIds] = useState<string[]>([]);
  const [contentTags, setContentTags] = useState<TagParams[]>([]);
  const [totalTags, setTotalTags] = useState(0);

  const [segmentationId, setSegmentationId] = useState<string | null>(null);
  const [segmentationConfig, setSegmentationConfig] = useState(null);
  const [segmentationData, setSegmentationData] =
    useState<SegmentationSendProps>();
  const [currentSegmentation, setCurrentSegmentation] = useState<
    SegmentationValueProps[]
  >([]);
  const [segmentationLoading, setSegmentationLoading] = useState(true);
  const [segmentationChangeControl, setSegmentationChangeControl] =
    useState<SegmentationSendProps>();
  const [segmentationTotalTarget, setSegmentationTotalTarget] = useState(0);

  const [canComment, setCanComment] = useState(false);

  const [gamification, setGamification] = useState<GamificationProps>({
    can_see_results: false,
    participation_points: 0,
  });

  const [notificationConfig, setNotificationConfig] = useState<
    NotificationConfigProps[] | null
  >(null);
  const [notificationValues, setNotificationValues] = useState<
    NotificationValueProps[] | null
  >(null);
  const [notificationChangeControl, setNotificationChangeControl] = useState<
    NotificationValueProps[] | null
  >(null);

  const [parent, setParent] = useState('');

  const [surveyConfig, setSurveyConfig] = useState<SurveyConfigProps>({
    nps: {
      first_placeholder_label: '',
      initial_scale: 1,
      last_placeholder_label: '',
      scale: [5, 7, 10],
    },
    loaded: false,
  });

  const [canEditFinishAt, setCanEditFinishAt] = useState(true);

  const [canEdit, setCanEdit] = useState(true);

  const [votingIsEnabled, setVotingIsEnabled] = useState(false);
  const [votingMessage, setVotingMessage] = useState('');
  const [withRecurrence, setWithRecurrence] = useState(false);

  const [contentConfigs, setContentConfigs] = useState<ContentConfigsProps>({
    editorial: [],
    poll_placeholder: '',
  });
  const [contentConfigsLoading, setContentConfigsLoading] = useState(true);

  const [selectedEditorial, setSelectedEditorial] = useState<string | null>(
    null,
  );

  const [randomOptions, setRandomOptions] = useState(false);

  const [canReaction, setCanReaction] = useState(false);

  const [hideAnswers, setHideAnswers] = useState(false);

  const showUnsavedChangesModal = (confirmCallback: () => void) => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <Confirm
            title={t('There are unsaved changes')}
            subtitle={t(
              'Any unsaved changes will be lost. Do you want to continue without saving?',
            )}
            onConfirm={confirmCallback}
          />
        ),
      }),
    );
  };

  const changeTab = (key: string) => {
    setTabs(
      tabs.map((tab) => {
        if (tab.key === key) {
          tab.active = true;
          return tab;
        }

        tab.active = false;
        return tab;
      }),
    );

    setContentFields({
      ...contentInitialState,
      start_at: {
        ...contentInitialState.start_at,
        value: periodDefault.start_at,
      },
      start_at_time: {
        ...contentInitialState.start_at_time,
        value: periodDefault.start_at_time,
      },
    });
    const to: string = contentTypeId
      ? `/mural/content/${contentType}/${key}/${contentTypeId}`
      : `/mural/content/${contentType}/${key}`;
    navigate(to);
  };

  const handleChangeTab = (key: string) => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'short';

    const currentContentState = contentStateFactory(contentFields, {
      can_comment: canComment,
      can_reaction: canReaction,
      gamification,
      parent,
      status: contentStatus,
      tags: contentTags,
      total_tags: totalTags,
      tag_ids: contentTagIds,
      type,
      voting_is_enabled: votingIsEnabled,
      voting_message: votingMessage,
      with_recurrence: withRecurrence,
      editorial: selectedEditorial,
    });

    const currentChangeControl = {
      ...changeControl,
      segmentations: undefined,
      notifications: undefined,
      type: currentContentState.type,
    };

    if (!objectsAreEquals(currentContentState, currentChangeControl)) {
      showUnsavedChangesModal(() => {
        changeTab(key);
      });
      return;
    }

    changeTab(key);
  };

  const getCurrentType = useCallback(
    () => tabs.find((tab) => tab.active),
    [tabs],
  );

  useEffect(() => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'short';

    if (subtype === 'drive' && !organizationModules?.includes('module_drive')) {
      navigate('/mural');
      return;
    }

    if (driveUrl && type === 'drive') {
      const driveUrlIsValid = isValidDriveUrl(driveUrl);

      setContentFields((lastContent) => ({
        ...lastContent,
        url: {
          value: driveUrl,
          error: {
            hasError: driveUrlIsValid,
            errorMessage: driveUrlIsValid ? '' : t('Enter a valid drive url'),
          },
          disabled: false,
        },
      }));
    }
  }, [driveUrl, getCurrentType, t, navigate, organizationModules, subtype]);

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const showErrorMessage = useCallback(
    (message: string) => {
      dispatch(
        showMessage({
          title: message,
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
    },
    [dispatch],
  );

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setContentFields((lastContentFields) => {
        const fieldContent = {
          ...lastContentFields[error.field as keyof BoardContentFields],
        };

        return {
          ...lastContentFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const calculateTotalSegmentationTarget = useCallback(
    (data?: SegmentationSendProps) => {
      cauculateTotalSegmentation(organizationId, data)
        .then((response) => {
          const { count_accounts } = response.data;
          setSegmentationTotalTarget(count_accounts);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while calculating total targeted users.',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .finally(() => {
          setSegmentationLoading(false);
        });
    },
    [t, dispatch, organizationId],
  );

  const getSegmentationEntries = useCallback(
    (id: string) => {
      getSegmentation(organizationId, id)
        .then((response) => {
          setCurrentSegmentation(response.data);

          const newSegmentationData = {
            id: id,
            entries: response.data
              .filter((item: SegmentationValueProps) => !!item.key)
              .map((item: SegmentationValueProps) => ({
                id: item.id.includes(':') ? null : item.id,
                key: item.key,
                operator: item.operator,
                type: item.type,
                values: getSegmentationDataValues(item.type, item.values),
              })),
          };

          setSegmentationData(newSegmentationData);
          setSegmentationChangeControl(newSegmentationData);
          calculateTotalSegmentationTarget(newSegmentationData);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading segmentation',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
          setSegmentationLoading(false);
        });
    },
    [dispatch, organizationId, t, calculateTotalSegmentationTarget],
  );

  const loadSegmentationConfig = useCallback(
    (id?: string, contentId?: string) => {
      getSegmentationOptions(organizationId, contentId)
        .then((response) => {
          setSegmentationConfig(
            response.data.map((configItems: { url: string | null }) => ({
              ...configItems,
              url: configItems.url ? configItems.url.replace('/v2', '') : null,
            })),
          );
          if (id) {
            getSegmentationEntries(id);
            return;
          }

          calculateTotalSegmentationTarget();
          setSegmentationId(id || null);
        })
        .catch(() => {
          showErrorMessage(
            t('An unexpected error occurred while segmenting the content'),
          );
          setSegmentationLoading(false);
        });
    },
    [
      organizationId,
      showErrorMessage,
      t,
      getSegmentationEntries,
      calculateTotalSegmentationTarget,
    ],
  );

  const loadSurveyConfig = useCallback(() => {
    getSurveyConfig(organizationId)
      .then((response) => {
        const {
          nps: {
            first_placeholder_label,
            initial_scale,
            last_placeholder_label,
            scale,
          },
        } = response.data;

        setSurveyConfig({
          nps: {
            first_placeholder_label,
            initial_scale,
            last_placeholder_label,
            scale,
          },
          loaded: true,
        });
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An error occurred while fetching the config'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  }, [organizationId, dispatch, t]);

  const renderContent = () => {
    switch (contentType) {
      case 'post': {
        return (
          <PostContents
            contentState={contentFields as PostContentFields}
            setContentState={
              setContentFields as Dispatch<SetStateAction<PostContentFields>>
            }
            setInitialChangeControl={setInitialChangeControl}
            loadSegmentationConfig={loadSegmentationConfig}
            setSegmentationChangeControl={setSegmentationChangeControl}
            contentStatus={contentStatus}
            setContentStatus={setContentStatus}
            canComment={canComment}
            setCanComment={setCanComment}
            setCanEditFinishAt={setCanEditFinishAt}
            setChangeControl={setChangeControl}
            contentTagIds={contentTagIds}
            setContentTagIds={setContentTagIds}
            contentTags={contentTags}
            setContentTags={setContentTags}
            totalTags={totalTags}
            setTotalTags={setTotalTags}
            gamification={gamification}
            setGamification={setGamification}
            setNotificationChangeControl={setNotificationChangeControl}
            notificationValues={notificationValues}
            setNotificationValues={setNotificationValues}
            parent={parent}
            setParent={setParent}
            segmentationData={segmentationData}
            segmentationConfig={segmentationConfig}
            segmentationLoading={segmentationLoading}
            setSegmentationId={setSegmentationId}
            segmentationTotalTarget={segmentationTotalTarget}
            getPeriodButtonLabel={getPeriodButtonLabel}
            isEditable={isEditable}
            showDependencyModal={showDependencyModal}
            showGamificationModal={showGamificationModal}
            showNotificationModal={showNotificationModal}
            showPublicationDateModal={showPublicationDateModal}
            showSegmentationModal={showSegmentationModal}
            showTagsModal={showTagsModal}
            handleFieldErrors={handleFieldErrors}
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
            getCurrentType={getCurrentType}
            tabs={tabs}
            handleChangeTab={handleChangeTab}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            showDirectPollModal={showDirectPollModal}
            votingIsEnabled={votingIsEnabled}
            setVotingIsEnabled={setVotingIsEnabled}
            votingMessage={votingMessage}
            setVotingMessage={setVotingMessage}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            contentConfigs={contentConfigs}
            contentConfigsLoading={contentConfigsLoading}
            selectedEditorial={selectedEditorial}
            setSelectedEditorial={setSelectedEditorial}
            canReaction={canReaction}
            setCanReaction={setCanReaction}
          />
        );
      }
      case 'survey': {
        return (
          <SurveyContents
            contentState={contentFields as SurveyContentFields}
            setContentState={
              setContentFields as Dispatch<SetStateAction<SurveyContentFields>>
            }
            setInitialChangeControl={setInitialChangeControl}
            loadSegmentationConfig={loadSegmentationConfig}
            setSegmentationChangeControl={setSegmentationChangeControl}
            contentStatus={contentStatus}
            setContentStatus={setContentStatus}
            canComment={canComment}
            setCanComment={setCanComment}
            setCanEditFinishAt={setCanEditFinishAt}
            setChangeControl={setChangeControl}
            contentTagIds={contentTagIds}
            setContentTagIds={setContentTagIds}
            contentTags={contentTags}
            setContentTags={setContentTags}
            totalTags={totalTags}
            setTotalTags={setTotalTags}
            gamification={gamification}
            setGamification={setGamification}
            setNotificationChangeControl={setNotificationChangeControl}
            notificationValues={notificationValues}
            setNotificationValues={setNotificationValues}
            parent={parent}
            setParent={setParent}
            segmentationData={segmentationData}
            segmentationConfig={segmentationConfig}
            segmentationLoading={segmentationLoading}
            setSegmentationId={setSegmentationId}
            segmentationTotalTarget={segmentationTotalTarget}
            getPeriodButtonLabel={getPeriodButtonLabel}
            isEditable={isEditable}
            showDependencyModal={showDependencyModal}
            showGamificationModal={showGamificationModal}
            showNotificationModal={showNotificationModal}
            showPublicationDateModal={showPublicationDateModal}
            showSegmentationModal={showSegmentationModal}
            showTagsModal={showTagsModal}
            handleFieldErrors={handleFieldErrors}
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
            getCurrentType={getCurrentType}
            tabs={tabs}
            handleChangeTab={handleChangeTab}
            surveyConfig={surveyConfig}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            contentConfigs={contentConfigs}
            contentConfigsLoading={contentConfigsLoading}
            selectedEditorial={selectedEditorial}
            setSelectedEditorial={setSelectedEditorial}
            randomOptions={randomOptions}
            setRandomOptions={setRandomOptions}
            hideAnswers={hideAnswers}
            setHideAnswers={setHideAnswers}
          />
        );
      }
      case 'checkin': {
        return (
          <CheckinContents
            contentState={contentFields as CheckinContentFields}
            setContentState={
              setContentFields as Dispatch<SetStateAction<CheckinContentFields>>
            }
            setInitialChangeControl={setInitialChangeControl}
            loadSegmentationConfig={loadSegmentationConfig}
            setSegmentationChangeControl={setSegmentationChangeControl}
            contentStatus={contentStatus}
            setContentStatus={setContentStatus}
            canComment={canComment}
            setCanComment={setCanComment}
            setCanEditFinishAt={setCanEditFinishAt}
            setChangeControl={setChangeControl}
            contentTagIds={contentTagIds}
            setContentTagIds={setContentTagIds}
            contentTags={contentTags}
            setContentTags={setContentTags}
            totalTags={totalTags}
            setTotalTags={setTotalTags}
            gamification={gamification}
            setGamification={setGamification}
            setNotificationChangeControl={setNotificationChangeControl}
            notificationValues={notificationValues}
            setNotificationValues={setNotificationValues}
            parent={parent}
            setParent={setParent}
            segmentationData={segmentationData}
            segmentationConfig={segmentationConfig}
            segmentationLoading={segmentationLoading}
            setSegmentationId={setSegmentationId}
            segmentationTotalTarget={segmentationTotalTarget}
            getPeriodButtonLabel={getPeriodButtonLabel}
            isEditable={isEditable}
            showDependencyModal={showDependencyModal}
            showGamificationModal={showGamificationModal}
            showNotificationModal={showNotificationModal}
            showPublicationDateModal={showPublicationDateModal}
            showSegmentationModal={showSegmentationModal}
            showTagsModal={showTagsModal}
            handleFieldErrors={handleFieldErrors}
            showErrorMessage={showErrorMessage}
            showSuccessMessage={showSuccessMessage}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            contentConfigs={contentConfigs}
            contentConfigsLoading={contentConfigsLoading}
            selectedEditorial={selectedEditorial}
            setSelectedEditorial={setSelectedEditorial}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  const handleBack = () => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'short';

    const currentContentState = contentStateFactory(contentFields, {
      can_comment: canComment,
      can_reaction: canReaction,
      gamification,
      parent,
      status: contentStatus,
      tags: contentTags,
      total_tags: totalTags,
      tag_ids: contentTagIds,
      type,
      voting_is_enabled: votingIsEnabled,
      voting_message: votingMessage,
      with_recurrence: withRecurrence,
      editorial: selectedEditorial,
    });

    const currentChangeControl = {
      ...changeControl,
      segmentations: undefined,
      notifications: undefined,
      type: currentContentState.type,
    };

    if (
      !objectsAreEquals(currentContentState, currentChangeControl) ||
      !objectsAreEquals(segmentationData, segmentationChangeControl) ||
      !objectsAreEquals(notificationValues, notificationChangeControl)
    ) {
      showUnsavedChangesModal(() => {
        navigate('/mural');
      });
      return;
    }
    navigate('/mural');
  };

  const showPublicationDateModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '482px',
        content: (
          <PublicationDateModal
            contentState={contentFields}
            setContentState={setContentFields}
            canEditFinishAt={canEditFinishAt}
            withRecurrence={withRecurrence}
            setWithRecurrence={setWithRecurrence}
            contentStatus={contentStatus}
            canSetRecurrence={subtype !== 'drive'}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const getPeriodButtonLabel = () => {
    if (!contentFields.start_at.value && !withRecurrence) {
      return t('Content period');
    }

    if (withRecurrence) {
      return t('Schedule');
    }

    const startAt = dayjs(contentFields.start_at.value).format('ll');

    switch (contentStatus) {
      case 'draft': {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
      case 'scheduled': {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
      case 'active': {
        if (contentFields.finish_at.value) {
          const finishAt = dayjs(contentFields.finish_at.value).format('ll');
          return t('Ends in {{finishAt}}', {
            finishAt,
          });
        }
        return t('Started in {{startAt}}', {
          startAt,
        });
      }
      case 'finishing': {
        if (contentFields.finish_at.value) {
          const finishAt = dayjs(contentFields.finish_at.value).format('ll');
          return t('Ends in {{finishAt}}', {
            finishAt,
          });
        }
        return t('Started in {{startAt}}', {
          startAt,
        });
      }
      case 'finished': {
        const finishAt = dayjs(contentFields.finish_at.value).format('ll');
        return t('Ended in {{finishAt}}', {
          finishAt,
        });
      }
      case 'canceled': {
        return t('Would start in {{startAt}}', {
          startAt,
        });
      }
      default: {
        return t('Starts at {{startAt}}', {
          startAt,
        });
      }
    }
  };

  const showTagsModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '655px',
        content: (
          <TagsModal
            selectedTagList={contentTags}
            onSave={(tagList) => {
              setContentTags(tagList);
              setContentTagIds(tagList.map((tag) => tag.id));
              setTotalTags(tagList.length);
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showSegmentationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '890px',
        content: (
          <ContentSegmentationModal
            title={t('Select the audience')}
            subtitle={t(
              'Apply segmentation criteria to define the audience that will be impacted by this content.',
            )}
            segmentationConfig={segmentationConfig}
            segmentationId={segmentationId}
            currentSegmentation={currentSegmentation}
            onSave={(data, segmentation) => {
              setSegmentationData(data);
              setCurrentSegmentation(segmentation);
              calculateTotalSegmentationTarget(data);
              setSegmentationLoading(true);
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showGamificationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '457px',
        content: (
          <GamificationConfig
            gamification={gamification}
            onSave={(newGamificationConfig) =>
              setGamification(newGamificationConfig)
            }
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showNotificationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '390px',
        content: (
          <NotificationsConfig
            notificationConfig={notificationConfig}
            setNotificationConfig={setNotificationConfig}
            notificationValues={notificationValues}
            onSave={(newNotificationValues) => {
              setNotificationValues(newNotificationValues);
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showDependencyModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '688px',
        content: (
          <DependencyConfig
            contentId={contentTypeId || ''}
            contentType={contentType}
            onSave={(parentId) => setParent(parentId)}
            currentParent={parent}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const showDirectPollModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '376px',
        content: (
          <DirectPollConfig
            isEnabled={votingIsEnabled}
            message={votingMessage}
            onSave={(isEnabled, message) => {
              setVotingIsEnabled(isEnabled);
              setVotingMessage(message);
            }}
            contentConfigs={contentConfigs}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const setInitialChangeControl = useCallback(() => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'short';

    let currentInitialState = null;

    if (contentType === 'post') {
      currentInitialState = { ...postInitialState };
    }

    if (contentType === 'survey') {
      currentInitialState = { ...surveyInitialState };
    }

    if (contentType === 'checkin') {
      currentInitialState = { ...checkinInitialState };
    }

    if (currentInitialState) {
      setChangeControl(
        contentStateFactory(
          {
            ...currentInitialState,
            start_at: {
              ...currentInitialState.start_at,
              value: periodDefault.start_at,
            },
            start_at_time: {
              ...currentInitialState.start_at_time,
              value: periodDefault.start_at_time,
            },
          },
          {
            can_comment: false,
            can_reaction: false,
            gamification: {
              can_see_results: false,
              participation_points: 0,
            },
            notifications: null,
            parent: '',
            segmentations: undefined,
            status: '',
            tags: [],
            total_tags: 0,
            tag_ids: [],
            type,
            with_recurrence: false,
            editorial: 'not_defined',
            voting_is_enabled: false,
            voting_message: '',
          },
        ),
      );
    }
  }, [
    contentType,
    getCurrentType,
    periodDefault.start_at,
    periodDefault.start_at_time,
  ]);

  const isEditable = () => contentStatus === 'draft' || contentStatus === '';

  const loadContentConfigs = useCallback(() => {
    setContentConfigsLoading(true);

    getContentConfigs(organizationId)
      .then((response) => {
        const formattedStartAt = apiDateToDayjsFormat(
          response.data.default_open_at,
        );
        const [startAt, startAtTime] = formattedStartAt.split(' ');

        setPeriodDefault({
          start_at: startAt ? dayjs(startAt).toDate() : undefined,
          start_at_time: startAtTime ? startAtTime.split(':').join(':') : '',
        });

        setContentFields((contentInitial) => {
          return {
            ...contentInitial,
            start_at: {
              ...contentInitial.start_at,
              value: startAt ? dayjs(startAt).toDate() : undefined,
            },
            start_at_time: {
              ...contentInitial.start_at_time,
              value: startAtTime ? startAtTime.split(':').join(':') : '',
            },
          };
        });

        setContentConfigs(response.data);
      })
      .finally(() => {
        setContentConfigsLoading(false);
      });
  }, [organizationId]);

  useEffect(() => {
    if (!contentTypeId) {
      loadSegmentationConfig();
    }

    if (!surveyConfig.loaded) {
      loadSurveyConfig();
    }
  }, [
    loadSegmentationConfig,
    contentTypeId,
    loadSurveyConfig,
    surveyConfig.loaded,
  ]);

  useEffect(() => {
    if (isFirstRender) {
      loadContentConfigs();
    }
  }, [isFirstRender, loadContentConfigs]);

  return (
    <StyledMuralContent className="mural-content-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      {renderContent()}
    </StyledMuralContent>
  );
};

export default MuralContent;
