import React from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/atom/Tooltip';

import { AvailablePlaceTypes } from './types';

interface CopyToClipboardProps {
  children?: React.ReactNode;
  copyId: string;
  message: string;
  successMessage?: string;
  messagePlace?: keyof typeof AvailablePlaceTypes;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  message,
  successMessage,
  messagePlace = 'top',
  copyId,
  children,
}) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(message);
  };

  return (
    <Tooltip
      id={copyId}
      place={messagePlace}
      openOnClick
      afterShow={handleCopy}
      content={successMessage || t('Copied!')}
    >
      {children}
    </Tooltip>
  );
};

export default CopyToClipboard;
