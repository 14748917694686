import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/molecule/Input';
import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';
import Checkbox from 'components/molecule/Checkbox';
import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';
import EmptyMessage from 'components/molecule/EmptyMessage';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import Icon from 'components/atom/Icon';

import { MultiSelectProps } from './types';

import { StyledMultiSelect } from './styles';

const MultiSelect: React.FC<MultiSelectProps> = ({
  items,
  setItems,
  isOpen,
  setIsOpen,
  loading,
  setLoading,
  loadMoreLoading,
  setLoadMoreLoading,
  onOpen,
  fetchItems,
  page,
  hasNextPage,
  selectButton,
  onSelect,
  onAll,
  allIsChecked,
  onCheck,
  onCancel,
  placeholder,
  count,
  onOutsideClick,
  search,
  setSearch,
}) => {
  const { t } = useTranslation();

  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollTop = 0;
      }
      if (fetchItems) {
        if (setLoading) setLoading(true);
        fetchItems(1, value);
      }
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const handleSelect = useCallback(() => {
    if (onSelect) {
      onSelect(search);
    }
    setIsOpen(false);
    setItems([]);
    setSearch('');
  }, [onSelect, setItems, search, setIsOpen, setSearch]);

  const handleOnAll = () => {
    if (onAll) {
      onAll(search);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input) => input.blur());
    setItems([]);
    setSearch('');
    if (onCancel) {
      onCancel();
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
        const inputs = document.querySelectorAll('input');
        inputs.forEach((input) => input.blur());
        setItems([]);
        setSearch('');
        if (onCancel) {
          onCancel();
        }
      }

      if (event.key === 'Enter') {
        const inputs = document.querySelectorAll('input');
        inputs.forEach((input) => input.blur());
        handleSelect();
        setSearch('');
      }
    },
    [handleSelect, setItems, onCancel, setIsOpen, setSearch],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <StyledMultiSelect className="multi-select" menuIsOpen={isOpen}>
      <Dropdown
        outsideOnClick={(event) => {
          if (onOutsideClick) {
            onOutsideClick(event);
          }
          handleOnClose();
        }}
        toggleElement={
          <Input
            placeholder={placeholder || t('Search by name')}
            icon="search"
            value={search}
            onChange={handleSearch}
            theme={isOpen ? 'post' : 'default'}
            actions={
              search !== '' ? (
                <Button
                  size="small"
                  theme="link-dark"
                  onClick={() => {
                    clearTimeout(currentSearchTimeOut);
                    setSearch('');
                    if (fetchItems) {
                      fetchItems(1, '');
                    }
                  }}
                >
                  <Icon name="close-circle-fill" />
                </Button>
              ) : (
                ''
              )
            }
          />
        }
        open={isOpen}
        setOpen={() => undefined}
        autoControl={false}
        toggleOnClick={() => {
          setIsOpen(true);

          if (fetchItems && !isOpen) {
            if (setLoading) setLoading(true);
            fetchItems(1, search);
          }

          if (onOpen) {
            onOpen();
          }
        }}
      >
        <div
          className="item-list default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          {!loading && items.length > 0 && (
            <Button
              theme="link-dark"
              size="small"
              onClick={handleOnAll}
              disabled={!items.some((item) => !item.disabled)}
            >
              <Checkbox
                disabled
                checked={allIsChecked}
                className={
                  !items.some((item) => !item.disabled) ? 'check-disabled' : ''
                }
              />
              <Text>
                {allIsChecked
                  ? `${t('Deselect all')} ${count ? `(${count})` : ''}`
                  : `${t('Select all')} ${count ? `(${count})` : ''}`}
              </Text>
            </Button>
          )}

          {!loading &&
            items.map((item, index) => (
              <Button
                theme="link-dark"
                size="small"
                key={index}
                onClick={() => onCheck(item.key, search)}
                disabled={item.disabled}
              >
                <Checkbox
                  disabled
                  checked={item.checked}
                  className={item.disabled ? 'check-disabled' : ''}
                />
                <Text>{item.value}</Text>
              </Button>
            ))}

          {loading && <Loading type="bubbles" />}

          {loadMoreLoading && (
            <Loading type="bubbles" className="load-more-loading" />
          )}

          {!loading && items.length === 0 && (
            <EmptyMessage
              title={t('No position found')}
              description={t(
                'Change the words in the search bar and try again.',
              )}
            />
          )}
        </div>
      </Dropdown>

      {selectButton}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          if (setLoadMoreLoading) {
            setLoadMoreLoading(true);
          }
          if (fetchItems && page) {
            fetchItems(page + 1, search);
          }
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledMultiSelect>
  );
};

export default MultiSelect;
