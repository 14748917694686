import styled from 'styled-components';

export const StyledMuralContentConfig = styled.div`
  width: 100%;
  height: 100%;

  .main-header {
    padding-left: 54px;

    .back-logo {
      display: flex;
      align-items: center;

      .button {
        margin-right: 12px;
      }
    }
  }

  .secondary-header {
    padding-left: 54px;
  }

  .content-config-container {
    width: 100%;
    height: calc(100% - 144px);
    padding: 0px 58px;
    display: flex;

    .menu-side {
      width: 100%;
      max-width: 240px;
      padding: 24px 0px;
      border-right: 1px solid var(--grayscale-100);
    }

    .content-side {
      width: 100%;
      padding: 32px;
      display: flex;
      justify-content: center;
    }
  }
`;
