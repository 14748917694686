import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import SegmentationRow from './components/SegmentationRow';

import { SegmentationEditorProps } from './types';

import { StyledSegmentationEditor } from './styles';

const SegmentationEditor: React.FC<SegmentationEditorProps> = ({
  segmentationConfig,
  segmentation,
  setSegmentation,
  apiVersion,
  canEdit = true,
}) => {
  const { t } = useTranslation();

  const handleRemove = (id: string) => {
    setSegmentation((lastSegmentation) => [
      ...lastSegmentation.filter((item) => item.id !== id),
    ]);
  };

  return (
    <StyledSegmentationEditor
      className={`segmentation-editor ${!canEdit ? 'pointer-events-none' : ''}`}
    >
      {segmentationConfig &&
        segmentation.map((item, index) => (
          <SegmentationRow
            key={index}
            rowId={item.id}
            firstItem={index === 0}
            config={segmentationConfig}
            segmentationRow={item}
            segmentation={segmentation}
            setSegmentation={setSegmentation}
            onRemove={() => handleRemove(item.id)}
            apiVersion={apiVersion}
          />
        ))}
      {segmentation.length === 0 && (
        <Text weight="700" color="grayscale-200" align="center">
          {t('No defined criteria')}
        </Text>
      )}
    </StyledSegmentationEditor>
  );
};

export default SegmentationEditor;
