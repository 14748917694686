import { CustomFieldText, CustomTextCfgResponse } from 'apis/core/types';
import { Dispatch, SetStateAction } from 'react';

export interface TextOptionItem extends CustomFieldText {
  id?: string;
  name: string;
  max_length_invalid_msg: string;
}

export const defaultTextOptionItem: TextOptionItem = {
  name: '',
  max_length: null,
  max_length_invalid_msg: '',
  is_multiline: false,
};

export interface TextOptionsProps {
  config?: CustomTextCfgResponse;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  item: TextOptionItem;
  setItem: Dispatch<SetStateAction<TextOptionItem>>;
}
