import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getColaboratorsConfigItems } from 'apis/config';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import TagGrouper from 'components/molecule/TagGrouper';
import ProfileList from '../HierarchyList';
import Tooltip from 'components/atom/Tooltip';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';

import { AccountFilter, AccountConfigItem } from './types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
  SearchableValue,
} from 'components/organism/Table/types';
import CollaboratorsTableActions from './components/ProfileItemActions';

import { StyledCollaboratorsTable } from './styles';

const CollaboratorsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<AccountFilter>({
    order: '',
    search: '',
    slug: '',
    departments: '',
    profiles: '',
  });

  const [tableRenderControl, setTableRenderControl] = useState('');

  const showProfileList = useCallback(
    (type: string, id: string, name: string) => {
      dispatch(
        setModalView({
          show: true,
          width: '600px',
          content: <ProfileList type={type} id={id} name={name} />,
        }),
      );
    },
    [dispatch],
  );

  const handleSetTableRenderControl = () => {
    setTableRenderControl((+new Date()).toString());
  };

  const getTableRows = useCallback(
    (data: AccountConfigItem[]) => {
      return data.map((item, index) => {
        return {
          cells: [
            {
              content: (
                <div className="flex justify-between items-center gap-1">
                  <Tooltip
                    id={`name-${item.id}-${index}`}
                    content={item.name}
                    disabled={item.name.length < 34}
                  >
                    <Text color="grayscale-400">{item.name}</Text>
                  </Tooltip>
                  {item.is_organization_admin ? (
                    <Tag radius="100px" padding="6px 16px" theme="tertiary-1">
                      <Text color="white-color" weight="700">
                        {t('Adm')}
                      </Text>
                    </Tag>
                  ) : null}
                </div>
              ),
            },
            {
              content: (
                <Tooltip
                  id={`username-${item.id}`}
                  content={item.username}
                  disabled={item.username.length < 34}
                >
                  <Text color="grayscale-400">{item.username}</Text>
                </Tooltip>
              ),
            },
            {
              content: (
                <Tooltip
                  id={`status-${item.id}`}
                  content={
                    item.first_access
                      ? t('First access performed')
                      : t('Pending on first access')
                  }
                >
                  <Icon
                    name={item.first_access ? 'check-double' : 'alert'}
                    color="grayscale-200"
                  />
                </Tooltip>
              ),
            },
            {
              content:
                item.profiles.total > 0 ? (
                  <TagGrouper
                    tags={item.profiles.results}
                    total={item.profiles.total}
                    displayLimit={2}
                    characterLimit={20}
                  />
                ) : (
                  '-'
                ),
              clickable: item.profiles.total > 0,
              onClick: () => showProfileList('profile', item.id, item.name),
            },
            {
              content:
                item.departments.total > 0 ? (
                  <TagGrouper
                    tags={item.departments.results}
                    total={item.departments.total}
                    displayLimit={2}
                    characterLimit={20}
                  />
                ) : (
                  '-'
                ),
              clickable: item.departments.total > 0,
              onClick: () => showProfileList('department', item.id, item.name),
            },
            {
              content: (
                <Tooltip
                  id={`email-${item.id}`}
                  content={item.email}
                  disabled={item.email.length < 34}
                >
                  <Text color="grayscale-400">{item.email}</Text>
                </Tooltip>
              ),
            },
            {
              content: item.gender ? (
                <Text color="grayscale-400">{item.gender}</Text>
              ) : (
                '-'
              ),
            },
            {
              content: item.country ? (
                <Text color="grayscale-400">{item.country}</Text>
              ) : (
                '-'
              ),
            },
            {
              content: (
                <CollaboratorsTableActions
                  id={item.id}
                  onSave={() => handleSetTableRenderControl()}
                />
              ),
            },
          ],
        };
      });
    },
    [showProfileList, t],
  );

  const loadCollaborators = useCallback(
    (page: number, filter: AccountFilter) => {
      setTableLoading(true);

      getColaboratorsConfigItems(organizationId, page, filter)
        .then((response) => {
          const { num_pages, results } = response.data;
          setPageCount(num_pages);
          setCurrentPage(page);
          setTableRows(() => getTableRows(results));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }

          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, organizationId, t, getTableRows],
  );

  const handleSearch = (values: SearchableValue[]) => {
    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        search: values.find((value) => value.key === 'name')?.value || '',
        slug: values.find((value) => value.key === 'slug')?.value || '',
        profiles: values.find((value) => value.key === 'profiles')?.value || '',
        departments:
          values.find((value) => value.key === 'departments')?.value || '',
      };
      return newFilter;
    });
    setCurrentPage(1);
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    const { value, key } = sortableValue;
    let newOrder = '';

    if (value === 1) {
      newOrder = key;
    }

    if (value === 0) {
      newOrder = '';
    }

    if (value === -1) {
      newOrder = `-${key}`;
    }

    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        order: newOrder,
      };
      return newFilter;
    });
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Name')}</Text>,
      sortable: {
        key: 'name',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
      searchable: {
        key: 'name',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by name'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Username')}</Text>,
      sortable: {
        key: 'slug',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
      searchable: {
        key: 'slug',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by username'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Status')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Positions')}</Text>,
      searchable: {
        key: 'profiles',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by positions'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Departments')}</Text>,
      searchable: {
        key: 'departments',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by departments'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Email')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Gender')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Country')}</Text>,
    },
    {
      content: <Text color="grayscale-200"></Text>,
    },
  ]);

  useEffect(() => {
    loadCollaborators(currentPage, filter);
  }, [currentPage, filter, loadCollaborators, tableRenderControl]);

  return (
    <StyledCollaboratorsTable className="collaborators-table">
      <div className="content-header">
        <Text weight="700" as="h3">
          {t('Collaborators')}
        </Text>
        <div className="actions">
          <Button
            theme="link-gray-primary"
            leftIcon="table"
            to="/collaborators/import"
          >
            {t('Import workers')}
          </Button>
          <Button theme="link-gray-primary" leftIcon="download" disabled>
            {t('Export')}
          </Button>
        </div>
      </div>
      <div className="content">
        <Table
          columns={columns}
          setColumns={setColumns}
          rows={tableRows}
          textAlign="start"
          fontColor="grayscale-200"
          hasPagination
          pageCount={pageCount}
          page={currentPage - 1}
          onChangePage={(page) => setCurrentPage(page + 1)}
          loading={tableLoading}
          emptyMessageDescription={t(
            'If there are already employees registered in your organization, change the words in the search bar and try again',
          )}
          scrollable
        />
      </div>
    </StyledCollaboratorsTable>
  );
};

export default CollaboratorsTable;
