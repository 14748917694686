import styled from 'styled-components';

export const StyledFilesTable = styled.div`
  margin-top: 78px;

  .table {
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .table-wrapper {
    width: 100%;
    table {
      width: max-content;
      min-width: 100%;
      tbody {
        tr {
          td {
            padding: 8px 10px;
          }
        }
      }
    }
  }
`;
