import styled from 'styled-components';

export const StyledFileUploader = styled.div`
  width: 100%;
  height: 284px;

  .drop-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: var(--z-index-level-2);
    border: 2px dashed var(--grayscale-100);
    border-radius: 8px;

    .tag {
      width: 52px;
      height: 52px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    .send-your-file-label {
      margin-bottom: 4px;
    }

    .format-and-size-label {
      margin-bottom: 24px;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .text:first-child {
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }

  label > div:not(.drop-area) {
    & ~ .drop-area {
      border: 2px solid var(--grayscale-100);
      .tag {
        background: var(--primary-color);
        .icon {
          color: var(--white-color);
        }
      }
    }
  }
`;
