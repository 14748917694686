import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setContent } from 'store/slices/content';

import { getBoardContentPost } from 'apis/board';

import { setContentTitle } from 'store/slices/contentResults';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import Image from 'components/molecule/Image';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';
import Text from 'components/atom/Text';
import RichTextView from 'components/atom/RichTextView';
import SkeletonLoading from 'components/atom/SkeletonLoading';

import { BoardContentPostData } from 'apis/board/types';

import { StyledPostDetails } from './styles';

const PostDetails: React.FC = () => {
  const { type, subtype, contentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [data, setData] = useState<BoardContentPostData>();
  const [loading, setLoading] = useState(true);

  const showFinishAtLabel = () => {
    if (data && data.finish_at) {
      return t('to {{finish_at}} at {{time_finish_at}}', {
        finish_at: getDateFromApiDate(data.finish_at),
        time_finish_at: getTimeFromApiDate(data.finish_at),
      });
    }

    return '';
  };

  useEffect(() => {
    if (contentId) {
      getBoardContentPost(organizationId, contentId)
        .then((response) => {
          setData(response.data);

          const { has_dashboard, can_comment, content_id, title } =
            response.data;

          dispatch(
            setContent({
              has_dashboard,
              can_comment,
              id: content_id,
            }),
          );
          dispatch(setContentTitle(title));
        })
        .catch(() => {
          navigate('/error-404');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contentId, navigate, organizationId, dispatch]);

  return (
    <StyledPostDetails className="post-details">
      {loading && (
        <div className="loadings">
          <SkeletonLoading
            type="custom"
            customStyle={{ width: '100%', height: '300px' }}
          />
          <SkeletonLoading type="line" />
          <SkeletonLoading type="text" />
          <SkeletonLoading type="paragraph" />
        </div>
      )}

      {!loading && data && (
        <div className="post-details-content">
          <div className="cover">
            <div className="actions">
              <div className="edit-button-wrapper">
                {['active'].includes(data.status) && (
                  <Button
                    rounded="true"
                    size="small"
                    theme="gray-primary"
                    to={`/mural/content/${type}/${subtype}/${contentId}`}
                  >
                    <Icon name="pencil-fill" />
                  </Button>
                )}
              </div>
              <div className="counters">
                {data.gamification.participation_points !== 0 && (
                  <Tag theme="gray-100">
                    {t('{{points}} points', {
                      points: data.gamification.participation_points,
                      count: data.gamification.participation_points,
                    })}
                  </Tag>
                )}

                {data.tags && data.tags.length > 0 && (
                  <Tag theme="gray-100">
                    {t('{{tags}} tag', {
                      tags: data.tags.length,
                      count: data.tags.length,
                    })}
                  </Tag>
                )}
              </div>
            </div>
            {data.cover && <Image src={data.cover.path} />}
          </div>
          <div className="content-elements">
            {data.start_at && (
              <Text as="pre" color="grayscale-200">
                {t('{{start_at}} at {{time_start_at}}', {
                  start_at: getDateFromApiDate(data.start_at),
                  time_start_at: getTimeFromApiDate(data.start_at),
                })}
                <span> </span>
                {showFinishAtLabel()}
              </Text>
            )}
            <Text as="h4" weight="700" className="title">
              {data.title}
            </Text>
            {data.description && (
              <Text className="description">{data.description}</Text>
            )}
            {data.text && <RichTextView content={data.text} />}
            {data.url && (
              <Button
                theme="link-primary"
                leftIcon="external-link"
                href={data.url}
              >
                {data.url}
              </Button>
            )}
          </div>
        </div>
      )}
    </StyledPostDetails>
  );
};

export default PostDetails;
