import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getContentParentCandidates } from 'apis/board';

import useSelectTypeOptions from 'hooks/board/useSelectTypeOptions';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import Input from 'components/molecule/Input';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Loading from 'components/molecule/Loading';
import Image from 'components/molecule/Image';
import Radio from 'components/molecule/Radio';
import InfiniteScroll from 'components/atom/InfiniteScroll';

import { SelectedOptionValueType } from 'components/molecule/Select/types';
import { AvailableTypes } from 'apis/board/types';
import {
  DependencyConfigProps,
  DependencyContentProps,
  DependencyContentResponseProps,
} from './types';

import { StyledDependencyConfig, StyledDependencyContent } from './styles';

const DependencyContent: React.FC<DependencyContentProps> = ({
  id,
  cover = '',
  title,
  description,
  createdAt,
  createdBy,
  checked,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDependencyContent className="dependency-content">
      <label
        onClick={(event) => {
          event.preventDefault();
          onSelect(id);
        }}
      >
        <Radio
          name="dependency-content"
          id={id}
          checked={checked}
          onChange={() => undefined}
        />
        <Image src={cover} width="124px" height="70px" />
        <div className="texts">
          {title && (
            <Text weight="700" className="title" color="grayscale-400">
              {title}
            </Text>
          )}
          {!title && (
            <Text weight="700" italic className="title" color="grayscale-400">
              {t('No title')}
            </Text>
          )}
          <Text color="grayscale-200" className="description">
            {description}
          </Text>
        </div>
        <div className="content-info">
          <Text as="pre" color="grayscale-200" className="created-at">
            {createdAt}
          </Text>
          <Text
            as="pre"
            color="grayscale-200"
            weight="bold"
            className="created-by"
          >
            {createdBy}
          </Text>
        </div>
      </label>
    </StyledDependencyContent>
  );
};

const DependencyConfig: React.FC<DependencyConfigProps> = ({
  contentId,
  contentType,
  onSave,
  currentParent,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const effectRan = useRef(false);
  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const selectOptions = useSelectTypeOptions();

  const [selectedOption, setSelectedOption] = useState<SelectedOptionValueType>(
    selectOptions[0],
  );
  const [type, setType] = useState('all');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [checkedContent, setCheckedContent] = useState(currentParent);

  const [contents, setContents] = useState<DependencyContentResponseProps[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);

  const loadContentList = useCallback(
    (
      page = 1,
      search = '',
      type = '' as keyof typeof AvailableTypes,
      isLoadMore = false,
    ) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      getContentParentCandidates(
        organizationId,
        {
          page,
          search,
          type,
          order: '',
          selected_content_id: checkedContent,
        },
        contentId,
        contentType,
      )
        .then((response) => {
          const { results, next } = response.data;

          setHasNextPage(!!next);
          setCurrentPage(page);

          if (isLoadMore) {
            setContents((lastContents) => [
              ...lastContents,
              ...results.map((item: DependencyContentResponseProps) => ({
                id: item.id,
                type: item.type,
                cover: item.cover,
                title: item.title,
                tags: item.tags,
                owner: item.owner,
                start_at: item.start_at,
                finish_at: item.finish_at,
                options: item.options,
              })),
            ]);
            return;
          }

          setContents(
            results.map((item: DependencyContentResponseProps) => ({
              id: item.id,
              type: item.type,
              cover: item.cover,
              title: item.title,
              tags: item.tags,
              owner: item.owner,
              start_at: item.start_at,
              finish_at: item.finish_at,
              options: item.options,
            })),
          );
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while displaying content'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadMoreLoading(false);
        });
    },
    [dispatch, organizationId, t, contentId, contentType, checkedContent],
  );

  const handleChangeType = (option: SelectedOptionValueType) => {
    const currentType = (
      option && 'value' in option ? option.value : 'all'
    ) as keyof typeof AvailableTypes;

    setType(currentType);
    loadContentList(1, search, currentType);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      loadContentList(1, value, type as keyof typeof AvailableTypes);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const getFormattedDescription = (
    tags: number,
    options: number,
    type: string,
  ): string => {
    const textTags: string =
      tags > 0 ? `${tags} ${t('tag', { count: tags })}` : '';
    let textOpts: string =
      options > 0 ? `${options} ${t('option', { count: options })}` : '';

    if (type === 'survey' && options === 0) {
      textOpts = t('No options');
    }

    if (textTags && !textOpts) return textTags;
    else if (!textTags && textOpts) return textOpts;
    else if (textTags && textOpts) return `${textTags} | ${textOpts}`;
    return '';
  };

  const getPeriodLabel = (startAt: string | null, finishAt: string | null) => {
    if (!startAt) return '';
    if (!finishAt) return `${dayjs(startAt).format('DD/MM/YYYY')}`;
    return `${dayjs(startAt).format('DD/MM/YYYY')} ${t('to')} ${dayjs(
      finishAt,
    ).format('DD/MM/YYYY')}`;
  };

  const handleSave = () => {
    onSave(checkedContent);
    dispatch(closeModal());
  };

  const handleSelect = (contentId: string) => {
    if (contentId === checkedContent) {
      setCheckedContent('');
      return;
    }

    setCheckedContent(contentId);
  };

  useEffect(() => {
    if (effectRan.current) {
      return;
    }

    loadContentList();

    return () => {
      effectRan.current = true;
    };
  }, [loadContentList]);

  return (
    <StyledDependencyConfig>
      <Text as="h5" weight="bold" className="modal-title">
        {t('Create content conditional on actions')}
      </Text>
      <Text as="h6" color="grayscale-200" className="modal-description">
        {t(
          'Select one of the contents below to generate a dependency relationship. That is, the public will only be able to access this action if they have performed a previously published action.',
        )}
      </Text>

      <div className="filters">
        <Select
          value={selectedOption}
          setValue={setSelectedOption}
          options={selectOptions.filter(
            (option) =>
              selectedOption &&
              'value' in selectedOption &&
              selectedOption.value !== option.value,
          )}
          className="content-type-select"
          theme="dark"
          onChange={handleChangeType}
        />

        <Input
          placeholder={t('Search by title')}
          icon="search"
          iconColor="primary-color"
          value={search}
          onChange={handleSearch}
        />
      </div>

      {!loading && contents.length > 0 && (
        <div
          className="contents-wrapper default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          <div className="contents">
            {contents.map((content, index) => (
              <DependencyContent
                key={index}
                id={content.id}
                cover={content.cover}
                title={content.title}
                description={getFormattedDescription(
                  content.tags,
                  content.options,
                  content.type,
                )}
                createdAt={getPeriodLabel(content.start_at, content.finish_at)}
                createdBy={content.owner}
                checked={checkedContent === content.id}
                onSelect={(contentId) => handleSelect(contentId)}
              />
            ))}
          </div>
        </div>
      )}

      {!loading && contents.length === 0 && (
        <div className="no-content-message">
          <EmptyMessage title={t('No content found')} icon="stack-line" />
        </div>
      )}

      {loading && <Loading width="80px" height="80px" color="primary-color" />}

      {loadMoreLoading && (
        <Loading
          width="44px"
          height="44px"
          color="primary-color"
          className="load-more"
          type="bubbles"
        />
      )}

      {!loadMoreLoading && (
        <div className="actions">
          <Button
            theme="primary"
            rounded="true"
            className="save-button"
            onClick={handleSave}
          >
            {t('Save')}
          </Button>
        </div>
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() =>
          loadContentList(
            currentPage + 1,
            search,
            type as keyof typeof AvailableTypes,
            true,
          )
        }
        disabled={!hasNextPage || loading || loadMoreLoading}
      />
    </StyledDependencyConfig>
  );
};

export default DependencyConfig;
