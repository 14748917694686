import styled from 'styled-components';

export const StyledResetInteractions = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h5 {
      margin-bottom: 6px;
    }

    .reset-title {
      text-transform: uppercase;
    }

    .generated-code {
      padding: 16px 0px;
      border: 1px solid var(--gray-color);
      font-size: 24px;
      text-align: center;
      margin: 16px 0px;
      margin-top: 32px;
      border-radius: 8px;
      font-weight: bold;
    }
  }

  .modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .page-loading {
    position: fixed;
    top: 0;
    left: 0;
  }
`;
