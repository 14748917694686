import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { cn } from 'utils/cn';
import { limitFileName } from 'utils/files';

import {
  getDriveFiles,
  getRecentDriveFiles,
  getDriveSegmentationOptions,
} from 'apis/driveDashboard';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { showMessage } from 'store/slices/toaster';
import {
  addFileToUploadList,
  setUploadListIsExpanded,
  setUploadListIsVisible,
} from 'store/slices/drive';
import { setModalView } from 'store/slices/modal';
import { uploadNextFile } from 'store/slices/drive/thunks';

import CircularProgressBar from 'components/atom/CircularProgressBar';
import Header from 'components/atom/Header';
import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Loading from 'components/molecule/Loading';
import { Dropdown } from 'components/molecule/DropdownV2';
import Icon from 'components/atom/Icon';
import Dropzone from 'components/molecule/Dropzone';
import PageLoading from 'components/molecule/PageLoading';
import Breadcrumb from 'components/organism/Breadcrumb';

import NewFolder from './components/actions/NewFolder';
import Share from './components/actions/Share';
import DriveCard from './components/DriveCard';
import FilesTable from './components/FilesTable';
import { BreadcrumbItem } from 'apis/general/types';

import {
  FileContentProps,
  FileFilter,
  SpaceInfoProps,
} from './components/FilesTable/types';

import driveIsEmptyImage from 'assets/images/clip-drive-is-empty.svg';

import { StyledDashboard } from './styles';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { parentId } = useParams();
  const { pk: organizationId, module_drive_id } = useAppSelector(
    (store) => store.organization,
  );
  const driveId = useAppSelector((store) => store.organization.module_drive_id);

  const { uploaded_files, upload_in_progress } = useAppSelector(
    (store) => store.drive,
  );

  const [recentFiles, setRecentFiles] = useState<FileContentProps[]>([]);
  const [recentFilesLoading, setRecentFilesLoading] = useState(true);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [files, setFiles] = useState<FileContentProps[]>([]);

  const [spaceInfo, setSpaceInfo] = useState<SpaceInfoProps>({
    available_size: 0,
    size: 0,
    used_size: 0,
    percentage: 0,
  });

  const [filter, setFilter] = useState<FileFilter>({
    page: 1,
    search: '',
    ordering: [],
  });

  const [searchTimeOut, setSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const [dropzoneIsVisible, setDropzoneIsVisible] = useState(false);

  const [segmentationConfig, setSegmentationConfig] = useState(null);

  const [segmentationConfigLoading, setSegmentationConfigLoading] =
    useState(true);

  const [loadingSaveFolder, setLoadingSaveFolder] = useState(false);

  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [hasMoreBreadcrumb, setHasMoreBreadcrumb] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    const { value } = event.target;

    if (value.length > 0 && value.trim() === '') {
      return;
    }

    const timeOut = setTimeout(() => {
      loadFiles({
        ...filter,
        ordering: filter.ordering.toString(),
        search: value,
      });
    }, 1000);

    setSearchTimeOut(timeOut);
    setFilter((updatedDriveFiltersState) => ({
      ...updatedDriveFiltersState,
      search: value,
    }));
  };

  const loadFiles = useCallback(
    (
      filters: { page: number; search: string; ordering: string } = {
        page: 1,
        search: '',
        ordering: '',
      },
    ) => {
      return new Promise((resolve, reject) => {
        setTableLoading(true);

        getDriveFiles(organizationId, filters, parentId ? parentId : '')
          .then((response) => {
            const {
              num_pages,
              results,
              breadcrumb: currentBreadcrumb,
            } = response.data;

            setPageCount(num_pages);
            setFiles(results);

            setFilter({
              ...filters,
              ordering:
                filters.ordering === '' ? [] : filters.ordering.split(','),
            });

            if (parentId) {
              setBreadcrumb(currentBreadcrumb.results);
              setHasMoreBreadcrumb(currentBreadcrumb.has_more);
            }

            resolve(response);
          })
          .catch((error) => {
            if (
              error.response.status !== 404 &&
              error.response.status !== 403
            ) {
              dispatch(
                showMessage({
                  title: t('An unexpected error occurred while loading data'),
                  theme: 'danger',
                  icon: 'close',
                  time: 10000,
                }),
              );
            }

            reject(error);
          })
          .finally(() => {
            setTableLoading(false);
          });
      });
    },
    [dispatch, organizationId, t, parentId],
  );

  const loadRecents = useCallback(() => {
    return new Promise((resolve, reject) => {
      getRecentDriveFiles(organizationId)
        .then((response) => {
          const { results, space } = response.data;

          setRecentFiles(results);
          setSpaceInfo(space);

          resolve(response);
        })
        .catch((error) => {
          if (error.response.status !== 404 && error.response.status !== 403) {
            dispatch(
              showMessage({
                title: t('An unexpected error occurred while loading data'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
          }

          reject(error);
        })
        .finally(() => {
          setRecentFilesLoading(false);
        });
    });
  }, [dispatch, organizationId, t]);

  const loadFilesAndRecents = useCallback(
    (
      filters: { page: number; search: string; ordering: string } = {
        page: 1,
        search: '',
        ordering: '',
      },
    ) => {
      Promise.all([loadFiles(filters), !parentId ? loadRecents() : null]).catch(
        (error) => {
          if (error.response.status === 403) {
            navigate('/');
            dispatch(
              showMessage({
                title: t('An unexpected error occurred while loading data'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }

          if (error.response.status === 404) {
            navigate('/drive');
            return;
          }
        },
      );
    },
    [dispatch, loadFiles, loadRecents, navigate, parentId, t],
  );

  useEffect(() => {
    loadFilesAndRecents({
      ordering: '',
      page: 1,
      search: '',
    });
  }, [loadFilesAndRecents, upload_in_progress]);

  const showNewFolderModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <NewFolder
            setLoadingSaveFolder={setLoadingSaveFolder}
            organizationId={organizationId}
            driveId={driveId}
            parentId={parentId || null}
            setTableLoading={setTableLoading}
          />
        ),
      }),
    );
  };

  const handleSendFiles = (files: File[]) => {
    const filesToUpload = files.slice(0, 10);

    filesToUpload.map((file) => {
      dispatch(
        addFileToUploadList({
          id: uuidv4(),
          progress: 0,
          status: 'pending',
          title: limitFileName(file.name, 100),
          file: file,
          organizationId,
          moduleDriveId: module_drive_id,
          parentId: parentId || null,
        }),
      );
    });
    dispatch(setUploadListIsVisible(true));
    dispatch(setUploadListIsExpanded(true));
    if (
      !uploaded_files.some(
        (file) => file.status === 'pending' || file.status === 'in-progress',
      )
    ) {
      dispatch(uploadNextFile());
    }
  };

  const handleNewFileClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;

    input.addEventListener('change', () => {
      if (input.files) {
        handleSendFiles(Array.from(input.files));
      }
    });

    input.click();
  };

  const handleDragEnter = () => {
    setDropzoneIsVisible(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragLeave = (event: any) => {
    const dropzoneElement = document.querySelector('.page-content');

    if (dropzoneElement && !dropzoneElement.contains(event.relatedTarget)) {
      event.preventDefault();
      event.stopPropagation();
      setDropzoneIsVisible(false);
    }
  };

  const handleDrop = () => {
    setDropzoneIsVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragOverOutside = (event: any) => {
    if (
      event.clientY < 0 ||
      event.clientX < 0 ||
      event.clientX >= window.innerWidth ||
      event.clientY >= window.innerHeight
    ) {
      setDropzoneIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('dragover', handleDragOverOutside);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragover', handleDragOverOutside);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  const showErrorMessage = useCallback(
    (message: string) => {
      dispatch(
        showMessage({
          title: message,
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
    },
    [dispatch],
  );

  const loadSegmentationConfig = useCallback(
    (contentId?: string) => {
      getDriveSegmentationOptions(organizationId, contentId)
        .then((response) => {
          setSegmentationConfig(
            response.data.map((configItems: { url: string | null }) => ({
              ...configItems,
              url: configItems.url ? configItems.url.replace('/v1', '') : null,
            })),
          );
        })
        .catch((error) => {
          if (error.response.status !== 403) {
            showErrorMessage(
              t(
                'An unexpected error occurred while loading the sharing configuration',
              ),
            );
          }
        })
        .then(() => {
          setSegmentationConfigLoading(false);
        });
    },
    [organizationId, showErrorMessage, t],
  );

  const handleShare = (
    fileId: string,
    fileUrl: string,
    segmentationItem: string | undefined,
    isShared: boolean,
  ) => {
    dispatch(
      setModalView({
        show: true,
        width: '888px',
        content: (
          <Share
            fileId={fileId}
            fileUrl={fileUrl}
            isShared={isShared}
            segmentationItem={segmentationItem}
            segmentationConfig={segmentationConfig}
            onSave={() => {
              loadFiles({ ...filter, ordering: filter.ordering.toString() });
              loadRecents();
            }}
          />
        ),
        disableBackgroundClick: true,
        hideCloseButton: true,
      }),
    );
  };

  useEffect(() => {
    loadSegmentationConfig();
  }, [loadSegmentationConfig]);

  useEffect(() => {
    if (parentId) {
      setRecentFiles([]);
    }
  }, [parentId]);

  return (
    <StyledDashboard>
      {loadingSaveFolder && <PageLoading />}
      <Header
        leftSideContent={
          <div className="left-side-content">
            <Text as="h3" weight="700" color="grayscale-400">
              {t('Drive')}
            </Text>
            <Dropdown.Root
              trigger={
                <p className="text-primary-color flex items-center gap-1 ml-2">
                  <Icon name="add" color="primary-color" />{' '}
                  <span className="mt-0.5">{t('New')}</span>
                </p>
              }
            >
              <>
                <Dropdown.Item onClick={showNewFolderModal}>
                  {t('New folder')}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleNewFileClick}>
                  {t('Upload file')}
                </Dropdown.Item>
              </>
            </Dropdown.Root>
            {parentId && (
              <Input
                theme="post"
                icon="search"
                iconColor="primary-color-200"
                placeholder={t('Search by name')}
                value={filter.search}
                onChange={handleSearchChange}
              />
            )}
          </div>
        }
      />
      {parentId && (
        <div
          className={`breadcrumb-container ${
            dropzoneIsVisible ? 'hidden' : ''
          }`}
        >
          <Breadcrumb
            links={breadcrumb.map((item) => ({
              id: item.id,
              label: item.name,
              action: () => navigate(`/drive/dashboard/${item.id}`),
              path: `/drive/dashboard/${item.id}`,
            }))}
            fetchUrl={`/drive/org/${organizationId}/files/${breadcrumb[0]?.id}/breadcrumb`}
            rootPath={parentId ? '/drive/dashboard' : undefined}
            hasMore={hasMoreBreadcrumb}
            hasBackButton
            hasCharLimit
            limit={27}
          />
        </div>
      )}
      <div
        className="page-content"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Dropzone
          className={cn(
            'absolute top-8 left-[54px] h-[calc(100%-64px)] w-[calc(100%-108px)] z-10',
            {
              hidden: !dropzoneIsVisible,
            },
          )}
          dragOnly
          onDrop={(files) => {
            handleSendFiles(files);
          }}
        />

        {tableLoading && recentFilesLoading ? (
          <Loading width="80px" height="80px" color="primary-color" />
        ) : files.length === 0 && recentFiles.length === 0 ? (
          <div className="empty-message-wrapper">
            <EmptyMessage
              title={t('No files')}
              description={t(
                'Create folders, upload files and manage their distribution to workforce.',
              )}
              src={driveIsEmptyImage}
              showImage
            />
            <Button theme="link-primary" onClick={handleNewFileClick}>
              {t('Send file')}
            </Button>
          </div>
        ) : (
          <div
            className={`drive-content pb-11 ${
              dropzoneIsVisible ? 'hidden' : ''
            }`}
          >
            <div
              className={`drive-header ${
                parentId || filter.search !== '' ? 'listing-folder' : ''
              }`}
            >
              {!parentId && (
                <>
                  <Input
                    theme="post"
                    icon="search"
                    iconColor="primary-color-200"
                    placeholder={t('Search by name')}
                    value={filter.search}
                    onChange={handleSearchChange}
                  />

                  <Tooltip
                    id="progress-tooltip"
                    content={
                      <>
                        <Text as="p" color="white-color">
                          {t('Total')}: {spaceInfo.size}
                        </Text>
                        <Text as="p" color="white-color">
                          {t('Used')}: {spaceInfo.used_size}
                        </Text>
                        <Text as="p" color="white-color">
                          {t('Available')}: {spaceInfo.available_size}
                        </Text>
                      </>
                    }
                    place="top"
                  >
                    <CircularProgressBar completed={spaceInfo.percentage} />
                  </Tooltip>
                </>
              )}
            </div>

            {!parentId && filter.search === '' && (
              <>
                <Text
                  as="h6"
                  weight="500"
                  color="grayscale-200"
                  className="recents-title"
                >
                  {t('RECENTS')}
                </Text>
                <div className="recent-cards-wrapper">
                  {recentFilesLoading || segmentationConfigLoading ? (
                    <Loading />
                  ) : (
                    recentFiles.map((file, index) => (
                      <DriveCard
                        key={index}
                        id={file.id}
                        index={index}
                        name={file.name}
                        type={file.is_folder ? 'folder' : file.extension}
                        size={file.size_format}
                        date={file.created_at}
                        disableBackgroundClick={!file.is_folder}
                        setTableLoading={setTableLoading}
                        onShare={(id) => {
                          handleShare(
                            id,
                            file.file_app_url,
                            file.segmentations[0],
                            file.is_shared,
                          );
                        }}
                        fileLink={file.file_app_url}
                        viewFile={file.url_view}
                      />
                    ))
                  )}
                </div>
              </>
            )}

            <FilesTable
              organizationId={organizationId}
              files={files}
              fetchFiles={loadFiles}
              fetchRecents={loadRecents}
              pageCount={pageCount}
              loading={tableLoading || segmentationConfigLoading}
              setTableLoading={setTableLoading}
              filter={filter}
              setFilter={setFilter}
              onShare={handleShare}
              parentId={parentId}
            />
          </div>
        )}
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
