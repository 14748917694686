import styled from 'styled-components';

import { StyledMultiSelectProps } from './types';

const boxShadow = '0px 0px 25px 4px rgba(0, 0, 0, 0.05);';

const getInputBorder = (menuIsOpen: boolean) => {
  if (menuIsOpen) return '10px 10px 0px 0px';
  return '10px';
};

export const StyledMultiSelect = styled.div<StyledMultiSelectProps>`
  display: flex;

  .dropdown {
    flex: 1;
    box-shadow: ${({ menuIsOpen }) => (menuIsOpen ? boxShadow : 'none')};
    border-radius: 10px;

    .dropdown-toggle {
      width: 100%;
      padding: 0px;
      border-radius: 10px;

      :hover {
        * {
          color: var(--grayscale-200);
        }
      }

      .button-content {
        width: 100%;
        background: none;
        border-radius: 10px;

        .input {
          width: 100%;
          border-radius: ${({ menuIsOpen }) => getInputBorder(menuIsOpen)};

          .input-content {
            border-radius: ${({ menuIsOpen }) => getInputBorder(menuIsOpen)};

            & > .icon {
              color: var(--primary-color);
            }

            &:focus-within {
              border-color: var(--gray-color);
            }

            input {
              color: var(--dark-color);
            }
          }
        }
      }
    }

    .dropdown-content {
      top: 44px;
      box-shadow: 0px 16px 25px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 10px 10px;
      display: flex;
      flex-direction: column;
      padding: 0px !important;

      .item-list {
        min-height: 60px;
        max-height: 250px;
        overflow-y: scroll;

        .button {
          width: 100%;
          justify-content: flex-start;
          border-radius: 0px;
          border-bottom: 1px solid var(--gray-color);
          min-height: 54px;

          &:first-child {
            border-top: 1px solid var(--gray-color);
          }

          .button-content {
            width: 100%;
            padding: 8px;
            display: flex;
            align-items: center;

            .checkbox {
              width: max-content;
              position: relative;
              top: -10px;
              pointer-events: none;

              &.disabled span.checkmark {
                background-color: var(--white-color);
              }

              &.disabled input:checked ~ span.checkmark {
                background-color: var(--primary-color);
                opacity: 1;
              }

              &.check-disabled {
                opacity: 0.3;
              }
            }
          }
        }
      }

      .empty-message {
        min-width: 100%;
        padding: 0px 32px;
        margin-top: 32px;
      }
    }
  }

  & > .button {
    margin-left: 16px;
  }
`;
