import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import Breadcrumb from 'components/organism/Breadcrumb';
import CollaboratorsTable from './components/CollaboratorsTable';

import { StyledCollaboratorsConfig } from './styles';

const CollaboratorsConfig: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const handleBack = () => {
    navigate('/settings');
  };

  return (
    <StyledCollaboratorsConfig className="mural-content-config-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <Header
        className="secondary-header"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              { label: t('Collaborators'), action: () => navigate('') },
            ]}
          />
        }
        rightSideContent={
          <Button leftIcon="add" theme="link-primary" disabled>
            {t('New collaborator')}
          </Button>
        }
      />
      <div className="content-config-container">
        <div className="content-side">
          <CollaboratorsTable />
        </div>
      </div>
    </StyledCollaboratorsConfig>
  );
};

export default CollaboratorsConfig;
