import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledWizard, StyledWizardBalloon } from './styles';

import Header from 'components/atom/Header';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';

import Logo from 'components/molecule/Logo';
import ChangeLanguage from 'components/molecule/ChangeLanguage';
import PageLoading from 'components/molecule/PageLoading';

import Toaster from 'components/organism/Toaster';

import wizardImage1 from 'assets/images/wizard-photo-1.png';

import AccountStage from './components/AccountStage';
import CompanyStage from './components/CompanyStage';
import { FieldErrors as AccountStageFieldErrors } from './components/AccountStage/types';
import { FieldErrors as CompanyStageFieldErrors } from './components/CompanyStage/types';

import { postRegisterCompany } from 'apis/wizard';
import Card from 'components/atom/Card';

const images = [wizardImage1];

interface AccountStageInfo {
  admin_full_name: string;
  admin_email: string;
  admin_password: string;
}

interface ErrorResponse {
  field: string;
  error: string;
}

const Wizard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    show: false,
  });
  const [accountStageInfo, setAccountStageInfo] = useState<AccountStageInfo>({
    admin_full_name: '',
    admin_email: '',
    admin_password: '',
  });
  const [accountStageFieldErrors, setAccountStageFieldErrors] =
    useState<AccountStageFieldErrors>({
      admin_full_name: {
        hasError: false,
        errorMessage: '',
      },
      admin_email: {
        hasError: false,
        errorMessage: '',
      },
      admin_password: {
        hasError: false,
        errorMessage: '',
      },
      admin_confirm_password: {
        hasError: false,
        errorMessage: '',
      },
    });
  const [companyStageFieldErrors, setCompanyStageFieldErrors] =
    useState<CompanyStageFieldErrors>({
      company_name: {
        hasError: false,
        errorMessage: '',
      },
      company_short_name: {
        hasError: false,
        errorMessage: '',
      },
      company_slug: {
        hasError: false,
        errorMessage: '',
      },
      company_country: {
        hasError: false,
        errorMessage: '',
      },
      company_employees: {
        hasError: false,
        errorMessage: '',
      },
      company_segment: {
        hasError: false,
        errorMessage: '',
      },
    });
  const [stage, setStage] = useState(0);

  // image change (carrossel)
  useEffect(() => {
    let nextImageIndex: React.SetStateAction<number>;

    if (imageIndex === images.length - 1) {
      nextImageIndex = 0;
    } else {
      nextImageIndex = imageIndex + 1;
    }

    setTimeout(() => setImageIndex(nextImageIndex), 5000);
  }, [imageIndex]);

  // if there is some error in account stage, change to that stage (this is useful after calling the company register endpoint)
  useEffect(() => {
    const hasError = Object.values(accountStageFieldErrors).some(
      (fieldError) => fieldError.hasError,
    );
    if (hasError) {
      setStage(0);
    }
  }, [accountStageFieldErrors]);

  const handleFieldErrors = (errors: ErrorResponse[]) => {
    // set errors to accountStage if endpoint returned some account related errors
    setAccountStageFieldErrors((prevErrors) => {
      const updatedFieldErrors: AccountStageFieldErrors = { ...prevErrors };

      errors.forEach((error) => {
        const { field, error: errorMessage } = error;
        if (field && field in updatedFieldErrors) {
          updatedFieldErrors[field as keyof AccountStageFieldErrors] = {
            hasError: true,
            errorMessage,
          };
        }
      });
      return updatedFieldErrors;
    });

    // set errors to companyStaged if endpoint returned some company related errors
    setCompanyStageFieldErrors((prevErrors) => {
      const updatedFieldErrors: CompanyStageFieldErrors = { ...prevErrors };

      errors.forEach((error) => {
        const { field, error: errorMessage } = error;
        if (field && field in updatedFieldErrors) {
          updatedFieldErrors[field as keyof CompanyStageFieldErrors] = {
            hasError: true,
            errorMessage,
          };
        }
      });
      return updatedFieldErrors;
    });
  };

  const accountStageSubmit = (
    admin_full_name: string,
    admin_email: string,
    admin_password: string,
  ) => {
    setAccountStageInfo({
      admin_full_name,
      admin_email,
      admin_password,
    });
    setStage(1);
  };

  const companyStageSubmit = (
    company_name: string,
    company_short_name: string,
    company_slug: string,
    company_country: string,
    company_employees: string,
    company_segment: string,
  ) => {
    setLoading(true);
    postRegisterCompany({
      admin_full_name: accountStageInfo.admin_full_name,
      admin_email: accountStageInfo.admin_email,
      admin_password: accountStageInfo.admin_password,
      company_name: company_name,
      company_short_name: company_short_name,
      company_slug: company_slug,
      company_country: company_country,
      company_employees: company_employees,
      company_segment: company_segment,
    })
      .then((response) => {
        localStorage.removeItem('wizard-admin-full-name');
        localStorage.removeItem('wizard-admin-email');
        localStorage.removeItem('wizard-company-country');
        localStorage.removeItem('wizard-company-employees');
        localStorage.removeItem('wizard-company-segment');
        localStorage.removeItem('wizard-company-name');
        localStorage.removeItem('wizard-company-short-name');
        localStorage.removeItem('wizard-company-slug');

        const token = response.data.token;
        navigate(`/confirm-account/${token}`, { replace: true });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          handleFieldErrors(err.response.data.errors);
        } else {
          setErrorMessage({
            title: t(
              'There was an error trying to register. Please, try again later.',
            ),
            show: true,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const companyStageGoBack = () => {
    setStage(0);
  };

  return (
    <StyledWizard carrosselImage={images[imageIndex]}>
      <Toaster
        show={errorMessage.show.toString()}
        time={10000}
        icon="close"
        title={errorMessage.title}
        theme="danger"
        onClose={() => setErrorMessage({ ...errorMessage, show: false })}
      />

      {loading && <PageLoading />}

      <ChangeLanguage />

      <Card noBorder="true">
        <div className="wizard-wrapper">
          <Header className="header" leftSideContent={<Logo />} />
          {stage === 0 && (
            <AccountStage
              stageErrors={accountStageFieldErrors}
              formSubmit={accountStageSubmit}
              stageInfo={accountStageInfo}
            />
          )}
          {stage === 1 && (
            <CompanyStage
              stageErrors={companyStageFieldErrors}
              formSubmit={companyStageSubmit}
              goBack={companyStageGoBack}
            />
          )}
        </div>
        <div className="carrossel-wrapper">
          <StyledWizardBalloon
            top="10%"
            left="45%"
            borderRadius="24px 24px 24px 0px"
            width="50%"
          >
            <Text
              as="h6"
              color="white-color"
              weight="600"
              className="balloon-text"
            >
              {t('Efficient and noise-free corporate communication')}
            </Text>
          </StyledWizardBalloon>
          <StyledWizardBalloon
            top="50%"
            left="15%"
            borderRadius="24px 0px 24px 24px"
            width="70px"
          >
            <Icon name="thumb-up-fill" color="white-color" />
          </StyledWizardBalloon>
          <StyledWizardBalloon
            bottom="10%"
            right="5%"
            borderRadius="0px 24px 24px 24px"
            width="40%"
          >
            <Text
              as="h6"
              color="white-color"
              weight="600"
              className="balloon-text"
            >
              {t('Safe and healthy digital environment')}
            </Text>
          </StyledWizardBalloon>
        </div>
      </Card>
    </StyledWizard>
  );
};

export default Wizard;
