import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createDenylistWord,
  getDenylistWords,
  updateDenylistWord,
  removeDenylistWord,
} from 'apis/core';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import EmptyMessage from 'components/molecule/EmptyMessage';
import DenylistItem from './components/DenylistItem';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import Input from 'components/molecule/Input';
import Confirm from 'components/molecule/Confirm';
import PageLoading from 'components/molecule/PageLoading';

import { DenylistWord } from './types';

import { StyledDenylistConfig } from './styles';

let currentScrollTop = 0;

const DenylistConfig: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const emptyDenylistMessage = t(
    'Add words and quotes that can be blocked from being seen in the community',
  );

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [words, setWords] = useState<DenylistWord[]>([]);
  const [, setCustomFieldsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [search, setSearch] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [termNotFound, setTermNotFound] = useState(false);

  const loadDenylist = useCallback(
    (page: number, search: string, toTheCurrentPage?: boolean) => {
      getDenylistWords(organizationId, page, search, toTheCurrentPage)
        .then((response) => {
          const { results, next, recordsTotal } = response.data;

          setCustomFieldsCount(recordsTotal);
          setHasNextPage(!!next);
          setPage(page);
          setTermNotFound(search.length > 0 && recordsTotal === 0);

          if (page === 1 || toTheCurrentPage) {
            setWords(
              results.map((item: { id: string; name: string }) => ({
                ...item,
                initialOpen: false,
                inEdit: false,
                hasError: false,
                errorMessage: '',
              })),
            );
            return;
          }

          setWords((lastEditorials) => [
            ...lastEditorials,
            ...results.map((item: { id: string; name: string }) => ({
              ...item,
              initialOpen: false,
              inEdit: false,
              hasError: false,
              errorMessage: '',
            })),
          ]);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }
          dispatch(
            showMessage({
              title: t('An error occurred while fetching the words'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadMoreLoading(false);
        });
    },
    [dispatch, t, organizationId],
  );

  const handleSearch = (value: string) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const timeOut = setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollTop = 0;
      }
      setLoading(true);
      loadDenylist(1, value);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const handleAdd = () => {
    setWords([
      {
        id: undefined,
        name: '',
        initialOpen: true,
        inEdit: true,
      },
      ...words,
    ]);
  };

  const createWord = (name: string, index: number) => {
    createDenylistWord(organizationId, {
      name,
    })
      .then(() => {
        setWords([]);
        setLoading(true);
        loadDenylist(1, search);
      })
      .catch((error) => {
        if (error.response.data.code === 'field_error') {
          setWords(() => {
            const currentWords = [...words];
            currentWords[index].inEdit = true;
            currentWords[index].initialOpen = true;
            currentWords[index].hasError = true;
            currentWords[index].errorMessage =
              error.response.data.errors[0].error;
            return currentWords;
          });
          return;
        } else if (
          error.response.data.code === 'invalid' ||
          error.response.data.code.indexOf('invalid') !== -1
        ) {
          dispatch(
            showMessage({
              title: error.response.data.errors[0],
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }

        dispatch(
          showMessage({
            title: t('An error occurred while saving the word in the denylist'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const updateWord = (id: string, name: string, index: number) => {
    updateDenylistWord(organizationId, id, {
      name,
    })
      .then(() => {
        setWords([]);
        setLoading(true);
        loadDenylist(page, search, true);
      })
      .catch((error) => {
        if (error.response.data.code === 'field_error') {
          setWords(() => {
            const currentWords = [...words];
            currentWords[index].hasError = true;
            currentWords[index].inEdit = true;
            currentWords[index].initialOpen = true;
            currentWords[index].errorMessage =
              error.response.data.errors[0].error;
            return currentWords;
          });
          return;
        }

        dispatch(
          showMessage({
            title: t('An error occurred while saving the word in the denylist'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleSave = (value: string, index: number) => {
    currentScrollTop = scrollElement ? scrollElement.scrollTop : 0;
    setSaveLoading(true);
    const currentWords = [...words];
    const currentWordId = currentWords[index].id;
    if (currentWordId) {
      updateWord(currentWordId, value, index);
      return;
    }
    createWord(value, index);
  };

  const handleEdit = (index: number) => {
    setWords(() => {
      const currentOptions = [...words];
      currentOptions[index].inEdit = true;
      return currentOptions;
    });
  };

  const deleteWord = (id: string) => {
    setSaveLoading(true);
    removeDenylistWord(organizationId, id)
      .then(() => {
        setWords([]);
        setLoading(true);
        loadDenylist(page, search, true);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An error occurred while removing the word from the denylist',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleRemove = (index: number) => {
    currentScrollTop = scrollElement ? scrollElement.scrollTop : 0;
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: (
          <Confirm
            title={t('Remove word?')}
            subtitle={t(
              'By continuing, the word will be removed from the denylist',
            )}
            onConfirm={() => {
              const { id } = words[index];
              if (id) {
                deleteWord(id);
              }
            }}
          />
        ),
        disableBackgroundClick: false,
        hideCloseButton: false,
      }),
    );
  };

  const handleCancel = (index: number) => {
    const currentWords = [...words];

    if (!currentWords[index].id) {
      currentWords.splice(index, 1);
      setWords(currentWords);
      return;
    }

    setWords(() => {
      currentWords[index].initialOpen = false;
      currentWords[index].inEdit = false;
      currentWords[index].hasError = false;
      return currentWords;
    });
  };

  useEffect(() => {
    loadDenylist(1, '');
  }, [loadDenylist]);

  useEffect(() => {
    if (scrollElement && page > 1 && currentScrollTop !== 0) {
      scrollElement.scroll(0, currentScrollTop);
    }
  }, [scrollElement, page]);

  return (
    <StyledDenylistConfig>
      <Text as="h3" weight="700">
        {t('Denylist')}
      </Text>
      <Text as="h5">{t('Restricted use words')}</Text>
      <Text color="grayscale-200">
        {t(
          'Create a list of words blocked to be used on your organization, even when used, they will not be seen by users',
        )}
      </Text>
      {!loading && (
        <div className="denylist-items-header">
          <Button
            theme="link-primary"
            leftIcon="add"
            onClick={handleAdd}
            disabled={words.some((item) => item.initialOpen || item.inEdit)}
            className="add-word-button"
          >
            {t('New entry')}
          </Button>
          <Input
            icon="search"
            iconColor="primary-color"
            theme="post"
            placeholder={t('Search for entries')}
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
            disabled={words.some((item) => item.initialOpen || item.inEdit)}
          />
        </div>
      )}
      {!loading && words.length > 0 && (
        <div
          className="denylist-items default-scroll"
          ref={(currentRef) => {
            setScrollElement(currentRef);
          }}
        >
          {words.map((word, index) => (
            <DenylistItem
              key={index}
              index={index}
              initialOpen={word.initialOpen}
              name={word.name}
              onEdit={handleEdit}
              onRemove={handleRemove}
              onSave={handleSave}
              onCancel={handleCancel}
              disabled={words.some((item) => item.initialOpen || item.inEdit)}
              hasError={word.hasError}
              errorMessage={word.errorMessage}
            />
          ))}
          {loadMoreLoading && <Loading type="bubbles" />}
        </div>
      )}

      {loading && <Loading type="bubbles" />}

      {!loading && !saveLoading && words.length === 0 && (
        <EmptyMessage
          title={!termNotFound ? t('No words added') : ''}
          description={!termNotFound ? emptyDenylistMessage : ''}
          icon="stack-line"
        />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadMoreLoading(true);
          loadDenylist(page + 1, search);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />

      {saveLoading && <PageLoading />}
    </StyledDenylistConfig>
  );
};

export default DenylistConfig;
