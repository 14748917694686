import styled from 'styled-components';

export const StyldContentHistoryHeader = styled.div`
  width: 100%;
  padding: 32px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin-top: 32px;
    }
  }

  .react-select {
    .order-option {
      display: flex;
      align-items: center;
      padding: 12px;
    }

    .react-select__control {
      padding: 0px;
      .order-option {
        padding: 12px 12px 12px 0px;
      }
    }

    .react-select__menu {
      min-width: 220px;
    }
  }
`;
