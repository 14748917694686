import React, { useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import {
  getGeneralAddContent,
  createGeneralAddContent,
  updateGeneralAddContent,
  publishGeneralAddContent,
} from 'apis/survey';

import SurveyQuestions from '../SurveyQuestions';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Header from 'components/atom/Header';
import ConfirmPublish from '../ConfirmPublish';
import Button from 'components/molecule/Button';
import Loading from 'components/molecule/Loading';
import Confirm from 'components/molecule/Confirm';
import Input from 'components/molecule/Input';
import Tooltip from 'components/atom/Tooltip';
import Textarea from 'components/molecule/Textarea';
import SwitchButton from 'components/atom/SwitchButton';

import {
  contentStateFactory,
  parseSchedulerDataToStateFormat,
} from '../../utils';

import { apiDateToDayjsFormat } from 'utils/date';
import { generalAPIDataFactory } from './utils';
import { schedulerInitialState } from '../PublicationDateModal/components/RecurrenceConfig/utils';
import { abbreviateNumberFormatter } from 'utils/numbers';

import {
  GeneralAddContentsProps,
  GeneralAddContentFields,
  GeneralAddContentState,
  QuestionList,
} from './types';

import {
  SurveyContentPostData,
  SurveyContentPostSendData,
} from 'apis/survey/types';

import { StyledGeneralAdd } from './styles';

import QuestionAttentionImage from './components/QuestionAttentionImage';
import CoverImage from 'components/organism/CoverImage';
import { FileUploadModules } from 'services/upload/types';
import { CoverProps } from 'components/organism/CoverImage/types';

const GeneralAdd: React.FC<GeneralAddContentsProps> = ({
  contentState,
  setContentState,
  setInitialChangeControl,
  segmentationConfig,
  segmentationLoading,
  loadSegmentationConfig,
  segmentationTotalTarget,
  contentStatus,
  setContentStatus,
  setCanEditFinishAt,
  setChangeControl,
  setNotificationChangeControl,
  notificationValues,
  setNotificationValues,
  segmentationData,
  setSegmentationId,
  setSegmentationChangeControl,
  getPeriodButtonLabel,
  isEditable,
  showSegmentationModal,
  showInstructionsModal,
  instructions,
  setInstructions,
  setInstructionsChangeControl,
  showPublicationDateModal,
  showSuccessMessage,
  showErrorMessage,
  handleFieldErrors,
  canEdit,
  setCanEdit,
  withRecurrence,
  setWithRecurrence,
  hideAnswers,
  setHideAnswers,
  shuffle,
  setShuffle,
  surveyPageTitle,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pk: organizationId } = useAppSelector((state) => ({
    pk: state.organization.pk,
  }));

  const { researchType: contentType = '', contentId } = useParams();

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: GeneralAddContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: GeneralAddContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const listQuestionsRef = useRef<QuestionList | null>(null);

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    setContentState((updatedState: GeneralAddContentFields) => ({
      ...updatedState,
      description: {
        ...updatedState.description,
        value,
        error: {
          hasError: false,
          errorMessage: '',
        },
      },
    }));
  };

  const createGeneralSurvey = (
    currentContentState: GeneralAddContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: SurveyContentPostSendData =
      generalAPIDataFactory(currentContentState);

    return new Promise<string>((resolve, reject) => {
      createGeneralAddContent(organizationId, data, contentType)
        .then((response) => {
          const { id } = response.data;

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );
          setInstructionsChangeControl(
            currentContentState.instructions || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Survey has been saved as a draft'));
          }

          getGeneralSurvey();

          navigate(`/research/${contentType}/edit/${id}`);
          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }
          showErrorMessage(t('An error occurred while saving survey'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const updateGeneralSurvey = (
    currentContentState: GeneralAddContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: SurveyContentPostSendData =
      generalAPIDataFactory(currentContentState);

    return new Promise<string>((resolve, reject) => {
      updateGeneralAddContent(
        organizationId,
        contentId || '',
        contentType,
        data,
      )
        .then((response) => {
          const { id } = response.data;

          if (contentStatus !== 'draft') {
            navigate('/research/');
            return;
          }

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );
          setInstructionsChangeControl(
            currentContentState.instructions || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Survey has been saved as a draft'));
          }

          getGeneralSurvey();

          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }

          if (code === 'invalid' || code.indexOf('invalid') !== -1) {
            showErrorMessage(errors[0]);
            return;
          }

          showErrorMessage(t('An error occurred while saving survey'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const publishGeneralSurveyContent = (id: string) => {
    dispatch(setPageLoading(true));

    publishGeneralAddContent(organizationId, id)
      .then(() => {
        showSuccessMessage(t('The survey has been published'));
        navigate('/research/');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while publishing the survey'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSave = (saveAndPublish = false) => {
    if (
      (listQuestionsRef.current &&
        listQuestionsRef.current.saveAllQuestions()) ||
      contentStatus === ''
    ) {
      const contentGeneralFields = {
        notifications: notificationValues,
        segmentations: segmentationData,
        status: contentStatus,
        type: contentType,
        with_recurrence: withRecurrence,
        anonymous: hideAnswers,
        shuffle: shuffle,
        instructions: instructions,
      };
      const currentContentState = contentStateFactory(
        contentState,
        contentGeneralFields,
      );
      if (!contentId) {
        createGeneralSurvey(currentContentState, saveAndPublish).then((id) => {
          if (saveAndPublish) {
            publishGeneralSurveyContent(id);
          }
        });
        return;
      }
      updateGeneralSurvey(currentContentState, saveAndPublish).then((id) => {
        if (saveAndPublish) {
          publishGeneralSurveyContent(id);
        }
      });
    } else {
      showErrorMessage(
        t('There are errors in your questions. Please check and try again.'),
      );
    }
  };

  const handlePublishChanges = () => {
    dispatch(
      setModalView({
        show: true,
        width: '388px',
        content: (
          <Confirm
            title={t('Publish changes')}
            subtitle={t('Are you sure you want to save the changes?')}
            onConfirm={handleSave}
          />
        ),
      }),
    );
  };

  const handlePublishClick = () => {
    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at: {
          ...updatedState.start_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at_time.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    dispatch(
      setModalView({
        show: true,
        width: '664px',
        content: (
          <ConfirmPublish
            startAt={dayjs(contentState.start_at.value || Date.now()).format(
              'DD/MM/YYYY',
            )}
            startAtTime={contentState.start_at_time.value}
            finishAt={
              contentState.finish_at.value
                ? dayjs(contentState.finish_at.value).format('DD/MM/YYYY')
                : null
            }
            finishAtTime={contentState.finish_at_time.value}
            onConfirm={() => handleSave(true)}
            withRecurrence={withRecurrence}
            scheduleState={{
              scheduler_start_date_at: contentState.scheduler_start_date_at,
              scheduler_finish_date_at: contentState.scheduler_finish_date_at,
              content_start_time_at: contentState.content_start_time_at,
              content_finish_time_at: contentState.content_finish_time_at,
              content_duration: contentState.content_duration,
              content_week_days: contentState.content_week_days,
              scheduler_var: contentState.scheduler_var,
              scheduler_type: contentState.scheduler_type,
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const handleGeneralSurveyLoadResponse = useCallback(
    (data: SurveyContentPostData) => {
      const {
        cover,
        title,
        description,
        start_at,
        finish_at,
        status,
        segmentations,
        notifications,
        instructions,
        type,
        scheduler,
        can_edit,
        anonymous,
        shuffle,
      } = data;

      const formattedStartAt = apiDateToDayjsFormat(start_at || '');
      const formattedFinishAt = apiDateToDayjsFormat(finish_at || '');

      const [startAt, startAtTime] = formattedStartAt.split(' ');
      const [finishAt, finishAtTime] = formattedFinishAt.split(' ');

      setContentState((lastContentFields) => {
        const currentFields = lastContentFields as GeneralAddContentFields;

        const newContentState: GeneralAddContentFields = {
          cover: {
            ...currentFields.cover,
            value: cover && cover.id ? cover.id : '',
            url: cover && cover.path ? cover.path : '',
          },
          title: { ...currentFields.title, value: title || '' },
          description: {
            ...currentFields.description,
            value: description || '',
          },
          start_at: {
            ...currentFields.start_at,
            value: startAt ? dayjs(startAt).toDate() : undefined,
          },
          start_at_time: {
            ...currentFields.start_at_time,
            value: startAtTime ? startAtTime.split(':').join(':') : '',
          },
          finish_at: {
            ...currentFields.finish_at,
            value: finishAt ? dayjs(finishAt).toDate() : undefined,
          },
          finish_at_time: {
            ...currentFields.finish_at_time,
            value: finishAtTime ? finishAtTime.split(':').join(':') : '',
          },
          ...parseSchedulerDataToStateFormat(scheduler),
        };
        setContentStatus(status);
        setNotificationValues(notifications);
        setInstructions(instructions);
        setInstructionsChangeControl(instructions);
        setNotificationChangeControl(notifications);
        setCanEditFinishAt(true);
        setCanEdit(can_edit);
        setWithRecurrence(!!scheduler);
        setHideAnswers(!!anonymous);
        setShuffle(!!shuffle);

        if (segmentations) {
          setSegmentationId(segmentations[0]);
        }

        setChangeControl(
          contentStateFactory(newContentState, {
            notifications: undefined,
            instructions: null,
            segmentations: undefined,
            status,
            type,
            with_recurrence: !!scheduler,
          }),
        );

        return newContentState;
      });
    },
    [
      setContentState,
      setContentStatus,
      setNotificationValues,
      setInstructions,
      setNotificationChangeControl,
      setInstructionsChangeControl,
      setCanEditFinishAt,
      setCanEdit,
      setWithRecurrence,
      setHideAnswers,
      setShuffle,
      setChangeControl,
      setSegmentationId,
    ],
  );

  const getGeneralSurvey = useCallback(() => {
    if (!contentId) return;
    dispatch(setPageLoading(true));

    getGeneralAddContent(organizationId, contentId)
      .then((response) => {
        handleGeneralSurveyLoadResponse(response.data);
        const { segmentations, content_id } = response.data;
        if (segmentations && segmentations[0]?.id) {
          loadSegmentationConfig(segmentations[0].id, content_id);
        }
      })
      .catch(() => {
        navigate('/error-404');
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  }, [
    contentId,
    dispatch,
    handleGeneralSurveyLoadResponse,
    navigate,
    organizationId,
    loadSegmentationConfig,
  ]);

  useEffect(() => {
    if (contentType !== 'general') {
      navigate('/page-404');
      return;
    }

    if (contentId) {
      getGeneralSurvey();
      return;
    }

    setInitialChangeControl();
  }, [
    contentType,
    contentId,
    navigate,
    contentStatus,
    getGeneralSurvey,
    setInitialChangeControl,
    canEdit,
    dispatch,
  ]);

  return (
    <StyledGeneralAdd>
      <Header
        leftSideContent={
          <div className="main-left-actions">
            <Text as="h6" weight="700">
              {contentId ? t('Edit survey') : surveyPageTitle}
            </Text>
            <Button
              theme="link-primary"
              rightIcon={!segmentationLoading ? 'arrow-down-s-line' : ''}
              onClick={showSegmentationModal}
              disabled={segmentationLoading || !segmentationConfig}
            >
              {!segmentationLoading &&
                t('Target audience ({{total}})', {
                  total: abbreviateNumberFormatter(segmentationTotalTarget),
                })}
              {segmentationLoading && (
                <Loading type="bubbles" color="grayscale-100" />
              )}
            </Button>
          </div>
        }
        rightSideContent={
          <div className="main-right-actions">
            <Button
              leftIcon={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'error-warning-fill'
                  : ''
              }
              theme={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'link-danger'
                  : 'link-primary'
              }
              rightIcon="arrow-down-s-line"
              onClick={showPublicationDateModal}
              disabled={!canEdit}
            >
              {getPeriodButtonLabel()}
            </Button>
            {isEditable() ? (
              <Button
                theme="dark"
                size="big"
                disabled={!contentStatus}
                onClick={() => handlePublishClick()}
              >
                {t('Publish')}
              </Button>
            ) : (
              <Button
                theme="dark"
                size="big"
                onClick={handlePublishChanges}
                disabled={!canEdit}
              >
                {t('Publish changes')}
              </Button>
            )}
          </div>
        }
      />

      <Header
        className="tab-header"
        leftSideContent={
          <Button
            theme="link-gray-primary"
            onClick={() => navigate('/research')}
          >
            {t('Cancel')}
          </Button>
        }
        rightSideContent={
          <Button
            theme="link-gray-primary"
            onClick={() => handleSave()}
            disabled={!isEditable()}
          >
            {t('Save draft')}
          </Button>
        }
      />

      <div className="flex h-[calc(100%-210px)]">
        <div className="w-full default-scroll overflow-y-scroll overflow-x-hidden px-[200px] py-[20px]">
          <div className="flex flex-col items-center h-full gap-7">
            <div
              className={twMerge([
                'flex flex-col gap-3 px-8 py-7 rounded-xl',
                'shadow-[0px_4px_52px_0px_rgba(0,0,0,0.1)] w-[800px]',
              ])}
            >
              <div className="px-[14px] mt-6">
                <CoverImage
                  coverUrl={contentState.cover.url}
                  setCover={handleChangeCover}
                  organizationId={organizationId}
                  hasError={contentState.cover.error.hasError}
                  errorMessage={contentState.cover.error.errorMessage}
                  module={FileUploadModules.RESEARCH}
                  disabled={!canEdit}
                  fileNamePrefix={'research-cover'}
                />
              </div>

              <Input
                theme="post"
                value={contentState.title.value?.toString() || undefined}
                onChange={handleChangeTitle}
                limit={255}
                hideCharacterCount
                placeholder={t('Enter the survey title *')}
                hasError={contentState.title.error.hasError}
                errorMessage={contentState.title.error.errorMessage}
                className="input-title"
                disabled={!canEdit}
              />
              <Textarea
                theme="post"
                value={contentState.description.value?.toString() || undefined}
                onChange={handleChangeDescription}
                limit={255}
                hideCharacterCount
                placeholder={t('Enter the survey description *')}
                hasError={contentState.description.error.hasError}
                errorMessage={contentState.description.error.errorMessage}
                disabled={!canEdit}
              />
            </div>
            {contentStatus && (
              <SurveyQuestions ref={listQuestionsRef} canEdit={canEdit} />
            )}
            {contentStatus === '' && !segmentationLoading && (
              <div
                className={twMerge([
                  'flex flex-col items-center justify-center gap-6 w-[800px]',
                ])}
              >
                <QuestionAttentionImage />
                <Text align="center" as="h6">
                  {t(
                    'To add questions to your survey, it needs to be either draft or published. This ensures that your changes are properly applied and that the survey functions as expected.',
                  )}
                </Text>
                <Text align="center" as="h6">
                  {t(
                    'If you need any assistance or have questions, our support team is here to help!',
                  )}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
      <footer
        className={twMerge([
          'bg-gray-color flex justify-center gap-1 items-center h-[74px]',
          'px-[52px]',
        ])}
      >
        <div className="flex gap-2">
          <div className="switch-wrapper hide-answers flex items-center gap-3">
            <Tooltip
              content={t(
                'By selecting this option, users answers will be anonymous',
              )}
              id="anonymous-answers"
              width="267px"
            >
              <SwitchButton
                id="anonymous-answers"
                checked={hideAnswers}
                onChange={() => {
                  setHideAnswers(!hideAnswers);
                }}
                disabled={!canEdit}
              />
            </Tooltip>
            <Text as="h6">{t('Anonymous answers')}</Text>
          </div>
          <div className="ml-2 switch-wrapper flex items-center gap-3">
            <Tooltip
              content={t(
                'By activating this option, the options will appear randomly to all workers answering this survey',
              )}
              id="random"
              width="267px"
            >
              <SwitchButton
                id="random"
                checked={shuffle}
                disabled={!canEdit}
                onChange={() => setShuffle(!shuffle)}
              />
            </Tooltip>
            <Text as="h6">{t('Shuffle')}</Text>
          </div>
          {/* <Button
            theme="link-gray-primary"
            rightIcon="arrow-up-s-line"
            onClick={showNotificationModal}
            className="!px-0"
            disabled={!isEditable()}
          >
            {t('Notifications')}
          </Button> */}
          <Button
            theme="link-gray-primary"
            rightIcon="arrow-up-s-line"
            onClick={showInstructionsModal}
            className="!px-0"
          >
            {t('Instructions')}
          </Button>
        </div>
      </footer>
    </StyledGeneralAdd>
  );
};

export default GeneralAdd;
