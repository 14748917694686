import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getHierarchyTotals } from 'apis/config';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import Breadcrumb from 'components/organism/Breadcrumb';
import ProfilesConfig from './components/ProfilesConfig';
import HierarchyTabOption from './components/HierarchyTabOption';
import DepartmentsConfig from './components/DepartmentsConfig';

import { TabMenuOption } from './types';

import { StyledHierarchyConfig } from './styles';

const HierarchyConfig: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { entity } = useParams();

  const { pk, avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const handleBack = () => {
    navigate('/settings');
  };

  const [menuActiveTab, setMenuActiveTab] = useState(entity);
  const [menuOptions, setMenuOptions] = useState<TabMenuOption[]>([
    {
      key: 'positions',
      content: (
        <HierarchyTabOption
          icon="group-fill"
          name={t('Positions')}
          total={null}
        />
      ),
      active: menuActiveTab === 'positions',
    },
    {
      key: 'departments',
      content: (
        <HierarchyTabOption
          icon="folder-fill"
          name={t('Departments')}
          total={null}
        />
      ),
      active: menuActiveTab === 'departments',
    },
  ]);

  const handleChangeMenuTab = (tab: string) => {
    setMenuOptions(
      menuOptions.map((item) => {
        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
          active: false,
        };
      }),
    );
    setMenuActiveTab(tab !== menuActiveTab ? tab : '');
    navigate(`/hierarchy/settings/${tab}`);
  };

  const renderMenuTabContent = () => {
    switch (menuActiveTab) {
      case 'positions': {
        return (
          <ProfilesConfig
            tabOptions={menuOptions}
            changeTabOption={handleChangeMenuTab}
            setMenuOptions={setMenuOptions}
          />
        );
      }
      case 'departments': {
        return (
          <DepartmentsConfig
            tabOptions={menuOptions}
            changeTabOption={handleChangeMenuTab}
            setMenuOptions={setMenuOptions}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  useEffect(() => {
    getHierarchyTotals(pk)
      .then((response) => {
        const { total_profiles, total_departments } = response.data;
        setMenuOptions((lastMenuOptions) =>
          lastMenuOptions.map((option) => {
            if (option.key === 'positions') {
              return {
                ...option,
                content: (
                  <HierarchyTabOption
                    icon="group-fill"
                    name={t('Positions')}
                    total={total_profiles}
                  />
                ),
              };
            }
            if (option.key === 'departments') {
              return {
                ...option,
                content: (
                  <HierarchyTabOption
                    icon="folder-fill"
                    name={t('Departments')}
                    total={total_departments}
                  />
                ),
              };
            }
            return option;
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          window.location.pathname = '/settings';
          dispatch(
            showMessage({
              title: t('You do not have permission to access this page'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        }
      });
  }, [pk, t, dispatch]);

  return (
    <StyledHierarchyConfig className="mural-content-config-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <Header
        className="secondary-header"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              {
                label: t('Organizational structure'),
                action: () => navigate(''),
              },
            ]}
          />
        }
        rightSideContent={
          <div className="main-right-actions">
            <Button
              className="right-action-button"
              radius="100px"
              size="small"
              disabled
            >
              <Icon name="folder-line" />
            </Button>
          </div>
        }
      />
      <div className="content-config-container">
        <div className="content-side">{renderMenuTabContent()}</div>
      </div>
    </StyledHierarchyConfig>
  );
};

export default HierarchyConfig;
