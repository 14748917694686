import styled from 'styled-components';

export const StyledCommentLoading = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  & > .skeleton-loading {
    margin-right: 10px;
  }

  .lines {
    flex: 1;
  }
`;
