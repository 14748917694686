import styled from 'styled-components';

export const StyledBreadcrumb = styled.div`
  ol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h6 {
      margin-top: 1px;
    }

    .more-options {
      .more-button {
        background: none;
      }

      .button-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin: 0;
        }
      }

      .dropdown-content {
        max-height: 240px;
        max-width: max-content;

        .link-list {
          position: relative;
          width: max-content;
          min-width: 100px;
          max-width: max-content;

          min-height: 32px;

          max-height: 240px;
          overflow-y: scroll;

          .button {
            width: 100%;
            margin: 0;
            justify-content: flex-start;
            padding: 8px 16px;
            height: max-content;

            .button-content {
              font-size: 16px;
              height: max-content;
              text-align: start;
            }

            &:first-child {
              border-radius: 6px 6px 0px 0px;
            }

            &:last-child {
              border-radius: 0px 0px 6px 6px;
            }
          }
        }
      }
    }

    .breadcrumb-item {
      .tooltip {
        .react-tooltip {
          max-width: 340px;
        }
      }
    }

    .link-button-wrapper {
      display: flex;
      align-items: center;

      .button {
        margin-right: 4px;

        .button-content {
          margin: 0;
          font-size: 18px;
          font-weight: 700;
        }
      }

      .icon {
        margin-right: 8px;
      }
    }

    .link-page-button:nth-child(1) {
      i {
        margin-right: 0 !important;
      }
    }
  }
`;
