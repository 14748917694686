import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getEditorialLines } from 'apis/config';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import EmptyMessage from 'components/molecule/EmptyMessage';
import EditorialConfigItem from './components/EditorialConfigItem';
import EditorialDetails from './components/EditorialDetails';
import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';

import { EditorialConfigItemData } from './types';

import { StyledEditorialConfig } from './styles';

const EditorialConfig: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector((state) => state.organization.pk);

  const [editorials, setEditorials] = useState<EditorialConfigItemData[]>([]);
  const [editorialsCount, setEditorialsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const openEditorialDetails = (editorial?: EditorialConfigItemData) => {
    dispatch(
      setModalView({
        show: true,
        width: '663px',
        content: (
          <EditorialDetails
            onSave={() => {
              setPage(1);
              setSearch('');
              loadEditorials(1, '');
            }}
            editorial={editorial}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const loadEditorials = useCallback(
    (page: number, search: string) => {
      getEditorialLines(organizationId, page, search)
        .then((response) => {
          const { results, next, recordsTotal } = response.data;

          setEditorialsCount(recordsTotal);
          setHasNextPage(!!next);
          setPage(page);

          if (page === 1) {
            setEditorials(results);
            return;
          }

          setEditorials((lastEditorials) => [...lastEditorials, ...results]);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An error occurred while fetching the editorial lines'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, t, organizationId],
  );

  const handleSearch = (value: string) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const timeOut = setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollTop = 0;
      }
      setLoading(true);
      loadEditorials(1, value);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  useEffect(() => {
    loadEditorials(1, '');
  }, [loadEditorials]);

  return (
    <StyledEditorialConfig>
      <Text as="h3" weight="700">
        {t('Editorial lines')}
      </Text>
      <Text as="h5">
        {t(
          'When creating new publications you can categorize them across editorial lines',
        )}
      </Text>
      <div className="editorial-list-header">
        <Button
          theme="link-primary"
          leftIcon="add"
          onClick={() => openEditorialDetails()}
          disabled={editorialsCount >= 10}
        >
          {t('Add editorial line')}
        </Button>
        <Input
          icon="search"
          iconColor="primary-color"
          theme="post"
          placeholder={t('Search for editorial lines')}
          value={search}
          onChange={(event) => handleSearch(event.target.value)}
        />
      </div>
      {!loading && editorials.length > 0 && (
        <div
          className="editorial-list default-scroll"
          ref={(currentRef) => setScrollElement(currentRef)}
        >
          {editorials.map((editorial, index) => (
            <EditorialConfigItem
              key={index}
              title={editorial.title}
              icon={editorial.data_icon}
              id={editorial.id}
              isDefault={editorial.default}
              color={editorial.color}
              onClick={() => openEditorialDetails(editorial)}
            />
          ))}
          {loadMoreLoading && <Loading type="bubbles" />}
        </div>
      )}

      {loading && <Loading type="bubbles" />}

      {!loading && editorials.length === 0 && (
        <EmptyMessage title={t('No editorial line found')} icon="stack-line" />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadMoreLoading(true);
          loadEditorials(page, search);
        }}
        disabled={loading || !hasNextPage}
      />
    </StyledEditorialConfig>
  );
};

export default EditorialConfig;
