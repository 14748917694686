import React from 'react';

import useVisualCustomization from './useVisualCustomization';

import Icon from 'components/atom/Icon';
import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import VisualIdentity from './components/VisualIdentity';

const VisualCustomization: React.FC = () => {
  const { orgInfo, handleBack } = useVisualCustomization();
  return (
    <div className="w-full h-full">
      <Header
        className="main-header !pl-[54px]"
        leftSideContent={
          <div className="flex items-center">
            <Button
              className="mr-3"
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={orgInfo.avatar}
              clientName={orgInfo.client || ''}
              name={orgInfo.name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <VisualIdentity />
    </div>
  );
};

export default VisualCustomization;
