import { useTranslation } from 'react-i18next';

import { MenuOptionProp } from 'components/organism/Menu/types';

import { AvailableOrganizationModules } from 'utils/organization';
import { useAppSelector } from 'store/hooks';

export const useHighlightMenuOptions = (): MenuOptionProp[] => {
  const { t } = useTranslation();
  const organizationModules = useAppSelector(
    (store) => store.organization.modules,
  );

  // base highlight options (valid for all organizations)
  const highlightOptions: MenuOptionProp[] = [
    {
      icon: 'home-6-fill',
      label: t('Home'),
      to: '/dashboard',
      disabled: false,
    },
  ];

  // specific highlight options (by modules)
  if (organizationModules) {
    const canShowMural: boolean =
      organizationModules.includes(AvailableOrganizationModules.comm) ||
      organizationModules.includes(AvailableOrganizationModules.advocacy);

    const canShowDrive = organizationModules.includes(
      AvailableOrganizationModules.drive,
    );

    const canShowResearch = organizationModules.includes(
      AvailableOrganizationModules.research,
    );

    if (canShowMural) {
      highlightOptions.push({
        icon: 'dashboard-fill',
        label: t('Mural'),
        to: '/mural',
        disabled: false,
      });
    }

    if (canShowDrive) {
      highlightOptions.push({
        icon: 'folder-fill',
        label: t('Drive'),
        to: '/drive/dashboard',
        disabled: false,
      });
    }

    if (canShowResearch) {
      highlightOptions.push({
        icon: 'chat-pool',
        label: t('Surveys'),
        to: '/research',
        disabled: false,
      });
    }
  }

  if (organizationModules) {
    const canShowSmartPush: boolean = organizationModules.includes(
      AvailableOrganizationModules.smartPush,
    );

    if (canShowSmartPush) {
      highlightOptions.push({
        icon: 'notification-3-fill-1',
        label: t('Smart push'),
        to: '/smartpush',
        disabled: false,
      });
    }
  }

  return highlightOptions;
};
