import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';

import { StyledManagementHeader } from './styles';

const ManagementHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledManagementHeader>
      <Text as="h3" weight="700">
        {t('Settings')}
      </Text>
    </StyledManagementHeader>
  );
};

export default ManagementHeader;
