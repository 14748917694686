import styled from 'styled-components';

import background from 'assets/images/background.svg';

export const StyledWizardAccountConfirmed = styled.div`
  width: 100%;
  height: 100%;
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  .change-language {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  .card {
    width: 100%;
    max-width: 980px;
    height: 100%;
    max-height: 750px;
    padding: 64px 46px;
    display: flex;
    flex-direction: column;

    .information-wrapper {
      width: 100%;
      height: min-content;
      display: flex;
      flex-direction: column;
      align-self: center;
      margin: 0 auto;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      .input {
        max-width: 400px;
      }
    }

    .logo-wrapper {
      margin-bottom: 42px;
    }

    .description {
      max-width: 500px;
    }

    .action-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .carrossel-pagination {
        margin: 0 auto;
      }

      .button {
        margin: 0px;
        width: 180px;
      }
    }

    @media only screen and (max-width: 720px) {
      .action-wrapper {
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
      }
    }

    .mt-2 {
      margin-top: 24px;
    }

    .mt-1 {
      margin-top: 14px;
    }

    .mt-05 {
      margin-top: 7px;
    }

    p {
      font-size: 20px;
    }

    .button {
      width: 100%;
      margin-top: 42px;
    }
  }
`;
