import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/atom/Tooltip';
import Footer from 'components/molecule/Footer';
import Button from 'components/molecule/Button';
import Collapse from 'components/molecule/Collapse';

import { MenuProps } from './types';

import { StyledMenu } from './styles';

const Menu: React.FC<MenuProps> = ({
  highlightOptions = [],
  options = [],
  organizationName = 'USEFLOW',
  onAfterClick,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [legaciesIsOpen, setLegaciesIsOpen] = useState(false);

  const handleClickOption = (to: string | undefined) => {
    if (!to) return;
    navigate(to);
    if (onAfterClick) {
      onAfterClick(to);
    }
  };

  return (
    <StyledMenu>
      <div className="menu-options default-scroll">
        <div className="highlight-options">
          {highlightOptions.map((highlightOption, index) => (
            <Button
              key={index}
              radius="0px"
              leftIcon={highlightOption.icon}
              onClick={() => handleClickOption(highlightOption.to)}
              disabled={highlightOption.disabled}
            >
              {highlightOption.label}
            </Button>
          ))}
        </div>
        {options.length > 0 && (
          <Collapse
            collapseHeader={
              <Button
                radius="0px"
                leftIcon="list"
                onClick={() => undefined}
                className="legacies-button"
              >
                {t('Legacy Panel')}
              </Button>
            }
            isOpen={legaciesIsOpen}
            setIsOpen={() => setLegaciesIsOpen(!legaciesIsOpen)}
          >
            <div className="options">
              {options.map((option, index) => {
                if (option.url) {
                  return (
                    <Tooltip
                      id={`tooltip-${index}`}
                      key={index}
                      content={option.label}
                      place="left"
                      disabled={option.label.length <= 18}
                      width="300px"
                    >
                      <Button
                        key={index}
                        radius="0px"
                        disabled={option.disabled}
                        onClick={option.onClick}
                        hasEllipses
                      >
                        {option.label}
                      </Button>
                    </Tooltip>
                  );
                }

                return (
                  <Button
                    key={index}
                    radius="0px"
                    to={option.to}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </Button>
                );
              })}
            </div>
          </Collapse>
        )}
        <div className="highlight-options">
          <Button radius="0px" leftIcon="settings-4-fill" to="/settings">
            {t('Settings')}
          </Button>
          <Button
            radius="0px"
            leftIcon="question-fill"
            href="https://help.useflow.tech/"
            target="_blank"
          >
            {t('Help')}
          </Button>
        </div>
      </div>
      <Footer organizationName={organizationName} />
    </StyledMenu>
  );
};

export default Menu;
