import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { addFixedContent } from 'apis/board';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';

import Input from 'components/molecule/Input';
import DatePicker from 'components/molecule/DatePicker';
import Button from 'components/molecule/Button';
import PageLoading from 'components/molecule/PageLoading';

import { dateAndTimeToApiDate, isValidTimeFormat } from 'utils/date';

import { PinContentModalProps, PinContentDateProps } from './types';

import { StyledPinContentModal } from './styles';

const initialInputState = {
  start_at: {
    value: undefined,
    error: { hasError: false, errorMessage: '' },
  },
  start_at_time: {
    value: undefined,
    error: { hasError: false, errorMessage: '' },
  },
  finish_at: {
    value: undefined,
    error: { hasError: false, errorMessage: '' },
  },
  finish_at_time: {
    value: undefined,
    error: { hasError: false, errorMessage: '' },
  },
};

const PinContentModal: React.FC<PinContentModalProps> = ({
  contentTypeId,
  organizationId,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [pinContentDates, setPinContentDates] =
    useState<PinContentDateProps>(initialInputState);

  const handleChangeStartAt = (date: Date) => {
    setPinContentDates((updatedState) => ({
      ...updatedState,
      start_at: {
        ...updatedState.start_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeStartAtTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setPinContentDates((updatedState) => ({
      ...updatedState,
      start_at_time: {
        ...updatedState.start_at_time,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishAt = (date: Date) => {
    setPinContentDates((updatedState) => ({
      ...updatedState,
      finish_at: {
        ...updatedState.finish_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishAtTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setPinContentDates((updatedState) => ({
      ...updatedState,
      finish_at_time: {
        ...updatedState.finish_at_time,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const clearErrors = () => {
    setPinContentDates((updatedState) => ({
      ...updatedState,
      start_at: {
        ...updatedState.start_at,
        error: { hasError: false, errorMessage: '' },
      },
      start_at_time: {
        ...updatedState.start_at_time,
        error: { hasError: false, errorMessage: '' },
      },
      finish_at: {
        ...updatedState.finish_at,
        error: { hasError: false, errorMessage: '' },
      },
      finish_at_time: {
        ...updatedState.finish_at_time,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleSave = () => {
    const {
      start_at: { value: start_at },
      start_at_time: { value: start_at_time },
      finish_at: { value: finish_at },
      finish_at_time: { value: finish_at_time },
    } = pinContentDates;

    clearErrors();

    if (start_at && !start_at_time) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (start_at_time && !start_at) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        start_at: {
          ...updatedState.start_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (finish_at && !finish_at_time) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (finish_at_time && !finish_at) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at: {
          ...updatedState.finish_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      finish_at &&
      start_at &&
      !dayjs(start_at).isSame(finish_at) &&
      !dayjs(start_at).isBefore(finish_at)
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at: {
          ...updatedState.finish_at,
          error: {
            hasError: true,
            errorMessage: t(
              'The start date cannot be greater than the end date.',
            ),
          },
        },
      }));
      return;
    }

    if (
      finish_at &&
      !isValidTimeFormat((finish_at_time as string)?.substring(0, 5))
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: { hasError: true, errorMessage: t('Enter a valid time') },
        },
      }));
      return;
    }

    if (
      start_at &&
      !isValidTimeFormat((start_at_time as string)?.substring(0, 5))
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('Enter a valid time') },
        },
      }));
      return;
    }

    if (
      finish_at &&
      dayjs(start_at).isSame(finish_at) &&
      finish_at_time &&
      start_at_time &&
      finish_at_time <= start_at_time
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: {
            hasError: true,
            errorMessage: t(
              'The start time cannot be greater than or equal to the end time.',
            ),
          },
        },
      }));
      return;
    }

    if (
      finish_at &&
      !dayjs().isBefore(finish_at) &&
      !dayjs().isSame(finish_at, 'day')
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at: {
          ...updatedState.finish_at,
          error: {
            hasError: true,
            errorMessage: t('The end date cannot be before today.'),
          },
        },
      }));
      return;
    }

    if (
      finish_at &&
      dayjs().isSame(finish_at, 'day') &&
      finish_at_time &&
      finish_at_time <= `${dayjs().hour()}:${dayjs().minute()}`
    ) {
      setPinContentDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: {
            hasError: true,
            errorMessage: t(
              'The end time cannot be before or equal than the actual time.',
            ),
          },
        },
      }));
      return;
    }

    let fixed_start_at = null;
    let fixed_finish_at = null;

    if (pinContentDates.start_at.value) {
      fixed_start_at = dateAndTimeToApiDate(
        pinContentDates.start_at.value as Date,
        pinContentDates.start_at_time.value as string,
      );
    }
    if (pinContentDates.finish_at.value) {
      fixed_finish_at = dateAndTimeToApiDate(
        pinContentDates.finish_at.value as Date,
        pinContentDates.finish_at_time.value as string,
      );
    }

    setLoading(true);

    addFixedContent(
      organizationId,
      contentTypeId,
      fixed_start_at,
      fixed_finish_at,
    )
      .then(() => {
        dispatch(
          showMessage({
            title: t('Content was highlighted'),
            theme: 'success',
            icon: 'check',
            time: 10000,
            customLeft: '0px',
          }),
        );
        onSave();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while highlighting the content',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(closeModal());
      });
  };

  return (
    <StyledPinContentModal>
      {loading && <PageLoading />}
      <Text as="h4" weight="700">
        {t('Choose the highlight period for the content')}
      </Text>
      <Text as="p" className="modal-description">
        {t(
          'The content will be fixed on top of the feed for all organization users',
        )}
      </Text>
      <div className="form-group">
        <DatePicker
          label={t('Start at')}
          id="start-at"
          icon="calendar-2-fill"
          value={(pinContentDates.start_at.value as Date) || undefined}
          onChange={handleChangeStartAt}
          hasError={pinContentDates.start_at.error.hasError}
          errorMessage={pinContentDates.start_at.error.errorMessage}
        />
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label="time"
          hideLabel
          placeholder="HH:MM"
          value={pinContentDates.start_at_time.value as string}
          onChange={handleChangeStartAtTime}
          hasError={pinContentDates.start_at_time.error.hasError}
          errorMessage={pinContentDates.start_at_time.error.errorMessage}
        />
      </div>
      <div className="form-group">
        <DatePicker
          label={t('Finish at')}
          id="finish-at"
          icon="calendar-2-fill"
          value={(pinContentDates.finish_at.value as Date) || undefined}
          onChange={handleChangeFinishAt}
          hasError={pinContentDates.finish_at.error.hasError}
          errorMessage={pinContentDates.finish_at.error.errorMessage}
        />
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label="time"
          hideLabel
          placeholder={'HH:MM'}
          value={pinContentDates.finish_at_time.value as string}
          onChange={handleChangeFinishAtTime}
          hasError={pinContentDates.finish_at_time.error.hasError}
          errorMessage={pinContentDates.finish_at_time.error.errorMessage}
        />
      </div>
      <Separator />
      <div className="form-action">
        <Button
          theme="link-dark-gray"
          leftIcon="arrow-left-s-line"
          onClick={() => dispatch(closeModal())}
        >
          {t('Back')}
        </Button>
        <Button theme="dark-outline" onClick={handleSave}>
          {t('Apply')}
        </Button>
      </div>
    </StyledPinContentModal>
  );
};

export default PinContentModal;
