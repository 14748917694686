import React, { useEffect, useState } from 'react';
import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledSurveyMetabaseDashboards } from './styles';
import { useTranslation } from 'react-i18next';
import Loading from 'components/molecule/Loading';
import { getSurveyMetabaseDashboards } from 'apis/survey';
import { showMessage } from 'store/slices/toaster';

interface PageTitle {
  full: string;
  short: string;
}

const SurveyMetabaseDashboards: React.FC = () => {
  const { pk: orgId } = useAppSelector((state) => state.organization);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState<string[]>([]);
  const [pageTitle, setPageTitle] = useState<PageTitle>({
    full: '',
    short: '',
  });
  const { contentId, questionId } = useParams();

  useEffect(() => {
    setLoading(true);
    getSurveyMetabaseDashboards(orgId, contentId, questionId)
      .then((response) => {
        const dashboards = response.data.list_dashboards;
        const title = response.data.title;
        setDashboards(dashboards);
        setPageTitle(title);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while loading results'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
        navigate('/research');
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, [t, dispatch, orgId, contentId, questionId, navigate]);

  const navigateHandler = () => {
    if (questionId) {
      navigate(`/research/${contentId}/questions/`);
    } else {
      navigate('/research');
    }
  };

  return (
    <StyledSurveyMetabaseDashboards className="mural-content-page">
      <Header
        leftSideContent={
          <div className="flex items-center">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={navigateHandler}
              className="border"
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Tooltip
              id="anonymous-icon"
              content={pageTitle.full}
              place="top"
              width="300px"
            >
              <Text
                as="h3"
                weight="700"
                color="grayscale-400"
                className="m-0 ml-2"
              >
                {pageTitle.short}
              </Text>
            </Tooltip>
          </div>
        }
      />
      <div className="page-content">
        {loading && <Loading />}
        {!loading && dashboards.length > 0 && (
          <div className="dashboards-wrapper">
            {dashboards.map((dashboardUrl, index) => (
              <iframe key={index} src={dashboardUrl} loading="eager" />
            ))}
          </div>
        )}
      </div>
    </StyledSurveyMetabaseDashboards>
  );
};

export default SurveyMetabaseDashboards;
