import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Image from '../Image';
import Text from 'components/atom/Text';

import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';

import enIcon from 'assets/images/en.svg';
import esIcon from 'assets/images/es.svg';
import ptBRIcon from 'assets/images/pt.svg';
import ptPTIcon from 'assets/images/pt-pt.svg';

import { StyledChangeLanguage } from './styles';

const options: readonly SelectOption[] = [
  {
    value: 'en',
    label: (
      <div className="language-option">
        <Image src={enIcon} />
        <Text color="grayscale-200">English</Text>
      </div>
    ),
  },
  {
    value: 'es',
    label: (
      <div className="language-option">
        <Image src={esIcon} />
        <Text color="grayscale-200">Español</Text>
      </div>
    ),
  },
  {
    value: 'pt',
    label: (
      <div className="language-option">
        <Image src={ptBRIcon} />
        <Text color="grayscale-200">Português do Brasil</Text>
      </div>
    ),
  },
  {
    value: 'pt-PT',
    label: (
      <div className="language-option">
        <Image src={ptPTIcon} />
        <Text color="grayscale-200">Português</Text>
      </div>
    ),
  },
];

const ChangeLanguage: React.FC = () => {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption>(
    options[0],
  );
  const [loaded, setLoaded] = useState(false);

  const handleChangeLanguage = (option: SingleSelectedOption) => {
    i18n.changeLanguage(option?.value);
    window.location.reload();
  };

  useEffect(() => {
    const currentLanguage =
      options.find((option) => option.value === i18n.language) || options[0];
    setSelectedLanguage(currentLanguage);
    i18n.changeLanguage(currentLanguage.value);

    setLoaded(true);
  }, [i18n.language, i18n]);

  return (
    <StyledChangeLanguage className="change-language">
      {loaded && (
        <Select
          options={options}
          value={selectedLanguage}
          setValue={(option) =>
            handleChangeLanguage(option as SingleSelectedOption)
          }
          size="small"
          theme="no-outline"
        />
      )}
    </StyledChangeLanguage>
  );
};

export default ChangeLanguage;
