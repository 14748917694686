import styled from 'styled-components';

export const StyledCheckinDetails = styled.div`
  width: 100%;

  .skeleton-loading {
    margin-bottom: 16px;
  }

  .details-wrapper {
    width: 100%;

    .cover {
      width: 100%;
      height: auto;
      min-height: 60px;
      position: relative;
      margin-bottom: 24px;

      .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        padding: 10px 32px;

        .button {
          border: 1px solid var(--grayscale-100);
        }

        .counters {
          display: flex;
          align-items: center;

          .tag {
            margin-left: 8px;
            border: 1px solid var(--grayscale-100);
          }
        }
      }

      .image {
        width: 100%;
        height: auto;
      }
    }

    .content-elements {
      padding: 0px 32px;

      pre {
        margin-bottom: 4px;
      }

      .title {
        margin-bottom: 20px;
      }
    }
  }
`;
