import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { StyledDelete } from './styles';

interface DeleteProps {
  name?: string;
  onConfirm?: () => void;
}

const Delete: React.FC<DeleteProps> = ({ name = '', onConfirm }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledDelete>
      <div className="modal-content">
        <Text as="h4" weight="700">
          {t('Do you want to delete {{fileName}}?', {
            fileName: name,
          })}
        </Text>
        <Text as="p">
          {t(
            'This file/folder will be deleted and no longer will be available for users to view.',
          )}
        </Text>
      </div>

      <Separator />

      <div className="modal-footer">
        <Button
          theme="link-dark-gray"
          onClick={handleCancel}
          leftIcon="arrow-left-s-line"
        >
          {t('Cancel')}
        </Button>
        <Button theme="danger-outline" onClick={handleConfirm}>
          {t('Delete')}
        </Button>
      </div>
    </StyledDelete>
  );
};

export default Delete;
