import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  getBoardContentPost,
  createBoardContentPost,
  updateBoardContentPost,
  publishBoardContentPost,
} from 'apis/board';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { setModalView } from 'store/slices/modal';

import ShortPostContent from './components/ShortPostContent';
import ArticlePostContent from './components/ArticlePostContent';
import UrlPostContent from './components/UrlPostContent';
import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';
import SwitchButton from 'components/atom/SwitchButton';
import Icon from 'components/atom/Icon';
import Confirm from 'components/molecule/Confirm';
import ConfirmPublish from '../ConfirmPublish';
import TabMenu from 'components/molecule/TabMenu';
import EditorialConfig from '../EditorialConfig';
import Tooltip from 'components/atom/Tooltip';
import FreeTrialButtonWrapper from 'components/molecule/FreeTrialButtonWrapper';

import {
  contentSubtypes,
  contentStateFactory,
  parseSchedulerDataToStateFormat,
} from '../../utils';
import { apiDateToDayjsFormat } from 'utils/date';
import { postAPIDataFactory } from './utils';
import { schedulerInitialState } from '../PublicationDateModal/components/RecurrenceConfig/utils';
import { abbreviateNumberFormatter } from 'utils/numbers';

import {
  PostContentsProps,
  PostContentFields,
  PostContentState,
} from './types';
import {
  BoardContentPostData,
  BoardContentPostSendData,
} from 'apis/board/types';

import { StyledPostContents } from './styles';
import DrivePostContent from './components/DrivePostContent';

const PostContents: React.FC<PostContentsProps> = ({
  contentState,
  setContentState,
  setInitialChangeControl,
  segmentationConfig,
  segmentationLoading,
  loadSegmentationConfig,
  segmentationTotalTarget,
  contentStatus,
  setContentStatus,
  canComment,
  setCanComment,
  setCanEditFinishAt,
  setChangeControl,
  contentTagIds,
  setContentTagIds,
  contentTags,
  setContentTags,
  totalTags,
  setTotalTags,
  gamification,
  setGamification,
  setNotificationChangeControl,
  notificationValues,
  setNotificationValues,
  parent,
  setParent,
  segmentationData,
  setSegmentationId,
  setSegmentationChangeControl,
  getPeriodButtonLabel,
  isEditable,
  showSegmentationModal,
  showGamificationModal,
  showPublicationDateModal,
  showNotificationModal,
  showTagsModal,
  showDependencyModal,
  showSuccessMessage,
  showErrorMessage,
  handleFieldErrors,
  tabs,
  handleChangeTab,
  getCurrentType,
  canEdit,
  setCanEdit,
  showDirectPollModal,
  votingIsEnabled,
  setVotingIsEnabled,
  votingMessage,
  setVotingMessage,
  withRecurrence,
  setWithRecurrence,
  contentConfigs,
  contentConfigsLoading,
  selectedEditorial,
  setSelectedEditorial,
  canReaction,
  setCanReaction,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { is_free: isFreeTrail, pk: organizationId } = useAppSelector(
    (state) => ({
      is_free: state.organization.is_free,
      pk: state.organization.pk,
    }),
  );

  const { type: contentType = '', subtype = '', contentId } = useParams();

  const renderContent = () => {
    switch (subtype) {
      case 'short': {
        return (
          <ShortPostContent
            shortContentState={contentState}
            setContentState={setContentState}
          />
        );
      }
      case 'article': {
        return (
          <ArticlePostContent
            articleContentState={contentState}
            setContentState={setContentState}
          />
        );
      }
      case 'url': {
        return (
          <UrlPostContent
            urlContentState={contentState}
            setContentState={setContentState}
            contentStatus={contentStatus}
          />
        );
      }
      case 'drive': {
        return (
          <DrivePostContent
            urlContentState={contentState}
            setContentState={setContentState}
            contentStatus={contentStatus}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  const createPost = (
    currentContentState: PostContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: BoardContentPostSendData =
      postAPIDataFactory(currentContentState);

    return new Promise<string>((resolve, reject) => {
      createBoardContentPost(organizationId, data)
        .then((response) => {
          const { id } = response.data;

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Survey has been saved as a draft'));
          }

          getPost();

          navigate(`/mural/content/${contentType}/${subtype}/${id}`);
          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }
          showErrorMessage(t('An error occurred while saving content'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const updatePost = (
    currentContentState: PostContentState,
    willBePublished: boolean,
  ) => {
    dispatch(setPageLoading(true));

    const data: BoardContentPostSendData =
      postAPIDataFactory(currentContentState);

    return new Promise<string>((resolve, reject) => {
      updateBoardContentPost(organizationId, contentId || '', data)
        .then((response) => {
          const { id } = response.data;

          if (contentStatus !== 'draft') {
            navigate('/mural');
            return;
          }

          setChangeControl(currentContentState);
          setSegmentationChangeControl(data.segmentations[0]);
          setNotificationChangeControl(
            currentContentState.notifications || null,
          );

          if (!withRecurrence) {
            setContentState({ ...contentState, ...schedulerInitialState });
          }

          if (!willBePublished) {
            showSuccessMessage(t('Survey has been saved as a draft'));
          }

          getPost();

          resolve(id);
        })
        .catch((responseError) => {
          const { code, errors } = responseError.response.data;
          if (code === 'field_error') {
            handleFieldErrors(errors);
            return;
          }

          if (code === 'invalid' || code.indexOf('invalid') !== -1) {
            showErrorMessage(errors[0]);
            return;
          }

          showErrorMessage(t('An error occurred while saving content'));
          reject(responseError);
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    });
  };

  const publishPostContent = (id: string) => {
    dispatch(setPageLoading(true));

    publishBoardContentPost(organizationId, id)
      .then(() => {
        showSuccessMessage(t('The content has been published'));
        navigate('/mural');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || code.indexOf('invalid') !== -1) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while publishing the content'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSave = (saveAndPublish = false) => {
    const activeTab = getCurrentType();
    const type = activeTab ? activeTab.key : 'short';

    const contentGeneralFields = {
      can_comment: canComment,
      can_reaction: canReaction,
      gamification,
      notifications: notificationValues,
      parent,
      segmentations: segmentationData,
      status: contentStatus,
      tags: contentTags,
      total_tags: totalTags,
      tag_ids: contentTagIds,
      type,
      voting_is_enabled: votingIsEnabled,
      voting_message: votingMessage,
      with_recurrence: withRecurrence,
      editorial: selectedEditorial,
    };

    const currentContentState = contentStateFactory(
      contentState,
      contentGeneralFields,
    );

    if (!contentId) {
      createPost(currentContentState, saveAndPublish).then((id) => {
        if (saveAndPublish) {
          publishPostContent(id);
        }
      });
      return;
    }

    updatePost(currentContentState, saveAndPublish).then((id) => {
      if (saveAndPublish) {
        publishPostContent(id);
      }
    });
  };

  const handlePublishChanges = () => {
    dispatch(
      setModalView({
        show: true,
        width: '388px',
        content: (
          <Confirm
            title={t('Publish changes')}
            subtitle={`${t('Are you sure you want to save the changes?')} ${
              !withRecurrence && !!contentState.scheduler_start_date_at
                ? t('The defined recurrence will be lost.')
                : ''
            }`}
            onConfirm={handleSave}
          />
        ),
      }),
    );
  };

  const handlePublishClick = () => {
    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at: {
          ...updatedState.start_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (
      !contentState.scheduler_start_date_at.value &&
      !contentState.start_at_time.value
    ) {
      setContentState((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    dispatch(
      setModalView({
        show: true,
        width: '664px',
        content: (
          <ConfirmPublish
            startAt={dayjs(contentState.start_at.value || Date.now()).format(
              'DD/MM/YYYY',
            )}
            startAtTime={contentState.start_at_time.value}
            finishAt={
              contentState.finish_at.value
                ? dayjs(contentState.finish_at.value).format('DD/MM/YYYY')
                : null
            }
            finishAtTime={contentState.finish_at_time.value}
            onConfirm={() => handleSave(true)}
            withRecurrence={withRecurrence}
            scheduleState={{
              scheduler_start_date_at: contentState.scheduler_start_date_at,
              scheduler_finish_date_at: contentState.scheduler_finish_date_at,
              content_start_time_at: contentState.content_start_time_at,
              content_finish_time_at: contentState.content_finish_time_at,
              content_duration: contentState.content_duration,
              content_week_days: contentState.content_week_days,
              scheduler_var: contentState.scheduler_var,
              scheduler_type: contentState.scheduler_type,
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const handlePostLoadResponse = useCallback(
    (data: BoardContentPostData) => {
      const {
        cover,
        text,
        title,
        url,
        description,
        start_at,
        finish_at,
        status,
        tags,
        segmentations,
        can_comment,
        gamification,
        notifications,
        parent,
        type,
        can_modify_finish_at,
        scheduler,
        can_edit,
        enable_poll,
        poll,
        editorial,
        can_reaction,
      } = data;

      const formattedStartAt = apiDateToDayjsFormat(start_at || '');
      const formattedFinishAt = apiDateToDayjsFormat(finish_at || '');

      const [startAt, startAtTime] = formattedStartAt.split(' ');
      const [finishAt, finishAtTime] = formattedFinishAt.split(' ');

      setContentState((lastContentFields) => {
        const currentFields = lastContentFields as PostContentFields;

        const newContentState: PostContentFields = {
          cover: {
            ...currentFields.cover,
            value: cover && cover.id ? cover.id : '',
            url: cover && cover.path ? cover.path : '',
          },
          text: {
            ...currentFields.text,
            value: text ? text : '',
          },
          title: { ...currentFields.title, value: title || '' },
          url: { ...currentFields.url, value: url || '' },
          description: {
            ...currentFields.description,
            value: description || '',
          },
          start_at: {
            ...currentFields.start_at,
            value: startAt ? dayjs(startAt).toDate() : undefined,
          },
          start_at_time: {
            ...currentFields.start_at_time,
            value: startAtTime ? startAtTime.split(':').join(':') : '',
          },
          finish_at: {
            ...currentFields.finish_at,
            value: finishAt ? dayjs(finishAt).toDate() : undefined,
          },
          finish_at_time: {
            ...currentFields.finish_at_time,
            value: finishAtTime ? finishAtTime.split(':').join(':') : '',
          },
          ...parseSchedulerDataToStateFormat(scheduler),
        };
        setContentStatus(status);
        setCanComment(can_comment);
        setGamification(gamification);
        setNotificationValues(notifications);
        setNotificationChangeControl(notifications);
        setParent(parent || '');
        setCanEditFinishAt(can_modify_finish_at);
        setCanEdit(can_edit);
        setWithRecurrence(!!scheduler);
        setVotingIsEnabled(enable_poll);
        setVotingMessage(poll);
        setSelectedEditorial(editorial || 'not_defined');

        if (can_reaction) {
          setCanReaction(can_reaction);
        }

        if (tags) {
          setContentTags(tags);
          setTotalTags(tags.length);
          setContentTagIds(tags.map((tag) => tag.id));
        }

        if (segmentations) {
          setSegmentationId(segmentations[0]);
        }

        setChangeControl(
          contentStateFactory(newContentState, {
            can_comment,
            can_reaction: can_reaction || false,
            gamification,
            notifications: undefined,
            parent: parent || '',
            segmentations: undefined,
            status,
            tags: tags ? tags : [],
            total_tags: tags ? tags.length : 0,
            tag_ids: tags ? tags.map((tag) => tag.id) : [],
            type,
            with_recurrence: !!scheduler,
            voting_is_enabled: enable_poll,
            voting_message: poll,
            editorial: editorial || 'not_defined',
          }),
        );

        return newContentState;
      });
    },
    [
      setContentState,
      setContentStatus,
      setCanComment,
      setCanEditFinishAt,
      setChangeControl,
      setContentTagIds,
      setContentTags,
      setTotalTags,
      setGamification,
      setNotificationChangeControl,
      setNotificationValues,
      setParent,
      setSegmentationId,
      setCanEdit,
      setWithRecurrence,
      setVotingIsEnabled,
      setVotingMessage,
      setSelectedEditorial,
      setCanReaction,
    ],
  );

  const getPost = useCallback(() => {
    if (!contentId) return;
    dispatch(setPageLoading(true));

    getBoardContentPost(organizationId, contentId)
      .then((response) => {
        handlePostLoadResponse(response.data);
        const { segmentations, content_id } = response.data;
        if (segmentations) {
          loadSegmentationConfig(segmentations[0], content_id);
        }
      })
      .catch(() => {
        navigate('/error-404');
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  }, [
    contentId,
    dispatch,
    handlePostLoadResponse,
    navigate,
    organizationId,
    loadSegmentationConfig,
  ]);

  useEffect(() => {
    if (contentType !== 'post') {
      navigate('/page-404');
      return;
    }

    if (!contentSubtypes.includes(subtype)) {
      navigate('/page-404');
      return;
    }

    if (!canEdit) {
      navigate('/mural');
      return;
    }

    if (contentId) {
      getPost();
      return;
    }

    setInitialChangeControl();
  }, [
    contentType,
    contentId,
    subtype,
    navigate,
    contentStatus,
    getPost,
    setInitialChangeControl,
    canEdit,
  ]);

  return (
    <StyledPostContents>
      <Header
        leftSideContent={
          <div className="main-left-actions">
            <Text as="h6" weight="700">
              {contentId ? t('Edit publication') : t('Create publication')}
            </Text>
            <Button
              theme="link-primary"
              rightIcon={!segmentationLoading ? 'arrow-down-s-line' : ''}
              onClick={showSegmentationModal}
              disabled={segmentationLoading || !segmentationConfig}
            >
              {!segmentationLoading &&
                t('Target audience ({{total}})', {
                  total: abbreviateNumberFormatter(segmentationTotalTarget),
                })}
              {segmentationLoading && (
                <Loading type="bubbles" color="grayscale-100" />
              )}
            </Button>
            <FreeTrialButtonWrapper isFreeTrial={isFreeTrail}>
              <Button
                theme="link-primary"
                rightIcon="arrow-down-s-line"
                onClick={showGamificationModal}
                className="gamification-button"
              >
                {t('Gamification')}
              </Button>
            </FreeTrialButtonWrapper>
            <EditorialConfig
              editorials={contentConfigs.editorial}
              loading={contentConfigsLoading}
              selectedEditorial={selectedEditorial}
              setSelectedEditorial={setSelectedEditorial}
            />
          </div>
        }
        rightSideContent={
          <div className="main-right-actions">
            <Button
              leftIcon={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'error-warning-fill'
                  : ''
              }
              theme={
                contentState.start_at.error.hasError ||
                contentState.finish_at.error.hasError
                  ? 'link-danger'
                  : 'link-primary'
              }
              rightIcon="arrow-down-s-line"
              onClick={showPublicationDateModal}
            >
              {getPeriodButtonLabel()}
            </Button>
            {isEditable() ? (
              <Button
                theme="dark"
                size="big"
                onClick={() => handlePublishClick()}
              >
                {t('Publish')}
              </Button>
            ) : (
              <Button theme="dark" size="big" onClick={handlePublishChanges}>
                {t('Publish changes')}
              </Button>
            )}
          </div>
        }
      />
      <Header
        className="tab-header"
        leftSideContent={
          <Button theme="link-gray-primary" onClick={() => navigate('/mural')}>
            {t('Cancel')}
          </Button>
        }
        rightSideContent={
          <Button
            theme="link-gray-primary"
            onClick={() => handleSave()}
            disabled={!isEditable()}
          >
            {t('Save draft')}
          </Button>
        }
      >
        {isEditable() && <TabMenu tabs={tabs} onChangeTab={handleChangeTab} />}
        {!isEditable() && (
          <TabMenu
            tabs={tabs.map((tab) => ({ ...tab, disabled: true }))}
            onChangeTab={handleChangeTab}
          />
        )}
      </Header>
      <div className="mural-content">
        <div className="content default-scroll">
          <div className="center">{renderContent()}</div>
        </div>
        <div className="actions">
          <div className="center">
            <div className="left-side">
              <div className="switch-wrapper">
                <SwitchButton
                  id="comments"
                  checked={canComment}
                  onChange={() => setCanComment(!canComment)}
                />
                <Text as="h6">{t('Comments')}</Text>
              </div>
              {!['url', 'drive'].includes(subtype) && (
                <div className="switch-wrapper">
                  <SwitchButton
                    id="reactions"
                    checked={canReaction}
                    onChange={() => setCanReaction(!canReaction)}
                  />
                  <Text as="h6">{t('Reactions')}</Text>
                </div>
              )}

              <FreeTrialButtonWrapper isFreeTrial={isFreeTrail && isEditable()}>
                <Button
                  theme="link-gray-primary"
                  rightIcon="arrow-up-s-line"
                  onClick={showNotificationModal}
                  disabled={!isEditable()}
                >
                  {t('Notifications')}
                </Button>
              </FreeTrialButtonWrapper>
            </div>
            <div className="right-side">
              {!['url', 'drive'].includes(subtype) && (
                <Tooltip content={t('Poll')} id="direct-poll">
                  <Button
                    theme="primary-flat"
                    rounded="true"
                    size="small"
                    className="direct-poll-button"
                    onClick={showDirectPollModal}
                  >
                    <Icon name="chat-pool" />
                  </Button>
                </Tooltip>
              )}
              <Button
                theme="primary-flat"
                rounded="true"
                size="small"
                className="tags-button"
                onClick={showTagsModal}
              >
                {t('{{count}} #tag', { count: totalTags })}
              </Button>
              <FreeTrialButtonWrapper isFreeTrial={isFreeTrail && isEditable()}>
                <Button
                  theme="primary-flat"
                  rounded="true"
                  size="small"
                  className="dependency-button"
                  onClick={showDependencyModal}
                  disabled={!isEditable()}
                >
                  <Icon name="git-merge-fill" />
                </Button>
              </FreeTrialButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </StyledPostContents>
  );
};

export default PostContents;
