import { t } from 'i18next';

import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';

import { createS3Url } from 'apis/upload';
import { CreateS3UrlResponseType, UploadFieldsType } from 'apis/upload/types';

import {
  FileUploadErrorDetails,
  FileUploadErrorNames,
  FileUploadModules,
  UploadDataRequest,
  UploadDataResponse,
} from './types';

import { BASE_UPLOAD_URL } from 'settings';

export class FileUploadError extends Error {
  public name: FileUploadErrorNames;
  public details: FileUploadErrorDetails;

  public constructor(
    name: FileUploadErrorNames,
    details?: FileUploadErrorDetails,
  ) {
    super(undefined);

    this.name = name;
    this.message = this.getErrorMessage();
    this.details = details || { status: 400, error: null };

    Object.setPrototypeOf(this, FileUploadError.prototype);
  }

  private getErrorMessage(): string {
    switch (this.name) {
      case FileUploadErrorNames.UPLOAD_FAILED:
        return t('An error occurred when uploading file');
      case FileUploadErrorNames.UPLOAD_CANCELED:
        return t('Upload has been canceled');
      case FileUploadErrorNames.NO_COMMON_DIRECTORY:
        return t('Loaded files should belong to a common root directory');
      case FileUploadErrorNames.CREATE_S3_URL_FAILED:
        return t('An error occurred while creating file path on storage');
      default:
        return t('Unknown error');
    }
  }
}

export class FileUpload {
  protected totalFiles: number;
  private cancelTokenSource: CancelTokenSource | null;
  private progressSum: number;
  private progress: number;
  private uploaded: boolean;
  private canceled: boolean;

  public constructor() {
    this.cancelTokenSource = null;
    this.progressSum = 0;
    this.totalFiles = 1;
    this.progress = 0;
    this.uploaded = false;
    this.canceled = false;
  }

  public send(
    uploadData: File | File[],
    module: FileUploadModules,
    organizationId: string,
    progressCallback: (progress: number) => void,
    cacheControl = 'max-age=3000',
  ): Promise<UploadDataResponse> {
    const data: UploadDataRequest = { module, organizationId };

    return new Promise((resolve, reject) => {
      if (uploadData instanceof File) {
        data.file = uploadData;
        data.cacheControl = cacheControl;
        this.sendFile(data, organizationId, progressCallback, resolve, reject);
        return;
      }
      if (this.isADir(uploadData)) {
        data.fileList = uploadData;
        this.sendDir(data, organizationId, progressCallback, resolve, reject);
        return;
      }

      reject(new FileUploadError(FileUploadErrorNames.NO_COMMON_DIRECTORY));
    });
  }

  public cancel(): void {
    if (this.cancelTokenSource) {
      this.canceled = true;
      this.progress = 0;
      this.progressSum = 0;
      this.cancelTokenSource.cancel();
    }
  }

  private isADir(fileList: File[]): boolean {
    return fileList.some((file: File) => file.webkitRelativePath !== '');
  }

  private handleProgress(
    event: AxiosProgressEvent,
    progressCallback: (progress: number) => void,
  ): void {
    if (event.loaded !== undefined && event.total !== undefined) {
      this.progress = Math.floor((event.loaded / event.total) * 100);
    }

    if (progressCallback) {
      progressCallback((this.progressSum + this.progress) / this.totalFiles);
    }
    if (event.loaded === event.total) {
      this.progressSum += this.progress;
    }
  }

  private uploadFile(
    url: string,
    fields: UploadFieldsType,
    progressCallback: (progress: number) => void,
    is_dir: boolean,
  ): Promise<unknown> {
    const data: FormData = new FormData();
    data.append('key', fields.key);
    data.append('AWSAccessKeyId', fields.AWSAccessKeyId);
    data.append('policy', fields.policy);
    data.append('signature', fields.signature);
    data.append('Content-Type', fields.file.type);
    data.append('Cache-Control', fields.cacheControl || 'max-age');
    data.append('file', fields.file);

    if (!is_dir) {
      this.progress = 0;
      this.progressSum = 0;
      this.uploaded = false;
      this.canceled = false;
    }

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line import/no-named-as-default-member
      this.cancelTokenSource = axios.CancelToken.source();

      axios
        .post(url, data, {
          onUploadProgress: (event) => {
            this.handleProgress(event, progressCallback);
          },
          cancelToken: this.cancelTokenSource.token,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (this.canceled) {
            reject(
              new FileUploadError(FileUploadErrorNames.UPLOAD_CANCELED, {
                status: 400,
                error,
              }),
            );
            return;
          }

          reject(
            new FileUploadError(FileUploadErrorNames.UPLOAD_FAILED, {
              status: 403,
              error,
            }),
          );
        });
    });
  }

  private handleCreateS3Url(
    data: UploadDataRequest,
    is_dir: boolean,
  ): Promise<CreateS3UrlResponseType> {
    const {
      organizationId,
      module,
      file,
      cacheControl = 'max-age=3000',
    } = data;
    const name: string = is_dir ? '' : file ? file.name : '';
    const content_type: string | undefined = file?.type;

    return new Promise((resolve, reject) => {
      createS3Url(organizationId, {
        module,
        name,
        is_dir,
        content_type,
        cache_control: cacheControl,
      })
        .then((response) => resolve(response.data))
        .catch((error) =>
          reject(
            new FileUploadError(FileUploadErrorNames.CREATE_S3_URL_FAILED, {
              status: error && error.response ? error.response.status : 500,
              error,
            }),
          ),
        );
    });
  }

  private sendFile(
    data: UploadDataRequest,
    organizationId: string,
    progressCallback: (progress: number) => void,
    resolve: (value: UploadDataResponse) => void,
    reject: (reason: FileUploadError) => void,
  ): void {
    this.handleCreateS3Url(data, false)
      .then((response: CreateS3UrlResponseType) => {
        const { url, fields, pk, uuid } = response;

        if (data && data.file) {
          fields.file = data.file;
        }

        this.uploadFile(url, fields, progressCallback, false)
          .then(() => {
            this.uploaded = true;
            this.cancelTokenSource = null;

            resolve({
              baseUrl: BASE_UPLOAD_URL,
              organizationId,
              uuid,

              totalFiles: this.totalFiles,
              progress: this.progress,
              uploaded: this.uploaded,
              canceled: this.canceled,
              moduleId: pk,
              fileUrl: `${url}/${fields.key}`,
            });
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  }

  private uploadAllFiles(
    files: File[],
    currentFileIndex: number,
    url: string,
    fields: UploadFieldsType,
    progressCallback: (progress: number) => void,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const currentFile: File = files[currentFileIndex];
      const currentFields: UploadFieldsType = { ...fields };
      const filePath: string = currentFile.webkitRelativePath.substring(
        currentFile.webkitRelativePath.indexOf('/') + 1,
      );

      currentFields.file = currentFile;
      currentFields.key = currentFields.key.replace(
        '#{filename}'.replace('#', '$'),
        filePath,
      );

      this.uploadFile(url, currentFields, progressCallback, true)
        .then(() => {
          if (currentFileIndex + 1 < files.length) {
            resolve(
              this.uploadAllFiles(
                files,
                currentFileIndex + 1,
                url,
                fields,
                progressCallback,
              ),
            );
          } else {
            resolve();
          }
        })
        .catch((error) => reject(error));
    });
  }

  private sendDir(
    data: UploadDataRequest,
    organizationId: string,
    progressCallback: (progress: number) => void,
    resolve: (value: UploadDataResponse) => void,
    reject: (reason: FileUploadError) => void,
  ): void {
    this.handleCreateS3Url(data, true)
      .then((response: CreateS3UrlResponseType) => {
        const { url, fields, pk, path, uuid } = response;
        const files: File[] = data.fileList ? data.fileList : [];

        this.totalFiles = files.length;
        this.progress = 0;
        this.progressSum = 0;
        this.uploaded = false;
        this.canceled = false;

        this.uploadAllFiles(files, 0, url, fields, progressCallback)
          .then(() => {
            this.uploaded = true;
            this.cancelTokenSource = null;

            resolve({
              baseUrl: BASE_UPLOAD_URL,
              organizationId,
              uuid,

              totalFiles: this.totalFiles,
              progress: this.progress,
              uploaded: this.uploaded,
              canceled: this.canceled,
              moduleId: pk,
              fileUrl: `${url}/${path}`,
            });
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  }
}
