import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { StyledPopupContent } from './styles';
import Button from 'components/molecule/Button';
import { OrgPopupProps } from 'apis/staff/types';
import { deletePopup } from 'apis/staff';
import { OrganizationState } from 'store/slices/organization/types';

interface ConfirmProps {
  popups: OrgPopupProps[];
}

const Confirm: React.FC<ConfirmProps> = ({ popups }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentPopup, setCurrentPopup] = useState(1);
  const selectedOrganization: OrganizationState = useAppSelector(
    (store) => store.organization,
  );

  const handleConfirm = () => {
    deletePopup(selectedOrganization.pk, popups[currentPopup - 1].id);
    if (currentPopup >= popups.length) {
      dispatch(
        setModalView({
          show: false,
        }),
      );
    } else {
      setCurrentPopup(currentPopup + 1);
    }
  };

  return (
    <StyledPopupContent>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {popups[currentPopup - 1].title}
        </Text>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: popups[currentPopup - 1].content }}
        />
      </div>

      <Separator />

      <div className="modal-footer">
        <Button theme="primary-outline" onClick={handleConfirm}>
          {currentPopup < popups.length ? t('Next') : t('Ok')}
        </Button>
      </div>
    </StyledPopupContent>
  );
};

export default Confirm;
