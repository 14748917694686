import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Organization from 'components/molecule/Organization';
import MainHeaderContent from 'layouts/RestrictedLayouts/components/MainHeaderContent';
import Breadcrumb from 'components/organism/Breadcrumb';
import MuralConfigMenu from './components/MuralConfigMenu';
import EditorialConfig from './components/EditorialConfig';

import { StyledMuralContentConfig } from './styles';
import SubscriptionModal from 'components/molecule/FreeTrialButtonWrapper/SubscriptionModal';

const MuralContentConfig: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [trialModalIsVisible, setTrialModalIsVisible] = useState(false);
  const isFreeTrial = useAppSelector((state) => state.organization.is_free);

  const { avatar, name, client } = useAppSelector(
    (state) => state.organization,
  );

  const handleBack = () => {
    navigate('/settings');
  };

  const [menuActiveTab, setMenuActiveTab] = useState('editorial-lines');
  const [menuOptions, setMenuOptions] = useState([
    {
      key: 'editorial-lines',
      content: t('Editorial lines'),
      active: menuActiveTab === 'editorial-lines',
    },
    {
      key: 'automatic-contents',
      content: t('Automatic contents'),
      active: menuActiveTab === 'automatic-contents',
      disabled: !isFreeTrial,
    },
  ]);

  const handleChangeMenuTab = (tab: string) => {
    if (isFreeTrial && tab === 'automatic-contents') {
      setTrialModalIsVisible(true);
      return;
    }

    setMenuOptions(
      menuOptions.map((item) => {
        if (item.key === tab) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      }),
    );
    setMenuActiveTab(tab !== menuActiveTab ? tab : '');
  };

  const renderMenuTabContent = () => {
    switch (menuActiveTab) {
      case 'editorial-lines': {
        return <EditorialConfig />;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <StyledMuralContentConfig className="mural-content-config-page">
      <Header
        className="main-header"
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={handleBack}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Organization
              avatar={avatar}
              clientName={client || ''}
              name={name}
            />
          </div>
        }
        rightSideContent={<MainHeaderContent />}
      />
      <Header
        className="secondary-header"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              { label: t('Mural contents'), action: () => navigate('') },
            ]}
          />
        }
      />
      <div className="content-config-container">
        <div className="menu-side">
          <MuralConfigMenu
            tabMenuOptions={menuOptions}
            onChangeTab={handleChangeMenuTab}
          />
        </div>
        <div className="content-side">{renderMenuTabContent()}</div>
      </div>

      {trialModalIsVisible && (
        <SubscriptionModal
          onCloseButtonClick={() => setTrialModalIsVisible(false)}
          hide={false}
          onSubscribe={() => setTrialModalIsVisible(false)}
        />
      )}
    </StyledMuralContentConfig>
  );
};

export default MuralContentConfig;
