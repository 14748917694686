import React from 'react';

import TabMenu from 'components/molecule/TabMenu';

import { Tab } from 'components/molecule/TabMenu/types';

import { StyledSecondaryDetailsHeader } from './styles';

interface SecondaryDetailsHeaderProps {
  tabMenuOptions: Tab[];
  onChangeTab: (key: string) => void;
}

const SecondaryDetailsHeader: React.FC<SecondaryDetailsHeaderProps> = ({
  tabMenuOptions,
  onChangeTab,
}) => {
  return (
    <StyledSecondaryDetailsHeader>
      <TabMenu tabs={tabMenuOptions} onChangeTab={(tab) => onChangeTab(tab)} />
    </StyledSecondaryDetailsHeader>
  );
};

export default SecondaryDetailsHeader;
