import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getAlerts, getPopups } from 'apis/staff';

import { setModalView } from 'store/slices/modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ExpandedLayoutMenu from './components/ExpandedLayoutMenu';
import PopupContent from '../components/PopupContent';

import { OrganizationState } from 'store/slices/organization/types';

import { StyledExpandedLayout } from './styles';

import { OrgAlertProps, OrgPopupProps } from 'apis/staff/types';

const ExpandedLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  const organization: OrganizationState = useAppSelector(
    (state) => state.organization,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setAlertItems] = useState<OrgAlertProps[]>([]);

  const showPopups = useCallback(
    (popups: OrgPopupProps[]) => {
      dispatch(
        setModalView({
          show: true,
          width: '500px',
          content: <PopupContent popups={popups} />,
          disableBackgroundClick: true,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    setAlertItems([]);
    getAlerts(
      organization.pk,
      location.pathname.includes('/dashboard') ? '1' : '0',
    ).then((response) => {
      setAlertItems(response.data);
    });
    getPopups(
      organization.pk,
      location.pathname.includes('/dashboard') ? '1' : '0',
    ).then((response) => {
      if (response.data.length > 0) {
        showPopups(response.data);
      }
    });
  }, [location.pathname, organization.pk, showPopups]);

  return (
    <StyledExpandedLayout>
      <ExpandedLayoutMenu selectedOrganization={organization} />
      <div className="content-container default-scroll">{children}</div>
    </StyledExpandedLayout>
  );
};

export default ExpandedLayout;
