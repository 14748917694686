import styled from 'styled-components';

export const StyledClientStep = styled.div`
  width: 100%;

  .position-title {
    margin-bottom: 20px;
  }

  .input {
    input {
      font-size: 24px;
    }
  }
`;
