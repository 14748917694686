import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Text from 'components/atom/Text';

import { StyledTextarea } from './styles';

export const AvailableTextareaThemes = {
  default: 'default',
  gray: 'gray',
  post: 'post',
  shadow: 'shadow',
  'secondary-dark': 'secondary-dark',
};

const AvailableCharacterCountTypes = {
  focused: 'focused',
  unfocused: 'unfocused',
};

interface TextareaProps {
  id?: string;
  rows?: number;
  label?: string;
  focusedLabel?: string;
  hideLabel?: boolean;
  placeholder?: string;
  placeholderAsLabel?: boolean;
  limit?: number;
  hideCharacterCount?: boolean;
  characterCountType?: keyof typeof AvailableCharacterCountTypes;
  resizable?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
  errorMessage?: string;
  theme?: keyof typeof AvailableTextareaThemes;
  disabled?: boolean | undefined;
  className?: string;
  autoResize?: boolean;
  autoFocus?: boolean;
  removeBreakLines?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  id,
  rows = 3,
  label,
  focusedLabel,
  hideLabel = false,
  placeholder = '',
  placeholderAsLabel = false,
  limit,
  hideCharacterCount = false,
  characterCountType = 'unfocused',
  resizable = 'false',
  value = '',
  onChange,
  onKeyDown,
  hasError = 'false',
  errorMessage,
  theme = 'default',
  disabled,
  className,
  autoResize = false,
  autoFocus = false,
  removeBreakLines = false,
}) => {
  const [count, setCount] = useState(value ? value.toString().length : 0);
  const [showCounter, setShowCounter] = useState(
    characterCountType === 'unfocused',
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (removeBreakLines) {
      event.target.value = event.target.value.replaceAll('\n', '');
    }

    if (limit) {
      const newValue = event.target.value.substring(0, limit);
      setCount(newValue.length);
      event.target.value = newValue;
      onChange?.(event);
      return;
    }
    onChange?.(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyDown?.(event);
  };

  const renderLabel = () => {
    const labelText: string =
      label || focusedLabel || (placeholderAsLabel ? placeholder : '');
    const shouldHideLabel: boolean =
      hideLabel || (focusedLabel && !value) || (placeholderAsLabel && !value);

    if (!labelText) return '';

    return (
      <label htmlFor={id}>
        <Text
          as="pre"
          color="grayscale-200"
          className={`textarea-label ${shouldHideLabel ? 'hide' : ''}`}
        >
          {labelText}
        </Text>
      </label>
    );
  };

  useEffect(() => {
    if (value) {
      setCount(value.toString().length);
    }
  }, [value]);

  return (
    <StyledTextarea
      className={`textarea ${theme} ${className || ''}`}
      resize={resizable !== 'true' ? 'none' : 'vertical'}
    >
      {renderLabel()}

      <div
        className={`textarea-content ${
          hasError === 'true' ? 'has-error' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        {!autoResize && (
          <textarea
            id={id || ''}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowCounter(true)}
            onBlur={() => setShowCounter(characterCountType === 'unfocused')}
            disabled={disabled}
            className="default-scroll"
            rows={rows}
            ref={(currentRef) => {
              if (autoFocus) {
                currentRef?.focus();
              }
            }}
          />
        )}

        {autoResize && (
          <TextareaAutosize
            id={id || ''}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowCounter(true)}
            onBlur={() => setShowCounter(characterCountType === 'unfocused')}
            disabled={disabled}
            className="default-scroll"
            ref={(currentRef) => {
              if (autoFocus) {
                currentRef?.focus();
              }
            }}
          />
        )}
      </div>
      <div className="information-wrapper">
        {!errorMessage && <span></span>}
        {errorMessage && (
          <Text
            as="pre"
            color="danger-color"
            className="textarea-error-message"
          >
            {errorMessage}
          </Text>
        )}
        {limit && !hideCharacterCount && showCounter && (
          <Text as="pre" color="grayscale-200" className="textarea-limit">
            <span>{count}</span>/<span>{limit}</span>
          </Text>
        )}
      </div>
    </StyledTextarea>
  );
};

export default Textarea;
