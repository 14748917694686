import React, { useState } from 'react';

import { FreeTrialButtonWrapperProps } from './types';

import SubscriptionModal from './SubscriptionModal';
import { useAppDispatch } from 'store/hooks';
import { showModal, hideModal } from 'store/slices/modal';

import { StyledFreeTrialButtonWrapper } from './styles';

const FreeTrialButtonWrapper: React.FC<FreeTrialButtonWrapperProps> = ({
  children,
  className,
  isFreeTrial,
}) => {
  const dispatch = useAppDispatch();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const handleClick = () => {
    if (!isFreeTrial) return;
    dispatch(hideModal());
    setModalIsVisible(true);
  };

  const handleCloseModal = () => {
    dispatch(showModal());
    setModalIsVisible(false);
  };

  return (
    <>
      {!!modalIsVisible && (
        <SubscriptionModal
          onCloseButtonClick={() => handleCloseModal()}
          hide={false}
          onSubscribe={() => handleCloseModal()}
        />
      )}
      <StyledFreeTrialButtonWrapper
        className={className}
        onClick={handleClick}
        isFreeTrial={isFreeTrial}
      >
        {children}
      </StyledFreeTrialButtonWrapper>
    </>
  );
};

export default FreeTrialButtonWrapper;
