import styled from 'styled-components';

export const StyledOrganizationConfig = styled.div`
  width: 100%;
  max-width: 634px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 56px;
    width: 100%;
  }

  h5 {
    margin-bottom: 6px;
    width: 100%;
  }

  & > p {
    margin-bottom: 16px;
    width: 100%;
  }

  hr {
    width: 100%;
    margin: 32px 0px;
  }

  .tooltip-holding-company {
    & > div:first-child {
      width: 100%;
    }
  }

  & > .loading {
    margin-top: 64px;
  }

  .react-select {
    .react-select__menu {
      .react-select__menu-list {
        max-height: 200px;
      }
    }
  }

  .empty-message {
    width: 100%;
    margin-top: 64px;
    max-width: 340px;
  }
`;
