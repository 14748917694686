import styled from 'styled-components';

export const StyledLegacyMenuRedirectModal = styled.div`
  .modal-content {
    margin: 0 0 42px 0;

    h5 {
      margin-bottom: 16px;
    }
  }

  .modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
