import React from 'react';

import Header from 'components/atom/Header';

import StaffHeaderContent from './components/StaffHeaderContent';
import StaffMenu from './components/StaffMenu';

import { StyledStaffDashboardLayout } from './styles';

const StaffDashboardLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  return (
    <StyledStaffDashboardLayout>
      <div className="menus">
        <div className="menus-wrapper">
          <StaffMenu />
        </div>
      </div>

      <div className="content default-scroll">
        <Header
          rightSideContent={
            <StaffHeaderContent className="right-side-content" />
          }
        />
        <div className="container default-scroll">{children}</div>
      </div>
    </StyledStaffDashboardLayout>
  );
};

export default StaffDashboardLayout;
