import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateFromApiDate, getTimeFromApiDate } from 'utils/date';

import Text from 'components/atom/Text';

import { StyledFilesTableCreatedAt } from './styles';

interface FilesTableCreatedAtProps {
  period: string;
}

const FilesTableCreatedAt: React.FC<FilesTableCreatedAtProps> = ({
  period,
}) => {
  const { t } = useTranslation();

  const getPeriodLabel = useCallback(
    (period: string) => {
      const periodLabel = `${t('{{start_at}} at {{time_start_at}}', {
        start_at: getDateFromApiDate(period),
        time_start_at: getTimeFromApiDate(period),
      })}`;

      return periodLabel;
    },
    [t],
  );

  return (
    <StyledFilesTableCreatedAt>
      <Text as="pre" color="grayscale-300">
        {getPeriodLabel(period)}
      </Text>
    </StyledFilesTableCreatedAt>
  );
};

export default FilesTableCreatedAt;
