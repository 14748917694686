import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateSegmentModalProps } from './types';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';

import { StyledCreateSegmentModal } from './styles';

const CreateSegmentModal: React.FC<CreateSegmentModalProps> = ({
  onConfirm,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(title.slice(0, 100));
  const [error, setError] = useState('');

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleConfirm = () => {
    if (!value) {
      setError(t('This field is required'));
      return;
    }

    if (onConfirm) {
      onConfirm(value);
    }
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  return (
    <StyledCreateSegmentModal>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('Confirm the custom field title')}
        </Text>
      </div>

      <div>
        <Input
          placeholder={t('Enter a title')}
          limit={100}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          hasError={!!error}
          errorMessage={error}
        />
      </div>

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button theme="primary-outline" onClick={handleConfirm}>
          {t('Confirm')}
        </Button>
      </div>
    </StyledCreateSegmentModal>
  );
};

export default CreateSegmentModal;
