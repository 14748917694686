import React from 'react';
import { useTranslation } from 'react-i18next';

import PollOption from 'components/organism/PollOption';
import Button from 'components/molecule/Button';
import Tooltip from 'components/atom/Tooltip';

import { PollOptionsProps } from './types';

import { StyledPollOptions } from './styles';
import { SurveyContentFields } from '../../../../types';

const PollOptions: React.FC<PollOptionsProps> = ({
  options,
  setContentState,
  contentStatus,
}) => {
  const { t } = useTranslation();

  const handleChangeText = (value: string, optionId: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      options: [
        ...updatedState.options.map((option) => {
          const newOption = { ...option };
          if (newOption.id === optionId) {
            newOption.text = value;
          }
          return newOption;
        }),
      ],
    }));
  };

  const handleClear = (optionId: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      options: updatedState.options.map((option) => {
        const newOption = { ...option };
        if (newOption.id === optionId) {
          newOption.text = '';
        }
        return newOption;
      }),
    }));
  };

  const handleToggleSelection = (optionId: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      options: updatedState.options.map((option) => {
        const newOption = { ...option };
        if (option.id === optionId) {
          newOption.is_correct = !option.is_correct;
        }
        return newOption;
      }),
    }));
  };

  const handleRemove = (optionId: string) => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      options: [
        ...updatedState.options.filter((option) => option.id !== optionId),
      ],
    }));
  };

  const addNewOption = () => {
    setContentState((updatedState: SurveyContentFields) => ({
      ...updatedState,
      options: [
        ...updatedState.options,
        {
          id: `poll-option-${Date.now()}`,
          text: '',
          is_correct: false,
          is_editable: true,
        },
      ],
    }));
  };
  return (
    <StyledPollOptions className="poll-options">
      <div className="option-list">
        {options.map((option, index) => (
          <Tooltip
            content={t(
              'This option cannot be changed as was already replied or is in use by a custom field.',
            )}
            key={index}
            disabled={option.is_editable}
            id={option.id}
            width="300px !important"
          >
            <PollOption
              id={option.id}
              text={option.text}
              isCorrect={option.is_correct}
              onChange={handleChangeText}
              onClear={handleClear}
              onRemove={handleRemove}
              onToggleSelection={handleToggleSelection}
              disableRemove={options.length <= 2}
              status={contentStatus}
              disabled={!option.is_editable}
            />
          </Tooltip>
        ))}
      </div>
      {(contentStatus === '' || contentStatus === 'draft') && (
        <Button
          theme="link-gray-primary"
          size="small"
          onClick={addNewOption}
          disabled={options.length >= 10}
        >
          {t('Add an option')}
        </Button>
      )}
    </StyledPollOptions>
  );
};

export default PollOptions;
