import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/atom/Text';
import SwitchButton from 'components/atom/SwitchButton';

import Input from 'components/molecule/Input';

import { BooleanOptionsProps } from './types';

import { StyledBooleanOptions } from './styles';

const BooleanOptions: React.FC<BooleanOptionsProps> = ({
  config,
  name,
  setName,
  item,
  setItem,
}) => {
  const { t } = useTranslation();

  return (
    <StyledBooleanOptions>
      <div className="field-section-wrapper">
        <Text as="h6">{t('Field name')}</Text>
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={t('Enter the title')}
          limit={100}
        />
        <div className="answer-options-preview-section">
          <Text as="h6">
            {t('Your question will have this answer options:')}
          </Text>
          <div className="answer-options-preview-wrapper">
            <div className="answer-options-preview-item-wrapper">
              <Text as="h5" weight="700">
                {item.boolean_label_false
                  ? item.boolean_label_false
                  : config?.boolean_label_false}
              </Text>
              <SwitchButton id="switch-preview-cf-boolean" size="big" />
              <Text as="h5" weight="700">
                {item.boolean_label_true
                  ? item.boolean_label_true
                  : config?.boolean_label_true}
              </Text>
            </div>
          </div>
        </div>

        <div className="answer-options-wrapper">
          <Text as="h6">{t('False label')}</Text>
          <Input
            placeholder={
              config?.boolean_label_false ? config.boolean_label_false : t('No')
            }
            limit={50}
            value={item?.boolean_label_false ? item.boolean_label_false : ''}
            onChange={(event) => {
              setItem((prevItem) => ({
                ...prevItem,
                boolean_label_false: event.target.value,
                boolean_label_false_invalid_msg: '',
              }));
            }}
            hasError={item.boolean_label_false_invalid_msg !== ''}
            errorMessage={item.boolean_label_false_invalid_msg}
            disabled={!!item.id}
          />
          <Text as="h6">{t('True label')}</Text>
          <Input
            placeholder={
              config?.boolean_label_true ? config.boolean_label_true : t('Yes')
            }
            limit={50}
            value={item?.boolean_label_true ? item.boolean_label_true : ''}
            onChange={(event) => {
              setItem((prevItem) => ({
                ...prevItem,
                boolean_label_true: event.target.value,
                boolean_label_true_invalid_msg: '',
              }));
            }}
            hasError={item.boolean_label_true_invalid_msg !== ''}
            errorMessage={item.boolean_label_true_invalid_msg}
            disabled={!!item.id}
          />
        </div>
      </div>
    </StyledBooleanOptions>
  );
};

export default BooleanOptions;
