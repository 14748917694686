import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';

import { cn } from 'utils/cn';
import { abbreviateNumberFormatter } from 'utils/numbers';
import CardActions from './components/CardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';
import FinalizeSurvey from './components/FinalizeSurvey';
import DuplicateSurvey from './components/DuplicateSurvey';
import { CoverParams } from 'apis/survey/types';

interface SurveyItemProps {
  id: string;
  title: string;
  cover: CoverParams | null;
  status: React.ReactNode;
  period: string;
  startAt: string;
  answers: number;
  anonymous: boolean;
  onClick?: () => void;
  onReloadSurveys: () => void;
  statusValue: string;
  qtdPerCycle: string;
  percentAnswers: string;
  totalQuestions: number;
  category: string;
}

const SurveyItem: React.FC<SurveyItemProps> = ({
  id,
  title,
  cover,
  status,
  period,
  startAt,
  answers,
  percentAnswers,
  statusValue,
  totalQuestions,
  anonymous,
  category,
  onReloadSurveys,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pk: orgId } = useAppSelector((state) => state.organization);

  const isAnonymous = () => {
    return anonymous ? (
      <Tooltip
        id="anonymous-icon"
        content={t('Contains anonymous responses')}
        place="top"
      >
        <Icon name="shield-person" color="grayscale-200" />
      </Tooltip>
    ) : null;
  };

  const hasCover = () => {
    return cover ? (
      <Tooltip id="cover-icon" content={t('Contains cover image')} place="top">
        <Icon name="image-line" color="grayscale-200" />
      </Tooltip>
    ) : null;
  };

  const showFinalizeSurveyConfirmModal = (surveyId: string) => {
    dispatch(
      setModalView({
        show: true,
        width: '565px',
        content: (
          <FinalizeSurvey
            surveyId={surveyId}
            organizationId={orgId}
            onSave={onReloadSurveys}
          />
        ),
      }),
    );
  };

  const showDuplicateSurveyConfirmModal = (surveyId: string) => {
    dispatch(
      setModalView({
        show: true,
        width: '565px',
        content: (
          <DuplicateSurvey
            surveyId={surveyId}
            organizationId={orgId}
            onSave={onReloadSurveys}
          />
        ),
      }),
    );
  };

  const moreOptions = () => {
    return (
      <CardActions
        id={id}
        status={statusValue}
        showFinalizeSurveyConfirmModal={showFinalizeSurveyConfirmModal}
        showDuplicateSurveyConfirmModal={showDuplicateSurveyConfirmModal}
      />
    );
  };

  const getEditButton = () => {
    return (
      <Button
        theme="dark"
        onClick={() => navigate(`/research/${category}/edit/${id}`)}
        className={twMerge([
          '!min-w-max !py-3 !px-0',
          'transition-opacity duration-300 ease-in-out',
        ])}
      >
        <Icon name="arrow-right-line" />
      </Button>
    );
  };

  const getSurveyInfo = () => {
    switch (statusValue) {
      case 'scheduled':
        return (
          <div className="flex items-center justify-between gap-1">
            <div className="flex gap-1 flex-col">
              <Text color="grayscale-400">
                {t('Responses will be accepted starting from')}
              </Text>
              <Text as="h1" color="grayscale-400" weight="700">
                {startAt}
              </Text>
            </div>
            {getEditButton()}
          </div>
        );
      case 'draft':
        return (
          <div className="flex items-center justify-between gap-1">
            <div className="flex gap-1 flex-col">
              <Text color="grayscale-400">{t('No start date scheduled')}</Text>
            </div>
            {getEditButton()}
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-between gap-1">
            <div className="flex gap-1 flex-col">
              <Text as="h1" color="grayscale-400" weight="700">
                {abbreviateNumberFormatter(answers)}
              </Text>
              <Text color="grayscale-400">
                {percentAnswers}% {t('of response', { count: answers })}
              </Text>
            </div>
            {getEditButton()}
          </div>
        );
    }
  };

  return (
    <div
      className={cn(
        'group transition-all duration-300 ease-in',
        'shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]',
        'w-full h-[376px] rounded-xl px-8 py-5 cursor-pointer',
        'hover:border-solid hover:border-primary-color-300 hover:border-2',
      )}
    >
      <div className="flex items-center justify-between py-[10px] pb-5 gap-1">
        <div className="flex items-center gap-[10px]">
          {status}
          {/* <span className="text-grayscale-200 font-bold">
            {t('Cicle {{qtd}}', {
              qtd: qtdPerCycle,
            })}
          </span> */}
          <Text color="grayscale-200">
            {totalQuestions} {t('question', { count: totalQuestions })}
          </Text>
        </div>
        <div className="flex items-center justify-center gap-4">
          {hasCover()}
          {isAnonymous()}
          {moreOptions()}
        </div>
      </div>

      <Separator />

      <div className="flex flex-col justify-between pt-5 h-[calc(100%-106px)]">
        <div className="flex gap-1 flex-col py-[10px]">
          <Text as="h5" color="grayscale-400" weight="700">
            {title}
          </Text>
          <Text color="grayscale-200">{period}</Text>
        </div>
        {getSurveyInfo()}
      </div>
    </div>
  );
};

export default SurveyItem;
