import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledWizardConfirmAccount } from './styles';

import Card from 'components/atom/Card';
import Text from 'components/atom/Text';

import Logo from 'components/molecule/Logo';
import ChangeLanguage from 'components/molecule/ChangeLanguage';
import Button from 'components/molecule/Button';

import Toaster from 'components/organism/Toaster';

import email from 'assets/images/email.png';
import Image from 'components/molecule/Image';
import { postResendEmailToConfirmCompany } from 'apis/wizard';

const WizardConfirmAccount: React.FC = () => {
  const { t } = useTranslation();

  const { token } = useParams();
  const [showTimer, setShowTimer] = useState(true);
  const [timer, setTimer] = useState(60);
  const [toasterMessage, setToasterMessage] = useState({
    title: '',
    theme: 'danger',
    customLeft: '0',
    icon: 'close',
    time: 10000,
    show: false,
  });

  const resendEmailClick = () => {
    if (token) {
      postResendEmailToConfirmCompany(token)
        .then(() => {
          setToasterMessage({
            ...toasterMessage,
            title: t('Confirmation email was resent.'),
            theme: 'success',
            show: true,
          });
        })
        .catch(() => {
          setToasterMessage({
            ...toasterMessage,
            title: t('There was an error resending confirmation email.'),
            theme: 'danger',
            show: true,
          });
        })
        .finally(() => {
          setShowTimer(true);
          setTimer(60);
        });
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (showTimer) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showTimer]);

  useEffect(() => {
    if (showTimer && timer === 0) {
      setShowTimer(false);
    }
  }, [showTimer, timer]);

  return (
    <StyledWizardConfirmAccount>
      <Toaster
        show={toasterMessage.show.toString()}
        time={toasterMessage.time}
        icon="close"
        title={toasterMessage.title}
        theme={toasterMessage.theme}
        customLeft={toasterMessage.customLeft}
        onClose={() => setToasterMessage({ ...toasterMessage, show: false })}
      />

      <ChangeLanguage />

      <Card noBorder="true">
        <div className="logo-wrapper">
          <Logo size="large" />
        </div>
        <Image src={email} width="100" />
        <Text align="center" as="h3" weight="700" className="mt-2">
          {t('Congratulations, you have created your organization')}
        </Text>
        <Text align="center" as="h5" weight="400" className="mt-1">
          {t(
            'A confirmation message was sent to your email. Please check your inbox and spam folder.',
          )}
        </Text>
        <Button
          theme="dark-outline-primary"
          leftIcon="send"
          onClick={resendEmailClick}
          disabled={showTimer}
        >
          {t('Resend email')}
          {showTimer && ` (${timer})`}
        </Button>
      </Card>
    </StyledWizardConfirmAccount>
  );
};

export default WizardConfirmAccount;
