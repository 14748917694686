import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Tooltip from 'components/atom/Tooltip';
import Select from 'components/molecule/Select';

import { ContentHistoryHeaderProps } from './types';
import {
  SelectOption,
  SingleSelectedOption,
} from 'components/molecule/Select/types';

import { StyldContentHistoryHeader } from './styles';

const ContentHistoryHeader: React.FC<ContentHistoryHeaderProps> = ({
  onExport,
  onChangeOrder,
}) => {
  const { t } = useTranslation();

  const options: readonly SelectOption[] = [
    {
      value: '-created_at',
      label: (
        <div className="order-option">
          <Text color="primary-color">{t('Order by most recent')}</Text>
        </div>
      ),
    },
    {
      value: 'created_at',
      label: (
        <div className="order-option">
          <Text color="primary-color">{t('Order by oldest')}</Text>
        </div>
      ),
    },
  ];
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    options[0],
  );

  const handleChangeProfile = (option: SingleSelectedOption) => {
    setSelectedOption(option as SelectOption);
    if (option) {
      onChangeOrder(option.value);
    }
  };

  return (
    <StyldContentHistoryHeader>
      <div className="row">
        <Text as="h3" weight="700">
          {t('History')}
        </Text>
        <div className="actions">
          <Tooltip id="export-history" content={t('Export')}>
            <Button
              theme="primary-flat"
              rounded="true"
              size="small"
              onClick={onExport}
            >
              <Icon name="download" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="row">
        <Select
          options={options}
          value={selectedOption}
          setValue={(option) =>
            handleChangeProfile(option as SingleSelectedOption)
          }
          size="small"
          theme="no-outline"
        />
      </div>
    </StyldContentHistoryHeader>
  );
};

export default ContentHistoryHeader;
