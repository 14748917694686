export enum QuestionMode {
  Edit = 'edit',
  Save = 'save',
  Fetch = 'fetch',
}

export enum SwapOptions {
  Up = -1,
  Down = 1,
}

export interface Question {
  id?: string;
  title: string;
  survey: string;
  is_active: boolean;
  order: number;
  category: string;
  is_required: boolean;
  nps_has_labels: boolean;
  nps_scale?: number;
  nps_first_value?: number;
  nps_last_value?: number;
  nps_first_label?: string;
  nps_last_label?: string;
  options: Options;
  mode: QuestionMode;
}

// State interfaces

export interface ErrorState {
  hasError: boolean;
  errorMessage: string;
}

export interface FieldState {
  value: string;
  error: ErrorState;
  disabled: boolean;
}

export interface Item {
  id: string;
  text: string;
  is_correct: boolean;
  is_editable: boolean;
}

export interface Options {
  items: Item[];
  errors: string[];
}

export interface QuestionState {
  id?: string;
  survey?: string;
  is_active?: boolean;
  order: number;
  category: string;
  is_required: boolean;
  nps_has_labels: boolean;
  title?: FieldState;
  nps_first_label?: FieldState;
  nps_last_label?: FieldState;
  nps_first_value?: number;
  nps_last_value?: number;
  nps_scale?: number;
  options: Options;
  mode: QuestionMode;
}

export interface QuestionHandle {
  saveQuestion: () => boolean;
}
