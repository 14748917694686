import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createCustomField,
  getCustomField,
  getCustomFieldsConfig,
  updateCustomField,
} from 'apis/core';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { closeModal } from 'store/slices/modal';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Separator from 'components/atom/Separator';
import Button from 'components/molecule/Button';
import Select from 'components/molecule/Select';
import ChoiceOptions from './components/ChoiceOptions';
import PageLoading from 'components/molecule/PageLoading';
import Loading from 'components/molecule/Loading';

import { CustomFieldFormModalProps } from './types';
import { AvailableIcons } from 'components/atom/Icon/types';
import { SingleSelectedOption } from 'components/molecule/Select/types';
import { ChoiceOptionItem } from './components/ChoiceOptions/types';
import {
  CustomFieldConfigResponseData,
  CustomFieldRequestData,
} from 'apis/core/types';
import {
  TextOptionItem,
  defaultTextOptionItem,
} from './components/TextOptions/types';
import {
  BooleanOptionItem,
  defaultBooleanOptionItem,
} from './components/BooleanOptions/types';
import {
  NumericOptionItem,
  defaultNumericOptionItem,
} from './components/NumericOptions/types';

import { StyledCustomFieldFormModal } from './styles';
import TextOptions from './components/TextOptions';
import BooleanOptions from './components/BooleanOptions';
import NumericOptions from './components/NumericOptions';

const CustomFieldFormModal: React.FC<CustomFieldFormModalProps> = ({
  type,
  onSave,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const customFields = {
    custom_string: {
      icon: 'menu',
      name: t('Text'),
    },
    custom_decimal: {
      icon: 'number',
      name: t('Numerical'),
    },
    custom_boolean: {
      icon: 'toggle-line',
      name: t('Boolean'),
    },
    custom_choices: {
      icon: 'drop',
      name: t('Dropdown'),
    },
    custom_multiple_choices: {
      icon: 'drop',
      name: t('Dropdown'),
    },
  };

  const permissionSelectOptions = useMemo(
    () => [
      {
        value: 'all-edit-all-view',
        label: (
          <div className="select-option">
            <Text>{t('Everyone can view and edit')}</Text>
          </div>
        ),
      },
      {
        value: 'admin-edit-all-view',
        label: (
          <div className="select-option">
            <Text>{t('Everyone can view but only admins can edit')}</Text>
          </div>
        ),
      },
      {
        value: 'admin-edit-admin-view',
        label: (
          <div className="select-option">
            <Text>{t('Only admins can view and edit')}</Text>
          </div>
        ),
      },
    ],
    [t],
  );

  const editSelectOptions = useMemo(
    () => [
      {
        value: 'all',
        label: (
          <div className="select-option">
            <Text as="pre" color="grayscale-200">
              {t('Edit')}
            </Text>
            <Text>{t('All')}</Text>
          </div>
        ),
      },
      {
        value: 'only-admin',
        label: (
          <div className="select-option">
            <Text as="pre" color="grayscale-200">
              {t('Edit')}
            </Text>
            <Text>{t('Only admins')}</Text>
          </div>
        ),
      },
    ],
    [t],
  );

  const typeAnswerSelectOptions = useMemo(
    () => [
      {
        value: 'custom_choices',
        label: (
          <div className="select-option">
            <Text>{t('Single choice')}</Text>
          </div>
        ),
      },
      {
        value: 'custom_multiple_choices',
        label: (
          <div className="select-option">
            <Text>{t('Multiple choice')}</Text>
          </div>
        ),
      },
    ],
    [t],
  );
  const [isEditing, setIsEditing] = useState(false);

  const [selectedPermission, setSelectedPermission] =
    useState<SingleSelectedOption>(permissionSelectOptions[0]);
  const [name, setName] = useState('');

  const [customFieldsConfig, setCustomFieldsConfig] =
    useState<CustomFieldConfigResponseData>({});

  const [selectedTypeAnswer, setSelectedTypeAnswer] =
    useState<SingleSelectedOption>(typeAnswerSelectOptions[0]);

  const [choiceOptions, setChoiceOptions] = useState<ChoiceOptionItem[]>([]);

  const [textOptions, setTextOptions] = useState<TextOptionItem>(
    defaultTextOptionItem,
  );

  const [booleanOptions, setBooleanOptions] = useState<BooleanOptionItem>(
    defaultBooleanOptionItem,
  );

  const [numericOptions, setNumericOptions] = useState<NumericOptionItem>(
    defaultNumericOptionItem,
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangePermissionSelect = (option: SingleSelectedOption) => {
    setSelectedPermission(option);
  };

  const handleChangeTypeAnswerSelect = (option: SingleSelectedOption) => {
    setSelectedTypeAnswer(option);
  };

  const checkSaveButtonDisableState = () => {
    if (name.trim().length === 0) {
      return true;
    }

    if (
      type === 'custom_choices' &&
      (choiceOptions.length < 2 ||
        choiceOptions.some((option) => option.inEdit))
    ) {
      return true;
    }

    return false;
  };

  const getTypeValue = () => {
    if (type === 'custom_choices') {
      return selectedTypeAnswer ? selectedTypeAnswer.value : type;
    }

    return type;
  };

  const handleCreateCustomField = (data: CustomFieldRequestData) => {
    createCustomField(organizationId, data)
      .then(() => {
        dispatch(closeModal());
        onSave();
      })
      .catch((error) => {
        if (error.response.data.code === 'field_error') {
          dispatch(
            showMessage({
              title: error.response.data.errors[0].error,
              theme: 'danger',
              icon: 'close',
              time: 5000,
            }),
          );
          return;
        } else if (
          error.response.data.code === 'invalid' ||
          error.response.data.code.indexOf('invalid') !== -1
        ) {
          dispatch(
            showMessage({
              title: error.response.data.errors[0],
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
          return;
        }
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while saving the custom field',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => setSaveLoading(false));
  };

  const handleUpdateCustomField = (data: CustomFieldRequestData) => {
    if (!id) return;

    updateCustomField(organizationId, data, id)
      .then(() => {
        dispatch(closeModal());
        onSave();
      })
      .catch((error) => {
        if (error.response.data.code === 'field_error') {
          dispatch(
            showMessage({
              title: error.response.data.errors[0].error,
              theme: 'danger',
              icon: 'close',
              time: 5000,
            }),
          );
          return;
        }
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while saving the custom field',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => setSaveLoading(false));
  };

  const handleSave = () => {
    if (
      type === 'custom_string' &&
      textOptions.max_length !== null &&
      textOptions.max_length < 3
    ) {
      setTextOptions((prevState) => ({
        ...prevState,
        max_length_invalid_msg: t('Max length must be at least 3.'),
      }));
      return;
    }
    if (
      type === 'custom_decimal' &&
      !numericOptions.id &&
      numericOptions.decimal_min_value !== null &&
      numericOptions.decimal_max_value !== null &&
      parseFloat(numericOptions.decimal_min_value as string) >=
        parseFloat(numericOptions.decimal_max_value as string)
    ) {
      setNumericOptions((prevState) => ({
        ...prevState,
        decimal_min_value_invalid_msg: t(
          'Minimum value must be less than maximum value.',
        ),
      }));
      return;
    }

    if (
      type === 'custom_boolean' &&
      booleanOptions.boolean_label_true &&
      !booleanOptions.boolean_label_false &&
      booleanOptions.boolean_label_true ===
        customFieldsConfig.custom_boolean?.boolean_label_false
    ) {
      setBooleanOptions((prevState) => ({
        ...prevState,
        boolean_label_true_invalid_msg: t(
          'The true label cannot be the same as the false one.',
        ),
      }));
      return;
    }

    if (
      type === 'custom_boolean' &&
      !booleanOptions.boolean_label_true &&
      booleanOptions.boolean_label_false &&
      booleanOptions.boolean_label_false ===
        customFieldsConfig.custom_boolean?.boolean_label_true
    ) {
      setBooleanOptions((prevState) => ({
        ...prevState,
        boolean_label_false_invalid_msg: t(
          'The false label cannot be the same as the true one.',
        ),
      }));
      return;
    }

    if (
      type === 'custom_boolean' &&
      booleanOptions.boolean_label_false &&
      booleanOptions.boolean_label_true &&
      booleanOptions.boolean_label_false === booleanOptions.boolean_label_true
    ) {
      setBooleanOptions((prevState) => ({
        ...prevState,
        boolean_label_false_invalid_msg: t(
          'The false label cannot be the same as the true one.',
        ),
      }));
      return;
    }

    const editable = selectedPermission?.value === 'all-edit-all-view';
    const visible = selectedPermission?.value !== 'admin-edit-admin-view';
    const max_length =
      textOptions.max_length != null ? Number(textOptions.max_length) : null;

    const decimal_min_value =
      numericOptions.decimal_min_value !== null
        ? Number(numericOptions.decimal_min_value)
        : null;
    const decimal_max_value =
      numericOptions.decimal_max_value !== null
        ? Number(numericOptions.decimal_max_value)
        : null;

    const data = {
      key: name,
      editable,
      type: getTypeValue(),
      max_length,
      is_multiline: textOptions.is_multiline,
      boolean_label_false: booleanOptions.boolean_label_false,
      boolean_label_true: booleanOptions.boolean_label_true,
      decimal_min_value,
      decimal_max_value,
      visible,
      options: choiceOptions.map((option) => ({
        value: option.name,
        pk: option.id,
      })),
    };

    setSaveLoading(true);

    if (!id) {
      handleCreateCustomField(data);
      return;
    }
    handleUpdateCustomField(data);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        // getCustomField waits for getCustomFieldsConfig
        const [configResponse, fieldResponse] = await Promise.all([
          getCustomFieldsConfig(organizationId),
          !id ? null : getCustomField(organizationId, id),
        ]);

        const {
          custom_boolean,
          custom_string,
          custom_decimal,
        }: CustomFieldConfigResponseData = configResponse.data;

        setCustomFieldsConfig({
          custom_boolean,
          custom_string,
          custom_decimal,
        });

        // fieldResponse will be null if id is falsy
        if (fieldResponse && fieldResponse.data) {
          const { key, editable, visible, id } = fieldResponse.data;
          setName(key);
          setIsEditing(!!id);

          if (type === 'custom_choices' || type === 'custom_multiple_choices') {
            setChoiceOptions(
              fieldResponse.data.options.map(
                (option: { value: string; pk: string }) => ({
                  id: option.pk,
                  inEdit: false,
                  initialOpen: false,
                  name: option.value,
                }),
              ),
            );

            const typeAnswerOption = typeAnswerSelectOptions.find(
              (typeAnswerOption) => typeAnswerOption.value === type,
            );

            if (typeAnswerOption) {
              setSelectedTypeAnswer(typeAnswerOption);
            }
          } else if (type === 'custom_string') {
            setTextOptions((prevState) => ({
              ...prevState,
              ...fieldResponse.data,
              max_length:
                fieldResponse.data.max_length !== null
                  ? fieldResponse.data.max_length
                  : custom_string?.max_length,
            }));
          } else if (type === 'custom_boolean') {
            const newBooleanOptions = { ...fieldResponse.data };
            if (!fieldResponse.data.boolean_label_false) {
              newBooleanOptions.boolean_label_false =
                custom_boolean?.boolean_label_false;
            }
            if (!fieldResponse.data.boolean_label_true) {
              newBooleanOptions.boolean_label_true =
                custom_boolean?.boolean_label_true;
            }
            setBooleanOptions((prevState) => ({
              ...prevState,
              ...newBooleanOptions,
            }));
          } else if (type === 'custom_decimal') {
            setNumericOptions((prevState) => ({
              ...prevState,
              ...fieldResponse.data,
            }));
          }

          let permission = '';

          if (!visible && !editable) {
            permission = 'admin-edit-admin-view';
          } else if (visible && !editable) {
            permission = 'admin-edit-all-view';
          } else if (visible && editable) {
            permission = 'all-edit-all-view';
          }

          if (permission) {
            const permissionOption = permissionSelectOptions.find(
              (permissionOption) => permissionOption.value === permission,
            );
            if (permissionOption) {
              setSelectedPermission(permissionOption);
            }
          }
        }
      } catch (error) {
        dispatch(closeModal());
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while loading the custom fields configuration',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    dispatch,
    id,
    organizationId,
    t,
    editSelectOptions,
    permissionSelectOptions,
    type,
    typeAnswerSelectOptions,
  ]);

  return (
    <StyledCustomFieldFormModal>
      <div className="custom-field-type">
        <Icon
          name={
            customFields[type as keyof typeof customFields]
              .icon as keyof typeof AvailableIcons
          }
        />
        <Text as="h6">
          {customFields[type as keyof typeof customFields].name}
        </Text>
      </div>
      <Text as="h4" weight="700">
        {t('Configure custom field')}
      </Text>
      <Separator />

      {!loading && (
        <>
          <div className="form-wrapper">
            <Text as="h6">{t('Permissions definitions')}</Text>
            <div className="form-group">
              <Select
                value={selectedPermission}
                setValue={(option) =>
                  handleChangePermissionSelect(option as SingleSelectedOption)
                }
                options={permissionSelectOptions}
                className="permission-select"
              />
            </div>

            {type === 'custom_string' && (
              <TextOptions
                config={customFieldsConfig.custom_string}
                name={name}
                setName={setName}
                item={textOptions}
                setItem={setTextOptions}
              />
            )}

            {(type === 'custom_choices' ||
              type === 'custom_multiple_choices') && (
              <>
                <Text as="h6">{t('Definition of choice')}</Text>
                <div className="form-group">
                  <Select
                    value={selectedTypeAnswer}
                    setValue={(option) =>
                      handleChangeTypeAnswerSelect(
                        option as SingleSelectedOption,
                      )
                    }
                    options={typeAnswerSelectOptions}
                    className="type-answer-select"
                    isDisabled={isEditing}
                    theme={isEditing ? 'gray' : 'default'}
                  />
                </div>

                <ChoiceOptions
                  name={name}
                  setName={setName}
                  options={choiceOptions}
                  setOptions={setChoiceOptions}
                />
              </>
            )}

            {type === 'custom_boolean' && (
              <BooleanOptions
                config={customFieldsConfig.custom_boolean}
                name={name}
                setName={setName}
                item={booleanOptions}
                setItem={setBooleanOptions}
              />
            )}

            {type === 'custom_decimal' && (
              <NumericOptions
                config={customFieldsConfig.custom_decimal}
                name={name}
                setName={setName}
                item={numericOptions}
                setItem={setNumericOptions}
              />
            )}

            {/* <div className="required-wrapper">
              <Text as="h6">{t('Required field')}</Text>
              <SwitchButton id="required-field" />
            </div> */}
          </div>

          <Separator />

          <div className="modal-footer">
            <Button
              theme="link-gray-primary"
              onClick={() => dispatch(closeModal())}
            >
              {t('Cancel')}
            </Button>
            <Button
              disabled={checkSaveButtonDisableState()}
              onClick={handleSave}
            >
              {t('Save')}
            </Button>
          </div>
        </>
      )}

      {saveLoading && <PageLoading />}

      {loading && <Loading />}
    </StyledCustomFieldFormModal>
  );
};

export default CustomFieldFormModal;
