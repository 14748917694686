import styled from 'styled-components';

export const StyledPinContentModal = styled.div`
  .modal-description {
    font-size: 18px;
    margin: 6px 0px 24px;
  }

  .form-group {
    display: flex;
    align-items: flex-start;

    .datepicker {
      margin-right: 10px;
    }

    .input {
      max-width: 122px;
    }
  }

  .form-group + .form-group {
    margin-top: 24px;
  }

  .form-group + hr {
    margin-top: 42px;
  }

  .form-action {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    /* .icon {
      font-size: 24px;
    } */
  }
`;
