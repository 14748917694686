import styled from 'styled-components';

export interface StyledEditorialIconContainerProps {
  iconColor: string;
  backgroundColor: string;
}

export const StyledEditorialIconContainer = styled.div.attrs(
  (props: StyledEditorialIconContainerProps) => ({
    iconColor: props.iconColor,
    backgroundColor: props.backgroundColor,
  }),
)<StyledEditorialIconContainerProps>`
  min-width: 32px;
  height: 32px;
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-100);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: -2px;

  svg {
    width: 22px;
    height: 22px;
    path {
      fill: ${({ iconColor }) => iconColor};
    }
  }
`;

export const StyledEditorialConfig = styled.div`
  margin-left: 12px;

  .react-select {
    .react-select__control {
      border-radius: 100px;
      padding: 0 0 0 0;

      i#button-left-icon {
        padding: 0 0 0 0 !important;
      }

      .button {
        width: 100%;
        .button-content {
          width: 100%;

          .text {
            max-width: 24ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &:hover {
          * {
            color: var(--grayscale-200);
          }
        }
      }
    }

    .react-select__menu {
      width: max-content;
      min-width: 212px;
      z-index: var(--z-index-level-2);

      .button {
        padding: 8px;
        height: max-content;
      }
    }

    .react-select__single-value {
      margin-top: 0px !important;
      padding-left: 30px;
    }
  }

  .editorial-select-option {
    width: 200px;

    .button-content {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
