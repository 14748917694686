import styled from 'styled-components';

export const StyledPeriodStatisticsCard = styled.div`
  width: 50%;
  height: max-content;
  padding: 16px;

  & > .card {
    padding: 42px;
    position: relative;
    min-height: 470px;
    display: flex;
    flex-direction: column;

    .tab-menu {
      background: none;
      .button {
        flex: 1;
        padding: 4px 12px;
        height: 28px;
        margin: 0px 8px;
        background: var(--gray-color);

        &.active {
          background: var(--dark-color);
          box-shadow: none;
          .button-content {
            color: var(--white-color);
          }
        }
      }
    }

    .info {
      margin: 32px 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side {
        display: flex;
        align-items: center;

        .description {
          position: relative;
          .react-tooltip {
            width: 180px;
            position: absolute !important;
            top: auto !important;
            left: auto !important;
            bottom: 30px !important;
            right: -80px !important;
          }
        }

        .total-of {
          margin-left: 12px;
        }
      }
    }

    .statistic-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      background: linear-gradient(
        30deg,
        rgba(255, 255, 255, 0.2),
        rgba(220, 254, 217, 0.2),
        rgba(210, 209, 255, 0.2),
        rgba(255, 248, 188, 0.2)
      );
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
    }

    .reactions {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 8px;
      margin-top: 24px;

      .counts {
        padding: 16px 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .reaction-count {
          display: flex;
          flex-direction: column;
          align-items: center;

          .reaction-label {
            margin-bottom: 6px;
          }

          .image {
            margin-bottom: 12px;
          }
        }
      }

      .info {
        margin: 32px 0px 0px 0px;
      }
    }

    .no-data {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        text-transform: capitalize;
      }

      background: var(--grayscale-100);
      border-radius: 8px;
    }
  }

  .custom-tooltip {
    padding: 8px;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }
`;
