import styled from 'styled-components';

export const StyledProfileForm = styled.div`
  .form-steps {
    margin-bottom: 64px;
  }

  .form-step-content {
    width: 100%;
  }

  .form-step-actions {
    width: 100%;
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button:first-child {
      padding-left: 0px;
    }
  }

  .cancel-modal {
    .title {
      margin-bottom: 6px;
    }

    hr {
      margin-top: 42px;
    }

    .cancel-modal-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;

      .button:first-child {
        padding-left: 0px;
      }
    }
  }
`;
