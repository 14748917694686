import { createSlice } from '@reduxjs/toolkit';

import { ModalState } from './types';

const initialState: ModalState = {
  id: undefined,
  show: false,
  hide: false,
  width: '600px',
  maxWidth: 'auto',
  content: '',
  disableBackgroundClick: false,
  onAfterClose: undefined,
  hideCloseButton: false,
  onCloseButtonClick: undefined,
};

export const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalView: (state, action) => {
      const {
        id,
        show,
        hide,
        width,
        maxWidth,
        padding,
        margin,
        content,
        disableBackgroundClick,
        onAfterClose,
        hideCloseButton,
        onCloseButtonClick,
      } = action.payload;

      state.id = id;
      state.show = show;
      state.hide = hide;
      state.width = width;
      state.maxWidth = maxWidth;
      state.padding = padding;
      state.margin = margin;
      state.content = content;
      state.disableBackgroundClick = disableBackgroundClick;
      state.onAfterClose = onAfterClose;
      state.hideCloseButton = hideCloseButton;
      state.onCloseButtonClick = onCloseButtonClick;
    },
    hideModal: (state) => {
      state.hide = true;
    },
    showModal: (state) => {
      state.hide = false;
    },
    closeModal: (state) => {
      const {
        id,
        show,
        hide,
        width,
        maxWidth,
        padding,
        margin,
        content,
        disableBackgroundClick,
        onAfterClose,
        hideCloseButton,
        onCloseButtonClick,
      } = initialState;

      state.id = id;
      state.show = show;
      state.hide = hide;
      state.width = width;
      state.maxWidth = maxWidth;
      state.padding = padding;
      state.margin = margin;
      state.content = content;
      state.disableBackgroundClick = disableBackgroundClick;
      state.onAfterClose = onAfterClose;
      state.hideCloseButton = hideCloseButton;
      state.onCloseButtonClick = onCloseButtonClick;
    },
  },
});

export const { setModalView, closeModal, hideModal, showModal } =
  ModalSlice.actions;

export default ModalSlice.reducer;
