import styled from 'styled-components';

export const StyledStaffRestrictedLayout = styled.div.attrs(() => ({
  className: 'staff-restricted-layout',
}))`
  width: 100%;
  height: 100%;
`;

export default StyledStaffRestrictedLayout;
