import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getMetabaseDashboards } from 'apis/board';

import { useAppSelector } from 'store/hooks';

import Loading from 'components/molecule/Loading';
import EmptyMessage from 'components/molecule/EmptyMessage';

import { StyledMetabaseResults } from './styles';

const MetabaseResults: React.FC = () => {
  const { contentId, type } = useParams();
  const { t } = useTranslation();

  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const [dashboardUrls, setDashboardUrls] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (contentId && type) {
      setLoading(true);

      getMetabaseDashboards(organizationId, contentId, type)
        .then((response) => {
          setDashboardUrls(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contentId, organizationId, type]);

  return (
    <StyledMetabaseResults>
      {loading && <Loading width="100px" height="100px" />}
      {!loading && dashboardUrls.length === 0 && (
        <EmptyMessage
          showImage
          title={t('An error occurred while displaying the results')}
        />
      )}
      {dashboardUrls.map((dashboardUrl, index) => (
        <iframe
          key={index}
          src={dashboardUrl}
          className="default-scroll"
          loading="eager"
        />
      ))}
    </StyledMetabaseResults>
  );
};

export default MetabaseResults;
