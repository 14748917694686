import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/slices/modal';

import Text from 'components/atom/Text';
import DatePicker from 'components/molecule/DatePicker';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';
import Separator from 'components/atom/Separator';
import Alert from 'components/molecule/Alert';

import { isValidTimeFormat } from 'utils/date';

import { PublicationDateConfigProps } from './types';

import { StyledPublicationDateConfig } from './styles';

const PublicationDateConfig: React.FC<PublicationDateConfigProps> = ({
  contentState,
  setContentState,
  canEditFinishAt,
  setWithRecurrence,
  contentStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const editableStatuses = ['', 'draft', 'scheduled'];

  const [publicationDates, setPublicationDates] = useState({
    start_at: contentState.start_at,
    start_at_time: contentState.start_at_time,
    finish_at: contentState.finish_at,
    finish_at_time: contentState.finish_at_time,
  });

  const handleChangeStartAt = (date: Date) => {
    setPublicationDates((updatedState) => ({
      ...updatedState,
      start_at: {
        ...updatedState.start_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeStartAtTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setPublicationDates((updatedState) => ({
      ...updatedState,
      start_at_time: {
        ...updatedState.start_at_time,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishAt = (date: Date) => {
    setPublicationDates((updatedState) => ({
      ...updatedState,
      finish_at: {
        ...updatedState.finish_at,
        value: date,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeFinishAtTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setPublicationDates((updatedState) => ({
      ...updatedState,
      finish_at_time: {
        ...updatedState.finish_at_time,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const clearErrors = () => {
    setPublicationDates((updatedState) => ({
      ...updatedState,
      start_at: {
        ...updatedState.start_at,
        error: { hasError: false, errorMessage: '' },
      },
      start_at_time: {
        ...updatedState.start_at_time,
        error: { hasError: false, errorMessage: '' },
      },
      finish_at: {
        ...updatedState.finish_at,
        error: { hasError: false, errorMessage: '' },
      },
      finish_at_time: {
        ...updatedState.finish_at_time,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleSave = () => {
    const {
      start_at: { value: start_at },
      start_at_time: { value: start_at_time },
      finish_at: { value: finish_at },
      finish_at_time: { value: finish_at_time },
    } = publicationDates;

    clearErrors();

    if (!start_at) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        start_at: {
          ...updatedState.start_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (!start_at_time) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (!isValidTimeFormat(start_at_time.substring(0, 5))) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        start_at_time: {
          ...updatedState.start_at_time,
          error: { hasError: true, errorMessage: t('Enter a valid time') },
        },
      }));
      return;
    }

    if (
      finish_at &&
      !dayjs(start_at).isSame(finish_at) &&
      !dayjs(start_at).isBefore(finish_at)
    ) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        finish_at: {
          ...updatedState.finish_at,
          error: {
            hasError: true,
            errorMessage: t(
              'The start date cannot be greater than the end date.',
            ),
          },
        },
      }));
      return;
    }

    if (finish_at_time && !finish_at) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        finish_at: {
          ...updatedState.finish_at,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (finish_at && !finish_at_time) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: { hasError: true, errorMessage: t('This field is required') },
        },
      }));
      return;
    }

    if (finish_at && !isValidTimeFormat(finish_at_time.substring(0, 5))) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: { hasError: true, errorMessage: t('Enter a valid time') },
        },
      }));
      return;
    }

    if (
      finish_at &&
      dayjs(start_at).isSame(finish_at) &&
      finish_at_time &&
      finish_at_time <= start_at_time
    ) {
      setPublicationDates((updatedState) => ({
        ...updatedState,
        finish_at_time: {
          ...updatedState.finish_at_time,
          error: {
            hasError: true,
            errorMessage: t(
              'The start time cannot be greater than or equal to the end time.',
            ),
          },
        },
      }));
      return;
    }

    setContentState((updatedState) => ({
      ...updatedState,
      start_at: publicationDates.start_at,
      start_at_time: publicationDates.start_at_time,
      finish_at: publicationDates.finish_at,
      finish_at_time: publicationDates.finish_at_time,
    }));
    setWithRecurrence(false);
    dispatch(closeModal());
  };

  const canShowAlertRecurrence = () => {
    return (
      ['active', 'scheduled'].includes(contentStatus) &&
      contentState.scheduler_start_date_at.value
    );
  };

  return (
    <StyledPublicationDateConfig>
      <Text as="h5" weight="700">
        {t('Publication date')}
      </Text>

      {!canEditFinishAt && (
        <Alert
          description={t(
            'Cannot change end date of parent content - finalize all content conditional on this before proceeding with editing',
          )}
        />
      )}

      {canShowAlertRecurrence() && (
        <Alert
          icon="alert"
          title={t('Attention!')}
          description={t(
            'By saving this change you will lose recurring schedule',
          )}
        />
      )}

      <div className="form-group">
        <DatePicker
          label={t('Start at')}
          id="start-at"
          icon="calendar-2-fill"
          value={publicationDates.start_at.value || undefined}
          onChange={handleChangeStartAt}
          hasError={publicationDates.start_at.error.hasError}
          errorMessage={publicationDates.start_at.error.errorMessage}
          disabled={
            !editableStatuses.some((status) => status === contentStatus)
          }
        />
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label="time"
          hideLabel
          placeholder="HH:MM"
          value={publicationDates.start_at_time.value?.toString() || ''}
          onChange={handleChangeStartAtTime}
          hasError={publicationDates.start_at_time.error.hasError}
          errorMessage={publicationDates.start_at_time.error.errorMessage}
          disabled={
            !editableStatuses.some((status) => status === contentStatus)
          }
        />
      </div>
      <div className="form-group">
        <DatePicker
          label={t('Finish at')}
          id="finish-at"
          icon="calendar-2-fill"
          value={publicationDates.finish_at.value}
          onChange={handleChangeFinishAt}
          hasError={publicationDates.finish_at.error.hasError}
          errorMessage={publicationDates.finish_at.error.errorMessage}
          disabled={!canEditFinishAt}
        />
        <Input
          type="time"
          theme="gray"
          icon="clock-solid"
          label="time"
          hideLabel
          placeholder={'HH:MM'}
          value={publicationDates.finish_at_time.value?.toString() || undefined}
          onChange={handleChangeFinishAtTime}
          hasError={publicationDates.finish_at_time.error.hasError}
          errorMessage={publicationDates.finish_at_time.error.errorMessage}
          disabled={!canEditFinishAt}
        />
      </div>
      <Separator />
      <Button theme="primary" rounded="true" onClick={handleSave}>
        {t('Save')}
      </Button>
    </StyledPublicationDateConfig>
  );
};

export default PublicationDateConfig;
