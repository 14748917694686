export const stripHTMLTags = (str: string | undefined | null): string => {
  if (!str) return '';

  // remove HTML tags from string, returning text content from it
  const doc: Document = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
};

export const addEllipsisInMiddle = (
  text: string,
  maxLength: number,
  totalLeft: number,
  totalRight: number,
) => {
  if (text.length > maxLength) {
    return (
      text.substring(0, totalLeft) +
      '...' +
      text.substring(text.length - totalRight)
    );
  }
  return text;
};
