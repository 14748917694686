import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Button from 'components/molecule/Button';
import Tooltip from 'components/atom/Tooltip';

import { StyledCustomFieldsConfigItem } from './styles';

import { AvailableIcons } from 'components/atom/Icon/types';

interface CustomFieldsConfigItemProps {
  id: string;
  title: string;
  type: string;
  onEdit?: (id: string) => void;
  disabled: boolean;
}

const customFieldIconNames = {
  custom_decimal: 'number',
  custom_string: 'menu',
  custom_boolean: 'toggle-line',
  custom_choices: 'drop',
  custom_multiple_choices: 'drop',
};

const CustomFieldsConfigItem: React.FC<CustomFieldsConfigItemProps> = ({
  id,
  title,
  type,
  onEdit,
  disabled,
}) => {
  const { t } = useTranslation();

  const editableTypes = [
    'custom_multiple_choices',
    'custom_choices',
    'custom_decimal',
    'custom_string',
    'custom_boolean',
  ];

  return (
    <StyledCustomFieldsConfigItem>
      <div className="info">
        <div className="custom-field-icon">
          <Icon
            name={
              customFieldIconNames[
                type as keyof typeof customFieldIconNames
              ] as keyof typeof AvailableIcons
            }
            color="tertiary-color-1"
          />
        </div>
        <Tooltip
          id={`custom-field-${id}`}
          content={title}
          width="340px"
          disabled={title.length < 56}
        >
          <Text>{title}</Text>
        </Tooltip>
      </div>
      {editableTypes.includes(type) ? (
        <div className="actions">
          <Tooltip
            id={`custom-field-button-${id}`}
            content={t(
              'This custom field cannot be edited, as it belongs to content',
            )}
            disabled={!disabled}
          >
            <Button
              theme="link-primary"
              size="small"
              onClick={() => {
                if (onEdit) {
                  onEdit(id);
                }
              }}
              disabled={disabled}
            >
              {t('Edit')}
            </Button>
          </Tooltip>
        </div>
      ) : null}
    </StyledCustomFieldsConfigItem>
  );
};

export default CustomFieldsConfigItem;
