import styled from 'styled-components';

export const StyledSurveyContent = styled.div`
  height: calc(100% - 88px);
  width: 100%;

  .main-header {
    .back-logo {
      display: flex;
      align-items: center;

      .button {
        margin-right: 12px;
      }
    }
  }
`;
