import styled from 'styled-components';

export const StyledProfilesConfig = styled.div`
  width: 100%;

  .content {
    width: 100%;
    margin-top: 16px;
    overflow-y: visible !important;

    .table {
      width: 100%;
      margin-top: 56px;
      .table-wrapper {
        width: 100%;
        padding-bottom: 44px;
        table {
          width: 100%;
          /* thead {
            th {
              &:last-child {
                width: 30px;
                max-width: 30px;
                min-width: 30px;
              }
            }
          } */
          tbody {
            tr {
              td {
                &:first-child {
                  .text {
                    max-width: 38ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .tag-grouper > .tag {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }

      .pagination-control {
        margin-top: 16px;
      }
    }
  }

  .modal {
    background: #00000000;
    .cancel-form-confirm {
      .title {
        margin-bottom: 6px;
      }

      hr {
        margin-top: 42px;
      }

      .cancel-modal-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;

        .button:first-child {
          padding-left: 0px;
        }
      }
    }
  }
`;
