import React from 'react';

import useVisualIdentity from './useVisualIdentity';

import Text from 'components/atom/Text';
import Header from 'components/atom/Header';
import Button from 'components/molecule/Button';
import ColorPalette from './components/ColorPalette';
import Breadcrumb from 'components/organism/Breadcrumb';
import PageLoading from 'components/molecule/PageLoading';

const VisualIdentity: React.FC = () => {
  const {
    t,
    navigate,
    handleSave,
    saveLoading,
    fieldErrors,
    setFieldErrors,
    paletteSelectOption,
    setPaletteSelectOption,
  } = useVisualIdentity();
  return (
    <>
      <Header
        className="!pl-[54px]"
        leftSideContent={
          <Breadcrumb
            links={[
              { label: t('Settings'), action: () => navigate('/settings') },
              {
                label: t('Visual identity'),
                action: () => navigate(''),
              },
            ]}
          />
        }
        rightSideContent={
          <div className="pr-[22px]">
            <Button theme="primary" rounded="true" onClick={handleSave}>
              {t('Save')}
            </Button>
          </div>
        }
      />
      <div className="px-[58px] py-8">
        <Text as="h3" weight="700" color="grayscale-400">
          {t('Customize your organizations profile')}
        </Text>
        <div className="pt-8 flex gap-5">
          <ColorPalette
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            paletteSelectOption={paletteSelectOption}
            setPaletteSelectOption={setPaletteSelectOption}
          />
        </div>
      </div>

      {saveLoading && <PageLoading />}
    </>
  );
};

export default VisualIdentity;
