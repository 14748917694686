import React from 'react';

import TabMenu from 'components/molecule/TabMenu';

import { Tab } from 'components/molecule/TabMenu/types';

import { StyledMuralConfigMenu } from './styles';

interface MuralConfigMenuProps {
  tabMenuOptions: Tab[];
  onChangeTab: (key: string) => void;
}

const MuralConfigMenu: React.FC<MuralConfigMenuProps> = ({
  tabMenuOptions,
  onChangeTab,
}) => {
  return (
    <StyledMuralConfigMenu className="mural-config-menu">
      <TabMenu tabs={tabMenuOptions} onChangeTab={(tab) => onChangeTab(tab)} />
    </StyledMuralConfigMenu>
  );
};

export default MuralConfigMenu;
