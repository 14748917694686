import { createSlice } from '@reduxjs/toolkit';

import {
  addFileToUploadListUtil,
  setUploadListIsVisibleUtil,
  setUploadListIsExpandedUtil,
  startUploadUtil,
  completeUploadUtil,
  updateUploadProgressUtil,
  setCloseButtonIsVisibleUtil,
  closeUploadListUtil,
  cancelUploadUtil,
  setFileUploadInstanceUtil,
  setUploadDoneUtil,
} from './reducers';

import { DriveState } from './types';

const initialState: DriveState = {
  close_button_is_visible: false,
  upload_list_is_expanded: true,
  current_in_progress_file_id: '',
  is_visible: false,
  last_uploaded_file_id: '',
  file_upload_instance: null,
  uploaded_files: [],
  upload_in_progress: false,
};

export const driveSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    addFileToUploadList: addFileToUploadListUtil,
    setUploadListIsVisible: setUploadListIsVisibleUtil,
    setUploadListIsExpanded: setUploadListIsExpandedUtil,
    startUpload: startUploadUtil,
    completeUpload: completeUploadUtil,
    updateUploadProgress: updateUploadProgressUtil,
    setCloseButtonIsVisible: setCloseButtonIsVisibleUtil,
    closeUploadList: closeUploadListUtil,
    cancelUpload: cancelUploadUtil,
    setFileUploadInstance: setFileUploadInstanceUtil,
    setUploadDone: setUploadDoneUtil,
  },
});

export const {
  addFileToUploadList,
  setUploadListIsVisible,
  setUploadListIsExpanded,
  startUpload,
  completeUpload,
  updateUploadProgress,
  setCloseButtonIsVisible,
  closeUploadList,
  cancelUpload,
  setFileUploadInstance,
  setUploadDone,
} = driveSlice.actions;

export default driveSlice.reducer;
