import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';
import { setModalView } from 'store/slices/modal';

import Dropdown from 'components/molecule/Dropdown';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import ArchiveContent from './components/ArchiveContent';
import PinContentModal from './components/PinContentModal';

import { AvailableTypes } from 'apis/board/types';
import { makeCopy } from 'apis/board';

import { CustomFilterSendAttrs } from 'pages/board/Mural/types';

import { StyledMuralTableActions } from './styles';
import { getWebappURL } from 'settings';
import RemoveContent from './components/RemoveContent';

interface MuralTableActionsProps {
  contentId: string;
  contentTypeId: string;
  contentType: string;
  contentSubtype: string;
  contentStatus: string;
  canEdit: boolean;
  isFixed: boolean;
  isArchived: boolean;
  onLoadContent: (
    page: number,
    search: string,
    type: keyof typeof AvailableTypes,
    order: string,
    filter: CustomFilterSendAttrs,
  ) => void;
  filters: {
    page: number;
    search: string;
    type: keyof typeof AvailableTypes;
    order: string;
    filter: CustomFilterSendAttrs;
  };
}

const MuralTableActions: React.FC<MuralTableActionsProps> = ({
  contentId,
  contentTypeId,
  contentType,
  contentSubtype,
  contentStatus,
  canEdit,
  isFixed,
  isArchived,
  onLoadContent,
  filters,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId: string = useAppSelector(
    (store) => store.organization.pk,
  );

  const { page, search, type, order, filter } = filters;

  const [open, setOpen] = useState(false);

  const showPinSchedule = () => {
    dispatch(
      setModalView({
        show: true,
        content: (
          <PinContentModal
            contentTypeId={contentTypeId}
            organizationId={organizationId}
            onSave={() => onLoadContent(page, search, type, order, filter)}
          />
        ),
        width: '565px',
      }),
    );
  };

  const showArchiveContent = () => {
    dispatch(
      setModalView({
        show: true,
        width: '565px',
        content: (
          <ArchiveContent
            contentTypeId={contentTypeId}
            organizationId={organizationId}
            onSave={() => onLoadContent(page, search, type, order, filter)}
          />
        ),
      }),
    );
  };

  const showRemoveContent = () => {
    dispatch(
      setModalView({
        show: true,
        width: '565px',
        content: (
          <RemoveContent
            contentTypeId={contentTypeId}
            organizationId={organizationId}
            onSave={() => onLoadContent(page, search, type, order, filter)}
          />
        ),
      }),
    );
  };

  const canDuplicateContent = useMemo(() => {
    const status = ['scheduled', 'active', 'draft'].includes(contentStatus);
    const subtype = ['short', 'article'].includes(contentSubtype);

    return status && subtype;
  }, [contentStatus, contentSubtype]);

  const canCopyLink = useMemo(() => {
    const status = ['scheduled', 'active'].includes(contentStatus);
    const subtype = ['short', 'article'].includes(contentSubtype);

    return status && subtype;
  }, [contentStatus, contentSubtype]);

  const handleMakeCopy = () => {
    setOpen(false);
    makeCopy(organizationId, contentTypeId)
      .then(() => {
        dispatch(
          showMessage({
            title: t('Content has been duplicated'),
            theme: 'success',
            icon: 'check',
            time: 10000,
            customLeft: '0px',
          }),
        );

        onLoadContent(page, search, type, order, filter);
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t(
              'An unexpected error occurred while duplicating the content',
            ),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      });
  };

  const handleCopyLink = () => {
    const link = getWebappURL(
      `org/${organizationId}/${contentType}/${contentTypeId}`,
    );
    navigator.clipboard.writeText(link);
    dispatch(
      showMessage({
        title: `${t('Link copied:')} ${link}`,
        theme: 'success',
        icon: 'check',
        time: 10000,
        customLeft: '0px',
      }),
    );
  };

  return (
    <StyledMuralTableActions>
      <Dropdown
        open={open}
        setOpen={setOpen}
        toggleElement={<Icon name="more-2" />}
        toggleButtonTheme="link-page-button-gray-primary"
        dropdownContentColor="dark-color"
        contentPlace="right"
      >
        {['draft', 'scheduled', 'active'].includes(contentStatus) && canEdit && (
          <Button
            theme="dark"
            leftIcon="pencil-fill"
            radius="0px"
            to={`/mural/content/${contentType}/${contentSubtype}/${contentId}`}
          >
            {t('Edit')}
          </Button>
        )}
        {/* <Button theme="dark" leftIcon="delete-bin-6-fill" radius="0px">
          {t('Delete')}
        </Button>
        <Button theme="dark" leftIcon="send" radius="0px">
          {t('Publish')}
        </Button> */}

        {['scheduled', 'active', 'draft'].includes(contentStatus) && (
          <Button
            theme="dark-dropdown"
            leftIcon="pin-side"
            onClick={showPinSchedule}
            disabled={isFixed || isArchived}
          >
            {t('Pin')}
          </Button>
        )}
        {['active', 'finished', 'finishing'].includes(contentStatus) && (
          <Button
            theme="dark"
            leftIcon="level"
            radius="0px"
            to={`/mural/content/${contentType}/${contentSubtype}/${contentId}/results`}
          >
            {t('Results')}
          </Button>
        )}
        {['active', 'finished', 'finishing'].includes(contentStatus) && (
          <Button
            theme="dark-dropdown"
            leftIcon="folder-line"
            onClick={showArchiveContent}
            disabled={isArchived}
          >
            {t('Archive')}
          </Button>
        )}
        {canDuplicateContent && (
          <Button
            theme="dark-dropdown"
            leftIcon="copy"
            onClick={handleMakeCopy}
          >
            {t('Make a copy')}
          </Button>
        )}
        {canCopyLink && (
          <Button
            theme="dark-dropdown"
            leftIcon="link"
            onClick={handleCopyLink}
          >
            {t('Copy link')}
          </Button>
        )}
        {['draft'].includes(contentStatus) && (
          <Button
            theme="dark-dropdown"
            leftIcon="delete-bin-6-line"
            onClick={showRemoveContent}
          >
            {t('Delete draft')}
          </Button>
        )}
      </Dropdown>
    </StyledMuralTableActions>
  );
};

export default MuralTableActions;
