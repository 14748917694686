import React from 'react';

import { StyledSwitchButton } from './styles';
import { SwitchButtonProps } from './types';

const SwitchButton: React.FC<SwitchButtonProps> = ({
  size = 'small',
  className,
  id,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledSwitchButton
      size={size}
      className={`switch-button ${className} ${disabled ? 'disabled' : ''}`}
    >
      <input
        id={id}
        className="switch switch--shadow"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </StyledSwitchButton>
  );
};

export default SwitchButton;
