import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from './types';
import { StyledWizardStep } from '../../styles';

import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';
import Button from 'components/molecule/Button';

interface StageInfo {
  admin_full_name?: string;
  admin_email?: string;
  admin_password?: string;
}

interface AccountStageProps {
  stageErrors: FieldErrors;
  formSubmit: (
    admin_full_name: string,
    admin_email: string,
    admin_password: string,
  ) => void;
  stageInfo: StageInfo;
}

// Stage 1

const AccountStage: React.FC<AccountStageProps> = (props) => {
  const { t } = useTranslation();
  const [admin_full_name, setName] = useState('');
  const [admin_email, setEmail] = useState('');
  const [admin_password, setPassword] = useState('');
  const [admin_confirm_password, setConfirmPassword] = useState('');
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(
    props.stageErrors,
  );

  useEffect(() => {
    const { admin_full_name, admin_email, admin_password } = props.stageInfo;

    if (admin_full_name) {
      setName(admin_full_name);
    } else {
      const storedName = localStorage.getItem('wizard-admin-full-name');
      if (storedName) {
        setName(storedName);
      }
    }

    if (admin_email) {
      setEmail(admin_email);
    } else {
      const storedEmail = localStorage.getItem('wizard-admin-email');
      if (storedEmail) {
        setEmail(storedEmail);
      }
    }

    if (admin_password) {
      setPassword(admin_password);
      setConfirmPassword(admin_password);
    }
  }, [props.stageInfo]);

  const clearErrorField = (field: string) => {
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[field as keyof FieldErrors].hasError = false;
    newFieldErrors[field as keyof FieldErrors].errorMessage = '';
    setFieldErrors(newFieldErrors);
  };

  const handleNameField = (value: string) => {
    localStorage.setItem('wizard-admin-full-name', value);
    const fieldName = 'admin_full_name';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setName(value);
  };

  const handleEmailField = (value: string) => {
    localStorage.setItem('wizard-admin-email', value);
    const fieldName = 'admin_email';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setEmail(value);
  };

  const handlePasswordField = (value: string) => {
    const fieldName = 'admin_password';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setPassword(value);
  };

  const handleConfirmPasswordField = (value: string) => {
    const fieldName = 'admin_confirm_password';
    if (fieldErrors[fieldName as keyof FieldErrors].hasError) {
      clearErrorField(fieldName);
    }
    setConfirmPassword(value);
  };

  const nameIsNotEmpty = admin_full_name.trim().length > 0;
  const emailIsNotEmpty = admin_email.trim().length > 0;
  const passwordIsNotEmpty = admin_password.trim().length > 0;
  const confirmPasswordIsNotEmpty = admin_confirm_password.trim().length > 0;

  const hasError = Object.values(fieldErrors).some(
    (fieldError) => fieldError.hasError,
  );

  const formIsNotEmpty =
    nameIsNotEmpty &&
    emailIsNotEmpty &&
    passwordIsNotEmpty &&
    confirmPasswordIsNotEmpty &&
    !hasError;

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formIsNotEmpty) {
      if (admin_password.length < 8) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          admin_password: {
            hasError: true,
            errorMessage: t('Password must have at least 8 characters.'),
          },
        }));
      } else if (admin_confirm_password !== admin_password) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          admin_confirm_password: {
            hasError: true,
            errorMessage: t('Passwords must match.'),
          },
        }));
      } else {
        props.formSubmit(admin_full_name, admin_email, admin_password);
      }
    }
  };

  return (
    <StyledWizardStep>
      <form className="wizard-form" onSubmit={formSubmitHandler}>
        <Text as="h2" color="dark-color" weight="700">
          {t('Transform the way your organization communicates.')}
        </Text>
        <Text as="h5" className="mt-2">
          {t('Talk a bit about you.')}
        </Text>
        <Input
          type="text"
          id="input-name"
          icon="user-line"
          label={t('What is your name?')}
          placeholder={t('Full name')}
          className="mt-2"
          value={admin_full_name}
          limit={60}
          hideCharacterCount={true}
          onChange={(event) => handleNameField(event.target.value)}
          hasError={fieldErrors.admin_full_name.hasError}
          errorMessage={fieldErrors.admin_full_name.errorMessage}
        />
        <Input
          type="email"
          id="input-email"
          icon="at"
          label={t('What is your email?')}
          placeholder={t('Email')}
          className="mt-2"
          value={admin_email}
          onChange={(event) => handleEmailField(event.target.value)}
          hasError={fieldErrors.admin_email.hasError}
          errorMessage={fieldErrors.admin_email.errorMessage}
        />
        <Input
          type="password"
          id="input-password"
          icon="lock-line"
          label={t('What is your password?')}
          placeholder={t('Password')}
          className="mt-2"
          value={admin_password}
          limit={16}
          hideCharacterCount={true}
          onChange={(event) => handlePasswordField(event.target.value)}
          hasError={fieldErrors.admin_password.hasError}
          errorMessage={fieldErrors.admin_password.errorMessage}
        />
        <Input
          type="password"
          id="input-password-confirm"
          icon="lock-line"
          label={t('Confirm your password.')}
          placeholder={t('Repeat your password')}
          className="mt-2"
          value={admin_confirm_password}
          limit={16}
          hideCharacterCount={true}
          onChange={(event) => handleConfirmPasswordField(event.target.value)}
          hasError={fieldErrors.admin_confirm_password.hasError}
          errorMessage={fieldErrors.admin_confirm_password.errorMessage}
        />

        <div className="wizard-footer">
          <div className="wizard-action">
            <Button theme="link-dark-gray" rounded="true" disabled={true}>
              {t('Back')}
            </Button>
            <Button
              theme="primary"
              rounded="true"
              rightIcon="arrow-right-s-line"
              disabled={!formIsNotEmpty}
              type="submit"
            >
              {t('Next')}
            </Button>
          </div>
        </div>
      </form>
    </StyledWizardStep>
  );
};

export default AccountStage;
