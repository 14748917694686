import { CustomBooleanCfgResponse, CustomFieldBoolean } from 'apis/core/types';
import { Dispatch, SetStateAction } from 'react';

export interface BooleanOptionItem extends CustomFieldBoolean {
  id?: string;
  name: string;
  boolean_label_false_invalid_msg: string;
  boolean_label_true_invalid_msg: string;
}

export const defaultBooleanOptionItem: BooleanOptionItem = {
  name: '',
  boolean_label_false: null,
  boolean_label_false_invalid_msg: '',
  boolean_label_true: null,
  boolean_label_true_invalid_msg: '',
};

export interface BooleanOptionsProps {
  config?: CustomBooleanCfgResponse;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  item: BooleanOptionItem;
  setItem: Dispatch<SetStateAction<BooleanOptionItem>>;
}
