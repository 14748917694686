import React from 'react';

import { useAppDispatch } from 'store/hooks';

import { setModalView } from 'store/slices/modal';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Card from 'components/atom/Card';

import { StyledModal } from './styles';

export interface ModalProps {
  show?: boolean;
  hide?: boolean;
  width?: string;
  maxWidth?: string;
  padding?: string;
  margin?: string;
  content?: React.ReactNode;
  disableBackgroundClick?: boolean;
  onAfterClose?: () => void;
  hideCloseButton?: boolean;
  onCloseButtonClick?: () => void;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  show = false,
  hide = false,
  width = '600px',
  maxWidth = 'auto',
  padding = '32px',
  margin = '0px',
  content,
  disableBackgroundClick = false,
  onAfterClose,
  hideCloseButton,
  onCloseButtonClick,
  className = '',
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
      return;
    }

    dispatch(setModalView({ show: false }));
    if (onAfterClose) {
      onAfterClose();
    }
  };

  const clickAwayListener = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> & {
      target: HTMLButtonElement;
    },
  ) => {
    if (event.target.classList.contains('modal') && !hide) {
      handleClose();
    }
  };

  return (
    <StyledModal
      className={`modal default-scroll ${
        show && hide ? '!invisible' : ''
      } ${className}`}
      style={{
        visibility: hide ? 'hidden' : 'visible',
      }}
      onClick={!disableBackgroundClick ? clickAwayListener : () => undefined}
      width={width}
      maxWidth={maxWidth}
      padding={padding}
      margin={margin}
    >
      <div className="modal-content-wrapper">
        {!hideCloseButton && (
          <Button
            theme="link-gray-primary"
            rounded="true"
            size="small"
            onClick={handleClose}
            className="close-modal-button"
          >
            <Icon name="close" />
          </Button>
        )}
        <Card
          color="white-color"
          noBorder="true"
          className="modal-content"
          shadow="true"
        >
          {content}
        </Card>
      </div>
    </StyledModal>
  );
};

export default Modal;
