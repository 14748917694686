import styled from 'styled-components';

export const StyledFilterModal = styled.div`
  .filter-modal-header {
    margin-bottom: 42px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-wrap: wrap;

    .button {
      padding: 0px;
    }
  }

  .filter-modal-content {
    margin: 0 0 42px 0;
    border-top: 1px solid var(--grayscale-100);

    .general-features-label {
      padding: 32px 0px;
    }

    .loading {
      margin-top: 16px;
    }
  }

  .filter-modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 720px) {
    .filter-modal-footer {
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
      }
    }
  }
`;
