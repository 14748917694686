import React from 'react';

import { useAppSelector } from 'store/hooks';

import { getI18NextLanguage } from 'services/language';

import Text from 'components/atom/Text';

import { abbreviateNumberFormatter } from 'utils/numbers';

import { StyledMuralTableComments } from './styles';

interface MuralTableCommentsProps {
  total: number;
}

const MuralTableComments: React.FC<MuralTableCommentsProps> = ({ total }) => {
  const storedLanguage = getI18NextLanguage();
  const language: string = useAppSelector((state) => state.client.language);

  return (
    <StyledMuralTableComments>
      <Text color="grayscale-400">
        {abbreviateNumberFormatter(total, storedLanguage || language)}
      </Text>
    </StyledMuralTableComments>
  );
};

export default MuralTableComments;
