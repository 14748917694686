import React from 'react';
import { useTranslation } from 'react-i18next';

import { setModalView } from 'store/slices/modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { appName, appVersion, environment } from 'settings';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';
import Button from '../Button';

import { StyledFooter } from './styles';
import ResetInteractions from 'components/organism/ResetInteractions';

interface FooterProps {
  organizationName: string;
}

const Footer: React.FC<FooterProps> = ({ organizationName }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isStaff = useAppSelector((state) => state.user.isStaff);
  const allowInteractionDeletion = useAppSelector(
    (state) => state.organization.allow_interaction_deletion,
  );

  const environmentLabel = environment !== 'prod' ? `(${environment})` : '';

  const showResetModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '500px',
        content: <ResetInteractions />,
      }),
    );
  };

  return (
    <StyledFooter
      background={environmentLabel ? 'danger-color-300' : 'none'}
      showResetButton={isStaff && allowInteractionDeletion}
    >
      <div className="footer-wrapper">
        <div className="icon-wrapper">
          <Icon name="logo" />
        </div>
        <div className="text-wrapper">
          <Text as="small" weight="bold" color="white-color">
            {organizationName}
          </Text>
          <Text as="small" color="white-color">
            {t('Using')} {appName} v{appVersion} {environmentLabel}
          </Text>
        </div>
      </div>

      {isStaff && allowInteractionDeletion && (
        <Button
          leftIcon="delete-bin-6-line"
          theme="danger"
          className="remove-interactions"
          onClick={showResetModal}
        >
          {t('Remove interactions')}
        </Button>
      )}
    </StyledFooter>
  );
};

export default Footer;
