import apiV2 from 'apis/apiV2';
import { OrganizationInfoSendProps } from './types';

const basePathname = '/admin/org';

export const getOrganizationInfo = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/core/organization`);

export const updateOrganizationInfo = (
  organizationId: string,
  data: OrganizationInfoSendProps,
) => {
  return apiV2.patch(
    `${basePathname}/${organizationId}/core/organization`,
    data,
  );
};
