import styled from 'styled-components';

export const StyledDenylistConfig = styled.div`
  width: 100%;
  max-width: 634px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 56px;
    width: 100%;
  }

  h5 {
    margin-bottom: 6px;
    width: 100%;
  }

  & > p {
    margin-bottom: 16px;
    width: 100%;
  }

  .denylist-items-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--grayscale-100);

    .button {
      min-width: max-content;
    }

    .add-word-button {
      padding-left: 0px;
    }

    .input {
      max-width: 280px;
      .input-content {
        padding-right: 6px;

        &.disabled {
          background: none;
          border: none;
          i,
          input {
            color: var(--grayscale-100);
            background: none;
          }
        }
      }

      input {
        width: 100%;
      }
    }
  }

  .denylist-items {
    position: relative;
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    max-height: 550px;

    & > .loading {
      margin-top: 16px;
    }
  }

  & > .loading {
    margin-top: 64px;
  }

  .empty-message {
    width: 100%;
    margin-top: 64px;
    max-width: 340px;
  }
`;
