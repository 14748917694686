import apiV2 from 'apis/apiV2';

const basePathname = '/admin/org';

export const updatePaletteColor = (
  organizationId: string,
  colorSchemeId: string | undefined,
) => {
  return apiV2.patch(
    `${basePathname}/${organizationId}/core/organization/update_visual_identity/`,
    {
      color_scheme_id: colorSchemeId,
    },
  );
};
