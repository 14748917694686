import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledSmartPushForm } from './styles';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';
import DatePicker from 'components/molecule/DatePicker';
import Separator from 'components/atom/Separator';
import { PushFields } from './types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPageLoading } from 'store/slices/pageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { dateAndTimeToApiDate } from 'utils/date';
import { RequestFieldError } from 'apis/types';
import { showMessage } from 'store/slices/toaster';
import Editor from 'components/molecule/Editor';
import Header from 'components/atom/Header';
import { SegmentationSendProps } from 'apis/board/types';
import { SegmentationValueProps } from 'components/organism/SegmentationEditor/types';
import { cauculateTotalSegmentation, getSegmentationOptions } from 'apis/board';
import { getSegmentationDataValues } from 'utils/segmentation';
import { setModalView } from 'store/slices/modal';
import { abbreviateNumberFormatter } from 'utils/numbers';
import Loading from 'components/molecule/Loading';
import ContentSegmentationModal from 'pages/board/MuralContent/components/ContentSegmentationModal';
import { AvailableOrganizationModules } from 'utils/organization';
import { PushPostSendData } from 'apis/smartpush/types';
import {
  createSmartPush,
  getSmartPush,
  updateSmartPush,
  abortSmartPush,
} from 'apis/smartpush';
import Confirm from 'components/molecule/Confirm';

const SmartPushForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationModules = useAppSelector(
    (store) => store.organization.modules,
  );
  const canShowSmartPush: boolean =
    organizationModules?.includes(AvailableOrganizationModules.smartPush) ??
    false;
  const { pushId } = useParams();
  const [pushStatus, setPushStatus] = useState<string>('');
  const [push, setPush] = useState<PushFields>({
    message: { value: null, error: { hasError: false, errorMessage: '' } },
    send_at: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
    send_at_time: {
      value: null,
      error: { hasError: false, errorMessage: '' },
    },
  });

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );
  const [segmentationId, setSegmentationId] = useState<string | null>(null);
  const [segmentationConfig, setSegmentationConfig] = useState(null);
  const [segmentationData, setSegmentationData] =
    useState<SegmentationSendProps>();
  const [currentSegmentation, setCurrentSegmentation] = useState<
    SegmentationValueProps[]
  >([]);
  const [segmentationLoading, setSegmentationLoading] = useState(true);
  const [, setSegmentationChangeControl] = useState<SegmentationSendProps>();
  const [segmentationTotalTarget, setSegmentationTotalTarget] = useState(0);

  const showErrorMessage = useCallback(
    (message: string) => {
      dispatch(
        showMessage({
          title: message,
          theme: 'danger',
          icon: 'close',
          time: 10000,
          customLeft: '0px',
        }),
      );
    },
    [dispatch],
  );

  const calculateTotalSegmentationTarget = useCallback(
    (data?: SegmentationSendProps) => {
      cauculateTotalSegmentation(organizationId, data)
        .then((response) => {
          const { count_accounts } = response.data;
          setSegmentationTotalTarget(count_accounts);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while calculating total targeted users.',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
              customLeft: '0px',
            }),
          );
        })
        .finally(() => {
          setSegmentationLoading(false);
        });
    },
    [t, dispatch, organizationId],
  );

  const loadSegmentationConfig = useCallback(
    (segmentations?: SegmentationSendProps[]) => {
      getSegmentationOptions(organizationId)
        .then((response) => {
          setSegmentationConfig(
            response.data.map((configItems: { url: string | null }) => ({
              ...configItems,
              url: configItems.url ? configItems.url.replace('/v2', '') : null,
            })),
          );

          if (segmentations?.length == 1) {
            const newSegmentationData = {
              id: segmentations[0].id,
              entries: (segmentations[0].entries as SegmentationValueProps[])
                .filter((item: SegmentationValueProps) => !!item.key)
                .map((item: SegmentationValueProps) => ({
                  id: item.id.includes(':') ? null : item.id,
                  key: item.key,
                  operator: item.operator,
                  type: item.type,
                  values: getSegmentationDataValues(item.type, item.values),
                })),
            };

            setCurrentSegmentation(
              segmentations[0].entries as SegmentationValueProps[],
            );
            setSegmentationData(newSegmentationData);
            setSegmentationChangeControl(newSegmentationData);
            calculateTotalSegmentationTarget(newSegmentationData);
            setSegmentationId(segmentations[0].id || null);
          } else {
            calculateTotalSegmentationTarget();
            setSegmentationId(null);
          }
        })
        .catch(() => {
          showErrorMessage(
            t('An unexpected error occurred while segmenting the content'),
          );
          setSegmentationLoading(false);
        });
    },
    [organizationId, showErrorMessage, t, calculateTotalSegmentationTarget],
  );

  const getSendAt = () => {
    if (push.send_at.value) {
      return dateAndTimeToApiDate(
        push.send_at.value,
        push.send_at_time?.value || undefined,
      );
    }

    return '';
  };

  const showSuccessMessage = (message: string) => {
    dispatch(
      showMessage({
        title: message,
        theme: 'success',
        icon: 'check',
        time: 3000,
        customLeft: '0px',
      }),
    );
  };

  const handleFieldErrors = (errors: RequestFieldError[]) => {
    errors.forEach((error) => {
      setPush((lastFields) => {
        const fieldContent = {
          ...lastFields[error.field as keyof PushFields],
        };

        return {
          ...lastFields,
          [error.field]: {
            ...fieldContent,
            error: {
              hasError: true,
              errorMessage: error.error,
            },
          },
        };
      });
    });
  };

  const postAPIDataFactory = () => {
    return {
      message: push.message.value || '',
      segmentations: segmentationData
        ? new Array<SegmentationSendProps>(segmentationData)
        : new Array<SegmentationSendProps>(),
      send_at: getSendAt(),
    };
  };

  const createPush = () => {
    dispatch(setPageLoading(true));

    const data: PushPostSendData = postAPIDataFactory();
    createSmartPush(organizationId, data)
      .then(() => {
        showSuccessMessage(t('The smart push has been published'));
        navigate('/smartpush');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while creating the alert'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const abortPush = () => {
    dispatch(setPageLoading(true));

    abortSmartPush(organizationId, pushId || '')
      .then(() => {
        showSuccessMessage(t('The smart push has been aborted'));
        navigate('/smartpush');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the alert'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const updatePush = () => {
    dispatch(setPageLoading(true));

    const data: PushPostSendData = postAPIDataFactory();

    updateSmartPush(organizationId, pushId || '', data)
      .then(() => {
        showSuccessMessage(t('The smart push has been updated'));
        navigate('/smartpush');
      })
      .catch((responseError) => {
        const { code, errors } = responseError.response.data;

        if (code === 'permission_denied') {
          showErrorMessage(
            t('You do not have permission to perform this action.'),
          );
          return;
        }

        if (code === 'invalid' || (code && code.indexOf('invalid') !== -1)) {
          showErrorMessage(errors[0]);
          return;
        }

        if (code === 'field_error') {
          handleFieldErrors(errors);
          return;
        }

        showErrorMessage(
          t('An unexpected error occurred while updating the alert'),
        );
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleSaveClick = () => {
    if (validatePush()) {
      if (pushId) {
        updatePush();
      } else {
        createPush();
      }
    }
  };

  const handleAbortClick = () => {
    dispatch(
      setModalView({
        show: true,
        width: '388px',
        content: (
          <Confirm
            title={t('Abort')}
            subtitle={t('Are you sure you want to abort this smart push?')}
            onConfirm={abortPush}
          />
        ),
      }),
    );
  };

  const validatePush = () => {
    const updatedPush = { ...push };
    let isValid = true;

    if (!push.message.value) {
      updatedPush.message.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPush.message.error = { hasError: false, errorMessage: '' };
    }

    if (!push.send_at.value) {
      updatedPush.send_at.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPush.send_at.error = { hasError: false, errorMessage: '' };
    }

    if (!push.send_at_time.value) {
      updatedPush.send_at_time.error = {
        hasError: true,
        errorMessage: t('This field is required'),
      };
      isValid = false;
    } else {
      updatedPush.send_at_time.error = { hasError: false, errorMessage: '' };
    }

    setPush(updatedPush);
    return isValid;
  };

  const showSegmentationModal = () => {
    dispatch(
      setModalView({
        show: true,
        width: '890px',
        content: (
          <ContentSegmentationModal
            title={t('Select the audience')}
            subtitle={t(
              'Apply segmentation criteria to define the audience that will be impacted by this content.',
            )}
            segmentationConfig={segmentationConfig}
            segmentationId={segmentationId}
            currentSegmentation={currentSegmentation}
            onSave={(data, segmentation) => {
              setSegmentationData(data);
              setCurrentSegmentation(segmentation);
              calculateTotalSegmentationTarget(data);
              setSegmentationLoading(true);
            }}
          />
        ),
        disableBackgroundClick: true,
      }),
    );
  };

  const getTimeAsStr = (date: Date) => {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const fetchData = useCallback(() => {
    if (pushId) {
      getSmartPush(organizationId, pushId)
        .then((response) => {
          setPush((currentPush) => ({
            ...currentPush,
            message: {
              value: response.data.message,
              error: { hasError: false, errorMessage: '' },
            },
            send_at: {
              value: new Date(response.data.send_at),
              error: { hasError: false, errorMessage: '' },
            },
            send_at_time: {
              value: getTimeAsStr(new Date(response.data.send_at)),
              error: { hasError: false, errorMessage: '' },
            },
          }));

          setPushStatus(response.data.status.key);
          loadSegmentationConfig(response.data.segmentations);
        })
        .catch(() => {
          navigate('/error-404');
        })
        .finally(() => {
          dispatch(setPageLoading(false));
        });
    } else {
      dispatch(setPageLoading(false));
      loadSegmentationConfig();
    }
  }, [dispatch, loadSegmentationConfig, navigate, organizationId, pushId]);

  useEffect(() => {
    if (!canShowSmartPush) {
      navigate('/');
      return;
    }

    fetchData();
  }, [dispatch, navigate, canShowSmartPush, fetchData]);

  return (
    <StyledSmartPushForm>
      <Header
        leftSideContent={
          <div className="left-side-content">
            <Text as="h3" weight="700" color="grayscale-400">
              {pushId ? t('Edit smart push') : t('New smart push')}
            </Text>
            <div className="actions">
              {pushId && pushStatus == 'scheduled' && (
                <Button
                  theme="link-danger"
                  className="abort-button"
                  size="small"
                  onClick={() => handleAbortClick()}
                >
                  {t('Abort')}
                </Button>
              )}
              <Button
                theme="link-gray-primary"
                size="small"
                onClick={() => navigate('/smartpush')}
              >
                {t('Cancel')}
              </Button>
              {(!pushId || ['scheduled'].includes(pushStatus)) && (
                <Button
                  theme="primary"
                  rounded="true"
                  onClick={() => handleSaveClick()}
                >
                  {t('Save')}
                </Button>
              )}
            </div>
          </div>
        }
      />
      <div className="content-config-container">
        <div className="content-side">
          <div className="content">
            <section>
              <Text as="h4" className="section-title">
                {t('General information')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <Text as="h6" color="grayscale-200">
                    {t('Send at')}*
                  </Text>
                  <div className="section-group">
                    <div className="form-group">
                      <DatePicker
                        id="startAt"
                        value={push.send_at.value || undefined}
                        icon="calendar-2-fill"
                        onChange={(date) =>
                          setPush({
                            ...push,
                            send_at: {
                              value: date,
                              error: { hasError: false, errorMessage: '' },
                            },
                          })
                        }
                        hasError={push.send_at.error.hasError}
                        errorMessage={push.send_at.error.errorMessage}
                      />
                      <Input
                        type="time"
                        theme="gray"
                        icon="clock-solid"
                        placeholder="HH:MM"
                        value={push.send_at_time.value?.toString() || ''}
                        onChange={(event) => {
                          const { value } = event.target;
                          setPush({
                            ...push,
                            send_at_time: {
                              value: value,
                              error: { hasError: false, errorMessage: '' },
                            },
                          });
                        }}
                        hasError={push.send_at_time.error.hasError}
                        errorMessage={push.send_at_time.error.errorMessage}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  theme="link-primary"
                  rightIcon={!segmentationLoading ? 'arrow-down-s-line' : ''}
                  onClick={showSegmentationModal}
                  disabled={segmentationLoading || !segmentationConfig}
                >
                  {!segmentationLoading &&
                    t('Target audience ({{total}})', {
                      total: abbreviateNumberFormatter(segmentationTotalTarget),
                    })}
                  {segmentationLoading && (
                    <Loading type="bubbles" color="grayscale-100" />
                  )}
                </Button>
              </div>
            </section>
            <section>
              <Text as="h4" className="section-title">
                {t('Message')}
              </Text>
              <Separator />
              <div className="section-contents">
                <div className="section-content">
                  <div className="section-group">
                    <Editor
                      className="text"
                      value={push.message.value?.toString() || undefined}
                      onChange={(value) => {
                        setPush({
                          ...push,
                          message: {
                            value: value,
                            error: { hasError: false, errorMessage: '' },
                          },
                        });
                      }}
                      pluginsOptions={[]}
                      toolbarOptions={[]}
                      quickbarsSelOptions={[]}
                      quickbarsInsOptions={''}
                      limit={200}
                      height={200}
                      characterCountType="focused"
                      hasError={push.message.error.hasError}
                      errorMessage={push.message.error.errorMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </StyledSmartPushForm>
  );
};

export default SmartPushForm;
