import styled from 'styled-components';

export const StyledStaffDashboardLayout = styled.div.attrs(() => ({
  className: 'dashboard-layout',
}))`
  width: 100%;
  height: 100%;

  display: flex;

  .menus {
    height: 100%;

    .menus-wrapper {
      display: flex;
      height: 100%;
      max-width: 312px;

      .menu {
        width: 260px;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .container {
      width: 100%;
      height: calc(100% - 72px);
      overflow-y: scroll;
    }
  }
`;

export default StyledStaffDashboardLayout;
