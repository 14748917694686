import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';

import { ClientStepProps } from './types';

import { StyledClientStep } from './styles';

const ClientStep: React.FC<ClientStepProps> = ({ name, setName, isUpdate }) => {
  const { t } = useTranslation();

  return (
    <StyledClientStep className="client-step">
      <Text as="h4" weight="bold" className="position-title">
        {isUpdate ? t('Update a position') : t('Create a position')}
      </Text>
      <Input
        value={name}
        onChange={(event) => setName(event.target.value)}
        limit={100}
        placeholder={t('Position')}
      />
    </StyledClientStep>
  );
};

export default ClientStep;
