import styled from 'styled-components';

export const StyledSchedulePreview = styled.div`
  width: 100%;

  .card {
    width: 100% !important;
    padding: 16px !important;

    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        font-size: 22px;
        min-width: 44px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        background: var(--warning-color);
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .more-icon {
        margin-bottom: 8px;
        .icon {
          font-size: 24px;
        }
      }

      .recurrence-item {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        align-items: space-between;

        .counters {
          width: 74px;

          .counter {
            font-size: 12px;
            font-weight: 700;
            margin-right: 8px;
            padding: 6px 8px;
          }
        }

        .values {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          .tag {
            font-size: 12px;
            font-family: monospace;
          }

          .tag:first-child {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .loading {
    margin-bottom: 42px;
    height: 220px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
