import styled from 'styled-components';

export const StyledDashboard = styled.div`
  height: 100%;

  .header {
    padding: 22px 54px;

    .left-side {
      width: 100%;
    }
    .text {
      margin-right: 8px;
    }
    .left-side-content {
      display: flex;
      align-items: center;
    }
  }

  .breadcrumb-container {
    padding: 24px 54px;

    border-bottom: 1px solid var(--grayscale-100);

    > i {
      font-size: 24px;
    }
  }

  .page-content {
    padding: 44px 54px;
    width: 100%;
    height: calc(100% - 72px);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .empty-message-wrapper {
      margin-top: 100px;

      & > .button {
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .empty-message {
      max-width: 300px;

      .description {
        margin-top: 12px;
        color: var(--dark-color);
      }
    }

    .react-tooltip {
      .text {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .drive-content {
      width: 100%;

      .drive-header {
        display: flex;
        gap: 16px;

        .input-content {
          padding-left: 0;
        }
      }

      .listing-folder + .file-table-wrapper {
        margin-top: 28px;
      }

      .recents-title {
        font-size: 14px;
        margin-top: 48px;
      }

      .recent-cards-wrapper {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        margin-bottom: 78px !important;

        .loading {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
`;
