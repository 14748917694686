import { configureStore } from '@reduxjs/toolkit';

import appLoading from './slices/appLoading';
import theme from './slices/theme';
import toaster from './slices/toaster';
import pageLoading from './slices/pageLoading';
import modal from './slices/modal';
import client from './slices/client';
import user from './slices/user';
import organization from './slices/organization';
import organizations from './slices/organizations';
import content from './slices/content';
import secondaryToaster from './slices/secondaryToaster';
import contentResults from './slices/contentResults';
import drive from './slices/drive';

import { uploadNextFile } from './slices/drive/thunks';

const store = configureStore({
  reducer: {
    appLoading,
    organization,
    theme,
    toaster,
    pageLoading,
    modal,
    client,
    user,
    organizations,
    content,
    secondaryToaster,
    contentResults,
    drive,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.subscribe(() => {
  const state = store.getState();
  if (
    state.drive.current_in_progress_file_id === '' &&
    !state.drive.uploaded_files.find((file) => file.status === 'pending')
  ) {
    store.dispatch(uploadNextFile());
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
