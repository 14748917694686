import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledContractList } from './styles';
import ContractsTable from './ContractsTable';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';

const ContractList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledContractList>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {t('Contracts')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                to="/staff/finance/contract/add"
                leftIcon="add-circle-fill"
              >
                {t('New contract')}
              </Button>
            </div>
          </div>
          <div className="content">
            <ContractsTable />
          </div>
        </div>
      </div>
    </StyledContractList>
  );
};

export default ContractList;
