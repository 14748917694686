import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import FilterItem from '../FilterItem';
import Button from 'components/molecule/Button';

import { CustomFilterAttrsTypes } from '../../types';

import { StyledFileTypeFilter } from './styles';

export interface FileTypeFilterProps {
  filter: CustomFilterAttrsTypes;
  setFilter: Dispatch<SetStateAction<CustomFilterAttrsTypes>>;
}

const FileTypeFilter: React.FC<FileTypeFilterProps> = ({
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();
  const totalFilterItems = 9;

  const [isOpen, setIsOpen] = useState(filter.types.length > 0);

  const handleClear = () => {
    const newFilter = { ...filter, types: [] };
    setFilter(newFilter);
  };

  const handleCheckAll = () => {
    const newFilter = {
      ...filter,
      types: [
        'is_folder',
        'pdf',
        'texts',
        'spreadsheets',
        'presentations',
        'audios',
        'videos',
        'images',
        'other_formats',
      ],
    };
    setFilter(newFilter);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  const handleToggleSelection = (select: boolean, item: string) => {
    const newTypes = [...filter.types];

    if (select) {
      newTypes.push(item);
    } else {
      newTypes.splice(newTypes.indexOf(item), 1);
    }

    setFilter({ ...filter, types: newTypes });
  };

  return (
    <StyledFileTypeFilter>
      <FilterItem
        title={t('Type')}
        isOpen={isOpen}
        onToggleIsOpen={toggleIsOpen}
        showClearButton={isOpen && filter.types.length > 0}
        onClear={handleClear}
        showCheckAllButton={isOpen && filter.types.length < totalFilterItems}
        onCheckAll={handleCheckAll}
      >
        <div className="button-list">
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.types.indexOf('is_folder') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('is_folder') === -1,
                'is_folder',
              )
            }
          >
            {t('Folders')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.types.indexOf('pdf') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(filter.types.indexOf('pdf') === -1, 'pdf')
            }
          >
            {t('PDF')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.types.indexOf('texts') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('texts') === -1,
                'texts',
              )
            }
          >
            {t('Texts')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.types.indexOf('spreadsheets') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('spreadsheets') === -1,
                'spreadsheets',
              )
            }
          >
            {t('Spreadsheets')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.types.indexOf('presentations') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('presentations') === -1,
                'presentations',
              )
            }
          >
            {t('Presentations')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.types.indexOf('audios') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('audios') === -1,
                'audios',
              )
            }
          >
            {t('Audios')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.types.indexOf('videos') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('videos') === -1,
                'videos',
              )
            }
          >
            {t('Videos')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={filter.types.indexOf('images') !== -1 ? 'selected' : ''}
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('images') === -1,
                'images',
              )
            }
          >
            {t('Images')}
          </Button>
          <Button
            theme="dark-outline"
            rounded="true"
            className={
              filter.types.indexOf('other_formats') !== -1 ? 'selected' : ''
            }
            onClick={() =>
              handleToggleSelection(
                filter.types.indexOf('other_formats') === -1,
                'other_formats',
              )
            }
          >
            {t('Other formats')}
          </Button>
        </div>
      </FilterItem>
    </StyledFileTypeFilter>
  );
};

export default FileTypeFilter;
