import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Text from 'components/atom/Text';
import Table from 'components/organism/Table';
import Tooltip from 'components/atom/Tooltip';

import { AlertConfigItem, AlertFilter } from './types';
import {
  ColumnProps,
  RowProps,
  SortableValueState,
  SearchableValue,
} from 'components/organism/Table/types';

import { StyledAlertsTable } from './styles';
import { getCSCXAlerts } from 'apis/staff';
import { useNavigate } from 'react-router-dom';
import Tag from 'components/atom/Tag';

const AlertsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tableRows, setTableRows] = useState<RowProps[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<AlertFilter>({
    search: '',
    title: '',
    order: '',
  });

  const getTableRows = useCallback(
    (data: AlertConfigItem[]) => {
      return data.map((item, index) => {
        return {
          onClick: () => navigate(`/staff/cscx/alert/${item.id}`),
          cells: [
            {
              content: (
                <Tooltip id={`title-${item.id}-${index}`} content={item.title}>
                  <Text color="grayscale-400">{item.title}</Text>
                </Tooltip>
              ),
            },
            {
              content: <Text color="grayscale-400">{item.section.value}</Text>,
            },
            {
              content: <Text color="grayscale-400">{item.target.value}</Text>,
            },
            {
              content: <Text color="grayscale-400">{item.mode.value}</Text>,
            },
            {
              content: (
                <Tag theme={item.is_active ? 'success-100' : 'danger-100'}>
                  {item.is_active ? 'Active' : 'Inactive'}
                </Tag>
              ),
            },
          ],
        };
      });
    },
    [navigate],
  );

  const loadAlerts = useCallback(
    (page: number, filter: AlertFilter) => {
      setTableLoading(true);

      getCSCXAlerts(page, filter)
        .then((response) => {
          const { num_pages, results } = response.data;
          setPageCount(num_pages);
          setCurrentPage(page);
          setTableRows(() => getTableRows(results));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.pathname = '/settings';
            dispatch(
              showMessage({
                title: t('You do not have permission to access this page'),
                theme: 'danger',
                icon: 'close',
                time: 10000,
              }),
            );
            return;
          }

          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading the data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, t, getTableRows],
  );

  const handleSearch = (values: SearchableValue[]) => {
    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        title: values.find((value) => value.key === 'title')?.value || '',
      };
      loadAlerts(1, newFilter);
      return newFilter;
    });
  };

  const handleOrder = (sortableValue: SortableValueState) => {
    const { value, key } = sortableValue;
    let newOrder = '';

    if (value === 1) {
      newOrder = key;
    }

    if (value === 0) {
      newOrder = '';
    }

    if (value === -1) {
      newOrder = `-${key}`;
    }

    setFilter((lastFilter) => {
      const newFilter = {
        ...lastFilter,
        order: newOrder,
      };
      loadAlerts(1, newFilter);
      return newFilter;
    });
  };

  const handleChangePage = (newPage: number) => {
    loadAlerts(newPage, filter);
  };

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      content: <Text color="grayscale-200">{t('Title')}</Text>,
      sortable: {
        key: 'title',
        sortableOnClick: (sortableValue) => handleOrder(sortableValue),
        value: 0,
      },
      searchable: {
        key: 'title',
        onSearch: (values) => handleSearch(values),
        placeholder: t('Search by title'),
        value: '',
      },
    },
    {
      content: <Text color="grayscale-200">{t('Section')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Target')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Style')}</Text>,
    },
    {
      content: <Text color="grayscale-200">{t('Status')}</Text>,
    },
  ]);

  useEffect(() => {
    loadAlerts(1, {
      search: '',
      title: '',
      order: '',
    });
  }, [loadAlerts]);

  return (
    <StyledAlertsTable className="alerts-table">
      <Table
        clickable={true}
        columns={columns}
        setColumns={setColumns}
        rows={tableRows}
        textAlign="start"
        fontColor="grayscale-200"
        hasPagination
        pageCount={pageCount}
        page={currentPage - 1}
        onChangePage={(page) => handleChangePage(page + 1)}
        loading={tableLoading}
        emptyMessageDescription={t(
          'If there are already alerts registered, change the words in the search bar and try again',
        )}
        scrollable
      />
    </StyledAlertsTable>
  );
};

export default AlertsTable;
