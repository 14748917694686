import { useCallback, useEffect } from 'react';

import { FLOWBOT_CHAT_WIDGET_URL } from 'settings';

import { useAppSelector } from 'store/hooks';

const useUricConfig = () => {
  const theme = useAppSelector((state) => state.organization.color_scheme);
  const flowBotConfig = useAppSelector(
    (state) => state.organization.module_flow_bot,
  );
  const slug = useAppSelector((state) => state.user.slug);
  const hasModuleFlowBot = useAppSelector((state) =>
    state.organization.modules?.includes('module_flowbot'),
  );

  const removeUric = () => {
    const flowBotContainer = document.querySelector('#flowbot-container');
    const flowBotScript = document.querySelector('#flowbot-script');
    const flowBotButton = document.querySelector('#flowbot-button');

    flowBotContainer?.remove();
    flowBotScript?.remove();
    flowBotButton?.remove();
  };

  const setUricScript = useCallback(
    (userSlug: string, appId: string, appKey: string) => {
      const flowBotContainer = document.querySelector('#flowbot-container');
      if (flowBotContainer) {
        removeUric();
      }

      const script: HTMLScriptElement = document.createElement('script');
      script.type = 'text/javascript';
      script.src = FLOWBOT_CHAT_WIDGET_URL;
      script.async = true;
      script.id = 'flowbot-script';

      script.setAttribute('data-flowbot-user', userSlug);
      script.setAttribute('data-flowbot-id', appId);
      script.setAttribute('data-flowbot-key', appKey);

      document.head.appendChild(script);
    },
    [],
  );

  const loadUric = useCallback(
    (userSlug: string, appId: string, appKey: string) => {
      setUricScript(userSlug, appId, appKey);
    },
    [setUricScript],
  );

  useEffect(() => {
    if (!hasModuleFlowBot) {
      removeUric();
      return;
    }

    if (!flowBotConfig) {
      removeUric();
    }

    if (slug && flowBotConfig) {
      loadUric(slug, flowBotConfig.app_id, flowBotConfig.api_key);
    }
  }, [theme, loadUric, slug, flowBotConfig, hasModuleFlowBot]);

  return null;
};

export default useUricConfig;
