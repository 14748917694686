import styled from 'styled-components';

export const StyledEditorialDetails = styled.div`
  width: 100%;

  .modal-content {
    margin: 0 0 42px 0;

    h4 {
      margin-bottom: 6px;
    }

    & > h6 {
      margin-bottom: 16px;
    }

    .input {
      margin-bottom: 16px;
      .input-content {
        padding-left: 0;
        input {
          font-size: 38px;
        }
      }
    }

    .default-check {
      display: flex;
      align-items: center;
      margin-top: 32px;

      h6 {
        margin-left: 12px;
      }
    }

    .icon-list-wrapper {
      width: 100%;
      overflow-y: scroll;
      max-height: 168px;

      .icon-list {
        width: 100%;
        height: max-content;
        display: flex;
        flex-wrap: wrap;

        .button {
          padding: 24px 16px;
          margin-right: 3px;
          margin-bottom: 8px;

          svg {
            width: 24px;
            height: 24px;
            path {
              fill: var(--grayscale-200);
            }
          }
        }

        .loading {
          margin: 0 auto;
        }
      }
    }

    .empty-message {
      margin: 16px auto;
      width: 100%;
    }
  }

  .modal-footer {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface StyledEditorialPreviewProps {
  color: string;
}

export const StyledEditorialPreview = styled.div<StyledEditorialPreviewProps>`
  width: 100%;
  border-radius: 12px;
  padding: 24px 32px;
  background: ${({ color }) => color};
  margin-bottom: 32px;

  .icon-wrapper {
    width: 56px;
    height: 56px;
    background: var(--white-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      path {
        fill: ${({ color }) => color};
      }
    }
  }
`;
