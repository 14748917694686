import React, { useState } from 'react';
import { StyledCardActions } from './styles';
import Dropdown from 'components/molecule/Dropdown';
import Icon from 'components/atom/Icon';
import { useTranslation } from 'react-i18next';
import Button from 'components/molecule/Button';

interface CardActionsProps {
  id: string;
  status: string;
  showFinalizeSurveyConfirmModal: (id: string) => void;
  showDuplicateSurveyConfirmModal: (id: string) => void;
}

const CardActions: React.FC<CardActionsProps> = ({
  id,
  status,
  showFinalizeSurveyConfirmModal,
  showDuplicateSurveyConfirmModal,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <StyledCardActions>
      {!['draft'].includes(status) && (
        <Dropdown
          open={open}
          setOpen={setOpen}
          toggleElement={<Icon name="more-2" />}
          toggleButtonTheme="link-page-button-gray-primary"
          dropdownContentColor="dark-color"
          contentPlace="right"
        >
          {!['draft', 'scheduled'].includes(status) && (
            <Button
              theme="dark"
              leftIcon="level"
              radius="0px"
              to={`/research/${id}/questions/`}
            >
              {t('Results')}
            </Button>
          )}

          {!['draft'].includes(status) && (
            <Button
              theme="dark"
              leftIcon="copy"
              radius="0px"
              onClick={() => showDuplicateSurveyConfirmModal(id)}
            >
              {t('Make a copy')}
            </Button>
          )}

          {['active'].includes(status) && (
            <Button
              theme="dark"
              leftIcon="check-double"
              radius="0px"
              onClick={() => showFinalizeSurveyConfirmModal(id)}
            >
              {t('Finalize')}
            </Button>
          )}
        </Dropdown>
      )}
    </StyledCardActions>
  );
};

export default CardActions;
