import styled from 'styled-components';

export const StyledConfigCard = styled.button`
  width: 360px;
  max-width: 360px;
  height: 200px;
  margin-right: 24px;
  margin-bottom: 32px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0px;

  &:disabled {
    cursor: initial;
  }

  .card {
    height: 200px;
    padding: 36px 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .icon {
      font-size: 26px;
    }

    h4 {
      margin-top: 32px;
      margin-bottom: 4px;
    }
  }
`;
