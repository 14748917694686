import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { abbreviateNumberFormatter } from 'utils/numbers';

import { getAccountProfilesDepartments } from 'apis/config';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showMessage } from 'store/slices/toaster';

import Loading from 'components/molecule/Loading';
import InfiniteScroll from 'components/atom/InfiniteScroll';
import EmptyMessage from 'components/molecule/EmptyMessage';
import Text from 'components/atom/Text';
import Input from 'components/molecule/Input';

import { HierarchyListProps, HierarchyItem } from './types';

import { StyledHierarchyList } from './styles';

const HierarchyList: React.FC<HierarchyListProps> = ({ type, id, name }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector((state) => state.organization.pk);

  const [items, setItems] = useState<HierarchyItem[]>([]);
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadingMoreLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState('');
  const [currentSearchTimeOut, setCurrentSearchTimeOut] =
    useState<ReturnType<typeof setTimeout>>();

  const loadItems = useCallback(
    (page: number, search: string) => {
      getAccountProfilesDepartments(organizationId, type, id, search, page)
        .then((response) => {
          setHasNextPage(!!response.data.next);
          setCurrentPage(page);
          setTotalRecords(response.data.recordsTotal);

          if (page > 1) {
            setItems((lastItems) => [...lastItems, ...response.data.results]);
            return;
          }

          setItems(response.data.results);
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMoreLoading(false);
        });
    },
    [dispatch, t, id, organizationId, type],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSearchTimeOut) {
      clearTimeout(currentSearchTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      loadItems(1, value);
    }, 1000);

    setCurrentSearchTimeOut(timeOut);
    setSearch(value);
  };

  const getDescription = () => {
    if (type === 'department') {
      return t('List of all departments in which {{name}} participates', {
        name,
      });
    }

    return t('List of all job positions held by {{name}}', {
      name,
    });
  };

  useEffect(() => {
    setLoading(true);
    loadItems(1, '');
  }, [loadItems]);

  return (
    <StyledHierarchyList>
      <Text as="h5" weight="700">
        {type === 'profile' ? t('Positions') : t('Departments')}
      </Text>
      <Text as="p" align="start">
        {getDescription()}
      </Text>

      <div className="search-wrapper">
        <Input
          icon="search"
          placeholder={t('Search by name')}
          value={search}
          onChange={handleSearch}
        />
        <Text className="counter" color="grayscale-300" weight="700">
          {abbreviateNumberFormatter(totalRecords)}{' '}
          {t('result', { count: totalRecords })}
        </Text>
      </div>

      <div
        className="list default-scroll"
        ref={(currentRef) => setScrollElement(currentRef)}
      >
        {items.map((item, index) => (
          <div className="item" key={index}>
            <Text>{item.name}</Text>
          </div>
        ))}

        {loadMoreLoading && <Loading type="bubbles" />}
      </div>

      {loading && <Loading type="bubbles" />}

      {!loading && items.length === 0 && (
        <EmptyMessage
          title={
            type === 'department'
              ? t('No department found')
              : t('No position found')
          }
          icon="close-circle-line"
        />
      )}

      <InfiniteScroll
        scrollElement={scrollElement}
        fetchMore={() => {
          setLoadingMoreLoading(true);
          loadItems(currentPage + 1, search);
        }}
        disabled={loading || loadMoreLoading || !hasNextPage}
      />
    </StyledHierarchyList>
  );
};

export default HierarchyList;
