export const downloadFile = async (
  fileUrl: string,
  name: string,
): Promise<void> => {
  try {
    const response = await fetch(fileUrl);

    if (!response.ok) {
      throw new Error('Erro ao baixar o arquivo.');
    }

    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    throw new Error(
      'Erro ao baixar o arquivo. Verifique sua conexão e tente novamente.',
    );
  }
};
