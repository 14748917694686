import styled from 'styled-components';

export const StyledFormSteps = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: max-content;
    padding: 12px 20px;
    border-radius: 10px;
    transition: background 0.2s;

    .text {
      margin-top: 2px;
    }

    .tag {
      margin-right: 8px;
    }

    & > .text {
      margin-right: 14px;
    }

    &.active {
      background-color: var(--grayscale-100);
    }
  }
`;
