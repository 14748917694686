import { getI18NextLanguage } from 'services/language';

const getNumberFormatLocale = (definedLanguage?: string): string[] => {
  return [definedLanguage || getI18NextLanguage() || 'en-US', 'en-US'];
};

export const numberFormatter = (value: number, language?: string): string => {
  return Intl.NumberFormat(getNumberFormatLocale(language)).format(value);
};

export const currencyNumberFormatter = (
  value: number,
  currency: string,
  language?: string,
): string => {
  // currency param: https://www.currency-iso.org/en/home/tables/table-a1.html
  return Intl.NumberFormat(getNumberFormatLocale(language), {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  }).format(value);
};

export const abbreviateNumberFormatter = (
  value: number,
  language?: string,
): string => {
  return Intl.NumberFormat(getNumberFormatLocale(language), {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);
};

export const preventNonNumericInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  const allowedCharacters = [
    '-',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
  ];
  if (!allowedCharacters.includes(event.key.toString())) {
    event.preventDefault();
  }
};

export const enforceIntegerAndDecimalLimit = (
  value: string,
  integerLimit: number,
  decimalLimit: number,
) => {
  const valueArray = value.split('.');
  if (!valueArray[0]) {
    return '';
  }
  const part1 = parseInt(valueArray[0]).toString();
  const part2 = valueArray[1];

  let result = '';

  if (part1) {
    if (part2 && part2.length > 0) {
      result =
        part1.substring(0, Math.min(part1.length, integerLimit)) +
        '.' +
        part2.substring(0, Math.min(part2.length, decimalLimit));
    } else {
      result = part1.substring(0, Math.min(part1.length, integerLimit));
    }
  }
  return result;
};
