import React, { useEffect, useState } from 'react';

import Table from 'components/organism/Table';

import FilesTableInfo from 'pages/drive/DriveManager/components/FilesTable/components/FilesTableInfo';
import FilesTableShared from 'pages/drive/DriveManager/components/FilesTable/components/FilesTableShared';
import NavigationTableActions from './components/NavigationTableActions';
import FilesTableCreatedBy from 'pages/drive/DriveManager/components/FilesTable/components/FilesTableCreatedBy';

import { ColumnProps, RowProps } from 'components/organism/Table/types';

import { FileContentProps } from 'pages/drive/DriveManager/components/FilesTable/types';
import { NavigationTableProps } from './types';

import { StyledNavigationTable } from './styles';
import { useTranslation } from 'react-i18next';

const generateFileTableRows = (
  data: FileContentProps[],
  fetchFolders: (parentFolderId: string | null, page: number) => void,
) => {
  return data.map((content, index) => {
    const { id, extension, name, owner, is_shared, is_folder } = content;

    return {
      onClick: () => fetchFolders(id ?? null, 1),
      cells: [
        {
          content: (
            <FilesTableInfo
              id={id}
              index={index}
              name={name}
              type={is_folder ? 'folder' : extension}
            />
          ),
        },
        {
          content: <FilesTableCreatedBy index={index} owner={owner} />,
        },
        {
          content: <FilesTableShared isShared={is_shared} />,
        },
        {
          content: <NavigationTableActions />,
        },
      ],
    };
  });
};

const NavigationTable: React.FC<NavigationTableProps> = ({
  folders,
  fetchFolders,
  loading,
  pageCount,
  onChangePage,
  page,
  newFolderFieldIsVisible,
}) => {
  const { t } = useTranslation();

  const [fileTableRows, setFileTableRows] = useState<RowProps[]>([]);

  const [columns, setColumns] = useState<ColumnProps[]>([]);

  useEffect(() => {
    if (folders) {
      setFileTableRows(() => generateFileTableRows(folders, fetchFolders));
    }
  }, [folders, fetchFolders]);

  return (
    <StyledNavigationTable>
      <Table
        columns={columns}
        setColumns={setColumns}
        rows={fileTableRows}
        scrollable={false}
        textAlign="start"
        fontColor="grayscale-200"
        loading={loading}
        clickable
        hasPagination={!newFolderFieldIsVisible}
        pageCount={pageCount}
        onChangePage={onChangePage}
        page={page}
        emptyMessageTitle={t('No folder')}
        emptyMessageDescription={t(
          'Create folders and manage their distribution to workforce.',
        )}
        hideEmptyMessage={newFolderFieldIsVisible}
      />
    </StyledNavigationTable>
  );
};

export default NavigationTable;
