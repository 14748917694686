import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';

import { ExportConfirmProps } from './types';

import { StyledExportConfirm } from './styles';

const ExportConfirm: React.FC<ExportConfirmProps> = ({
  order,
  onExport,
  userEmail,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleExport = () => {
    onExport();
    closeModal();
  };

  return (
    <StyledExportConfirm>
      <div className="modal-content">
        <Text as="h5" weight="700">
          {t('Review the export request')}
        </Text>
        <Text as="p">
          {t(
            'A report will be generated containing the history of content edits in {{order}} date order',
            {
              order: order === 'created_at' ? t('ascending') : t('descending'),
            },
          )}
        </Text>
        <Text>
          {t('Export of edit history will be sent to {{email}}', {
            email: userEmail,
          })}
        </Text>
      </div>

      <div className="modal-footer">
        <Button theme="link-dark-gray" onClick={closeModal}>
          {t('Cancel')}
        </Button>
        <Button theme="dark" onClick={handleExport}>
          {t('Export')}
        </Button>
      </div>
    </StyledExportConfirm>
  );
};

export default ExportConfirm;
