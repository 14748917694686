/* eslint-disable */
export const UseflowIcons = {
  '': '',
  'arrow-down-line': '\\e900',
  'arrow-down-s-line': '\\e901',
  'arrow-left-line': '\\e902',
  'arrow-left-right-line': '\\e903',
  'arrow-left-s-line': '\\e904',
  'arrow-right-line': '\\e905',
  'arrow-right-s-line': '\\e906',
  'arrow-top-line': '\\e907',
  'arrow-up-line': '\\e908',
  'arrow-up-s-line': '\\e909',
  'code-s-line': '\\e90a',
  'code-s-slash-line': '\\e90b',
  drop: '\\e90c',
  'drop-double': '\\e90d',
  'left-solid-arrow': '\\e90e',
  'chat-4-fill': '\\e90f',
  'chat-4-line': '\\e910',
  'message-2-fill': '\\e911',
  'message-2-line': '\\e912',
  'question-answer-fill': '\\e913',
  'question-answer-line': '\\e914',
  'add-circle-fill': '\\e915',
  'add-circle-line': '\\e916',
  'checkbox-circle-fill': '\\e917',
  'checkbox-circle-line': '\\e918',
  'close-circle-fill': '\\e919',
  'close-circle-line': '\\e91a',
  'error-warning-fill': '\\e91b',
  'error-warning-line': '\\e91c',
  'indeterminate-circle-fill': '\\e91d',
  'indeterminate-circle-line': '\\e91e',
  'information-fill': '\\e91f',
  'information-line': '\\e920',
  'question-fill': '\\e921',
  'question-line': '\\e922',
  'shield-fill': '\\e923',
  'shield-line': '\\e924',
  'star-fill': '\\e925',
  'star-half-line': '\\e926',
  'star-line': '\\e927',
  action: '\\e928',
  add: '\\e929',
  'add-outline': '\\e92a',
  'add-solid': '\\e92b',
  alert: '\\e92c',
  'alert-fill': '\\e92d',
  at: '\\e92e',
  'book-open-fill': '\\e92f',
  'book-open-line': '\\e930',
  'build-1': '\\e931',
  'build-2': '\\e932',
  'build-3': '\\e933',
  bullets: '\\e934',
  'calendar-2-fill': '\\e935',
  'calendar-2-line': '\\e936',
  camera: '\\e937',
  card: '\\e938',
  check: '\\e939',
  'check-double': '\\e93a',
  circle: '\\e93b',
  'clock-outline': '\\e93c',
  'clock-solid': '\\e93d',
  close: '\\e93e',
  copy: '\\e93f',
  'copy-outline': '\\e940',
  'crop-cut': '\\e941',
  'crop-outline': '\\e942',
  'dashboard-fill': '\\e943',
  'dashboard-line': '\\e944',
  'delete-bin-6-fill': '\\e945',
  'delete-bin-6-line': '\\e946',
  doc: '\\e947',
  'doc-file': '\\e948',
  'doc-fill': '\\e949',
  download: '\\e94a',
  'download-1': '\\e94b',
  drag: '\\e94c',
  drag2: '\\e94d',
  'earth-fill': '\\e94e',
  'earth-line': '\\e94f',
  'edit-fill': '\\e950',
  'edit-line': '\\e951',
  'equalizer-fill': '\\e952',
  'equalizer-line': '\\e953',
  'external-link': '\\e954',
  'eye-fill': '\\e955',
  'eye-line': '\\e956',
  'eye-off-fill': '\\e957',
  'eye-off-line': '\\e958',
  'file-edit-fill': '\\e959',
  'file-edit-line': '\\e95a',
  'file-strange': '\\ea17',
  'filter-order': '\\e95b',
  flag: '\\e95c',
  'flag-2-fill': '\\e95d',
  'flag-2-line': '\\e95e',
  'flag-outline': '\\e95f',
  focus: '\\e960',
  'folder-fill': '\\e961',
  'folder-line': '\\e962',
  form: '\\e963',
  fullscreen: '\\e964',
  'git-merge-fill': '\\e965',
  'git-merge-line': '\\e966',
  'group-fill': '\\e967',
  'group-line': '\\e968',
  'guide-fill': '\\e969',
  'guide-line': '\\e96a',
  hastag: '\\e96b',
  history: '\\e96c',
  'home-6-fill': '\\e96d',
  'home-6-line': '\\e96e',
  idea: '\\e96f',
  image: '\\e970',
  'image-fill': '\\e971',
  'image-line': '\\e972',
  label: '\\e973',
  level: '\\e974',
  link: '\\e975',
  loader: '\\e976',
  'lock-fill': '\\e977',
  'lock-fill-2': '\\e978',
  'lock-line': '\\e979',
  'lock-line-2': '\\e97a',
  'lock-line-2-1': '\\e97b',
  'lock-open-2': '\\e97c',
  logout: '\\e97d',
  'mail-add-line-1': '\\e97e',
  'mail-fill': '\\e97f',
  'mail-line': '\\e980',
  'mail-unread-line': '\\e981',
  'map-pin-2-fill': '\\e982',
  'map-pin-2-line': '\\e983',
  mastercard: '\\e984',
  menu: '\\e985',
  'menu-2': '\\e986',
  'mic-fill': '\\e987',
  'mic-line': '\\e988',
  'money-dollar-circle-fill': '\\e989',
  'money-dollar-circle-line': '\\e98a',
  more: '\\e98b',
  'more-2': '\\e98c',
  'notification-3-fill-1': '\\e98d',
  'notification-3-line-1': '\\e98e',
  'out-system': '\\e98f',
  'palette-fill': '\\e990',
  'palette-line': '\\e991',
  pause: '\\e992',
  pdf: '\\e993',
  'pencil-fill': '\\e994',
  'pencil-line': '\\e995',
  'pin-place': '\\e996',
  'pin-side': '\\e997',
  'pin-ver': '\\e998',
  'plant-line': '\\e999',
  play: '\\e99a',
  play_circle: '\\e99b',
  'play-outline': '\\e99c',
  'qr-code': '\\e99d',
  'qr-code-fill': '\\e99e',
  'question-mark': '\\e99f',
  'radio-button-line-1': '\\e9a0',
  'route-fill': '\\e9a1',
  'route-fill-1': '\\e9a2',
  'route-line': '\\e9a3',
  search: '\\e9a4',
  'search-fill': '\\e9a5',
  send: '\\e9a6',
  'settings-4-fill': '\\e9a7',
  'settings-4-line': '\\e9a8',
  'share-fill': '\\e9a9',
  'share-line': '\\e9aa',
  'shut-down-fill': '\\e9ab',
  'shut-down-line': '\\e9ac',
  sound: '\\e9ad',
  'spam-2-fill': '\\e9ae',
  'spam-2-fill-1': '\\e9af',
  'spam-3-fill': '\\e9b0',
  'spam-3-fill-1': '\\e9b1',
  'stack-fill': '\\e9b2',
  'stack-line': '\\e9b3',
  table: '\\e9b4',
  'text-expand': '\\e9b5',
  'thumb-down-fill': '\\e9b6',
  'thumb-down-line': '\\e9b7',
  'ticket-outline': '\\e9b8',
  'ticket-solid': '\\e9b9',
  'time-fill': '\\e9ba',
  'time-line': '\\e9bb',
  'title-major': '\\e9bc',
  'title-minor': '\\e9bd',
  'tools-fill': '\\e9be',
  'troph-outline': '\\e9bf',
  'troph-solid': '\\e9c0',
  unsplash: '\\e9c1',
  upload: '\\e9c2',
  upload2: '\\e9c3',
  'upload-cloud': '\\e9c4',
  'user-fill': '\\e9c5',
  'user-line': '\\e9c6',
  'u-cross': '\\e9c7',
  'video-fill': '\\e9c8',
  'video-line': '\\e9c9',
  'slide': '\\ea18',
  visa: '\\e9ca',
  'zoom-in-map': '\\e9cb',
  'zoom-out-map': '\\e9cc',
  educator: '\\e9cd',
  'forward-10sec': '\\e9ce',
  less: '\\e9cf',
  'replay-10sec': '\\e9d0',
  replay: '\\e9d1',
  'skip-next': '\\e9d2',
  'skip-previous': '\\e9d3',
  'unfold-less': '\\e9d4',
  'unfold-more': '\\e9d5',
  'emotion-fill': '\\e9d7',
  'emotion-happy-fill': '\\e9d8',
  'emotion-happy-line': '\\e9d9',
  'emotion-laugh-fill': '\\e9da',
  'emotion-laugh-line': '\\e9db',
  'emotion-line': '\\e9dc',
  'emotion-normal-fill': '\\e9dd',
  'emotion-normal-line': '\\e9de',
  'emotion-sad-fill': '\\e9df',
  'emotion-sad-line': '\\e9e0',
  'emotion-unhappy-fill': '\\e9e1',
  'emotion-unhappy-line': '\\e9e2',
  'heart-fill': '\\e9e3',
  'heart-line': '\\e9e4',
  'thumb-up-fill': '\\e9e5',
  'thumb-up-line': '\\e9e6',
  'facebook-circle-fill': '\\e9e7',
  'facebook-circle-line': '\\e9e8',
  'instagram-fill': '\\e9e9',
  'instagram-line': '\\e9ea',
  'linkedin-fill': '\\e9eb',
  'linkedin-line': '\\e9ec',
  'twitter-fill': '\\e9ed',
  'twitter-line': '\\e9ee',
  'whatsapp-fill': '\\e9ef',
  'whatsapp-line': '\\e9f0',
  'youtube-fill': '\\e9f1',
  'youtube-line': '\\e9f2',
  'checkbox-blank-circle-line': '\\e9f3',
  'checkbox-blank-line': '\\e9f4',
  'checkbox-fill': '\\e9f5',
  'checkbox-indeterminate-line': '\\e9f6',
  'checkbox-line': '\\e9f7',
  'radio-button-line': '\\e9f8',
  'toggle-fill': '\\e9f9',
  'toggle-line': '\\e9fa',
  logo: '\\e9d6',
  'chat-pool': '\\e9fb',
  'left-drop': '\\e9fc',
  'top-solid-arrow': '\\e9fd',
  'box-play': '\\e9fe',
  'chat-dot': '\\e9ff',
  'coin-outline': '\\ea00',
  'coin-solid': '\\ea01',
  'collage': '\\ea02',
  'double-coin': '\\ea03',
  'excel': '\\ea04',
  'file': '\\ea05',
  'flag-wind': '\\ea06',
  'gift': '\\ea07',
  'grid': '\\ea08',
  'hospital': '\\ea09',
  'list': '\\ea0a',
  'people': '\\ea0b',
  'phone-outline': '\\ea0c',
  'phone-solid': '\\ea0d',
  'plant': '\\ea0e',
  'shop-bag': '\\ea0f',
  'shufle': '\\ea10',
  'sip': '\\ea11',
  'smile': '\\ea12',
  'store': '\\ea13',
  'uric-coins': '\\ea14',
  'vote-pool': '\\ea15',
  'number': '\\ea16',
  'sort-right': '\\ea19',
  'shield-person': '\\ea1a'
};
