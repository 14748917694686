import React from 'react';
import Button from 'components/molecule/Button';
import { SingleValue } from 'react-select';

import {
  SegmentationOptionProps,
  SegmentationOperatorProps,
  SegmentationSettingProps,
  SegmentationValueProps,
  SegmentationSettingsType,
  SegmentationEntityContent,
} from '../../types';
import { SelectOption } from 'components/molecule/Select/types';

export const operatorOptionsFactory = (
  operators: SegmentationOperatorProps[],
) => {
  return operators.map((item: SegmentationOperatorProps) => ({
    value: item.key,
    label: (
      <Button theme="link-gray-primary" size="small">
        {item.title}
      </Button>
    ),
    many: item.many,
  }));
};

export const getSelectedEntity = (
  entities: SegmentationSettingProps[],
  segmentationItem: SegmentationValueProps,
) => {
  const entityConfig = entities.find(
    (item) => item.key === segmentationItem.key,
  );

  if (!entityConfig) return null;

  return {
    value: entityConfig.key,
    label: (
      <Button theme="link-white" size="small">
        {entityConfig.title}
      </Button>
    ),
    group: entityConfig.group,
  };
};

export const getSelectedOperator = (
  entities: SegmentationSettingProps[],
  segmentationItem: SegmentationValueProps,
) => {
  const entityConfig = entities.find(
    (item) => item.key === segmentationItem.key,
  );

  if (!entityConfig || !entityConfig.operators) return null;

  const operatorConfig = entityConfig.operators.find(
    (item) => item.key === segmentationItem.operator,
  );

  if (!operatorConfig) return null;

  return {
    value: operatorConfig.key,
    label: (
      <Button theme="link-gray-primary" size="small">
        {operatorConfig.title}
      </Button>
    ),
    many: operatorConfig.many,
  };
};

export const segmentationValueOptionsFactory = (
  options: SegmentationOptionProps[],
) => {
  return options.map((item: SegmentationOptionProps) => ({
    value: `${item.value}__${item.key}`,
    label: (
      <Button theme="link-gray-primary" size="small">
        {item.value}
      </Button>
    ),
  }));
};

export const convertToValueOption = (values: SegmentationOptionProps[]) => {
  return values.map((item: SegmentationOptionProps) => ({
    value: `${item.value}__${item.key}`,
    label: (
      <Button theme="link-gray-primary" size="small">
        {item.value}
      </Button>
    ),
  }));
};

export const convertOptionToSegmentationOption = (
  option: SingleValue<SelectOption>,
) => {
  if (!option) {
    return {
      key: '',
      value: '',
    };
  }

  return {
    key: option.value.split('__')[1],
    value: option.value.split('__')[0],
  };
};

export const getAllConfig = (config: SegmentationSettingsType) => {
  const normalConfig = config.filter((entity) => entity.key !== 'group');
  const groupConfig = config.filter((entity) => entity.key === 'group');
  const groupConfigContents: SegmentationEntityContent[] = [];
  groupConfig.forEach((entity) => {
    if (entity.contents) {
      const contentWithGroupName = entity.contents.map((item) => {
        const newItem = { ...item };
        newItem.group = entity.group;
        return newItem;
      });
      groupConfigContents.push(...contentWithGroupName);
    }
  });
  return [...normalConfig, ...groupConfigContents];
};
