import styled from 'styled-components';

export const StyledComment = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 32px;
  margin-bottom: 10px;

  .avatar {
    min-width: 32px;
    margin-right: 10px;
  }

  .comment-content {
    .owner-name {
      margin-bottom: 8px;
    }

    & > pre {
      max-width: 100%;
      font-size: 14px;
      margin-bottom: 6px;
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }

    .removed {
      text-decoration: line-through;
    }

    .dates {
      display: flex;
      align-items: center;
      small {
        margin-right: 12px;
      }
      .created-at {
        min-width: 98px;
      }

      .tag {
        font-size: 12px;
        text-align: center;
      }
    }

    .actions {
      display: flex;
      .button {
        padding: 0px;
        margin-right: 16px;
      }
    }
  }
`;
