import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { cn } from 'utils/cn';
import { abbreviateNumberFormatter } from 'utils/numbers';

interface FastFilterItemProps {
  name: string;
  color: string;
  qtdResearch: number;
  currentStatus: string;
  status: string;
  onClick: () => void;
}

const FastFilterItem: React.FC<FastFilterItemProps> = ({
  name,
  color,
  qtdResearch,
  currentStatus,
  status,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(currentStatus === status);

  useEffect(() => {
    setIsActive(currentStatus === status);
  }, [currentStatus, status]);

  return (
    <div
      className={cn('w-[304px] h-36 cursor-pointer rounded-xl', {
        'p-2 border-2 border-solid border-grayscale-200': isActive,
      })}
      onClick={() => {
        onClick();
        setIsActive((currentActive) => !currentActive);
      }}
    >
      <div
        className={twMerge([
          'w-full h-full flex gap-[10px] items-center justify-center',
          'rounded-xl shadow-[0px_4px_100px_0px_rgba(0,0,0,0.1)]',
          'text-dark-color p-3',
        ])}
      >
        <div
          className={twMerge([
            'w-20 h-20 flex items-center justify-center rounded-full',
            'text-[28px] text-dark-color',
          ])}
          style={{ backgroundColor: color }}
        >
          <span className="font-bold">
            {abbreviateNumberFormatter(qtdResearch, 'en')}
          </span>
        </div>
        <div className="text-base text-dark-color">
          <span className="font-bold">{name}</span>
        </div>
      </div>
    </div>
  );
};

export default FastFilterItem;
