import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getSchedulePreview } from 'apis/survey';

import { useAppSelector } from 'store/hooks';

import { dateAndTimeToApiDate } from 'utils/date';

import Card from 'components/atom/Card';
import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Loading from 'components/molecule/Loading';
import Tag from 'components/atom/Tag';

import { SchedulerStateProps } from '../PublicationDateModal/components/RecurrenceConfig/types';

import { StyledSchedulePreview } from './styles';

interface SchedulePreviewProps {
  schedulerState: SchedulerStateProps;
}

interface RecurrenceItemProps {
  key: string;
  dates: string;
  times: string;
}

const SchedulePreview: React.FC<SchedulePreviewProps> = ({
  schedulerState,
}) => {
  const { t } = useTranslation();
  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const [previewItems, setPreviewItems] = useState<RecurrenceItemProps[]>([]);
  const [lastPreviewItem, setLastPreviewItem] =
    useState<RecurrenceItemProps | null>(null);
  const [loading, setLoading] = useState(true);

  const loadPreview = useCallback(() => {
    setLoading(true);

    let startAt = '';
    if (schedulerState.scheduler_start_date_at.value) {
      startAt = dateAndTimeToApiDate(
        schedulerState.scheduler_start_date_at.value,
      ).split('T')[0];
    }

    let finishAt = '';
    if (schedulerState.scheduler_finish_date_at.value) {
      finishAt = dateAndTimeToApiDate(
        schedulerState.scheduler_finish_date_at.value,
      ).split('T')[0];
    }

    const params = {
      scheduler_start_date_at: startAt,
      scheduler_finish_date_at: finishAt,
      content_start_time_at: schedulerState.content_start_time_at.value
        ? schedulerState.content_start_time_at.value.toString().substring(0, 5)
        : '',
      content_finish_time_at: schedulerState.content_finish_time_at.value
        ? schedulerState.content_finish_time_at.value.toString().substring(0, 5)
        : '',
      content_duration: schedulerState.content_duration.value,
      content_week_days: schedulerState.content_week_days.value.toString(),
      scheduler_var: schedulerState.scheduler_var.value,
      scheduler_type: schedulerState.scheduler_type.value,
    };

    getSchedulePreview(organizationId, params)
      .then((response) => {
        const { data } = response;

        if (
          data.length > 1 &&
          data[data.length - 1].key - data[data.length - 2].key > 1
        ) {
          const [lastItem] = response.data.splice(response.data.length - 1, 1);
          setPreviewItems(response.data);
          lastItem.key = parseInt(lastItem.key) > 999 ? '999+' : lastItem.key;
          setLastPreviewItem(lastItem);
          return;
        }

        setPreviewItems(response.data);
        setLastPreviewItem(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organizationId, schedulerState]);

  useEffect(() => {
    loadPreview();
  }, [schedulerState, loadPreview]);

  return (
    <StyledSchedulePreview className="scheduler-preview">
      {!loading && previewItems.length !== 0 && (
        <Card>
          <div className="card-header">
            <Icon name="idea" />
            <Text as="h6" weight="700">
              {t('See how publication occurrences will look')}
            </Text>
          </div>
          <div className="card-content">
            {previewItems.map((item, index) => (
              <div className="recurrence-item" key={index}>
                <div className="counters">
                  <Tag className="counter" theme="dark">
                    {item.key}
                  </Tag>
                </div>
                <div className="values">
                  <Tag>{item.dates}</Tag>
                  <Tag>{item.times}</Tag>
                </div>
              </div>
            ))}
            {lastPreviewItem && (
              <>
                <div className="more-icon">
                  <Icon name="more" color="grayscale-200" />
                </div>
                <div className="recurrence-item">
                  <div className="counters">
                    <Tag className="counter" theme="dark">
                      {lastPreviewItem.key}
                    </Tag>
                  </div>
                  <div className="values">
                    <Tag>{lastPreviewItem.dates}</Tag>
                    <Tag>{lastPreviewItem.times}</Tag>
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
      )}

      {loading && <Loading />}
    </StyledSchedulePreview>
  );
};

export default SchedulePreview;
