import React, { Dispatch, SetStateAction } from 'react';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Input from 'components/molecule/Input';
import Tooltip from 'components/atom/Tooltip';
import Select from 'components/molecule/Select';
import Button from 'components/molecule/Button';
import Loading from 'components/molecule/Loading';
import Separator from 'components/atom/Separator';
import { SingleSelectedOption } from 'components/molecule/Select/types';

import { useOrganization } from './useOrganization';

import { StyledOrganizationConfig } from './styles';

interface OrganizationProps {
  setSaveLoading: Dispatch<SetStateAction<boolean>>;
}

const OrganizationConfig: React.FC<OrganizationProps> = ({
  setSaveLoading,
}) => {
  const {
    t,
    loading,
    fieldErrors,
    handleSubmit,
    countryOptions,
    timezoneOptions,
    countrySelectOption,
    handleChangeCountry,
    timezoneSelectOption,
    handleChangeTimezone,
    newOrganizationName,
    handleChangeOrganizationName,
    currentOrganizationInfo,
    customInformation: CustomInformation,
  } = useOrganization({ setSaveLoading });

  const isUseflowClient =
    currentOrganizationInfo?.client_name.toLocaleLowerCase() === 'useflow';

  return (
    <>
      <Button
        theme="primary"
        rounded="true"
        className="!absolute right-0 -top-[59px]"
        onClick={handleSubmit}
      >
        {t('Save')}
      </Button>
      <StyledOrganizationConfig>
        <Text as="h3" weight="700">
          {t('Organization')}
        </Text>
        <Text as="h5" className="!m-0">
          {t('About the organization')}
        </Text>
        {!loading && (
          <>
            <div className="mt-8 w-full">
              <Input
                id="name"
                label={t('Name of the organization')}
                placeholder={t('Happy Time')}
                limit={120}
                value={newOrganizationName}
                onChange={(event) =>
                  handleChangeOrganizationName(event.target.value)
                }
                hasError={fieldErrors.name.hasError}
                errorMessage={fieldErrors.name.errorMessage}
              />
            </div>

            <Separator />

            <Text as="h5" className="!m-0">
              {t('Holding company')}
            </Text>
            <div className="mt-8 w-full flex flex-col gap-8">
              <Input
                id="official-name"
                label={t(
                  'Official name, as written in the companys documentation',
                )}
                placeholder={t('Useflow technologies ltda')}
                icon="build-2"
                value={currentOrganizationInfo?.client_name}
                disabled
              />
              <Tooltip
                id="tooltip-holding-company-2"
                content={<CustomInformation />}
                place="right"
                className="tooltip-holding-company"
              >
                <Input
                  id="nickname"
                  label={t('Company nickname')}
                  placeholder={t('Flow')}
                  actions={<Icon name="error-warning-line" />}
                  icon="home-6-line"
                  value={currentOrganizationInfo?.client_slug}
                  disabled
                />
              </Tooltip>
              <div>
                <Text className="mb-[6px]" as="pre" color="grayscale-200">
                  {t('Location')}
                </Text>
                <Select
                  theme={isUseflowClient ? 'gray' : 'default'}
                  value={countrySelectOption}
                  setValue={(option) =>
                    handleChangeCountry(option as SingleSelectedOption)
                  }
                  options={countryOptions}
                  placeholder={
                    <div>
                      <Text color="grayscale-200">{t('Select...')}</Text>
                    </div>
                  }
                  isSearchable
                  hasError={fieldErrors.country.hasError}
                  errorMessage={fieldErrors.country.errorMessage}
                  isDisabled={isUseflowClient}
                />
              </div>
            </div>

            <Separator />

            <Text as="h5" className="!m-0">
              {t('Timezone')}
            </Text>
            <Text color="grayscale-200" className="mt-1">
              {t(
                'Communities in the same location or with different time zones should follow the main timezone.',
              )}
            </Text>
            <div className="mt-8 pb-56 w-full flex flex-col">
              <Select
                value={timezoneSelectOption}
                setValue={(option) =>
                  handleChangeTimezone(option as SingleSelectedOption)
                }
                options={timezoneOptions}
                isSearchable
                placeholder={
                  <div className="wizard-form-select-placeholder">
                    <Text color="grayscale-200">{t('Select...')}</Text>
                  </div>
                }
                hasError={fieldErrors.timezone.hasError}
                errorMessage={fieldErrors.timezone.errorMessage}
              />
            </div>
          </>
        )}

        {loading && <Loading type="bubbles" />}
      </StyledOrganizationConfig>
    </>
  );
};

export default OrganizationConfig;
