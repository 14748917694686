import React from 'react';
import useDriveList from './hook';

import EmptyMessage from 'components/molecule/EmptyMessage';
import Loading from 'components/molecule/Loading';
import Breadcrumb from 'components/organism/Breadcrumb';

import FilesTable from './components/FilesTable';

import driveIsEmptyImage from 'assets/images/clip-drive-is-empty.svg';
import unavailableContentImage from 'assets/images/unavailable-content.svg';

import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import DebouncedInput from 'components/molecule/DebouncedInput';
import Icon from 'components/atom/Icon';
import Modal from 'components/molecule/Modal';
import FilterModal from './components/FilterModal';

import { StyledDriveList } from './styles';

const DriveList: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const {
    urlSufix,
    breadcrumb,
    files,
    loadFiles,
    setTableLoading,
    tableError,
    openModal,
    setOpenModal,
    filter,
    setFilter,
    handleSearchChange,
    hasMoreBreadcrumb,
    pageCount,
    t,
    tableLoading,
    parentId,
    handleNavigate,
    handleApplyCustomFilter,
    customFilterInitialState,
    handleChangeType,
  } = useDriveList(organizationId);

  return (
    <StyledDriveList className="w-full max-w-6xl">
      {parentId && (
        <div className="breadcrumb-container">
          <Breadcrumb
            links={breadcrumb.map((item) => ({
              id: item.id,
              label: item.name,
              action: () => handleNavigate(`/drive/${item.id}${urlSufix}`),
              path: `/drive/${item.id}${urlSufix}`,
            }))}
            fetchUrl={`/drive/org/${organizationId}/files/${breadcrumb[0]?.id}/breadcrumb`}
            fetchParams={{
              shared_with_me: 'true',
            }}
            rootPath={parentId ? `/drive${urlSufix}` : undefined}
            hasMore={hasMoreBreadcrumb}
            hasBackButton
            hasCharLimit
            limit={27}
          />
        </div>
      )}
      <div className="page-content">
        <div className="drive-content pb-11">
          {!tableError && (
            <div className="w-full">
              <Text as="h2" weight="700" className="mb-6">
                {t('Drive')}
              </Text>
              <div className="flex items-center gap-6 filter-wrapper">
                <DebouncedInput
                  icon="search"
                  iconColor="primary-color-200"
                  placeholder={t('Search by name')}
                  initialValue={filter.search}
                  onChange={handleSearchChange}
                />
                <Button
                  theme="primary-shadow"
                  leftIcon="filter-order"
                  onClick={() => setOpenModal((currentState) => !currentState)}
                  badge={
                    filter.created_at_period_from ||
                    filter.created_at_period_to ||
                    filter.size ||
                    filter.types.length > 0
                      ? ' '
                      : ''
                  }
                  className="custom-filter-bottom"
                >
                  {t('Filter')}
                </Button>
              </div>
              <div className="mt-8 flex items-center gap-2.5 flex-wrap">
                <Button
                  theme={filter.types.length === 0 ? 'dark' : 'dark-outline'}
                  size="small"
                  onClick={() => {
                    handleChangeType('all');
                  }}
                >
                  <div className="flex items-center gap-1">
                    <Icon name="action" />
                    <p className="mt-0.5">{t('All')}</p>
                  </div>
                </Button>

                <Button
                  theme={
                    filter.types.includes('images') ? 'dark' : 'dark-outline'
                  }
                  size="small"
                  onClick={() => {
                    handleChangeType('images');
                  }}
                >
                  <div className="flex items-center gap-1">
                    <Icon name="image" />
                    <p className="mt-0.5">{t('Images')}</p>
                  </div>
                </Button>
                <Button
                  theme={filter.types.includes('pdf') ? 'dark' : 'dark-outline'}
                  size="small"
                  onClick={() => {
                    handleChangeType('pdf');
                  }}
                >
                  <div className="flex items-center gap-1">
                    <Icon name="doc-file" />
                    <p className="mt-0.5">{t('PDF')}</p>
                  </div>
                </Button>
                <Button
                  theme={
                    filter.types.includes('videos') ? 'dark' : 'dark-outline'
                  }
                  size="small"
                  onClick={() => {
                    handleChangeType('videos');
                  }}
                >
                  <div className="flex items-center gap-1">
                    <Icon name="video-fill" />
                    <p className="mt-0.5">{t('Videos')}</p>
                  </div>
                </Button>
              </div>
            </div>
          )}

          {tableLoading && (
            <Loading
              width="80px"
              height="80px"
              color="primary-color"
              className="my-32"
            />
          )}

          {tableError && (
            <div className="empty-message-wrapper w-full flex flex-col items-center gap-4 justify-center">
              <EmptyMessage
                title={t('Unavailable content')}
                src={unavailableContentImage}
                showImage
                className="flex-1"
              />
              <Button to={`/drive/${urlSufix}`}>{t('Go to drive')}</Button>
            </div>
          )}

          {!tableError && !tableLoading && files.length === 0 && (
            <div className="empty-message-wrapper w-full flex justify-center">
              <EmptyMessage
                title={t('No files')}
                description={t('No files or folders found')}
                src={driveIsEmptyImage}
                showImage
              />
            </div>
          )}

          {!tableError && !tableLoading && files.length > 0 && (
            <FilesTable
              files={files}
              fetchFiles={(data) =>
                loadFiles({
                  ...data,
                  ordering: data.ordering.toString(),
                  size: data.size ? `${data.size}${data.size_order}` : '',
                  types: data.types,
                })
              }
              pageCount={pageCount}
              loading={tableLoading}
              setTableLoading={setTableLoading}
              handleNavigate={handleNavigate}
              filter={filter}
              setFilter={setFilter}
              onShare={() => undefined}
            />
          )}
        </div>
      </div>

      {openModal && (
        <Modal
          content={
            <FilterModal
              customFilter={{
                created_at_period_from: filter.created_at_period_from,
                created_at_period_to: filter.created_at_period_to,
                size_number: filter.size,
                size_order_of_magnitude: filter.size_order,
                types: filter.types,
              }}
              onApply={handleApplyCustomFilter}
              initialState={customFilterInitialState}
              onCancel={() => setOpenModal((currentState) => !currentState)}
            />
          }
          width="100%"
          maxWidth="865px"
          onCloseButtonClick={() =>
            setOpenModal((currentState) => !currentState)
          }
          disableBackgroundClick
        />
      )}
    </StyledDriveList>
  );
};

export default DriveList;
