import styled from 'styled-components';

export const StyledSmartPushForm = styled.div`
  width: 100%;

  h3 {
    margin-right: 12px;
  }

  .header {
    padding: 22px 54px;
    diplay: flex;

    .left-side {
      flex: 1;
    }

    .left-side-content {
      display: flex;
      min-height: 44px;
      align-items: center;
      justify-content: space-between;

      .actions {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;

        .button:first-child {
          padding-left: 0px;
        }
      }
    }

    #button-left-icon {
      font-size: 16px;
    }
  }

  .switch-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .text {
      margin-left: 8px;
      margin-bottom: 0 !important;
    }
  }

  section {
    margin-bottom: 24px;

    .section-contents {
      max-width: 800px;

      .button {
        margin: 0;
        padding: 0;
        justify-content: start;
        align-items: start;
      }

      .section-content {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        margin-right: 32px;
        margin-bottom: 16px;

        h6 {
          margin-bottom: 8px;
        }

        .section-group {
          align-items: center;
        }

        .form-group {
          display: flex;
          align-items: flex-start;
          gap: 8px;
        }
      }
    }
  }

  .content-config-container {
    width: 100%;
    height: calc(100% - 144px);
    padding: 0px 58px;

    .content-side {
      width: 100%;
      padding: 32px 0px;

      .content-header {
        display: flex;
        min-height: 44px;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          align-items: center;

          .button:first-child {
            padding-left: 0px;
          }
        }
      }

      .content {
        width: 100%;
        overflow-y: visible !important;
        margin-top: 24px;
      }
    }
  }

  .abort-button {
    margin-right: 40px;
  }
`;
