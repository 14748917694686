import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import Icon from 'components/atom/Icon';
import { Dropdown } from 'components/molecule/DropdownV2';

import CollaboratorProfile from '../CollaboratorProfile';

import { CollaboratorsTableActionsProps } from './types';

import { StyledCollaboratorsTableActions } from './styles';

const CollaboratorsTableActions: React.FC<CollaboratorsTableActionsProps> = ({
  id,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleManageUser = () => {
    dispatch(
      setModalView({
        show: true,
        width: '718px',
        padding: '0px',
        content: <CollaboratorProfile id={id} onSave={onSave} />,
      }),
    );
  };

  return (
    <StyledCollaboratorsTableActions className="action-wrapper">
      <Dropdown.Root
        trigger={<Icon name="more-2" color="grayscale-200" />}
        theme="dark"
      >
        <React.Fragment>
          <Dropdown.Item onClick={handleManageUser}>
            {t('Manage')}
          </Dropdown.Item>
        </React.Fragment>
      </Dropdown.Root>
    </StyledCollaboratorsTableActions>
  );
};

export default CollaboratorsTableActions;
