import styled from 'styled-components';

export const StyledPostDetails = styled.div`
  width: 100%;

  .skeleton-loading {
    margin-bottom: 16px;
  }

  .post-details-content {
    width: 100%;

    .cover {
      width: 100%;
      height: auto;
      min-height: 60px;
      position: relative;
      margin-bottom: 24px;

      .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        padding: 10px 32px;

        .button {
          border: 1px solid var(--grayscale-100);
        }

        .counters {
          display: flex;
          align-items: center;

          .tag {
            margin-left: 8px;
            border: 1px solid var(--grayscale-100);
          }
        }
      }

      .image {
        width: 100%;
        height: auto;
      }
    }

    .content-elements {
      padding: 0px 32px;

      pre {
        margin-bottom: 4px;
      }

      .title {
        margin-bottom: 8px;
      }

      .description {
        margin-bottom: 20px;
        line-height: 1.2;
      }

      .post-text {
        margin-bottom: 4px;
      }

      .button {
        padding-left: 0px;
        i {
          padding-left: 0px !important;
        }
      }

      a.button {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        .button-content {
          margin-top: 4px;
          white-space: pre-line;
          white-space: -o-pre-wrap;
          white-space: -moz-pre-wrap;
          word-break: break-all;
        }
      }
    }
  }
`;
