import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PeriodStatisticsCard from './components/PeriodStatisticsCard';

import { StyledStatistics } from './styles';

const Statistics: React.FC = () => {
  const { t } = useTranslation();
  const { subtype } = useParams();

  return (
    <StyledStatistics>
      <PeriodStatisticsCard
        title={
          subtype && ['short', 'article'].includes(subtype)
            ? t('Views')
            : t('Attainment')
        }
        description={t(
          'Total amount of users that viewed or responded to this content',
        )}
        statisticEntity="views"
      />
      <PeriodStatisticsCard
        title={t('Reactions')}
        description={t('Total of reactions to this content')}
        statisticEntity="reaction"
      />
      <PeriodStatisticsCard
        title={t('Comments')}
        description={t('Total of comments made or replied to this content')}
        statisticEntity="comments"
      />
      <PeriodStatisticsCard
        title={t('Engagement')}
        description={t(
          'Engagement is the % of valid views or responses over the total audience of a content. A response or view is valid as long as the user is still active and within programmed segmentation of the content',
        )}
        statisticEntity="engagement"
        isPercent
      />
    </StyledStatistics>
  );
};

export default Statistics;
