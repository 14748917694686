import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledAlertList } from './styles';
import AlertsTable from './AlertsTable';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';

const AlertList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAlertList>
      <div className="content-config-container">
        <div className="content-side">
          <div className="content-header">
            <Text weight="700" as="h3">
              {t('Alerts')}
            </Text>
            <div className="actions">
              <Button
                theme="link-gray-primary"
                to="/staff/cscx/alert/add"
                leftIcon="add-circle-fill"
              >
                {t('New alert')}
              </Button>
            </div>
          </div>
          <div className="content">
            <AlertsTable />
          </div>
        </div>
      </div>
    </StyledAlertList>
  );
};

export default AlertList;
