import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { clearUser } from 'store/slices/user';
import { clearOrganization } from 'store/slices/organization';

import { logout } from 'services/auth';

import AccountMenu from 'components/organism/AccountMenu';
import Button from 'components/molecule/Button';
import ChangeLanguage from 'components/molecule/ChangeLanguage';

import UserState from 'store/slices/user/types';

interface StaffHeaderContentProps {
  className?: string;
}

import { StyledStaffHeaderContent } from './styles';
import OrganizationsState from 'store/slices/organizations/types';

const StaffHeaderContent: React.FC<StaffHeaderContentProps> = ({
  className = '',
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user: UserState = useAppSelector((store) => store.user);
  const organizations: OrganizationsState = useAppSelector(
    (store) => store.organizations,
  );

  return (
    <StyledStaffHeaderContent className={`main-header-content ${className}`}>
      <ChangeLanguage />
      <AccountMenu name={user.name} avatar={user.avatar}>
        {(organizations.list || []).length > 0 && (
          <Button
            theme="link-gray-primary"
            leftIcon="people"
            onClick={() => {
              navigate('/');
            }}
          >
            {t('Admin panel')}
          </Button>
        )}
        <Button
          theme="link-gray-primary"
          leftIcon="logout"
          onClick={() => {
            logout();
            dispatch(clearUser());
            dispatch(clearOrganization());
            navigate('/');
          }}
        >
          {t('Logout')}
        </Button>
      </AccountMenu>
    </StyledStaffHeaderContent>
  );
};

export default StaffHeaderContent;
