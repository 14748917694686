import React from 'react';
import { useTranslation } from 'react-i18next';

import PollOption from 'components/organism/PollOption';
import Button from 'components/molecule/Button';

import { PollOptionsProps } from './types';
import { QuestionState } from '../../../types';

import { StyledPollOptions } from './styles';

const PollOptions: React.FC<PollOptionsProps> = ({
  options,
  setQuestionState,
  contentStatus,
}) => {
  const { t } = useTranslation();
  const isDisabled = !['', 'draft'].includes(contentStatus);

  const handleChangeText = (value: string, optionId: string) => {
    setQuestionState((updatedState: QuestionState) => ({
      ...updatedState,
      options: {
        ...updatedState.options,
        items: [
          ...updatedState.options.items.map((option) => {
            const newOption = { ...option };
            if (newOption.id === optionId) {
              newOption.text = value;
            }
            return newOption;
          }),
        ],
      },
    }));
  };

  const handleClear = (optionId: string) => {
    setQuestionState((updatedState: QuestionState) => ({
      ...updatedState,
      options: {
        ...updatedState.options,
        items: updatedState.options.items.map((option) => {
          const newOption = { ...option };
          if (newOption.id === optionId) {
            newOption.text = '';
          }
          return newOption;
        }),
      },
    }));
  };

  const handleToggleSelection = (optionId: string) => {
    setQuestionState((updatedState: QuestionState) => ({
      ...updatedState,
      options: {
        ...updatedState.options,
        items: updatedState.options.items.map((option) => {
          const newOption = { ...option };
          if (option.id === optionId) {
            newOption.is_correct = !option.is_correct;
          }
          return newOption;
        }),
      },
    }));
  };

  const handleRemove = (optionId: string) => {
    setQuestionState((updatedState: QuestionState) => ({
      ...updatedState,
      options: {
        ...updatedState.options,
        items: [
          ...updatedState.options.items.filter(
            (options) => options.id !== optionId,
          ),
        ],
      },
    }));
  };

  const addNewOption = () => {
    setQuestionState((updatedState: QuestionState) => ({
      ...updatedState,
      options: {
        items: [
          ...updatedState.options.items,
          {
            id: `poll-option-${Date.now()}`,
            text: '',
            is_correct: false,
            is_editable: true,
          },
        ],
        errors: [],
      },
    }));
  };
  return (
    <StyledPollOptions className="poll-options">
      <div className="option-list">
        {options.map((option, index) => (
          <PollOption
            key={index}
            id={option.id}
            text={option.text}
            isCorrect={option.is_correct}
            onChange={handleChangeText}
            onClear={handleClear}
            onRemove={handleRemove}
            onToggleSelection={handleToggleSelection}
            disableRemove={options.length <= 2}
            status={contentStatus}
            disabled={!option.is_editable || isDisabled}
          />
        ))}
      </div>
      {!isDisabled && (
        <Button
          theme="link-gray-primary"
          size="small"
          onClick={addNewOption}
          disabled={options.length >= 10}
        >
          {t('Add an option')}
        </Button>
      )}
    </StyledPollOptions>
  );
};

export default PollOptions;
