import React from 'react';
import { useParams } from 'react-router-dom';

import PostDetails from './components/PostDetails';
import SurveyDetails from './components/SurveyDetails';
import CheckinDetails from './components/CheckinDetails';

import { StyledContentDetails } from './styles';

const ContentDetails: React.FC = () => {
  const { type } = useParams();

  const renderContent = () => {
    switch (type) {
      case 'post': {
        return <PostDetails />;
      }
      case 'survey': {
        return <SurveyDetails />;
      }
      case 'checkin': {
        return <CheckinDetails />;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <StyledContentDetails className="content-details">
      {renderContent()}
    </StyledContentDetails>
  );
};

export default ContentDetails;
