import { isValidUrl } from './utils';
import { isYouTubeUrl, resolveYouTubeEmbed } from './parsers/youtube';
import { isKalturaUrl, resolveKalturaEmbed } from './parsers/kaltura';
import { isSharePointUrl, resolveSharePointEmbed } from './parsers/sharepoint';

export const getPluginOptions = () => [
  'advlist',
  'autolink',
  'directionality',
  'emoticons',
  'link',
  'lists',
  'image',
  'quickbars',
  'wordcount',
  'media',
];

export const getToolbarOptions = (isShowingToolbar: boolean) => {
  if (!isShowingToolbar) return false;

  return [
    'blocks fontfamily',
    'bold italic forecolor backcolor',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent',
    'ltr rtl',
  ].join(' | ');
};

export const getQuickbarSelectionOptions = (isShowingToolbar: boolean) => {
  if (isShowingToolbar) {
    return ['bold italic underline', 'quicklink blockquote'].join(' | ');
  }

  return [
    'blocks fontfamily',
    'bold italic underline forecolor backcolor',
    'quicklink blockquote',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent',
    'ltr rtl',
  ].join(' | ');
};

export const getFontFamilyFormats = () => {
  return ['Sans-serif=Hind,sans-serif', 'Monospaced=monospace'].join('; ');
};

export const getBlockFormats = () => {
  return [
    'Paragraph=p',
    'Heading 1=h1',
    'Heading 2=h2',
    'Heading 3=h3',
    'Heading 4=h4',
    'Heading 5=h5',
    'Heading 6=h6',
  ].join('; ');
};

export const getQuickbarInsertOptions = () => {
  return 'quickimage emoticons hr media';
};

export const getDefaultEditorCSSRules = () => {
  // language=css
  return `
    @import url('https://fonts.googleapis.com/css2?family=Hind&display=swap');

    body {
      font-family: Hind, sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      font-style: normal;
      text-align: start;
      font-size: 16px;
      font-weight: 550;
      color: #B4B6CB;
    }

    .mce-content-body {
      margin: 1px;
    }
  `;
};

export const getMediaPluginSettings = (): Record<string, unknown> => {
  return {
    // controls if user can set dimensions (width, height) in media
    media_dimensions: false,

    // controls if user can see "alternative source" tab
    media_alt_source: false,

    // controls if it should enable XSS sanitization for objects
    media_filter_html: true,

    // controls if user should see a live preview of embedded video
    media_live_embeds: true,

    // controls if user can see "advanced" tab
    media_poster: false,

    // handles resolving of urls into embedded media iframes
    media_url_resolver: function (
      data: { url: string },
      resolve: (response: { html: string }) => void,
      reject: (error: { msg: string }) => void,
    ): void {
      if (!isValidUrl(data.url)) {
        reject({
          msg: 'Media source (URL) supplied is not valid or not supported.',
        });
      }

      switch (true) {
        case isYouTubeUrl(data.url): {
          return resolveYouTubeEmbed({ data, resolve, reject });
        }

        case isKalturaUrl(data.url): {
          return resolveKalturaEmbed({ data, resolve, reject });
        }

        case isSharePointUrl(data.url): {
          return resolveSharePointEmbed({ data, resolve, reject });
        }

        default: {
          reject({
            msg: 'Media source (URL) supplied is not valid or not supported.',
          });
        }
      }
    },
  };
};
