import React from 'react';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { StyledFastMenu } from './styles';
import { FastMenuOptionProp } from './types';

interface MenuProps {
  topOptions?: FastMenuOptionProp[];
  bottomOptions?: FastMenuOptionProp[];
}

const FastMenu: React.FC<MenuProps> = ({
  topOptions = [],
  bottomOptions = [],
}) => {
  return (
    <StyledFastMenu>
      <div className="top-options">
        {topOptions.map((topOption, index) => (
          <Button
            key={index}
            theme="primary"
            to={topOption.path}
            disabled={topOption.disabled}
          >
            <Icon name={topOption.icon} />
          </Button>
        ))}
      </div>
      <div className="bottom-options">
        {bottomOptions.map((bottomOption, index) => (
          <div key={index}>
            {bottomOption.path && (
              <Button
                key={index}
                theme="primary"
                rounded="true"
                to={bottomOption.path}
                disabled={bottomOption.disabled}
              >
                <Icon name={bottomOption.icon} />
              </Button>
            )}
            {bottomOption.href && (
              <Button
                key={index}
                theme="primary"
                rounded="true"
                href={bottomOption.href}
                disabled={bottomOption.disabled}
              >
                <Icon name={bottomOption.icon} />
              </Button>
            )}
          </div>
        ))}
      </div>
    </StyledFastMenu>
  );
};

export default FastMenu;
