import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { showMessage } from 'store/slices/toaster';

import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';
import Loading from 'components/molecule/Loading';

import Question from './Question';

import { SelectOption } from 'components/molecule/Select/types';
import {
  Question as QuestionType,
  QuestionMode as QuestionModeType,
  SwapOptions as SwapOptionsType,
  QuestionHandle,
} from './types';

import {
  getSurveyQuestions,
  createSurveyQuestions,
  updateSurveyQuestions,
  deleteSurveyQuestions,
  swapSurveyQuestions,
} from 'apis/survey';

import { QuestionList, QuestionListProps } from '../GeneralAdd/types';

import { StyledSurveyQuestions } from './styles';
import { QuestionData } from 'apis/survey/types';

const SurveyQuestions = forwardRef<QuestionList, QuestionListProps>(
  ({ canEdit }, ref) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { pk: organizationId } = useAppSelector((state) => ({
      pk: state.organization.pk,
    }));

    const { contentId: contentTypeId } = useParams();

    const [questions, setQuestions] = useState<QuestionType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const questionRefs = useRef<Array<QuestionHandle | null>>([]);

    useImperativeHandle(ref, () => ({
      saveAllQuestions() {
        let success = true;
        questionRefs.current.forEach((ref) => {
          if (ref) {
            success &&= ref.saveQuestion();
          }
        });

        return success;
      },
    }));

    const questionCategories: SelectOption[] = [
      {
        value: 'open_answer',
        label: (
          <div className="flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Open answer')}
            </Text>
          </div>
        ),
      },
      {
        value: 'point_scale',
        label: (
          <div className="flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Likert')}
            </Text>
          </div>
        ),
      },
      {
        value: 'multiple_choice',
        label: (
          <div className="flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Multiple choice')}
            </Text>
          </div>
        ),
      },
      {
        value: 'single_choice',
        label: (
          <div className="flex gap-1 px-[10px] py-4 items-center">
            <Text color="primary-color-200" className="mt-0.5">
              {t('Single choice')}
            </Text>
          </div>
        ),
      },
    ];

    const showErrorMessage = useCallback(
      (message: string) => {
        dispatch(
          showMessage({
            title: message,
            theme: 'danger',
            icon: 'close',
            time: 10000,
            customLeft: '0px',
          }),
        );
      },
      [dispatch],
    );

    useEffect(() => {
      if (contentTypeId) {
        getSurveyQuestions(organizationId, contentTypeId).then((response) => {
          const updatedQuestions = response.data.map(
            (question: QuestionType) => ({
              ...question,
              options: {
                items: question.options,
                errors: [],
              },
              mode: QuestionModeType.Edit,
            }),
          );
          setQuestions(updatedQuestions);
        });
      }

      setIsLoading(false);
    }, [contentTypeId, organizationId]);

    const addNewQuestion = () => {
      const newQuestion: QuestionType = {
        is_active: true,
        title: '',
        is_required: false,
        nps_has_labels: true,
        order: questions.length + 1,
        category: 'open_answer',
        mode: QuestionModeType.Save,
        survey: contentTypeId || '',
        options: {
          items: [],
          errors: [],
        },
      };

      setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    };

    const removeQuestion = (order: number) => {
      const question = questions.find((question) => question.order === order);

      if (contentTypeId && question) {
        if (question.id) {
          deleteSurveyQuestions(organizationId, contentTypeId, question.id)
            .then(() => {
              setQuestions((prevQuestions) => {
                const newArray = prevQuestions
                  .filter((element) => element.order !== order)
                  .map((element, index) => {
                    return {
                      ...element,
                      order: index + 1,
                    };
                  });
                return newArray;
              });
            })
            .catch(() => {
              showErrorMessage(
                t('An unexpected error occurred while save question.'),
              );
            });
        } else {
          setQuestions((prevQuestions) => {
            const newArray = prevQuestions
              .filter((element) => element.order !== order)
              .map((element, index) => {
                return {
                  ...element,
                  order: index + 1,
                };
              });
            return newArray;
          });
        }
      }
    };

    const copyQuestion = (order: number) => {
      const questionToCopy = questions.find(
        (question) => question.order === order,
      );

      if (questionToCopy) {
        const newQuestion = { ...questionToCopy };
        newQuestion.id = undefined;
        newQuestion.order = questions.length + 1;
        saveQuestion(newQuestion);
      }
    };

    const swapQuestions = (order: number, direction: SwapOptionsType) => {
      const index = questions.findIndex((question) => question.order === order);

      if (index >= 0) {
        const newIndex = index + direction;
        if (newIndex >= 0 && newIndex < questions.length) {
          const newQuestions = [...questions];

          [newQuestions[index].order, newQuestions[newIndex].order] = [
            newQuestions[newIndex].order,
            newQuestions[index].order,
          ];

          if (contentTypeId) {
            const data = {
              from_question_id: newQuestions[index].id || '',
              to_question_id: newQuestions[newIndex].id || '',
            };

            if (data.from_question_id && data.to_question_id) {
              swapSurveyQuestions(organizationId, contentTypeId, data)
                .then((response) => {
                  if (response.status === 200) {
                    newQuestions.sort((a, b) => a.order - b.order);
                    setQuestions([...newQuestions]);
                  }
                })
                .catch(() => {
                  showErrorMessage(
                    t('An unexpected error occurred while swap question.'),
                  );
                });
            } else {
              newQuestions.sort((a, b) => a.order - b.order);
              setQuestions([...newQuestions]);
            }
          }
        }
      }
    };

    const saveLocalQuestion = (question: QuestionType) => {
      const index = questions.findIndex(
        (element) => element.order === question.order,
      );
      const newQuestions = [...questions];

      if (index >= 0) {
        newQuestions[index] = question;
        setQuestions([...newQuestions]);
      } else {
        setQuestions([...newQuestions, question]);
      }
    };

    const saveQuestion = (question: QuestionType) => {
      if (contentTypeId) {
        const newQuestion = {
          ...question,
          options: question.options.items,
        } as QuestionData;

        if (question.id) {
          updateSurveyQuestions(
            organizationId,
            contentTypeId,
            newQuestion,
            question.id,
          )
            .then((response) => {
              saveLocalQuestion({ ...question, id: response.data.id });
            })
            .catch(() => {
              showErrorMessage(
                t('An unexpected error occurred while save question.'),
              );
            });
        } else {
          createSurveyQuestions(organizationId, contentTypeId, newQuestion)
            .then((response) => {
              saveLocalQuestion({ ...question, id: response.data.id });
            })
            .catch(() => {
              showErrorMessage(
                t('An unexpected error occurred while save question.'),
              );
            });
        }
      }
    };

    const questionsMaxLength = 100;
    const hasReachedQuestionLimit = questions.length === questionsMaxLength;

    return (
      <StyledSurveyQuestions>
        {isLoading && <Loading type="bubbles" color="grayscale-100" />}
        {!isLoading &&
          questions.map((element: QuestionType, index: number) => {
            return (
              <div
                className="question-container"
                key={`${index}-${element.order}-${element.title}`}
              >
                <Question
                  ref={(el) => (questionRefs.current[index] = el)}
                  obj={element}
                  qntQuestions={questions.length}
                  questionCategories={questionCategories}
                  handleRemoveQuestion={() => removeQuestion(element.order)}
                  handleCopyQuestion={() => copyQuestion(element.order)}
                  handleSwapQuestions={swapQuestions}
                  handleSaveQuestion={saveQuestion}
                  hasReachedQuestionLimit={hasReachedQuestionLimit}
                  canEdit={canEdit}
                />
              </div>
            );
          })}
        {!isLoading && (
          <Button
            className="btn-full add-button"
            theme="primary-outline"
            onClick={addNewQuestion}
            leftIcon="add"
            disabled={hasReachedQuestionLimit || !canEdit}
          >
            {t('Add question')}
          </Button>
        )}
      </StyledSurveyQuestions>
    );
  },
);

SurveyQuestions.displayName = 'SurveyQuestions';

export default SurveyQuestions;
