import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { showMessage } from 'store/slices/toaster';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import {
  SelectOption,
  SelectedOptionValueType,
} from 'components/molecule/Select/types';
import { CustomFilterAttrsTypes } from './components/FilterModal/types';

import {
  AvailableTypes,
  AvailableVisibleTypes,
  FastFilterTypes,
  FilterProps,
  SurveyFilter,
  SurveyItemsProps,
} from './types';

import { getSurveys } from 'apis/survey';
import { objectsAreEquals } from 'utils/comparations';
import { AvailableOrganizationModules } from 'utils/organization';

const initialFilters: SurveyFilter = {
  page: 1,
  search: '',
  ordering: '-finish_at' as keyof typeof AvailableTypes,
  start_at: '',
  finish_at: '',
  status: 'all',
  anonymous: 'all' as keyof typeof AvailableVisibleTypes,
  minimum: null,
  maximum: null,
};

const useSurveyList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { researchType = 'general' } = useParams();

  const { pk, avatar, name, client, modules } = useAppSelector(
    (state) => state.organization,
  );

  const [pageCount, setPageCount] = useState(0);
  const [fastFilters, setFastFilters] = useState<FastFilterTypes>({
    draft: 0,
    scheduled: 0,
    active: 0,
    finished: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const selectOptions: SelectOption[] = [
    {
      value: '-finish_at',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('Due date')}
          </Text>
        </div>
      ),
    },
    {
      value: '-start_at',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('Start date')}
          </Text>
        </div>
      ),
    },
    {
      value: '-answers',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('Most answers')}
          </Text>
        </div>
      ),
    },
    {
      value: 'answers',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('Least answers')}
          </Text>
        </div>
      ),
    },
    {
      value: 'title',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('A-Z')}
          </Text>
        </div>
      ),
    },
    {
      value: '-title',
      label: (
        <div className="sort-list-item flex gap-1 px-[10px] py-4 items-center">
          <Icon name="sort-right" color="primary-color-200" />
          <Text color="primary-color-200" className="mt-0.5">
            {t('Z-A')}
          </Text>
        </div>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState<SelectedOptionValueType>(
    selectOptions[0],
  );
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState<SurveyFilter>(initialFilters);

  const [surveyItems, setSurveyItems] = useState<SurveyItemsProps[]>([]);
  const [loadingChange, setLoadingChange] = useState(true);

  const checkIfFiltersApplied = (filters: FilterProps) => {
    const check = !objectsAreEquals(filters, initialFilters);
    setIsFilterApplied(check);
  };

  const loadSurveys = useCallback(
    (
      filters: FilterProps = {
        page: 1,
        search: '',
        ordering: '-finish_at' as keyof typeof AvailableTypes,
        start_at: '',
        finish_at: '',
        status: 'all',
        anonymous: 'all' as keyof typeof AvailableVisibleTypes,
        minimum: null,
        maximum: null,
      },
    ) => {
      setLoadingChange(true);
      getSurveys(pk, filters)
        .then(({ data }) => {
          const { results, num_pages, surveys_by_status } = data;
          setPageCount(num_pages);

          setSurveyItems(results);
          setFastFilters(surveys_by_status);

          checkIfFiltersApplied(filters);

          setFilter((updatedSurveyFiltersState) => ({
            ...updatedSurveyFiltersState,
            page: filters.page,
          }));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate('/');
            return;
          }

          dispatch(
            showMessage({
              title: t(
                'An unexpected error occurred while loading the surveys',
              ),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setIsLoading(false);
          setLoadingChange(false);
        });
    },
    [dispatch, pk, t, navigate],
  );

  const getStatus = (status: string) => {
    switch (status) {
      case 'scheduled':
        return (
          <div
            className="px-[10px] py-3 w-max rounded-lg"
            style={{ backgroundColor: '#FFAB2C' }}
          >
            <span className="text-white-gray-color font-bold">
              {t('Scheduled')}
            </span>
          </div>
        );
      case 'active':
        return (
          <div
            className="px-[10px] py-3 w-max rounded-lg"
            style={{ backgroundColor: '#45C93A' }}
          >
            <span className="text-white-gray-color font-bold">
              {t('Active')}
            </span>
          </div>
        );
      case 'finished':
        return (
          <div
            className="px-[10px] py-3 w-max rounded-lg"
            style={{ backgroundColor: '#B4B6CB' }}
          >
            <span className="text-white-gray-color font-bold">
              {t('Finished')}
            </span>
          </div>
        );

      default:
        return (
          <div
            className="px-[10px] py-3 w-max rounded-lg"
            style={{ backgroundColor: '#D2D1FF' }}
          >
            <span className="text-white-gray-color font-bold">
              {t('Draft')}
            </span>
          </div>
        );
    }
  };

  const handleBack = () => {
    navigate('/research');
  };

  const hasAppliedFilter = () => {
    return filter.start_at || filter.finish_at || filter.anonymous !== 'all';
  };

  const handleSearchChange = (value: string) => {
    const { ordering, start_at, finish_at } = filter;

    loadSurveys({
      ...filter,
      start_at: start_at || '',
      finish_at: finish_at || '',
      ordering: ordering as keyof typeof AvailableTypes,
      search: value,
      page: 1,
    });

    setFilter((updatedSurveyFiltersState) => ({
      ...updatedSurveyFiltersState,
      search: value,
    }));
  };

  const handleChangeOrdering = (option: SelectedOptionValueType) => {
    const currentType = (
      option && 'value' in option ? option.value : '-start_at'
    ) as keyof typeof AvailableTypes;

    const { start_at, finish_at } = filter;

    loadSurveys({
      ...filter,
      start_at: start_at || '',
      finish_at: finish_at || '',
      ordering: currentType,
    });

    setFilter((updatedSurveyFiltersState) => ({
      ...updatedSurveyFiltersState,
      ordering: currentType,
    }));
  };

  const handleChangePage = (newPage: number) => {
    const { ordering, search, start_at, finish_at } = filter;

    loadSurveys({
      ...filter,
      search: search,
      start_at: start_at || '',
      finish_at: finish_at || '',
      ordering: ordering,
      page: newPage + 1,
    });

    setFilter((updatedSurveyFiltersState) => ({
      ...updatedSurveyFiltersState,
      page: newPage + 1,
    }));
  };

  const handleApplyCustomFilter = (newFilter: CustomFilterAttrsTypes) => {
    const { ordering, search } = filter;

    loadSurveys({
      ...filter,
      search: search,
      start_at: newFilter.start_at || '',
      finish_at: newFilter.finish_at || '',
      ordering: ordering,
      anonymous: newFilter.anonymous,
      minimum: newFilter.minimum,
      maximum: newFilter.maximum,
      page: 1,
    });
    setFilter((updatedSurveyFiltersState) => ({
      ...updatedSurveyFiltersState,
      start_at: newFilter.start_at || '',
      finish_at: newFilter.finish_at || '',
      anonymous: newFilter.anonymous,
      minimum: newFilter.minimum,
      maximum: newFilter.maximum,
      page: 1,
    }));
    setOpenModal((currentState) => !currentState);
  };

  const handleChangeStatus = (newStatus: string) => {
    const { ordering, status, search, start_at, finish_at } = filter;

    const finalStatus = newStatus === status ? 'all' : newStatus;

    loadSurveys({
      ...filter,
      search: search,
      start_at: start_at || '',
      finish_at: finish_at || '',
      ordering: ordering,
      page: 1,
      status: finalStatus,
    });

    setFilter((updatedSurveyFiltersState) => ({
      ...updatedSurveyFiltersState,
      status: finalStatus,
    }));
  };

  const onReloadSurveys = () => {
    const { start_at, finish_at } = filter;

    loadSurveys({
      ...filter,
      start_at: start_at || '',
      finish_at: finish_at || '',
      page: 1,
    });
  };

  const canShowResearch: boolean =
    modules?.includes(AvailableOrganizationModules.research) ?? false;

  useEffect(() => {
    if (!canShowResearch) {
      navigate('/');
      return;
    }

    loadSurveys();
  }, [loadSurveys, navigate, canShowResearch]);

  return {
    t,
    filter,
    orgInfo: {
      pk,
      avatar,
      name,
      client,
    },
    navigate,
    isLoading,
    pageCount,
    handleBack,
    fastFilters,
    getStatus,
    openModal,
    setOpenModal,
    researchType,
    loadingChange,
    isFilterApplied,
    handleChangePage,
    selectOptions,
    surveyItems,
    setSurveyItems,
    selectedOption,
    setSelectedOption,
    handleChangeStatus,
    handleSearchChange,
    handleChangeOrdering,
    handleApplyCustomFilter,
    hasAppliedFilter,
    onReloadSurveys,
  };
};

export default useSurveyList;
