import React from 'react';

import Text from 'components/atom/Text';
import Tooltip from 'components/atom/Tooltip';

import { StyledFilesTableCreatedBy } from './styles';

interface FilesTableCreatedByProps {
  index: number;
  owner: string;
}

const FilesTableCreatedBy: React.FC<FilesTableCreatedByProps> = ({
  index,
  owner,
}) => {
  return (
    <StyledFilesTableCreatedBy>
      <Tooltip
        id={`owner-${index}`}
        content={owner}
        disabled={owner.length < 19}
      >
        <Text as="pre" color="grayscale-300">
          {owner}
        </Text>
      </Tooltip>
    </StyledFilesTableCreatedBy>
  );
};

export default FilesTableCreatedBy;
