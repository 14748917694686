import { postInitialState } from 'pages/board/MuralContent/components/PostContents/utils';

const useContentInitialState = (type: string) => {
  switch (type) {
    case 'general': {
      return { ...postInitialState };
    }
    default: {
      return { ...postInitialState };
    }
  }
};

export default useContentInitialState;
