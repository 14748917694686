import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/atom/Text';
import Icon from 'components/atom/Icon';
import Button from 'components/molecule/Button';
import Input from 'components/molecule/Input';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalView } from 'store/slices/modal';

import { StyledMove } from './styles';
import Breadcrumb from 'components/organism/Breadcrumb';
import NavigationTable from './components/NavigationTable';

import { FileContentProps } from '../../FilesTable/types';
import { createDriveFile, getDriveFiles } from 'apis/driveDashboard';
import { showMessage } from 'store/slices/toaster';
import { BreadcrumbItem } from 'apis/general/types';
import Loading from 'components/molecule/Loading';
import { addEllipsisInMiddle } from 'utils/string';

interface MoveProps {
  name: string;
  fileId: string;
  onConfirm?: (path: string | null) => void;
  currentParentId: string | null;
  onLoadDriveFiles: () => void;
}

const Move: React.FC<MoveProps> = ({
  fileId,
  name,
  onConfirm,
  currentParentId,
  onLoadDriveFiles,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pk: organizationId, module_drive_id: driveId } = useAppSelector(
    (store) => store.organization,
  );

  const [showNewFolder, setShowNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [currentFolderName, setCurrentFolderName] = useState<string | null>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [folders, setFolders] = useState<FileContentProps[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [parentId, setParentId] = useState(currentParentId);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [hasMoreBreadcrumb, setHasMoreBreadcrumb] = useState(false);
  const [loadingSaveFolder, setLoadingSaveFolder] = useState(false);

  const loadFolders = useCallback(
    (parentId: string | null, page: number) => {
      setTableLoading(true);
      getDriveFiles(
        organizationId,
        {
          ordering: '',
          page: page,
          search: '',
          is_folder: true,
          exclude_file_id: fileId,
        },
        parentId ? parentId : '',
      )
        .then((response) => {
          const {
            results,
            num_pages,
            breadcrumb: currentBreadcrumb,
            current_folder_name,
          } = response.data;
          setFolders(results);
          setPageCount(num_pages);

          setParentId(parentId);
          setCurrentPage(page);

          setBreadcrumb(parentId ? currentBreadcrumb.results : []);
          setHasMoreBreadcrumb(parentId ? currentBreadcrumb.has_more : false);

          setCurrentFolderName((lastCurrentFolderName) => {
            if (!lastCurrentFolderName) {
              return current_folder_name;
            }
            return lastCurrentFolderName;
          });
        })
        .catch(() => {
          dispatch(
            showMessage({
              title: t('An unexpected error occurred while loading data'),
              theme: 'danger',
              icon: 'close',
              time: 10000,
            }),
          );
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [dispatch, organizationId, t, fileId],
  );

  const handleCloseNewFolder = () => {
    setNewFolderName('');
    setShowNewFolder(false);
  };

  const handleCreateNewFolder = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoadingSaveFolder(true);

    createDriveFile(organizationId, {
      drive: driveId,
      name: newFolderName,
      parent: parentId,
    })
      .then((response) => {
        const { id } = response.data;
        loadFolders(id, 1);
        setNewFolderName('');
        setShowNewFolder(false);
        onLoadDriveFiles();
      })
      .catch(() => {
        dispatch(
          showMessage({
            title: t('An unexpected error occurred while creating a folder'),
            theme: 'danger',
            icon: 'close',
            time: 10000,
          }),
        );
      })
      .finally(() => {
        setLoadingSaveFolder(false);
      });
  };

  const handleCancel = () => {
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(parentId);
    }
    dispatch(
      setModalView({
        show: false,
      }),
    );
  };

  const moveToName = useMemo(
    () =>
      breadcrumb.length > 0 ? breadcrumb[breadcrumb.length - 1].name : 'Drive',
    [breadcrumb],
  );

  useEffect(() => {
    loadFolders(currentParentId, 1);
  }, [loadFolders, currentParentId]);

  return (
    <StyledMove>
      <div className="modal-content">
        <Text as="h4" weight="700">
          {t('Move file')}
        </Text>
        <div className="subtitle-wrapper">
          <Text as="p">{t('You are moving the file/folder')}</Text>
          <Text
            as="p"
            weight="700"
            color="primary-color-200"
            className="file-name"
          >
            {addEllipsisInMiddle(name, 20, 20, 7)}
          </Text>
        </div>
        <div className="location-wrapper">
          <div className="initial-location">
            <Text as="p">{t('Current location')}</Text>
            <div>
              <Icon name="folder-fill" />
              <Text className="truncate max-w-[18ch]" as="p">
                {currentFolderName}
              </Text>
            </div>
          </div>
          <div className="final-location">
            <Text as="p">{t('Move to')}</Text>
            <div>
              <Icon name="folder-line" />
              <Text className="truncate max-w-[18ch]" as="p">
                {breadcrumb.length > 0
                  ? breadcrumb[breadcrumb.length - 1].name
                  : 'Drive'}
              </Text>
            </div>
          </div>
        </div>
        <div className="folder-navigation-wrapper">
          <Breadcrumb
            links={breadcrumb.map((item) => ({
              id: item.id,
              label: item.name,
              action: () => {
                loadFolders(item.id, 1);
              },
            }))}
            fetchUrl={`/drive/org/${organizationId}/files/${breadcrumb[0]?.id}/breadcrumb`}
            rootPath={parentId ? '/' : ''}
            rootOnClick={() => {
              loadFolders(null, 1);
            }}
            hasMore={hasMoreBreadcrumb}
            hasBackButton
            backButttonOnClick={() =>
              loadFolders(breadcrumb[breadcrumb.length - 2].id, 1)
            }
            linkOnClick={(path) => {
              const pathArray = path.split('/');
              loadFolders(pathArray[pathArray.length - 1] || null, 1);
            }}
            hasCharLimit
            limit={27}
          />

          <NavigationTable
            folders={folders}
            loading={tableLoading}
            fetchFolders={loadFolders}
            page={currentPage - 1}
            pageCount={pageCount}
            onChangePage={(newPage) => {
              loadFolders(parentId, newPage + 1);
            }}
            newFolderFieldIsVisible={showNewFolder}
          />
          {showNewFolder && (
            <form
              onSubmit={handleCreateNewFolder}
              className="flex items-center gap-2"
            >
              <Input
                type="text"
                placeholder={t('Folder name')}
                actions={
                  !loadingSaveFolder ? (
                    <Button
                      theme="link-gray-primary"
                      size="small"
                      onClick={handleCloseNewFolder}
                    >
                      <Icon name="close-circle-fill" color="primary-color" />
                    </Button>
                  ) : (
                    <Loading
                      type="bubbles"
                      width="32px"
                      height="32px"
                      className="p-1"
                    />
                  )
                }
                value={newFolderName}
                onChange={(event) => setNewFolderName(event.target.value)}
                limit={100}
                hideCharacterCount
                disabled={loadingSaveFolder}
              />
              <Button
                type="submit"
                size="small"
                theme="success"
                rounded="true"
                className="mt-3"
                disabled={
                  loadingSaveFolder || newFolderName.trim().length === 0
                }
              >
                <Icon name="check" color="white-color" />
              </Button>
            </form>
          )}
        </div>
        <div className="modal-footer">
          <div>
            <Button
              theme="link-page-button-primary"
              leftIcon="add"
              onClick={() => setShowNewFolder(true)}
              disabled={showNewFolder}
            >
              {t('New Folder')}
            </Button>
          </div>
          <div>
            <Button theme="link-dark-gray" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
            <Button
              theme="primary"
              onClick={handleConfirm}
              rounded="true"
              disabled={currentFolderName === moveToName}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </StyledMove>
  );
};

export default Move;
