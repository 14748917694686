import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';
import { stripHTMLTags } from 'utils/string';

import Input from 'components/molecule/Input';
import Textarea from 'components/molecule/Textarea';
import CoverImage from 'components/organism/CoverImage';

import { ShortPostContentProps } from './types';
import { CoverProps } from 'components/organism/CoverImage/types';
import { PostContentFields } from '../../types';

import { StyledShortPostContent } from './styles';

const ShortPostContent: React.FC<ShortPostContentProps> = ({
  shortContentState,
  setContentState,
}) => {
  const { t } = useTranslation();

  const organizationId: string = useAppSelector(
    (state) => state.organization.pk,
  );

  const handleChangeCover = (cover: CoverProps) => {
    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      cover: {
        ...updatedState.cover,
        value: cover.id,
        url: cover.url,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      title: {
        ...updatedState.title,
        value: value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setContentState((updatedState: PostContentFields) => ({
      ...updatedState,
      text: {
        ...updatedState.text,
        value,
        error: { hasError: false, errorMessage: '' },
      },
    }));
  };

  return (
    <StyledShortPostContent className="short-post-content">
      <CoverImage
        coverUrl={shortContentState.cover.url}
        setCover={handleChangeCover}
        organizationId={organizationId}
        hasError={shortContentState.cover.error.hasError}
        errorMessage={shortContentState.cover.error.errorMessage}
      />

      <Input
        id="short-post-title-input"
        className="title"
        theme="post"
        focusedLabel={t('Title *')}
        placeholder={`${t('Enter the title *')}`}
        value={shortContentState.title.value?.toString() || undefined}
        onChange={handleChangeTitle}
        limit={255}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={shortContentState.title.error.hasError}
        errorMessage={shortContentState.title.error.errorMessage}
      />
      <Textarea
        id="short-post-description-textarea"
        className="description"
        theme="post"
        focusedLabel={t('Content *')}
        placeholder={`${t('Enter the content *')}`}
        value={
          stripHTMLTags(shortContentState.text.value?.toString()) || undefined
        }
        onChange={handleChangeText}
        limit={1000}
        hideCharacterCount={false}
        characterCountType="focused"
        hasError={shortContentState.text.error.hasError}
        errorMessage={shortContentState.text.error.errorMessage}
        autoResize
        removeBreakLines
      />
    </StyledShortPostContent>
  );
};

export default ShortPostContent;
