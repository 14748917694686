import React from 'react';

import { DriveProps } from './types';

import useDrive from './hook';

import Header from 'components/atom/Header';
import DriveList from './components/DriveList';
import EmptyMessage from 'components/molecule/EmptyMessage';
import AppLoading from 'components/organism/AppLoading';

import unavailableContentImage from 'assets/images/unavailable-content.svg';

import { StyledDrive } from './styles';

const Drive: React.FC<DriveProps> = () => {
  const { isError, t, organizationId, isLoading } = useDrive();

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <StyledDrive className="w-full h-max flex flex-col items-center justify-start default-scroll">
      <Header className="min-h-[64px] max-h-16" />
      {!isError && organizationId && (
        <DriveList organizationId={organizationId} />
      )}
      {isError && (
        <EmptyMessage
          title={t('Unavailable content')}
          src={unavailableContentImage}
          showImage
          className="flex-1"
        />
      )}
    </StyledDrive>
  );
};

export default Drive;
