import apiV2 from 'apis/apiV2';
import { CustomFieldRequestData, DenylistRequestData } from './types';

const basePathname = '/admin/org';

export const getCustomFields = (organizationId: string, page: number) =>
  apiV2.get(`${basePathname}/${organizationId}/core/customfields/`, {
    params: {
      page,
    },
  });

export const getCustomFieldsConfig = (organizationId: string) =>
  apiV2.get(`${basePathname}/${organizationId}/core/customfields/config/`);

export const getCustomField = (organizationId: string, id: string) =>
  apiV2.get(`${basePathname}/${organizationId}/core/customfields/${id}/`);

export const createCustomField = (
  organizationId: string,
  data: CustomFieldRequestData,
) => apiV2.post(`${basePathname}/${organizationId}/core/customfields/`, data);

export const updateCustomField = (
  organizationId: string,
  data: CustomFieldRequestData,
  id: string,
) =>
  apiV2.put(`${basePathname}/${organizationId}/core/customfields/${id}/`, data);

export const getDenylistWords = (
  organizationId: string,
  page: number,
  search: string,
  toTheCurrentPage?: boolean,
) =>
  apiV2.get(`${basePathname}/${organizationId}/core/denylist/`, {
    params: {
      page: toTheCurrentPage ? null : page,
      search,
      next_pages: toTheCurrentPage ? page : null,
    },
  });

export const createDenylistWord = (
  organizationId: string,
  data: DenylistRequestData,
) => apiV2.post(`${basePathname}/${organizationId}/core/denylist/`, data);

export const updateDenylistWord = (
  organizationId: string,
  id: string,
  data: DenylistRequestData,
) => apiV2.put(`${basePathname}/${organizationId}/core/denylist/${id}/`, data);

export const removeDenylistWord = (organizationId: string, id: string) =>
  apiV2.delete(`${basePathname}/${organizationId}/core/denylist/${id}/`);
